import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import api from '@/store/api';
// import aggregations from '../../views/collection/mock/collection-time-series-agg.mock';
// import timeScales from '../../views/collection/mock/collection-time-series-timescales.mock';

// const MAX_PAGE_SIZE = 500;

export default {

    namespaced: true,

    state: {
        collectionSettings: null,
        schema: null,

        filter: {
            filterQuery  : '*',
            filterQueryLanguage: 'SIMPLE',
            facetFilters : [],
            rangeFilters : []
        },

        form: {
            attribute: {},
            interval: 15,
            valid: false,
            sent: false
        },

        selectedSort: 'name-asc',

        response: {}
    },

    mutations: {

        setCollectionSettings(state, response) {
            state.collectionSettings = get(response, 'collectionSettings', {});
            state.schema = get(response, 'schema', {});
        },

        setAttribute(state, attribute) {
            if (attribute) {
                state.form.attribute = attribute;
                state.form.valid = !(isEmpty(state.form.attribute) && isEmpty(state.form.interval));
            }
        },

        setInterval(state, interval) {
            if (interval) {
                state.form.interval = interval;
                state.form.valid = !(isEmpty(state.form.attribute) && isEmpty(state.form.interval));
            }
        },

        setSelectedSort(state, value) {
            state.selectedSort = value || 'value';
        },

        setResponse(state, response) {
            if (response) {
                state.response = response;
                state.response.data.datatype = state.schema.attributes[response.request.distribution.attributeName].datatype;
                state.filter = response.filter || response.request.filter;
            }
        },

        setFilterQuery(state, { filterQuery, filterQueryLanguage}) {
            state.filter.filterQuery = filterQuery ? filterQuery : '';
            state.filter.filterQueryLanguage = filterQueryLanguage ? filterQueryLanguage  : 'SIMPLE';
        },

        setFacetFilters(state, filters) {
            state.filter.facetFilters = filters.facetFilters ? filters.facetFilters : [];
            state.filter.rangeFilters = filters.rangeFilters ? filters.rangeFilters : [];
        },
    },

    actions: {

        async loadCollectionSettings(context, { collectionId }) {
            return api.collections.findById(collectionId).then(response => {
                context.commit('setCollectionSettings', response);
            });
        },

        async loadData(context, { collectionId }) {
            const payload = context.getters.request;
            return api.collections.distribution.getDistribution(collectionId, payload).then(response => {
                context.commit('setResponse', response);
            }); 
        },

        async updateFilterQuery(context, {collectionId, filterQuery}) {
            context.commit('setFilterQuery', filterQuery);
            if (context.getters.form.valid) {
                return context.dispatch('loadData', {collectionId});
            }
        },

        async updateFacetFilters(context, {collectionId, facetFilters}) {
            context.commit('setFacetFilters', facetFilters);
            if (context.getters.form.valid) {
                return context.dispatch('loadData', {collectionId: collectionId});
            }
        },

        async export(context, {collectionId, format}) {
            const request = {distribution: context.getters.request, format: format};
            if (context.getters.form.valid) {
                return api.collections.distribution.export(collectionId, request).then(() => {});
            }
        },
    },

    getters: {
        collectionSettings(state) {
            return state.collectionSettings;
        },

        schema(state) {
            return state.schema;
        },

        filter(state) {
            return state.filter;
        },

        form(state) {
            return state.form;
        },

        selectedSort(state) {
            return state.selectedSort;
        },

        request(state) {
            return {
                filter: state.filter,
                distribution: {
                    attributeName: state.form.attribute.name,
                    interval: state.form.interval,
                }
            }
        },

        response(state) {
            return state.response;
        }
    }
};