
<template>
  <v-sheet class="grey lighten-3">

      <v-container fluid>
          <v-row>
            <v-col lg="8" cols="12">
                    
              <!-- DANGER: do not remove :key="chartKey". This is a required workaround -->
              <v-card tile>
                <v-card-text class="text-h6">{{$t('collection-group-detail.component.cards.chart.title')}}</v-card-text>
                <v-card-text>
                  {{$t('collection-group-detail.component.cards.chart.description', {numberOfDocuments, allElements})}}
                </v-card-text>
                <c-metrics-time-series-chart :value="chart" :key="chartKey" legend="bottom" zoom="top" class="pb-5" height="600px" fallbackChartType="line" />
              </v-card>

              <v-card tile class="mt-5">
                <v-card-text class="text-h6">{{$t('collection-group-detail.component.cards.table.title')}}</v-card-text>
                <v-card-text>
                  {{$t('collection-group-detail.component.cards.chart.description', {numberOfDocuments, allElements})}}
                </v-card-text>
                <c-collection-metric-table v-model="value"/>
              </v-card>
                    
              </v-col>
              <v-col lg="4" cols="12">
                  <v-container fluid class="pa-0">
                      <v-row>
                        <v-col cols="12">
                            <c-collection-metric-tiles v-model="commonAggregations" />
                        </v-col>
                        <v-col cols="12">
                            <c-collection-metric-tiles v-model="quartileAggregations">
                                <c-box-plot-chart :items="boxPlot" :key="boxPlotKey" layout="horizontal" height="180px" :showCategoryAxis="false"/>
                            </c-collection-metric-tiles>
                        </v-col>
                        <v-col cols="12">
                            <c-collection-metric-tiles v-model="varianceAggregations" />
                        </v-col>
                        <v-col cols="12">
                            <c-collection-metric-tiles v-model="standardDeviationAggregations" />
                        </v-col>
                      </v-row>
                  </v-container>
                
                
                
               
              </v-col>
          </v-row>
      </v-container>
      
      
      <v-divider/>

      
  </v-sheet>
</template>

<script>
const MODULE_NAME = "collectionMetrics";
import { get, pick } from 'lodash';
import { Charts } from '@/core/components/collections/metric-charts.lib.js';
// import boxPlotChartmodel from '@/core/components/collections/metric-charts-boxplot.lib.js';

import CollectionMetricTilesComponent from "@/core/components/collections/collection-metrics-tiles.component.vue";
import CollectionMetricTableComponent from "@/core/components/collections/collection-metrics-table.component.vue";
export default {
  components: {
    "c-collection-metric-tiles": CollectionMetricTilesComponent,
    "c-collection-metric-table": CollectionMetricTableComponent
  },
  props: {
    value:{
      type: Object,
      required: true
    }
  },

  data() {
    return {
        chartKey: 0,
        boxPlotKey: 0
    };
  },

  computed: {
    group: {
      get(){
        return get(this.value, "group", {});
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    request() {
      return get(this.value, "request", {});
    },
    numberOfDocuments(){
      return this.documents.length;
    },
    aggregations(){
      return get(this.value, "group.metrics", {});
    },
    documents(){
      return get(this.value, "group.documents", []);
    },
    name(){
      return get(this.value, "group.name", {});
    },
    allElements(){
      return get(this.aggregations, "count", " ");
    },
    chartAggregations(){
      let toContain = ["average", "min", "max"];
      let result = pick(this.aggregations, toContain);
      return result;
    },

    quartiles(){
      return get(this.value, "group.quartiles", {});
    },
    commonAggregations(){
      let toContain = ["average", "min", "max", "sum_of_squares"];
      let aggs = pick(this.aggregations, toContain);
      return {aggs: aggs, group: "collection-metrics-tiles.component.common.label"};
    },
    quartileAggregations(){
      return {aggs: this.quartiles, group: "collection-metrics-tiles.component.quartiles.label"}
    },
    varianceAggregations(){
      let toContain = ["variance", "variance_sampling", "variance_population"];
      let aggs = pick(this.aggregations, toContain);
      return {aggs: aggs, group: "variance"};
    },
    standardDeviationAggregations(){
      let toContain = ["std_deviation", "std_deviation_sampling", "std_deviation_population"];
      let aggs = pick(this.aggregations, toContain);
      return {aggs: aggs, group: "std_deviation"};
    },
    lastRequest(){
      return this.$store.getters[MODULE_NAME + "/lastRequest"];
    },
    chart(){
      this.forceRerender();
      return Charts.chartmodel(this.chartAggregations, this.documents, this.lastRequest);
    },
    boxPlot(){
      this.forceRerender2();
      return Charts.boxPlotChartmodel(this.quartiles);
    }
  },
  methods: {
    forceRerender(){
      // Changes chartKey attribute to force rerender the chart component by :key="chartKey"
      // When missing to so, the chart won´t resize correctly and the visualization looks crappy
      this.chartKey += 1;

    },
    forceRerender2(){
      // Changes chartKey attribute to force rerender the chart component by :key="chartKey"
      // When missing to so, the chart won´t resize correctly and the visualization looks crappy
      this.boxPlotKey += 1;
    }

  }
};
</script>

<i18n>
    {
        "en" : {
            "collection-metrics.view.group.elements.number.label": "Elements",
            "collection-group-detail.component.cards.chart.title" : "Chart",
            "collection-group-detail.component.cards.boxplot.title" : "Box-Plot",
            "collection-group-detail.component.cards.table.title" : "Tabular Data",
            "collection-group-detail.component.cards.chart.description": "Shown are {numberOfDocuments} out of {allElements} values ordered descending by timestamp" 
        },
        "de" : {
            "collection-metrics.view.group.elements.number.label": "Elemente",
            "collection-group-detail.component.cards.chart.title" : "Diagramm",
            "collection-group-detail.component.cards.boxplot.title" : "Box-Plot",
            "collection-group-detail.component.cards.table.title" : "Werte-Tabelle",
            "collection-group-detail.component.cards.chart.description": "Angezeigt werden {numberOfDocuments} von {allElements} Werten, absteigend sortiert nach dem Zeitpunkt"
        }
    }
</i18n>