export default [
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "1 second",
                "shortName": "1 sec."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "1 Sekunde",
                "shortName": "1 Sek."
            },
        },
        value: 1,
        unit: 'second'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "2 seconds",
                "shortName": "2 secs."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "2 Sekunden",
                "shortName": "2 Sek."
            },
        },
        value: 2,
        unit: 'second'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "5 seconds",
                "shortName": "5 secs."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "5 Sekunden",
                "shortName": "5 Sek."
            },
        },
        value: 5,
        unit: 'second'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "10 seconds",
                "shortName": "10 secs."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "10 Sekunden",
                "shortName": "10 Sek."
            },
        },
        value: 10,
        unit: 'second'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "15 seconds",
                "shortName": "15 secs."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "15 Sekunden",
                "shortName": "15 Sek."
            },
        },
        value: 15,
        unit: 'second'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "30 seconds",
                "shortName": "30 secs."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "30 Sekunden",
                "shortName": "30 Sek."
            },
        },
        value: 30,
        unit: 'second'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "1 minute",
                "shortName": "1 min."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "1 Minute",
                "shortName": "30 Min."
            },
        },
        value: 1,
        unit: 'minute'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "5 minutes",
                "shortName": "5 min."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "5 Minuten",
                "shortName": "5 Min."
            },
        },
        value: 300,
        unit: 'minute'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "10 minutes",
                "shortName": "10 min."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "10 Minuten",
                "shortName": "10 Min."
            },
        },
        value: 600,
        unit: 'minute'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "15 minutes",
                "shortName": "15 min."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "15 Minuten",
                "shortName": "15 Min."
            },
        },
        value: 900,
        unit: 'minute'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "30 minutes",
                "shortName": "30 min."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "30 Minuten",
                "shortName": "30 Min."
            },
        },
        value: 1800,
        unit: 'minute'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "1 hour",
                "shortName": "1 h"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "1 Stunde",
                "shortName": "1 Std."
            },
        },
        value: 3600,
        unit: 'hour'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "1.5 hours",
                "shortName": "1.5 h"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "1,5 Stunden",
                "shortName": "1,5 Std."
            },
        },
        value: 5400,
        unit: 'hour'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "2 hours",
                "shortName": "2 h"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "2 Stunden",
                "shortName": "2 Std."
            },
        },
        value: 7200,
        unit: 'hour'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "2.5 hours",
                "shortName": "2.5 h"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "2,5 Stunden",
                "shortName": "2,5 Std."
            },
        },
        value: 9000,
        unit: 'hour'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "3 hours",
                "shortName": "3 h"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "3 Stunden",
                "shortName": "3 Std."
            },
        },
        value: 10800,
        unit: 'hour'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "3.5 hours",
                "shortName": "3.5 h"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "3,5 Stunden",
                "shortName": "3,5 Std."
            },
        },
        value: 12600,
        unit: 'hour'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "6 hours",
                "shortName": "6 h"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "6 Stunden",
                "shortName": "6 Std."
            },
        },
        value: 21600,
        unit: 'hour'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "12 hours",
                "shortName": "12 h"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "12 Stunden",
                "shortName": "12 Std."
            },
        },
        value: 43200,
        unit: 'hour'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "1 day",
                "shortName": "1 day"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "1 Tag",
                "shortName": "1 Tag"
            },
        },
        value: 86400,
        unit: 'day'
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "2 days",
                "shortName": "2 days"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "2 Tage",
                "shortName": "2 Tage"
            },
        },
        value: 172800,
        unit: 'day'
    }
]