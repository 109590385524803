<template>
    <v-card flat >
        
        <c-section :title="$t('collection-list.view.title')" :subtitle="$t('collection-list.view.subtitle')"  class="secondary">
            
            <c-item-list-container :value="{ items: collections}" @item-clicked="onItemClicked" :loading="loading">
                <template v-slot:item-list-card-body="props">{{props.item | localized-description($i18n.locale)}}</template>
                <template v-slot:item-list-table-head-additionalColumns><th>Beschreibung</th></template>
                <template v-slot:item-list-table-body-additionalColumns="props"><td>{{props.item | localized-description($i18n.locale)}}</td></template>
            </c-item-list-container>
                        
        </c-section>
        
    </v-card>
</template>

<script>

const MODULE_NAME = 'collectionList';

export default {

    name: 'collection-list',

    props: {
        value: {
            type: Object,
            required: false,
        }        
    },

    inject: ['errorHandlerService'],

    data: () => ({
        selectedTab: 0,
        loading: true
    }),

    methods : {
        async refresh() {
            this.loading = true;
            try {
                await this.$store.dispatch(MODULE_NAME + '/load');
            } catch (error) {
                this.loading = false;
                this.errorHandlerService.handleError(error);
            } finally {
                this.loading = false;
            }
        },

        onItemClicked(event) {
            if (event) {
                this.$router.push({
                    name: 'collectionDocumentsView',
                    params: {
                        collectionId : event.item.collectionId
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to prediction scenario.")
            }
        }
    },

    watch: {
        $route() {
            this.refresh();
        }
    }, 

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        collections() {
            return this.response ? this.response.data : []; 
        },

        collectionsEmpty() {
            return this.collections.length === 0;
        }
    },

    created() {
        this.refresh();
    }
    
}
</script>