import { get } from 'lodash';
import api from '../../../api/smart-factory.api';


export default {

    namespaced: true,

    state: {

        sites: emptySiteReponse()
    },

    mutations: {

        setSites(state, siteReponse) {

            if (siteReponse) {
                state.sites = siteReponse;
            } else {
                state.sites = emptySiteReponse();
            }
        }
    },

    actions: {

        async loadSites(context) {
            
            try {
            
                const response = await api.masterdata.sites.findByFilter('*', 0, 100);
                context.commit('setSites', response);
            
            } catch (error) {
                context.commit('setSites', emptySiteReponse);
                throw error;
            }
        } 
    },

    getters: {
        model(state) {
            return {
                sites: get(state, 'sites.items', [])
            };
        }
    }
}

function emptySiteReponse() {
    return {
        items: []
    }
}