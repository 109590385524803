import api from '../../../api/smart-factory.api';
import { get } from 'lodash';



export default {

    namespaced: true,

    state : {

        shiftEventType: createDefaultShiftEventType(),
    },

    mutations: {

        setShiftEventType(state, setShiftEventType) {

            if (setShiftEventType) {
                state.shiftEventType.tenantId = setShiftEventType.tenantId;
                state.shiftEventType.name = setShiftEventType.name;
                state.shiftEventType.i18n = setShiftEventType.i18n;
                state.shiftEventType.data = setShiftEventType.data;
                state.shiftEventType.active = setShiftEventType.active;
                state.shiftEventType.creationDateTime     = setShiftEventType.creationDateTime;
                state.shiftEventType.creationUser         = setShiftEventType.creationUser;
                state.shiftEventType.modificationDateTime = setShiftEventType.modificationDateTime;
                state.shiftEventType.modificationUser     = setShiftEventType.modificationUser;
            }
            
        }

    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.shiftEventTypes.findById(name);
                context.commit('setShiftEventType', get(response, 'item.shiftEventType', createDefaultShiftEventType()));
            } catch (error) {
                context.commit('shiftEventTypesId', createDefaultShiftEventType());
                throw error;
            }
        },

        async saveData(context) {

            try {
                const payload = get(context.getters.model, 'shiftEventType');
                let response = await api.masterdata.shiftEventTypes.update(payload);
                console.log(JSON.stringify(response))
                console.log(JSON.stringify(payload))
                context.commit('setShiftEventType', get(response, 'item.shiftEventType', createDefaultShiftEventType()));

            } catch (error) {
                console.log(error);
                context.commit('setShiftEventType', createDefaultShiftEventType());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            console.log('+++ updateModel ', JSON.stringify(model, true, 2));
            context.commit('setShiftEventType', get(model, 'shiftEventType', createDefaultShiftEventType()));
        },

        updateShiftEventType(context, shiftEventType) {
            console.log('+++ updateshiftEventType ', JSON.stringify(shiftEventType, true, 2));
            context.commit('setShiftEventType', shiftEventType);
        }
    },

    getters: {
        
        model(state) {
            return {
                shiftEventType: state.shiftEventType
            }
        },
        
    }
}

function createDefaultShiftEventType() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {},
        active: null,
        references: {},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }}
