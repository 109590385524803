<template>
    <v-tabs 
        v-model="selectedTab"
        centered 
        align-with-title
        grow
        color="grey" 
        background-color="grey lighten-3">
        
        <v-tab>{{$t('detection-rules-expression-help.component.tab.fields')}}</v-tab>
        <v-tab>{{$t('detection-rules-expression-help.component.tab.source')}}</v-tab>
        <v-tab>{{$t('detection-rules-expression-help.component.tab.context')}}</v-tab>
        <v-tab>{{$t('detection-rules-expression-help.component.tab.custData')}}</v-tab>
        <v-tab>{{$t('detection-rules-expression-help.component.tab.functions')}}</v-tab>

        <v-tabs-items v-model="selectedTab">

            <!-- Data Fields -->
            <v-tab-item>
                <v-simple-table >
                    <thead>
                        <tr>
                            <th>{{$t('detection-rules-expression-help.component.tab.field')}}</th>
                            <th>{{$t('detection-rules-expression-help.component.tab.datatype')}}</th>
                            <th>{{$t('detection-rules-expression-help.component.tab.note')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><code>$data</code></td>
                            <td>MAP</td>
                            <td>{{$t('detection-rules-expression-help.component.tab.note.data')}}</td>
                        </tr>
                         <tr>
                            <td><code>$data.timeWindow.from</code></td>
                            <td>DATETIME</td>
                            <td>{{$t('detection-rules-expression-help.component.tab.note.data.timewindow.from')}}</td>
                        </tr>
                        <tr>
                            <td><code>$data.timeWindow.until</code></td>
                            <td>DATETIME</td>
                            <td>{{$t('detection-rules-expression-help.component.tab.note.data.timewindow.until')}}</td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('detection-rules-expression-help.component.tab.fields.category.aggregations')}}</td>
                        </tr>
                        <tr v-for="(value,key) of aggregationList" :key="key">
                            <td><code>$data.{{value.attributeName}}.{{value.aggregationOperation}}</code></td>
                            <td>NUMBER</td>
                            <td>{{$t('detection-rules-expression-help.component.tab.fields.' + value.aggregationOperation, {field:value.attributeName})}}</td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('detection-rules-expression-help.component.tab.fields.category.groups')}}</td>
                        </tr>
                        <tr v-for="(value,key) of groupByList" :key="key">
                            <td><code>$data.{{value}}</code></td>
                            <td>{{getAttributeDatatype(value)}}</td>
                            <td>{{$t('detection-rules-expression-help.component.tab.group', {field:value})}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-tab-item> 

            <!-- Source-->
            <v-tab-item>
                <v-simple-table >
                    <thead>
                        <tr>
                            <th>{{$t('detection-rules-expression-help.component.tab.field')}}</th>
                            <th>{{$t('detection-rules-expression-help.component.tab.datatype')}}</th>
                            <th>{{$t('detection-rules-expression-help.component.tab.note')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><code>$source</code></td>
                            <td>MAP</td>
                            <td>{{$t("detection-rules-expression-help.component.tab.source.source")}}</td>
                        </tr>
                         <tr>
                            <td><code>$source.collectionSettings</code></td>
                            <td>MAP</td>
                            <td>{{$t("detection-rules-expression-help.component.tab.source.colllectionSettings")}}</td>
                        </tr>
                        <tr>
                            <td><code>$source.schema</code></td>
                            <td>MAP</td>
                            <td>{{$t("detection-rules-expression-help.component.tab.source.schema")}}</td>
                        </tr>
                        <tr>
                            <td><code>$source.tenant</code></td>
                            <td>MAP</td>
                            <td>{{$t("detection-rules-expression-help.component.tab.source.tenant")}}</td>
                        </tr>
                        <!-- <tr>
                            <td><code>$source.tenant.custData</code></td>
                            <td>MAP</td>
                            <td>{{$t("detection-rules-expression-help.component.tab.source.tenant.custData")}}</td>
                        </tr> -->
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('detection-rules-expression-help.component.tab.fields.category.systemParameters')}}</td>
                        </tr>
                        <tr v-for="parameter of systemParameters" :key="parameter.name">
                            <td><code>$source.tenant.custData.{{parameter.name}}</code></td>
                            <td>STRING</td>
                            <td>{{$t("detection-rules-expression-help.component.tab.source.tenant.custData", {field: parameter.name})}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-tab-item>

            <!-- Context -->
            <v-tab-item>
                <v-simple-table >
                    <thead>
                        <tr>
                            <th>{{$t('detection-rules-expression-help.component.tab.field')}}</th>
                            <th>{{$t('detection-rules-expression-help.component.tab.datatype')}}</th>
                            <th>{{$t('detection-rules-expression-help.component.tab.note')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                         <tr>
                            <td><code>$job.template</code></td>
                            <td>MAP</td>
                            <td>{{$t("detection-rules-expression-help.component.tab.job.template")}}</td>
                        </tr>
                        <tr>
                            <td><code>$job.instance</code></td>
                            <td>MAP</td>
                            <td>{{$t("detection-rules-expression-help.component.tab.job.instance")}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-tab-item>

            <v-tab-item>
                <v-simple-table v-if="custDataExists">
                    <thead>
                        <tr>
                            <th>{{$t('detection-rules-expression-help.component.tab.field')}}</th>
                            <th>{{$t('detection-rules-expression-help.component.tab.datatype')}}</th>
                            <th>{{$t('detection-rules-expression-help.component.tab.note')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                         <tr v-for="(value,key) of custData" :key="value">
                            <td><code>$job.custData.{{key}}</code></td>
                            <td>STRING</td>
                            <td>{{$t("detection-rules-expression-help.component.tab.custData.field", {field: key})}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>

                <v-card-text class="grey lighten-3" v-else>
                    <p />
                    <div class="subtitle-1 text-center">
                    {{$t('detection-rules-expression-help.component.tab.empty')}}
                    </div>
                    <p />
                </v-card-text>
            </v-tab-item>

            <v-tab-item>
                <dxs-expression-functions-help/>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</template>

<script>
import ExpressionFunctionsHelpComponent from '../../help/expression-functions-help.component';

import { get } from 'lodash';

export default {

    name: 'detection-rules-expression-help',

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),
    components: {
      'dxs-expression-functions-help' : ExpressionFunctionsHelpComponent,
    },
    methods: {

        isNumeric(attribute) {
            return (attribute.datatype === 'DECIMAL' || attribute.datatype === 'INTEGER');
        },

        getAttributeDatatype(attributeName) {
            return get(this.attributes, attributeName + '.datatype', "NULL");
        }
    },

    computed: {

        context(){
            return get(this.value, 'response', {});
        },

        collectionSettings(){
            return get(this.context, 'collectionSettings', {});
        },

        schema(){
            return get(this.context, 'schema', {});
        },

        attributes(){
            return get(this.schema, 'attributes', {});
        },
        
        tenant(){
            return get(this.context, 'tenant', {});
        },

        detectionJobTemplate(){
            return get(this.context, 'detectionJobTemplate', {});
        },

        source() {
            return get(this.detectionJobTemplate, 'source', {});
        },

        aggregationList() {
            return get(this.source, 'aggregation', []);
        },

        groupByList() {
            return get(this.source, 'groupBy', []);
        },

        systemParameters() {
            return get(this.value, 'systemParameters.items', [])
        },

        custData() {
            return get(this.value, 'custData', {})
        },

        custDataExists() {
            if(Object.keys(this.custData).length > 0){
                return true;
            }else{
                return false;
            }
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "detection-rules-expression-help.component.tab.fields" : "Field",
        "detection-rules-expression-help.component.tab.source" : "Source",
        "detection-rules-expression-help.component.tab.context" : "Context",
        "detection-rules-expression-help.component.tab.custData" : "Custom Paramaters",
        "detection-rules-expression-help.component.tab.functions" : "Functions",

        "detection-rules-expression-help.component.tab.field" : "Field",
        "detection-rules-expression-help.component.tab.datatype" : "Datatype",
        "detection-rules-expression-help.component.tab.note" : "Note",        
        "detection-rules-expression-help.component.tab.empty" : "No elements are currently present.",

        "detection-rules-expression-help.component.tab.note.data" : "Contains the actual payload for evaluation, i.e. the aggregated values of a given group.",
        "detection-rules-expression-help.component.tab.note.data.timewindow.from" : "The beginning of the time window.",
        "detection-rules-expression-help.component.tab.note.data.timewindow.until" : "The end of the time window.",

        "detection-rules-expression-help.component.tab.fields.MIN" : "The minimum value of all values for the field {field}.",
        "detection-rules-expression-help.component.tab.fields.MAX" : "The maximum value of all values for the field {field}.",
        "detection-rules-expression-help.component.tab.fields.MEDIAN" : "The median value of all values for the field {field}.",
        "detection-rules-expression-help.component.tab.fields.AVERAGE" : "The average value of all values for the field {field}.",
        "detection-rules-expression-help.component.tab.fields.SUM" : "The sum of the value of all values for field {field}.",

        "detection-rules-expression-help.component.tab.group" : "{field}",

        "detection-rules-expression-help.component.tab.source.source" : "Contains information of the source collection.",
        "detection-rules-expression-help.component.tab.source.collectionSettings" : "The definition of the source collection.",
        "detection-rules-expression-help.component.tab.source.schema" : "The definition of the source collection's schema.",
        "detection-rules-expression-help.component.tab.source.tenant" : "Contains information about the current tenant.",
        "detection-rules-expression-help.component.tab.source.tenant.custData" : "Value of custom parameter: {field}",

        "detection-rules-expression-help.component.tab.job.instance" : "Represents the Job Instance information.",
        "detection-rules-expression-help.component.tab.job.template" : "Represents the Job Template information.",

        "detection-rules-expression-help.component.tab.custData.field" : "{field}",

        "detection-rules-expression-help.component.tab.fields.category.aggregations" : "Aggregations",
        "detection-rules-expression-help.component.tab.fields.category.groups" : "Groups",
        "detection-rules-expression-help.component.tab.fields.category.systemParameters" : "System Parameters"

    },

    "de" : {
        "detection-rules-expression-help.component.tab.fields" : "Felder",
        "detection-rules-expression-help.component.tab.source" : "Quelle",
        "detection-rules-expression-help.component.tab.context" : "Kontext",
        "detection-rules-expression-help.component.tab.custData" : "Kundenspezifische Parameter",
        "detection-rules-expression-help.component.tab.functions" : "Funktionen",

        "detection-rules-expression-help.component.tab.field" : "Feld",
        "detection-rules-expression-help.component.tab.datatype" : "Datentyp",
        "detection-rules-expression-help.component.tab.note" : "Erläuterung",
        "detection-rules-expression-help.component.tab.empty" : "Momentan sind keine Elemente vorhanden.",

        "detection-rules-expression-help.component.tab.note.data" : "Enthält die payload zur (z.B.) Aggregation von Werten einer gegebenen Gruppe.",
        "detection-rules-expression-help.component.tab.note.data.timewindow.from" : "Der Anfang des Zeitfensters.",
        "detection-rules-expression-help.component.tab.note.data.timewindow.until" : "Das Ende des Zeitfensters",

        "detection-rules-expression-help.component.tab.fields.MIN" : "Der minimale Wert aller Werte für das Feld {field}.",
        "detection-rules-expression-help.component.tab.fields.MAX" : "Der maximale Wert aller Werte für das Feld {field}.",
        "detection-rules-expression-help.component.tab.fields.MEDIAN" : "Der median Wert aller Werte für das Feld {field}.",
        "detection-rules-expression-help.component.tab.fields.AVERAGE" : "Der Durchschnittswert aller Werte für das Feld {field}.",
        "detection-rules-expression-help.component.tab.fields.SUM" : "Die Summe des Wertes aller Werte für das Feld {field}.",

        "detection-rules-expression-help.component.tab.group" : "{field}",

        "detection-rules-expression-help.component.tab.source.source" : "Enthält Informationen zum Quelldatenbereich",
        "detection-rules-expression-help.component.tab.source.collectionSettings" : "Die Definition zum Quelldatenbereich.",
        "detection-rules-expression-help.component.tab.source.schema" : "Die Definition des Schemas des Quelldatenbereichs.",
        "detection-rules-expression-help.component.tab.source.tenant" : "Enthält Informationen zum aktuellen Mandanten.",
        "detection-rules-expression-help.component.tab.source.tenant.custData" : "Wert des kundenspezifischen Parameter: {field}",

        "detection-rules-expression-help.component.tab.job.instance" : "Stellt die Informationen zur Jobinstanz dar.",
        "detection-rules-expression-help.component.tab.job.template" : "Stellt die Informationen zur Jobvorlage dar.",

        "detection-rules-expression-help.component.tab.custData.field" : "{field}",

        "detection-rules-expression-help.component.tab.fields.category.aggregations" : "Aggregationen",
        "detection-rules-expression-help.component.tab.fields.category.groups" : "Gruppen",
        "detection-rules-expression-help.component.tab.fields.category.systemParameters" : "Systemparameter"
    }
}
</i18n>

<style>

</style>