import api from '@/store/api';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';

export default {

    namespaced : true,

    state: {

        items: [],

        meta: {},

        query: {

            filter: {

                filterQuery: '*',

                filterQueryLanguage: 'SIMPLE',

                facetFilters: [],

                rangeFilters: []
            },

            sort: {
                sortCriteria: [{name: "publishDate", ordering: "DESC"}]
            },

            pagination: {
                from: 0,
                size: 50,
                searchAfter: []
            },
        },

        viewSettings: {

            attributes: []
        },

        pagination: {
            from: 0,
            size: 50,
            searchAfter: []
        },

        facets: {},

        links: {},
    },

    mutations: {

        setViewSettings(state, viewSettings) {

            if (viewSettings) {

                if (viewSettings.attributes) {
                    state.viewSettings.attributes = viewSettings.attributes;
                }
            }  
        },

        setPagination(state, pagination) {
            state.pagination = pagination;
        },

        setResponse(state, response) {


            state.items = response.items ? response.items : [];
            state.meta  = response.meta ? response.meta : {};
            
            state.facets     = response.facets ? response.facets : {};
            state.links      = response.links ? response.links : {};
            state.pagination = response.pagination ? response.pagination : {from: 0, size: 50, searchAfter: []};
            
            state.query.filter     = (response.query && response.query.filter) ? response.query.filter : { filterQuery: '*', filterQueryLanguage: 'SIMPLE', facetFilters: [], rangeFilters: [] };
            state.query.sort       = (response.query && response.query.sort) ? response.query.sort : { sortCriteria: [] },
            state.query.pagination = (response.query && response.query.pagination) ? response.query.pagination : { from: 0, size: 50, searchAfter: [] };

            // DXS-99: Reset the view settings if an empty response has been received.
            // It is necessary to do so in order to support the reusing of components by vue router.
            // Otherwise the view settings will not change when navigating.
            if (isEmpty(response)) { // Always remember: Empty objects are NOT falsy... thus use the lodash is empty method for a safe check.
                state.viewSettings.attributes = [];   
            }

            // If no explicit view settings have been set, 
            // compute them on the fly.
            

            if (isEmpty(state.viewSettings.attributes)) {
                forOwn(state.meta.attributes, attribute => state.viewSettings.attributes.push({
                    value: attribute,
                    display: !!get(attribute, 'clientSettings.table.display', true)
                }));
                

                state.viewSettings.attributes.sort( (first, second) => first.value.ordering - second.value.ordering);
            }
            
        },

        appendResponse(state, response) {
        
            // In order to play it safe, make sure there is an actual instance.
            if (!state.items) {
                state.items = [];
            }

            // Push additional items
            // Note: Due to reasons with vuex it is necessary to push each item separately inside of a loop
            // It is not sufficient to push the array as is.
            for (let item of response.items) {
                state.items.push(item);
            }
            

            // Of course we also need to update the pagination
            state.pagination = response.pagination;
        },

        setFilter(state, filter) {

            state.query.filter = filter;
        },

        setFilterQuery(state, { filterQuery, filterQueryLanguage}) {

            //console.log(">>>" + JSON.stringify(filterQuery) + " + " + JSON.stringify(filterQueryLanguage))
            state.query.filter.filterQuery = filterQuery ? filterQuery : '*';
            state.query.filter.filterQueryLanguage = filterQueryLanguage ? filterQueryLanguage  : 'SIMPLE';
        },

        setFacetFilters(state, filters) {
            
            state.query.filter.facetFilters = filters.facetFilters;
            state.query.filter.rangeFilters = filters.rangeFilters;
        },

        setSort(state, sort) {

            state.query.sort = sort;
        }

        

    },

    actions: {

        /**
         * Resets the filter and sort settings.
         * @param {*} context 
         */
            async resetView(context) {

            // Adding an empty response will lead to resetting the dialog back to its original state,
            // esp. discarding all filters, etc.
            context.commit('setResponse', {})
            
        },

        async updateViewSettings(context, viewSettings) {

            context.commit('setViewSettings', viewSettings);
        },

        async loadData(context) {

            // Reset the pagination... otherwise sooner or later we will run out of pages to display
            let pagination = {
                from: 0,
                searchAfter: [],
                size: 50
            };
            context.commit('setPagination', pagination);
            const query = context.getters.query;
            
                
            return api.collections.notifications.findByQuery(query).then(response => context.commit('setResponse', response));
        },

        async exportDocument(context, {format}) {

            const query = context.getters.query;
            const body = {
                filter: query.filter,
                sort: query.sort,
                format: format
            }

            return api.collections.notifications.exportDocument("Notification", body).then(() => {});
        },

        async loadMore(context) {

            // Please note that load more will add another page to the existing set of results.
            const query = context.getters.query;

            // We need to tweak the query in order to load the next page.
            query.pagination.searchAfter = context.state.pagination.searchAfter; 
            query.pagination.size = context.state.pagination.size;
            
            // DXS-95: Add the from - parameter: This is not required on subsequent calls by elastic, 
            // but causes trouble in the Backend if missing.
            if (!query.pagination.from) query.pagination.from = 0;

            //console.log(">>> LOAD MORE : " + JSON.stringify(query))
                
            return api.collections.notifications.findByQuery(query).then(response => context.commit('appendResponse', response));
        },


        async updateFilter(context, {filter}) {

            context.commit('setFilter', filter);

            return context.dispatch('loadData');
        },

        async updateFacetFilters(context, {facetFilters}) {
            context.commit('setFacetFilters', facetFilters);

            return context.dispatch('loadData');
        },

        async updateFilterQuery(context, {filterQuery}) {

            //console.log("+++ " + JSON.stringify(filterQuery));
            context.commit('setFilterQuery', filterQuery);


            return context.dispatch('loadData');
        },

        

        async resetFilter(context) {
            
            const filter = {
                filterExpression: '*',
                facetFilters: [],
                rangeFilters: []
            };

            return context.dispatch('setFilter', filter);
        },

        async updateSort(context, {sort}) {

            context.commit('setSort', sort);

            return context.dispatch('loadData');
        },

        async resetSort(context) {
            
            const sort = {
                sortCriteria: []
            };

            return context.dispatch('setSort', sort);
        },
    },

    getters: {

        query(state) {
            
            return { 
                filter: state.query.filter,
                sort: state.query.sort,
                pagination: state.pagination,
                queryTimeoutMiliseconds: 25 * 1000 // 25 seconds
            }
        },

        filter(state) {
            
            return state.query.filter;  
        },

        sort(state) {
            return state.query.sort;
        },

        response(state) {

            return {
                items        : state.items,
                meta         : state.meta,
                filter       : state.query.filter,
                viewSettings : state.viewSettings,
                sort         : state.query.sort,
                pagination   : state.pagination,
                facets       : state.facets,
                links        : state.links
            }
        }
    }


}