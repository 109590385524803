import api from '@/store/api';

export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(), 

        reindexResponse : null,

        filter: getDefaultFilter()
    },

    mutations: {

        setResponse(state, response) {

            if (response) {

                let items = [];
                for (let item of response.items) {
                    items.push(item);
                }

                state.response = {
                    items: items,
                    pagination: response.pagination
                };
            }

        },



        setFilter(state, filter) {

            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter()
            }
        }
    },

    actions: {

        async loadResponse(context, {from, pageSize}) {

            try {
                let response = await api.catalog.systemCollectionSettings.findAll(context.state.filter, from, pageSize);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        },
    },

    getters: {

        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        }
    }
}

function getDefaultResponse() {

    return {
        items: []
    };
}

function getDefaultFilter() {
    return '*';
}