import api from '@/store/api';
import cloneDeep from 'lodash/cloneDeep';
import { set } from 'lodash';

import Configuration from '@/utils/configuration';

const TENANT_ID = Configuration.value('TENANT_ID');


export default {

    namespaced: true,

    state: {
        response: emptyResponse(),
        schemas: [],
    },

    mutations: {

        setI18N(state, i18n) {
            if (i18n) {
                state.response.data.collectionSettings.i18n = i18n;
            }
        },

        setParameter(state, parameter) {
            if (parameter) {
                state.response.data.collectionSettings.parameter = parameter;
            }

            // Unfortunately, a new object reference is necessary here, otherwise any change of "Parameter" cannot be registered.
            state.response.data.collectionSettings = cloneDeep(state.response.data.collectionSettings);
        },

        setCustData(state, custData) {
            if (custData) {
                state.response.data.collectionSettings.custData = custData;
            }

            // Unfortunately, a new object reference is necessary here, otherwise any change of "CustData" cannot be registered.
            state.response.data.collectionSettings = cloneDeep(state.response.data.collectionSettings);
        },

        setDetailDialogUrlTemplate(state, detailDialogUrlTemplate) {
            if (detailDialogUrlTemplate) {
                state.response.data.collectionSettings.clientSettings.detailDialogUrlTemplate = detailDialogUrlTemplate;
            } else {
                state.response.data.collectionSettings.clientSettings.detailDialogUrlTemplate = '/collections/${collectionId}/documents/${documentId}';
            }
        },

        setListDialogUrlTemplate(state, listDialogUrlTemplate) {
            if (listDialogUrlTemplate) {
                state.response.data.collectionSettings.clientSettings.listDialogUrlTemplate = listDialogUrlTemplate;
            } else {
                state.response.data.collectionSettings.clientSettings.listDialogUrlTemplate = '/collections/${collectionId}/documents';
            }
        },

        setTimeIntervals(state, timeIntervals) {
            if (timeIntervals) {
                state.response.data.collectionSettings.clientSettings.distribution.timeIntervals = timeIntervals;
            }
        },

        setEmbeddedCollections(state, embeddedCollections) {
            if (embeddedCollections) {
                state.response.data.collectionSettings.embeddedCollections = embeddedCollections;
            }

            // Unfortunately, a new object reference is necessary here, otherwise any change of "EmbeddedCollections" cannot be registered.
            state.response.data.collectionSettings = cloneDeep(state.response.data.collectionSettings);
        },

        setResponse(state, response) {

            // Push additional data into the response
            if (response) {

                state.response.data.collectionSettings = response.collectionSettings;
                state.response.data.schema = response.schema;

                if (!response.collectionSettings['parameter']) {
                    state.response.data.collectionSettings.parameter = {};
                }

                if (!response.collectionSettings['custData']) {
                    state.response.data.collectionSettings.custData = {};
                }

                if (!response.collectionSettings['embeddedCollections']) {
                    state.response.data.collectionSettings.embeddedCollections = [];
                }
            
            } else {
                state.response = emptyResponse();
            }
        },

        setSchemas(state, schemas){

            if (schemas) {
                state.schemas = schemas;
            } else  {
                state.schemas = [];
            }
        },

        setDocumentChangeLogging(state, logging) {
            set(state, 'response.data.collectionSettings.loggingSettings.documentChangeLogging', logging);
        },

        setQueryLogging(state, logging) {
            set(state, 'response.data.collectionSettings.loggingSettings.queryLogging', logging);
        }
    },

    actions: {

        async loadResponse(context, {name}) {

            try {

                let response = await api.catalog.collectionSettings.findById(name);
                context.commit('setResponse', response);

                // Schemas are required for editing embedded collections.
                let schemas = await api.catalog.schemas.findAll();
                context.commit('setSchemas', schemas.items ? schemas.items : []);
                
            } catch (error) {
                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', emptyResponse());
                context.commit('setSchemas', []);
                return Promise.reject(error);
            }
        },

        async update(context, {name}) {
            try {
                let request = context.getters.request;
                //console.log(">>> DEBUG: " + JSON.stringify(request));
                let response = await api.catalog.collectionSettings.update(name, request);
                context.commit('setResponse', response);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateI18N(context, {i18n}) {
            try {
                context.commit('setI18N', i18n);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateParameter(context, {parameter}) {
            try {
                context.commit('setParameter', parameter);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateCustData(context, {custData}) {
            try {
                context.commit('setCustData', custData);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateEmbeddedCollections(context, {embeddedCollections}) {
            try {
                context.commit('setEmbeddedCollections', embeddedCollections);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateDetailDialogUrlTemplate(context, {detailDialogUrlTemplate}) {
            try {
                context.commit('setDetailDialogUrlTemplate', detailDialogUrlTemplate);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateListDialogUrlTemplate(context, {listDialogUrlTemplate}) {
            try {
                context.commit('setListDialogUrlTemplate', listDialogUrlTemplate);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTimeIntervals(context, {timeIntervals}){
            try {
                context.commit('setTimeIntervals', timeIntervals);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateDocumentChangeLogging(context, logging) {
            try {
                context.commit('setDocumentChangeLogging', logging);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateQueryLogging(context, logging) {
            try {
                context.commit('setQueryLogging', logging);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },
    },

    getters: {
        request(state) {
            let payload = state.response.data.collectionSettings;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        response(state) {
            return state.response;
        },

        collectionSettings(state) {
            return state.response.data.collectionSettings;
        },

        schema(state) {
            return state.response.data.schema;
        },

        parameter(state) {
            return state.response.data.collectionSettings.parameter;
        },

        dataIngestion(state) {
            return state.response.data.collectionSettings.dataIngestion;
        },
 
        custData(state) {
            return state.response.data.collectionSettings.custData;
        },

        i18n(state) {
            return state.response.data.collectionSettings.i18n;
        },

        schemas(state) {
            return state.schemas ? state.schemas : [];
        },

        timeIntervals(state) {
            return state.response.data.collectionSettings.clientSettings.distribution.timeIntervals;
        }
    }
}

function emptyResponse() {

    return {
        
        data: {

            collectionSettings: {

                name: null,
                partitionIdTemplate: null,
                idTemplate: null,
                i18n: {},
                custData: {},
                clientSettings: {
                    listDialogUrlTemplate: "/collections/${collectionId}/documents",
                    detailDialogUrlTemplate: "/collections/${collectionId}/documents/${documentId}",
                    distribution: {
                        timeIntervals: []
                    }
                },
                
                // DXS-935 Added new default values for the ingestion topics.
                dataIngestion: {
                    deadLetter: {
                        topic: `dxs-${TENANT_ID}-core-deadletter-in`
                    },
                    document: {
                        topic: `dxs-${TENANT_ID}-core-document-in`
                    },
                    documentRecord: {
                        topic: `dxs-${TENANT_ID}-core-documentRecord-in`
                    }
                },
                
                loggingSettings: {
                    documentChangeLogging: "TENANT",
                    queryLogging: "TENANT"
                },
                embeddedCollections: [],
            },

            schema: {

                i18n: {},
                attributes: {}
            },
        }

    };
}