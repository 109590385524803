var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"secondary pt-10 pb-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('c-base-dialog',{attrs:{"value":_vm.manufacturerplants,"title":_vm.$t('manufacturerplants-wizard.view.title'),"subtitle":_vm.$t('manufacturerplants-wizard.view.subtitle'),"color":"secondary"},on:{"dialog-saved":_vm.save,"dialog-canceled":_vm.cancel},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('c-name-input',{attrs:{"filled":""},model:{value:(props.item.name),callback:function ($$v) {_vm.$set(props.item, "name", $$v)},expression:"props.item.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('c-item-selector',{attrs:{"items":_vm.manufacturer.items,"return-object":false,"filled":"","chips":false,"rules":[  v => v && !!v.trim()  || _vm.$t('manufacturer-wizard.view.fields.schema.validation.notEmpty') ],"label":_vm.$t('manufacturerplant-wizard.view.subheader.manufacturer')},model:{value:(_vm.manufacturerId),callback:function ($$v) {_vm.manufacturerId=$$v},expression:"manufacturerId"}},[_vm._v("Manufacturers")])],1)],1),_c('divider'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.streetLabel,"rules":[ 
                                      v => v && !!v.trim()  || _vm.$t('name-input.component.validation.notEmpty')
                                      
                                  ]},model:{value:(props.item.data.street),callback:function ($$v) {_vm.$set(props.item.data, "street", $$v)},expression:"props.item.data.street"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.buildingLabel,"rules":[ 
                                      v => v && !!v.trim()  || _vm.$t('name-input.component.validation.notEmpty')
                                      
                                  ]},model:{value:(props.item.data.building),callback:function ($$v) {_vm.$set(props.item.data, "building", $$v)},expression:"props.item.data.building"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.zipLabel,"rules":[ 
                                      v => v && !!v.trim()  || _vm.$t('name-input.component.validation.notEmpty')
                                      
                                  ]},model:{value:(props.item.data.zipCode),callback:function ($$v) {_vm.$set(props.item.data, "zipCode", $$v)},expression:"props.item.data.zipCode"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.countryLabel,"rules":[ 
                                      v => v && !!v.trim()  || _vm.$t('name-input.component.validation.notEmpty')
                                  ]},model:{value:(props.item.data.country),callback:function ($$v) {_vm.$set(props.item.data, "country", $$v)},expression:"props.item.data.country"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.provinceLabel,"rules":[ 
                                      v => v && !!v.trim()  || _vm.$t('name-input.component.validation.notEmpty')
                                      
                                  ]},model:{value:(props.item.data.province),callback:function ($$v) {_vm.$set(props.item.data, "province", $$v)},expression:"props.item.data.province"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"filled":"","label":_vm.cityLabel,"rules":[ 
                                      v => v && !!v.trim()  || _vm.$t('name-input.component.validation.notEmpty')
                                  ]},model:{value:(props.item.data.city),callback:function ($$v) {_vm.$set(props.item.data, "city", $$v)},expression:"props.item.data.city"}})],1)],1)],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }