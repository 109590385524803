import api from '@/store/api';
import { get } from 'lodash';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {

        response: {
            
            items: [],

            collectionSettings: {},

            schema: {},

            filter: {

            },

            pagination: {
                searchAfter: [],
                size: 0,
                totalItemCount: 0
            }
        },

        filter: {

            filterQuery: '*',

            filterQueryLanguage: 'SIMPLE',

            facetFilters: [],

            rangeFilters: []
        },

        
    },

    mutations: {

        setResponse(state, response) {

            // Push additional data into the response. 
            // Be aware that the response might be undefined or empty.
            if (response) {

                state.response.items =  response.items ? response.items : [];
                state.response.collectionSettings = response.collectionSettings ? response.collectionSettings : {};
                state.response.schema = response.schema ? response.schema : {};
                state.response.filter = response.filter ? response.filter : {};
                state.response.pagination = response.pagination ? response.pagination : {
                    searchAfter: [],
                    size: 0,
                    totalItemCount: 0
                }
            }
        },

        appendItem(state, item) {
            
            if (item) {
                
                // Initialize if not set.
                if (!state.response.items) {
                    state.response.items = [];
                }

                state.response.items.push(item);
            }
        },

        setItems(state, items) {
            set(state, 'response.items', items);
        },

        setFilterQuery(state, { filterQuery, filterQueryLanguage}) {
            state.filter.filterQuery = filterQuery ? filterQuery : '*';
            state.filter.filterQueryLanguage = filterQueryLanguage ? filterQueryLanguage  : 'SIMPLE';
        },

        setCollectionSettings(state, collectionSettings) {
            state.response.collectionSettings = collectionSettings ? collectionSettings : {}
        },

        setSchema(state, schema) {
            state.response.schema = schema ? schema : { attributes: {}};
        },

    },

    actions: {

        async resetView(context) {

            // Adding an empty response will lead to resetting the dialog back to its original state,
            // esp. discarding all filters, etc.
            context.commit('setResponse', {});
            
        },

        async resetItems(context) {
            context.commit('setItems', []);
        },

        async initData(context, {collectionId}) {

            
            const response = await api.collections.findById(collectionId);
            context.commit('setCollectionSettings', response ? response.collectionSettings : {});
            context.commit('setSchema', response ? response.schema : {});

        },

        async loadItem(context, {collectionId, attributeName}) {
            
            try {

                let payload = {
                    filter : context.getters.filter,
                    attributes: [attributeName]
                }
    
                let response = await api.collections.stats.getCollectionStatistics(collectionId, payload);

                const items = get(response, 'items', []);
                for (const item of items) {
                    context.commit('appendItem', item);
                }
                
            } catch (error) {                
                // Rethrow error
                return Promise.reject(error);
            }
        },


        async updateFilterQuery(context, {filterQuery}) {
            context.commit('setFilterQuery', filterQuery);
        },
    },

    getters: {

        response(state) {
            return state.response 
                 ? state.response 
                 : { items: [], collectionSettings: {}, schema: {}, filter: {} };
        },

        filter(state) {
            return state.filter;
        }
    }
}