import api from '@/store/api';
import { get, isEmpty} from 'lodash';
export default {

    namespaced: true,

    state: {
        meta: {},
        metrics: [],
        collectionSettings: null,
        schema: null,
        query: {
            filter: {
                filterQuery  : '*',
                filterQueryLanguage: 'SIMPLE',
                facetFilters : [],
                rangeFilters : []
            },
            sort: {
                sortCriteria: []
            }
        },
        pagination: {
            from: 0,
            size: 5,
            searchAfter: []
        },
        form: {
            measure: null,
            timeDimension: null,
            groupAttributes: [],
            isValid: false,
            sent: false
        },
        facets: {},
        links: {},
        viewSettings: {
            attributes: []
        },
        lastRequest: {
            measureAttribute: null,
            timeDimensionAttribute: null,
            groupAttributes: null,
            filter: null,
            pagination: null
        }
    },

    mutations: {
        appendResponse(state, response) {
        
            // In order to play it safe, make sure there is an actual instance.
            if (!state.metrics) {
                state.metrics = [];
            }
            // Push additional items
            // Note: Due to reasons with vuex it is necessary to push each item separately inside of a loop
            // It is not sufficient to push the array as is.
            for (let item of response.items) {
                state.metrics.items.push(item);
            }
            // Of course we also need to update the pagination
            state.pagination = response.pagination;
        },
        setCollectionSettings(state, response) {
            state.collectionSettings = get(response, 'collectionSettings', {});
            state.schema = get(response, 'schema', {});
        },
        setMeasure(state, measure) {
            state.form.measure = measure;
            state.form.isValid = !isEmpty(state.form.measure) && !isEmpty(state.form.timeDimension);
        },
        setTimeDimension(state, timeDimension) {
            state.form.timeDimension = timeDimension;
            state.form.isValid = !isEmpty(state.form.measure) && !isEmpty(state.form.timeDimension);
        },
        setGroupAttributes(state, groupAttributes) {
            state.form.groupAttributes = groupAttributes;
        },
        setFilterQuery(state, { filterQuery, filterQueryLanguage}) {
            state.query.filter.filterQuery = filterQuery ? filterQuery : '*';
            state.query.filter.filterQueryLanguage = filterQueryLanguage ? filterQueryLanguage  : 'SIMPLE';
        },
        setFormSent(state, sent) {
            state.form.sent = sent;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setResponse(state, response) {

            state.metrics = response;
            state.pagination = response.pagination;
        },
        setFacetFilters(state, filters) {
            
            state.query.filter.facetFilters = filters.facetFilters;
            state.query.filter.rangeFilters = filters.rangeFilters;
        },
        clear(state){
            state.meta = {},
            state.metrics = [],
            state.collectionSettings = null,
            state.schema = null,
            state.query = {
                filter: {
                    filterQuery  : '*',
                    filterQueryLanguage: 'SIMPLE',
                    facetFilters : [],
                    rangeFilters : []
                },
                sort: {
                    sortCriteria: []
                }
            },
            state.pagination = {
                from: 0,
                size: 5,
                searchAfter: []
            },
            state.form = {
                measure: null,
                timeDimension: null,
                groupAttributes: [],
                isValid: false,
                sent: false
            },
            state.facets = {},
            state.links = {},
            state.viewSettings = {
                attributes: []
            },
            state.lastRequest = {
                measureAttribute: null,
                timeDimensionAttribute: null,
                groupAttributes: null,
                filter: null,
                pagination: null
            }
        }

    },

    actions: {

        async loadMetricData(context, {collectionId}) {
            
            // Reset the pagination... otherwise sooner or later we will run out of pages to display
            let pagination = {
                from: 0,
                searchAfter: [],
                size: 5
            };
            context.commit('setPagination', pagination);
            const request = context.getters.request;
            // refresh lastRequest seperately, so that old request stays the same when changing measures before loading new data
            context.state.lastRequest.measureAttribute = request.measureAttribute;
            context.state.lastRequest.timeDimensionAttribute = request.timeDimensionAttribute;
            context.state.lastRequest.groupAttributes = request.groupAttributes;
            context.state.lastRequest.filter = request.filter;
            context.state.lastRequest.pagination = request.pagination;
            
            try {
                try{
                let response = await api.collections.metrics.getMetrics(collectionId, request);
                // console.log("Response: " + JSON.stringify(response))
                context.commit('setResponse', response);
                }finally{
                    context.commit('setFormSent', true);
                }

            } catch (error) {

                console.log("An error has occured" + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', []);
                return Promise.reject(error);
              }
        },
        async loadMore(context, {collectionId}) {

            // Please note that load more will add another page to the existing set of results.
            const lastRequest = context.state.lastRequest

            // We need to tweak the request in order to load the next page.
            lastRequest.pagination.searchAfter = context.state.pagination.searchAfter; 
            lastRequest.pagination.size = context.state.pagination.size;
            
            // DXS-95: Add the from - parameter: This is not required on subsequent calls by elastic, 
            // but causes trouble in the Backend if missing.
            if (!lastRequest.pagination.from) lastRequest.pagination.from = 0;

            //console.log(">>> LOAD MORE : " + JSON.stringify(request))
                
            return api.collections.metrics.getMetrics(collectionId, lastRequest).then(response => context.commit('appendResponse', response));
        },

        async updateFacetFilters(context, {collectionId, facetFilters}) {
            context.commit('setFacetFilters', facetFilters);

            return context.dispatch('loadMetricData', {collectionId});
        },
        async loadCollectionSettings(context, { collectionId }) {
            return api.collections.findById(collectionId).then(response => {
                context.commit('setCollectionSettings', response);
            });
        },
        async clearMetrics(context) {
            context.commit('clear');
        },
        async updateFilterQuery(context, {collectionId, filterQuery}) {
            context.commit('setFilterQuery', filterQuery);
            return context.dispatch('loadData', {collectionId: collectionId});
        },
    },

    getters: {

        response(state) {
            return state.response ? state.response : [];
        },
        responseItems(state) {
            return get(state.metrics, "pagination.totalItemCount", 0);
        },
        schema(state) {
            return state.schema;
        },
        collectionSettings(state){
            return state.collectionSettings;
        },
        measure(state) {
            return state.form.measure;
        },
        timeDimension(state){
            return state.form.timeDimension;
        },
        groupAttributes(state){
            return state.form.groupAttributes;
        },
        formValid(state) {
            return state.form.isValid;
        },
        metrics(state) {
            return state.metrics;
        },
        lastRequest(state) {
            return state.lastRequest;
        },
        formSent(state) {
            return state.form.sent;
        },
        filter(state) {
            
            return state.query.filter;  
        },
        query(state) {
            
            return { 
                filter: state.query.filter,
                sort: state.query.sort,
                pagination: state.pagination,
                queryTimeoutMiliseconds: 25 * 1000 // 25 seconds
            }
        },
        request(state) {
            const measure = get(state.form, 'measure', null);
            const timeDimensionAttribute = get(state.form, 'timeDimension', null);
            const groupAttributes = get(state.form, 'groupAttributes');
            const filter = state.query.filter;
            const pagination = state.pagination;
            console.log("measure: " + get(measure, "name", " "))
            console.log("time dimension: " + get(timeDimensionAttribute, "name", " "))
            console.log("group: " + groupAttributes)
            console.log("pagination: " + pagination)
            return {
                // "queryTimeoutMiliseconds": queryTimeoutMiliseconds,
                "measureAttribute": get(measure, "name", " "),
                "timeDimensionAttribute":get(timeDimensionAttribute, "name", " "),
                "groupAttributes": groupAttributes,
                "filter": filter,
                "pagination": pagination
           }
        },
    }
}