import api from '@/store/api';

export default {

    namespaced: true,

    state: {

        response: {

            document: {},

            collectionSettings: {},

            schema: {}
        },
    },

    mutations: {

        setResponse(state, response) {

            // Push additional data into the response
            if (response) {

                state.response = response;
            
            } else {
                state.respone = {
                    collectionSettings: {},
                    schema: {},
                    document: {}
                }
            }
        },

    },

    actions: {

        async loadData(context, {collectionId, documentId}) {

            try {

                let response = await api.collections.documents.findById(collectionId, documentId);
                context.commit('setResponse', response);
                
            } catch (error) {

                console.log("An error has occured" + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', {collectionSettings: {}, schema: {}} );
              }
        },

        async deleteDocument(context, {collectionId, documentId}) {

            try {

                await api.collections.documents.deleteById(collectionId, documentId);
                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', {collectionSettings: {}, schema: {}} );
                
            } catch (error) {

                console.log("An error has occured" + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', {collectionSettings: {}, schema: {}} );
              }
        },
    },

    getters: {

        response(state) {
            return state.response;
        },
    }
}