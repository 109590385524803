import api from '@/store/api';
import { get } from 'lodash';
export default {

    namespaced: true,

    state: {

        response: emptyResponse(),
        
        collectionSettingsToReingest: [],

        //oldAttributes needed to compare the computed properties of the attributes.
        oldAttributes: {}

    },

    mutations: {

        setResponse(state, response) {

            // Push additional data into the response
            if (response) {

                state.response = response;
            
            } else {
                state.response = {

                    data: {

                        schema: {
        
                            name: null,
                            attributes: {},
                            i18n: {}
                        }
                    }
                    
                }
            }
        },

        setName(state, name) {
            if (name) {
                state.response.data.schema.name = name;
            }
        },

        setI18N(state, i18n) {
            if (i18n) {
                state.response.data.schema.i18n = i18n;
            }
        },

        setAttributes(state, attributes) {
            if (attributes) {
                state.response.data.schema.attributes = attributes;
            }
        },

        setcollectionSettingsToReingest(state, collectionSettingsToReingest){
            if(collectionSettingsToReingest){
                state.collectionSettingsToReingest = collectionSettingsToReingest;
            }
        },
        
        setOldAttributes(state, oldAttributes){
            if(oldAttributes){
                state.oldAttributes = oldAttributes;
            }
        }


    },

    actions: {

        /**
         * Resets the response object to empty default values. 
         * @param {*} context 
         */
        async resetResponse(context) {

            context.commit('setResponse', emptyResponse())
        },

        async loadResponse(context, {name}) {

            try {
                
                let response = await api.catalog.schemas.findById(name);
                context.commit('setOldAttributes', response.data.schema.attributes);
                context.commit('setResponse', response);
                
            } catch (error) {

                console.log("An error has occured" + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', emptyResponse());
                return Promise.reject(error);
              }
        },

        async create(context) {

            try {
                
                let request = context.getters.request;
                let response = await api.catalog.schemas.create(request);
                context.commit('setResponse', response);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async update(context, {name}) {

            try {
                
                let request = context.getters.request;
                let response = await api.catalog.schemas.update(name, request);
                context.commit('setOldAttributes', request.attributes);
                context.commit('setResponse', response);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async searchCollectionSettings(context, {schemaName}){
            try {
                let allCollectionSettings = await api.catalog.findAllCollectionSettings()
                let collectionSettingsToReingest = [];
                for(let collectionSetting of allCollectionSettings){
                    let name = get(collectionSetting, 'schema.name', "");
                    if(name === schemaName){
                        let collectionId = get(collectionSetting, 'collectionSettings.collectionId')
                        collectionSettingsToReingest.push(collectionId);
                    }
                }
                context.commit('setcollectionSettingsToReingest', collectionSettingsToReingest);
            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async updateName(context, {name}) {
            
            try {
                context.commit('setName', name);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateAttributes(context, {attributes}) {

            try {
                context.commit('setAttributes', attributes);
            } catch (error) {
                return Promise.reject(error);
            }
            
        },

        async updateI18N(context, {i18n}) {

            try {
                context.commit('setI18N', i18n);
            } catch (error) {
                return Promise.reject(error);
            }
            
        }
    },

    getters: {

        
        request(state) {
            return {

                name: state.response.data.schema.name,
                indexName: state.response.data.schema.name,
                attributes: state.response.data.schema.attributes,
                i18n: state.response.data.schema.i18n,  
                creationDateTime: null,
                creationUser: null,
                modificationDateTime: null,
                modificationUser: null
            };
        },

        response(state) {
            return state.response;
        },

        collectionSettingsToReingest(state) {
            return state.collectionSettingsToReingest;
        },

        oldAttributes(state) {
            return state.oldAttributes;
        }
    }
}

function emptyResponse() {
    return {
        
        data: {

            schema: {

                name: null,
                attributes: {},
                i18n: {}
            }
        }
    }
}