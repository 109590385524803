export default {


    COLLECTIONS_READ: 'amentis-dxs-collections-read',
    COLLECTIONS_EXPORT: 'amentis-dxs-collections-export',
    COLLECTIONS_UPLOAD: 'amentis-dxs-collections-upload',
    COLLECTIONS_CHANGELOG_READ: 'amentis-dxs-collections-changelog-read',
    COLLECTIONS_CHANGELOG_RESTORE: 'amentis-dxs-collections-changelog-restore',

    SYSTEM_LOGS_READ: 'amentis-dxs-system-servicelogs-read',


    TENANT_READ: 'amentis-dxs-catalog-tenant-read',
    TENANT_EDIT: 'amentis-dxs-catalog-tenant-edit',

    SCHEMAS_READ: 'amentis-dxs-catalog-schemas-read',
    SCHEMAS_EDIT: 'amentis-dxs-catalog-schemas-edit',
    SCHEMAS_CREATE: 'amentis-dxs-catalog-schemas-create',
    SCHEMAS_DELETE: 'amentis-dxs-catalog-schemas-delete',

    COLLECTION_SETTINGS_READ: 'amentis-dxs-catalog-collectionsettings-read',
    COLLECTION_SETTINGS_EDIT: 'amentis-dxs-catalog-collectionsettings-edit',
    COLLECTION_SETTINGS_CREATE: 'amentis-dxs-catalog-collectionsettings-create',
    COLLECTION_SETTINGS_DELETE: 'amentis-dxs-catalog-collectionsettings-delete',

    COLLECTION_PERMISSIONS_READ: 'amentis-dxs-catalog-collectionpermissions-read',
    COLLECTION_PERMISSIONS_EDIT: 'amentis-dxs-catalog-collectionpermissions-edit',
    COLLECTION_PERMISSIONS_CREATE: 'amentis-dxs-catalog-collectionpermissions-create',
    COLLECTION_PERMISSIONS_DELETE: 'amentis-dxs-catalog-collectionpermissions-delete',

    SYSTEM_PARAMETERS_READ: 'amentis-dxs-catalog-parameters-read',
    SYSTEM_PARAMETERS_EDIT: 'amentis-dxs-catalog-parameters-edit',
    SYSTEM_PARAMETERS_CREATE: 'amentis-dxs-catalog-parameters-create',
    SYSTEM_PARAMETERS_DELETE: 'amentis-dxs-catalog-parameters-delete',

    TAGS_READ: 'amentis-dxs-catalog-tags-read',
    TAGS_EDIT: 'amentis-dxs-catalog-tags-edit',
    TAGS_CREATE: 'amentis-dxs-catalog-tags-create',
    TAGS_DELETE: 'amentis-dxs-catalog-tags-delete',

    SYSTEM_CHANGELOG_READ: 'amentis-dxs-system-changelog-read',
    SYSTEM_CHANGELOG_RESTORE: 'amentis-dxs-system-changelog-restore',

    SYSTEM_QUERYLOG_READ: 'amentis-dxs-system-querylog-read',

    SYSTEM_SERVICEAVAILABILITY_READ: 'amentis-dxs-system-serviceavailability-read',

    MAPPING_JOB_TEMPLATES_READ: 'amentis-dxs-jobs-mappingjobtemplates-read',
    MAPPING_JOB_TEMPLATES_EDIT: 'amentis-dxs-jobs-mappingjobtemplates-edit',
    MAPPING_JOB_TEMPLATES_CREATE: 'amentis-dxs-jobs-mappingjobtemplates-create',
    MAPPING_JOB_TEMPLATES_DELETE: 'amentis-dxs-jobs-mappingjobtemplates-delete',

    USERS_READ: 'amentis-dxs-usermgmt-users-read',
    USERS_EDIT: 'amentis-dxs-usermgmt-users-edit',
    USERS_CREATE: 'amentis-dxs-usermgmt-users-create',
    USERS_DELETE: 'amentis-dxs-usermgmt-users-delete',

    USER_GROUPS_READ: 'amentis-dxs-usermgmt-usergroups-read',
    USER_GROUPS_EDIT: 'amentis-dxs-usermgmt-usergroups-edit',
    USER_GROUPS_CREATE: 'amentis-dxs-usermgmt-usergroups-create',
    USER_GROUPS_DELETE: 'amentis-dxs-usermgmt-usergroups-delete',

    USER_LIST_TEMPLATES_READ: 'amentis-dxs-usermgmt-userlisttemplates-read',
    USER_LIST_TEMPLATES_EDIT: 'amentis-dxs-usermgmt-userlisttemplates-edit',
    USER_LIST_TEMPLATES_CREATE: 'amentis-dxs-usermgmt-userlisttemplates-create',
    USER_LIST_TEMPLATES_DELETE: 'amentis-dxs-usermgmt-userlisttemplates-delete',

    TENANT_LIST_TEMPLATES_READ: 'amentis-dxs-catalog-tenantlisttemplates-read',
    TENANT_LIST_TEMPLATES_EDIT: 'amentis-dxs-catalog-tenantlisttemplates-edit',
    TENANT_LIST_TEMPLATES_CREATE: 'amentis-dxs-catalog-tenantlisttemplates-create',
    TENANT_LIST_TEMPLATES_DELETE: 'amentis-dxs-catalog-tenantlisttemplates-delete',

    DETECTION_JOB_TEMPLATES_READ: 'amentis-dxs-catalog-detectionjobtemplates-read',
    DETECTION_JOB_TEMPLATES_EDIT: 'amentis-dxs-catalog-detectionjobtemplates-edit',
    DETECTION_JOB_TEMPLATES_CREATE: 'amentis-dxs-catalog-detectionjobtemplates-create',
    DETECTION_JOB_TEMPLATES_DELETE: 'amentis-dxs-catalog-detectionjobtemplates-delete',


    ACCESS_CONTROL_LISTS_READ: 'amentis-dxs-usermgmt-accesscontrollists-read',
    ACCESS_CONTROL_LISTS_EDIT: 'amentis-dxs-usermgmt-accesscontrollists-edit',
    ACCESS_CONTROL_LISTS_CREATE: 'amentis-dxs-usermgmt-accesscontrollists-create',
    ACCESS_CONTROL_LISTS_DELETE: 'amentis-dxs-usermgmt-accesscontrollists-delete',

    PIPELINES_READ: 'amentis-dxs-integration-pipelines-read',
    PIPELINES_UPLOAD: 'amentis-dxs-integration-pipelines-upload',

    DEADLETTERS_READ: 'amentis-dxs-integration-deadletters-read',
    DEADLETTERS_EDIT: 'amentis-dxs-integration-deadletters-edit',
    DEADLETTERS_CREATE: 'amentis-dxs-integration-deadletters-create',
    DEADLETTERS_DELETE: 'amentis-dxs-integration-deadletters-delete',

    WEBHOOKS_SUBSCRIPTIONS_READ: 'amentis-dxs-webhooksubscriptions-read',
    WEBHOOKS_SUBSCRIPTIONS_EDIT: 'amentis-dxs-webhooksubscriptions-edit',
    WEBHOOKS_SUBSCRIPTIONS_CREATE: 'amentis-dxs-webhooksubscriptions-create',
    WEBHOOKS_SUBSCRIPTIONS_DELETE: 'amentis-dxs-webhooksubscriptions-delete',

    DOCUMENTS_DELETE: 'amentis-dxs-documents-delete',

    SITES_READ: 'amentis-dxs-solutions-smartfactorymasterdata-sites-read',
    SITES_EDIT: 'amentis-dxs-solutions-smartfactorymasterdata-sites-edit',
    SITES_CREATE: 'amentis-dxs-solutions-smartfactorymasterdata-sites-create',
    SITES_DELETE: 'amentis-dxs-solutions-smartfactorymasterdata-sites-delete',


    SHIFTEVENTS_READ: 'amentis-dxs-solutions-smartfactoryapps-shiftevents-read',
    SHIFTEVENTS_EDIT: 'amentis-dxs-solutions-smartfactoryapps-shiftevents-edit',
    SHIFTEVENTS_CREATE: 'amentis-dxs-solutions-smartfactoryapps-shiftevents-create',
    SHIFTEVENTS_DELETE: 'amentis-dxs-solutions-smartfactoryapps-shiftevents-delete',


    SHIFTEVENTTYPE_READ: 'amentis-dxs-solutions-smartfactorymasterdata-shifteventtypes-read',
    SHIFTEVENTTYPE_EDIT: 'amentis-dxs-solutions-smartfactorymasterdata-shifteventtypes-edit',
    SHIFTEVENTTYPE_CREATE: 'amentis-dxs-solutions-smartfactorymasterdata-shifteventtypes-create',
    SHIFTEVENTTYPE_DELETE: 'amentis-dxs-solutions-smartfactorymasterdata-shifteventtypes-delete',

    WORKPLACETYPES_READ: 'amentis-dxs-solutions-smartfactorymasterdata-workplacetypes-read',
    WORKPLACETYPES_EDIT: 'amentis-dxs-solutions-smartfactorymasterdata-workplacetypes-edit',
    WORKPLACETYPES_CREATE: 'amentis-dxs-solutions-smartfactorymasterdata-workplacetypes-create',
    WORKPLACETYPES_DELETE: 'amentis-dxs-solutions-smartfactorymasterdata-workplacetypes-delete',

    HAZARDOUSSUBSTANCECLASSIFICATION_READ: 'amentis-dxs-solutions-batterydatahub-hazardoussubstanceclassification-read',
    HAZARDOUSSUBSTANCECLASSIFICATION_EDIT: 'amentis-dxs-solutions-batterydatahub-hazardoussubstanceclassification-edit',
    HAZARDOUSSUBSTANCECLASSIFICATION_CREATE: 'amentis-dxs-solutions-batterydatahub-hazardoussubstanceclassification-create',
    HAZARDOUSSUBSTANCECLASSIFICATION_DELETE: 'amentis-dxs-solutions-batterydatahub-hazardoussubstanceclassification-delete',

    BATTERYSUBSTANCE_READ: 'amentis-dxs-solutions-batterydatahub-batterysubstance-read',
    BATTERYSUBSTANCE_EDIT: 'amentis-dxs-solutions-batterydatahub-batterysubstance-edit',
    BATTERYSUBSTANCE_CREATE: 'amentis-dxs-solutions-batterydatahub-batterysubstance-create',
    BATTERYSUBSTANCE_DELETE: 'amentis-dxs-solutions-batterydatahub-batterysubstance-delete',

    BATTERYCATEGORY_READ:   'amentis-dxs-solutions-batterydatahub-batterycategory-read',
    BATTERYCATEGORY_EDIT:   'amentis-dxs-solutions-batterydatahub-batterycategory-edit',
    BATTERYCATEGORY_CREATE: 'amentis-dxs-solutions-batterydatahub-batterycategory-create',
    BATTERYCATEGORY_DELETE: 'amentis-dxs-solutions-batterydatahub-batterycategory-delete',

    
    RECYCLINGSHARE_READ:   'amentis-dxs-solutions-batterydatahub-recyclingshare-read',
    RECYCLINGSHARE_EDIT:   'amentis-dxs-solutions-batterydatahub-recyclingshare-edit',
    RECYCLINGSHARE_CREATE: 'amentis-dxs-solutions-batterydatahub-recyclingshare-create',
    RECYCLINGSHARE_DELETE: 'amentis-dxs-solutions-batterydatahub-recyclingshare-delete',

    
    MANUFACTURERS_READ:   'amentis-dxs-solutions-batterydatahub-manufacturers-read',
    MANUFACTURERS_EDIT:   'amentis-dxs-solutions-batterydatahub-manufacturers-edit',
    MANUFACTURERS_CREATE: 'amentis-dxs-solutions-batterydatahub-manufacturers-create',
    MANUFACTURERS_DELETE: 'amentis-dxs-solutions-batterydatahub-manufacturers-delete',
    
    MANUFACTURERPLANT_READ:   'amentis-dxs-solutions-batterydatahub-manufacturerplants-read',
    MANUFACTURERPLANT_EDIT:   'amentis-dxs-solutions-batterydatahub-manufacturerplants-edit',
    MANUFACTURERPLANT_CREATE: 'amentis-dxs-solutions-batterydatahub-manufacturerplants-create',
    MANUFACTURERPLANT_DELETE: 'amentis-dxs-solutions-batterydatahub-manufacturerplants-delete',

    WORKPLACE_READ: 'amentis-dxs-solutions-smartfactorymasterdata-workplaces-read',
    WORKPLACE_EDIT: 'amentis-dxs-solutions-smartfactorymasterdata-workplaces-edit',
    WORKPLACE_CREATE: 'amentis-dxs-solutions-smartfactorymasterdata-workplaces-create',
    WORKPLACE_DELETE: 'amentis-dxs-solutions-smartfactorymasterdata-workplaces-delete',

    BATTERYMATERIALS_READ: 'amentis-dxs-solutions-batterydatahub-batterymaterials-read',
    BATTERYMATERIALS_EDIT: 'amentis-dxs-solutions-batterydatahub-batterymaterials-edit',
    BATTERYMATERIALS_CREATE: 'amentis-dxs-solutions-batterydatahub-batterymaterials-create',
    BATTERYMATERIALS_DELETE: 'amentis-dxs-solutions-batterydatahub-batterymaterials-delete',

    BATTERYSTATUS_READ: 'amentis-dxs-solutions-batterydatahub-batterystatus-read',
    BATTERYSTATUS_EDIT: 'amentis-dxs-solutions-batterydatahub-batterystatus-edit',
    BATTERYSTATUS_CREATE: 'amentis-dxs-solutions-batterydatahub-batterystatus-create',
    BATTERYSTATUS_DELETE: 'amentis-dxs-solutions-batterydatahub-batterystatus-delete',

    BATTERYMODEL_READ: 'amentis-dxs-solutions-batterydatahub-batterymodel-read',
    BATTERYMODEL_EDIT: 'amentis-dxs-solutions-batterydatahub-batterymodel-edit',
    BATTERYMODEL_CREATE: 'amentis-dxs-solutions-batterydatahub-batterymodel-create',
    BATTERYMODEL_DELETE: 'amentis-dxs-solutions-batterydatahub-batterymodel-delete',


    BATTERYPACKS_READ: 'amentis-dxs-solutions-batterydatahub-batterypacks-read',
    BATTERYPACKS_EDIT: 'amentis-dxs-solutions-batterydatahub-batterypacks-edit',
    BATTERYPACKS_CREATE: 'amentis-dxs-solutions-batterydatahub-batterypacks-create',
    BATTERYPACKS_DELETE: 'amentis-dxs-solutions-batterydatahub-batterypacks-delete',

}