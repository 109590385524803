import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';

export default {
    namespaced: true,

    state : {

        batterySubstance: createDefaultBatterySubstance(),
        hazardousSubstanceClassificationId : null,
        hazardousSubstanceClassifications : createEmptyHazardousSubstanceClassifications()
    },

    mutations: {  

        setBatterySubstance(state, batterySubstance) {

            if (batterySubstance) {
                state.batterySubstance.tenantId = batterySubstance.tenantId;
                state.batterySubstance.name = batterySubstance.name;
                state.batterySubstance.i18n = batterySubstance.i18n;
                state.batterySubstance.data = batterySubstance.data;
                state.batterySubstance.references = batterySubstance.references;
                state.batterySubstance.active = batterySubstance.active;
                state.batterySubstance.creationDateTime     = batterySubstance.creationDateTime;
                state.batterySubstance.creationUser         = batterySubstance.creationUser;
                state.batterySubstance.modificationDateTime = batterySubstance.modificationDateTime;
                state.batterySubstance.modificationUser     = batterySubstance.modificationUser;
            }
            
        },
        
        async updateBatterySubstances(context, batterySubstance) {

            try {
                context.commit('setBatterySubstance', batterySubstance);
            } catch (error) {
                return Promise.reject(error);
            }
        },        
        async setHazardousSubstanceClassificationId(state, hazardousSubstanceClassificationId) {
            if (hazardousSubstanceClassificationId) {
                //const payload = await api.masterdata.hazardousSubstanceClassifications.findById(hazardousSubstanceClassificationId);
                state.batterySubstance.references.hazardousSubstanceClassification = hazardousSubstanceClassificationId;
                state.hazardousSubstanceClassificationId = hazardousSubstanceClassificationId;
            } else {
                state.batterySubstance.references.hazardousSubstanceClassification = null;
            }
        },
        setHazardousSubstanceClassifications(state, hazardousSubstanceClassifications){ 
            if (hazardousSubstanceClassifications) {

            let items = [];
            if (hazardousSubstanceClassifications.items) {
                state.hazardousSubstanceClassifications.items = hazardousSubstanceClassifications.items;
            } else {
                state.hazardousSubstanceClassifications.items = items;
            }

            if (hazardousSubstanceClassifications.tenantId) {
                state.hazardousSubstanceClassifications.tenantId = hazardousSubstanceClassifications.tenantId;
            }

            if (hazardousSubstanceClassifications.pagination) {
                state.hazardousSubstanceClassifications.pagination = hazardousSubstanceClassifications.pagination;
            }

        }
        }

    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.batterySubstances.findById(name);
                    let hazardousSubstanceClassifications = await api.masterdata.hazardousSubstanceClassifications.findByFilter('*', 0, 100);
                    context.commit('setHazardousSubstanceClassifications', hazardousSubstanceClassifications)
                
                get(response, 'item.hazardousSubstanceClassification.name', createDefaultBatterySubstance())
                context.commit('setBatterySubstance', get(response, 'item.batterySubstance', createDefaultBatterySubstance()));
                context.commit('setHazardousSubstanceClassificationId', get(response, 'item.batterySubstance.references.hazardousSubstanceClassification', createDefaultBatterySubstance()));
            
            } catch (error) {
                context.commit('setBatterySubstance', createDefaultBatterySubstance());
                context.commit('setHazardousSubstanceClassificationId', '');
                context.commit('setHazardousSubstanceClassifications', []);
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters.model, 'batterySubstance');
                console.log(">>> VUEX SAVE DATA ", JSON.stringify(payload));
                let response = await api.masterdata.batterySubstances.update(payload);
                console.log('#################', JSON.stringify(response));
                context.commit('setBatterySubstance', get(response, 'item.batterySubstance', createDefaultBatterySubstance()));

            } catch (error) {
                console.log(error);
                context.commit('setBatterySubstance', createDefaultBatterySubstance());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            console.log('+++ updateModel ', JSON.stringify(model, true, 2));
            context.commit('setBatterySubstance', get(model, 'batterySubstance', createDefaultBatterySubstance()));
        },

        updateBatterySubstance(context, batterySubstance) {
            console.log('+++ updateBatterySubstance ', JSON.stringify(batterySubstance, true, 2));
            context.commit('setBatterySubstance', batterySubstance);
        },
        async updateHazardousSubstanceClassificationId(context, hazardousSubstanceClassificationId) {
            try {
                context.commit('setHazardousSubstanceClassificationId', hazardousSubstanceClassificationId);
            } catch (error) {
                return Promise.reject(error);
            }

        },
    },

    getters: {
        
        request(state) {
            let payload = state.batterySubstance;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },
        batterySubstance(state) {
            return state.batterySubstance ? state.batterySubstance : createDefaultBatterySubstance();
        },
        
        model(state) {
                 return {
                    batterySubstance: state.batterySubstance
            }
        },
        hazardousSubstanceClassifications(state) {
            return state.hazardousSubstanceClassifications ? state.hazardousSubstanceClassifications : {items:[]};
        },

        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },
        hazardousSubstanceClassificationId(state) {
            return get(state, 'batterySubstance.referencehazardousSubstanceClassification',get(state,'hazardousSubstanceClassificationId'));
        },
        
    }
}

function createDefaultBatterySubstance() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {},
        active: null,
        references: {
            hazardousSubstanceClassification: null
        },
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }}

    
function createEmptyHazardousSubstanceClassifications() {

    return {

        "items": ["Healthy","Edible","Bad Smell"]
        
    }}
