<template>
   <c-item-editor-page :title="$t('access-control-list-assignment-editor-page.component.title')" :subtitle="$t('access-control-list-assignment-editor-page.component.subtitle')" class="mb-5" flat>
       <v-card-text>
           <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('access-control-list-assignment-editor-page.component.sections.acls.title')}}</div>
           <div>{{$t('access-control-list-assignment-editor-page.component.sections.acls.subtitle')}}</div>
       </v-card-text>
        <c-item-checkbox-multi-selector v-model="selectedAccessControlLists" :items="accessControlLists" :return-object="false">
            <template v-slot:list-item-title="{ item }">
                {{$dxs.i18n.localizedName(item, $i18n.locale)}} ({{item.name}})
            </template>
            <template v-slot:list-item-subtitle="{ item }">
                {{getCollectionAccessRulesCommaSeparatedList(item)}}
            </template>
        </c-item-checkbox-multi-selector>
        <v-divider />
       <v-card-text>
           <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('access-control-list-assignment-editor-page.component.sections.effectivePermissions.title')}}</div>
           <div>{{$t('access-control-list-assignment-editor-page.component.sections.effectivePermissions.subtitle')}}</div>
       </v-card-text>
       <dxs-access-control-list-permission-table :items="assignedACLs" class="pa-5"/>
   </c-item-editor-page>
</template>

<script>
import AccessControlListPermissionTableComponent from '@/core/components/usermgmt/access-control/access-control-list-permission-table.component';
import { cloneDeep, get, isEmpty, set } from 'lodash';

export default {

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    components : {
        'dxs-access-control-list-permission-table' : AccessControlListPermissionTableComponent
    },

    data() {

        return {
            model: cloneDeep(this.value)
        };
    },

    watch: {
        value(newValue) {
            this.model = cloneDeep(newValue);
        }
    },

    methods: {
        getCollectionAccessRulesCommaSeparatedList(item) {

            const rules = get(item, 'collectionAccess.collectionAccessRules', []);
            return !isEmpty(rules) ? rules.map(e => e.collectionId).join(', ') : this.$t('access-control-list-assignment-editor-page.component.sections.acls.itemSelection.noCollectionsAssigned');
        },

        fireInput() {

            this.$emit('input', cloneDeep(this.model));
        }
    },

    computed:  {

        accessControlLists: {
            get() {
                return get(this.model, 'accessControlLists', [])
            }
        },

        accessControlListsMap() {

            let map = {};
            for (const acl of this.accessControlLists) {
                map[acl.name] = acl;
            }

            return map;
        },

        selectedAccessControlLists: {
            get() {
                return get(this.model, 'account.accessControlLists', [])
            },

            set(value) {
                set(this.model, 'account.accessControlLists', value);
                this.fireInput();
            }
        },

        assignedACLs() {

            const assignedACLs = [];
            for (const aclId of this.selectedAccessControlLists) {
                assignedACLs.push(this.accessControlListsMap[aclId])
            }

            return assignedACLs;
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "access-control-list-assignment-editor-page.component.title" : "Access Control",
        "access-control-list-assignment-editor-page.component.subtitle" : "This page contains options for granting access to the system, e.g. for collections.",

        "access-control-list-assignment-editor-page.component.sections.acls.itemSelection.noCollectionsAssigned" : "No Assigned Collections",

        "access-control-list-assignment-editor-page.component.sections.acls.title" : "Access Control Lists (ACLs)",
        "access-control-list-assignment-editor-page.component.sections.acls.subtitle" : "Select Access Controls Lists (ACLs) in order to grant access.",
        "access-control-list-assignment-editor-page.component.sections.effectivePermissions.title" : "Effective Permissions",
        "access-control-list-assignment-editor-page.component.sections.effectivePermissions.subtitle" : "The selected access control list are applied in an additive way: the system creates a list of effective permissions based on the summary of the selected access permission list."
    },

    "de" : {
        "access-control-list-assignment-editor-page.component.title" : "Zugriffssteuerung",
        "access-control-list-assignment-editor-page.component.subtitle" : "Diese Seite enthält die Optionen zur Zugriffsteuerung z.B. für Datenbereiche.",

        "access-control-list-assignment-editor-page.component.sections.acls.itemSelection.noCollectionsAssigned" : "Keine Datenbereiche zugewiesen",

        "access-control-list-assignment-editor-page.component.sections.acls.title" : "Zugriffsberechtigungen",
        "access-control-list-assignment-editor-page.component.sections.acls.subtitle" : "Weisen Sie eine oder mehrere Zugriffsberechtigungen zu, um die Berechtigungen zu hinterlegen.",
        "access-control-list-assignment-editor-page.component.sections.effectivePermissions.title" : "Effektive Berechtigungen",
        "access-control-list-assignment-editor-page.component.sections.effectivePermissions.subtitle" : "Die ausgewählten Zugriffsberechtigungen wirken additiv: Das System erstellt eine Liste effektiver Berechtigungen, die sich aus der Summe der ausgewählten Zugriffsberechtigungen ergeben."
    }
}
</i18n>