
import { get } from 'lodash';
import { forEach } from 'lodash';

import ListTemplateDataModule from './list-template-data.module';

export function newInstance({moduleId}) {

    return {
        inject: ['progressIndicatorService', 'envContextService', 'userContextService'], 

        methods: {

            async loadListTemplates(collectionId) {
                await this.$store.dispatch(moduleId + '/loadListTemplates', { collectionId });
            },

            async applyListTemplate(listTemplate) {
                //console.log("HERE WE ARE AGAIN " + JSON.stringify(listTemplate))
                await this.$store.dispatch(moduleId + '/applyListTemplate', listTemplate); 
            },

            async saveListTemplate(userId, collectionId, listTemplate) {
                const result = await this.$store.dispatch(moduleId + '/saveListTemplate', {userId, collectionId, listTemplate}); 

                return result;
            },

            async saveTenantListTemplate(collectionId, listTemplate) {
                const result = await this.$store.dispatch(moduleId + '/saveTenantListTemplate', {collectionId, listTemplate}); 

                return result;
            },

            createEmptyListTemplate({attributes}) {

                
                let template = {

                    name: null,
                    scope: 'user', // Admissible scopes are user and tenant
        
                    filterSettings : { 
                        filterQuery : '*',
                        filterQueryLanguage : 'SIMPLE',
                        rangeFilters : [],
                        facetFilters : []
                    },
    
                    viewSettings: {
                        columns: []
                    },
    
                    sortSettings : {
                        sortCriteria : [] 
                    }
                };

                // Build the view settings dynamically from the given schema.
                // Make sure that view settings take the ordering from the schema into account
                if (attributes) {
                
                    forEach(attributes, attribute => template.viewSettings.columns.push({
                        name: attribute.name,
                        display: true,
                        order: attribute.ordering
                    }));

                    template.viewSettings.columns.sort((first, second) => first.order - second.order);
                }
                

                return template;
            }
        },

        computed: {

            listTemplates: {

                get() {
                    let templates = this.$store.getters[moduleId + '/listTemplates'];
                    return templates;
                },

            },

            selectedListTemplate: {

                get() {
                    let template = this.$store.getters[moduleId + '/selectedListTemplate'];
                    return template;
                },

                set(listTemplate) {
                    this.applyListTemplate(listTemplate);
                }
            },

            filterSettings() {
                return get(this.selectedListTemplate, 'filterSettings');
            },

            viewSettings() {
                return get(this.selectedListTemplate, 'viewSettings');
            },

            sortSettings() {
                return get(this.selectedListTemplate, 'sortSettings');
            },
        },

        created() {

            // Dynamically register the vuex store module for managing the data.
            if (!this.$store.hasModule(moduleId)){
                this.$store.registerModule(moduleId, ListTemplateDataModule);
            }
        }
    };
}