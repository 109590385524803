import api from '@/store/api';
import { set } from 'lodash';
export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(),

        filter: getDefaultFilter()
    },

    mutations: {

        setResponse(state, response) {
            state.response = response;

        },

        setFilter(state, filter) {
            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter();
            }
        },
    },

    actions: {

        async loadResponse(context) {
            try {
                let response = await api.catalog.systemParameters.findAll(context.state.filter); 
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },
        async updateItem(context, {item}) {
            try {
                let payload = item;
                payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
                payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
                payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
                payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
                await api.catalog.systemParameters.update(item.name, item);
                await context.dispatch('loadResponse')
            } catch (error) {
                console.log("An error has occured" + error);
                throw error;
            }
        },
        
        async removeItem(context, {item}) { 
            try {
                await api.catalog.systemParameters.delete(item.name); 
                await context.dispatch('loadResponse')
            } catch (error) {
                console.log("An error has occured" + error);
                throw error;
            }
        }
    },

    getters: {

        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        }
    }
}

function getDefaultResponse() {

    return {
        items: []
    };
}

function getDefaultFilter() {
    return '*';
}