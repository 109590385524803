<template>
    <v-simple-table >
                    <thead>
                        <tr>
                            <th>{{$t('webhook-functions-help.component.tab.func')}}</th>
                            <th>{{$t('webhook-functions-help.component.tab.returnValue')}}</th>
                            <th>{{$t('webhook-functions-help.component.tab.description')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('webhook-functions-help.component.tab.func.category.strings')}}</td>
                        </tr>
                         <tr>
                            <td><code>LENGTH(text: STRING)</code></td>
                            <td><code>INTEGER</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.LENGTH')}}</td>
                        </tr>
                        <tr>
                            <td><code>TRIM(text: STRING)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.TRIM')}}</td>
                        </tr>
                        <tr>
                            <td><code>SUBSTRING(text: STRING, INTEGER, INTEGER)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.SUBSTRING')}}</td>
                        </tr>
                        <tr>
                            <td><code>UPPERCASE(text: STRING)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.UPPERCASE')}}</td>
                        </tr>
                        <tr>
                            <td><code>LOWERCASE(text: STRING)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.LOWERCASE')}}</td>
                        </tr>
                        <tr>
                            <td><code>CONTAINS_TEXT(text: STRING, expression: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.CONTAINS_TEXT')}}</td>
                        </tr>
                        <tr>
                            <td><code>CONTAINS_TEXT_IGNORE_CASE(text: STRING, expression: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.CONTAINS_TEXT_IGNORE_CASE')}}</td>
                        </tr>
                        <tr>
                            <td><code>STARTS_WITH(text: STRING, prefix: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.STARTS_WITH')}}</td>
                        </tr>
                        <tr>
                            <td><code>ENDS_WITH(text: STRING, suffix: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.ENDS_WITH')}}</td>
                        </tr>
                        <tr>
                            <td><code>MATCHES_REGEX(text: STRING, regex: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.MATCHES_REGEX')}}</td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('webhook-functions-help.component.tab.func.category.general')}}</td>
                        </tr>
                        <tr>
                            <td><code>IF(condition: BOOLEAN, first: ANY, second: ANY)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.IF')}}</td>
                        </tr>
                        <tr>
                            <td><code>IF_NOT(condition: BOOLEAN, first: ANY, second: ANY)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.IF_NOT')}}</td>
                        </tr>
                        <tr>
                            <td><code>IF_NULL(value: ANY, first: ANY, second: ANY)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.IF_NULL')}}</td>
                        </tr>
                        <tr>
                            <td><code>IS_NULL(value: ANY)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.IS_NULL')}}</td>
                        </tr>
                        <tr>
                            <td><code>IS_NOT_NULL(value: ANY)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.IS_NOT_NULL')}}</td>
                        </tr>
                        <tr>
                            <td><code>IS_EMPTY(value: ANY)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.IS_EMPTY')}}</td>
                        </tr>
                        <tr>
                            <td><code>TO_STRING(value: ANY)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.TO_STRING')}}</td>
                        </tr>
                        
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('webhook-functions-help.component.tab.func.category.collection')}}</td>
                        </tr>
                        <tr>
                            <td><code>CONTAINS_VALUE(collection: LIST | SET, value: ANY)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.CONTAINS_VALUE')}}</td>
                        </tr>
                        <tr>
                            <td><code>CONTAINS_KEY(collection: MAP, value: ANY)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.CONTAINS_KEY')}}</td>
                        </tr>
                        <tr>
                            <td><code>GET(collection: MAP, value: ANY)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.GET')}}</td>
                        </tr>
                        <tr>
                            <td><code>GET_OR_ELSE(collection: MAP, value: ANY, fallback: ANY)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.GET_OR_ELSE')}}</td>
                        </tr>
                       

                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('webhook-functions-help.component.tab.func.category.math')}}</td>
                        </tr>

                        <tr>
                            <td><code>LOG(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.LOG')}}</td>
                        </tr>
                        <tr>
                            <td><code>LOG10(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.LOG10')}}</td>
                        </tr>
                        <tr>
                            <td><code>SQRT(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.SQRT')}}</td>
                        </tr>
                        <tr>
                            <td><code>CEIL(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.CEIL')}}</td>
                        </tr>
                        <tr>
                            <td><code>FLOOR(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.FLOOR')}}</td>
                        </tr>
                        <tr>
                            <td><code>ROUND(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.ROUND')}}</td>
                        </tr>
                        <tr>
                            <td><code>POWER(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.POWER')}}</td>
                        </tr>
                        <tr>
                            <td><code>ABS(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.ABS')}}</td>
                        </tr>
                        <tr>
                            <td><code>RANDOM()</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.RANDOM')}}</td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('webhook-functions-help.component.tab.func.category.datetime')}}</td>
                        </tr>

                        <tr>
                            <td><code>FORMAT_YEAR(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.YEAR')}}</td>
                        </tr>
                        <tr>
                            <td><code>FORMAT_MONTH(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.MONTH')}}</td>
                        </tr>
                        <tr>
                            <td><code>FORMAT_DAY_OF_YEAR(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.DAY_OF_YEAR')}}</td>
                        </tr>
                        <tr>
                            <td><code>FORMAT_DAY_OF_MONTH(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.DAY_OF_MONTH')}}</td>
                        </tr>
                        <!-- <tr>
                            <td><code>DAY_OF_WEEK(value: DATETIME | DATE)</code></td>
                            <td><code>INTEGER</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.DAY_OF_WEEK')}}</td>
                        </tr> -->
                        <tr>
                            <td><code>FORMAT_WEEK(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.FORMAT_WEEK')}}</td>
                        </tr>
                        <tr>
                            <td><code>FORMAT_YEAR_WEEK(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.FORMAT_YEAR_WEEK')}}</td>
                        </tr>
                        <tr>
                            <td><code>FORMAT_YEAR_MONTH(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.FORMAT_YEAR_MONTH')}}</td>
                        </tr>
                        <tr>
                            <td><code>FORMAT_QUARTER(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.FORMAT_QUARTER')}}</td>
                        </tr>
                        <tr>
                            <td><code>FORMAT_YEAR_QUARTER(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.FORMAT_YEAR_QUARTER')}}</td>
                        </tr>
                        <tr>
                            <td><code>FORMAT_ISO_DATE_TIME(value: DATETIME)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.FORMAT_ISO_DATE_TIME')}}</td>
                        </tr>
                        <tr>
                            <td><code>FORMAT_ISO_DATE(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.FORMAT_ISO_DATE')}}</td>
                        </tr>

                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('webhook-functions-help.component.tab.func.category.UUID')}}</td>
                        </tr>

                        <tr>
                            <td><code>RANDOM_ID()</code></td>
                            <td><code>UUID (INTEGER)</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.RANDOM_ID')}}</td>
                        </tr>
                        <tr>
                            <td><code>UUID(value: ANY)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.UUID')}}</td>
                        </tr>
                        <tr>
                            <td><code>UUID_BYTES(value: LIST)</code></td>
                            <td><code>UUID (INTEGER)</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.UUID_BYTES')}}</td>
                        </tr>
                        <tr>
                            <td><code>UUID_STRINGS(value: LIST)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.UUID_STRINGS')}}</td>
                        </tr>

                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('webhook-functions-help.component.tab.func.category.encoding')}}</td>
                        </tr>
                        <tr>
                            <td><code>BASE64_ENCODE(value: STRING)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('webhook-functions-help.component.tab.func.BASE64_ENCODE')}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
</template>

<script>

export default {
    name: 'expression-functions-help',
}

</script>
<i18n>
{
    "en": {
        "webhook-functions-help.component.tab.func.category.general" : "General Functions",
        "webhook-functions-help.component.tab.func.category.strings" : "String Functions",
        "webhook-functions-help.component.tab.func.category.collection" : "Collection Functions",
        "webhook-functions-help.component.tab.func.category.math" : "Math Functions",
        "webhook-functions-help.component.tab.func.category.datetime" : "Date Time Functions",
        "webhook-functions-help.component.tab.func.category.UUID" : "UUID",
        "webhook-functions-help.component.tab.func.category.encoding" : "Encoding",

        "webhook-functions-help.component.tab.func.LOWERCASE" : "Converts the given string to lowercase, e.g. LOWERCASE('heLlo wOrLd!') will become 'hello world!'.",
        "webhook-functions-help.component.tab.func.UPPERCASE" : "Converts the given string to uppercase, e.g. UPPERCASE('heLlo wOrLd!') will become 'HELLO WORLD!'.",
        "webhook-functions-help.component.tab.func.CONTAINS_TEXT" : "Returns true when the given text contain the expression at least once, false otherwise. Please note: This function is case sensitive.",
        "webhook-functions-help.component.tab.func.CONTAINS_TEXT_IGNORE_CASE" : "Returns true when the given text contains the expression at least once, false otherwise. Please note: This function is NOT case sensitive.",


        "webhook-functions-help.component.tab.func.STARTS_WITH" : "Returns true when the given text starts with the prefix, false otherwise. Please note: This function is case sensitive.",
        "webhook-functions-help.component.tab.func.ENDS_WITH" : "Returns true when the given text end with the suffix, false otherwise. Please note: This function is case sensitive.",
        "webhook-functions-help.component.tab.func.MATCHES_REGEX" : "Returns true when the given text matches the regular expression, false otherwise.",

        "webhook-functions-help.component.tab.func.IS_EMPTY" : "Returns true when the given value is neither null nor empty (emptiness is checked for values of type MAP, LIST, SET and STRING), otherwise false is returned.",
        "webhook-functions-help.component.tab.func.LENGTH" : "Returns the length (i.e. number of characters) of the given string.",
        "webhook-functions-help.component.tab.func.TRIM" : "Removes leading and trailing whitespace from the string.",
        "webhook-functions-help.component.tab.func.SUBSTRING" : "Returns a substring from the given string e.g. SUBSTRING('Hello', 0,3) returns 'Hel'.",

        "webhook-functions-help.component.tab.func.CONTAINS_VALUE" : "Returns true when the given value is contained at least once in the collection, otherwise false is returned.",
        "webhook-functions-help.component.tab.func.CONTAINS_KEY" : "Returns true if the given MAP contains a mapping for the specified key.",
        "webhook-functions-help.component.tab.func.GET" : "Returns the value to which the specified key is mapped, or null if this MAP contains no mapping for the key.",
        "webhook-functions-help.component.tab.func.GET_OR_ELSE" : "Returns the value to which the specified key is mapped, or the given fallback if this MAP contains no mapping for the key.",

        "webhook-functions-help.component.tab.func.IF" : "Evaluates the given condition: When true the first value is returned, other the second value is returned.",
        "webhook-functions-help.component.tab.func.IF_NOT" : "Evaluates the given condition: When false the first value is returned, other the second value is returned. This function negates the IF function.",
        "webhook-functions-help.component.tab.func.IF_NULL" : "Checks if the given value is null: When true the first value will be returned, otherwise the second value will be returned.",
        "webhook-functions-help.component.tab.func.IS_NULL" : "Returns true if the given value is null, otherwise false.",
        "webhook-functions-help.component.tab.func.IS_NOT_NULL" : "Returns false if the given value is null, otherwise true.",
        "webhook-functions-help.component.tab.func.TO_STRING" : "Produces a string representation of the given object. Required e.g. when converting numbers to text for generating IDs.",

        "webhook-functions-help.component.tab.func.LOG" : "Returns the logarithm of the given input.",
        "webhook-functions-help.component.tab.func.LOG10" : "Returns the logarithm 10 of the given input.",
        "webhook-functions-help.component.tab.func.CEIL" : "Returns the nearest integer greater than or equal to the given input, e.g. CEIL(1.23) will produce 2.0.",
        "webhook-functions-help.component.tab.func.FLOOR" : "Returns the nearest integer less than or equal to the given input, e.g. FLOOR(1.68) will produce 1.0.",
        "webhook-functions-help.component.tab.func.ROUND" : "Rounds the given decimal value to it's closest integer, e.g. ROUND(1.23) will produce 1.0 while ROUND(1.5) will produce 2.0.",
        "webhook-functions-help.component.tab.func.RANDOM" : "Returns a random decimal value between 0.0 and 1.0, e.g. 0.9873.",
        "webhook-functions-help.component.tab.func.ABS" : "Returns the absolute value of the given input, e.g. ABS(-1) will produce the value 1.",
        "webhook-functions-help.component.tab.func.POWER" : "Returns the power of the given base and exponent, e.g. POWER(4.0 , 2.0) will return 16.0.",
        "webhook-functions-help.component.tab.func.SQRT" : "Returns the square root of a given value, e.g. SQRT(16.0) will produce the value 4.0.",

        "webhook-functions-help.component.tab.func.YEAR" : "Returns the year fraction of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 2021 is returned.",
        "webhook-functions-help.component.tab.func.MONTH" : "Returns the month fraction of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 12 is returned (January = 1, February = 2, ..., December = 12).",
        "webhook-functions-help.component.tab.func.DAY_OF_WEEK" :"Returns the index of the weekday of the given datetime, where monday is considered the first day of the week. For 2021-12-01 08:49:27 PM a value of 3 (Wednesday) is returned.",
        "webhook-functions-help.component.tab.func.DAY_OF_YEAR" : "Returns the day of year of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 335 is returned.",
        "webhook-functions-help.component.tab.func.DAY_OF_MONTH" : "Returns the day fraction of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 1 is returned.",
        "webhook-functions-help.component.tab.func.FORMAT_WEEK" : "Returns the calendar week of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 51 is returned.",
        "webhook-functions-help.component.tab.func.FORMAT_YEAR_WEEK" : "Returns the year and calendar week of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 2021-51 is returned.",
        "webhook-functions-help.component.tab.func.FORMAT_YEAR_MONTH" : "Returns the year and month of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 2021-21 is returned.",
        "webhook-functions-help.component.tab.func.FORMAT_QUARTER" : "Returns the quarter of the year for the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 04 is returned.",
        "webhook-functions-help.component.tab.func.FORMAT_YEAR_QUARTER" : "Returns the year and quarter of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 2021-04 is returned.",
        "webhook-functions-help.component.tab.func.FORMAT_ISO_DATE" : "Returns the calendar week of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 2021-12-01 is returned.",
        "webhook-functions-help.component.tab.func.FORMAT_ISO_DATE_TIME" : "Returns the calendar week of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 2021-12-01T08:49:27 is returned.",
        
        "webhook-functions-help.component.tab.func.RANDOM_ID" : "Returns a randomly generated UUID.",
        "webhook-functions-help.component.tab.func.UUID" : "Return UUID based on the given string.",
        "webhook-functions-help.component.tab.func.UUID_BYTES" : "Returns a UUID based on the given array of bytes",
        "webhook-functions-help.component.tab.func.UUID_STRINGS" : "Return a UUID based on the given array of strings.",
        
        "webhook-functions-help.component.tab.func.BASE64_ENCODE" : "Returns the BASE64 encoded string.",

        "webhook-functions-help.component.tab.func" : "Function",
        "webhook-functions-help.component.tab.description" : "Description",
        "webhook-functions-help.component.tab.returnValue" : "Return Type"
    },
    "de": {
        "webhook-functions-help.component.tab.func.category.general" : "Allgemeine Funktionen",
        "webhook-functions-help.component.tab.func.category.strings" : "Zeichenketten-Funktionen",
        "webhook-functions-help.component.tab.func.category.collection" : "Listen- und Mengen-Funktionen",
        "webhook-functions-help.component.tab.func.category.math" : "Mathematische Funktionen",
        "webhook-functions-help.component.tab.func.category.datetime" : "Datums-Funktionen",
        "webhook-functions-help.component.tab.func.category.UUID" : "UUID",
        "webhook-functions-help.component.tab.func.category.encoding" : "Verschlüsselung",

        "webhook-functions-help.component.tab.func.LOWERCASE" : "Konvertiert die angegebene Zeichenkette in Kleinbuchstaben, z.B. LOWERCASE('haLlo wELt!') wird zu 'hallo welt!'.",
        "webhook-functions-help.component.tab.func.UPPERCASE" : "Konvertiert die angegebene Zeichenkette in Großbuchstaben, z.B. LOWERCASE('haLlo wELt!') wird zu 'hallo welt!'.",
        "webhook-functions-help.component.tab.func.CONTAINS_TEXT" : "Gibt true zurück, wenn der angegebene Text den Ausdruck mindestens einmal enthält, andernfalls false. Bitte beachten Sie: Bei dieser Funktion wird zwischen Groß- und Kleinschreibung unterschieden.",
        "webhook-functions-help.component.tab.func.CONTAINS_TEXT_IGNORE_CASE" : "Gibt true zurück, wenn der angegebene Text den Ausdruck mindestens einmal enthält, andernfalls false. Bitte beachten Sie: Bei dieser Funktion wird NICHT zwischen Groß- und Kleinschreibung unterschieden.",


        "webhook-functions-help.component.tab.func.STARTS_WITH" : "Gibt true zurück, wenn der angegebene Text mit dem Präfix beginnt, sonst false. Bitte beachten Sie: Bei dieser Funktion wird zwischen Groß- und Kleinschreibung unterschieden.",
        "webhook-functions-help.component.tab.func.ENDS_WITH" : "Gibt true zurück, wenn der angegebene Text mit dem Suffix endet, sonst false. Bitte beachten Sie: Bei dieser Funktion wird zwischen Groß- und Kleinschreibung unterschieden.",
        "webhook-functions-help.component.tab.func.MATCHES_REGEX" : "Gibt true zurück, wenn der angegebene Text mit dem regulären Ausdruck übereinstimmt, andernfalls false.",

        "webhook-functions-help.component.tab.func.IS_EMPTY" : "Gibt true zurück, wenn der angegebene Wert weder null noch leer ist (Leer wird für Werte vom Typ MAP, LIST, SET und STRING geprüft), andernfalls wird false zurückgegeben.",
        "webhook-functions-help.component.tab.func.LENGTH" : "Gibt die Länge (d.h. die Anzahl der Zeichen) der angegebenen Zeichenkette zurück.",
        "webhook-functions-help.component.tab.func.TRIM" : "Entfernt führende und nachgestellte Leerzeichen aus der Zeichenkette.",
        "webhook-functions-help.component.tab.func.SUBSTRING" : "Gibt eine Teilzeichenfolge aus der angegebenen Zeichenkette zurück z.B. SUBSTRING('Hallo', 0,3) gibt 'Hal' zurück.",

        "webhook-functions-help.component.tab.func.CONTAINS_VALUE" : "Gibt true zurück, wenn der angegebene Wert mindestens einmal im Datenbereich enthalten ist, andernfalls wird false zurückgegeben.",
        "webhook-functions-help.component.tab.func.CONTAINS_KEY" : "Gibt true zurück, wenn die übergebene MAP eine Zuordnung für den gegebenen Schlüssel enthält.",
        "webhook-functions-help.component.tab.func.GET" : "Gibt den Wert zurück, der dem angegebenen Schlüssel zugeordnet ist, oder null, wenn diese MAP keine Zuordnung für den Schlüssel enthält.",
        "webhook-functions-help.component.tab.func.GET_OR_ELSE" : "Gibt den Wert zurück, der dem angegebenen Schlüssel zugeordnet ist, oder den fallback, wenn diese MAP keine Zuordnung für den Schlüssel enthält.",

        "webhook-functions-help.component.tab.func.IF" : "Wertet die angegebene Bedingung aus: Wenn wahr, wird der erste Wert zurückgegeben, andernfalls der zweite Wert.",
        "webhook-functions-help.component.tab.func.IF_NOT" : "Wertet die angegebene Bedingung aus: Bei false wird der erste Wert zurückgegeben, bei other wird der zweite Wert zurückgegeben. Diese Funktion negiert die IF-Funktion.",
        "webhook-functions-help.component.tab.func.IF_NULL" : "Prüft, ob der angegebene Wert null ist: Bei true wird der erste Wert zurückgegeben, andernfalls der zweite Wert.",
        "webhook-functions-help.component.tab.func.IS_NULL" : "Gibt true zurück, wenn der angegebene Wert null ist, andernfalls false.",
        "webhook-functions-help.component.tab.func.IS_NOT_NULL" : "Gibt false zurück, wenn der angegebene Wert null ist, andernfalls true.",
        "webhook-functions-help.component.tab.func.TO_STRING" : "Erzeugt eine String-Darstellung des angegebenen Objekts. Erforderlich z.B. bei der Konvertierung von Zahlen in Text zur Erzeugung von IDs.",

        "webhook-functions-help.component.tab.func.LOG" : "Gibt den Logarithmus des angegebenen Wertes zurück.",
        "webhook-functions-help.component.tab.func.LOG10" : "Gibt den Logarithmus 10 des angegebenen Wertes zurück.",
        "webhook-functions-help.component.tab.func.CEIL" : "Gibt die nächstgelegene ganze Zahl zurück, die größer oder gleich des angegebenen Wertes ist, z.B. CEIL(1.23) ergibt 2.0.",
        "webhook-functions-help.component.tab.func.FLOOR" : "Gibt die nächstgelegene ganze Zahl zurück, die kleiner oder gleich des angegebenen Wertes ist, z.B. FLOOR(1.68) ergibt 1.0.",
        "webhook-functions-help.component.tab.func.ROUND" : "Rundet den angegebenen Dezimalwert auf die nächstliegende ganze Zahl, z.B. ROUND(1.23) ergibt 1.0, ROUND(1.5) ergibt 2.0.",
        "webhook-functions-help.component.tab.func.RANDOM" : "Gibt einen zufälligen Dezimalwert zwischen 0,0 und 1,0 zurück, z.B. 0,9873.",
        "webhook-functions-help.component.tab.func.ABS" : "Gibt den absoluten Wert des angegebenen Wertes zurück, z.B. ergibt ABS(-1) den Wert 1.",
        "webhook-functions-help.component.tab.func.POWER" : "Gibt die Potenz des angegebenen Wertes und des Exponenten zurück, z.B. POWER(4.0 , 2.0) ergibt 16.0.",
        "webhook-functions-help.component.tab.func.SQRT" : "Gibt die Quadratwurzel des angegebenen Wertes zurück, z.B. SQRT(16.0) ergibt den Wert 4.0.",

        "webhook-functions-help.component.tab.func.YEAR" : "Gibt das Jahr des angegebenen Zeitstempels zurück, z.B. für 2021-12-01 08:49:27 PM wird der Wert 2021 zurückgegeben.",
        "webhook-functions-help.component.tab.func.MONTH" : "Gibt den Monat des angegebenen Zeitstempels zurück, z.B. für 2021-12-01 08:49:27 PM wird der Wert 12 zurückgegeben (Januar = 1, Februar = 2, ..., Dezember = 12).",
        "webhook-functions-help.component.tab.func.DAY_OF_WEEK" :"Gibt den Index des Wochentags des angegebenen Zeitstempels zurück, wobei der Montag als erster Tag der Woche gilt. Für 2021-12-01 08:49:27 PM wird der Wert 3 (Mittwoch) zurückgegeben.",
        "webhook-functions-help.component.tab.func.DAY_OF_YEAR" : "Gibt den Tag im Jahr für den angegebenen Zeitstempel zurück, z.B. für 2021-12-01 08:49:27 PM wird der Wert 335 zurückgegeben.",
        "webhook-functions-help.component.tab.func.DAY_OF_MONTH" : "Gibt den Tag im Monat für den angegebenen Zeitstempel zurück z.B. für 2021-12-01 08:49:27 PM wird der Wert 1 zurückgegeben.",
        "webhook-functions-help.component.tab.func.FORMAT_WEEK" : "Gibt die Kalenderwoche im Jahr für den angegebenen Zeitstempel zurück z.B. für 2021-12-01 08:49:27 PM wird der Wert 51 zurückgegeben.",
        "webhook-functions-help.component.tab.func.FORMAT_YEAR_WEEK" : "Gibt das Jahr und die Kalenderwoche für den angegebenen Zeitstempel zurück z.B. für 2021-12-01 08:49:27 PM wird der Wert 2021-51 zurückgegeben.",
        "webhook-functions-help.component.tab.func.FORMAT_YEAR_MONTH" : "Gibt das Jahr und den Monat für den angegebenen Zeitstempel zurück z.B. für 2021-12-01 08:49:27 PM wird der Wert 2021-12 zurückgegeben.",
        "webhook-functions-help.component.tab.func.FORMAT_QUARTER" : "Gibt das Quartal im Jahr für den angegebenen Zeitstempel zurück z.B. für 2021-12-01 08:49:27 PM wird der Wert 04 zurückgegeben.",
        "webhook-functions-help.component.tab.func.FORMAT_YEAR_QUARTER" : "Gibt das Jahr und das Quartal im Jahr für den angegebenen Zeitstempel zurück z.B. für 2021-12-01 08:49:27 PM wird der Wert 2021-04 zurückgegeben.",
        "webhook-functions-help.component.tab.func.FORMAT_ISO_DATE" : "Gibt das ISO formatierte Datum für den angegebenen Zeitstempel zurück z.B. für 2021-12-01 08:49:27 PM wird der Wert 2021-12-01 zurückgegeben.",
        "webhook-functions-help.component.tab.func.FORMAT_ISO_DATE_TIME" : "Gibt den ISO formatierten Zeitstempel für den angegebenen Zeitstempel zurück z.B. für 2021-12-01 08:49:27 PM wird der Wert 2021-12-01T08:49:27 zurückgegeben.",
        
        "webhook-functions-help.component.tab.func.RANDOM_ID" : "Gibt eine zufällig generierte UUID zurück.",
        "webhook-functions-help.component.tab.func.UUID" : "Gibt eine UUID basierend auf der Zeichenkette zurück.",
        "webhook-functions-help.component.tab.func.UUID_BYTES" : "Gibt eine UUID basierend auf das bytearray zurück",
        "webhook-functions-help.component.tab.func.UUID_STRINGS" : "Gibt eine UUID basierend auf der String-Liste zurück.",
        
        "webhook-functions-help.component.tab.func.BASE64_ENCODE" : "Gibt den BASE64 verschlüsselten String der übergebenen Zeichenkette zurück.",
    
        "webhook-functions-help.component.tab.func" : "Funktion",
        "webhook-functions-help.component.tab.description" : "Beschreibung",
        "webhook-functions-help.component.tab.returnValue" : "Rückgabewert"
    }

}
        
</i18n>