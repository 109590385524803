import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue';
import Vuetify from 'vuetify/lib';


import CoreTheme from '@/core/theme';
import CustTheme from '@/cust/theme';
import { get } from 'lodash';



const options = {

    icons: {
      iconfont: 'md',
    },


    theme: {

        dark: get(CustTheme, 'dark', CoreTheme.dark),
        
        themes: {
          light: get(CustTheme, 'themes.light', CoreTheme.themes.light), 
  
          dark: get(CustTheme, 'themes.dark', CoreTheme.themes.dark),
        }
      },
      

};

Vue.use(Vuetify);

/*
options = {

  iconfont: 'md',
  
  lang: {
    locales: { de, en },
    current: 'de'
  },

  theme: {
    primary: '#0E3A5A',
    secondary: '#52BBB5',
    tertiary: '#F0F0F0',
    serious: '#0E3A5A',  
    playful: '#52BBB5',
    clear: '#F0F0F0',
    accent: '#F50057',
    error: Colors.red.base,
    warning: '#fdd835',
    info: Colors.blue.base,
    success: Colors.green.base
  },
  
  components: { VLayout }
};
*/
export default new Vuetify(options);
