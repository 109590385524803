import api from '@/store/api';

export default {

    namespaced: true,

    state: {
        response: getDefaultResponse()
    },

    mutations: {

        setResponse(state, response) {
            state.response = response;

        },
    },

    actions: {

        async loadResponse(context) {
            try {
                let response = await api.system.serviceAvailability.getStatus();
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        }
    },

    getters: {

        response(state) {
            return state.response ? state.response : getDefaultResponse();
        }
    }
}


function getDefaultResponse() {
    return '[]';
}