import { get } from 'lodash';
import api from '../../../api/battery-data-hub.api';

export default {
    namespaced: true,

    state: {
        batteryModel: createDefaultBatteryModel(),
        batteryWeight: null,
        batteryChemistry: null,
        idOfEuDeclerationOfConformity: null,
        euDeclerationOfConformity: null,
        testReports: [],
        batteryMaterialCompositions: [],
        batteryMaterials: [],
        batteryCategories: getDefaultResponse(),
        manufacturer: getDefaultResponse()

    },
    mutations: {
        setBatteryModel(state, batteryModel) {

            console.log("=====================================")
            console.log(JSON.stringify(batteryModel, true, 2))
            console.log("=====================================")

            if (batteryModel) {
                state.batteryModel.creationDateTime = batteryModel.creationDateTime ? batteryModel.creationDateTime : null;
                state.batteryModel.creationUser = batteryModel.creationUser ? batteryModel.creationUser : null;
                state.batteryModel.modificationDateTime = batteryModel.modificationDateTime ? batteryModel.modificationDateTime : null;
                state.batteryModel.modificationUser = batteryModel.modificationUser ? batteryModel.modificationUser : null;
                state.batteryModel.tenantId = batteryModel.tenantId ? batteryModel.tenantId : null;
                state.batteryModel.name = batteryModel.name ? batteryModel.name : null;
                state.batteryModel.i18n = batteryModel.i18n ? batteryModel.i18n : {};
                state.batteryModel.active = batteryModel.active ? batteryModel.active : true;
                state.batteryModel.references = batteryModel.references ? batteryModel.references : {};
                state.batteryModel.data.batteryWeight = batteryModel.data.batteryWeight ? batteryModel.data.batteryWeight : 0;
                state.batteryModel.data.batteryChemistry = batteryModel.data.batteryChemistry ? batteryModel.data.batteryChemistry : null;
                state.batteryModel.data.testReports = batteryModel.data.testReports ? batteryModel.data.testReports : [];
                state.batteryModel.data.idOfEuDeclerationOfConformity = batteryModel.data.idOfEuDeclerationOfConformity ? batteryModel.data.idOfEuDeclerationOfConformity : null;
                state.batteryModel.data.euDeclerationOfConformity = batteryModel.data.euDeclerationOfConformity ? batteryModel.data.euDeclerationOfConformity : null;
            }

        },

        setBatteryWeight(state, batteryWeight) {

            if (batteryWeight) {
                state.batteryModel.data.batteryWeight = batteryWeight;
            } else {
                state.batteryModel.data.batteryWeight = 0;
            }
        },
        setBatteryChemistry(state, batteryChemistry) {
            if (batteryChemistry) {
                state.batteryModel.data.batteryChemistry = batteryChemistry;
            }
            else {
                state.batteryModel.data.batteryChemistry = null;
            }
        },

        setIdOfEuDeclerationOfConformity(state, idOfEuDeclerationOfConformity) {
            if (idOfEuDeclerationOfConformity) {
                state.batteryModel.data.idOfEuDeclerationOfConformity = idOfEuDeclerationOfConformity;
            }
        },
        setEuDeclerationOfConformity(state, euDeclerationOfConformity) {
            if (euDeclerationOfConformity) {
                state.batteryModel.data.euDeclerationOfConformity = euDeclerationOfConformity;
            }
        },
        setTestReports(state, testReports) {
            if (testReports) {
                state.batteryModel.data.testReports = testReports ? testReports.map(report => report.value) : [];
            }
            else {
                state.batteryModel.data.testReports = [];
            }
        },
        setBatteryMaterialCompositionItems(state, batteryMaterialCompositionItems) {
            if (batteryMaterialCompositionItems) {
                state.batteryMaterialCompositions.items = batteryMaterialCompositionItems;
            }
        },
        setBatteryMaterialCompositions(state, batteryMaterialCompositions) {
            if (batteryMaterialCompositions) {
                state.batteryMaterialCompositions = batteryMaterialCompositions;

            }
        },
        setBatteryMaterials(state, batteryMaterials) {

            if (batteryMaterials && Array.isArray(batteryMaterials.items)) {
                state.batteryMaterials = batteryMaterials.items;
            } else {
                state.batteryMaterials = [];
            }

        },

        setCategories(state, batteryCategories) {
            if (batteryCategories) {
                let items = [];
                if (batteryCategories.items) {
                    state.batteryCategories = batteryCategories.items;
                } else {
                    state.batteryCategories = items;
                }

                if (batteryCategories.tenantId) {
                    state.batteryCategories.tenantId = batteryCategories.tenantId;
                }

                if (batteryCategories.pagination) {
                    state.batteryCategories.pagination = batteryCategories.pagination;
                }
            }
        },
        setManufacturer(state, manufacturer) {
            if (manufacturer) {

                let items = [];
                if (manufacturer.items) {
                    state.manufacturer.items = manufacturer.items;
                } else {
                    state.manufacturer.items = items;
                }

                if (manufacturer.tenantId) {
                    state.manufacturer.tenantId = manufacturer.tenantId;
                }

                if (manufacturer.pagination) {
                    state.manufacturer.pagination = manufacturer.pagination;
                }

            }
        },
        async setManufacturerId(state, manufacturerId) {
            if (manufacturerId) {

                state.batteryModel.references.manufacturerId = manufacturerId;

            } else {
                state.batteryModel.references.manufacturerId = null;
            }
        },

        setBatteryCategory(state, batteryCategory) {
            if (batteryCategory) {
                state.batteryModel.references.batterycategoryId = batteryCategory;
            } else {
                state.batteryModel.references.batterycategoryId = null;
            }
        }


    },
    actions: {

        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.batteryModels.findById(name);
                context.commit('setBatteryModel', get(response, 'item.batteryModel', createDefaultBatteryModel()));
            } catch (error) {
                context.commit('setBatteryModel', createDefaultBatteryModel());
                throw error;
            }

            try {
                let batteryMaterialCompositions = await api.masterdata.batteryModels.findCompositionByBatteryModelId(name);

                context.commit('setBatteryMaterialCompositions', batteryMaterialCompositions, []);

            } catch (error) {
                context.commit('setBatteryMaterialCompositions', []);
                throw error;
            }
            try {
                let batteryMaterials = await api.masterdata.batteryMaterials.findByFilter('*', 0, 100);
                context.commit('setBatteryMaterials', batteryMaterials, []);

            } catch (error) {
                context.commit('setBatteryMaterials', []);
                throw error;
            }

            try {
                let response = await api.masterdata.batteryModels.findBatteryCategoriesByFilter('*', 0, 100);
                context.commit('setCategories', response);
            } catch (error) {
                context.commit('setCategories', getDefaultResponse());
                throw error;
            }

        },

        async loadManufacturers(context) {

            try {
                let manufacturer = await api.masterdata.manufacturers.findByFilter('*', 0, 100);
                await context.commit('setManufacturer', manufacturer);
            } catch (error) {
                context.commit('setManufacturer', getDefaultResponse());
                throw error;
            }
        },




        async saveData(context, { name }) {

            try {

                const payload = get(context.getters, 'batteryModel');
                let response = await api.masterdata.batteryModels.update(payload);
                context.commit('setBatteryModel', get(response, 'item.batteryModel', createDefaultBatteryModel()));
            } catch (error) {
                console.log(error);
                context.commit('setBatteryModel', createDefaultBatteryModel());
                throw error;

            }
            try {
                const compositions = get(context.getters, 'batteryMaterialCompositionItems');
                let response = await api.masterdata.batteryMaterialCompositions.updateBulk(compositions, name);
                context.commit('setBatteryMaterialCompositions', response, []);

            } catch (error) {
                console.log(error);
                context.commit('setBatteryMaterialCompositions', []);
                throw error;

            }
        },

        async updateBatteryModel(context, batteryModel) {
            context.commit('setBatteryModel', batteryModel, createDefaultBatteryModel());
        },

        async updateBatteryWeight(context, batteryWeight) {
            context.commit('setBatteryWeight', batteryWeight, 0);
        },

        async updateBatteryChemistry(context, batteryChemistry) {
            context.commit('setBatteryChemistry', batteryChemistry, 0);
        },
        async updateIdOfEuDeclerationOfConformity(context, idOfEuDeclerationOfConformity) {
            context.commit('setIdOfEuDeclerationOfConformity', idOfEuDeclerationOfConformity, 0);
        },
        async updateEuDeclerationOfConformity(context, euDeclerationOfConformity) {
            context.commit('setEuDeclerationOfConformity', euDeclerationOfConformity, 0);
        },

        async updateTestReports(context, testReports) {
            context.commit('setTestReports', testReports);
        },
        async updateBatteryMaterialCompositions(context, batteryMaterialCompositionItems) {
            context.commit('setBatteryMaterialCompositionItems', batteryMaterialCompositionItems);
        },
        async updateBatteryCategory(context, batteryCategory) {
            try {
                context.commit('setBatteryCategory', batteryCategory);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async updateManufacturerId(context, manufacturerId) {
            try {
                context.commit('setManufacturerId', manufacturerId);
            } catch (error) {
                return Promise.reject(error);
            }

        },

    },
    getters: {
        batteryModel(state) {
            return state.batteryModel;
        },

        batteryWeight(state) {
            return get(state, 'batteryModel.data.batteryWeight', 0);
        },

        batteryChemistry(state) {
            return get(state, 'batteryModel.data.batteryChemistry', null);
        },

        idOfEuDeclerationOfConformity(state) {
            return get(state, 'batteryModel.data.idOfEuDeclerationOfConformity');
        },

        euDeclerationOfConformity(state) {
            return get(state, 'batteryModel.data.euDeclerationOfConformity');
        },
        testReports(state) {
            const originalTestReports = get(state, 'batteryModel.data.testReports', []);
            return originalTestReports.map(report => ({ value: report }));
        },
        batteryMaterialCompositions(state) {

            return get(state, 'batteryMaterialCompositions', []);
        },
        batteryMaterialCompositionItems(state) {
            return get(state, 'batteryMaterialCompositions.items', []);
        },

        batteryMaterials(state) {
            const batteryMaterialCompositionList = get(state, 'batteryMaterialCompositions.items', []);
            const compositionMaterialIds = batteryMaterialCompositionList.map(item => get(item, 'references.batterymaterialId'));
            const batteryMaterialItems = get(state, 'batteryMaterials', []);
            const filteresMaterialItems = batteryMaterialItems.filter(material => !compositionMaterialIds.includes(material.name));

            return filteresMaterialItems.sort((a, b) => a.name.localeCompare(b.name));
        },

        batteryCategories(state) {
            return state.batteryCategories ? state.batteryCategories : getDefaultResponse();
        },
        batteryCategory(state) {
            return get(state, 'batteryModel.references.batterycategoryId');
        },
        manufacturer(state) {
            return state.manufacturer ? state.manufacturer : getDefaultResponse();
        },
        manufacturerId(state) {
            return get(state, 'batteryModel.references.manufacturerid');
        },

    }

}

function createDefaultBatteryModel() {

    return {
        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "batteryWeight": 0,
            "batteryChemistry": null,
            "idOfEuDeclerationOfConformity": null,
            "euDeclerationOfConformity": null,
            "testReports": []
        },
        "active": true,
        "references": {
            "batterycategoryid": null,
            "manufacturerid": null
        },
    }
}
function getDefaultResponse() {
    return {
        tenantId: "",
        items: [],
        pagination: {}
    };
}


