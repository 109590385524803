import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state: {
        tenantId: "",
        name: "",
        manufacturerplant: createEmptyResponse(),
        manufacturer:  createEmptyManufacturer()
    },

    mutations: {

        async setManufacturerId(state, manufacturerId) {
            if (manufacturerId) {
                //const payload = await api.masterdata.manufacturer.findById(manufacturerId);
                state.manufacturerplant.references.manufacturerid = manufacturerId;
                
            } else {
                state.manufacturerplant.references.manufacturerid = null;
            }
        }, 


        setManufacturerplant(state, manufacturerplant) {
            
            if (manufacturerplant) {
                state.name = manufacturerplant.name ? manufacturerplant.name : null;
                state.manufacturerplant.tenantId = manufacturerplant.tenantId ? manufacturerplant.tenantId : null;
                state.manufacturerplant.name = manufacturerplant.name ? manufacturerplant.name : null;
                state.manufacturerplant.i18n = manufacturerplant.i18n ? manufacturerplant.i18n : {};
                state.manufacturerplant.data = manufacturerplant.data ? manufacturerplant.data : { custData: {} };
                state.manufacturerplant.active = manufacturerplant.active ? manufacturerplant.active : true;
                state.manufacturerplant.references.manufacturerid = manufacturerplant.references.manufacturerid ? manufacturerplant.references.manufacturerid : null;
                state.manufacturerplant.creationDateTime = manufacturerplant.creationDateTime ? manufacturerplant.creationDateTime : null;
                state.manufacturerplant.creationUser = manufacturerplant.creationUser ? manufacturerplant.creationDateTime : null;
                state.manufacturerplant.modificationDateTime = manufacturerplant.modificationDateTime ? manufacturerplant.modificationDateTime : null;
                state.manufacturerplant.modificationUser = manufacturerplant.modificationUser ? manufacturerplant.modificationUser : null;
            } else {
                state.manufacturerplant.tenantId = null;
                state.manufacturerplant.name = null;
                state.manufacturerplant.i18n = null;
                state.manufacturerplant.data = null;
                state.manufacturerplant.active = null;
                state.manufacturerplant.references = null;
                state.manufacturerplant.creationDateTime = null;
                state.manufacturerplant.modificationDateTime = null;
                state.manufacturerplant.creationUser         = null;
                state.manufacturerplant.modificationUser = null;
            }

        },
        setManufacturer(state, manufacturer) {
            if (manufacturer) {

                let items = [];
                if (manufacturer.items) {
                    state.manufacturer.items = manufacturer.items;
                } else {
                    state.manufacturer.items = items;
                }

                if (manufacturer.tenantId) {
                    state.manufacturer.tenantId = manufacturer.tenantId;
                }

                if (manufacturer.pagination) {
                    state.manufacturer.pagination = manufacturer.pagination;
                }

            }
        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        },
        

    },

    actions:{

        async loadManufacturers(context) {

            try {
                let manufacturer = await api.masterdata.manufacturers.findByFilter('*', 0, 100);
                console.log(manufacturer);
                console.log("***************************************");
                await context.commit('setManufacturer', manufacturer);
            } catch (error) {
                context.commit('setManufacturer', createEmptyResponse());
                throw error;
            }
        },


        async createManufacturerplant(context) {

            try {
                
                let request = context.getters.request;
                console.log(request)
                
                await api.masterdata.manufacturerplant.create(request);
            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async updateManufacturerId(context, manufacturerId) {
            try {
                context.commit('setManufacturerId', manufacturerId);
            } catch (error) {
                return Promise.reject(error);
            }

        },
       
        async resetManufacturerplant(context) {
            context.commit('setManufacturerplant', createEmptyResponse());
        },

        async updateManufacturerplant(context, manufacturerplant) {

            try {
                context.commit('setManufacturerplant', manufacturerplant);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateManufacturerplants(context, manufacturerplant) {

            try {
                context.commit('setManufacturerplant', manufacturerplant);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.manufacturerplant;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        manufacturerplant(state) {
            return state.manufacturerplant ? state.manufacturerplant : createEmptyResponse();
        },

        manufacturer(state) {
            return state.manufacturer ? state.manufacturer : createEmptyResponse();
        },

        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },
        manufacturerId(state) {
            return get(state, 'manufacturerplant.references.manufacturerid');
        },

    }
}

function createEmptyResponse() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { street: null,
            zipCode: null,
            city: null,
            country: null,
            province: null,
            building: null,
         },
        "active": true,
        "references": {
            manufacturerid: null
        }

    }
}


function createEmptyManufacturer() {

    return {

        "items": ["Healthy","Edible","Bad Smell"]
        
    }
}
