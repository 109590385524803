<template>
  <v-card flat tile>
      <v-subheader>{{$t('detection-rules-editor.component.title')}}</v-subheader>
      <!-- <v-card-text>
        {{$t('detection-rules-editor.component.subtitle')}}
      </v-card-text> -->
      <v-card-text>
                <v-text-field 
                    :disabled="!isNewItem"
                    v-model="name"
                    filled
                    :rules="[
                        v => !!v || $t('detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.validation.notEmpty'),
                    ]"
                    :label="$t('detection-rules-editor.component.fields.name')"
                />
        </v-card-text>
        <v-divider/>
        <v-card-text class="text-subtitle-1 font-weight-medium pb-3">{{$t('detection-rules-editor.component.sections.expression.title')}}</v-card-text>
        <v-card-text>
        {{$t('detection-rules-editor.component.sections.expression.subtitle')}}
        </v-card-text>
        <v-card-text>
            <dxs-detection-rules-expression-input
                v-model="expression" 
                :label="$t('detection-rules-editor.component.fields.expression')"
                filled 
            />
        </v-card-text>
        <v-card-text>
            <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div><v-icon color="accent" class="mr-2">help</v-icon>{{$t('detection-rules-editor.component.sections.expression.writeRule')}}</div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card tile flat>
                            <v-card-text>
                                {{$t('detection-rules-editor.component.sections.expression.information')}}
                            </v-card-text>
                            <dxs-detection-rules-expression-help :value="context" />
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
        <v-divider/>
            <v-card-text>
                <v-switch 
                    v-model="active" 
                    :label="$t('detection-rules-editor.component.fields.active')" 
                    inset 
                    color="accent" 
                />
            </v-card-text>
        <v-divider/>
        <v-card-text class="text-subtitle-1 font-weight-medium pb-3">{{$t('detection-rules-editor.component.fields.severity.title')}}</v-card-text>
        <v-card-text>
            <c-item-radio-single-selector 
                v-model="severity"  
                :items="severities"
                hideFilter
            >
            <template v-slot:list-item-subtitle="props" >
                <span>{{$dxs.i18n.localizedDescription(props.item, $i18n.locale)}}</span>
            </template>
            </c-item-radio-single-selector>
        </v-card-text>
        <v-divider />
        <v-card-text>
            <div class="text-subtitle-1 font-weight-medium pb-3">
                {{ $t("detection-rules-editor.component.sections.i18n.title") }}
            </div>
            <div>
                {{
                $t("detection-rules-editor.component.sections.i18n.subtitle")
                }}
            </div>
            <c-i18n-editor
                v-model="i18n"
                header-style="lite"
                flat
                :return-object="true"
            />
        </v-card-text>

  </v-card>
</template>

<script>
import { get, set } from 'lodash';

import DetectionRulesExpressionInputComponent from './detection-rules-expression-input.component';
import DetectionRulesExpressionHelpComponent from './detection-rules-expression-help.component';

export default {

    name: 'detection-rules-editor',

    props: {

        value: {
            type: Object,
            required: true
        },

        context: {
            type: Object,
            required: false
        },

        isNewItem: {
            type: Boolean,
            required: false
        }
        
    },

    components: {
        'dxs-detection-rules-expression-input' : DetectionRulesExpressionInputComponent,
        'dxs-detection-rules-expression-help' : DetectionRulesExpressionHelpComponent
    },

    data: () => ({

      severities: [{
                        name: 'LOW', 
                        i18n: {
                            en : {
                                name: 'Low',
                                description: "The severity of the event is low."
                            },

                            de :  {
                                name: 'Niedrig',
                                description: "Der Schweregrad des Events ist niedrig."
                            }
                        }
                    },{
                        name: 'HIGH', 
                        i18n: {
                            en : {
                                name: 'High',
                                description: "The severity of the event is high."
                            },

                            de :  {
                                name: 'Hoch',
                                description: "Der Schweregrad des Events ist hoch."
                            }
                        }
                    },{
                        name: 'CRITICAL', 
                        i18n: {
                            en : {
                                name: 'Critical',
                                description: "The severity of the event is critical."
                            },

                            de :  {
                                name: 'Kritisch',
                                description: "Der Schweregrad des Events ist kritisch."
                            }
                        }
                    }]
    

    }),

    computed: {
        name: {
            get() {
                return get(this.value, 'name', null); 
            },

            set(value) {
                set(this.value, 'name', value);
            }
        },

        active: {
            get() {
                return get(this.value, 'active', null); 
            },

            set(value) {
                set(this.value, 'active', value);
            }
        },

        severity: {
            get() {
                return get(this.value, 'severity', null); 
            },

            set(value) {
                set(this.value, 'severity', value);
            }
        },

        i18n: {
            get() {
                return get(this.value, 'i18n', null); 
            },

            set(value) {
                set(this.value, 'i18n', value);
            }
        },
        expression: {
            get() {
                return get(this.value, 'expression', ""); 
            },

            set(value) {
                set(this.value, 'expression', value);
            }
        },
    }
}
</script>

<i18n>
{
    "en" : {
        
        
        "detection-rules-editor.component.title" : "Detection Job Rule",
        "detection-rules-editor.component.subtitle" : "Please specify the rule.",

        "detection-rules-editor.component.fields.active" : "Active",

        "detection-rules-editor.component.fields.name" : "Technical name",

        "detection-rules-editor.component.fields.severity.title" : "Severity",

        "detection-rules-editor.component.sections.expression.title" : "Rule Expression",
        "detection-rules-editor.component.sections.expression.subtitle" : "Define a Rule expression to use for detection.",
        "detection-rules-editor.component.fields.expression" : "Rule Expression",

        "detection-rules-editor.component.sections.expression.writeRule" : "Write Rule Expression",
        "detection-rules-editor.component.sections.expression.information" : "Please select one of the topics below for detailed information.",

        "detection-rules-editor.component.sections.i18n.title" : "Localization and Translations",
        "detection-rules-editor.component.sections.i18n.subtitle" : "You can translate the name of this object into different languages. The system will display the translated names according to the user's language."

    },

    "de" : {

        
        "detection-rules-editor.component.title" : "Detection Job Regel",
        "detection-rules-editor.component.subtitle" : "Bitte bestimmen Sie die Regel.",

        "detection-rules-editor.component.fields.active" : "Active",

        "detection-rules-editor.component.fields.name" : "Technischer Name",

        "detection-rules-editor.component.fields.severity.title" : "Schweregrad",

        "detection-rules-editor.component.sections.expression.title" : "Regelausdruck",
        "detection-rules-editor.component.sections.expression.subtitle" : "Definieren Sie einen Regelausdruck der zur Detection genutzt werden soll.",
        "detection-rules-editor.component.fields.expression" : "Regelausdruck",

        "detection-rules-editor.component.sections.expression.writeRule" : "Regelausdruck schreiben",
        "detection-rules-editor.component.sections.expression.information" : "Wählen Sie bitte eines der untenstehenden Themen für detaillierte Informationen aus.",

        "detection-rules-editor.component.sections.i18n.title" : "Lokalisierung und Übersetzung",
        "detection-rules-editor.component.sections.i18n.subtitle" : "Sie können den Namen dieses Objekts in verschiedene Sprachen übersetzen. Im System werden die übersetzten Namen passend zur eingestellten Sprache angezeigt."
    }
}
</i18n>