import api from '@/store/api';
import Configuration from '@/utils/configuration';
const TENANT_ID = Configuration.value('TENANT_ID');

export default {

    namespaced: true,

    state: {

        collectionSetting: {
            name: "",
            schemaId: null,
            idTemplate: null,
            partitionIdTemplate: null,
            embeddedCollections: []
        },

        schemas: []
    },

    mutations: {

        setCollectionSetting(state, collectionSetting) {

            if (collectionSetting) {
                state.collectionSetting.name = collectionSetting.name ? collectionSetting.name : "";
                state.collectionSetting.schemaId = collectionSetting.schemaId ? collectionSetting.schemaId : null;
                state.collectionSetting.idTemplate = collectionSetting.idTemplate ? collectionSetting.idTemplate : null;
                state.collectionSetting.partitionIdTemplate = collectionSetting.partitionIdTemplate ? collectionSetting.partitionIdTemplate : null;
                state.collectionSetting.embeddedCollections = collectionSetting.embeddedCollections ? collectionSetting.embeddedCollections : []
            } else {
                state.collectionSetting.name = "";
                state.collectionSetting.schemaId = null;
                state.collectionSetting.idTemplate = null;
                state.collectionSetting.partitionIdTemplate = null;
                state.collectionSetting.embeddedCollections = []
            }

        },

        setSchemas(state, schemas) {


            if (schemas) {
                state.schemas = schemas;

            } else {
                state.schemas = [];
            }
        }
    },

    actions: {

        async createCollectionSetting(context) {

            try {

                let request = context.getters.request;
                await api.catalog.collectionSettings.create(request.name, request);

            } catch (error) {

                console.log("An error has occured" + error);
                return Promise.reject(error);
            }
        },

        async loadSchemas(context) {

            try {
               let data =  await api.catalog.schemas.findAll('*');
               context.commit('setSchemas', data && data.items ? data.items : []);
            } catch (error) {

                console.log("An error has occured" + error);
                return Promise.reject(error);
            }
        },

        async reset(context) {
            context.commit('setCollectionSetting', {name : "", schema: null, idTemplate: null, partitionIdTemplate: null})
        },

        async updateCollectionSetting(context, collectionSetting) {

            try {
                context.commit('setCollectionSetting', collectionSetting);
            } catch (error) {
                return Promise.reject(error);
            }
        },

    },



    getters: {


        request(state) {
            return {
                name: state.collectionSetting.name,
                collectionId : state.collectionSetting.name,
                schemaId : state.collectionSetting.schemaId,
                idTemplate: state.collectionSetting.idTemplate,
                partitionIdTemplate: state.collectionSetting.partitionIdTemplate,
                i18n : {},
                custData : {},
                systemCollection: false,
                exportSettings : {},
                clientSettings: {
                    listDialogUrlTemplate: "/collections/${collectionId}/documents",
                    detailDialogUrlTemplate: "/collections/${collectionId}/documents/${documentId}",
                    distribution: {
                        timeIntervals: []
                    }
                },
                loggingSettings: {
                    documentChangeLogging: "TENANT",
                    queryLogging: "TENANT"
                },
                
                // DXS-935 Added new default values for the ingestion topics.
                dataIngestion: {
                    deadLetter: {
                        topic: `dxs-${TENANT_ID}-core-deadletter-in`
                    },
                    document: {
                        topic: `dxs-${TENANT_ID}-core-document-in`
                    },
                    documentRecord: {
                        topic: `dxs-${TENANT_ID}-core-documentRecord-in`
                    }
                },
                
                embeddedCollections: state.collectionSetting.embeddedCollections,
                creationDateTime: null,
                creationUser: null,
                modificationDateTime: null,
                modificationUser: null
            };
        },

        collectionSetting(state) {
            return state.collectionSetting ? state.collectionSetting : {name: "", schemaId: null, idTemplate: null, partitionIdTemplate: null, embeddedCollections: []}
        },

        schemas(state) {
            return state.schemas ? state.schemas : [];
        }
    }
}