import api from '@/store/api';

export default {

    namespaced: true,

    state: {

        response: {
            
            data: []
        },


    },

    mutations: {

        appendResponse(state, response) {

            // Push additional data into the response
            /*
            if (response) {

                for (let item of response) {
                    state.response.data.push(item);
                }
            }*/

            
            if (response) {
                
                // While no pagination is in place... avoid appending and reset array instead.
                state.response.data = [];

                for (let item of response) {
                    state.response.data.push(item);
                }
            }

        },

    },

    actions: {

        async loadMore(context) {

            try {

                let response = await api.catalog.findAllCollectionSettings();
                context.commit('appendResponse', response);
                
            } catch (error) {

                console.log("An error has occured" + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('appendResponse', []);
              }
        },
    },

    getters: {

        response(state) {
            return state.response ? state.response : { data: [] };
        },
    }
}