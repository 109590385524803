<template>
  <div class="secondary darken-1">

    <v-breadcrumbs>
      <v-breadcrumbs-item
        :to="{ name: 'collectionList' }"
        :disabled="false"
      >{{$t('prediction-scenario-editor.breadcrumbs.root')}}</v-breadcrumbs-item>
      <v-breadcrumbs-divider>
        <v-icon>chevron_right</v-icon>
      </v-breadcrumbs-divider>
      <v-breadcrumbs-item>{{modelId}}</v-breadcrumbs-item>
    </v-breadcrumbs>

    <c-section
      :title="title"
      :subtitle="$t('prediction-scenario-editor.view.sections.common.subtitle', $i18n.locale)"
      class="secondary"
    >
      <v-container fluid class="secondary">
        <v-row justify="center">
          <v-col cols="12" lg="9">
            <v-card tile>
              <v-card-text>
                <div class="title">{{$t('prediction-scenario-editor.view.sections.scenario.title', $i18n.locale)}}</div>
              </v-card-text>

              <v-divider />
              <v-simple-table>
                <thead>
                  <tr>
                    <th>{{$t('prediction-scenario-editor.view.sections.scenario.table.header.attribute', $i18n.locale)}}</th>
                    <th>{{$t('prediction-scenario-editor.view.sections.scenario.table.header.value', $i18n.locale)}}</th>
                    <th>{{$t('prediction-scenario-editor.view.sections.scenario.table.header.description', $i18n.locale)}}</th>
                  </tr>
                </thead>
                <tbody class="body">
                  
                  <tr>
                    <td>{{$t('prediction-scenario-editor.view.sections.scenario.table.rows.targetValue')}}</td>
                    <td>{{targetAttribute | localized-name($i18n.locale)}}</td>
                    <td>Dieser Wert wird im Rahmen dieses Szenarios durch die Modelle vorhergesagt.</td>
                  </tr>
                  <tr>
                    <td>{{$t('prediction-scenario-editor.view.sections.scenario.table.rows.collection')}}</td>
                    <td>{{collectionSettings | localized-name($i18n.locale)}}</td>
                    <td>Das Szenario verwendet diesen Datenbereich als Grundlage für das Training seiner Modelle</td>
                  </tr>
                  <tr>
                    <td>{{$t('prediction-scenario-editor.view.sections.scenario.table.rows.schema')}}</td>
                    <td>{{schema | localized-name($i18n.locale)}}</td>
                    <td>Dieses Schema definiert die Datenstruktur.</td>
                  </tr>
                  
                </tbody>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </c-section>


    <c-section class="secondary lighten-1"
      :title="$t('prediction-scenario-editor.view.sections.i18n.title', $i18n.locale)"
      :subtitle="$t('prediction-scenario-editor.view.sections.i18n.subtitle', $i18n.locale)">
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12" lg="9">
              <c-locale-list :value="i18n" v-on:on-value-update="oni18NUpdate" />
          </v-col>
        </v-row>
      </v-container>
    </c-section>

    <c-section
      class="secondary"
      :title="$t('prediction-scenario-editor.view.sections.training.title', $i18n.locale)"
      :subtitle="$t('prediction-scenario-editor.view.sections.training.subtitle', $i18n.locale)"
    >
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12" lg="9">
            <v-card tile>
              <v-btn absolute dark fab top right color="accent" @click="showEditDialog">
                <v-icon>add</v-icon>
              </v-btn>
              <v-card-text>
                <div class="title">{{$t('prediction-scenario-editor.view.sections.training.title', $i18n.locale)}}</div>
              </v-card-text>
              <v-simple-table v-if="!empty">
                <thead>
                  <tr>
                    <th>{{$t('prediction-scenario-editor.view.sections.configuration.table.header.delete', $i18n.locale)}}</th>
                    <th>ID</th>
                    <th class="d-none d-sm-table-cell">{{$t('prediction-scenario-editor.view.sections.configuration.table.header.lastTraining', $i18n.locale)}}</th>
                    <th class="d-none d-sm-table-cell">{{$t('prediction-scenario-editor.view.sections.configuration.table.header.predictionAccuracy', $i18n.locale)}}</th>
                    <th class="d-none d-sm-table-cell">{{$t('prediction-scenario-editor.view.sections.configuration.table.header.minAccuracy', $i18n.locale)}}</th>
                    <th class="d-none d-sm-table-cell">{{$t('prediction-scenario-editor.view.sections.configuration.table.header.maxDuration', $i18n.locale)}}</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="model in models" :key="model.name">
                      <td>
                      <v-btn icon @click="deleteConfiguration(configuration.id)">
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </td>
                    <td>{{model.name}}</td>
                    <td class="d-none d-sm-table-cell">Stat 1</td>
                    <td class="d-none d-sm-table-cell">Stat 2</td>
                    <td class="d-none d-sm-table-cell">Stat 3</td>
                    <td class="d-none d-sm-table-cell">Stat 4</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-card-text class="grey lighten-3" v-else>
                <p />
                <div class="subtitle-1 text-center">{{$t('prediction-scenario-editor.view.sections.configuration.table.empty.title', $i18n.locale)}}</div>
                <div class="text-center">{{$t('prediction-scenario-editor.view.sections.configuration.table.empty.subtitle', $i18n.locale)}}</div>
                <p />
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </c-section>

  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const MODULE_NAME = "predictionScenarioDetail";
const { mapGetters } = createNamespacedHelpers(MODULE_NAME);

export default {
  name: 'prediction-scenario-editor',

  props: {
    modelId: {
      type: String,
      required: true
    },

    options: {
      type: Object,
      required: false
    }
  },

  inject: ['progressIndicatorService'],

  data: () => ({
    selectedConfigurationId : null,
  }),

  computed: {
    title() {
      return this.modelId;
    },

    empty() {
        return this.models && this.models.length == 0;
    },

    targetAttribute() {
        if ( this.scenario.targetAttribute ) {
            let attribute = this.schema.attributes[this.scenario.targetAttribute];
            if (attribute) {
                return attribute;
            }
        }
        
        return {};
    },

    ...mapGetters(["models", "collectionSettings", "scenario", "schema", "i18n"]),
  },

  methods: {
    

    async refresh() {
      this.progressIndicatorService.show();

      try {
        await this.$store.dispatch(MODULE_NAME + "/findPredictionScenarioById", {
          scenarioId: this.modelId
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    showEditDialog() {
      
      this.$router.push({name: 'predictionModelWizardView', params: { scenarioId : this.modelId }})

    },


    deleteConfiguration(selectedConfigurationId) {
        this.$store.dispatch(MODULE_NAME + "/deletePredictionModelConfiguration", selectedConfigurationId);
    },

    oni18NUpdate(event) {
        this.$store.dispatch(MODULE_NAME + "/updateI18n", event);
    },

  },

  created() {
    this.refresh();
  }
};
</script>

<i18n>
{
  "en": {
    "prediction-scenario-editor.view.sections.common.subtitle" : "Prediction Scenario",
    "prediction-scenario-editor.breadcrumbs.root": "Prediction Scenario",
    "prediction-scenario-editor.view.sections.scenario.title" : "Scenario Data",
    "prediction-scenario-editor.view.sections.scenario.table.header.attribute" : "Attribute",
    "prediction-scenario-editor.view.sections.scenario.table.header.value" : "Value",
    "prediction-scenario-editor.view.sections.scenario.table.header.description" : "Description",
    "prediction-scenario-editor.view.sections.scenario.table.rows.targetValue" : "Target Value",
    "prediction-scenario-editor.view.sections.scenario.table.rows.collection" : "Collection",
    "prediction-scenario-editor.view.sections.scenario.table.rows.schema" : "Schema",


    "prediction-scenario-editor.view.sections.i18n.title" : "Internationalization",
    "prediction-scenario-editor.view.sections.i18n.subtitle" : "Define translated names and descriptions for the prediction model.",

    "prediction-scenario-editor.view.sections.training.title" : "Models",
    "prediction-scenario-editor.view.sections.training.subtitle" : "Manage the models for your predicition scenario.",
    "prediction-scenario-editor.view.sections.configuration.table.header.lastTraining" : "Last training",
    "prediction-scenario-editor.view.sections.configuration.table.header.predictionAccuracy" : "Accuracy prediction",
    "prediction-scenario-editor.view.sections.configuration.table.header.minAccuracy" : "Min. accuracy",
    "prediction-scenario-editor.view.sections.configuration.table.header.maxDuration" : "Max. duration training (h)",
    "prediction-scenario-editor.view.sections.configuration.table.header.train" : "Train",
    "prediction-scenario-editor.view.sections.configuration.table.header.delete" : "Delete",

    "prediction-scenario-editor.view.sections.configuration.table.empty.title" : "No models are currently present.",
    "prediction-scenario-editor.view.sections.configuration.table.empty.subtitle" : "You may add new models by using the  \"+\" button.",

    "prediction-scenario-editor.view.configuration.edit.dialog.title" : "Training configuration",
    "prediction-scenario-editor.view.configuration.edit.dialog.subtitle" : "Please note: The ID of the configuration cannot be changed after creation.",

    "prediction-scenario-editor.view.configuration.edit.dialog.evaluation.header" : "Evaluation",
    "prediction-scenario-editor.view.configuration.edit.dialog.numberOfRuns.label" : "Number of Runs",
    "prediction-scenario-editor.view.configuration.edit.dialog.validationDataPercentage.label": "Share of data used for validation in %",
    "prediction-scenario-editor.view.configuration.edit.dialog.crossValidation.label": "Cross validation",

    "prediction-scenario-editor.view.configuration.edit.dialog.terminationcriteria.header" : "Termination criteria",
    "prediction-scenario-editor.view.configuration.edit.dialog.precisionPercentage.label": "Precision in %",
    "prediction-scenario-editor.view.configuration.edit.dialog.maximumTrainingDurationHours.label": "Maximum training duration in hours"

  },

  "de": {
"prediction-scenario-editor.view.sections.common.subtitle" : "Vorhersageszenario",
    "prediction-scenario-editor.breadcrumbs.root": "Alle Vorhersageszenarien",
    "prediction-scenario-editor.view.sections.scenario.title" : "Stammdaten",
    "prediction-scenario-editor.view.sections.scenario.table.header.attribute" : "Attribut",
    "prediction-scenario-editor.view.sections.scenario.table.header.value" : "Wert",
    "prediction-scenario-editor.view.sections.scenario.table.header.description" : "Beschreibung",
    "prediction-scenario-editor.view.sections.scenario.table.rows.targetValue" : "Zielwert",
    "prediction-scenario-editor.view.sections.scenario.table.rows.collection" : "Datenbereich",
    "prediction-scenario-editor.view.sections.scenario.table.rows.schema" : "Schema",




    "prediction-scenario-editor.view.sections.training.title" : "Vorhersagemodelle",
    "prediction-scenario-editor.view.sections.training.subtitle" : "Verwalten Sie die Vorhersagemodelle für das ausgewählte Szenario.",

    "prediction-scenario-editor.view.sections.i18n.title" : "Internationalisierung",
    "prediction-scenario-editor.view.sections.i18n.subtitle" : "Bestimmen Sie übersetzte Namen und Beschreibungen für das Modell.",

    "prediction-scenario-editor.view.sections.configuration.title" : "Einstellungen Training",
    "prediction-scenario-editor.view.sections.configuration.table.header.lastTraining" : "Letztes Training",
    "prediction-scenario-editor.view.sections.configuration.table.header.predictionAccuracy" : "Genauigkeit Vorhersage",
    "prediction-scenario-editor.view.sections.configuration.table.header.minAccuracy" : "Min. Genauigkeit",
    "prediction-scenario-editor.view.sections.configuration.table.header.maxDuration" : "Max. Dauer Training (h)",
    "prediction-scenario-editor.view.sections.configuration.table.header.train" : "Trainieren",
    "prediction-scenario-editor.view.sections.configuration.table.header.delete" : "Löschen",

    "prediction-scenario-editor.view.sections.configuration.table.empty.title" : "Momentan sind keine Modelle vorhanden.",
    "prediction-scenario-editor.view.sections.configuration.table.empty.subtitle" : "Sie können durch die \"+\" Schaltfläche weitere Einträge hinzufügen.",

    "prediction-scenario-editor.view.configuration.edit.dialog.title" : "Modelle",
    "prediction-scenario-editor.view.configuration.edit.dialog.subtitle" : "Bitte beachten Sie: Die ID der Trainingskonfiguration kann nach dem Anlegen nicht mehr geändert werden.",

    "prediction-scenario-editor.view.configuration.edit.dialog.evaluation.header" : "Evaluierung",
    "prediction-scenario-editor.view.configuration.edit.dialog.numberOfRuns.label" : "Anzahl Durchläufe Training",
    "prediction-scenario-editor.view.configuration.edit.dialog.validationDataPercentage.label": "Anteil Datensätze Validierung in %",
    "prediction-scenario-editor.view.configuration.edit.dialog.crossValidation.label": "Kreuzvalidierung",

    "prediction-scenario-editor.view.configuration.edit.dialog.terminationcriteria.header" : "Abbruchkriterien",
    "prediction-scenario-editor.view.configuration.edit.dialog.precisionPercentage.label": "Genauigkeit in %",
    "prediction-scenario-editor.view.configuration.edit.dialog.maximumTrainingDurationHours.label": "Maximale Trainingsdauer in Stunden"
  }
}
</i18n>