var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"secondary pt-10 pb-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"9"}},[_c('c-base-dialog',{attrs:{"value":_vm.batteryPack,"title":_vm.$t('battery-pack-wizard.view.title'),"subtitle":_vm.$t('battery-pack-wizard.view.subtitle'),"color":"secondary"},on:{"dialog-saved":_vm.save,"dialog-canceled":_vm.cancel},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('c-name-input',{attrs:{"filled":""},model:{value:(_vm.batteryPack.name),callback:function ($$v) {_vm.$set(_vm.batteryPack, "name", $$v)},expression:"batteryPack.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('c-item-selector',{attrs:{"items":_vm.manufacturerplants.items,"return-object":false,"filled":"","chips":false,"label":_vm.$t('batteryPack-wizard.view.subheader.manufacturerplant'),"rules":[ 
                                      v => v && !!v.trim()  || _vm.$t('name-input.component.validation.notEmpty')
                                  ]},model:{value:(_vm.manufacturerplantid),callback:function ($$v) {_vm.manufacturerplantid=$$v},expression:"manufacturerplantid"}}),_c('c-item-selector',{attrs:{"items":_vm.batterystatuss.items,"return-object":false,"filled":"","chips":false,"label":_vm.$t('batteryPack-wizard.view.subheader.batterystatus'),"rules":[ 
                                      v => v && !!v.trim()  || _vm.$t('name-input.component.validation.notEmpty')
                                  ]},model:{value:(_vm.batterystatus),callback:function ($$v) {_vm.batterystatus=$$v},expression:"batterystatus"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('c-item-selector',{attrs:{"items":_vm.batteryModel.items,"return-object":false,"filled":"","chips":false,"label":_vm.$t('batteryPack-wizard.view.subheader.batteryModel'),"rules":[ 
                                      v => v && !!v.trim()  || _vm.$t('name-input.component.validation.notEmpty')
                                  ]},model:{value:(_vm.batteryPack.references.batterymodel),callback:function ($$v) {_vm.$set(_vm.batteryPack.references, "batterymodel", $$v)},expression:"batteryPack.references.batterymodel"}})],1)],1),_c('v-row'),_c('v-row')],1)],1)]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }