<template>
  <v-sheet v-if="hasDateAttributes">
    <c-sticky-content>

      <!-- *********************************************************************** -->
      <!-- TOOLBAR                                                                 -->
      <!-- *********************************************************************** -->
      <v-container fluid grid-list-md class="grey lighten-4">
        <v-row justify="center">
          <v-col :cols="12">
            <c-filter-query-input
              :value="{ query: filter.filterQuery }"
              :disabled="loading"
              :loading="loading"
              @filter-query-changed="onFilterQueryChange"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-divider />

      <v-toolbar flat color="white">
        <v-toolbar-title>{{$t('collection-series.view.results', {totalItemCount: totalItemCountFormatted})}}</v-toolbar-title>
        <v-btn icon @click="toogleQuerySummary">
          <v-icon v-if="!querySummary">expand_more</v-icon>
          <v-icon v-else>expand_less</v-icon>
        </v-btn>
        <v-spacer />
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon @click="getTimeSeriesData">
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-btn icon @click="filterDialog = !filterDialog">
            <v-icon>filter_list</v-icon>
          </v-btn>

          <v-menu top>
            <template v-slot:activator="{ on }">
                <v-btn :disabled="!hasTimeSeries && !formSent" icon v-on="on">
                    <v-icon>print</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item @click="goToPrintView('ALL')">
                    <v-list-item-icon><v-icon>print</v-icon></v-list-item-icon>
                    <v-list-item-title>{{$t('collection-series.view.print.diagramTable')}}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="goToPrintView('DIAGRAMM')">
                    <v-list-item-icon><v-icon>print</v-icon></v-list-item-icon>
                    <v-list-item-title>{{$t('collection-series.view.print.diagram')}}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>
            <v-menu top>
              <template v-slot:activator="{ on }">
                  <v-btn :disabled="!hasTimeSeries && !formSent" icon v-on="on">
                      <v-icon>cloud_download</v-icon>
                  </v-btn>
              </template>

              <v-list>
                  <v-list-item @click="exportDocument('CSV')">
                      <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                      <v-list-item-title>{{$t('collection-documents.view.actions.export.csv', $i18n.locale)}}</v-list-item-title>
                  </v-list-item>
              </v-list>
          </v-menu>
        </v-toolbar-items>
      </v-toolbar>

      <v-divider />
      
      <template v-if="querySummary">
        <v-divider />
        <c-query-summary :value="{filter: filter, meta: schema}" />
      </template>
    </c-sticky-content>

    <v-container fluid>
      <c-attribute-single-selector v-model="attribute" :items="{...attributes}" :filled="true" :filter="attributeFilter" />
      <v-combobox v-model="timescale" filled :label="$t('collection-time-series.view.timescale.label')" persistent-hint :hint="$t('collection-time-series.view.timescale.hint')" :items="timeScales">
        <template v-slot:selection="{ item }">
          {{item | localized-name($i18n.locale)}}
        </template>
        <template v-slot:item="{ item }">
          {{item | localized-name($i18n.locale)}}
        </template>
      </v-combobox>

      <c-editable-item-list 
        headerStyle="lite" 
        :flat="true" 
        :title="$t('collection-series.view.measures.label')" 
        @item-updated="addMeasure" 
        @item-removed="removeMeasure" 
        :items="measures"
       :check-duplicate="checkDuplicate"
        >

        <template v-slot:item-list-head>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>{{$t('collection-series.view.measure.label')}}</th>
                    <th>{{$t('collection-series.view.aggregation.label')}}</th>
                    <th>{{$t('collection-series.view.color.label')}}</th>

                </tr>
            </thead>
        </template>
        <template v-slot:item-list-body-row="props">
              <td>{{ props.item.measure | localized-name($i18n.locale) }}</td>
              <td>{{ props.item.aggregation | localized-name($i18n.locale) }}</td>
              <td>
                  <c-color-label :value="props.item.color"></c-color-label>
              </td>
          </template>

          <template v-slot:item-list-editor-title="props">
            <div v-if="!!props.item.measure" class="title">{{$t('collection-time-series.view.measures.edit.label')}} ({{ props.item.measure | localized-name }})</div>
            <div v-else class="title">{{$t('collection-series.view.measures.add.label')}}</div>
          </template>

          <template v-slot:item-list-editor="props">
            <v-card tile flat>
                <v-card-text>
                    <c-measure-single-selector v-model="props.item.measure" :items="{...attributes}" :filled="true" />
                    <v-combobox filled :label="$t('collection-series.view.selectAggregation.label')" :items="aggregations" :item-text="`i18n.${$i18n.locale}.name`" v-model="props.item.aggregation"></v-combobox>
                    <c-color-selector v-model="props.item.color" />

                </v-card-text>
            </v-card>
        </template>
      </c-editable-item-list>
    </v-container>

    <!-- SHOW CHART BUTTON -->
    <v-container fluid>
      <v-btn :disabled="!formValid" block color="accent" v-on:click="getTimeSeriesData"><v-icon class="mr-2">input</v-icon>
        {{$t('collection-time-series.view.showChart.title')}}
      </v-btn>
    </v-container>
    
    <!-- RENDER CHART AND TABLE -->
    <v-container fluid v-if="hasTimeSeries && formValid">

      <!-- *************************************************************************************************** -->
      <!-- DON'T REMOVE WITHOUT PRIOR CONSULTATION                                                             -->
      <!-- *************************************************************************************************** -->
      <!-- Protect the browser/amcharts from too many data points to render - this might cause out of memory   -->
      <!-- situations, thus crashing the browser. This catastrophic situation must be prevented!               -->
      <!-- *************************************************************************************************** -->
      <!-- <template v-if="tooManyDataPoints">
          <div class="text-center body-2 mt-5">
              <v-icon large color="error">warning</v-icon>
              <div class="title error--text">{{$t('collection-time-series.view.sections.tooManyDataPoints.title')}}</div>
              <p />
              <div>{{$t('collection-time-series.view.sections.tooManyDataPoints.description.problem')}}</div>
              <div>{{$t('collection-time-series.view.sections.tooManyDataPoints.description.solution')}}</div>
          </div>
      </template> -->

      <!-- *************************************************************************************************** -->
      <!-- REGULAR MODE                                                             -->
      <!-- *************************************************************************************************** -->

      <c-data-time-series-chart ref="timeSeriesChart" :value="timeSeries"></c-data-time-series-chart>
      <v-divider class="mt-5"></v-divider>
      <c-data-time-series-table :value="timeSeries"></c-data-time-series-table>
    </v-container>

    <!-- CHECK WHY THERE IS NO DATA -->
    <v-container v-else>
      <!-- FORM HAS NOT BEEN SUBMITTED YET -->
      <div v-if="(!hasTimeSeries || !formValid) && !formSent">
        <v-card tile flat >
          <v-card-text>
              <p />
              <div class="text-center"><v-icon color="primary" large>info</v-icon></div>
              <div class="title text-center">{{$t('collection-series.view.missingConfig.title')}}</div>
              <div class="text-center">{{$t('collection-time-series.view.form.selection')}}</div>
              <div class="text-center">
                <v-chip v-for="formField in formFields" :outlined="!formField.isValid" :key="formField.name"
                  class="ma-2"
                  :input-value="formField.isValid"
                  filter
                >{{formField.text}}</v-chip>
              </div>
          </v-card-text>
        </v-card>
      </div>
      <!-- QUERY DOES NOT MATCH ANY RESULTS -->
      <c-empty-result-tile v-else />
    </v-container>
    
    <!-- Filter Settings Sidebar -->
    <v-navigation-drawer
        fixed
        right
        temporary
        v-model="filterDialog"
        width="500"
        >
            <c-filter-settings :value="filter" :schema="schema" @filter-changed="onFilterChanged" />
    </v-navigation-drawer>
  </v-sheet>
  <v-sheet v-else>
    <v-card tile flat >
      <v-card-text>
          <p />
          <div class="text-center"><v-icon color="accent" large>warning</v-icon></div>
          <div class="title text-center">{{$t('collection-time-series.view.missingDateAttribute.title')}}</div>
          <div class="text-center">{{$t('collection-time-series.view.missingDateAttribute.subtitle')}}</div>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import aggregations from './mock/collection-time-series-agg.mock'
import timeScales from './mock/collection-time-series-timescales.mock'
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import clone from 'lodash/clone';

const MODULE_NAME = "collectionTimeSeries";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },

    options: {
      type: Object,
      required: false,
    },
  },

  inject: ['shellState', 'errorHandlerService', 'messageService', 'progressIndicatorService'],

  watch: {
    docCount: {
      handler(count) {
        if (this.totalItemCount) {
          this.progressIndicatorService.updateText({from: count, to: this.totalItemCount})
        }
      }
    }
  },

  data: () => ({
    filterDialog: false,
    querySummary: false,
    aggregations: aggregations,
    timeScales: timeScales,
  }),

  computed: {
    loading() {
      return this.shellState.loading;
    },

    form() {
      return this.$store.getters[MODULE_NAME + "/form"]
    },

    attribute: {
      get() {
        return this.$store.getters[MODULE_NAME + "/attribute"]
      },

      set(value) {
        this.$store.commit(MODULE_NAME + '/setAttribute', value)
      }
    },

    timescale: {
      get() {
        return this.$store.getters[MODULE_NAME + "/timescale"]
      },

      set(value) {
        this.$store.commit(MODULE_NAME + '/setTimescale', value)
      }
    },

    measures: {
      get() {
        return this.$store.getters[MODULE_NAME + "/measures"];
      },

      set(value) {
        this.$store.commit(MODULE_NAME + '/setMeasures', value)
      }
    },

    compute() {
      return {
        response: this.$store.getters[MODULE_NAME + "/response"],
        collectionId: this.value.collectionId,
      };
    },


    collectionId() {
      return this.value.collectionId;
    },

    collectionSettings() {
      return this.$store.getters[MODULE_NAME + "/collectionSettings"]
    },

    schema() {
      return this.$store.getters[MODULE_NAME + "/schema"]
    },

    filter() {
      return this.$store.getters[MODULE_NAME + "/filter"]
    },

    timeSeries() {
      return this.$store.getters[MODULE_NAME + "/timeSeries"]
    },

    totalItemCount() {
      return this.$store.getters[MODULE_NAME + "/totalItemCount"]
    },

    totalItemCountFormatted() {
      
      return this.$dxs.formatting.formatInteger(this.totalItemCount, this.$i18n.locale);
    },

    attributes() {
      const attributes = get(this.schema, 'attributes', {});
      const attributeList = [];
      Object.keys(attributes).forEach(key => {
        attributeList.push(attributes[key]);
      });

      return attributeList;
    },

    dateAttributes() {
      return this.attributes.filter(this.attributeFilter);
    },

    hasTimeSeries() {
      return get(this.timeSeries, 'data.result', []).length > 0;
    },

    hasDateAttributes() {
      return this.dateAttributes.length > 0;
    },

    formValid() {
      return this.$store.getters[MODULE_NAME + "/formValid"];
    },

    formSent() {
      return this.$store.getters[MODULE_NAME + "/formSent"];
    },

    docCount() {
      return this.$store.getters[MODULE_NAME + "/docCount"];
    },

    formFields() {
      return [
        {
          name: 'attribute',
          text: this.$t('collection-series.view.aggregation.label'),
          value: this.attribute,
          isValid: !isEmpty(this.attribute)
        },
        {
          name: 'timescale',
          text: this.$t('collection-time-series.view.timescale.label'),
          value: this.timescale,
          isValid: !isEmpty(this.timescale)
        },
        {
          name: 'measures',
          text: this.$t('collection-series.view.measures.label'),
          value: this.measures,
          isValid: !isEmpty(this.measures)
        }
      ]
    },

    countDataPoints() {


        //console.log(">>>> " + JSON.stringify(this.measures))
        let countDays = (this.timeSeries && this.timeSeries.data && this.timeSeries.data.result) ? this.timeSeries.data.result.length : 0;
        let countMeasures = (this.measures) ? this.measures.length : 0;

        return countDays * countMeasures;
    },

    /**
     * A check which prevents rendering of results in case too many datapoints have been loaded from the backend.
     */
    tooManyDataPoints() {

        this.$log.debug("Actual number of data points: " + this.countDataPoints);

        // This is a technical restriction of Amcharts which cannot be easily worked around.
        // Therefore we should not render the view as this might cause the browser to crash.
        if (this.countDataPoints > 90000) {
            this.$log.debug("Too many data points to render. This is a technical restriction of the chart library. Please adapt filters and scale.")
            return true;
        }

        return false;
    }
  },

  methods: {

    checkDuplicate(e1, e2) {
        return (get(e1, 'measure.name') === get(e2, 'measure.name'))
            && (get(e1, 'aggregation.name') === get(e2, 'aggregation.name'));
    },

    async getTimeSeriesData() {
      try {
        this.progressIndicatorService.show();
        this.progressIndicatorService.updateText({from: this.docCount, to: this.totalItemCount})
        await this.$store.dispatch(MODULE_NAME + "/loadTimeSeriesData", {
          collectionId: this.compute.collectionId,
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    addMeasure(event) {
      const measureId = event.item.measure.name + '_' + event.item.aggregation.name;
      const measures = this.measures;

      measures[event.index] = {...event.item, measureId};

      this.measures = clone(measures);
    },

    removeMeasure(event) {
      const measures = this.measures;
      measures.splice(event.index, 1);

      this.measures = measures;
    },

    attributeFilter(value) {
      return value.datatype === 'DATETIME';
    },

    toogleQuerySummary() {
      this.querySummary = !this.querySummary;
    },

    async onFilterQueryChange(event) {
      this.progressIndicatorService.show();
      const query = {
        collectionId: this.compute.collectionId,
        filterQuery: {
          filterQuery: event.query,
          filterQueryLanguage: event.mode,
        },
      };

      try {
        await this.$store.dispatch(MODULE_NAME + "/updateFilterQuery", query);
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    async onFilterChanged(event) {
      this.progressIndicatorService.show();

      try {
          await this.$store.dispatch(MODULE_NAME + '/updateFacetFilters', {collectionId: this.collectionId, facetFilters: event});
      } catch (error) {
          this.errorHandlerService.handleError(error);
      } finally {
          this.progressIndicatorService.hide();
      }
    },

    async exportDocument(format) {

        this.progressIndicatorService.show();

        try {
            await this.$store.dispatch(MODULE_NAME + '/export', {collectionId: this.collectionId, format: format});

            this.messageService.show({
              title: this.$t('collection-documents.view.messages.export.success.title', this.$i18n.locale),
              text: this.$t('collection-documents.view.messages.export.success.message', this.$i18n.locale)
            });
        } catch (error) {
            this.errorHandlerService.handleError(error);
        } finally {
            this.progressIndicatorService.hide();
        }
    },

    printView(type) {
      switch(type.toLowerCase()) {
        case 'all':
          // this.$router.push('/print/collectionTimeSeriesPrint', {data: "test"});
          this.$router.push({name: 'collectionTimeSeriesPrint', params: {foo: 1}})
          break;
        case 'diagram':
          this.$router.push('/print/collectionTimeSeriesPrint?type=diagram')
          break;
        default:
          this.$router.push('/print/collectionTimeSeriesPrint')
          break;

      }
    },

    goToPrintView(type) {
      this.progressIndicatorService.show();
      const chart = this.$refs.timeSeriesChart.$data.eChart;
      const chartImage = chart.getDataURL({
        // Exporting format, can be either png, or jpeg
        type: 'png',
        // Resolution ratio of exporting image, 1 by default.
        pixelRatio: 2,
        // Background color of exporting image, use backgroundColor in option by default.
        backgroundColor: '#fff',
        // Excluded components list. e.g. ['toolbox']
        excludeComponents: ['toolbox', 'tooltip', 'dataZoom']
      });
      this.progressIndicatorService.hide();

      this.$router.push({name: 'collectionTimeSeriesPrint', params: {image: chartImage, type: type}})
    },
  },

  created() {
    this.$store.dispatch(MODULE_NAME + '/loadCollectionSettings',  {collectionId: this.collectionId}).finally(() => this.progressIndicatorService.hide());
 },
};
</script>

<i18n>
{
  "en" : {
    "collection-time-series.view.missingDateAttribute.title" : "Time Series Analysis is not Available",
    "collection-time-series.view.missingDateAttribute.subtitle" : "This collection does not contain date-related attributes. Therefore it is not possible to perform time series analysis.",
    "collection-time-series.view.timescale.label" : "Time scale",
    "collection-time-series.view.timescale.hint" : "Please select the scale to be applied for displaying the time axis",
    "collection-time-series.view.measures.edit.label" : "Edit Measure",
    "collection-time-series.view.selectColor.label" : "Select Color",
    "collection-time-series.view.form.selection" : "To perform a time series analysis, please select at least the following:",
    "collection-time-series.view.sections.tooManyDataPoints.title" : "Too Many Data Points - can't render Diagram",
    "collection-time-series.view.sections.tooManyDataPoints.description.problem" : "Your request contains too many data points and can therefore not be displayed in the browser. Please reduce the number of datapoints by means of applying additional filters or choosing a less fine-grained time scale.",
    "collection-time-series.view.sections.tooManyDataPoints.description.solution" : "Please reduce the number of datapoints by means of applying additional filters or choosing a less fine-grained time scale."
  },

  "de" : {
    "collection-time-series.view.missingDateAttribute.title" : "Zeitreihenanalyse nicht verfügbar",
    "collection-time-series.view.missingDateAttribute.subtitle" : "In diesem Datenbereich gibt es keine Datumsfelder. Es ist deshalb leider nicht möglich, eine Zeitreihenanalyse durchzuführen.",
    "collection-time-series.view.timescale.label" : "Zeitskala",
    "collection-time-series.view.timescale.hint" : "Bitte wählen Sie die Skala, die für die Darstellung der Zeitachse verwendet werden soll",
    "collection-time-series.view.measures.edit.label" : "Kennzahl bearbeiten",
    "collection-time-series.view.selectColor.label" : "Farbe auswählen",
    "collection-time-series.view.form.selection" : "Um eine Zeitreihenanalyse durchzuführen, wählen Sie bitte mindestens folgendes aus:",
    "collection-time-series.view.sections.tooManyDataPoints.title" : "Diagramm kann nicht angezeigt werden",
    "collection-time-series.view.sections.tooManyDataPoints.description.problem" : "Ihre Anfrage enthält zu viele Datenpunkte und kann deshalb nicht im Browser angezeigt werden.",
    "collection-time-series.view.sections.tooManyDataPoints.description.solution" : "Bitte reduzieren Sie die Anzahl der Datenpunkte durch eine zusätzliche Filterung oder durch die Auswahl einer Zeitskala mit größeren Abständen."

  }
}
</i18n>
