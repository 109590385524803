import api from '../../../api/smart-factory.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        site: createEmptySite(),
        tenantId: null
    },

    mutations: {

        setSite(state, site) {

            if (site) {

                state.site.tenantId = site.tenantId ? site.tenantId : null;
                state.site.name = site.name ? site.name : null;
                state.site.i18n = site.i18n ? site.i18n : {};
                state.site.data = site.data ? site.data : { custData: {} };
                state.site.active = site.active ? site.active : true;
                state.site.references = site.references ? site.references : {};

            } else {
                state.site.tenantId = null;
                state.site.name = null;
                state.site.i18n = null;
                state.site.data = null;
                state.site.active = null;
                state.site.references = null;
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        }


    },

    actions: {

        async createSite(context) {

            try {

                let request = context.getters.request;
                await api.masterdata.sites.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetSite(context) {
            context.commit('setSite', createEmptySite());
        },

        async updateSite(context, site) {

            try {
                context.commit('setSite', site);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.site;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        site(state) {
            return state.site ? state.site : createEmptySite();
        }

}
}

function createEmptySite() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { custData: {} },
        "active": true,
        "references": {}

    }
}