<template>
  <v-sheet color="secondary darken-1">
      <v-container><v-row><v-col cols="3">
      <dxs-metric-tile :value="metric" />
      </v-col></v-row></v-container>
      <c-item-editor-form title="Metrics" :editable="true" v-model="model">
            <dxs-metric-definition-editor v-model="model" :disabled="false"  /> 
      </c-item-editor-form>

      


      <v-card tile>
            <c-sticky-content>
            
                <v-toolbar color="white" flat>
                    <v-toolbar-items><v-btn icon @click="cancel"><v-icon>arrow_back</v-icon></v-btn></v-toolbar-items>
                    <div class="text-h6 ">{{$t('catalog.common.schema.label')}}: {{schema | localized-name($i18n.locale)}}</div>
                    
                    <v-spacer />
                    <v-toolbar-items><v-btn text @click="save">{{$t('actions.save.label')}}</v-btn></v-toolbar-items>
                    <v-toolbar-items><v-btn text @click="cancel">{{$t('actions.cancel.label')}}</v-btn></v-toolbar-items>
                    
                </v-toolbar>
                <v-divider />
            </c-sticky-content>
            
            <v-card-text />
            <v-card-text class="text-h6">{{$t('catalog.common.technicalname.label')}}</v-card-text>
            <v-card-text class="body-2">
                {{$t('catalog.common.technicalname.schema.description')}}
            </v-card-text>
            <v-card-text class="body-2">
                <v-text-field :value="name" :readonly="true" filled :label="$t('catalog.common.technicalname.label')" />
            </v-card-text>

            <!-- Settings -->
            <v-divider />
            <v-card-text />
            <v-card-text class="text-h6">{{$t('metrics-editor.view.sections.settings.title')}}</v-card-text>
            <v-card-text class="body-2">
                {{$t('metrics-editor.view.sections.settings.description')}}
            </v-card-text>
            <v-container fluid>
                <!-- Collection Settings -->
                <!-- Measure -->
                <!-- Aggregation Operation -->
            <v-row>
                <v-col cols="12" md="8">
                    <c-measure-single-selector v-model="measure" :items="measures" filled />
                </v-col>
                <v-col cols="12" md="4">
                    <!-- Aggregation -->
                    <c-measure-single-selector v-model="measure" :items="measures" filled />
                </v-col>
            </v-row>
            </v-container>
            <v-divider />
            <v-subheader>Zeithorizont</v-subheader>
            Letzte [25] [/Stunde/Tage] 
            Letzte
            Aktueller Woche
            Aktueller Monat
            Aktuelles Quartal 
            Aktuelles Jahr


            Zukunft
            <v-divider />
            <v-subheader>Time Horizon</v-subheader>
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
            <v-list>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox v-model="filterActive"  color="accent"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>{{$t('Keine')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.filterActive.hint')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox v-model="facetFilterActive"  color="accent"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('Stunde')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.facetFilterActive.hint')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox v-model="sortActive"  color="accent"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('Tag')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.sortActive.hint')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox v-model="displayTable"  color="accent"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('Woche')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.displayTable.hint')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox v-model="displayTile"  color="accent"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('attribute-editor.component.fields.displayTile')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.displayTile.hint')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox v-model="exportActive"  color="accent"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('attribute-editor.component.fields.exportActive')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('attribute-editor.component.fields.exportActive.hint')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            
                </v-col>
            </v-row>
        </v-container>
        


           <v-divider />
            <v-card-text />
            <v-card-text class="text-h6">{{$t('catalog.common.translation.localization.label')}}</v-card-text>
            <v-card-text class="body-2">
                {{$t('catalog.common.translation.localization.schema.description')}}
            </v-card-text>
             
            <c-i18n-editor 
                :items="i18n" 
                header-style="lite" 
                flat 
                @item-removed="removeI18n" 
                @item-updated="addI18n"/>
            <v-divider />
  

            
      </v-card>

  </v-sheet>
</template>

<script>
import MetricDefinitionEditorComponent from '@/core/components/metrics/metric-definition-editor.component';
import MetricTileComponent from '@/core/components/metrics/metric-tile.component';

export default {

    name: 'metrics-editor',

    components : {

        'dxs-metric-definition-editor' : MetricDefinitionEditorComponent,
        'dxs-metric-tile' : MetricTileComponent
    },

    data: () =>({
        testVal: null,

        model : {

            name: 'Hello1234',
            aggregation: null,

            filter:  {
                "filterQuery":"*",
                "filterQueryLanguage":"SIMPLE",
                "facetFilters":[],
                "rangeFilters":[]
            },

            i18n: {
                
            }
        }
    }),

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    computed: {

        metric() {

            return {

                metric: {

                    value: 621.35,
                    totalItemCount: 354678,
                    from: new Date('2021-07-01T13:00:48.0+01:00'),
                    until: new Date('2021-07-16T19:22:03.0+01:00')
                },

                metricDefinition: {
                    
                    name: 'Hello1234',
                    aggregation: null,

                    filter:  {
                        "filterQuery":"*",
                        "filterQueryLanguage":"SIMPLE",
                        "facetFilters":[],
                        "rangeFilters":[]
                    },

                    i18n: {
                        'de' : {
                            name: 'Dichte ZUK-A',
                            description: 'Messpunkt zur Bestimmung der Dichte gemäß Spezifikation ZUK-A'
                        }
                    }
                }

            }

        },

        name: {

            get() {
                return 'Test';
            }
        },

        i18n : {

            get() {
                return {};
            }
        },

        measure: {

            get() {
                return 'MY-KPI';
            },
        },    

        collectionSettings: {

            get() {
                return {};
            }
        },

        schema: {

            get() {
                return {};
            }
        },

        attributes: {
            get() {
                return {}
            }
        }
    }


}
</script>

<i18n>
{
    "en" : {
        "metrics-editor.view.sections.settings.title" : "Metric Definition",
        "metrics-editor.view.sections.settings.description" : "Define the settings for this metric."
    },

    "de" : {
        "metrics-editor.view.sections.settings.title" : "Definition Kennzahl",
        "metrics-editor.view.sections.settings.description" : "Legen Sie die Definition Ihrer Kennzahl fest. Das System wird diese Einstellungen verwenden, um die Kennzahl zur Anzeige zu berechnen."
    }
}
</i18n>