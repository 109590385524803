import Vue from 'vue';
import App from './application.vue';

// The imported defaults must be written exactly this way.
import router from './router';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import store from './store/index';


import './plugins/vue-logger';
// import './plugins/amentis-charts';
import './plugins/amentis-core-fe-components';
import Keycloak from 'keycloak-js'
import Configuration from '@/utils/configuration';

import { isMobile, isMobileOnly, isTablet, isIOS, isAndroid } from "mobile-device-detect"

import '@/assets/index.scss'

Vue.config.productionTip = false;

const VUE_APP_SSO_HOST = Configuration.value('VUE_APP_SSO_HOST');
const VUE_APP_SSO_REALM = Configuration.value('VUE_APP_SSO_REALM');
const VUE_APP_SSO_CLIENT_ID = Configuration.value('VUE_APP_SSO_CLIENT_ID');
const VUE_APP_SSO_USER_MANAGEMENT_CONSOLE_ENABLED = Configuration.value('VUE_APP_SSO_USER_MANAGEMENT_CONSOLE_ENABLED');

// Setup the global error handler.
Vue.config.errorHandler = (err, vm, info) => {
    console.log("[ERROR CATCH]: ", err);
    console.log("[ERROR COMPONENT]: ", vm);
    console.log("[ERROR INFO]: ", info);
};

Vue.use({
    install(vue) {
        vue.prototype.$isMobile = isMobile;
        vue.prototype.$isMobileOnly = isMobileOnly;
        vue.prototype.$isTablet = isTablet;
        vue.prototype.$isIOS = isIOS;
        vue.prototype.$isAndroid = isAndroid;
    }
});

let initOptions = {
    url: VUE_APP_SSO_HOST,
    realm: VUE_APP_SSO_REALM,
    clientId: VUE_APP_SSO_CLIENT_ID,
    onLoad: 'login-required',
    promiseType: 'native'
}

let keycloak = Keycloak(initOptions);

let refreshToken = function () {
    Vue.$log.debug('Checking if token needs to be refreshed');
    keycloak.updateToken(30).then((refreshed) => {
        if (refreshed) {
            Vue.$log.debug('Token refreshed ' + refreshed);

            localStorage.setItem("vue-token", keycloak.token);
            localStorage.setItem("vue-refresh-token", keycloak.refreshToken);

        } else {
            Vue.$log.debug('Token not refreshed, valid for '
                + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
    }).catch(error => {
        Vue.$log.error('Failed to refresh token', error);
    });
}

// Check if token must be refreshed every 10 seconds
setInterval(refreshToken, 10000);

keycloak.init({ onLoad: initOptions.onLoad, promiseType: initOptions.promiseType }).then((auth) => {

    if (!auth) {
        window.location.reload();
    } else {
        Vue.$log.debug("Authenticated");
    }


    Vue.use({
        install(vue) {
           
            vue.$token = keycloak.tokenParsed;

            vue.$keycloak = keycloak;
            vue.$user = {

                
                get name() {
                    return this.getAttribute('name', 'Unknown User');
                },

                get userName() {
                    return this.getAttribute('preferred_username', 'Unknown User');
                },

                get email() {
                    return this.getAttribute('email', null);
                },

                get pictureUrl() {
                    return this.getAttribute('picture', null);
                },

                get locale() {
                    return this.getAttribute('locale', 'en');
                },

                get userManagementEnabled() {
                    return VUE_APP_SSO_USER_MANAGEMENT_CONSOLE_ENABLED;
                },

                get roles() {

                    let resourceAccess = vue.$token['resource_access'] ? vue.$token['resource_access']  : {};
                    let client = resourceAccess['dxs-backend'] ? resourceAccess['dxs-backend'] : {};


                    return client.roles ? client.roles : [];
                },

                get userGroups() {
                    return this.getAttribute('userGroups', []);
                },

                /**
                 * Provides a means for retrieving (custom) attributes from the user token. 
                 * @param {*} name The attribute's name
                 * @param {*} defaultValue A default value to return, when the attribute does not exist.
                 * @returns The value associated with the given attribute name or the defaultValue, when the attribute could not be found on the token.
                 */
                getAttribute(name, defaultValue=null) {

                    return vue.$token[name] ? vue.$token[name] : defaultValue;
                },

                logout() {
                    vue.$keycloak.logout();
                },

                account() {
                    VUE_APP_SSO_USER_MANAGEMENT_CONSOLE_ENABLED ? vue.$keycloak.accountManagement() : null;
                },

                hasRole(role) {

                    // Check the role array
                    return this.roles ? this.roles.findIndex( e => e === role) !== -1 : false;
                },

                hasAllRoles(roles) {
                    
                    if (roles instanceof Array) {

                        for (let role of roles) {
                            if (!this.hasRole(role)) return false;
                        } 
                        
                    } else {
                       return this.hasRole(roles);
                    }

                    // Fallback, e.g. when no roles are required at all.
                    return true;
                }

            }

        }
    });

    new Vue({
        router,
        i18n,
        vuetify,
        store,
        render: h => h(App)
    }).$mount('#app')

    localStorage.setItem("vue-token", keycloak.token);

    localStorage.setItem("vue-refresh-token", keycloak.refreshToken);
}, (error) => {
    Vue.$log.error("Authenticated Failed", error);
});
