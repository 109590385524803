<template>
    <v-simple-table >
                    <thead>
                        <tr>
                            <th>{{$t('mapping-rule-expression-help.component.tab.func')}}</th>
                            <th>{{$t('mapping-rule-expression-help.component.tab.returnValue')}}</th>
                            <th>{{$t('mapping-rule-expression-help.component.tab.description')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                         <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('mapping-rule-expression-help.component.tab.func.category.uuids')}}</td>
                        </tr>
                        <tr>
                            <td><code>RANDOM_ID()</code></td>
                            <td><code>STRING</code></td>
                            <td>
                                {{$t('mapping-rule-expression-help.component.tab.func.RANDOM_ID')}}
                                <br />
                                {{$t('mapping-rule-expression-help.component.tab.func.RANDOM_ID.hint')}}
                            </td>
                        </tr>
                        <tr>
                            <td><code>UUID_FROM_STRING(String)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.UUID_FROM_STRING')}}</td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('mapping-rule-expression-help.component.tab.func.category.strings')}}</td>
                        </tr>
                         <tr>
                            <td><code>LENGTH(text: STRING)</code></td>
                            <td><code>INTEGER</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.LENGTH')}}</td>
                        </tr>
                        <tr>
                            <td><code>TRIM(text: STRING)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.TRIM')}}</td>
                        </tr>
                        <tr>
                            <td><code>SUBSTRING(text: STRING, INTEGER, INTEGER)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.SUBSTRING')}}</td>
                        </tr>
                        <tr>
                            <td><code>UPPERCASE(text: STRING)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.UPPERCASE')}}</td>
                        </tr>
                        <tr>
                            <td><code>LOWERCASE(text: STRING)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.LOWERCASE')}}</td>
                        </tr>
                        <tr>
                            <td><code>CONTAINS_TEXT(text: STRING, expression: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.CONTAINS_TEXT')}}</td>
                        </tr>
                        <tr>
                            <td><code>CONTAINS_TEXT_IGNORE_CASE(text: STRING, expression: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.CONTAINS_TEXT_IGNORE_CASE')}}</td>
                        </tr>
                        <tr>
                            <td><code>STARTS_WITH(text: STRING, prefix: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.STARTS_WITH')}}</td>
                        </tr>
                        <tr>
                            <td><code>ENDS_WITH(text: STRING, suffix: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.ENDS_WITH')}}</td>
                        </tr>
                        <tr>
                            <td><code>MATCHES_REGEX(text: STRING, regex: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.MATCHES_REGEX')}}</td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('mapping-rule-expression-help.component.tab.func.category.general')}}</td>
                        </tr>
                        <tr>
                            <td><code>IF(condition: BOOLEAN, first: ANY, second: ANY)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.IF')}}</td>
                        </tr>
                        <tr>
                            <td><code>IF_NOT(condition: BOOLEAN, first: ANY, second: ANY)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.IF_NOT')}}</td>
                        </tr>
                        <tr>
                            <td><code>IF_NULL(value: ANY, first: ANY, second: ANY)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.IF_NULL')}}</td>
                        </tr>
                        <tr>
                            <td><code>IS_NULL(value: ANY)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.IS_NULL')}}</td>
                        </tr>
                        <tr>
                            <td><code>IS_NOT_NULL(value: ANY)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.IS_NOT_NULL')}}</td>
                        </tr>
                        <tr>
                            <td><code>IS_EMPTY(value: ANY)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.IS_EMPTY')}}</td>
                        </tr>
                        <tr>
                            <td><code>TO_STRING(value: ANY)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.TO_STRING')}}</td>
                        </tr>
                        
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('mapping-rule-expression-help.component.tab.func.category.collection')}}</td>
                        </tr>
                        <tr>
                            <td><code>CONTAINS_VALUE(collection: LIST | SET, value: ANY)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.CONTAINS_VALUE')}}</td>
                        </tr>
                       
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('mapping-rule-expression-help.component.tab.func.category.math')}}</td>
                        </tr>

                        <tr>
                            <td><code>LOG(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.LOG')}}</td>
                        </tr>
                        <tr>
                            <td><code>LOG10(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.LOG10')}}</td>
                        </tr>
                        <tr>
                            <td><code>SQRT(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.SQRT')}}</td>
                        </tr>
                        <tr>
                            <td><code>CEIL(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.CEIL')}}</td>
                        </tr>
                        <tr>
                            <td><code>FLOOR(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.FLOOR')}}</td>
                        </tr>
                        <tr>
                            <td><code>ROUND(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.ROUND')}}</td>
                        </tr>
                        <tr>
                            <td><code>POWER(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.POWER')}}</td>
                        </tr>
                        <tr>
                            <td><code>ABS(value: DECIMAL | INTEGER)</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.ABS')}}</td>
                        </tr>
                        <tr>
                            <td><code>RANDOM()</code></td>
                            <td><code>DECIMAL</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.RANDOM')}}</td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('mapping-rule-expression-help.component.tab.func.category.datetime')}}</td>
                        </tr>
                        <tr>
                            <td><code>PARSE_WITH_FORMAT(value: DATETIME, format: STRING)</code></td>
                            <td><code>DATETIME</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.PARSE_WITH_FORMAT')}}</td>
                        </tr>
                        <tr>
                            <td><code>ISO_DATE_TIME(value: DATETIME)</code></td>
                            <td><code>DATETIME</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.ISO_DATE_TIME')}}</td>
                        </tr>
                        <tr>
                            <td><code>ISO_DATE(value: DATE)</code></td>
                            <td><code>DATE</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.ISO_DATE')}}</td>
                        </tr>
                        <tr>
                            <td><code>YEAR(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.YEAR')}}</td>
                        </tr>
                        <tr>
                            <td><code>MONTH(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.MONTH')}}</td>
                        </tr>
                        <tr>
                            <td><code>YEAR_MONTH(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.YEAR_MONTH')}}</td>
                        </tr>
                        <tr>
                            <td><code>QUARTER(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.QUARTER')}}</td>
                        </tr>
                        <tr>
                            <td><code>YEAR_QUARTER(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.YEAR_QUARTER')}}</td>
                        </tr>
                        <tr>
                            <td><code>DAY_OF_YEAR(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.DAY_OF_YEAR')}}</td>
                        </tr>
                        <tr>
                            <td><code>DAY_OF_MONTH(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.DAY_OF_MONTH')}}</td>
                        </tr>
                        <tr>
                            <td><code>DAY_OF_WEEK(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.DAY_OF_WEEK')}}</td>
                        </tr>
                        <tr>
                            <td><code>WEEK(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.WEEK')}}</td>
                        </tr>
                        <tr>
                            <td><code>YEAR_WEEK(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.YEAR_WEEK')}}</td>
                        </tr>
                        <tr>
                            <td><code>HOUR(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.HOUR')}}</td>
                        </tr>
                        <tr>
                            <td><code>MINUTE(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.MINUTE')}}</td>
                        </tr>
                        <tr>
                            <td><code>SECOND(value: DATETIME | DATE)</code></td>
                            <td><code>STRING</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.SECOND')}}</td>
                        </tr>
                        <tr>
                            <td><code>TO_TIMESTAMP(value: DATETIME | DATE)</code></td>
                            <td><code>INTEGER</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.TO_TIMESTAMP')}}</td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">{{$t('mapping-rule-expression-help.component.tab.func.category.access')}}</td>
                        </tr>
                        <tr>
                            <td><code>GET(value: MAP, key: STRING)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.GET')}}</td>
                        </tr>
                        <tr>
                            <td><code>GET_OR_ELSE(value: MAP, key: STRING, other: ANY)</code></td>
                            <td><code>ANY</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.GET_OR_ELSE')}}</td>
                        </tr>
                        <tr>
                            <td><code>CONTAINS_KEY(value: MAP, key: STRING)</code></td>
                            <td><code>BOOLEAN</code></td>
                            <td>{{$t('mapping-rule-expression-help.component.tab.func.CONTAINS_KEY')}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
</template>

<script>

export default {
    name: 'expression-functions-help',
}

</script>
<i18n>
{
    "en": {
        "mapping-rule-expression-help.component.tab.func.category.general" : "General Functions",
        "mapping-rule-expression-help.component.tab.func.category.strings" : "String Functions",
        "mapping-rule-expression-help.component.tab.func.category.collection" : "Collection Functions",
        "mapping-rule-expression-help.component.tab.func.category.math" : "Math Functions",
        "mapping-rule-expression-help.component.tab.func.category.datetime" : "Date Time Functions",
        "mapping-rule-expression-help.component.tab.func.category.uuids": "UUID Functions",
        "mapping-rule-expression-help.component.tab.func.category.access": "Access Functions",

        "mapping-rule-expression-help.component.tab.func.LOWERCASE" : "Converts the given string to lowercase, e.g. LOWERCASE('heLlo wOrLd!') will become 'hello world!'.",
        "mapping-rule-expression-help.component.tab.func.UPPERCASE" : "Converts the given string to uppercase, e.g. UPPERCASE('heLlo wOrLd!') will become 'HELLO WORLD!'.",
        "mapping-rule-expression-help.component.tab.func.CONTAINS_TEXT" : "Returns true when the given text contains the expression at least once, false otherwise. Please note: This function is case sensitive.",
        "mapping-rule-expression-help.component.tab.func.CONTAINS_TEXT_IGNORE_CASE" : "Returns true when the given text contains the expression at least once, false otherwise. Please note: This function is NOT case sensitive.",

        "mapping-rule-expression-help.component.tab.func.STARTS_WITH" : "Returns true when the given text starts with the prefix, false otherwise. Please note: This function is case sensitive.",
        "mapping-rule-expression-help.component.tab.func.ENDS_WITH" : "Returns true when the given text end with the suffix, false otherwise. Please note: This function is case sensitive.",
        "mapping-rule-expression-help.component.tab.func.MATCHES_REGEX" : "Returns true when the given text matches the regular expression, false otherwise.",

        "mapping-rule-expression-help.component.tab.func.IS_EMPTY" : "Returns true when the given value is neither null nor empty (emptiness is checked for values of type MAP, LIST, SET and STRING), otherwise false is returned.",
        "mapping-rule-expression-help.component.tab.func.LENGTH" : "Returns the length (i.e. number of characters) of the given string.",
        "mapping-rule-expression-help.component.tab.func.TRIM" : "Removes leading and trailing whitespace from the string.",
        "mapping-rule-expression-help.component.tab.func.SUBSTRING" : "Returns a substring from the given string e.g. SUBSTRING('Hello', 0,3) returns 'Hel'.",

        "mapping-rule-expression-help.component.tab.func.CONTAINS_VALUE" : "Returns true when the given value is contained at least once in the collection, otherwise false is returned.",

        "mapping-rule-expression-help.component.tab.func.IF" : "Evaluates the given condition: When true the first value is returned, other the second value is returned.",
        "mapping-rule-expression-help.component.tab.func.IF_NOT" : "Evaluates the given condition: When false the first value is returned, other the second value is returned. This function negates the IF function.",
        "mapping-rule-expression-help.component.tab.func.IF_NULL" : "Checks if the given value is null: When true the first value will be returned, otherwise the second value will be returned.",
        "mapping-rule-expression-help.component.tab.func.IS_NULL" : "Returns true if the given value is null, otherwise false.",
        "mapping-rule-expression-help.component.tab.func.IS_NOT_NULL" : "Returns false if the given value is null, otherwise true.",
        "mapping-rule-expression-help.component.tab.func.TO_STRING" : "Produces a string representation of the given object. Required e.g. when converting numbers to text for generating IDs.",

        "mapping-rule-expression-help.component.tab.func.LOG" : "Returns the logarithm of the given input.",
        "mapping-rule-expression-help.component.tab.func.LOG10" : "Returns the logarithm 10 of the given input.",
        "mapping-rule-expression-help.component.tab.func.CEIL" : "Returns the nearest integer greater than or equal to the given input, e.g. CEIL(1.23) will produce 2.0.",
        "mapping-rule-expression-help.component.tab.func.FLOOR" : "Returns the nearest integer less than or equal to the given input, e.g. FLOOR(1.68) will produce 1.0.",
        "mapping-rule-expression-help.component.tab.func.ROUND" : "Rounds the given decimal value to it's closest integer, e.g. ROUND(1.23) will produce 1.0 while ROUND(1.5) will produce 2.0.",
        "mapping-rule-expression-help.component.tab.func.RANDOM" : "Returns a random decimal value between 0.0 and 1.0, e.g. 0.9873.",
        "mapping-rule-expression-help.component.tab.func.ABS" : "Returns the absolute value of the given input, e.g. ABS(-1) will produce the value 1.",
        "mapping-rule-expression-help.component.tab.func.POWER" : "Returns the power of the given base and exponent, e.g. POWER(4.0 , 2.0) will return 16.0.",
        "mapping-rule-expression-help.component.tab.func.SQRT" : "Returns the square root of a given value, e.g. SQRT(16.0) will produce the value 4.0.",


        "mapping-rule-expression-help.component.tab.func.PARSE_WITH_FORMAT" : "Formats the given input as a date and time string according to the given format-string. Examples: 2024-08-29T13:49:42.715+02:00 and format yyyy-MM-dd'T'HH:mm:ss.SSSZZ results in '2024-08-29T13:49:42.715+02:00' ",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_MONTH" : "Extracts a string representing the day in month fraction from the given date time input. Examples: 2021-08-09 results in '09'.",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_YEAR" : "Extracts a string representing the day in year fraction from the given date time input. Examples: 2021-01-03 results in '003' ",
        "mapping-rule-expression-help.component.tab.func.WEEK" : "Extracts a string representing the week fraction from the given date time input. Examples: 2021-01-03 results in '01' ",
        "mapping-rule-expression-help.component.tab.func.YEAR_WEEK" : "Extracts a string representing the week and year fraction from the given date time input. Examples: 2021-01-03 results in '2021-01' ",
        "mapping-rule-expression-help.component.tab.func.MONTH" : "Extracts a string representing the month fraction from the given date time input. Examples: 2021-04-27 results in '04' ",
        "mapping-rule-expression-help.component.tab.func.YEAR_MONTH" : "Extracts a string representing the month and year fraction from the given date time input. Examples: 2021-04-27 results in '2021-04' ",
        "mapping-rule-expression-help.component.tab.func.QUARTER" : "Extracts a string representing the qaurter fraction from the given date time input. Examples: 2021-04-27 results in '02' ",
        "mapping-rule-expression-help.component.tab.func.YEAR_QUARTER" : "Extracts a string representing the quarter and year fraction from the given date time input. Examples: 2021-04-27 results in '2021-02' ",
        "mapping-rule-expression-help.component.tab.func.YEAR" : "Extracts a string representing the year fraction from the given date time input. Examples: 2021-04-27 results in '2021' ",
        "mapping-rule-expression-help.component.tab.func.ISO_DATE" : "Formats the given input as a date string according to the ISO-8601 guidelines. Examples: 2021-01-97 results in '2021-01-27' ",
        "mapping-rule-expression-help.component.tab.func.ISO_DATE_TIME" : "Formats the given input as a date and time string according to the ISO-8601 guidelines. Examples: 2021-01-271 06:35:16 pm (GMT+02) results in '2021-01-27T18:35:16.000+02:00' ",
        "mapping-rule-expression-help.component.tab.func.YEAR" : "Returns the year fraction of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 2021 is returned.",
        "mapping-rule-expression-help.component.tab.func.MONTH" : "Returns the month fraction of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 12 is returned (January = 1, February = 2, ..., December = 12).",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_WEEK" :"Returns the index of the weekday of the given datetime, where monday is considered the first day of the week. For 2021-12-01 08:49:27 PM a value of 3 (Wednesday) is returned.",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_YEAR" : "Returns the day of year of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 335 is returned.",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_MONTH" : "Returns the day fraction of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 1 is returned.",
        "mapping-rule-expression-help.component.tab.func.HOUR" : "Returns the hour fraction of the given date time according to the 24h-based clock, e.g. for 2021-12-01 08:49:27 PM a value of 20 (= 8 PM) is returned.",
        "mapping-rule-expression-help.component.tab.func.MINUTE" : "Returns the minute fraction of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 49 is returned.",
        "mapping-rule-expression-help.component.tab.func.SECOND" : "Returns the second fraction of the given date time, e.g. for 2021-12-01 08:49:27 PM a value of 27 is returned.",
        "mapping-rule-expression-help.component.tab.func.TO_TIMESTAMP" : "Returns a timestamp in miliseconds (number of milliseconds since January 1st 1970).",
    
        "mapping-rule-expression-help.component.tab.func.RANDOM_ID" : "Creates a random alphanumeric ID. ",
        "mapping-rule-expression-help.component.tab.func.RANDOM_ID.hint" : "Please Note: Do not use this function when your documents need to be updated at a later time.",
        "mapping-rule-expression-help.component.tab.func.UUID_FROM_STRING" : "Creates a unique ID based on the given input data.",

        "mapping-rule-expression-help.component.tab.func.GET": "Returns the found value found at the given key in the given object. If no value can be found for the given key, null will be returned.",
        "mapping-rule-expression-help.component.tab.func.GET_OR_ELSE": "Returns the found value found at the given key in the given object. If no value can be found for the given key, the third argument will be returned instead.",
        "mapping-rule-expression-help.component.tab.func.CONTAINS_KEY": "Returns if the given object contains the key.",

        "mapping-rule-expression-help.component.tab.func" : "Function",
        "mapping-rule-expression-help.component.tab.description" : "Description",
        "mapping-rule-expression-help.component.tab.returnValue" : "Return Type"
    },
    "de": {
        "mapping-rule-expression-help.component.tab.func.category.general" : "Allgemeine Funktionen",
        "mapping-rule-expression-help.component.tab.func.category.strings" : "Zeichenketten-Funktionen",
        "mapping-rule-expression-help.component.tab.func.category.collection" : "Listen- und Mengen-Funktionen",
        "mapping-rule-expression-help.component.tab.func.category.math" : "Mathematische Funktionen",
        "mapping-rule-expression-help.component.tab.func.category.datetime" : "Datums-Funktionen",
        "mapping-rule-expression-help.component.tab.func.category.uuids": "UUID Funktionen",
        "mapping-rule-expression-help.component.tab.func.category.access": "Zugriffs-Funktionen",

        "mapping-rule-expression-help.component.tab.func.LOWERCASE" : "Konvertiert die angegebene Zeichenkette in Kleinbuchstaben, z.B. LOWERCASE('haLlo wELt!') wird zu 'hallo welt!'.",
        "mapping-rule-expression-help.component.tab.func.UPPERCASE" : "Konvertiert die angegebene Zeichenkette in Großbuchstaben, z.B. LOWERCASE('haLlo wELt!') wird zu 'hallo welt!'.",
        "mapping-rule-expression-help.component.tab.func.CONTAINS_TEXT" : "Gibt true zurück, wenn der angegebene Text den Ausdruck mindestens einmal enthält, andernfalls false. Bitte beachten Sie: Bei dieser Funktion wird zwischen Groß- und Kleinschreibung unterschieden.",
        "mapping-rule-expression-help.component.tab.func.CONTAINS_TEXT_IGNORE_CASE" : "Gibt true zurück, wenn der angegebene Text den Ausdruck mindestens einmal enthält, andernfalls false. Bei dieser Funktion wird NICHT zwischen Groß- und Kleinschreibung unterschieden.",

        "mapping-rule-expression-help.component.tab.func.STARTS_WITH" : "Gibt true zurück, wenn der angegebene Text mit dem Präfix beginnt, sonst false. Bitte beachten Sie: Bei dieser Funktion wird zwischen Groß- und Kleinschreibung unterschieden.",
        "mapping-rule-expression-help.component.tab.func.ENDS_WITH" : "Gibt true zurück, wenn der angegebene Text mit dem Suffix endet, sonst false. Bitte beachten Sie: Bei dieser Funktion wird zwischen Groß- und Kleinschreibung unterschieden.",
        "mapping-rule-expression-help.component.tab.func.MATCHES_REGEX" : "Gibt true zurück, wenn der angegebene Text mit dem regulären Ausdruck übereinstimmt, andernfalls false.",

        "mapping-rule-expression-help.component.tab.func.IS_EMPTY" : "Gibt true zurück, wenn der angegebene Wert weder null noch leer ist (Leer wird für Werte vom Typ MAP, LIST, SET und STRING geprüft), andernfalls wird false zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.LENGTH" : "Gibt die Länge (d.h. die Anzahl der Zeichen) der angegebenen Zeichenkette zurück.",
        "mapping-rule-expression-help.component.tab.func.TRIM" : "Entfernt führende und nachgestellte Leerzeichen aus der Zeichenkette.",
        "mapping-rule-expression-help.component.tab.func.SUBSTRING" : "Gibt eine Teilzeichenfolge aus der angegebenen Zeichenkette zurück z.B. SUBSTRING('Hallo', 0,3) gibt 'Hal' zurück.",

        "mapping-rule-expression-help.component.tab.func.CONTAINS_VALUE" : "Gibt true zurück, wenn der angegebene Wert mindestens einmal im Datenbereich enthalten ist, andernfalls wird false zurückgegeben.",

        "mapping-rule-expression-help.component.tab.func.IF" : "Wertet die angegebene Bedingung aus: Wenn wahr, wird der erste Wert zurückgegeben, andernfalls der zweite Wert.",
        "mapping-rule-expression-help.component.tab.func.IF_NOT" : "Wertet die angegebene Bedingung aus: Bei false wird der erste Wert zurückgegeben, bei other wird der zweite Wert zurückgegeben. Diese Funktion negiert die IF-Funktion.",
        "mapping-rule-expression-help.component.tab.func.IF_NULL" : "Prüft, ob der angegebene Wert null ist: Bei true wird der erste Wert zurückgegeben, andernfalls der zweite Wert.",
        "mapping-rule-expression-help.component.tab.func.IS_NULL" : "Gibt true zurück, wenn der angegebene Wert null ist, andernfalls false.",
        "mapping-rule-expression-help.component.tab.func.IS_NOT_NULL" : "Gibt false zurück, wenn der angegebene Wert null ist, andernfalls true.",
        "mapping-rule-expression-help.component.tab.func.TO_STRING" : "Erzeugt eine String-Darstellung des angegebenen Objekts. Erforderlich z.B. bei der Konvertierung von Zahlen in Text zur Erzeugung von IDs.",

        "mapping-rule-expression-help.component.tab.func.LOG" : "Gibt den Logarithmus des angegebenen Wertes zurück.",
        "mapping-rule-expression-help.component.tab.func.LOG10" : "Gibt den Logarithmus 10 des angegebenen Wertes zurück.",
        "mapping-rule-expression-help.component.tab.func.CEIL" : "Gibt die nächstgelegene ganze Zahl zurück, die größer oder gleich des angegebenen Wertes ist, z.B. CEIL(1.23) ergibt 2.0.",
        "mapping-rule-expression-help.component.tab.func.FLOOR" : "Gibt die nächstgelegene ganze Zahl zurück, die kleiner oder gleich des angegebenen Wertes ist, z.B. FLOOR(1.68) ergibt 1.0.",
        "mapping-rule-expression-help.component.tab.func.ROUND" : "Rundet den angegebenen Dezimalwert auf die nächstliegende ganze Zahl, z.B. ROUND(1.23) ergibt 1.0, ROUND(1.5) ergibt 2.0.",
        "mapping-rule-expression-help.component.tab.func.RANDOM" : "Gibt einen zufälligen Dezimalwert zwischen 0,0 und 1,0 zurück, z.B. 0,9873.",
        "mapping-rule-expression-help.component.tab.func.ABS" : "Gibt den absoluten Wert des angegebenen Wertes zurück, z.B. ergibt ABS(-1) den Wert 1.",
        "mapping-rule-expression-help.component.tab.func.POWER" : "Gibt die Potenz des angegebenen Wertes und des Exponenten zurück, z.B. POWER(4.0 , 2.0) ergibt 16.0.",
        "mapping-rule-expression-help.component.tab.func.SQRT" : "Gibt die Quadratwurzel des angegebenen Wertes zurück, z.B. SQRT(16.0) ergibt den Wert 4.0.",

        "mapping-rule-expression-help.component.tab.func.PARSE_WITH_FORMAT" : "Formatiert das Datum und die Uhrzeit gemäß dem angegebenen Format und gibt diesen String zurück. Beispiel: 2024-08-29T13:49:42.715+02:00 und Format yyyy-MM-dd'T'HH:mm:ss.SSSZZ resultiert in '2024-08-29T13:49:42.715+02:00' ",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_MONTH" : "Entnimmt aus dem übergebenen Datum den Wert des Tages im Monat und gibt diesen als String zurück. Beispiel: 09.08.2021 wird zu '09' ",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_YEAR" : "Entnimmt aus dem übergebenen Datum den Wert des Tages im Jahr und gibt diesen als String zurück. Beispiel: 03.01.2021 wird zu '003' ",
        "mapping-rule-expression-help.component.tab.func.WEEK" : "Entnimmt aus dem übergebenen Datum den Wert der Woche gibt diesen als String zurück. Beispiel: 03.01.2021 wird zu '01' ",
        "mapping-rule-expression-help.component.tab.func.YEAR_WEEK" : "Entnimmt aus dem übergebenen Datum den Wert der Woche und des Jahres gibt diesen als String zurück. Beispiel: 03.01.2021 wird zu '2021-01' ",
        "mapping-rule-expression-help.component.tab.func.MONTH" : "Entnimmt aus dem übergebenen Datum den Wert des Monats gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '04' ",
        "mapping-rule-expression-help.component.tab.func.YEAR_MONTH" : "Entnimmt aus dem übergebenen Datum den Wert des Monats und des Jahres gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '2021-04' ",
        "mapping-rule-expression-help.component.tab.func.QUARTER" : "Entnimmt aus dem übergebenen Datum den Wert des Quartals gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '02' ",
        "mapping-rule-expression-help.component.tab.func.YEAR_QUARTER" : "Entnimmt aus dem übergebenen Datum den Wert des Quartals und des Jahres gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '2021-02' ",
        "mapping-rule-expression-help.component.tab.func.YEAR" : "Entnimmt aus dem übergebenen Datum den Wert des Jahres gibt diesen als String zurück. Beispiel: 27.04.2021 wird zu '2021' ",
        "mapping-rule-expression-help.component.tab.func.ISO_DATE" : "Formatiert das Datum und die Uhrzeit gemäß ISO-8601 Richtline und gibt diesen String zurück. Beispiel: 27.01.2021 wird zu '2021-01-27' ",
        "mapping-rule-expression-help.component.tab.func.ISO_DATE_TIME" : "Formatiert das Datum und die Uhrzeit gemäß ISO-8601 Richtline und gibt diesen String zurück. Beispiel: 27.01.2021 18:35:16 (GMT+02) wird zu '2021-01-27T18:35:16.000+02:00' ",
        "mapping-rule-expression-help.component.tab.func.YEAR" : "Gibt das Jahr des angegebenen Zeitstempels zurück, z.B. für 2021-12-01 08:49:27 PM wird der Wert 2021 zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.MONTH" : "Gibt den Monat des angegebenen Zeitstempels zurück, z.B. für 2021-12-01 08:49:27 PM wird der Wert 12 zurückgegeben (Januar = 1, Februar = 2, ..., Dezember = 12).",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_WEEK" :"Gibt den Index des Wochentags des angegebenen Zeitstempels zurück, wobei der Montag als erster Tag der Woche gilt. Für 2021-12-01 08:49:27 PM wird der Wert 3 (Mittwoch) zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_YEAR" : "Gibt den Tag im Jahr für den angegebenen Zeitstempel zurück, z.B. für 2021-12-01 08:49:27 PM wird der Wert 335 zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.DAY_OF_MONTH" : "Gibt den Tag im Monat für den angegebenen Zeitstempel zurück z.B. for 2021-12-01 08:49:27 PM wird der Wert 1 zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.HOUR" : "Gibt die Stunde des angegebenen Zeitstempels gemäß der 24h-basierten Uhr zurück, z.B. für 2021-12-01 08:49:27 PM wird der Wert 20 (= 8 PM) zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.MINUTE" : "Gibt die Minuten des angegebenen Zeitstempels zurück, z.B. für 2021-12-01 08:49:27 PM wird der Wert 49 zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.SECOND" : "Gibt die Sekunden des angegebenen Zeitstempels zurück, z.B. für 2021-12-01 08:49:27 PM wird der Wert 27 zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.TO_TIMESTAMP" : "Gibt einen Zeitstempel in Millisekunden zurück (Anzahl Millisekunden seit 01. Januar 1970).",
    
        "mapping-rule-expression-help.component.tab.func.RANDOM_ID" : "Erzeugt eine zufallsgenerierte ID.",
        "mapping-rule-expression-help.component.tab.func.RANDOM_ID.hint" : "Achtung: Verwenden Sie diese Funktion nicht, wenn das Dokument später aktualisiert werden soll.",
        "mapping-rule-expression-help.component.tab.func.UUID_FROM_STRING" : "Erzeugt eine eindeutige ID auf Basis des übergebenen Objekts.",

        "mapping-rule-expression-help.component.tab.func.GET": "Gibt den gefundenen Wert zurück, der unter dem angegebenen Schlüssel im Objekt gefunden wurde. Wenn kein Wert für den angegebenen Schlüssel gefunden werden kann, wird null zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.GET_OR_ELSE": "Gibt den gefundenen Wert zurück, der unter dem angegebenen Schlüssel im Objekt gefunden wurde. Wenn kein Wert für den angegebenen Schlüssel gefunden werden kann, wird stattdessen das dritte Argument zurückgegeben.",
        "mapping-rule-expression-help.component.tab.func.CONTAINS_KEY": "Gibt zurück, ob das angegebene Objekt den Schlüssel enthält.",


        "mapping-rule-expression-help.component.tab.func" : "Funktion",
        "mapping-rule-expression-help.component.tab.description" : "Beschreibung",
        "mapping-rule-expression-help.component.tab.returnValue" : "Rückgabewert"
    }

}
        
</i18n>