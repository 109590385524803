<template>
    <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form :title="$t('battery-status-editor.view.title', { name: name })" :value="batteryStatus"
            @update-confirmed="save" @update-canceled="cancel" :editable="hasEditRole" :hideSaveAction="!hasEditRole">

            <template v-slot:default>
                <v-sheet color="grey lighten-3">

                    <v-tabs v-model="selectedTab" :vertical="$vuetify.breakpoint.mdAndUp" color="grey"
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" slider-color="grey"
                        show-arrows :fixed-tabs="!$vuetify.breakpoint.mdAndUp">


                        <!-- Tabs -->
                        <v-tab :key="0">{{ $t('battery-status-editor.view.tabs.general') }}</v-tab>
                        <v-tab :key="1">{{ $t('battery-status-editor.view.tabs.settings') }}</v-tab>
                        <v-tab :key="2">{{ $t('battery-status-editor.view.tabs.json') }}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="batteryStatus" :disabled="!hasEditRole" />
                            </v-tab-item>

                            <!-- Color Settings (Color, etc.) -->
                            <v-tab-item :key="1">
                                <v-card tile flat :disabled="!hasEditRole">
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('battery-status-editor.view.component.title') }}</div>
                                        <div>{{ $t('battery-status-editor.view.component.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                        <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t('battery-status-editor.view.sections.color.title') }}</div>
                                        <div>{{ $t('battery-status-editor.view.sections.color.subtitle') }}</div>
                                        <c-color-single-selector :items="defaultColors" v-model="color" flat
                                            color="white" />
                                    </v-card-text>
                                </v-card>

                            </v-tab-item>

                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="batteryStatus" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
        </c-item-editor-form>
    </v-sheet>
</template>

<script>
import { get } from 'lodash';


import ColorItems from "../../../constants/colors.constants";
import BatteryStatusEditorModule from "../../../store/masterdata/battery-status/battery-status-editor.module";

import Roles from '@/roles';

const MODULE_ID = 'batteryStatusEditor';

export default {

    name: 'battery-status-editor',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],



    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },



        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/saveData");
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push({
                name: "BatteryStatusListView",
            });
        }
    },

    computed: {

        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.BATTERYSTATUS_EDIT);
        },

        defaultColors() {
            return ColorItems;
        },

        batteryStatus: {

            get() {
                return this.$store.getters[MODULE_ID + '/batteryStatus'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryStatus", value);
            }
        },

        color: {
            get() {
                return this.$store.getters[MODULE_ID + '/color'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateColor", value);
            }

        },


    },

    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, BatteryStatusEditorModule);
        }

        this.refresh();

    }
}
</script>

<i18n>
   
{
    "en" : {
        "battery-status-editor.view.title" : "Battery Status {name}",

        "battery-status-editor.view.tabs.general" : "General",
        "battery-status-editor.view.tabs.settings" : "Settings",
        "battery-status-editor.view.tabs.json" : "JSON",
        "battery-status-editor.view.component.title":"Settings",
        "battery-status-editor.view.component.subtitle": "This page contains settings for the selected color.",
        "battery-status-editor.view.sections.color.title":"Color",
        "battery-status-editor.view.sections.color.subtitle" : "Please assign the Battery Status a color with which it can be displayed in the user interface."
    },

    "de" : {
        "battery-status-editor.view.title" : "Batterie Status {name}",
        "battery-status-editor.view.tabs.general" : "Allgemein",
        "battery-status-editor.view.tabs.settings" : "Einstellungen",
        "battery-status-editor.view.tabs.json" : "JSON",
        "battery-status-editor.view.component.title":"Einstellungen",
        "battery-status-editor.view.component.subtitle": "Diese Seite beinhaltet Einstellungen für die ausgewählte Farbe.",
        "battery-status-editor.view.sections.color.title":"Farbe",
        "battery-status-editor.view.sections.color.subtitle" : "Bitte weisen Sie dem Batterie-Status eine Farbe zu, damit er in der Benutzeroberfläche dargestellt werden kann."

    }
}
</i18n>