import api from '@/store/api';
import { get } from 'lodash';

export default {

    namespaced: true,

    state : {

        tag: createDefaultTag(),
    },

    mutations: {

        setTag(state, tag) {

            console.log("=====================================")
            console.log(JSON.stringify(tag, true, 2))
            console.log("=====================================")

            if (tag) {
                state.tag.color = tag.color;
                state.tag.name = tag.name;
                state.tag.i18n = tag.i18n;
                state.tag.creationDateTime     = tag.creationDateTime;
                state.tag.creationUser         = tag.creationUser;
                state.tag.modificationDateTime = tag.modificationDateTime;
                state.tag.modificationUser     = tag.modificationUser;
            }
            
        }

    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.catalog.tags.findById(name);
                context.commit('setTag', get(response, 'data.tag', createDefaultTag()));
            } catch (error) {
                context.commit('setTag', createDefaultTag());
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters.model, 'tag');
                console.log(">>> VUEX SAVE DATA ", JSON.stringify(payload));
                let response = await api.catalog.tags.update(payload.name, payload);
                context.commit('setTag', get(response, 'data.tag', createDefaultTag()));

            } catch (error) {
                console.log(error);
                context.commit('setTag', createDefaultTag());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            console.log('+++ updateModel ', JSON.stringify(model, true, 2));
            context.commit('setTag', get(model, 'tag', createDefaultTag()));
        },

        updateTag(context, tag) {
            console.log('+++ updateTag ', JSON.stringify(tag, true, 2));
            context.commit('setTag', tag);
        }
    },

    getters: {
        
        model(state) {
            return {
                tag: state.tag
            }
        },
        
    }
}

function createDefaultTag() {
    return {
        name: null,
        i18n: null,
        color: null,
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }
}