<template>
    <v-sheet  color="secondary">

        <c-section :title="$t('detection-job-templates-list.view.sections.list.title')" :subtitle="$t('detection-job-templates-list.view.sections.list.subtitle')" class="secondary">

            <v-card class="mt-5 mb-5" tile>
                <c-item-list 
                    :title="$t('detection-job-templates-list.view.toolbar.title')" 
                    :items="items" 
                    :pagination="pagination"
                    :disable-add-item="!hasCreateRole" 
                    :disable-edit-item="!hasEditRole && !hasReadRole" 
                    :disable-delete-item="!hasDeleteRole"
                    @refresh-list="refresh"
                    @previous-page="onPreviousPage"
                    @next-page="onNextPage"
                    @item-added="onItemAdded" 
                    @item-edited="onItemEdited"
                    @item-deleted="onItemDeleted"
                    @item-list-filter-applied="onFilterChanged"
                >
                    <template v-slot:item-list-table-head-columns>
                        <th class="text-center">{{$t('detection-job-templates-list.view.columns.active')}}</th>
                        <th class="text-center">{{$t('detection-job-templates-list.view.columns.createLog')}}</th>
                    </template>

                    <template v-slot:item-list-table-body-columns="props">
                        <td class="text-center"><v-icon small v-if="props.item.active">check_box</v-icon><v-icon small v-else>check_box_outline_blank</v-icon></td>
                        <td class="text-center"><v-btn icon @click="showConfirmationDialog(props.item)"><v-icon>add_circle_outline</v-icon></v-btn></td>
                    </template>
                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('detection-job-templates-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('detection-job-templates-list.view.help.description')}}</v-card-text>
            </v-card>

            <v-dialog v-model="displayConfirmationDialog" width="430" >
                <v-card tile>
                    <v-card-title>
                        {{$t('detection-job-templates-list.view.createLog.dialog.title')}}
                    </v-card-title>
                    <v-spacer/>
                    <v-card-subtitle>
                        {{$t('detection-job-templates-list.view.createLog.dialog.subtitle')}}
                    </v-card-subtitle>
                    <v-card-actions class="text-right pa-5">
                        <v-spacer />
                        <v-btn text color="accent" @click="onConfirm">{{$t('detection-job-templates-list.view.createLog.dialog.confirm')}}</v-btn>
                        <v-btn text color="grey" @click="onCancel">{{$t('detection-job-templates-list.view.createLog.dialog.cancel')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </c-section>
        
    </v-sheet>


</template>

<script>
import Roles from '@/roles';
import { get } from 'lodash';
const MODULE_NAME = 'detectionJobTemplatesList';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    data: () =>({

        selectedTemplate: {},

        displayConfirmationDialog : false,
        
     }),

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        },

        pagination() {
            return this.response.pagination;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.DETECTION_JOB_TEMPLATES_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.DETECTION_JOB_TEMPLATES_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.DETECTION_JOB_TEMPLATES_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.DETECTION_JOB_TEMPLATES_DELETE);
        }
    },

    methods: {

        async refresh() {
            this.loadPage(0, 50);
        },

        async onNextPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse", {from, pageSize});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemEdited(event) {

            if (event) {
                this.$router.push({
                    name: 'detectionJobTemplatesEditor',
                    params: {
                        name : event.item.name,
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to schema editor.")
            }
        },

        async onItemDeleted({ item }) {
            this.progressIndicatorService.show();
            try {

                // Delete the item, then reload the list in order to reflect change.
                await this.$store.dispatch(MODULE_NAME + "/deleteItem", item);
                this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemAdded() {
            this.$router.push({ name: 'detectionJobTemplatesWizard'});
        },

        showConfirmationDialog(template) {
            this.selectedTemplate = template;
            this.displayConfirmationDialog = true;
        },

        hideConfirmationDialog() {
            this.displayConfirmationDialog = false;
        },

        onConfirm() {
            this.createLogCollection(this.selectedTemplate);
            this.hideConfirmationDialog();
        },

        onCancel() {
           this.hideConfirmationDialog(); 
        },

        async createLogCollection(template){
            this.progressIndicatorService.show();
            try {
                const logResponse = await this.$store.dispatch(MODULE_NAME + "/createLogCollection", template);

                // route to created collection setting
                const createdCollectionId = get(logResponse, 'data.collectionSettings.collectionId', "");
                this.$router.push({
                    name: 'collectionSettingsEditor',
                    params: { name: createdCollectionId} 
                });
            }finally{
                this.progressIndicatorService.hide();
            }
            },
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {

        "detection-job-templates-list.view.sections.list.title" : "Detection Job Templates",
        "detection-job-templates-list.view.sections.list.subtitle" : "You may use this dialog for creating, editing or deleting detection job templates.",
        "detection-job-templates-list.view.toolbar.title" : "Detection Job Templates",

        "detection-job-templates-list.view.columns.active" : "Active",

        "detection-job-templates-list.view.help.title" : "What are Detection Job Templates?",
        "detection-job-templates-list.view.help.description" : "Detection job templates are used for data preparation, e.g. for training data set in machine learning. In a detection job template, you define detection rules for transforming data. You can execute the template as often as you like. Here, data is read from a source collection, transformed and finally written to the target collection.",

        "detection-job-templates-list.view.columns.createLog" : "Enable Logging",
        "detection-job-templates-list.view.createLog.dialog.title" : "Create collection and schema for logging",
        "detection-job-templates-list.view.createLog.dialog.subtitle" : "The creation of the logging collection may take up to several minutes. Do you really want to start this process?",
        "detection-job-templates-list.view.createLog.dialog.confirm" : "Start now",
        "detection-job-templates-list.view.createLog.dialog.cancel" : "Cancel"
    },

    "de" : {

        "detection-job-templates-list.view.sections.list.title" : "Detection Job Vorlagen",
        "detection-job-templates-list.view.sections.list.subtitle" : "Sie können neue Templates für detection Jobs anlegen, Templates editieren oder löschen.",
        "detection-job-templates-list.view.toolbar.title" : "Detection Job Vorlagen",

        "detection-job-templates-list.view.columns.active" : "Aktiv",

        "detection-job-templates-list.view.help.title" : "Was sind Detection Job Vorlage?",
        "detection-job-templates-list.view.help.description" : "Detection Job Vorlagen dienen der Datenvorbereitung, z.B. für Trainingsdaten im Machine Learning. In einer Detection Job Vorlage definieren Sie Abbildungsregeln zur Transformation von Daten zwischen zwei Datenbereichen. Sie können die Vorlage beliebig oft ausführen. Hierbei werden Daten aus einem Quelldatenbereich ausgelesen, transformiert und schließlich in den Zieldatenbereich geschrieben.",
    
        "detection-job-templates-list.view.columns.createLog" : "Logging aktivieren",
        "detection-job-templates-list.view.createLog.dialog.title" : "Erstellung Logging-Datenbereich/Schema",
        "detection-job-templates-list.view.createLog.dialog.subtitle" : "Die Erstellung des Logging-Datenbereichs/Schemas kann ggf. einige Minuten dauern. Möchten Sie diesen Prozess wirklich starten?",
        "detection-job-templates-list.view.createLog.dialog.confirm" : "Jetzt ausführen",
        "detection-job-templates-list.view.createLog.dialog.cancel" : "Abbrechen"

    }
}
</i18n>