<template>
    <v-sheet>
    
    <dxs-category-section :title="$t('supply-chain.page.sections.dueDiligenceReport.title')">
        <v-container fluid>
            <v-row class="justify-center">
                <v-col cols="12" sm="8" lg="6">
                    <c-link-card :value="dueDiligenceReport" tile/>
                </v-col>
            </v-row>
        </v-container>
    </dxs-category-section>
    <v-divider />
    
    <dxs-category-section :title="$t('supply-chain.page.sections.additionalVoluntary.title')">
        <v-container fluid>
            <v-row class="justify-center">
                <v-col cols="12" sm="8" lg="6">
                    <v-card tile>
                       <c-link-list :items="additionalVoluntary" open-link-icon="link" /> 
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </dxs-category-section>
    </v-sheet>
</template>

<script>

import CategorySectionComponent from '@/solutions/battery-data-hub/components/common/category-section.component.vue';
import { get } from 'lodash';

export default {
    
    name: 'battery-supply-chain-page',

    components: {
        'dxs-category-section' : CategorySectionComponent,
    },

    props: {

        value: {
            type: Object,
            required: false
        }
    },

    computed: {
        
        dueDiligenceReport() {
            return get(this.value, 'dueDiligenceReport', {});
        },

        additionalVoluntary() {
            return get(this.value, 'additionalVoluntary', []);
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "supply-chain.page.sections.dueDiligenceReport.title" : "Due Diligence Report",
        "supply-chain.page.sections.additionalVoluntary.title" : "Additional Voluntary Reports"
    },

    "de" : {
        "supply-chain.page.sections.dueDiligenceReport.title" : "Due Diligence Bericht (Sorgfaltspflicht)",
        "supply-chain.page.sections.additionalVoluntary.title" : "Freiwillige Zusatz-Berichte"
    }
}
</i18n>