import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state: {
        tenantId: "",
        name: "",
        batterySubstance: createEmptyResponse(),
        hazardousSubstanceClassifications:  createEmptyHazardousSubstanceClassifications(),
    },

    mutations: {

        async setHazardousSubstanceClassificationId(state, hazardousSubstanceClassificationId) {
            if (hazardousSubstanceClassificationId) {
                //const payload = await api.masterdata.hazardousSubstanceClassifications.findById(hazardousSubstanceClassificationId);
                state.batterySubstance.references.hazardousSubstanceClassification = hazardousSubstanceClassificationId;
                
            } else {
                state.batterySubstance.references.hazardousSubstanceClassification = null;
            }
        },


        setBatterySubstance(state, batterySubstance) {

            if (batterySubstance) {
                state.name = batterySubstance.name ? batterySubstance.name : null;
                state.batterySubstance.tenantId = batterySubstance.tenantId ? batterySubstance.tenantId : null;
                state.batterySubstance.name = batterySubstance.name ? batterySubstance.name : null;
                state.batterySubstance.i18n = batterySubstance.i18n ? batterySubstance.i18n : {};
                state.batterySubstance.data = batterySubstance.data ? batterySubstance.data : { custData: {} };
                state.batterySubstance.active = batterySubstance.active ? batterySubstance.active : true;
                state.batterySubstance.references.hazardousSubstanceClassification = batterySubstance.references.azardousSubstanceClassification ? batterySubstance.references.hazardousSubstanceClassification : null;
                state.batterySubstance.creationDateTime = batterySubstance.creationDateTime ? batterySubstance.creationDateTime : null;
                state.batterySubstance.creationUser = batterySubstance.creationUser ? batterySubstance.creationDateTime : null;
                state.batterySubstance.modificationDateTime = batterySubstance.modificationDateTime ? batterySubstance.modificationDateTime : null;
                state.batterySubstance.modificationUser = batterySubstance.modificationUser ? batterySubstance.modificationUser : null;
            } else {
                state.batterySubstance.tenantId = null;
                state.batterySubstance.name = null;
                state.batterySubstance.i18n = null;
                state.batterySubstance.data = null;
                state.batterySubstance.active = null;
                state.batterySubstance.references = null;
                state.batterySubstance.creationDateTime = null;
                state.batterySubstance.modificationDateTime = null;
                state.batterySubstance.creationUser         = null;
                state.batterySubstance.modificationUser = null;
            }

        },
        setHazardousSubstanceClassifications(state, hazardousSubstanceClassifications) {
            if (hazardousSubstanceClassifications) {

                let items = [];
                if (hazardousSubstanceClassifications.items) {
                    state.hazardousSubstanceClassifications.items = hazardousSubstanceClassifications.items;
                } else {
                    state.hazardousSubstanceClassifications.items = items;
                }

                if (hazardousSubstanceClassifications.tenantId) {
                    state.hazardousSubstanceClassifications.tenantId = hazardousSubstanceClassifications.tenantId;
                }

                if (hazardousSubstanceClassifications.pagination) {
                    state.hazardousSubstanceClassifications.pagination = hazardousSubstanceClassifications.pagination;
                }

            }
        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        },
        

    },

    actions:{

        async loadHazardousSubstanceClassifications(context) {

            try {
                let hazardousSubstanceClassifications = await api.masterdata.hazardousSubstanceClassifications.findByFilter('*', 0, 100);
                context.commit('setHazardousSubstanceClassifications', hazardousSubstanceClassifications);
            } catch (error) {
                context.commit('setHazardousSubstanceClassifications', createEmptyResponse());
                throw error;
            }
        },
        async checkForBatterySubstance(context){
            let name = context.getters.name;
            const answer = await api.masterdata.batterySubstances.findById(name);
            if(answer.item.name){
            throw new Error(`This Battery Substance already exists ${answer.item.batterySubstance.name} with a reference to ${answer.item.hazardousSubstanceClassification.name} as Hazardous Substance Classification.`);
            }else{
                return {};
            }
        },



        async createBatterySubstance(context) {

            try {
                
                let request = context.getters.request;
                console.log(request)
                
                await api.masterdata.batterySubstances.create(request);
            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async updateHazardousSubstanceClassificationId(context, hazardousSubstanceClassificationId) {
            try {
                context.commit('setHazardousSubstanceClassificationId', hazardousSubstanceClassificationId);
            } catch (error) {
                return Promise.reject(error);
            }

        },
       
        async resetBatterySubstance(context) {
            context.commit('setBatterySubstance', createEmptyResponse());
        },

        async updateBatterySubstance(context, batterySubstance) {

            try {
                context.commit('setBatterySubstance', batterySubstance);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateBatterySubstances(context, batterySubstance) {

            try {
                context.commit('setBatterySubstance', batterySubstance);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.batterySubstance;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        batterySubstance(state) {
            return state.batterySubstance ? state.batterySubstance : createEmptyResponse();
        },

        hazardousSubstanceClassifications(state) {
            return state.hazardousSubstanceClassifications ? state.hazardousSubstanceClassifications : createEmptyResponse();
        },
        name(state){
            return state.batterySubstance.name;
        },

        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },
        hazardousSubstanceClassificationId(state) {
            return get(state, 'batterySubstance.references.hazardousSubstanceClassification');
        },

    }
}

function createEmptyResponse() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { custData: {} },
        "active": true,
        "references": {
            hazardousSubstanceClassification: null
        }

    }
}


function createEmptyHazardousSubstanceClassifications() {

    return {

        "items": ["Healthy","Edible","Bad Smell"]
        
    }
}
