<template>
  <v-card flat tile>
    
    <!--Common Information -->
    <v-subheader>Allgemein</v-subheader>
    <v-card-text>
      Der technische Bezeichner kann nach der Anlage nicht mehr verändert werden und wird zu Informationszwecken angezeigt. 
    </v-card-text>
    <v-card-text>
        <v-text-field :value="name" disabled filled label="Technischer Bezeichner" hint=" Der technische Bezeichner kann nach der Anlage nicht mehr verändert werden und wird zu Informationszwecken angezeigt. " persistent-hint/>
        <v-text-field :value="name" disabled filled label="Datenbereich" />
        <v-text-field :value="name" disabled filled label="Kennzahl" />
        <c-item-selector 
                    label="Aggregation auswählen"
                    v-model="aggregation"
                    :items="aggregationOperations"
                    filled
                    :rules="[ v => v !== null && v !== undefined || $t('metric-definition-editor.component.fields.aggregation.notEmpty') ]"
                />
    </v-card-text>
    <v-subheader>Zeithorizont und Filter festlegen</v-subheader>
    <v-card-text>
        Legen Sie den Zeithorizont fest, auf dessen Grundlage die Metrik berechnet wird.
    </v-card-text>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <c-item-selector 
                        label="Datums-Dimension auswählen"
                        :items="metricsTimeHorizonOptions"
                        filled
                    />
            </v-col>
            
        </v-row>
        <v-row>
            <v-col cols="12">
                <c-item-selector 
                        label="Zeithorizont auswählen"
                        :items="metricsTimeHorizonOptions"
                        filled
                    />
            </v-col>
            
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <c-item-selector 
                    label="Zeithorizont Vergangenheit"
                    :items="aggregationOperations"
                    filled
                />
            </v-col>
            <v-col cols="12" md="6">
                <c-item-selector 
                    label="Zeithorizont Zukunft"
                    :items="aggregationOperations"
                    filled
                />
            </v-col>
        </v-row>
    </v-container>
    <v-card-text>
        Bitte definieren Sie einen Filter. Durch den Filter wird festgelegt, welche Datensätze bei Berechnung der Metrik berücksichtigt werden. 
    </v-card-text>
    <c-filter-editor 
        v-model="filter"
        filled 
        :disabled="false"   
    />

    <!-- I18N -->  
    <v-divider />

    

    <!-- View -->
    <v-divider/>
    <v-subheader>Anzeige</v-subheader>
    <v-card-text>
        Bitte wählen Sie den Anzeigemodus für diese Kennzahl.
    </v-card-text>
    <c-i18n-editor 
        v-model="i18n" 
        header-style="lite" 
        flat 
        class="pa-5"
    />

    <v-card-text>
        <c-item-selector 
            label="Darstellung auswählen"
            :items="metricsDisplayOptions"
            filled
        />
        <v-text-field :value="60" type="number" filled label="Aktualisierung Intervall" suffix="Sekunden"/>

    </v-card-text>
  </v-card>
</template>

<script>
import { get } from 'lodash';
import { set } from 'lodash';

import AggregationOperations from '@/core/types/aggregation-operations.enum';
import MetricsDisplayOptions from '@/core/types/metrics-display-options.enum';
import MetricsTimeHorizonOptions from '@/core/types/metrics-time-horizon-options.enum';

export default {

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data() {

        return {
            model: this.value
        }
    },

    methods: {

        fireInput() {
            this.$emit('input', this.model);
        }
    },

    computed: {

        name: {

            get() {
                return get(this.model, 'name');
            },

            set(value) {
                set(this.model, 'name', value);
                this.fireInput();
            } 

        },

        aggregation: {
            get() {
                return get(this.model, 'aggregation');
            },

            set(value) {
                set(this.model, 'aggregation', value);
                this.fireInput();
            } 
        },

        filter: {

            get() {
                return get(this.model, 'filter', { 

                });
            },

            set(value) {
                set(this.model, 'filter', value);
                this.fireInput();
            } 
        },

        i18n : {

            get() {
                return get(this.model, 'i18n', { 

                });
            },

            set(value) {
                set(this.model, 'i18n', value);
                this.fireInput();
            }
        },

        aggregationOperations() {
            return AggregationOperations;
        },

        metricsDisplayOptions() {
            return MetricsDisplayOptions;
        },

        metricsTimeHorizonOptions() {
            return MetricsTimeHorizonOptions;
        }
    }

}
</script>

<style>

</style>