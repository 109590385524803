<template>
  <v-card tile >
    <v-card-text :class="type" class="white--text">
        {{ title }}
    </v-card-text>
    <v-card-text>
        {{ text }}
    </v-card-text>
  </v-card>
</template>

<script>
/**
 * Admissible types
 */
const TYPES = ['info', 'error', 'warning'];
import { isObject, isString, get } from 'lodash'; 

export default {

    props: {
        value: {
            type: [Object, String],
            required: true,
            validator: (v) => isObject(v) ? (v.title && v.text) : isString(v)                      
        }
    },

    computed: {

        title() {
            return get(this.value, 'title', this.$t('user-message-toast.component.types.' + this.type) );
        },

        text() {
            return isString(this.value) 
                 ? this.value 
                 : get(this.value, 'text', null);
        },

        type() {
            const type = get(this.value, 'type', 'info');
            if (TYPES.indexOf(type)) {
                return type;
            } else {
                return TYPES[0];
            }
        }

    }

}
</script>

<i18n>
{
    "en" : {
        "user-message-toast.component.types.info" : "Information",
        "user-message-toast.component.types.warning" : "Warning",
        "user-message-toast.component.types.error" : "Error"
    },

    "de" : {
        "user-message-toast.component.types.info" : "Information",
        "user-message-toast.component.types.warning" : "Warnung",
        "user-message-toast.component.types.error" : "Fehler"
    }
}
</i18n>