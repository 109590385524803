<template>
    <div>

            <v-container fluid class="secondary pt-10 pb-10">
                <v-row justify="center">
                    <v-col cols="12" lg="9">
                        <c-base-dialog 
                            :value="schema"
                            :title="$t('new-schema-wizard.view.title')" 
                            :subtitle="$t('new-schema-wizard.view.subtitle')"
                            color="secondary"
                            @dialog-saved="save"
                            @dialog-canceled="cancel"
                            >
                            <template v-slot:default="props"> 
                                <v-card tile flat>
                                    <v-card-text>
                                        <c-name-input 
                                            v-model="props.item.name"
                                            filled />
                                    </v-card-text>
                                </v-card>
                            </template>
                        </c-base-dialog>
<!--
                        <v-card tile>
                            <v-card-text >
                                <div class="text-h6">{{$t('new-schema-wizard.view.title')}}</div>
                                <div class="text-subtitle-1">{{$t('new-schema-wizard.view.subtitle')}}</div>
                            </v-card-text>
                            <v-divider />
                            <v-card-text />

                            <v-form ref="form1" v-model="form1Valid" lazy-validation>
                                
                                <v-card-text>
                                    <div class="text-subtitle-1">{{$t('new-schema-wizard.view.stepper.1.content.name.title')}}</div>
                                    <p />
                                    <div >{{$t('new-schema-wizard.view.stepper.1.content.name.subtitle')}}</div>
                                </v-card-text>
                                <v-card-text>

                                    <v-text-field
                                        filled
                                        v-model="name"
                                        :label="$t('new-schema-wizard.view.stepper.1.content.name.label')"
                                        :rules="[v => v && !!v.trim() && v.length >= 3  || $t('new-schema-wizard.view.stepper.1.content.name.validation.error', $i18n.locale)]"
                                    />
                                </v-card-text>
                            </v-form>
                            

                            
                            <v-card-text />
                            <v-divider />
                            <v-card-actions>
                                <v-btn label="Speichern" @click="save" text color="accent">Speichern</v-btn>
                                <v-btn label="Abbrechen" @click="cancel" text >Abbrechen</v-btn>
                            </v-card-actions>
                        </v-card>
                        -->
                    </v-col>
                </v-row>

                <!--
                <v-row justify="center">
                    <v-col cols="12" lg="9">
                        <v-card tile>
                            <v-card-text class="accent white--text">
                                <div class="text-h6"><v-icon color="white">help</v-icon>  Hilfe</div>
                            </v-card-text>
                            <v-card-text>
                                <div class="text-h6">Was ist ein Schema?</div>
                            </v-card-text>
                            <v-card-text>
                                Mit einem Schema definieren Sie die Struktur der Dokumente, die innerhalb eines Datenbereichs gespeichert werden können.
                                Die Struktur wird hierbei durch Attribute festgelegt. Durch die Eigenschaften der Attribute können Sie deren Verhalten in der Anwendung beeinflussen.
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                -->
            </v-container>

    </div>
</template>

<script>
const MODULE_NAME = 'newSchemaWizard';

export default {


    inject: ['errorHandlerService', 'progressIndicatorService'],


    methods: {

        async reset() {
            this.progressIndicatorService.show();
           
            try {
                await this.$store.dispatch(MODULE_NAME + "/resetSchema");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async save() {
            
            this.progressIndicatorService.show();
           
            try {
                
                await this.$store.dispatch(MODULE_NAME + "/createSchema");
                
                this.$router.push( {name: 'schemaEditor', params: {name: this.name}  });

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push( {
                name: 'schemaList'
            });
        }
    },
    
    computed: {


        schema: {

            get() {
                return this.$store.getters[MODULE_NAME + '/schema'];
            }, 

            set(item) {
                
                this.$store
                    .dispatch(MODULE_NAME + "/updateSchema", item)
                    .catch(this.errorHandlerService.handleError);
            }
        },

        name: {

            get() {
                return this.schema ? this.schema.name : null;
            }
        }

    },

    created() {
        this.reset();
    }
            
         
}
</script>

<i18n>
{
    "en" : {
        "new-schema-wizard.view.title" : "Create New Schema",
        "new-schema-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new schema.",
        "new-schema-wizard.view.stepper.1.header" : "Define Masterdata",
        "new-schema-wizard.view.stepper.2.header" : "Validate and Save",

        "new-schema-wizard.view.stepper.1.content.name.title" : "Define Unique Technical Name",
        "new-schema-wizard.view.stepper.1.content.name.subtitle" : "Please define a unqiue technical name for your schema. The technical name can't be changed after creating the schema.",
        "new-schema-wizard.view.stepper.1.content.name.label" : "Technical Name",
        "new-schema-wizard.view.stepper.1.content.name.validation.error" : "Please provide a name that is at least 3 characters long."

    },

    "de" : {
        "new-schema-wizard.view.title" : "Neues Schema anlegen",
        "new-schema-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zum Anlegen eines neuen Schemas.",
        "new-schema-wizard.view.stepper.1.header" : "Stammdaten anlegen",
        "new-schema-wizard.view.stepper.2.header" : "Prüfen und Speichern",

        
        
        "new-schema-wizard.view.stepper.1.content.name.title" : "Eindeutigen Bezeichner festlegen",
        "new-schema-wizard.view.stepper.1.content.name.subtitle" : "Legen Sie einen eindeutigen Bezeichner (Technischer Bezeichner) für Ihr Schema fest. Der technische Bezeichner kann nach dem Anlegen des Schemas nicht mehr geändert werden.",
        "new-schema-wizard.view.stepper.1.content.name.label" : "Technischer Bezeichner",
        "new-schema-wizard.view.stepper.1.content.name.validation.error" : "Bitte geben Sie einen Bezeichner ein der mindestens 3 Zeichen enthält.",

        "new-schema-wizard.view.stepper.2.content.name.title" : "Prüfen und Speichern",
        "new-schema-wizard.view.stepper.2.content.name.subtitle" : "Alle notwendigen Daten wurden erfasst. Sie können das Schema nun speichern. Nachdem Sie Speichern geklickt haben, werden Sie zur Detailseite des Schemas weitergeleitet. Dort können Sie weitere Einstellungen treffen."

    }
}
</i18n>