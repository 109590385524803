import api from '@/store/api';
import { get } from 'lodash';
import { set } from 'lodash';
//import cloneDeep from 'lodash/cloneDeep';

export default {

    namespaced: true,

    state: {

        response: defaultResponse()
    },

    mutations: {
        setResponse(state, response) {
            state.response = response ? response : {};
        },

        setName(state, name) {
            set(state, 'response.data.name', name);
        },

        setI18N(state, i18n) {
            set(state, 'response.data.i18n', i18n)
        },

        setDataProtection(state, dataProtection) {
            set(state, 'response.data.dataProtection', dataProtection);
        },

        setLogging(state, logging) {
            set(state, 'response.data.logging', logging);
        }
    },

    actions: {
        async loadData(context) {

            try {
                let response = await api.catalog.tenant.find();
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', {});
            }
            
        },

        updateGeneralSettings(context, generalSettings) {
            context.commit('setName', get(generalSettings, 'name'));
            context.commit('setI18N', get(generalSettings, 'i18n', {}));
        },

        updateDataProtection(context, dataProtection) {
            context.commit('setDataProtection', dataProtection);
        },

        updateLogging(context, logging) {
            context.commit('setLogging', logging);
        },

        async saveData(context) {
            
            try {

                let payload = context.getters.request;
                let response = await api.catalog.tenant.update(payload);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', {});
            }
        }
    },

    getters: {
        
        request(state) {
            
            let request = {
                name: get(state, 'response.data.name', null),
                i18n: get(state, 'response.data.i18n', {}),
                dataProtection: get(state, 'response.data.dataProtection', defaultDataProtection()),
                logging: get(state, 'response.data.logging', defaultLogging()),
                creationDateTime: null,
                creationUser: null,
                modificationDateTime: null,
                modificationUser: null
            };

            return request;
        },

        response(state) {
            return state.response;
        },

        logging(state) {
            return get(state, 'response.data.logging', 'ON');
        }
    },
}

function defaultResponse() {
    return {
        data: {
            name: null,
            i18n: {},
            dataProtection: defaultDataProtection(),
            logging: defaultLogging()
        }
    }
}

function defaultDataProtection() {
    return {
        collectionAccess: 'ON'
    }
}

function defaultLogging() {
    return {
        documentChangeLogging: 'ON', 
        queryLogging: 'ON'
    }
}