<template>
    <v-simple-table >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      {{$t("collection-metrics-table-header-open")}}
                    </th>
                    <th class="text-left">             
                      {{$t("collection-metrics-table-header-timestamp")}}
                    </th>
                    <th class="text-left">
                         {{$t("collection-metrics-table.component.header.documentKey")}}
                    </th>
                    <th class="text-right">
                      {{$t("collection-metrics-table-header-value")}}
                    </th>
                    <th v-for="(value, key) in cardAggregations" :key="key"
                    class="text-right">
                      {{$t("collection-metrics-table-header-difference")  + " " + $t(key)}}
                    </th>
                  </tr> 
                </thead>
                <tbody>
                  <tr class="grey lighten-3 font-weight-bold text-right">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>
                      {{cardAggregations.average | decimal-format($i18n.locale)}}
                    </td>
                    <td>
                      {{cardAggregations.min | decimal-format($i18n.locale)}}
                    </td>
                    <td>
                      {{cardAggregations.max | decimal-format($i18n.locale)}}
                    </td>
                  </tr>
                  <tr
                    v-for="document in documents"
                    :key="document._id"
                  >
                    <td class="text-center">
                      <v-btn icon @click="onOpenClicked(document)"><v-icon small>open_in_new</v-icon></v-btn>
                    
                    </td>
                    <td>{{ documentTimestamp(document) | localized-date-time-format($i18n.locale) }}</td>
                     <td class="text-left" :title="{document, schema} | document-key">{{ {document, schema} | document-key | truncate-middle(48)}}</td>
                    <td class="text-right">{{ documentValue(document) | decimal-format($i18n.locale)}}</td>
                    <td v-for="(value, key) in cardAggregations" :key="key" class="text-right">
                      {{ documentMetricDifference(document, value) | decimal-format($i18n.locale)}}
                    </td>

                  </tr>
                </tbody>
              </template>
            </v-simple-table>  
</template>

<script>
import { get, pick } from "lodash";
export default{
    props: {
    value:{
      type: Object,
      required: true
    }
  },
  computed: {
    aggregations(){
      return get(this.value, "group.metrics", []);
    },
    cardAggregations(){
      let toContain = ["average", "min", "max"];
      let result = pick(this.aggregations, toContain);
      return result;
    },
    documents(){
      return get(this.value, "group.documents", []);
    },
    request(){
      return get(this.value, "request", {});
    },
    schema() {
        return get(this.value, "schema");
    },
  },
  methods: {
    
    documentTimestamp(document){
      return  get(this.documentData(document), this.request.timeDimensionAttribute, {});
    },
    
    documentData(document){
      return get(document, "data", {});
    },
    
    documentValue(document){
      return this.documentData(document)[this.request.measureAttribute];
    },
    
    documentMetricDifference(document, metric){
      let result = metric - this.documentData(document)[this.request.measureAttribute];
      return result *= -1
    },
    
    onOpenClicked(item) {
      this.goToDetailsView({
                collectionSettings: get(this.value, "collectionSettings", {}), 
                schema: this.meta,
                item: item 
            });        
    },
    goToDetailsView({collectionSettings, item}) {
            
      // This method relies on the client settings attached to the given collection.
      const pathDetailView = this.$dxs.collectionSettings.getDetailViewUrl(collectionSettings, item);
           
      // Unfortunatetly there is currently not better way to open new tabs in vue router.
      const route = this.$router.resolve(pathDetailView);
      window.open(route.href);
    },
  }


}
</script>

<i18n>
    {
        "en" : {
            "collection-metrics-table-header-difference": "Difference from",
            "collection-metrics-table-header-timestamp": "Timestamp",
            "collection-metrics-table-header-value": "Value",
            "collection-metrics-table-header-open": "Open",
            "collection-metrics-table.component.header.documentKey" : "Key",
            "count": "Count",
            "min": "Minimum",
            "max": "Maximum",
            "average": "Average",
            "sum": "Sum",
            "sum_of_squares": "Sum of Squares",
            "variance": "Variance",
            "variance_population": "Variance Popoulation",
            "variance_sampling": "Variance Sampling",
            "std_deviation": "Std Deviation",
            "std_deviation_population": "Std Deviation Population",
            "std_deviation_sampling": "Std Deviation Sampling",
            "std_deviation_bounds_upper": "Std Deviation Bounds Upper",
            "std_deviation_bounds_lower": "Std Deviation Bounds Lower",
            "std_deviation_bounds_upper_population": "Std Deviation Bounds Upper Population",
            "std_deviation_bounds_lower_population": "Std Deviation Bounds Lower Population",
            "std_deviation_bounds_upper_sampling": "Std Deviation Bounds Upper Sampling",
            "std_deviation_bounds_lower_sampling": "Std Deviation Bounds Lower Sampling"
        },
        "de" : {
            "collection-metrics-table-header-difference": "Differenz vom",
            "collection-metrics-table-header-timestamp": "Zeitpunkt",
            "collection-metrics-table-header-value": "Wert",
            "collection-metrics-table-header-open": "Öffnen",
            "collection-metrics-table.component.header.documentKey" : "Schlüssel",
            "count": "Anzahl",
            "min": "Minimum",
            "max": "Maximum",
            "average": "Durchschnitt",
            "sum": "Summe",
            "sum_of_squares": "Quadratsumme",
            "variance": "Varianz",
            "variance_population": "Varianzpopoulation",
            "variance_sampling": "Varianz Sampling",
            "std_deviation": "Std Abweichung",
            "std_deviation_population": "Std Abweichungspopulation",
            "std_deviation_sampling": "Std Abweichung Sampling",
            "std_deviation_bounds_upper": "Std Abweichung Obergrenze",
            "std_deviation_bounds_lower": "Std Abweichung Untergrenze",
            "std_deviation_bounds_upper_population": "Std Abweichung Obergrenze Population",
            "std_deviation_bounds_lower_population": "Std Abweichung Untergrenze Population",
            "std_deviation_bounds_upper_sampling": "Std Abweichung Obergrenze Sampling",
            "std_deviation_bounds_lower_sampling": "Std Abweichung Untergrenze Sampling"
        }
    }
</i18n>