<template>
  <v-sheet>
    <template v-if="!collectionAccessItemsEmpty">
        <v-simple-table>
           <thead>
               <tr>
                   <th class="text-left">&nbsp;</th>
                   <th class="text-left">{{$t('access-control-list-permission-table.component.collectionAccess.cols.collectionId')}}</th>
                   <th class="text-center">{{$t('access-control-list-permission-table.component.collectionAccess.cols.read')}}</th>
                   <th class="text-center">{{$t('access-control-list-permission-table.component.collectionAccess.cols.create')}}</th>
                   <th class="text-center">{{$t('access-control-list-permission-table.component.collectionAccess.cols.update')}}</th>
                   <th class="text-center">{{$t('access-control-list-permission-table.component.collectionAccess.cols.delete')}}</th>
                   <th class="text-center">{{$t('access-control-list-permission-table.component.collectionAccess.cols.import')}}</th>
                   <th class="text-center">{{$t('access-control-list-permission-table.component.collectionAccess.cols.export')}}</th>
                   <th class="text-center">{{$t('access-control-list-permission-table.component.collectionAccess.cols.archive')}}</th>
               </tr>
           </thead>
            <tbody>
                <template  v-for="(item, index) of collectionAccessItems"> 
                    <tr :key="'tr_item_' + index">
                        <td>
                            <v-btn icon @click="toggleExpand(item)">
                                <v-icon v-if="!item.expand">expand_more</v-icon>
                                <v-icon v-else>expand_less</v-icon>
                            </v-btn>
                        </td>
                        <td class="text-left">{{item.collectionId}} ({{item.acls.length}})</td>
                        <td class="text-center"><v-icon>{{ getIcon(item.readPermission) }}</v-icon></td>
                        <td class="text-center"><v-icon>{{ getIcon(item.createPermission) }}</v-icon></td>
                        <td class="text-center"><v-icon>{{ getIcon(item.updatePermission) }}</v-icon></td>
                        <td class="text-center"><v-icon>{{ getIcon(item.deletePermission) }}</v-icon></td>
                        <td class="text-center"><v-icon>{{ getIcon(item.importPermission) }}</v-icon></td>
                        <td class="text-center"><v-icon>{{ getIcon(item.exportPermission) }}</v-icon></td>
                        <td class="text-center"><v-icon>{{ getIcon(item.archivePermission) }}</v-icon></td>
                    </tr>
                    <template v-for="acl of item.acls">
                        <tr :key="'tr_item_' + index + '-' + acl.name" class="grey lighten-3" v-if="item.expand">
                            <td>&nbsp;</td>
                            <td class="text-left">{{$dxs.i18n.localizedName(acl, $i18n.locale)}}</td>
                            <td class="text-center"><v-icon>{{ getIcon(acl.rule.readPermission) }}</v-icon></td>
                            <td class="text-center"><v-icon>{{ getIcon(acl.rule.createPermission) }}</v-icon></td>
                            <td class="text-center"><v-icon>{{ getIcon(acl.rule.updatePermission) }}</v-icon></td>
                            <td class="text-center"><v-icon>{{ getIcon(acl.rule.deletePermission) }}</v-icon></td>
                            <td class="text-center"><v-icon>{{ getIcon(acl.rule.importPermission) }}</v-icon></td>
                            <td class="text-center"><v-icon>{{ getIcon(acl.rule.exportPermission) }}</v-icon></td>
                            <td class="text-center"><v-icon>{{ getIcon(acl.rule.archivePermission) }}</v-icon></td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </v-simple-table>
    </template>
    <template v-else>
       <v-card color="grey lighten-3" flat tile>
           <v-card-text>
               {{$t('access-control-list-permission-table.component.noPermissions')}}
           </v-card-text>
       </v-card>
    </template>
  </v-sheet>
</template>

<script>
import { get, isEmpty } from 'lodash';
export default {

    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    data() {

        return {

            collectionAccessItems: this.getCollectionAccessItems(this.items)
        }
    },

    watch: {

        items(newValue) {
            this.collectionAccessItems = this.getCollectionAccessItems(newValue);
        } 
    },

    methods: {

        getIcon(state) {
            return (state) ? 'check_box' : 'check_box_outline_blank'
        },

        toggleExpand(item) {
            item.expand = !item.expand;
           
        },

        getCollectionAccessItems(items) {

            // We need to transform the incoming item props to model objects.

            // First create an array of all attached rules.
            const allRules = [];
            for (const item of items) {
                const rules = get(item, 'collectionAccess.collectionAccessRules', []);
                for (const rule of rules) {
                    allRules.push({name: item.name, i18n: item.i18n, rule: rule});
                }
            }

            // Now group all rules on a per-collection basis
            let map = {};
            for (const item of allRules) {
                
                const rule = item.rule;
                const collectionId = rule.collectionId;
                if (!map[collectionId]) {
                    map[collectionId] = { 
                        collectionId: collectionId,
                        createPermission: false,
                        readPermission: false, 
                        updatePermission: false, 
                        deletePermission: false, 
                        importPermission: false,
                        exportPermission: false,
                        archivePermission: false,
                        acls: [],
                        expand: false
                    }
                }

                if (rule.createPermission) map[collectionId].createPermission = true;
                if (rule.readPermission) map[collectionId].readPermission = true;
                if (rule.updatePermission) map[collectionId].updatePermission = true;
                if (rule.deletePermission) map[collectionId].deletePermission = true;
                if (rule.importPermission) map[collectionId].importPermission = true;
                if (rule.exportPermission) map[collectionId].exportPermission = true;
                if (rule.archivePermission) map[collectionId].archivePermission = true;

                map[collectionId].acls.push(item);
            }


            // Finally copy the resulting structure to an array and sort it by collectionId
            let collectionAccessItems = Object.values(map);
            collectionAccessItems.sort((first, second) => first.collectionId.localeCompare(second.collectionId));
            return collectionAccessItems;



        }

    },

    computed: {

        collectionAccessItemsEmpty() {
            return isEmpty(this.collectionAccessItems);
        },
    

        collectionAccessRules() {

            const collectionAccessRules = [];
            for (const item of this.items) {

                const rules = get(item, 'collectionAccess.collectionAccessRules', []);
                
                for (const rule of rules) {
                    collectionAccessRules.push({name: item.name, i18n: item.i18n, rule: rule});
                }
                
            }

            return collectionAccessRules;
        },
/*
        collectionAccessItems() {
            
            let map = {};
            for (const item of this.collectionAccessRules) {

                const rule = item.rule;
                const collectionId = rule.collectionId;
                if (!map[collectionId]) {
                    map[collectionId] = { 
                        collectionId: collectionId,
                        createPermission: false,
                        readPermission: false, 
                        updatePermission: false, 
                        deletePermission: false, 
                        importPermission: false,
                        exportPermission: false,
                        archivePermission: false,
                        acls: []
                    }
                }

                if (rule.createPermission) map[collectionId].createPermission = true;
                if (rule.readPermission) map[collectionId].readPermission = true;
                if (rule.updatePermission) map[collectionId].updatePermission = true;
                if (rule.deletePermission) map[collectionId].deletePermission = true;
                if (rule.importPermission) map[collectionId].importPermission = true;
                if (rule.exportPermission) map[collectionId].exportPermission = true;
                if (rule.archivePermission) map[collectionId].archivePermission = true;

                map[collectionId].acls.push(item);

            }

            let itemList = Object.values(map)

            return itemList;
        }
        */
    }
    
    
}
</script>

<i18n>
{
    "en" : {
        "access-control-list-permission-table.component.collectionAccess.cols.collectionId" : "Collection",
        "access-control-list-permission-table.component.collectionAccess.cols.read" : "Read",
        "access-control-list-permission-table.component.collectionAccess.cols.create" : "Create",
        "access-control-list-permission-table.component.collectionAccess.cols.update" : "Update",
        "access-control-list-permission-table.component.collectionAccess.cols.delete" : "Delete",
        "access-control-list-permission-table.component.collectionAccess.cols.import" : "Import",
        "access-control-list-permission-table.component.collectionAccess.cols.export" : "Export",
        "access-control-list-permission-table.component.collectionAccess.cols.archive" : "Archive",

        "access-control-list-permission-table.component.noPermissions" : "Currently no permissions are assigned."
    },

    "de" : {
        "access-control-list-permission-table.component.collectionAccess.cols.collectionId" : "Datenbereich",
        "access-control-list-permission-table.component.collectionAccess.cols.read" : "Lesen",
        "access-control-list-permission-table.component.collectionAccess.cols.create" : "Anlegen",
        "access-control-list-permission-table.component.collectionAccess.cols.update" : "Ändern",
        "access-control-list-permission-table.component.collectionAccess.cols.delete" : "Löschen",
        "access-control-list-permission-table.component.collectionAccess.cols.import" : "Importieren",
        "access-control-list-permission-table.component.collectionAccess.cols.export" : "Exportieren",
        "access-control-list-permission-table.component.collectionAccess.cols.archive" : "Archivieren",

        "access-control-list-permission-table.component.noPermissions" : "Momentan sind keine Berechtigungen zugewiesen."
    }
}
</i18n>