
//import Colors from 'vuetify/lib/util/colors';

// Please Note: The theme will be merged with the actual vuetify configuration in the \src\plugins\vuetify.js module

export default {
 
    
/*    themes: {
        light: {
            primary: '#1F2837',
            secondary: '#78909c',
            tertiary: '#F0F0F0',
            accent: '#6EC800',

            error: '#F50057',
            warning: '#FDD835',
            info: Colors.blue.base,
            success: Colors.green.base
        },

        dark: {
            primary: '#0E3A5A',
            secondary: '#52BBB5',
            tertiary: '#F0F0F0',
            accent: '#F0AA50',

            error: '#F50057',
            warning: '#FDD835',
            info: Colors.blue.base,
            success: Colors.green.base
        }
    }
 */   
    

 }