<template>
    <v-sheet  color="secondary">

        <c-section :title="$t('access-control-lists-list.view.sections.list.title')" :subtitle="$t('access-control-lists-list.view.sections.list.subtitle')" class="secondary">

            <v-card class="mt-5 mb-5" tile>
                <c-item-list 
                    :title="$t('access-control-lists-list.view.toolbar.title')" 
                    :items="items" 
                    :pagination="pagination"
                    :disable-add-item="!hasCreateRole" 
                    :disable-edit-item="!hasEditRole && !hasReadRole" 
                    :disable-delete-item="!hasDeleteRole"
                    @refresh-list="refresh"
                    @previous-page="onPreviousPage"
                    @next-page="onNextPage"
                    @item-added="onItemAdded" 
                    @item-edited="onItemEdited"
                    @item-deleted="onItemDeleted"
                    @item-list-filter-applied="onFilterChanged"
                >
                    <template v-slot:item-list-table-head-columns>
                        <th>{{$t('access-control-lists-list.view.columns.collectionRuleCount')}}</th>
                        <th>{{$t('access-control-lists-list.view.columns.collectionRules')}}</th>  
                    </template>

                    <template v-slot:item-list-table-body-columns="{item}">
                        <td>{{getCollectionAccessRules(item).length}}</td>
                        <td :title="getCollectionIdList(item)">{{$dxs.formatting.truncateMiddle(getCollectionIdList(item), 32)}}</td>
                        
                    </template>
                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('access-control-lists-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('access-control-lists-list.view.help.description')}}</v-card-text>
            </v-card>
        </c-section>
        
    </v-sheet>


</template>

<script>
import Roles from '@/roles';
import AccessControlListsListModule from '@/core/store/modules/user-mgmt/access-control/access-control-lists-list.module';

import { get, isEmpty } from 'lodash';
const MODULE_NAME = 'accessControlListsList';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        },

        pagination() {
            return this.response.pagination;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.ACCESS_CONTROL_LISTS_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.ACCESS_CONTROL_LISTS_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.ACCESS_CONTROL_LISTS_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.ACCESS_CONTROL_LISTS_DELETE);
        }
    },

    methods: {

        getCollectionAccessRules(item) {
            return get(item, 'collectionAccess.collectionAccessRules', []);
        },

        getCollectionIdList(item) {
            const collectionIds = this.getCollectionAccessRules(item).map(e => e.collectionId);
            return isEmpty(collectionIds) ? '-' : collectionIds.join(', ');
        },

        async refresh() {
            this.loadPage(0, 50);
        },

        async onNextPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse", {from, pageSize});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemAdded() {
            this.$router.push({
                name: 'accessControlListsWizard',
            });
        },

        onItemEdited(event) {

            if (event) {
                this.$router.push({
                    name: 'accessControlListsEditor',
                    params: {
                        name : event.item.name,
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to editor.")
            }
        },

        async onItemDeleted({ item }) {
            this.progressIndicatorService.show();
            try {

                // Delete the item, then reload the list in order to reflect change.
                await this.$store.dispatch(MODULE_NAME + "/deleteItem", item);
                this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

    },

    created() {

        // Dynamic VUEX Module registration
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, AccessControlListsListModule);
        }
        
        // Now load data from the server.
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {

        "access-control-lists-list.view.sections.list.title" : "Access Control Lists",
        "access-control-lists-list.view.sections.list.subtitle" : "You may use this dialog for creating, editing or deleting access control lists.",
        "access-control-lists-list.view.toolbar.title" : "Access Control Lists",

        "access-control-lists-list.view.columns.collectionRules" : "Collections",
        "access-control-lists-list.view.columns.collectionRuleCount" : "# Collections",

        "access-control-lists-list.view.help.title" : "What are Access Control Lists?",
        "access-control-lists-list.view.help.description" : "Access Control Lists (ACLs) are used for defining who (users or user groups) are granted access to dedicated parts of the system (e.g. collections)."

    },

    "de" : {

        "access-control-lists-list.view.sections.list.title" : "Zugriffsberechtigungen",
        "access-control-lists-list.view.sections.list.subtitle" : "Sie können neue Zugriffsberechtigungen anlegen, oder vorhandene Zugriffsberechtigungen editieren oder löschen.",
        "access-control-lists-list.view.toolbar.title" : "Zugriffsberechtigungen",

        "access-control-lists-list.view.columns.collectionRules" : "Datenbereiche",
        "access-control-lists-list.view.columns.collectionRuleCount" : "Anz. Datenb.",

        "access-control-lists-list.view.help.title" : "Was sind Zugriffsberechtigungen?",
        "access-control-lists-list.view.help.description" : "Zugriffsberechtigungen enthalten Berechtigungslisten für den Zugriff auf bestimmte Bereiche des Systems (insb. Datenbereiche). Durch die Zuweisung einer Zugriffsberechtigung wird Anwendern oder Anwendergruppen der Zugriff auf diese Teile des Systems gewährt."
    }
}
</i18n>