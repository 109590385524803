<template>
  <v-container fluid class="secondary pt-10 pb-10">
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <c-base-dialog
          :value="site"
          :title="$t('sites-wizard.view.title')"
          :subtitle="$t('sites-wizard.view.subtitle')"
          color="secondary"
          @dialog-saved="save"
          @dialog-canceled="cancel"
        >
          <template v-slot:default="props">
            <v-card flat>
              <v-card-text>
                <c-name-input v-model="props.item.name" filled />
              </v-card-text>
            </v-card>
          </template>
        </c-base-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SitesWizardModule from "../../../store/masterdata/sites/sites-wizard.module";

const MODULE_NAME = "sitesWizard";

export default {
  name: "sites-wizard",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "envContextService",
  ],

  methods: {
    async save() {
      this.progressIndicatorService.show();

      try {
        await this.$store.dispatch(MODULE_NAME + "/createSite");

        this.$router.push({
          name: "SiteEditorView",
          params: { name: this.site.name },
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    cancel() {
      this.$router.push({
        name: "SitesListView",
      });
    },

    setTenantId(tenantId) {
      this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
    },

    reset() {
      this.$store.dispatch(MODULE_NAME + "/resetSite");
      this.setTenantId(this.tenantId);
    },
  },

  computed: {
    site: {
      get() {
        return this.$store.getters[MODULE_NAME + "/site"];
      },

      set(item) {
        this.$store
          .dispatch(MODULE_NAME + "/updateSite", item)
          .catch(this.errorHandlerService.handleError);
      },
    },
    
    tenantId() {
        return this.envContextService.tenantId;
    }

  },

  created() {
    if (!this.$store.hasModule(MODULE_NAME)) {
      this.$store.registerModule(MODULE_NAME, SitesWizardModule);
    }

    this.reset();
  },
};
</script>

<i18n>
{
    "en" : {
        "sites-wizard.view.title" : "Create New Site",
        "sites-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new site."
    },

    "de" : {
        "sites-wizard.view.title" : "Neuen Produktionsstandort anlegen",
        "sites-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Produktionsstandorts."
    }
}
</i18n>


