import api from '@/store/api';
import findIndex from 'lodash/findIndex';

export default {

    namespaced: true,

    state: {

        prediction: {
            collectionSettings: {},
            schema: {},
            models: [],
            scenario: {}
        },

        configuration: {
            modelId: 'Test',
            features: [],
            testTrainingSplitRatio: 25,
            crossValidation: true,
            handleOutliers: true
        }
       
    },

    mutations: {

        setPrediction(state, prediction) {

            if (prediction) {
                state.prediction = prediction;
            }
        },

        setModelId(state, modelId){
            if (modelId) {
                state.configuration.modelId = modelId;
            }
        },

        setTestTrainingSplitRatio(state, testTrainingSplitRatio){
            if (testTrainingSplitRatio) {
                state.configuration.testTrainingSplitRatio = testTrainingSplitRatio;
            }
        },

        setCrossValidation(state, crossValidation) {    
            state.configuration.crossValidation = crossValidation;
            
        },

        setHandleOutliers(state, handleOutliers) {  
            state.configuration.handleOutliers = handleOutliers;
            
        },

        addFeature(state, feature) {
            let index = findIndex(state.configuration.features, f => f.name === feature.name);
            
            // If the index is greater or equal zero, we need to update the feature.
            // Otherwise simply add to the end.
            if (index >= 0) {
                state.configuration.features[index] = feature;
            } else {
                state.configuration.features.push(feature);
            }
        },

        removeFeature(state, feature) {
            
            let index = findIndex(state.configuration.features, f => f.name === feature.name);
            if (index >= 0) {
                state.configuration.features.splice(index, 1);
            } 
        },

    },

    actions: {

        async loadPrediction(context, { scenarioId }) {

            try {
                let scenario = await api.prediction.findPredictionScenarioById(scenarioId);
                context.commit('setPrediction', scenario);
            } catch (error) {

                console.log("An error has occured while fetching the prediction with id " + scenarioId);

                // Pass an empty response so nothing will be added actually.
                context.commit('setPrediction', {
                    predictionId : scenarioId
                });
            }
        },

        async saveModel(context) {

            let request = context.getters['request'];
            let scenarioId = context.getters['prediction'] ? context.getters['prediction'].scenario.name : '';
            let modelId    = request  ? request.modelId : '';
            

            // The configuration must be persisted in the backend. This will 
            // also trigger the actual job. 
            api.prediction.models.createModel(scenarioId, modelId, request);
        },

        updateModelId(context, { modelId }) {
            context.commit('setModelId', modelId);
        },

        updateTestTrainingSplitRatio(context, { testTrainingSplitRatio }) {
            context.commit('setTestTrainingSplitRatio', testTrainingSplitRatio);
        },

        updateCrossValidation(context, { crossValidation }) {
            context.commit('setCrossValidation', crossValidation);
        },

        updateHandleOutliers(context, { handleOutliers }) {
            context.commit('setHandleOutliers', handleOutliers);
        },

        addFeature(context, { feature }) {
            context.commit('addFeature', feature);
        },

        removeFeature(context, { feature }) {
            context.commit('removeFeature', feature);
        }


    },

    getters: {

        prediction(state) {
            return state.prediction
        },

        modelId(state) {
            return state.configuration.modelId;
        },

        modelConfiguration(state) {
            return state.configuration
        },

        request(state, getters) {
            return {
                modelId : getters.modelId,

                attributes: state.configuration.features,
                outlierDetection: state.configuration.handleOutliers,
                trainingRatioPercentage: state.configuration.testTrainingSplitRatio,
                crossValidation: state.configuration.crossValidation,
  
            };
        }
    }
}