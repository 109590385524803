<template>
  <v-card tile class="grey lighten-3">
      <v-divider />
      <v-subheader>DXS-72401</v-subheader>
      <v-container fluid>
          <v-row>
              <v-col cols="12">
                  <v-card tile>
                    <v-card-text>
                        <div class="title">Trendverlauf Temperatur °C</div>
                    </v-card-text>
                    <c-data-series-chart :value="series" :options="chartOptions" />
                    <v-divider />
                    <v-container fluid>
                        <v-row>
                            <v-col>
                                <div class="subtitle-1 text-center">Durchschnitt</div>
                                <div class="display-1 text-center">45,87</div>
                            </v-col>
                             <v-col>
                                <div class="subtitle-1 text-center">Minimum</div>
                                <div class="display-1 text-center">45,87</div>
                            </v-col>
                            <v-col>
                                <div class="subtitle-1 text-center">Maximum</div>
                                <div class="display-1 text-center">45,87</div>
                            </v-col>
                        </v-row>
                    </v-container>

                  </v-card>
              </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="3" v-for="i of count" :key="'tile_' + i">
                <v-card tile>
                    <v-card-text>
                        <div class="title">Temperatur °C</div>
                        <div class="subtitle-1">27.11.2015 11:47:35</div>
                    </v-card-text>
                    <v-card-text>
                        <div class="display-1 text-right">45,87</div>
                        <div class="body-2 text-right">Präziser Wert:</div>
                        <div class="body-2 text-right">45,8624902</div>
                    </v-card-text>
                    <v-divider />
                    <v-card-action>
                        <v-btn text block >Dokument Öffnen</v-btn>
                    </v-card-action>
                </v-card>
            </v-col>
            
          </v-row>
      </v-container>

      <v-divider />
      <v-subheader>DXS-72406</v-subheader>
     
  </v-card>
</template>

<script>
export default {

computed: {
    count() {
        return [1, 2, 3, 4, 5, 6, 7];
    },

    chartOptions() {
        return {
            chartHeight: 320,
            showXAxisFilter: false

        }
    },

    series() {
        return {
              "data": {
    "series": [
      {
        "measure": {
          "customData": {
            "chartType": "LINE_CHART_SMOOTH"
          },
          "attribute": "waterTemperatureCelsius",
          "operation": ""
        },
        "color": "#00D2F5",
        "items": [
          {
            "roundedValue": 3.13,
            "attributes": [
              "2020-05-25 17:00"
            ],
            "label": "2020-05-25 17:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 3.13,
            "attributes": [
              "2020-05-25 18:00"
            ],
            "label": "2020-05-25 18:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 3.13,
            "attributes": [
              "2020-05-25 19:00"
            ],
            "label": "2020-05-25 19:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 3.13,
            "attributes": [
              "2020-05-25 20:00"
            ],
            "label": "2020-05-25 20:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 3.13,
            "attributes": [
              "2020-05-25 21:00"
            ],
            "label": "2020-05-25 21:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 3.13,
            "attributes": [
              "2020-05-25 22:00"
            ],
            "label": "2020-05-25 22:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 5.59,
            "attributes": [
              "2020-05-26 08:00"
            ],
            "label": "2020-05-26 08:00",
            "value": 5.594318948165884
          },
          {
            "roundedValue": 5.59,
            "attributes": [
              "2020-05-26 09:00"
            ],
            "label": "2020-05-26 09:00",
            "value": 5.594318948165884
          },
          {
            "roundedValue": 2.5,
            "attributes": [
              "2020-05-26 12:00"
            ],
            "label": "2020-05-26 12:00",
            "value": 2.5022011515173515
          },
          {
            "roundedValue": 2.5,
            "attributes": [
              "2020-05-26 14:00"
            ],
            "label": "2020-05-26 14:00",
            "value": 2.5022011515173515
          },
          {
            "roundedValue": 3.95,
            "attributes": [
              "2020-05-27 06:00"
            ],
            "label": "2020-05-27 06:00",
            "value": 3.946965254327468
          },
          {
            "roundedValue": 3.95,
            "attributes": [
              "2020-05-27 07:00"
            ],
            "label": "2020-05-27 07:00",
            "value": 3.946965254327468
          }
        ]
      },
       {
        "measure": {
          "customData": {
            "chartType": "LINE_CHART"
          },
          "attribute": "waterTemperatureCelsius MAX",
          "operation": ""
        },
        "color": "#00D2E6",
        "items": [
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-25 17:00"
            ],
            "label": "2020-05-25 17:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-25 18:00"
            ],
            "label": "2020-05-25 18:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-25 19:00"
            ],
            "label": "2020-05-25 19:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-25 20:00"
            ],
            "label": "2020-05-25 20:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-25 21:00"
            ],
            "label": "2020-05-25 21:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-25 22:00"
            ],
            "label": "2020-05-25 22:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-26 08:00"
            ],
            "label": "2020-05-26 08:00",
            "value": 5.594318948165884
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-26 09:00"
            ],
            "label": "2020-05-26 09:00",
            "value": 5.594318948165884
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-26 12:00"
            ],
            "label": "2020-05-26 12:00",
            "value": 2.5022011515173515
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-26 14:00"
            ],
            "label": "2020-05-26 14:00",
            "value": 2.5022011515173515
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-27 06:00"
            ],
            "label": "2020-05-27 06:00",
            "value": 3.946965254327468
          },
          {
            "roundedValue": 6.95,
            "attributes": [
              "2020-05-27 07:00"
            ],
            "label": "2020-05-27 07:00",
            "value": 3.946965254327468
          }
        ]
      },
             {
        "measure": {
          "customData": {
            "chartType": "LINE_CHART_SMOOTH"
          },
          "attribute": "waterTemperatureCelsius MAX",
          "operation": ""
        },
        "color": "#f4511e",
        "items": [
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-25 17:00"
            ],
            "label": "2020-05-25 17:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-25 18:00"
            ],
            "label": "2020-05-25 18:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-25 19:00"
            ],
            "label": "2020-05-25 19:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-25 20:00"
            ],
            "label": "2020-05-25 20:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-25 21:00"
            ],
            "label": "2020-05-25 21:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-25 22:00"
            ],
            "label": "2020-05-25 22:00",
            "value": 3.1283684419659807
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-26 08:00"
            ],
            "label": "2020-05-26 08:00",
            "value": 5.594318948165884
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-26 09:00"
            ],
            "label": "2020-05-26 09:00",
            "value": 5.594318948165884
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-26 12:00"
            ],
            "label": "2020-05-26 12:00",
            "value": 2.5022011515173515
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-26 14:00"
            ],
            "label": "2020-05-26 14:00",
            "value": 2.5022011515173515
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-27 06:00"
            ],
            "label": "2020-05-27 06:00",
            "value": 3.946965254327468
          },
          {
            "roundedValue": 2.95,
            "attributes": [
              "2020-05-27 07:00"
            ],
            "label": "2020-05-27 07:00",
            "value": 3.946965254327468
          }
        ]
      }
    ],
    "labels": [
      "2020-05-25 17:00",
      "2020-05-25 18:00",
      "2020-05-25 19:00",
      "2020-05-25 20:00",
      "2020-05-25 21:00",
      "2020-05-25 22:00",
      "2020-05-26 08:00",
      "2020-05-26 09:00",
      "2020-05-26 12:00",
      "2020-05-26 14:00",
      "2020-05-27 06:00",
      "2020-05-27 07:00"
    ]
  },
        }
    }
}

}
</script>

