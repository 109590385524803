<template>
  <div class="secondary darken-1">

    <!-- Bredcrumbs for navigation -->
    <v-breadcrumbs>
      <v-breadcrumbs-item :disabled="true">{{$t("Katalog")}}</v-breadcrumbs-item>
      <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{ name: 'systemCollectionSettingsList' }" :disabled="false">{{ $t("System-Datenbereiche") }}</v-breadcrumbs-item>
      <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
      <v-breadcrumbs-item>{{collectionSettings | localizedName($i18n.locale)}}</v-breadcrumbs-item>
    </v-breadcrumbs>

    <c-sticky-content>
     <v-toolbar color="white" flat>
        <v-toolbar-items
        ><v-btn icon @click="cancel"
            ><v-icon>arrow_back</v-icon></v-btn
        ></v-toolbar-items
        >
        <div class="text-h6">
        {{ $t("catalog.common.collection.label") }}:
        {{ $dxs.i18n.localizedName(this.collectionSettings, $i18n.locale) }}
        
        </div>
        <v-spacer />
        <div>
            <v-chip label color="accent" class="ma-3"><v-icon class="pr-5" color="white">lock</v-icon>{{$t('collection-settings-dashboard.view.button.system')}}</v-chip>
          </div>
     </v-toolbar>
    <v-divider />
    </c-sticky-content>
        <v-container fluid class="grey lighten-3">

            <v-row  v-if="systemCollection" class="justify-center">
                <v-col cols="12" md="10">
                    <v-card color="accent" tile>
                        <v-card-text class="white--text"><v-icon class="pr-5" color="white">lock</v-icon>{{$t('collection-settings-dashboard.view.hint')}}</v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="justify-center">
                <v-col cols="12" md="10">
                    <v-card tile>
                        <v-toolbar flat>
                            <v-toolbar-title>{{$t('collection-settings-dashboard.section.general.title')}}</v-toolbar-title>
                        </v-toolbar>
                        <v-divider />
                        <v-card-text>
                            <v-text-field
                                :value="collectionSettings.name"
                                :disabled="true"
                                filled
                                :label="$t('catalog.common.technicalname.label')"
                                :hint="$t('catalog.common.technicalname.collection.description')"
                                persistent-hint
                                class="ma-5"
                            />
                            <v-text-field
                                :value="collectionSettings.idTemplate"
                                :disabled="true"
                                filled
                                :label="$t('collection-settings-dashboard.section.general.idTemplate.name')"
                                :hint="$t('collection-settings-dashboard.section.general.idTemplate.description')"
                                persistent-hint
                                class="ma-5"
                            />
                            <v-text-field
                                :value="collectionSettings.partitionIdTemplate"
                                :disabled="true"
                                filled
                                :label="$t('collection-settings-dashboard.section.general.partitionIdTemplate.name')"
                                :hint="$t('collection-settings-dashboard.section.general.partitionIdTemplate.description')"
                                persistent-hint
                                class="ma-5"
                            />
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>

            <v-row class="justify-center">
                <v-col cols="12" md="5">
                    <v-card tile>
                    <v-toolbar>
                        <v-toolbar-title>{{$t('collection-settings-dashboard.section.translation.title')}}</v-toolbar-title>
                    </v-toolbar>

                    <c-i18n-editor
                        :items="i18n"
                        :disabled="true"
                        header-style="none"
                        flat
                        
                    />
                    </v-card>
                </v-col>

                <v-col cols="12" md="5">
                    <v-card tile>
                        <v-toolbar>
                            <v-toolbar-title>{{$t('collection-settings-dashboard.section.parameter.title')}}</v-toolbar-title>
                        </v-toolbar>

                        <c-params-editor
                            header-style="none"
                            flat
                            :items="custData"
                            :disabled="true"
                        />
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="justify-center">
                <v-col cols="12" md="10">
                    <v-card tile>
                        <v-toolbar flat>
                            <v-toolbar-title>{{$t('collection-settings-dashboard.section.schema.title')}}</v-toolbar-title>
                        </v-toolbar>
                        <v-divider />
                        <c-schema-table :value="{ schema: schema }" />
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
  </div>
</template>

<script>
import { get } from 'lodash';

import { newInstance as mixin } from '@/core/mixins/collection-settings-data/collection-settings-data.mixin';


const MODULE_NAME = 'collectionSettingsDashboard';

export default {

    mixins: [mixin({moduleId: MODULE_NAME})],

    props: {

        /**
         * Expected structure:
         * 
         * {
         *     name: string
         * }
         */
        value: {

            type: Object,
            required: true
        }
    },

    computed: {

        collectionId() {
            return get(this.value, 'name')
        },

    },

    methods: {

        init() {

            if (this.collectionId) {
                this.refresh();
            }
        },

        async refresh() {
            this.progressIndicatorService.show();
    
            try {
                await this.loadCollectionSettings(this.collectionId);
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.go(-1);
        }
    },

    created() {

        this.init();
    }
}
</script>

<i18n>
{
    "en" : {
        "collection-settings-dashboard.view.button.system" : "System",
        "collection-settings-dashboard.view.hint" : "This collection is a protected system collection and cannot be changed.",

        "collection-settings-dashboard.section.general.title" : "General",
        "collection-settings-dashboard.section.translation.title" : "Translation",
        "collection-settings-dashboard.section.parameter.title" : "Parameter",
        "collection-settings-dashboard.section.schema.title" : "Schema",

        "collection-settings-dashboard.section.general.idTemplate.name" : "ID Template",
        "collection-settings-dashboard.section.general.idTemplate.description" : "ID Template",

        "collection-settings-dashboard.section.general.partitionIdTemplate.name" : "Partition-ID Template",
        "collection-settings-dashboard.section.general.partitionIdTemplate.description" : "Partition-ID Template"
    },

    "de" : {
        "collection-settings-dashboard.view.button.system" : "System",
        "collection-settings-dashboard.view.hint" : "Dieser Datenbereich ist ein geschützter Systemdatenbereich und kann nicht geändert werden.",

        "collection-settings-dashboard.section.general.title" : "Allgemein",
        "collection-settings-dashboard.section.translation.title" : "Übersetzung",
        "collection-settings-dashboard.section.parameter.title" : "Parameter",
        "collection-settings-dashboard.section.schema.title" : "Schema",

        "collection-settings-dashboard.section.general.idTemplate.name" : "ID Template",
        "collection-settings-dashboard.section.general.idTemplate.description" : "ID Template",

        "collection-settings-dashboard.section.general.partitionIdTemplate.name" : "Partition-ID Template",
        "collection-settings-dashboard.section.general.partitionIdTemplate.description" : "Partition-ID Template"

    }
}
</i18n>

<style>

</style>