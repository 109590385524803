import api from '@/store/api';
import { v4 as uuidv4  } from 'uuid';

const COLLECTION_MAPPING_JOB_LOG = "MappingJobLog";

export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(),

        filter: getDefaultFilter(),

        mappingJobInstances: {}
    },

    mutations: {

        setResponse(state, response) {

            if (response) {
                state.response = response;
            }

        },

        setMappingJobInstances(state, mappingJobInstances) {

            if (mappingJobInstances) {
                state.mappingJobInstances = mappingJobInstances;
            }
        },

        setFilter(state, filter) {

            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter();
            }
        }
    },

    actions: {

        async loadData(context) {

            try {
                let response = await api.catalog.mappingJobTemplates.findAll(context.state.filter);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

            try {

                const query = {
                    filter : { 
                        facetFilters:[],
                        filterQuery: '*',
                        filterQueryLanguage: 'SIMPLE',
                        rangeFilters:[]
                    },
                    sort : { 
                        sortCriteria :[ { name :'jobStartDateTime', ordering : 'DESC'}]
                    },
                    pagination : { from :0, searchAfter :[], size :50},
                    queryTimeoutMiliseconds :25000
                }
                const mappingJobInstances = await api.collections.documents.findByQuery(COLLECTION_MAPPING_JOB_LOG, query);
                context.commit('setMappingJobInstances', mappingJobInstances);

            } catch (error) {
                context.commit('setMappingJobInstances', {});
                throw error;
            }

        },

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        },

        async startJob(context, {jobTemplateId}) {
            
            // The unique job id is generated by the client.
            const jobInstanceId = jobTemplateId + '-' + uuidv4();
            await api.dataPreparation.mappingJobInstances.startJob(jobInstanceId, jobTemplateId);

            return jobInstanceId;
            
        }
    },

    getters: {

        model(state) {
            return  {
                mappingJobTemplates: state.response ? state.response : getDefaultResponse(),
                mappingJobInstances: state.mappingJobInstances ? state.mappingJobInstances : { items: [], pagination: { totalItemCount: 0} }
            }
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        }
    }
}

function getDefaultResponse() {

    return {
        items: []
    };
}

function getDefaultFilter() {
    return '*';
}