import { get, isArray } from 'lodash';
import api from '../../../api/battery-data-hub.api';
export default {

    namespaced: true,

    state: {
        materials: defaultObject(),
        batteryModels: defaultObject(),
        manufacturer: defaultObject(),
        batteryMaterialMatrix: defaultObject(),

        batteryModelsFilterItems: [],
        manufacturerFilterItems: [],
        materialsFilterItems: [],


        filter: {
            batteryModelsFilterItems: [],
            manufacturerFilterItems: [],
            materialsFilterItems: [],
        },

    },
    mutations: {

        setPagination(state, { listName, response }) {

            if (response && listName && state[listName]) {
                state[listName].pagination = response.pagination ? response.pagination : defaultObject().pagination;
            }
        },

        appendItems(state, { listName, response }) {
            if (response && listName && state[listName]) {
                const responseItems = get(response, 'items', []);
                responseItems.forEach(item => {
                    state[listName].items.push(item);
                });
            }

        },
        setAllLists(state) {
            state.materials = defaultObject();
            state.batteryModels = defaultObject();
            state.batteryMaterialMatrix = defaultObject();
            state.batteryModelsFilterItems = [];
            state.manufacturerFilterItems = [];
            state.materialsFilterItems = [];

        },

        setBatteryModelsFilterItems(state, batteryModelsFilterItems) {
            if (isArray(batteryModelsFilterItems)) {
                state.filter.batteryModelsFilterItems = batteryModelsFilterItems;
            } else {
                state.filter.batteryModelsFilterItems = [];
            }

        },

        setManufacturerFilterItems(state, manufacturerFilterItems) {
            if (isArray(manufacturerFilterItems)) {
                state.filter.manufacturerFilterItems = manufacturerFilterItems;
            } else {
                state.filter.manufacturerFilterItems = [];
            }
        },

        setMaterialsFilterItems(state, materialsFilterItems) {
            if (isArray(materialsFilterItems)) {
                state.filter.materialsFilterItems = materialsFilterItems;
            } else {
                state.filter.materialsFilterItems = [];
            }
        },
    },


    actions: {
        async loadMaterials(context, { from, pageSize }) {

            try {

                let batteryMaterials = await api.masterdata.batteryMaterials.findByFilter('*', from, pageSize);
                context.commit('setPagination', { listName: 'materials', response: batteryMaterials });

                context.commit('appendItems', { listName: 'materials', response: batteryMaterials });
            } catch (error) {
                context.commit('appendItems', { listName: 'materials', response: defaultObject() });

                throw error;
            }

        },
        async loadBatteryModels(context, { from, pageSize }) {

            try {

                let batteryModels = await api.masterdata.batteryModels.findByFilter('*', from, pageSize);
                context.commit('setPagination', { listName: 'batteryModels', response: batteryModels });

                context.commit('appendItems', { listName: 'batteryModels', response: batteryModels });
            } catch (error) {
                context.commit('appendItems', { items: [] });

                throw error;
            }

        },

        async loadManufacturer(context, { from, pageSize }) {
            try {
                const manufacturer = await api.masterdata.manufacturers.findByFilter('*', from, pageSize);
                context.commit('setPagination', { listName: 'manufacturer', response: manufacturer });
                context.commit('appendItems', { listName: 'manufacturer', response: manufacturer });
            } catch (error) {
                context.commit('appendItems', { items: [] });

                throw error;
            }

        },

        async loadBatteryMaterialMatrixData(context, { from, pageSize }) {
            const filter = get(context, 'getters.filter');

            try {
                const batteryMaterialMatrix = await api.masterdata.batteryModels.getBatteryMaterialMatrixByFilter(filter, from, pageSize);

                console.log(JSON.stringify(batteryMaterialMatrix, null, 2));
                context.commit('setPagination', { listName: 'batteryMaterialMatrix', response: batteryMaterialMatrix });
                context.commit('appendItems', { listName: 'batteryMaterialMatrix', response: batteryMaterialMatrix });
            }
            catch (error) {
                context.commit('appendItems', { items: [] });
            }

        },

        async resetAllLists(context) {
            context.commit('setAllLists');
        },
        async updateBatteryModelsFilterItems(context, batteryModelsFilterItems) {
            context.commit('setBatteryModelsFilterItems', batteryModelsFilterItems);
        },
        async updateManufacturerFilterItems(context, manudacturerFilterItems) {
            context.commit('setManufacturerFilterItems', manudacturerFilterItems);
        },
        async updateMaterialsFilterItems(context, materialsFilterItems) {
            context.commit('setMaterialsFilterItems', materialsFilterItems);
        },

    },

    getters: {

        batteryMaterialMatrixData(state) {
            return get(state, 'batteryMaterialMatrix');
        },


        materials(state) {
            return get(state, 'materials');
        },

        batteryModels(state) {
            return get(state, 'batteryModels');
        },


        batteryModelsFilterItems(state) {
            const batterymodels = get(state, 'batteryModels.items');

            if (batterymodels) {
                batterymodels.forEach(item => {

                    const exists = state.batteryModelsFilterItems.some(existingItem =>
                        existingItem.name === item.name
                    );

                    if (!exists) {
                        state.batteryModelsFilterItems.push({ name: item.name });
                    }

                });
            }

            return get(state, 'batteryModelsFilterItems');
        },

        manufacturerFilterItems(state) {
            const manufacturer = get(state, 'manufacturer.items', []);

            if (manufacturer) {
                manufacturer.forEach(item => {

                    const exists = state.manufacturerFilterItems.some(existingItem =>
                        existingItem.name === item.name
                    );

                    if (!exists) {
                        state.manufacturerFilterItems.push({ name: item.name });
                    }
                });
            }
            return get(state, 'manufacturerFilterItems');
        },

        materialsFilterItems(state) {
            const materials = get(state, 'materials.items', []);

            if (materials) {
                materials.forEach(item => {
                    state.materialsFilterItems.push({ name: item.name });
                });
            }

            return state.materialsFilterItems;
        },


        filter(state) {
            return state.filter;
        },

        manufacturer(state) {
            return get(state, 'manufacturer');
        }



    },
}
function defaultObject() {
    return {
        items: [],
        pagination: {
            from: 0,
            pageSize: 0,
            totalItemCount: 0
        }
    }
}