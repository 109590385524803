import { get } from 'lodash';

import CollectionSettingsDataModule from './collection-settings-data.module';

export function newInstance({moduleId}) {

    return  {
    
        inject: ['progressIndicatorService', 'envContextService'],
    

        // ==================================================================
        // METHODS
        // ==================================================================
        methods : {
            
            async loadCollectionSettings(name) {
                // Delegate call to vuex store.
                await this.$store.dispatch(moduleId + '/loadData', {name});
            },
        },
    
        
        // ==================================================================
        // COMPUTED
        // ==================================================================
        computed: {

            collectionSettingsDataModel() {
                let model = this.$store.getters[moduleId + '/model'];
                return model;
            },

            collectionSettings() {
                return get(this.collectionSettingsDataModel, 'collectionSettings', {});
            },
    
            i18n() {
                return get(this.collectionSettings, 'i18n', {})
            },
    
            custData() {
                return get(this.collectionSettings, 'cust', {});
            },
    
            systemCollection() {
                return true;
            },
    
            schema() {
                return get(this.collectionSettingsDataModel, 'schema', {});
            },

            schemaAttributes() {
                return get(this.schema, 'attributes', {});
            },

            schemaAttributeList() {

                const list = [];
                for (const name in this.schemaAttributes) {
                    list.push(this.schemaAttributes[name]);
                }

                return list;
            }
        },

        // ==================================================================
        // HOOKS
        // ==================================================================
        created() {
            // Dynamically register the vuex store module for managing the data.
            if (!this.$store.hasModule(moduleId)){
                this.$store.registerModule(moduleId, CollectionSettingsDataModule);
            }
        }
    };
} 

