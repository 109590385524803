import Configuration from "@/utils/configuration";

import { axios } from "@/store/axios";

const BACKEND_HOST = Configuration.value("BACKEND_HOST");
const BACKEND_PORT = Configuration.value("BACKEND_PORT");
const TENANT_ID = Configuration.value("TENANT_ID");
const API_VERSION = "v2";
const API_VERSION_V1 = "v1";
const BACKENDROOT = BACKEND_HOST + (BACKEND_PORT ? ":" + BACKEND_PORT : "");
const BACKEND = BACKENDROOT + "/" + API_VERSION + "/" + TENANT_ID;
const BACKEND_V1 = BACKENDROOT + "/" + API_VERSION_V1 + "/" + TENANT_ID;

const TIMEOUT = 6000;
const TIMEOUT_LONG_RUNNING = TIMEOUT * 10;

export default {
    
    catalog: {
        /**
         * The endpoint for managing collection settings.
         * @since DXS 3.4.0
         */
        collectionSettings: {
            findAll: (filter, from, pageSize) => {
                let url = BACKEND + "/catalog/collectionSettings";

                return axios
                    .get(url, {
                        timeout: TIMEOUT,
                        params: { filter: filter, from: from, pageSize: pageSize },
                    })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            findById(collectionId) {
                let url = BACKEND_V1 + "/catalog/collectionSettings/" + collectionId;

                return axios
                    .get(url, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },
        },

        /**
         * The endpoint for managing schema settings.
         * @since DXS 3.4.0
         */
        schemas: {
            findAll: (filter, from, pageSize) => {
                let url = BACKEND + "/catalog/schemas";

                return axios
                    .get(url, {
                        timeout: TIMEOUT,
                        params: { filter: filter, from: from, pageSize: pageSize },
                    })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            findById(schemaId) {
                let url = BACKEND_V1 + "/catalog/schemas/" + schemaId;

                return axios
                    .get(url, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },
        },

        /**
         * The endpoint for managing detection job templates.
         * @since DXS 3.4.0
         */
        detectionJobTemplates: {
            findByName: (name) => {
                let url = BACKEND + "/catalog/detectionJobTemplates/" + name;

                return axios
                    .get(url, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            findAll: (filter, from, pageSize) => {
                let url = BACKEND + "/catalog/detectionJobTemplates";

                return axios
                    .get(url, {
                        timeout: TIMEOUT,
                        params: { filter: filter, from: from, pageSize: pageSize },
                    })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            create(name, request) {
                let url = BACKEND + "/catalog/detectionJobTemplates/" + name;
                const requestConfig = {
                    timeout: TIMEOUT,
                };

                return axios
                    .post(url, request, requestConfig)
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update(name, request) {
                let url = BACKEND + "/catalog/detectionJobTemplates/" + name;

                const requestConfig = {
                    timeout: TIMEOUT,
                };

                return axios
                    .put(url, request, requestConfig)
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            deleteById: (name) => {
                let url = BACKEND + "/catalog/detectionJobTemplates/" + name;

                return axios
                    .delete(url, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            createLogCollection(name, template) {
                let url =
                    BACKEND +
                    "/catalog/detectionJobTemplates/" +
                    name +
                    "/actions/create-log-collection";

                const requestConfig = {
                    timeout: TIMEOUT,
                };

                return axios
                    .post(url, template, requestConfig)
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },
        },

        /**
         * The v2 endpoint for managing system parameters.
         * @since DXS 3.4.0
         */
        systemParameters: {
            findAll(filter, from, pageSize) {
                let url = BACKEND_V1 + "/catalog/systemParameters";

                return axios
                    .get(url, {
                        timeout: TIMEOUT,
                        params: { filter: filter, from: from, pageSize: pageSize },
                    })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },
        },
    },

    pipelines: {

        findAll(filter = "*", from = 0, pageSize = 50) {

            let url = BACKEND + '/pipelines';

            return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },

        uploadJson(collectionId, mesg) {
            let url = BACKEND + `/pipelines/${collectionId}`;

            const messageToSend = JSON.stringify(mesg);
            return axios
                .post(url, JSON.parse(messageToSend))
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
    },

    collections: {

        documents: {

            // Creates a single new document. 
            create(collectionId, { data, i18n }) {

                let url = `${BACKEND_V1}/collections/${collectionId}/documents`;

                return axios
                    .post(url, { data, i18n }, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },
        }
    },

    upload: {
        uploadFile(collectionId, file, fileName, fileFormat, charset) {
            let url = "";
            if(fileFormat === 'CSV-UTF8' || fileFormat === 'CSV-WINDOWS1252'){
                url = BACKEND + '/fileupload/csv/' + collectionId;
            } else if(fileFormat == 'JSON'){
               url = BACKEND + '/fileupload/json/' + collectionId;
            }
            const requestConfig = {
                timeout: TIMEOUT_LONG_RUNNING * 5,
                params: {
                    charset: charset.charset
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
                
            };
            const formData = new FormData();
            formData.append('file', file);
            formData.append('filename', fileName);
            return axios.post(url, formData, requestConfig)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        }
    },

};
