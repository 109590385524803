import api from '@/store/api';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import forOwn from 'lodash/forOwn';
import { isNil } from 'lodash';
export default {

    namespaced: true,

    state: {

        documents: {

        },
        schema: {

        },

        items: [],

        meta: {},

        collectionSettings: {},

        query: {

            filter: {

                filterQuery: '*',

                filterQueryLanguage: 'SIMPLE',

                facetFilters: [],

                rangeFilters: []
            },

            sort: {
                sortCriteria: []
            },

            pagination: {
                from: 0,
                size: 50,
                searchAfter: []
            },
        },

        viewSettings: {

            attributes: []
        },

        pagination: {
            from: 0,
            size: 50,
            searchAfter: []
        },

        facets: {},

        links: {},

        
    },

    mutations: {

        setDocuments(state, documents) {

            if (documents) {

                state.documents = documents;
            
            } else {
                state.documents = []
            }
        },
        setSchema(state, schema) {
            if (schema) {

                state.schema = schema;
            
            } else {
                state.schema = {}
            }
        },
        setResponse(state, response) {


            state.items = response.items ? response.items : [];
            state.meta  = response.meta ? response.meta : {};
            state.collectionSettings = response.collectionSettings ? response.collectionSettings : { name: null};
            
            state.facets     = response.facets ? response.facets : {};
            state.links      = response.links ? response.links : {};
            state.pagination = response.pagination ? response.pagination : {from: 0, size: 50, searchAfter: []};
            
            state.query.filter     = (response.query && response.query.filter) ? response.query.filter : { filterQuery: '*', filterQueryLanguage: 'SIMPLE', facetFilters: [], rangeFilters: [] };
            state.query.sort       = (response.query && response.query.sort) ? response.query.sort : { sortCriteria: [] },
            state.query.pagination = (response.query && response.query.pagination) ? response.query.pagination : { from: 0, size: 50, searchAfter: [] };

            // DXS-99: Reset the view settings if an empty response has been received.
            // It is necessary to do so in order to support the reusing of components by vue router.
            // Otherwise the view settings will not change when navigating.
            // DXS-327 always reset the viewsettings

            if (isEmpty(response)) { // Always remember: Empty objects are NOT falsy... thus use the lodash is empty method for a safe check.
                state.viewSettings.attributes = [];   
            }

            // If no explicit view settings have been set, 
            // compute them on the fly.
            if (isEmpty(state.viewSettings.attributes)) {
                forOwn(state.meta.attributes, attribute => state.viewSettings.attributes.push({
                    value: attribute,
                    display: !!get(attribute, 'clientSettings.table.display', true)
                }));
                

                state.viewSettings.attributes.sort( (first, second) => first.value.ordering - second.value.ordering);
            }

        },


    },

    actions: {
        async loadDocuments(context, {collectionId, documentId, rangeFilter}) {
            try {
                let query = {
                    filter : {
                        filterQuery: '*',
                        filterQueryLanguage: 'SIMPLE',
                        facetFilters:[{name: "documentId", filterQuery: documentId, values: [], filterMode: 'CONTAINS'},
                                      {name: "collectionId", filterQuery: collectionId, values: [], filterMode: 'CONTAINS'}],
                        rangeFilters:[]
                    },
                    sort : { 
                        sortCriteria :[ { name :'modificationDateTime', ordering : 'DESC'}]
                    },
                    pagination : { from :0, searchAfter :[], size :50},
                    queryTimeoutMiliseconds :25000
                }
                // Make sure that the resulting filter contains actual values
                if (!isNil(rangeFilter.from) && !isNil(rangeFilter.until)) {
                    query.filter.rangeFilters = [rangeFilter];
                }

                const documents = await api.collections.documents.findByQuery("DocumentChangeLog", query);
                context.commit('setDocuments', documents);
                context.commit('setResponse', documents)

            } catch (error) {
                context.commit('setDocuments', {});
                throw error;
            }

        },
        async loadSchema(context, {collectionId}){
            try {
                let collectionSettings = await api.catalog.collectionSettings.findById(collectionId);
                let schemaId = get(collectionSettings, 'collectionSettings.schemaId' , '');
                let schema = await api.catalog.schemas.findById(schemaId);
                context.commit('setSchema', schema);
             } catch (error) {
                 console.log("An error has occured" + error);
                 return Promise.reject(error);
             }
        },
        async restoreDocument(context, {collectionId, documents}){
            try {
                await api.collections.documents.import(collectionId, documents);
             } catch (error) {
                 console.log("An error has occured" + error);
                 return Promise.reject(error);
             }
        },
       

    
    },

    getters: {

        documents(state) {
            return state.documents;
        },
        schema(state) {
            return state.schema;
        },
        response(state) {

            return {
                items              : state.items,
                meta               : state.meta,
                collectionSettings : state.collectionSettings,
                filter             : state.query.filter,
                viewSettings       : state.viewSettings,
                sort               : state.query.sort,
                pagination         : state.pagination,
                facets             : state.facets,
                links              : state.links
            }
        }
    }
}