<template>
  <v-sheet>
      <c-sticky-content>
            <v-toolbar color="secondary darken-1" flat >
                <v-breadcrumbs>
                    <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false">{{$t('query-log-detail.view.breadcrumbs.root')}}</v-breadcrumbs-item>
                    <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                    <v-breadcrumbs-item>{{documentId | truncate-middle(16)}}</v-breadcrumbs-item>                
                </v-breadcrumbs>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon><v-icon @click="refresh">refresh</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
        </c-sticky-content>
      <c-section class="secondary" :title="$t('query-log-detail.view.title', { documentId: documentId })" :subtitle="$t('query-log-detail.view.subtitle')">

      </c-section>
      <v-container fluid class="grey lighten-3">
          
        <v-row justify="center">
            <v-col cols="12" md="6" lg="4">
                <v-card class="mt-5">
                    <v-card-text class="text-h6">
                        {{$t('query-log-detail.view.overview.title')}}
                    </v-card-text>
                    <v-divider />
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td>{{$t('query-log-detail.view.overview.documentId')}}</td>
                                <td>{{documentId}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('query-log-detail.view.overview.collectionId')}}</td>
                                <td>{{collectionId}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('query-log-detail.view.overview.userId')}}</td>
                                <td>{{userId}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('query-log-detail.view.overview.duration')}}</td>
                                <td>{{duration}} ms</td>
                            </tr>
                            <tr>
                                <td>{{$t('query-log-detail.view.overview.queryTimestamp')}}</td>
                                <td>{{queryTimestamp | localized-date-time-format($i18n.locale)}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('query-log-detail.view.overview.hits')}}</td>
                                <td>{{hits | integer-format($i18n.locale)}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="8">
                <v-card class="mt-5">
                    <v-card-text class="text-h6">
                       {{$t('query-log-detail.view.overview.nativeQuery')}}
                    </v-card-text>
                    <v-card-text class="grey lighten-5">
                        <pre>{{nativeQuery}}</pre>
                    </v-card-text>
                </v-card>

                <v-card class="mt-5">
                    <v-card-text class="text-h6">
                       {{$t('query-log-detail.view.overview.query')}}
                    </v-card-text>
                    <v-card-text class="grey lighten-5">
                        <pre>{{query}}</pre>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
      </v-container>
  </v-sheet>
</template>

<script>
import DocumentDetailDataMixin from '@/core/mixins/document-detail-data.mixin';
import { get } from 'lodash';

export default {

    mixins: [DocumentDetailDataMixin],

    methods: {
        
        /**
         * This function attempts to render given fields as JSON. However the function is enabled to 
         * cope with situations when the JSON is fragmentary due to truncation and might fail in pretty printing.
         * 
         * @since 3.3.0 
         */
        prettyPrintJson(input) {

            // Only apply formatting when the input is truthy.
            if (input) {

                try {
                    return JSON.stringify(JSON.parse(input), null, 2);
                } catch (error) {
                    this.$log.warn("The given input could not be formatted as JSON: " + input);
                }
            } 

            return input;   
        },
    },

    computed: {

        queryId() {
            return get(this.data, 'collectionId');
        },

        collectionId() {
            return get(this.data, 'collectionId');
        },

        queryTimestamp() {
            return get(this.data, 'queryTimestamp');
        },

        userId() {
            return get(this.data, 'userId');
        },

        duration() {
            return get(this.data, 'duration');
        },

        query() {
            // DXS-710: Handle situations with incomplete structure
            return this.prettyPrintJson(get(this.data, 'query', '{}'));
        },

        nativeQuery() {
            // DXS-710: Handle situations with incomplete structure
            return this.prettyPrintJson(get(this.data, 'nativeQuery', '{}'));
        },

        hits() {
            return get(this.data, 'hits');
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "query-log-detail.view.breadcrumbs.root" : "Query Log",
        "query-log-detail.view.title" : "Query Log {documentId}",
        "query-log-detail.view.subtitle" : "This dashboard provides a summary on the query.",
        "query-log-detail.view.overview.title": "Overview",
        "query-log-detail.view.overview.documentId": "Query Id",
        "query-log-detail.view.overview.collectionId": "Collection",
        "query-log-detail.view.overview.queryTimestamp": "Query timestamp",
        "query-log-detail.view.overview.userId": "User Id",
        "query-log-detail.view.overview.duration": "Duration",
        "query-log-detail.view.overview.query": "Query",
        "query-log-detail.view.overview.nativeQuery": "Query native",
        "query-log-detail.view.overview.hits": "Number of hits"
    },

    "de" : {
        "query-log-detail.view.breadcrumbs.root" : "Zugriffsprotokoll",
        "query-log-detail.view.title" : "Zugriffsprotokoll {documentId}",
        "query-log-detail.view.subtitle" : "Auf diesem Dashboard finden Sie alle Informationen zur Ausführung der Abfrage.",
        "query-log-detail.view.overview.title": "Allgemein",
        "query-log-detail.view.overview.documentId": "Abfragekennung",
        "query-log-detail.view.overview.collectionId": "Datenbereich",
        "query-log-detail.view.overview.queryTimestamp": "Zeitpunkt",
        "query-log-detail.view.overview.userId": "Benutzerkennung",
        "query-log-detail.view.overview.duration": "Abfragedauer",
        "query-log-detail.view.overview.query": "Abfrage",
        "query-log-detail.view.overview.nativeQuery": "Abfrage nativ",
        "query-log-detail.view.overview.hits": "Anzahl der Treffer"
    }
}
</i18n>