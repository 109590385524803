<template>
<div>
    <div class="message-panel" v-bind:class="{'show': this.show}">
        <div class="message-panel-inner grey lighten-4">
            <div :class="'border-left ' + color"></div>
            <v-container>
                <v-row align="center">
                    <v-col cols="auto">
                        <v-icon medium :color="color">error</v-icon>
                    </v-col>
                    <v-col class="pl-0" cols="auto">
                        <v-row>
                            <v-col cols="12" :class="'pb-0 font-weight-bold '  + color + '--text'">{{ title }}</v-col>
                            <v-col cols="12" class="pt-0 grey--text text--darken-3">{{ text }}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto" class="cursor-pointer">
                        <v-btn icon v-on:click="show = false"><v-icon color="grey">close</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-container>            
        </div>
    </div>
    
    <!-- <v-snackbar v-model="show" :multi-line="true" :color="type" bottom left>
        <v-row align="center">
            <v-col cols="auto">
                <v-icon v-if="type === 'info'" color="white">info</v-icon>
                <v-icon v-if="type === 'error'" color="white">error</v-icon>
            </v-col>

            <v-col v-if="title" class="pl-0">
                <div class="font-weight-medium">{{ title }}</div>
            </v-col>

            <v-col v-if="text" class="pt-0" cols="12">{{ text }}</v-col>
        </v-row>
    </v-snackbar> -->

</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';

const MESSAGE_PANEL_TYPES = [{'info' : 'primary'}, {'warn' : 'accent'}, {'error': 'error'}];
export default {
    props: {
        value: {
            type: Object,
            required: true
        }
    },

    watch: {
        value: {
            handler(val) {
                clearTimeout(this.showDurationTimeout);

                this.show = true;
                this.title = val.title;
                this.text = val.text;
                this.type = val.type || 'info';
            },
            deep: true
        },

        show: {
            handler() {
                this.showDurationTimeout = setTimeout(() => {
                    this.show = false;    
                }, this.duration);
            }
        }
    },

    data: () => ({
        duration: 5000,
        show: false,
        title: 'Title',
        text: 'Example text',
        type: 'info',

        showDurationTimeout: null
    }),

    computed: {
        panelType() {
            const panelType = MESSAGE_PANEL_TYPES.find(type => type[this.type]);
            if (isEmpty(panelType)) {
                throw Error('Invalid type for message-panel: ' + this.type);
            }

            return panelType
        },

        color() {
            return this.panelType[this.type];
        }
    },
};
</script>

<style lang="scss">
.message-panel {
    position: fixed;
    bottom: 0;
    left: -100%;
    padding: 10px;

    -webkit-transition: left 0.5s 0s ease-out;
    -moz-transition: left 0.5s 0s ease-out;
    -o-transition: left 0.5s 0s ease-out;
    transition: left 0.5s 0s ease-out;

    &.show {
        left: 0;
    }

    .message-panel-inner {
        background: rgb(226, 226, 226);
        display: flex;

        .border-left {
            width: 3px;
        }
    }
}
</style>
