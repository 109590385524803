<template>
    <dxs-app-form :title="$t('battery-status-matrix.view.title')" :subtitle="$t('battery-status-matrix.view.subtitle')">
        <template v-slot:actions>
            <v-btn icon><v-icon>refresh</v-icon></v-btn>
        </template>
        
        
        <v-card tile>
            <v-toolbar flat>
                <v-toolbar-title >{{ title }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon><v-icon>refresh</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-container fluid>
                <v-row  no-gutters>
                    <v-col cols="2">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th width="150">Battery Model</th>
                                    <th width="150">Category</th>
                                    <th width="150">Manufacturer</th>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <div ref="tableLeft"  class="table-container-left" @scroll="onScrollLeft">
                            <v-simple-table>
                                <tbody>
                                    <tr v-for="(item, index) of items" :key="`item-tr-left-${index}`">
                                        <td width="150">{{ item.name }}</td>
                                        <td width="150" >World</td>
                                        <td width="150" >World</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                        
                    </v-col>
                    
                    <v-col cols="10">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th width="300">Name</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                    <th width="200">Next</th>
                                </tr>
                            </thead>
                        </v-simple-table>
                        <div ref="tableRight" class="table-container-right" @scroll="onScrollRight">
                            <v-simple-table>
                                <tbody>
                                    <tr v-for="(item, index) of items" :key="`item-tr-left-${index}`">
                                        <td width="300">{{ item.name }}</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                        <td width="200">World</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                    </v-col>
                
                </v-row>
                
                

            </v-container>
        </v-card>
        
    </dxs-app-form>
</template>

<script>
import AppFormComponent from '@/core/components/apps/app-form.component';

export default {

    name: 'battery-status-matrix',

    components: {
        'dxs-app-form' : AppFormComponent
    },

    methods: {
        onScroll(event) {
      console.log('Scroll event:', event);
      // Hier kannst du deine Logik hinzufügen
    },

        onScrollLeft() {

            // Synchronize the scrolling positions
            const tableLeft = this.$refs.tableLeft;
            const tableRight = this.$refs.tableRight;

            tableRight.scrollTop = tableLeft.scrollTop;

        },

        onScrollRight() {
            
            // Synchronize the scrolling positions
            const tableLeft = this.$refs.tableLeft;
            const tableRight = this.$refs.tableRight;

            tableLeft.scrollTop = tableRight.scrollTop;

        },

        
    },

    computed: {

        headers() {
            return [
                {name: 'Name', value: 'name'}
            ]
        },

        items() {

            const items = [];
            for (let i = 0; i < 1000; i++) {
                items.push({name: `Hello ${i}`});
            }

            return items;
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "battery-status-matrix.view.title" : "Battery Status Matrix",
        "battery-status-matrix.view.subtitle" : "This dialog shows an overview of the current battery status per model."
    }
}
</i18n>

<style>
.table-container-left {
  max-height: 70vh; 
  overflow-y: hidden;
}

.table-container-right {
  max-height: 70vh; 
  overflow-y: auto;
  overflow-x: auto;
}

</style>