<template>
  <v-card tile flat>
      <v-card-text>
          <div class="text-h5">{{$t('data-protection-config-page.component.title')}}</div>
          <div>{{$t('data-protection-config-page.component.subtitle')}}</div>
      </v-card-text>
      <v-divider />

      <!-- COLLECTION ACCESS -->
      <v-card-text>
        <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('data-protection-config-page.component.sections.collectionAccess.title')}}</div>
        <div>{{$t('data-protection-config-page.component.sections.collectionAccess.subtitle')}}</div>
    </v-card-text>
    <v-card-text v-if="collectionAccess === 'OFF'">
    <dxs-warning-message >
        {{$t('data-protection-config-page.component.sections.collectionAccess.OFF.warning')}}
    </dxs-warning-message>
    </v-card-text>
    <v-list>
        <v-radio-group mandatory v-model="collectionAccess" :disabled="disabled">
        <v-list-item three-line> 
            
            <v-list-item-action>
                <v-radio key="ON" value="ON" />
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{$t('data-protection-config-page.component.sections.collectionAccess.ON.title')}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('data-protection-config-page.component.sections.collectionAccess.ON.subtitle')}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-list-item three-line>
            <v-list-item-action>
                <v-radio key="OFF" value="OFF" />
            </v-list-item-action>
            <v-list-item-content>
                 <v-list-item-title>{{$t('data-protection-config-page.component.sections.collectionAccess.OFF.title')}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('data-protection-config-page.component.sections.collectionAccess.OFF.subtitle')}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        </v-radio-group>
    </v-list>

  </v-card>
</template>

<script>
import WarningMessageComponent from '@/components/messages/warning-message.component';
import { get, set, cloneDeep } from 'lodash';

export default {

    name: 'data-protection-config-page',

     components: {
        'dxs-warning-message' : WarningMessageComponent
    }, 

    props: {

        value: {
            type: Object,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {

        return {
            model : cloneDeep(this.value)
        }
    },

    methods: {

        fireInput() {
            this.$emit('input', cloneDeep(this.model));
        }

    },

    computed: {
        collectionAccess: {

            get() {
                return get(this.model, 'collectionAccess', 'ON')
            },

            set(value) {
                set(this.model, 'collectionAccess', value)
                this.fireInput();
            }
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "data-protection-config-page.component.title" : "Data Protection",
        "data-protection-config-page.component.subtitle" : "This page contains configuration for securing and protecting your data.",
        "data-protection-config-page.component.sections.collectionAccess.title" : "Access Control for Collections",
        "data-protection-config-page.component.sections.collectionAccess.subtitle" : "With the access control for collections, you can selectively grant access to collections for users and user groups.",
        "data-protection-config-page.component.sections.collectionAccess.ON.title" : "Access Control On (Recommended)",
        "data-protection-config-page.component.sections.collectionAccess.ON.subtitle" : "Authenticated users are only granted access to collections for which they have been explicitly authorized. Access to other collections is denied.",
        "data-protection-config-page.component.sections.collectionAccess.OFF.title" : "Access Control Off (Not Recommended)",
        "data-protection-config-page.component.sections.collectionAccess.OFF.subtitle" : "Authenticated users are granted unrestricted access to all collections.",
        "data-protection-config-page.component.sections.collectionAccess.OFF.warning" : "The access control for collection is switched off. All authenticated users thus have access to all collection in this tenant. This setting provides only minimal protection for the data and should only be used in justified exceptional cases."

    },

    "de" : {
        "data-protection-config-page.component.title" : "Datenschutz",
        "data-protection-config-page.component.subtitle" : "Diese Seite enthält Einstellungen zur Absicherung und zum Schutz Ihrer Daten.",
        "data-protection-config-page.component.sections.collectionAccess.title" : "Zugriffsteuerung Datenbereiche",
        "data-protection-config-page.component.sections.collectionAccess.subtitle" : "Mit der Zugriffsteuerung für Datenbereiche können Sie ganze Datenbereiche selektiv für Anwender und Anwendergruppen freigeben.",
        "data-protection-config-page.component.sections.collectionAccess.ON.title" : "Zugriffssteuerung einschalten (Empfohlen)",
        "data-protection-config-page.component.sections.collectionAccess.ON.subtitle" : "Authentifizierte Anwender erhalten nur Zugriff auf Datenbereiche für die sie explizit berechtigt wurden. Der Zugriff auf andere Datenbereiche wird verwehrt.",
        "data-protection-config-page.component.sections.collectionAccess.OFF.title" : "Zugriffssteuerung ausschalten (Nicht empfohlen)",
        "data-protection-config-page.component.sections.collectionAccess.OFF.subtitle" : "Authentifizierte Anwender erhalten uneingeschränkten Zugriff auf alle Datenbereiche.",
        "data-protection-config-page.component.sections.collectionAccess.OFF.warning" : "Die Zugriffssteuerung für Datenbereiche ist ausgeschaltet. Alle authentifizierten Anwender erhalten somit Zugriff auf alle Datenbereiche in diesem Mandanten. Diese Einstellung schützt den Zugriff auf die Daten nur minimal und sollte nur in begründeten Ausnahmefällen genutzt werden."
    }

    
}
</i18n>