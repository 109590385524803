import { DateTime, Duration } from 'luxon';
import { get, isArray, isEmpty } from 'lodash';

import api from '@/store/api';
import smartFactoryApi from '@/solutions/smart-factory-services/api/smart-factory.api';

const PAGE_SIZE = 25;

export default {

    namespaced: true,

    state: {
        

        filter: {
            siteId: null,
            dateTimeFrom: DateTime.now().minus(Duration.fromMillis(1000 * 3600)).toISO(),
            dateTimeUntil: DateTime.now().toISO(),
            workplaces: [],
            shiftEventTypes: []
        },

        pagination: {
            searchAfter: [],
            size:PAGE_SIZE,
            totalItemCount:0
        },

        model : {
            events: [],
            site: {},
            workplaces: [],
            shiftEventTypes: [],
        }
    },
    
    mutations: {
        
        setSiteId(state, siteId) {
            state.filter.siteId = siteId ? siteId : null;
        },

        setDateTimeFromFilter(state, dateTimeFrom) {
            if (dateTimeFrom) {
                state.filter.dateTimeFrom = dateTimeFrom;
            } else {
                state.filter.dateTimeFrom = DateTime.now().minus(Duration.fromMillis(1000 * 3600));
            }
        },

        setDateTimeUntilFilter(state, dateTimeUntil) {
            if (dateTimeUntil) {
                state.filter.dateTimeUntil = dateTimeUntil;
            } else {
                state.filter.dateTimeUntil = DateTime.now();
            }
        },

        setShiftEventTypeFilter(state, shiftEventTypeFilter) {

            if (isArray(shiftEventTypeFilter)) {
                state.filter.shiftEventTypes = shiftEventTypeFilter;
            } else {
                state.filter.shiftEventTypes = [];
            }
        },

        setWorkplaceFilter(state, workplaceFilter) {
            if (isArray(workplaceFilter)) {
                state.filter.workplaces = workplaceFilter;
            } else {
                state.filter.workplaces = [];
            }
        },

        appendEvents(state, events) {
            if (!state.model.events) {
                state.model.events = [];
            }

            if (events) {
                for (const event of events) {
                    state.model.events.push(event);
                }
                
            }
        },

        setPagination(state, pagination) {
            
            // Either apply the pagination or a default value.
            if (!isEmpty(pagination)) {
                state.pagination = pagination;
            } else {
                state.pagination = {
                    searchAfter: [],
                    size: PAGE_SIZE,
                    totalItemCount:0
                };
            }
        },

        setEvents(state, events) {
            state.model.events = events;
        },

        setSite(state, site) {
            state.model.site = site;
        },

        setWorkplaces(state, workplaces) {
            if (isArray(workplaces)) {
                state.model.workplaces = workplaces
            } else {
                state.model.workplaces =  [];
            }
        },

        setShiftEventTypes(state, shiftEventTypes) {
            if (isArray(shiftEventTypes)) {
                state.model.shiftEventTypes = shiftEventTypes
            } else {
                state.model.shiftEventTypes =  [];
            }
        }
    },

    actions: {

        async updateSiteIdFilter(context, siteId) {
            context.commit('setSiteId', siteId)
        },

        async updateDateTimeFromFilter(context, dateTimeFrom) {
            context.commit('setDateTimeFromFilter', dateTimeFrom);
        },

        async updateDateTimeUntilFilter(context, dateTimeUntil) {
            context.commit('setDateTimeUntilFilter', dateTimeUntil);
        },

        async updateWorkplaceFilter(context, workplaceFilter) {
            context.commit('setWorkplaceFilter', workplaceFilter);
        },

        async updateShiftEventTypeFilter(context, shiftEventTypeFilter) {
            context.commit('setShiftEventTypeFilter', shiftEventTypeFilter);
        },


        async resetModel(context) {
            console.log('Model is resetted.')
            context.commit('setEvents', []);
            context.commit('setPagination', null);

        },

        async loadData(context) {

            // Load Shift Events
            const response = await api.collections.documents.findByQuery(
                'ShiftEventLog',
                context.getters.query
            );

            console.log(`Loaded ${response.items.length} items.`)
            context.commit('appendEvents', response.items);
            context.commit('setPagination', response.pagination);
            
            // Load corresponding site
            const siteResponse = await smartFactoryApi.masterdata.sites.findById(context.getters.siteId);
            const site = get(siteResponse, 'item.site', {});
            if (!isEmpty(site)) {
                context.commit('setSite', site);   

                // Now load workplaces for site
                const workplaceResponse = await smartFactoryApi.masterdata.workplaces.findBySiteId(context.getters.siteId, 0, 50);
                context.commit('setWorkplaces', workplaceResponse.items);

                
                const shiftEventTypesResponse = await smartFactoryApi.masterdata.shiftEventTypes.findByFilter('*', 0, 50);
                context.commit('setShiftEventTypes', shiftEventTypesResponse.items);

                
            } else {
                console.warn(`Site ${context.getters.siteId} could not be loaded. Does this site exist?`);
                context.commit('setSite', {});
            }


        } 
        
    },

    getters: {
        siteId(state) {
            return state.filter.siteId;
        },

        model(state) {
            return state.model;
        },

        pagination(state) {
            return state.pagination;
        },

        filter(state) {
            return state.filter;
        },

        workplaces(state) {
            return state.model.workplaces;
        },

        shiftEventTypes(state) {
            return state.model.shiftEventTypes;
        },


        query(state, getters){
            
            // Build filter
            const filter = getters.filter;
            const facetFilters = [];

            facetFilters.push({name: "site", filterQuery: '*', values: [filter.siteId], type: "TEXT", filterMode: "CONTAINS"});
            
            if (!isEmpty(filter.workplaces)) {
                facetFilters.push({name: "workplace", filterQuery: '*', values: filter.workplaces, type: "TEXT", filterMode: "CONTAINS"});
            }

            if (!isEmpty(filter.shiftEventTypes)) {
                facetFilters.push({name: "eventType", filterQuery: '*', values: filter.shiftEventTypes, type: "TEXT", filterMode: "CONTAINS"});
            }

            return {
                filter: { 
                    "filterQuery":"*",
                    "filterQueryLanguage":"SIMPLE",
                    "rangeFilters":[{
                        "type": "TEMPORAL",
                        "name": "dateTime",
                        "from": filter.dateTimeFrom,
                        "until": filter.dateTimeUntil,
                        "filterMode": "CONTAINS"
                      }],
                    "facetFilters": facetFilters
                },
                sort: {
                    "sortCriteria":[{name: "dateTime", ordering: "DESC"}]
                },
                pagination: {
                    from: 0,
                    searchAfter: state.pagination.searchAfter,
                    size: state.pagination.size
                },
                "queryTimeoutMiliseconds":25000
            };
            
        }  
    }
}