<template>
  <v-container fluid class="secondary pt-10 pb-10">
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <c-base-dialog
          :value="detectionJobTemplate"
          :title="$t('detection-job-templates-wizard.view.title')"
          :subtitle="$t('detection-job-templates-wizard.view.subtitle')"
          color="secondary"
          @dialog-saved="save"
          @dialog-canceled="cancel"
          ref="form"
        >
          <template v-slot:default="props">
            <v-sheet class="ma-5">
              <c-name-input v-model="props.item.name" filled />
              <c-collection-settings-selector
                v-model="props.item.collectionId"
                :items="collectionSettingsList"
                :label="$t('detection-job-templates-wizard.view.fields.collectionId')"
                :rules="[(v) =>(v !== null && v !== undefined) || $t('detection-job-templates-wizard.view.fields.collectionId.notEmpty')]"
                filled
                @input="loadCollectionSettingsById(props.item.collectionId); props.item.dateTimeAttribute = null"
                :return-object="false"
              />
              <v-select
                v-if="hasMultipleDateTimeAttr(dateTimeAttributes) && collectionSelected"
                v-model="props.item.dateTimeAttribute"
                :items="dateTimeAttributes"
                :label="$t('detection-job-templates-wizard.view.fields.date-time-attribute.label')"
                :rules="[(v) =>(v !== null && v !== undefined) || $t('detection-job-templates-wizard.view.fields.dateTimeAttribute.notEmpty')]"
                filled
                color="accent"
                :clearable="true"
                item-value="name"
              >
                <template v-slot:selection="{ item }">
                    {{item | localized-name($i18n.locale)}}
                </template>
                <template v-slot:item="{ item, on, attrs }">
                    <v-list-item two-line v-on="on" v-bind="attrs" color="white">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{item | localized-name($i18n.locale)}}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{item | localized-description($i18n.locale) | truncate(32)}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
              
                </template>
              </v-select>

              <v-container v-else-if="collectionSelected && hasNoDateTimeAttr(dateTimeAttributes)">
                <v-banner single-line 
                  color="accent" 
                  icon="info" 
                  icon-color="white" 
                  class="white--text">
                  {{$t("detection-job-templates-wizard.view.fields.date-time-attribute.banner")}}
                </v-banner>
                <!-- THIS COMPONENT IS USED TO INVALIDATE THE FORM; ITS NOT SHOWN -->
                <v-select
                  v-show="false"
                  :items="dateTimeAttributes"
                  :rules="[(v) =>(v !== null && v !== undefined) || $t('detection-job-templates-wizard.view.fields.dateTimeAttribute.notEmpty')]"
                  item-value="name"
                />
                </v-container>
             
              <v-select
                v-else-if="collectionSelected"
                disabled
                v-model="props.item.dateTimeAttribute"
                :items="dateTimeAttributes"
                :label="$t('detection-job-templates-wizard.view.fields.date-time-attribute.label')"
                filled
                color="accent"
                item-value="name"
              >
                <template v-slot:selection="{ item }">
                    {{item | localized-name($i18n.locale)}}
                </template>
              </v-select>

            </v-sheet>
          </template>
        </c-base-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { forOwn } from "lodash";

const MODULE_NAME = "detectionJobTemplatesWizard";

export default {
  name: "detection-job-templates-wizard",

  inject: ["errorHandlerService", "progressIndicatorService"],

   watch: {
    hasNoDateTimeAttr(newVal) {
      if (newVal) {
        this.resetForm(); // Reset the form when the component is shown
      }
    }
  },

  methods: {
    async save() {
      this.progressIndicatorService.show();

      try {
        await this.$store.dispatch(MODULE_NAME + "/createDetectionJobTemplate");

        this.$router.push({
          name: "detectionJobTemplatesEditor",
          params: { name: this.detectionJobTemplate.name },
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {

        await this.$store.dispatch(MODULE_NAME + "/resetState");

        this.progressIndicatorService.hide();
      }
    },

    async cancel() {
      this.progressIndicatorService.show();

      try {
        await this.$store.dispatch(MODULE_NAME + "/resetState");

        this.$router.push({
          name: "detectionJobTemplatesList",
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    async refresh(from=0, pageSize=50) {
      this.progressIndicatorService.show();

      try {
        await this.$store.dispatch(MODULE_NAME + "/loadData", {from, pageSize});
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
        this.loadAllSchemas()
      }
    },

    resetForm() {
      this.$refs.form.reset(); // Reset the form using its ref
    },

    reset() {
      this.$store.dispatch(MODULE_NAME + "/resetDetectionJobTemplate");
    },

    async loadCollectionSettingsById(collectionId) {
      try {
        await this.$store.dispatch(MODULE_NAME + "/loadCollectionSettings", {collectionId});
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    async loadAllSchemas() {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_NAME + "/loadAllSchemas");
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    hasMultipleDateTimeAttr(dateTimeAttributes) {
      return Object.keys(dateTimeAttributes).length > 1;
    },

    hasNoDateTimeAttr(dateTimeAttributes) {
      return Object.keys(dateTimeAttributes).length == 0;
    },

    setDateTimeAttribute(item){
      this.detectionJobTemplate.dateTimeAttribute = item.name
    }
  
  },

  computed: {

    detectionJobTemplate: {
      get() {
        return this.$store.getters[MODULE_NAME + "/detectionJobTemplate"];
      },

      set(item) {
        this.$store
          .dispatch(MODULE_NAME + "/updateDetectionJobTemplate", item)
          .catch(this.errorHandlerService.handleError);
      },
    },

    collectionSettingsList: {
      get() {
        return this.$store.getters[MODULE_NAME + "/collectionSettingsList"];
      },
    },

    collectionSelected(){
      if(this.detectionJobTemplate.collectionId != null){
        return true;
      }else{
        return false;
      }
    },

    // ====================================================================================================================
    // THE FOLLOWING TWO ATTRIBUTES NARROW DOWN THE SCHEMAS AND COLLECTIONS TO CONSIST OF AT LEAST ONE DATETIME ATTRIBUTE.
    // ====================================================================================================================
    // collectionSettingsWithDateTime(){
    //   const collectionSettingsList = this.collectionSettingsList.filter((collection) =>
    //     this.schemasWithDatetime.some((schema) => collection.schemaId === schema.name)
    //   );

    //   return collectionSettingsList;
    // },

    // schemasWithDatetime() {
    //   const filteredSchemaList = filter(this.schemas.items, (schema) =>
    //     Object.values(schema.attributes).some(attribute => attribute.datatype === 'DATETIME')
    //   );

    //   return filteredSchemaList
    // },

    schema: {
      get() {
        return this.$store.getters[MODULE_NAME + "/schema"];
      },
    },

    schemas(){
      return this.$store.getters[MODULE_NAME + "/schemas"];
    },
 
    dateTimeAttributes() {
      let items = [];
      forOwn(this.schema.attributes, (value) => {
        if (value.datatype === "DATETIME") {
          items.push(value)
        }
      });

      if(items.length == 1){
        this.setDateTimeAttribute(items[0]);
      }

      return items;
    },
  },

  created() {
    this.reset();
    this.refresh();
  },
};
</script>

<i18n>
{
    "en" : {
        "detection-job-templates-wizard.view.title" : "Create New Detection Job Template",
        "detection-job-templates-wizard.view.subtitle" : "This wizard will guide you through the required steps for create a new detection job template.",
        "detection-job-templates-wizard.view.fields.collectionId" : "Source Collection",
        "detection-job-templates-wizard.view.fields.collectionId.notEmpty" : "The source collection must not be empty. Please select a source collection.",
        "detection-job-templates-wizard.view.fields.dateTimeAttribute.notEmpty" : "The date time field must not be empty. Please select an attribute.",
        "detection-job-templates-wizard.view.fields.date-time-attribute.banner" : "No date time attributes could be found for the selected collection. Please select a collection with at least one date time attribute.",
        "detection-job-templates-wizard.view.fields.date-time-attribute.label" : "Date-Time-Attribute"
    },

    "de" : {
        "detection-job-templates-wizard.view.title" : "Neues Detection Job Template anlegen",
        "detection-job-templates-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Detection Job Templates.",
        "detection-job-templates-wizard.view.fields.collectionId" : "Quell-Datenbereich",
        "detection-job-templates-wizard.view.fields.collectionId.notEmpty" : "Der Quell-Datenbereich darf nicht leer sein. Bitte wählen Sie einen Datenbereich aus.",
        "detection-job-templates-wizard.view.fields.dateTimeAttribute.notEmpty" : "Das Zeistempel Feld darf nicht leer sein. Bitte wählen Sie ein Zeistempel-Attribut aus.",
        "detection-job-templates-wizard.view.fields.date-time-attribute.banner" : "Es konnte kein Zeitstempel-Attribut für diesen Datenbereich gefunden werden. Bitte wählen sie einen Datenbereich mit mindestens einem Zeitstempel-Attribut.",
        "detection-job-templates-wizard.view.fields.date-time-attribute.label" : "Zeitstempel-Attribut"
    }
}
</i18n>