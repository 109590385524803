import api from '../../../api/battery-data-hub.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        batteryCategory: createEmptyBatteryCategory(),
        tenantId: null
    },

    mutations: {

        setBatteryCategory(state, batteryCategory) {

            if (batteryCategory) {

                state.batteryCategory.tenantId = batteryCategory.tenantId ? batteryCategory.tenantId : null;
                state.batteryCategory.name = batteryCategory.name ? batteryCategory.name : null;
                state.batteryCategory.i18n = batteryCategory.i18n ? batteryCategory.i18n : {};
                state.batteryCategory.data = batteryCategory.data ? batteryCategory.data : { custData: {} };
                state.batteryCategory.active = batteryCategory.active ? batteryCategory.active : true;
                state.batteryCategory.references = batteryCategory.references ? batteryCategory.references : {};

            } else {
                state.batteryCategory.tenantId = null;
                state.batteryCategory.name = null;
                state.batteryCategory.i18n = null;
                state.batteryCategory.data = null;
                state.batteryCategory.active = null;
                state.batteryCategory.references = null;
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        }


    },

    actions: {

        async createBatteryCategory(context) {

            try {

                let request = context.getters.request;
                await api.masterdata.batteryCategories.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetBatteryCategory(context) {
            context.commit('setBatteryCategory', createEmptyBatteryCategory());
        },

        async updateBatteryCategory(context, batteryCategory) {

            try {
                context.commit('setBatteryCategory', batteryCategory);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.batteryCategory;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        batteryCategory(state) {
            return state.batteryCategory ? state.batteryCategory : createEmptyBatteryCategory();
        }

}
}

function createEmptyBatteryCategory() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { custData: {} },
        "active": true,
        "references": {}

    }
}