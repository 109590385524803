<template>
    <v-tabs 
        v-model="selectedTab"
        centered 
        align-with-title
        grow
        color="grey" 
        background-color="grey lighten-3">
        
        <v-tab>{{$t('template-expression-help.component.tab.fields')}}</v-tab>
        <v-tab>{{$t('template-expression-help.component.tab.context')}}</v-tab>
        <v-tab>{{$t('template-expression-help.component.tab.functions')}}</v-tab>

        <v-tabs-items v-model="selectedTab">

            <!-- Data Fields -->
            <v-tab-item>
               <dxs-expression-attributes-help :value="value" />
            </v-tab-item> 

            <!-- Context-->
            <v-tab-item>
                <v-simple-table >
                    <thead>
                        <tr>
                            <th>{{$t('template-expression-help.component.tab.func')}}</th>
                            <th>{{$t('template-expression-help.component.tab.returnValue')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="3" class="grey lighten-3"><code>$source</code></td>
                        </tr>
                        <tr>
                            <td><code>$source.collectionSettings</code></td>
                            <td><code>MAP</code></td>
                        </tr>
                        <tr>
                            <td><code>$source.schema</code></td>
                            <td><code>MAP</code></td>
                        </tr>
                        <tr>
                            <td><code>$source.document</code></td>
                            <td><code>MAP</code></td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">Tenant Context</td>
                        </tr>
                        <tr>
                            <td><code>$tenant.tenantId</code></td>
                            <td><code>STRING</code></td>
                        </tr>
                        <tr>
                            <td><code>$tenant.stage</code></td>
                            <td><code>STRING</code></td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">Time Context</td>
                        </tr>
                        <tr>
                            <td><code>$time.instant</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td><code>$time.timestamp</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td><code>$time.year</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td><code>$time.month</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td><code>$time.dayOfMonth</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td><code>$time.dayOfYear</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td><code>$time.dayOfWeek</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td><code>$time.hour</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td><code>$time.minute</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td><code>$time.second</code></td>
                            <td><code>DATETIME</code></td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">Shortcuts</td>
                        </tr>
                        <tr>
                            <td><code>$data</code></td>
                            <td><code>MAP</code></td>
                        </tr>
                        <!-- <tr>
                            <td><code>$tags</code></td>
                            <td>MAP</td>
                        </tr> -->
                    </tbody>
                </v-simple-table>
            </v-tab-item>

            <!-- Functions -->
            <v-tab-item>
                <dxs-expression-functions-help/>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</template>

<script>
import ExpressionFunctionsHelpComponent from './expression-functions-help.component';
import ExpressionAttributesHelpComponent from './expression-attributes-help.component';

export default {

    name: 'template-expression-help',

    props: {

        value: {
            type: Array,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),
    components: {
      'dxs-expression-functions-help' : ExpressionFunctionsHelpComponent,
      'dxs-expression-attributes-help' : ExpressionAttributesHelpComponent
    },
    methods: {

        isNumeric(attribute) {
            return (attribute.datatype === 'DECIMAL' || attribute.datatype === 'INTEGER');
        }
    },

    computed: {


    }
}
</script>

<i18n>
 {
     "en": {
        "template-expression-help.component.tab.fields" : "Fields",
        "template-expression-help.component.tab.context" : "Context",
        "template-expression-help.component.tab.functions" : "Functions",
        "template-expression-help.component.tab.field" : "Field",
        "template-expression-help.component.tab.datatype" : "Datatype",
        "template-expression-help.component.tab.note" : "Note",        
        "template-expression-help.component.tab.func" : "Function",
        "template-expression-help.component.tab.description" : "Description",
        "template-expression-help.component.tab.returnValue" : "Return Type"

       
     },

     "de" : {
        "template-expression-help.component.tab.fields" : "Felder",
        "template-expression-help.component.tab.context" : "Kontext",
        "template-expression-help.component.tab.functions" : "Funktionen",
        "template-expression-help.component.tab.field" : "Feld",
        "template-expression-help.component.tab.datatype" : "Datentyp",
        "template-expression-help.component.tab.note" : "Erläuterung",
        "template-expression-help.component.tab.func" : "Funktion",
        "template-expression-help.component.tab.description" : "Beschreibung",
        "template-expression-help.component.tab.returnValue" : "Rückgabewert"
     }
 }
</i18n>