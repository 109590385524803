<template>
<v-sheet>
    
    <dxs-category-section :title="$t('battery-circularity.page.sections.renewableContent.title')">
        <c-metrics-chart horizontal height="300px" background-color="#eeeeee" stack-data show-value-label :dimensions="[{name: 'renewable', color: '#FCD299', }, {name: 'other', color: '#599099'}]" :items="[{name: 'a', values: {'renewable' : 12, other: 88}}]" :show-background="false" />
    </dxs-category-section>
    <v-divider />
    
    <dxs-category-section :title="$t('battery-circularity.page.sections.recycledContent.title')">
        <v-container fluid >
            <v-row>
                <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) of recycledSubstancesModel" :key="`substance-card-${index}`">
                    <dxs-substance-recycling-data-tile :value="item" />
                </v-col>
            </v-row>
        </v-container>
    </dxs-category-section>
    <v-divider />
    <dxs-category-section :title="$t('battery-circularity.page.sections.endOfLife.title')">
        <v-container class="justify-center">
            <v-row>
                <v-col cols="12">
                    <v-card tile>
                        <c-link-list :items="endOfLifeDocumentLinks" open-link-icon="link" />
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        
    </dxs-category-section>
    <v-divider />
    <dxs-category-section :title="$t('battery-circularity.page.sections.designForCirularity.title')">

    </dxs-category-section>
    <v-divider />
    <dxs-category-section :title="$t('battery-circularity.page.sections.safetyRequirements.title')">
        <v-container fluid >
            <v-row>
                <v-col cols="12" lg="4">
                    <dxs-fire-extinguisher-class-tile :value="fireExtinguisherClassModel" height="100%"/>
                </v-col>
                <v-col cols="12" lg="8">
                    <v-card height="100%">
                        <v-card-text>
                            <div class="text-h6">Safety Instructions</div>
                        </v-card-text>
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>warning</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Do not pry open
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>warning</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Do not put underwater
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>warning</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Do not put underwater
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>warning</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Do not put underwater
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>warning</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Do not put underwater
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider />
                        <v-card-actions class="text-right" >
                            <v-btn color="accent" depressed  block>
                                <v-icon color="white" class="mr-5">file_open</v-icon> Open File...
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </dxs-category-section>
    <v-divider />
    
</v-sheet>
</template>

<script>

import SubstanceRecyclingDataTileComponent from '@/solutions/battery-data-hub/components/circularity/substance-recycling-data-tile.component.vue';
import FireExtinguisherClassTileComponent from '@/solutions/battery-data-hub/components/circularity/fire-extinguisher-class-tile.component.vue';
import { get } from 'lodash';
import CategorySectionComponent from '@/solutions/battery-data-hub/components/common/category-section.component.vue';


export default {
    
    name: 'battery-circularity-page',

    components: {
        'dxs-category-section' : CategorySectionComponent,
        'dxs-substance-recycling-data-tile' : SubstanceRecyclingDataTileComponent,
        'dxs-fire-extinguisher-class-tile' : FireExtinguisherClassTileComponent
    },

    props: {

        value: {
            type: Object,
            required: false
        }
    },


    computed: {

        battery() {
            return get(this.value, 'battery', {});
        },

        safetyRequirements() {
            return get(this.value, 'safetyRequirements', {});
        },  

        fireExtinguisherClassModel() {

            return {
                battery: this.battery,
                fireExtinguisherClass: this.safetyRequirements.fireExtinguisherClass
            }
        },

        recycledSubstancesModel() {
            const items = [];

            for (const substance of this.value.substances) {
                items.push({
                    battery: this.value.battery,
                    substance: substance.substance,
                    batterySubstanceQuantity: substance.batterySubstanceQuantity
                })
            }
            return items;
        },

        endOfLife() {
            return get(this.value, 'endOfLife', {});
        },

        endOfLifeDocumentLinks() {

            return [
                this.endOfLife.endUserWastePreventionInfo,
                this.endOfLife.endUserWasteCollectionInfo,
                this.endOfLife.generalInfo
            ]
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "battery-circularity.page.sections.endOfLife.title" : "End Of Life",
        "battery-circularity.page.sections.designForCirularity.title" : "Design for Circularity",
        "battery-circularity.page.sections.recycledContent.title" : "Recycled Content",
        "battery-circularity.page.sections.renewableContent.title" : "Renewable Content",
        "battery-circularity.page.sections.safetyRequirements.title" : "Security Requirements"
    },

    "de" : {
        "battery-circularity.page.sections.endOfLife.title" : "Informationen zum Ende des Lebenszyklus",
        "battery-circularity.page.sections.designForCirularity.title" : "Design für Kreislaufwirtschaft",
        "battery-circularity.page.sections.recycledContent.title" : "Recycling-Anteil",
        "battery-circularity.page.sections.renewableContent.title" : "Anteil erneuerbarer Inhalt",
        "battery-circularity.page.sections.safetyRequirements.title" : "Sicherheitsanforderungen"
    }
}    
</i18n>