<template>
  <v-sheet>
    <c-sticky-content>
        <v-toolbar flat>
            <v-toolbar-title>{{$t('time-series-explorer.view.title')}}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn color="accent" text @click="showSaveDialog = true"><v-icon class="pr-5">save_alt</v-icon> {{$t('time-series-explorer.view.actions.save')}}</v-btn>
                <v-btn color="accent" text @click="refresh" :disabled="timeSeriesItemsEmpty"><v-icon class="pr-5">refresh</v-icon> {{$t('time-series-explorer.view.actions.refresh')}}</v-btn>
                <v-btn color="accent" text @click="addNewTimeSeries"><v-icon class="pr-5">add</v-icon> {{$t('time-series-explorer.view.actions.add')}}</v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
    </c-sticky-content>

    <!-- Configuration -->
    <v-card tile flat>
        <c-time-range-selector v-model="timeRange" flat filled />
        <v-divider />
        <dxs-time-series-panel v-model="timeSeriesItems" :collectionData="collections" ref="timeSeriesItemEditor" flat />
    </v-card>
    <v-divider />

    <!-- Diagramm Area -->
    <v-card tile flat v-show="!timeSeriesItemsEmpty">
        <c-metrics-time-series-chart 
            :value="chartModel" 
            legend="bottom" 
            class="pb-5" 
            :height="600 + 'px'" 
            fallbackChartType="line" 
            :showValueAxis="true" 
            :scaleValueAxis="false"
            zoom="top" 
            />
    </v-card>
    <v-card tile flat color="grey lighten-3" v-show="timeSeriesItemsEmpty">

        <v-card-text class="text-center text-h4 grey--text">
            <v-icon color="accent" large>arrow_circle_up</v-icon>
            <br />
            Wo sind meine Daten?
        </v-card-text>
         <v-card-text class="text-center grey--text">
            Bitte legen Sie mindestens eine Zeitreihe an und bestätigen Sie dann mit Neu Laden, um die Zeitreihe zu visualisieren.
        </v-card-text>
    </v-card>


    

    <v-dialog v-model="showSaveDialog" width="640" :fullscreen="$vuetify.breakpoint.smAndDown">
        <v-card tile flat>
            <v-toolbar flat>
                <v-toolbar-item>Save as...</v-toolbar-item>
                <v-spacer />
                <v-toolbar-items>
                    
                    <v-btn icon @click="showSaveDialog = false"><v-icon>close</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-card-text />
            <v-card-text>
                <c-name-input filled />
                <c-i18n-editor flat header-style="lite" />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn color="accent" text>{{$t('time-series-explorer.view.actions.save')}}</v-btn>
                <v-btn color="grey" text>{{$t('time-series-explorer.view.actions.cancel')}}</v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>



  </v-sheet>
</template>

<script>
import { DateTime } from 'luxon';
import { get, isEmpty } from 'lodash';

//import TimeSeriesItemEditorComponent from '@/core/components/data-exploration/time-series/time-series-item-editor.component';
import TimeSeriesPanelComponent from '@/core/components/data-exploration/time-series/time-series-panel.component';

import TimeSeriesExplorerModule from '@/core/store/modules/time-series/time-series-explorer.module';

const MODULE_NAME = 'timeSeriesExplorer';

export default {

    name: 'time-series-explorer',

    components: {
       // 'dxs-time-series-item-editor' : TimeSeriesItemEditorComponent,
        'dxs-time-series-panel' : TimeSeriesPanelComponent
    },

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    data() {
        const now = DateTime.now();

        return {

            showSaveDialog: false,

            showTimeSeriesItemPane: false,

            timeRange: {
                dateTimeFrom: now.startOf('day').toISO(),
                dateTimeUntil: now.toISO(),
            },

            timeSeriesItems: []
        };
    },

    computed: {

        model: {

            get() {
                return this.$store.getters[MODULE_NAME + "/model"];
            },
            
            /*
            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateModel", value);
            }
            */
        },

        collections() {
            return get(this.model, 'collections', []);
        },

        timeSeriesItemsEmpty() {
            return isEmpty(this.timeSeriesItems)
        },

        chartHeigth() {

            if (this.$vuetify.breakpoint.smAndDown) return 300;
            else return 600;
        },

        chartModel() {
             return this.$store.getters[MODULE_NAME + "/chartModel"];
        }
/*
        chartModel() {
            return {

                items: [
                    {dateTime : '2022-08-02T12:00:00.0+01:00', values: {'Sensor/Heat': 13.5, 'Sensor/Pressure' : 47.12 }},
                    {dateTime : '2022-08-02T12:01:00.0+01:00', values: {'Sensor/Heat': 13.8, 'Sensor/Pressure' : 53.12 }},
                    {dateTime : '2022-08-02T12:02:00.0+01:00', values: {'Sensor/Heat': 14.5, 'Sensor/Pressure' : 49.12 }},
                    {dateTime : '2022-08-02T12:02:50.0+01:00', values: {'Sensor/Heat': 13.6 }},
                    {dateTime : '2022-08-02T12:03:00.0+01:00', values: {'Sensor/Heat': 13.6, 'Sensor/Pressure' : 41.12 }},
                    {dateTime : '2022-08-02T12:04:00.0+01:00', values: {'Sensor/Heat': 13.89, 'Sensor/Pressure' : 41.9998 }},
                    {dateTime : '2022-08-02T12:05:00.0+01:00', values: {'Sensor/Heat': 13.3, 'Sensor/Pressure' : 48.1 }},
                    {dateTime : '2022-08-02T12:06:00.0+01:00', values: {'Sensor/Heat': 13.6 }},
                    {dateTime : '2022-08-02T12:07:00.0+01:00', values: {'Sensor/Heat': 14.91 }},
                ],

                series: {
                    'Sensor/Heat': {

                        name: 'Sensor/Heat',
                        i18n: {
                            'en' : {
                                name: 'Heat (Sensor)'
                            },

                            'de' : {
                                name: 'Hitze (Sensor)'
                            }
                        }
                    },

                    'Sensor/Pressure': {

                        name: 'Sensor/Pressure',
                        i18n: {
                            'en' : {
                                name: 'Pressure (Sensor)'
                            },

                            'de' : {
                                name: 'Druck (Sensor)'
                            }
                        }
                    },
                }
            }
        }
        */
    },

    methods: {

        addNewTimeSeries() {

            this.$refs.timeSeriesItemEditor.addNewItem();
        },

        async initView() {
            this.progressIndicatorService.show();
            try {

                // First reset ...
                //await this.$store.dispatch(MODULE_NAME + "/resetData")

                // ... then reload 
                await this.$store.dispatch(MODULE_NAME + '/loadMasterdata');


            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async refresh() {
            this.progressIndicatorService.show();
            try {

                // First reset ...
                await this.$store.dispatch(MODULE_NAME + '/resetTimeSeries');

                // ... then reload 
                //await this.$store.dispatch(MODULE_NAME + '/loadMasterdata');
                
                
                

                // Iterate the time series
                for (const ts of this.timeSeriesItems) {
                    await this.$store.dispatch(MODULE_NAME + '/loadTimeSeries', {
                        fromDate: this.timeRange.dateTimeFrom,
                        untilDate: this.timeRange.dateTimeUntil, 
                        timeSeriesItem: ts
                    })
                }

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    created() {

        // Dynamically register the corresponding vuex module on component creation.
        if (!this.$store.hasModule(MODULE_NAME)){
            this.$store.registerModule(MODULE_NAME, TimeSeriesExplorerModule);
        }

        this.initView();
    }
}
</script>

<i18n>
{   
    "en" : {
        "time-series-explorer.view.title" : "Time Series Explorer",
        "time-series-explorer.view.actions.save" : "Save",
        "time-series-explorer.view.actions.cancel" : "Cancel",
        "time-series-explorer.view.actions.refresh" : "Refresh",
        "time-series-explorer.view.actions.add" : "Add Time Serie"
    },

    "de" : {
        "time-series-explorer.view.title" : "Zeitreihen Cockpit",
        "time-series-explorer.view.actions.save" : "Speichern",
        "time-series-explorer.view.actions.cancel" : "Abbrechen",
        "time-series-explorer.view.actions.refresh" : "Neu Laden",
        "time-series-explorer.view.actions.add" : "Zeitreihe hinzufügen"
    }
}
</i18n>