<template>
  <c-app-shell :locales="locales" class="secondary"></c-app-shell>
</template>

<script>
import ApplicationShell from "@/application.shell";

// Setup services to be provided.

export default {
  name: "c-app",

  components: {
    "c-app-shell": ApplicationShell,
  },

  data() {
    return {
      locales: ["en", "de"],
    };
  },

  /*
    provide() {

    }
*/
};
</script>