<template>
  <div class="secondary darken-1">
    <c-section
      :title="$t('prediction-model-wizard.view.sections.title')"
      :subtitle="$t('prediction-model-wizard.view.sections.subtitle')"
      class="secondary"
    >
      <v-container fluid class="secondary">
        <v-row justify="center">
          <v-col cols="12" lg="9">
            <v-card tile>
              
              <v-stepper value="1" non-linear class="elevation-0">
                <v-stepper-header class="elevation-0">
                  <v-stepper-step
                    :rules="[() => form1Valid]"
                    editable
                    step="1"
                  >{{$t('prediction-model-wizard.view.stepper.1.header')}}</v-stepper-step>
                  <v-divider />
                  <v-stepper-step
                    :rules="[() => form2Valid]"
                    editable
                    step="2"
                  >{{$t('prediction-model-wizard.view.stepper.2.header')}}</v-stepper-step>
                  <v-divider />
                  <v-stepper-step
                    editable
                    step="3"
                  >{{$t('prediction-model-wizard.view.stepper.3.header')}}</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form ref="form1" v-model="form1Valid" lazy-validation>
                      <v-card tile>
                        <v-card-text>
                          <div
                            class="text-h6"
                          >{{$t('prediction-model-wizard.view.stepper.1.content.name.title')}}</div>
                          <p />
                          <div>{{$t('prediction-model-wizard.view.stepper.1.content.name.subtitle')}}</div>
                        </v-card-text>
                        <v-card-text>
                          <v-text-field
                            dense
                            v-model="model.name"
                            filled
                            :label="$t('prediction-model-wizard.view.stepper.1.content.name.label')"
                            :rules="[v => v && !!v.trim() && v.length >= 3  || $t('prediction-model-wizard.view.stepper.1.content.name.validation.error', $i18n.locale)]"
                          />
                        </v-card-text>
                        <v-card-text>
                          <div
                            class="text-h6"
                          >{{$t('prediction-model-wizard.view.stepper.1.content.collection.title')}}</div>
                          <p />
                          <div>{{$t('prediction-model-wizard.view.stepper.1.content.collection.subtitle')}}</div>
                        </v-card-text>
                        <v-card-text>
                          <c-item-selector
                            v-bind:value="model.collectionSettingsId"
                            v-on:input="model.collectionSettingsId = $event.value"
                            :items="collectionSettings"
                            filled
                            dense
                            itemKey="collectionId"
                            :label="$t('prediction-model-wizard.view.stepper.1.content.collection.label')"
                            :rules="[v => !!v || $t('prediction-model-wizard.view.stepper.1.content.collection.validation.error', $i18n.locale)]"
                          ></c-item-selector>
                        </v-card-text>
                      </v-card>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-form ref="form2" v-model="form2Valid" lazy-validation>
                      <v-card tile>
                        <v-card-text>
                          <div
                            class="text-h6"
                          >{{$t('prediction-model-wizard.view.stepper.2.content.predictedAttribute.title')}}</div>
                          <p />
                          <div>{{$t('prediction-model-wizard.view.stepper.2.content.predictedAttribute.subtitle')}}</div>
                        </v-card-text>
                        <v-card-text>
                          <c-item-selector
                            v-bind:value="model.targetAttribute"
                            v-on:input="model.targetAttribute = $event.value"
                            :items="selectableMeasures"
                            filled
                            dense
                            itemKey="name"
                            :label="$t('prediction-model-wizard.view.stepper.2.content.predictedAttribute.label')"
                            :rules="[v => !!v || $t('prediction-model-wizard.view.stepper.2.content.predictedAttribute.validation.error', $i18n.locale)]"
                          />
                        </v-card-text>
                       
                      </v-card>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-card tile>
                      <v-card-text>
                        <div class="text-h6">Prüfen und Speichern</div>
                        <p />
                        <div>
                          Bitte überprüfen Sie vor dem Speichern nochmals die Richtigkeit Ihrer Modell-Konfiguration.
                          Nachträgliche Änderungen des Modell sind nach der Speicherung nicht mehr möglich.
                        </div>
                        <p />
                        <div>Klicken Sie danach auf den untenstehenden Knopf, um das Modell für die weitere Verwendung zu speichern.</div>
                      </v-card-text>
                      <v-card-text>
                        <v-btn v-on:click="save" color="primary">Speichern</v-btn>
                      </v-card-text>
                    </v-card>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </c-section>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const MODULE_NAME = "predictionScenarioWizard";
const { mapMutations, mapGetters, mapActions } = createNamespacedHelpers(
  MODULE_NAME
);

export default {
  name: "prediction-scenario-wizard",

  props: {
    value: {
      type: Object,
      required: false,
    },

    options: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      loading: false,
      form1Valid: false,
      form2Valid: false,
      model: {
        name: null,
        collectionSettingsId: null,
        targetAttribute: null,
        i18n: {},
        customData: {},
      },
    };
  },

  computed: {
    ...mapGetters(["collectionSettings", "schemas"]),

    selectableMeasures() {
        return Object.values(this.selectableAttributes).filter(e => e.attributeType === "MEASURE")
    },

    selectableAttributes() {
        if(this.model.collectionSettingsId) {
            let collection = this.collectionSettings.find(e => e.collectionId === this.model.collectionSettingsId);
            if(collection) {
                let schema = this.schemas.find(schema => schema.name === collection.schemaId)
                return schema.attributes
            }
        }
        return [];
    }
  },

  methods: {
    ...mapMutations(['setRequest']),

    ...mapActions(['loadCollectionSettings','createPredictionScenario']),

    save() {
      if (this.validate()) {
        let request = {...this.model};
        this.setRequest(request)
        this.createPredictionScenario().then(() => this.$router.push({ path: `/prediction-scenarios/${this.model.name}` }));
      }
    },

    validate() {
      return this.$refs.form1.validate() && this.$refs.form2.validate();
    },
  },

  created() {
    this.loadCollectionSettings();
  },
};
</script>

<style>
</style>

<i18n>
{
  "en": {
    "prediction-model-wizard.view.sections.title" : "Create new model",
    "prediction-model-wizard.view.sections.subtitle" : "Follow these steps to create a new prediction model.",
    "prediction-model-wizard.view.stepper.1.header" : "Define model root",
    "prediction-model-wizard.view.stepper.2.header" : "Define model attributes",
    "prediction-model-wizard.view.stepper.3.header" : "Validate and Save",
    "prediction-model-wizard.view.stepper.1.content.name.title" : "Define unique name",
    "prediction-model-wizard.view.stepper.1.content.name.label" : "Name",
    "prediction-model-wizard.view.stepper.1.content.name.subtitle" : "Define a unique name (ID) for your model. This name will be used to query your models API endpoint. The ID cannot be changed after creation.",
    "prediction-model-wizard.view.stepper.1.content.name.validation.error" : "Please provide a name that is at least 3 characters long.",
    "prediction-model-wizard.view.stepper.1.content.collection.title" : "Define data collection",
    "prediction-model-wizard.view.stepper.1.content.collection.label" : "Data collection",
    "prediction-model-wizard.view.stepper.1.content.collection.subtitle" : "Define a data collection which is used for the model. The structure is based on the schema that is present on the data collection. The data of the collection is used to train and validate your model.",
    "prediction-model-wizard.view.stepper.1.content.collection.validation.error" : "Please select a data collection.",

    "prediction-model-wizard.view.stepper.2.content.predictedAttribute.title" : "Define predicted attribute",
    "prediction-model-wizard.view.stepper.2.content.predictedAttribute.subtitle" : "Define the attribute that should be predicted by your model. Only measures are applicable for prediction.",
    "prediction-model-wizard.view.stepper.2.content.predictedAttribute.label" : "Predicted attribute",
    "prediction-model-wizard.view.stepper.2.content.predictedAttribute.validation.error" : "Please select a predicted attribute."
  },

  "de": {
    "prediction-model-wizard.view.sections.title" : "Neues Modell definieren",
    "prediction-model-wizard.view.sections.subtitle" : "Folgen Sie den Schritten um ein neues Vorhersage-Modell zu definieren.",
    "prediction-model-wizard.view.stepper.1.header" : "Modellstamm definieren",
    "prediction-model-wizard.view.stepper.2.header" : "Modellattribute festlegen",
    "prediction-model-wizard.view.stepper.3.header" : "Prüfen und Speichern",
    "prediction-model-wizard.view.stepper.1.content.name.title" : "Eindeutigen Bezeichner festlegen",
    "prediction-model-wizard.view.stepper.1.content.name.label" : "Bezeichner",
    "prediction-model-wizard.view.stepper.1.content.name.subtitle" : "Legen Sie einen eindeutigen Bezeichner (ID) für Ihr Modell fest. Dieser Bezeichner wird verwendet, um Ihr Modell als API-Endpunkt anzusprechen. Die ID kann nach dem Anlegen des Modells nicht mehr geändert werden.",
    "prediction-model-wizard.view.stepper.1.content.name.validation.error" : "Bitte geben Sie einen Bezeichner ein der mindestens 3 Zeichen enthält.",
    "prediction-model-wizard.view.stepper.1.content.collection.title" : "Datenbereich festlegen",
    "prediction-model-wizard.view.stepper.1.content.collection.label" : "Datenbereich",
    "prediction-model-wizard.view.stepper.1.content.collection.subtitle" : "Legen Sie einen Datenbereich fest, auf dessen Basis das Modell definiert wird. Anhand des Schemas des Datenbereichs legen Sie die Struktur des Modells fest. Die Datensätze in Ihrem Datenbereich dienen zum Training und zu Validierung des Modells.",
    "prediction-model-wizard.view.stepper.1.content.collection.validation.error" : "Bitte wählen Sie einen Datenbereich aus.",

    "prediction-model-wizard.view.stepper.2.content.predictedAttribute.title" : "Vorherzusagenden Wert festlegen",
    "prediction-model-wizard.view.stepper.2.content.predictedAttribute.subtitle" : "Wählen Sie denjenigen Wert aus Ihrem Datenbereich aus, der künftig von Ihrem Modell vorhergesagt werden soll. Bitte beachten Sie, dass nur Kennzahlen vorhergesagt werden können.",
    "prediction-model-wizard.view.stepper.2.content.predictedAttribute.label" : "Vorherzusagender Wert",
    "prediction-model-wizard.view.stepper.2.content.predictedAttribute.validation.error" : "Bitte wählen Sie einen vorherzusagenden Wert aus."
  }
}
</i18n>