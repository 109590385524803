<template>
    <div>
        <c-section :title="$t('prediction-scenario-list.view.title')" :subtitle="$t('prediction-scenario-list.view.subtitle')"  class="secondary">
            <c-item-list-container :value="{ items: items}" @item-clicked="onItemClicked" :loading="loading">

            </c-item-list-container>
        </c-section>
    </div>
</template>

<script>
const MODULE_NAME = 'predictionScenarioList';

export default {

    data: () => ({
        loading: false
    }),

    inject: ['errorHandlerService'],

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        }
    },

    methods: {

        async refresh() {

            this.loading = true;
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse")
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.loading = false;
            }
        },

        onItemClicked(event) {

            if (event) {
                this.$router.push({
                    name: 'predictionScenarioDetailView',
                    params: {
                        scenarioId : event.item.name
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to prediction scenario.")
            }
            
        }
    },

    created() {
        this.refresh();
    }
}
</script>

<style>

</style>