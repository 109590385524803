<template>
    <div>
        <c-section :title="$t('data-pipeline-list.view.title')" :subtitle="$t('data-pipeline-list.view.subtitle')"
            class="secondary">
            <v-card class="mt-5 mb-5" tile>
                <v-card-text v-if="!canUploadData" class="error white--text text-center">
                    <v-icon color="white" class="mr-3">warning</v-icon> {{ $t("data-pipeline-list.view.dialogs.delete.help.uploadNotAvailable") }}
                </v-card-text>
                <c-item-list :title="$t('data-pipeline-list.view.toolbar.title')" :items="items" :pagination="pagination"
                    @refresh-list="refresh" @previous-page="onPreviousPage" @next-page="onNextPage"
                    @item-list-filter-applied="onFilterChanged" :disable-edit-item="!hasEditRole && !hasReadRole"
                    :supports-add-item="false" :supports-delete-item="false">
                    <template v-slot:item-list="{ items }">
                        <v-container fluid>
                            <v-row>
                                <v-col>
                                    
                                    <dxs-collection-data-pipeline-list-item v-for="(item, index) of items"
                                        :key="'listItem_' + index" 
                                        :value="item" 
                                        :disabled-upload="!canUploadData"
                                        @upload-clicked="goToJsonUploadView" />
                                    
                                </v-col>

                            </v-row>
                        </v-container>
                    </template>

                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{ $t('data-pipeline-list.view.help.title') }}</v-subheader>
                <v-card-text class="grey lighten-5">{{ $t('data-pipeline-list.view.help.description') }}</v-card-text>
            </v-card>
        </c-section>

    </div>
</template>

<script>
const MODULE_NAME = 'core:integration:data-pipelines:data-pipelines.list';
import Roles from '@/roles';
import { get } from 'lodash';

import DataPipelinesListModule from '@/core/store/modules/integration/data-pipelines/data-pipelines-list.module';
import CollectionDataPipelineListItemComponent from '@/core/components/integration/data-pipelines/collection-data-pipeline-list-item.component';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    components: {
        'dxs-collection-data-pipeline-list-item': CollectionDataPipelineListItemComponent
    },

    data: () => ({

        collectionSetting: {},

    }),

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        },

        pagination() {
            return get(this.response, 'pagination', {});
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.PIPELINES_READ);
        },

        
        canUploadData() {
            return this.userContextService.hasAllRoles([Roles.PIPELINES_UPLOAD, Roles.SCHEMAS_READ, Roles.COLLECTION_SETTINGS_READ]);
        }

    },

    methods: {

        async refresh() {
            this.loadPage(0, 50);
        },

        async onNextPage({ from, pageSize }) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({ from, pageSize }) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse", { from, pageSize });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },



        goToJsonUploadView({ pipelineName }) {

            this.$log.debug(`Navigation to view CollectionSettingsDataUpload for pipeline ${pipelineName}`);
            this.$router.push({
                name: 'CollectionSettingsDataUpload',
                params: {
                    name: pipelineName
                }
            });
        }



    },

    created() {
        // Dynamically register the vuex store module for managing the data.
        if (!this.$store.hasModule(MODULE_NAME)){
            this.$store.registerModule(MODULE_NAME, DataPipelinesListModule);
        }

        this.refresh();

        
    }
}
</script>

<i18n>
{
    "en" : {
        "data-pipeline-list.view.title" : "Data Pipelines ",
        "data-pipeline-list.view.subtitle" : "This dialog manages the pipelines that supply the system with data. A pipeline is automatically created for each collection.",
        "data-pipeline-list.view.toolbar.title" : "Data Pipelines",

        "data-pipeline-list.view.columns.open" : "Open",
        "data-pipeline-list.view.columns.reindex" : "Reindex",
        "data-pipeline-list.view.columns.apis" : "APIs",
        "data-pipeline-list.view.columns.embeddedCollections" : "Embed. Coll.",
        "data-pipeline-list.view.columns.systemCollection" : "System?",
        "data-pipeline-list.view.columns.systemCollection.hint" : "System Collections are automatically provided by the platform in order to provided flexible access to technical data like dead letters, logs, etc.",

        "data-pipeline-list.view.help.title" : "What is a Data Pipeline?",
        "data-pipeline-list.view.help.description" : "A Data Pipeline can be used to manually import individual documents directly into a collection.",

        "data-pipeline-list.view.reindex.dialog.title" : "Reindex",
        "data-pipeline-list.view.reindex.dialog.subtitle" : "The reindexing of a collection may take up to several minutes. Do you really want to start this process?",
        "data-pipeline-list.view.reindex.dialog.confirm" : "Start now",
        "data-pipeline-list.view.reindex.dialog.cancel" : "Cancel",

        "data-pipeline-list.view.dialogs.delete.title" : "You really want to delete these Collection Settings?",
        "data-pipeline-list.view.dialogs.delete.description" : "You are about to delete the collection {name}. When deleting a collection, all associated data documents of the collection will also be deleted irrevocably. This action cannot be undone. Therefore, please confirm that you now want to proceed to delete the entire collection and all associated data documents.",
        "data-pipeline-list.view.dialogs.delete.actions.confirm" : "Confirm",
        "data-pipeline-list.view.dialogs.delete.actions.cancel" : "Cancel",

        "data-pipeline-list.view.dialogs.delete.help.uploadNotAvailable" : "The upload is disabled because you are missing at least one of the following permissions: amentis-dxs-catalog-collectionsettings-read, amentis-dxs-catalog-schemas-read, amentis-dxs-integration-pipelines-upload"


    },

    "de" : {
        "data-pipeline-list.view.title" : "Verwaltung Daten-Pipelines",
        "data-pipeline-list.view.subtitle" : "Dieser Dialog dient der Verwaltung der Pipelines, durch die das System mit Daten versorgt wird. Für jeden Datenbereich wird automatisch eine Pipeline angelegt.",
        "data-pipeline-list.view.toolbar.title" : "Daten-Pipelines",

        "data-pipeline-list.view.columns.open" : "Öffnen",
        "data-pipeline-list.view.columns.reindex" : "Neu indizieren",
        "data-pipeline-list.view.columns.apis" : "APIs",
        "data-pipeline-list.view.columns.embeddedCollections" : "Unterbereiche?",
        "data-pipeline-list.view.columns.systemCollection" : "System?",
        "data-pipeline-list.view.columns.systemCollection.hint" : "System-Datenbereiche werden von der Plattform automatisch bereitgestellt. Sie ermöglichen für Systembetreuer den flexiblen Zugriff auf technische Informationen wie Logs und fehlerhafte Nachrichten.",


        "data-pipeline-list.view.help.title" : "Was ist eine Daten-Pipeline?",
        "data-pipeline-list.view.help.description" : "Über eine Daten-Pipeline können einzelne Dokumente manuell direkt in einen Datenbereich importiert werden.",

        "data-pipeline-list.view.reindex.dialog.title" : "Neu indizieren",
        "data-pipeline-list.view.reindex.dialog.subtitle" : "Die Neuindizierung eines Datenbereichs kann ggf. einige Minuten dauern. Möchten Sie diesen Prozess wirklich starten?",
        "data-pipeline-list.view.reindex.dialog.confirm" : "Jetzt ausführen",
        "data-pipeline-list.view.reindex.dialog.cancel" : "Abbrechen",

        
        "data-pipeline-list.view.dialogs.delete.title" : "Möchten Sie den Datenbereich wirklich löschen?",
        "data-pipeline-list.view.dialogs.delete.description" : "Sie möchten den Datenbereich {name} löschen. Beim Löschen eines Datenbereichs werden auch alle Daten des Datenbereichs unwiderruflich gelöscht. Diese Aktion kann nicht rückgängig gemacht werden. Bitte bestätigen Sie deshalb, dass Sie nun fortfahren möchten, um den gesamten Datenbereich und alle damit verbunden Daten zu löschen.",
        "data-pipeline-list.view.dialogs.delete.actions.confirm" : "Bestätigen",
        "data-pipeline-list.view.dialogs.delete.actions.cancel" : "Abbrechen",

        "data-pipeline-list.view.dialogs.delete.help.uploadNotAvailable" : "Der Upload von Daten ist nicht möglich, da Ihnen mindestens eine der folgenden Berechtigungen fehlt: amentis-dxs-catalog-collectionsettings-read, amentis-dxs-catalog-schemas-read, amentis-dxs-integration-pipelines-upload"


    }
}
</i18n>