<template>
    <c-editable-item-list
        :title="$t('collection-access-rule-editor.component.list.title')"
        v-model="collectionAccessRules"
        :new-item="{ collectionId: null, createPermission: false, readPermission: true, updatePermission: false, deletePermission: false, importPermission: false, exportPermission: true, archivePermission: false  }"
        :readonly="readonly"
        :disabled="disabled"
        :header-style="headerStyle"
        :flat="flat"
        :return-object="true"
        :check-duplicate="(e1, e2) => (e1.collectionId === e2.collectionId)"
        :validate-item="validateItem">
  
   <template v-slot:item-list-head>
       <thead>
           <tr>
            <th colspan="2"></th>
            <th>{{$t('collection-access-rule-editor.component.list.cols.collectionId')}}</th>
            <template v-if="largeScreen">
                <th>{{$t('collection-access-rule-editor.component.list.cols.name')}}</th>
                <th>{{$t('collection-access-rule-editor.component.list.cols.read')}}</th>
                <th>{{$t('collection-access-rule-editor.component.list.cols.create')}}</th>
                <th>{{$t('collection-access-rule-editor.component.list.cols.update')}}</th>
                <th>{{$t('collection-access-rule-editor.component.list.cols.delete')}}</th>
                <th>{{$t('collection-access-rule-editor.component.list.cols.import')}}</th>
                <th>{{$t('collection-access-rule-editor.component.list.cols.export')}}</th>
                <th>{{$t('collection-access-rule-editor.component.list.cols.archive')}}</th>
            </template>
            <template v-else>
                <th>{{$t('collection-access-rule-editor.component.list.cols.permissions')}}</th>
            </template>
           </tr>
       </thead>
   </template>

    <template v-slot:item-list-body-row="{item}">
        <td>{{item.collectionId}}</td>
        <template v-if="largeScreen">
            <td>{{getCollectionName(item)}}</td>
            <td><v-icon>{{getIcon(item.readPermission)}}</v-icon></td>
            <td><v-icon>{{getIcon(item.createPermission)}}</v-icon></td>
            <td><v-icon>{{getIcon(item.updatePermission)}}</v-icon></td>
            <td><v-icon>{{getIcon(item.deletePermission)}}</v-icon></td>
            <td><v-icon>{{getIcon(item.importPermission)}}</v-icon></td>
            <td><v-icon>{{getIcon(item.exportPermission)}}</v-icon></td>
            <td><v-icon>{{getIcon(item.archivePermission)}}</v-icon></td>
        </template>
         <template v-else>
             <td>{{getPermissionString(item)}}</td>
         </template>
    </template>

    <!-- Customize the editor's header -->
    <template v-slot:item-list-editor-title="{isNewItem}">
        <div class="title" v-if="isNewItem">{{$t('collection-access-rule-editor.component.editor.newItemTitle', $i18n.locale)}}</div>
        <div class="title" v-else>{{$t('collection-access-rule-editor.component.editor.editItemTitle', $i18n.locale)}}</div>
        <div class="subtitle-1">{{$t('collection-access-rule-editor.component.editor.subtitle', $i18n.locale)}}</div>  
    </template>

    <template v-slot:item-list-editor="{item,  isNewItem}">
        <v-card tile flat>
            <v-divider />
            <v-subheader>{{$t('collection-access-rule-editor.component.editor.sections.collection.title')}}</v-subheader>
            <v-card-text>
                <c-item-selector 
                    v-model="item.collectionId"
                    :items="collectionSettings"
                    :label="$t('collection-access-rule-editor.component.item.collectionId.label', $i18n.locale)"
                    :disabled="!isNewItem"
                    filled
                    :rules="[v => !!v || $t('collection-access-rule-editor.component.item.collectionId.validation.error', $i18n.locale)]"
                    />
            </v-card-text>
            <v-divider />
            <v-subheader>{{$t('collection-access-rule-editor.component.editor.sections.permissions.title')}}</v-subheader>
            <v-list subheader two-line flat>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox color="accent" v-model="item.readPermission" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('collection-access-rule-editor.component.editor.fields.read.title')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('collection-access-rule-editor.component.editor.fields.read.subtitle')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox color="accent" v-model="item.createPermission" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('collection-access-rule-editor.component.editor.fields.create.title')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('collection-access-rule-editor.component.editor.fields.create.subtitle')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox color="accent" v-model="item.updatePermission" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('collection-access-rule-editor.component.editor.fields.update.title')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('collection-access-rule-editor.component.editor.fields.update.subtitle')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox color="accent" v-model="item.deletePermission" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('collection-access-rule-editor.component.editor.fields.delete.title')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('collection-access-rule-editor.component.editor.fields.delete.subtitle')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox color="accent" v-model="item.importPermission" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('collection-access-rule-editor.component.editor.fields.import.title')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('collection-access-rule-editor.component.editor.fields.import.subtitle')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox color="accent" v-model="item.exportPermission" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('collection-access-rule-editor.component.editor.fields.export.title')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('collection-access-rule-editor.component.editor.fields.export.subtitle')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-action>
                        <v-checkbox color="accent" v-model="item.archivePermission" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('collection-access-rule-editor.component.editor.fields.archive.title')}}</v-list-item-title>
                        <v-list-item-subtitle>{{$t('collection-access-rule-editor.component.editor.fields.archive.subtitle')}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

            </v-list>
        </v-card>
    </template>

  </c-editable-item-list>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {

    name: 'collection-access-rule-editor',
    
    /**
     * Properties
     */
    props: {

        items: {
            type: Array,
            required: true,
            default: () => []
        },

        readonly : {
            type: Boolean,
            required: false,
            default: false 
        },

        disabled : {
            type: Boolean,
            required: false,
            default: false 
        },

        /**
        * Available options are 'default', 'full' and 'lite'
        */
        headerStyle : {
            type: String,
            default: 'default'
        },

        flat : {
            type: Boolean,
            required: false,
            default: false
        },

        collectionSettings: {
            type: Array,
            required: true,
            default: () => []
        }

    },

    model: {
        prop: 'items',
        event: 'input'
    },

    data() {

        return {
            model: cloneDeep(this.items)
        };
    },

    watch: {
        items(newValue) {
            this.model = cloneDeep(newValue);
        }
    },

    methods: {

        getIcon(state) {

            return (state) ? 'check_box' : 'check_box_outline_blank'
        },

        getCollectionName(item) {

            if (item) {
                for (const c of this.collectionSettings) {
                    if (c.collectionId === item.collectionId) {
                       return  this.$dxs.i18n.localizedName(c, this.$i18n.locale);
                    }
                }
            }

            return '???';
            
        },

        getPermissionString(item) {
            let permissions = [];
            
            permissions.push(item && item.createPermission ? this.$t('collection-access-rule-editor.component.list.cols.create') : '-');
            permissions.push(item && item.readPermission ? this.$t('collection-access-rule-editor.component.list.cols.read')  : '-');
            permissions.push(item && item.updatePermission ? this.$t('collection-access-rule-editor.component.list.cols.update')  : '-');
            permissions.push(item && item.deletePermission ? this.$t('collection-access-rule-editor.component.list.cols.delete')  : '-');
            permissions.push(item && item.importPermission ? this.$t('collection-access-rule-editor.component.list.cols.import')  : '-');
            permissions.push(item && item.exportPermission ? this.$t('collection-access-rule-editor.component.list.cols.export')  : '-');
            permissions.push(item && item.archivePermission ? this.$t('collection-access-rule-editor.component.list.cols.archive')  : '-');

            return permissions.join(' / ');
        },

        validateItem({item}) {

            if (item) {
                if (!this.isAnyPermissionSelected(item)) {
                    return this.$t('collection-access-rule-editor.item.validation.selectAtLeastOnePermission', this.$i18n.locle)
                }
            }

            return '';
        }, 

        isAnyPermissionSelected(item) {
            return item.createPermission || item.readPermission || item.updatePermission || item.deletePermission || item.importPermission || item.exportPermission || item.archivePermission
        },


        fireInput() {
            this.$emit('input', cloneDeep(this.model));
        }
    },

    computed: {
        largeScreen() {
            return this.$vuetify.breakpoint.mdAndUp;
        },

        collectionAccessRules : {

            get() {
                return this.model;
            },

            set(value) {
                this.model = value;
                this.fireInput();
            }
        },

        
    }
}
</script>

<i18n>
{
    "en" : {
        "collection-access-rule-editor.component.list.title" : "Collection Access Rules",
        "collection-access-rule-editor.component.list.cols.collectionId" : "ID",
        "collection-access-rule-editor.component.list.cols.name" : "Collection",
        "collection-access-rule-editor.component.list.cols.create" : "Create",
        "collection-access-rule-editor.component.list.cols.read" : "Read",
        "collection-access-rule-editor.component.list.cols.update" : "Update",
        "collection-access-rule-editor.component.list.cols.delete" : "Delete",
        "collection-access-rule-editor.component.list.cols.permissions" : "Permissions",
        "collection-access-rule-editor.component.list.cols.import" : "Import",
        "collection-access-rule-editor.component.list.cols.export" : "Export",
        "collection-access-rule-editor.component.list.cols.archive" : "Archive",

        "collection-access-rule-editor.component.editor.newItemTitle" : "Create new Access Rule",
        "collection-access-rule-editor.component.editor.editItemTitle" : "Edit Access Rule",
        "collection-access-rule-editor.component.editor.subtitle" : "The system will apply access rules in order to control access to the data stored in the selected collection.",

        "collection-access-rule-editor.component.editor.sections.collection.title" : "Select Collection",
        "collection-access-rule-editor.component.editor.sections.permissions.title" : "Select Permissions",

        "collection-access-rule-editor.component.item.collectionId.label" : "Collection",
        "collection-access-rule-editor.component.item.collectionId.validation.error" : "The collection must not be empty. Please select a collection from the list, in order to proceed.",

        "collection-access-rule-editor.component.editor.fields.create.title" : "Create",
        "collection-access-rule-editor.component.editor.fields.create.subtitle" : "Users may add new documents to this collection.",
        "collection-access-rule-editor.component.editor.fields.read.title" : "Read",
        "collection-access-rule-editor.component.editor.fields.read.subtitle" : "Users  may read existing documents from this collection.",
        "collection-access-rule-editor.component.editor.fields.update.title" : "Update",
        "collection-access-rule-editor.component.editor.fields.update.subtitle" : "Users may modify existing documents in this collection.",
        "collection-access-rule-editor.component.editor.fields.delete.title" : "Delete",
        "collection-access-rule-editor.component.editor.fields.delete.subtitle" : "Users may delete existing documents from this collection.",
        "collection-access-rule-editor.component.editor.fields.import.title" : "Import",
        "collection-access-rule-editor.component.editor.fields.import.subtitle" : "Users may use upload in order to import documents into this collection.",
        "collection-access-rule-editor.component.editor.fields.export.title" : "Export",
        "collection-access-rule-editor.component.editor.fields.export.subtitle" : "Users may export documents from this collection to CSV, JSON, etc.",
        "collection-access-rule-editor.component.editor.fields.archive.title" : "Archive",
        "collection-access-rule-editor.component.editor.fields.archive.subtitle" : "Users may archive documents from this collection to external storage.",

        "collection-access-rule-editor.item.validation.selectAtLeastOnePermission" : "It is not admissible to create a rule without permissions. Please select at least one permission in order to proceed."
    },

    "de" : {
        "collection-access-rule-editor.component.list.title" : "Zugriffsregeln für Datenbereiche",
        "collection-access-rule-editor.component.list.cols.collectionId" : "ID",
        "collection-access-rule-editor.component.list.cols.name" : "Datenbereich",
        "collection-access-rule-editor.component.list.cols.create" : "Anlegen",
        "collection-access-rule-editor.component.list.cols.read" : "Lesen",
        "collection-access-rule-editor.component.list.cols.update" : "Ändern",
        "collection-access-rule-editor.component.list.cols.delete" : "Löschen",
        "collection-access-rule-editor.component.list.cols.permissions" : "Berechtigungen",
        "collection-access-rule-editor.component.list.cols.import" : "Importieren",
        "collection-access-rule-editor.component.list.cols.export" : "Exportieren",
        "collection-access-rule-editor.component.list.cols.archive" : "Archivieren",

        "collection-access-rule-editor.component.editor.newItemTitle" : "Neue Zugriffsregel anlegen",
        "collection-access-rule-editor.component.editor.editItemTitle" : "Zugriffsregel bearbeiten",
        "collection-access-rule-editor.component.editor.subtitle" : "Das System verwendet diese Regel, um den Zugriff auf den ausgewählten Datenbereich zu steuern.",

        "collection-access-rule-editor.component.editor.sections.collection.title" : "Datenbereich auswählen",
        "collection-access-rule-editor.component.editor.sections.permissions.title" : "Berechtigungen auswählen",

        "collection-access-rule-editor.component.item.collectionId.label" : "Datenbereich",
        "collection-access-rule-editor.component.item.collectionId.validation.error" : "Der Datenbereich darf nicht leer sein. Bitte wählen Sie einen Datenbereich aus der Liste, um fortzufahren.",


        "collection-access-rule-editor.component.editor.fields.create.title" : "Anlegen",
        "collection-access-rule-editor.component.editor.fields.create.subtitle" : "Anwender dürfen neue Dokumente in diesem Datenbereich anlegen.",
        "collection-access-rule-editor.component.editor.fields.read.title" : "Lesen",
        "collection-access-rule-editor.component.editor.fields.read.subtitle" : "Anwender dürfen existierende Dokumente in diesem Datenbereich lesen.",
        "collection-access-rule-editor.component.editor.fields.update.title" : "Ändern",
        "collection-access-rule-editor.component.editor.fields.update.subtitle" : "Anwender dürfen existierende Dokumente in diesem Datenbereich verändern.",
        "collection-access-rule-editor.component.editor.fields.delete.title" : "Löschen",
        "collection-access-rule-editor.component.editor.fields.delete.subtitle" : "Anwender dürfen existierende Dokumente in diesem Datenbereich dauerhaft löschen.",
        "collection-access-rule-editor.component.editor.fields.import.title" : "Importieren",
        "collection-access-rule-editor.component.editor.fields.import.subtitle" : "Anwender dürfen dem Datenbereiche Dokumente über den Datei-Upload hinzufügen.",
        "collection-access-rule-editor.component.editor.fields.export.title" : "Exportieren",
        "collection-access-rule-editor.component.editor.fields.export.subtitle" : "Anwender dürfen Dokumente aus diesem Datenbereich in Dateien exportieren (CSV, JSON, etc.)",
        "collection-access-rule-editor.component.editor.fields.archive.title" : "Archivieren",
        "collection-access-rule-editor.component.editor.fields.archive.subtitle" : "Anwender dürfen Dokumente aus diesem Datenbereich archivieren.",

        "collection-access-rule-editor.item.validation.selectAtLeastOnePermission" : "Eine Regel muss mindestens eine Berechtigung besitzen. Bitte wählen Sie mindestens eine der Berechtigungen aus, um fortzufahren."
    }
}
</i18n>