<template>
    <div>
        <c-query-configuration-panel 
            v-model="selectedListTemplate" 
            @list-settings-changed="refresh"
            @list-settings-reset="onResetTemplate"
            :title="$t('collection-distribution.view.title')"
            :collection-settings="collectionSettings"
            :schema="schema"
            :templates="listTemplates"
            :disabled="false"
            hide-sort-settings
            hide-view-settings
            >

            <!-- Extend Toolbar -->
            <template v-slot:toolbar-items-prepend="{disabled}">
                
                <v-btn icon @click="refresh" :disabled="disabled">
                    <v-icon>refresh</v-icon>
                </v-btn>
            
            </template>


            <template v-slot:content>
                <template v-if="formSubmitted">
                    <!-- ************************************************************* -->
                    <!-- TIME-BASED DISTRIBUTION                                       -->
                    <!-- ************************************************************* -->
                    <template v-if="!timeDimensionsEmpty">
                        <c-section :title="$t('collection-distribution.view.sections.histogram.title')"
                                    :subtitle="$t('collection-distribution.view.sections.measures.subtitle', {count: dimensionTotalItemCount})" 
                                    class="grey lighten-3">

                            <div class="text-center">
                                <v-btn-toggle  tile color="grey" v-model="selectedTimeDimension">
                                    <v-btn v-for="(item, index) of timeDimensions" 
                                        :key="'timeDimensionBtn_' + index"
                                        :value="item.name"
                                        @click="clickedTimeDimension(item.name)">
                                        {{$dxs.i18n.localizedName(item, $i18n.locale)}}
                                    </v-btn>
                                </v-btn-toggle>
                            </div>
                            
                            <v-container fluid class="grey lighten-3">
                                <v-row justify="center">
                                    <v-col>
                                        <v-lazy><dxs-data-distribution-histogram-card :title="$t('collection-distribution.view.sections.histogram.card.title')"  
                                        :items="histogramItems" :series="histogramDimensions" :time-scales="timeScalesOrDefault" v-model="selectedTimeInterval" @click="refreshHistogram">
                                        <template v-slot:card-header v-if="histogramIntervalCount >= maxHistogramIntervals">
                                            <v-banner single-line 
                                            color="accent" 
                                            icon="info" 
                                            icon-color="white" 
                                            class="white--text">
                                                {{$t("data-distribution-histogram-card.component.histogram.info", {maxIntervals: maxHistogramIntervals})}}
                                            </v-banner>
                                        </template>
                                        </dxs-data-distribution-histogram-card>
                                        </v-lazy>
                                    </v-col> 
                                </v-row>
                            </v-container>
                        </c-section>
                    </template>
                    <template v-else>
                        <v-sheet>
                            <v-card tile flat class="grey lighten-3" >
                                <v-card-text>
                                    <p />
                                    <div class="text-center"><v-icon color="accent" large>warning</v-icon></div>
                                    <div class="title text-center">{{$t('collection-distribution.view.missingDatetimeAttribute.title')}}</div>
                                    <div class="text-center">{{$t('collection-distribution.view.missingDatetimeAttribute.subtitle')}}</div>
                                </v-card-text>
                            </v-card>
                        </v-sheet>
                    </template>

                    <!-- ************************************************************* -->
                    <!-- DIMENSIONS                                                    -->
                    <!-- ************************************************************* -->
                    
                    <c-section 
                        :title="$t('collection-distribution.view.sections.dimensions.title')" 
                        :subtitle="$t('collection-distribution.view.sections.dimensions.subtitle', {count: dimensionTotalItemCount})" 
                        class="grey lighten-2"
                    >
                        <v-container fluid>
                            <v-row justify="center">
                                <v-col sm="6" cols="12" :class="'text-' + ($vuetify.smAndUp ? 'right' : 'center')">
                                    <v-btn-toggle  tile color="grey" v-model="maxCategories" >
                                        <v-btn :value="5" >Top 5</v-btn>
                                        <v-btn :value="10">Top 10</v-btn>
                                        <v-btn :value="20">Top 20</v-btn>
                                    </v-btn-toggle>
                                </v-col>
                                <v-col sm="6" cols="12" :class="'text-' + ($vuetify.smAndUp ? 'left' : 'center')">
                                    <v-btn-toggle  tile color="grey" v-model="viewType">
                                        <v-btn value="treemap"><v-icon color="grey">dashboard</v-icon></v-btn>
                                        <v-btn value="pie" ><v-icon color="grey">pie_chart</v-icon></v-btn>
                                        <v-btn value="donut"><v-icon color="grey">donut_large</v-icon></v-btn>
                                        
                                    </v-btn-toggle>
                                    
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-container fluid >
                            <v-row justify="center" >
                                <v-col cols="12" md="6" lg="4"  v-for="(item, index) of categoryValueDistributionItems" :key="'dimensionValDistribution_' + index" >
                                    <dxs-data-distribution-card 
                                        tile 
                                        :items="getItems(item, maxCategories)" 
                                        :title="$dxs.i18n.localizedName(item, $i18n.locale)"
                                        :subtitle="$t('collection-distribution.view.sections.dimensions.card.subtitle', {count: maxCategories})" 
                                        :chart-type="viewType" 
                                        hide-chart-type-selector 
                                    >
                                        <template v-slot:actions>
                                            <v-divider />
                                            <v-card-actions>
                                                <v-btn :to="createDimensionDrillDownPath(item)"  block text>Details</v-btn>
                                            </v-card-actions>
                                        </template>
                                    </dxs-data-distribution-card>
                                </v-col>
                            </v-row>
                        </v-container>

                    </c-section>

                    <!-- ************************************************************* -->
                    <!-- METRICS                                                       -->
                    <!-- ************************************************************* -->

                    <c-section 
                        :title="$t('collection-distribution.view.sections.measures.title')" 
                        :subtitle="$t('collection-distribution.view.sections.measures.subtitle', {count: dimensionTotalItemCount})" 
                        class="grey lighten-4">
                        <v-container fluid>
                            <v-row justify="center" v-if="!measuresEmpty">
                                <v-col cols="12" md="6" lg="4" v-for="(item, index) of quartileDistributionItems" :key="'measureCol_' + index">
                                    <dxs-data-distribution-metric-card
                                        :value="{name: item.name, values: item.quartiles }"
                                        :title="$dxs.i18n.localizedName(item, $i18n.locale)"
                                    />
                                </v-col> 
                            </v-row>
                            <v-row justify="center" v-else>
                                <v-col cols="12" md="6" lg="4">
                                    <v-card tile class="grey lighten-3 text-center pa-5">
                                        <v-card-text class="text-center">
                                        <v-icon x-large color="grey lighten-1">domain_disabled</v-icon> 
                                            <p />
                                            <div class="text-h6 grey--text">{{$t('collection-distribution.view.sections.measures.empty.title')}}</div>
                                            <div class="text-subtitle-1 grey--text">{{$t('collection-distribution.view.sections.measures.empty.subtitle')}}</div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                        
                    </c-section>
                </template>
                <template v-else>
                    <dxs-send-query-hint-banner />
                </template>

            </template>

            
        </c-query-configuration-panel>

        
    </div>
</template>


<script>
import chroma from "chroma-js";

import { newInstance as listTemplateDataMixin }  from '@/core/mixins/list-template-data/list-template-data.mixin';
import { newInstance as collectionSettingsDataMixin }   from '@/core/mixins/collection-settings-data/collection-settings-data.mixin';

import { isEmpty } from 'lodash';
import { get } from 'lodash';

import CollectionHistogramDistributionModule from '@/core/store/modules/collections/collection-histogram-distribution.module';
import CollectionMeasuresDistributionModule from '@/core/store/modules/collections/collection-measures-distribution.module';
import CollectionCategoriesDistributionModule from '@/core/store/modules/collections/collection-categories-distribution.module';

import DataDistributionCardComponent from '@/core/components/data-exploration/distribution/data-distribution-card.component';
import DataDistributionMetricCardComponent from '@/core/components/data-exploration/distribution/data-distribution-metric-card.component';
import DataDistributionHistogramCardComponent from '@/core/components/data-exploration/distribution/data-distribution-histogram-card.component';

import SendQueryHintBannerComponent from '@/core/components/collections/send-query-hint-banner.component';

const HISTOGRAM_MODULE_ID = 'collection-distribution-histogram';
const MEASURES_MODULE_ID = 'collection-distribution-measures';
const DIMENSION_MODULE_ID = 'collection-distribution-dimensions';

const DEFAULT_TIME_SCALE = [
    { name: '10s', value: 10, unit: 's' },
    { name: '15s', value: 15, unit: 's' },
    { name: '30s', value: 30, unit: 's' },
    { name: '1m',  value: 1, unit: 'm' },
    { name: '15m', value: 15, unit: 'm' },
    { name: '30m', value: 30, unit: 'm' },
    { name: '1h', value: 1, unit: 'h' },
    { name: '2h', value: 2, unit: 'h' },
    { name: '3h', value: 3, unit: 'h' },
    { name: '4h', value: 4, unit: 'h' },
    { name: '6h', value: 6, unit: 'h' },
    { name: '12h', value: 12, unit: 'h' },
    { name: '14h', value: 24, unit: 'h' },
    
]

/**
 * A dashboard which focuses on the distribution of data in a single collection.
 */
export default {

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    components : {
        'dxs-data-distribution-card' : DataDistributionCardComponent,
        'dxs-data-distribution-metric-card' : DataDistributionMetricCardComponent,
        'dxs-data-distribution-histogram-card' : DataDistributionHistogramCardComponent,
        'dxs-send-query-hint-banner' : SendQueryHintBannerComponent
    },

    data() {
        return {
            viewType: 'treemap',

            selectedTimeDimension: "",
            selectedTimeInterval: null,
            
            maxCategoriesInternal : 5,
            formSubmitted: false,

            maxHistogramIntervals: 100
        };
    },

    mixins: [
        collectionSettingsDataMixin({moduleId: 'collection-distribution-collectionSettings'}),
        listTemplateDataMixin({moduleId: 'collection-distribution-listTemplates'})
        
    ],

    inject: ['progressIndicatorService', 'errorHandlerService', 'envContextService', 'userContextService'],

    methods : {

        
        /**
         * Initializes the view, e.g. by loading masterdata.
         */
        async initView( {collectionId, listTemplate} ) {
            this.progressIndicatorService.show();

            try {

                //await this.$store.dispatch(MODULE_NAME + '/resetView');
                
                //===================================================================================
                // Load Metadata
                //===================================================================================
                await this.loadCollectionSettings(collectionId);
                await this.loadListTemplates(this.collectionId);
                
                const timeDimension = get(this.timeDimensions, '[0]', null);
                if(timeDimension){
                    this.selectedTimeDimension = timeDimension.name;    
                }

                const timeInterval = get(this.timeScalesOrDefault, '[0]', null);
                if(timeInterval){
                    this.selectedTimeInterval = timeInterval;    
                }
                //===================================================================================
                // Init List Template Configuration based on Metadata
                //===================================================================================

                if (isEmpty(listTemplate)) {
                    // Use a default template in case the parameter has not been set.
                    const emptyTemplate = this.createEmptyListTemplate(this.schema);
                    this.selectedListTemplate  = emptyTemplate;
                } else {
                    this.selectedListTemplate = listTemplate;
                }

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        

        async onResetTemplate() {

            
            const emptyTemplate = this.createEmptyListTemplate(this.schema);
            console.log("+++  Reset Template: " + JSON.stringify(emptyTemplate));
            
            this.selectedListTemplate = emptyTemplate;

        }, 

        async refresh() {
            if (this.collectionId) {


                this.progressIndicatorService.show();
        
                try {

                    this.formSubmitted = true;

                    // Load analytical data.
                    this.progressIndicatorService.updateText(this.$t('collection-distribution.view.progress.time'));
                    await this.loadCollectionDateHistogramDistribution();
                    
                    this.progressIndicatorService.updateText(this.$t('collection-distribution.view.progress.dimensions'));
                    await this.loadCategoryValueDistributions();

                    this.progressIndicatorService.updateText(this.$t('collection-distribution.view.progress.measures'));
                    await this.loadQuartileDistributions();

                    
                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                }
            }
        },

        async refreshHistogram(){
            if (this.collectionId) {

                this.progressIndicatorService.show();
        
                try {
                    // Load analytical histogram data.
                    await this.loadCollectionDateHistogramDistribution();

                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                }
            }
        },

        async loadCollectionDateHistogramDistribution()  {

            await this.$store.dispatch(HISTOGRAM_MODULE_ID + '/loadCollectionDateHistogramDistribution', {
                collectionId: this.collectionId,
                filter: this.selectedListTemplate.filterSettings,
                histogramSize: this.maxHistogramIntervals,
                dateTimeDimensionId: this.selectedTimeDimension,
                timeInterval: this.selectedTimeInterval
            });

        },

        async loadCategoryValueDistributions() {

            await this.$store.dispatch(DIMENSION_MODULE_ID + '/loadCategoryValueDistributions', {
                collectionId: this.collectionId,
                categoryIdList: this.dimensions.map(e => e.name),
                filter: this.selectedListTemplate.filterSettings,
                size: this.maxCategoriesInternal
            });
        }, 

        async loadQuartileDistributions() {

            await this.$store.dispatch(MEASURES_MODULE_ID + '/loadQuartileDistributions', {
                collectionId: this.collectionId,
                measureIdList: this.measures.map(e => e.name),
                filter: this.selectedListTemplate.filterSettings
            });
        }, 

        sum(item) {

            let sum = 0;
            if (item && item.values) {
                for (const name in item.values) {
                    sum = sum + item.values[name];
                }
            }
            return sum;
        },

        /**
         * Adds further visualization-relevant information to the given items.
         */
        getItems(object, size) {

            // The palette is computed dynamically            
            let palette = this.colorScale.colors(size);

            // The original items.

            let items = [];  

            if (get(object, 'items', []).length < 1) {
                return items;
            }
            
            for (let i = 0; i < size; i++) {
                
                let e = get(object, 'items[' + i + ']', {name: '-', i18n: {}, value: 0.0, color: 'grey'} );
                if (!e.color) {
                    e.color = palette[i % palette.length];
                }

                items.push(e);
                //console.log(JSON.stringify(e));

            }

            const other = object.remainingItemsSummary;
            items.push({
                name: other.name,
                i18n: other.i18n,
                value: other.value,
                color: '#FCD299'
            });



            return items;
        },

        getMetrics(item){
            let values = {
               "q1": item.quartiles.get("q1"),
               "q2": item.quartiles.get("q2"),
               "q3": item.quartiles.get("q3"),
               "min": item.quartiles.get("min"),
               "max": item.quartiles.get("max")
           }

           return values;
        },

        clickedTimeDimension(item){
            this.selectedTimeDimension = item;
            this.refreshHistogram();
        },

        /**
         *  Retrieves the path for navigating to the dimension drilldown page.
         */
        createDimensionDrillDownPath(dimension) {
            const route = this.$router.resolve({
                name: 'collectionDimensionDistribution',
                params: {
                    collectionId : this.collectionId,
                    dimensionId: dimension.name,
                    listTemplate: this.selectedListTemplate
                }
            });
            return route.href;
        },

    },

    computed: {

        colorScale() {
            return chroma.bezier(['#005155', '#A5D3E3',]).scale().correctLightness();
        },


        collectionId() {
            return get(this.value, 'collectionId', null);
        },

        measures() {

            let measures =  this.schemaAttributeList.filter(e => this.$dxs.attributes.isMeasure(e) );

            return measures;

        },

        measuresEmpty() {

            return isEmpty(this.measures);
        },

        drillDownLevels() {

            let levels = [];

            levels.push({
                name: 'Collection',

                i18n: {
                    de : {
                        name: 'Gesamter Datenbereich'
                    },

                    en: {
                        name: 'Collection'
                    }
                },
            });

            levels.push(...this.dimensions);

            return levels;
            
        },

        dimensions() {
            const dimensions = [];      
            for (const attribute of this.schemaAttributeList) {

                if (this.$dxs.attributes.isDimension(attribute) && !this.$dxs.attributes.isTypeTemporal(attribute)) {
                    dimensions.push(attribute);
                }
                
            }   

            // The dimensions must be ordered according to the attribute definition in the schema.
            return dimensions.sort((first, second) => first.ordering - second.ordering);
        },

        timeDimensions() {
            
            let timeDimensions = [];
            for (const attribute of this.schemaAttributeList) {

                if (this.$dxs.attributes.isDimension(attribute) && this.$dxs.attributes.isTypeDateTime(attribute)) {
                    timeDimensions.push(attribute);
                }
                
            }  
            
            return timeDimensions;
        },

        timeDimensionsEmpty() {
            return isEmpty(this.timeDimensions);
        },

        histogramModel() {
            return this.$store.getters[HISTOGRAM_MODULE_ID + '/model']
        }, 

        categoryModel() {
            return this.$store.getters[DIMENSION_MODULE_ID + '/model']
        },

        quartileModel() {
            return this.$store.getters[MEASURES_MODULE_ID + '/quartileModel']
        },

        percentileModel() {
            return this.$store.getters[MEASURES_MODULE_ID + '/percentileModel']
        }, 

        /**
         * Time Scales are configured as part of the client settings in the collection settings. 
         */
        timeScales() {
            return get(this.collectionSettings, 'clientSettings.distribution.timeIntervals', [])
        },

        timeScalesOrDefault() {

            return isEmpty(this.timeScales) ? DEFAULT_TIME_SCALE : this.timeScales;
        },

        dimensionTotalItemCount() {
            const dimensionTotalItemCount = get(this.categoryModel, 'totalItemCount');
            return dimensionTotalItemCount; 
        },

        histogramIntervalCount() {
            return this.histogramItems.length;
        },
 
        categoryValueDistributionItems() {
            const items = get(this.categoryModel, 'categoryValueDistributionItems', []);
            
            return items;
        },

        quartileDistributionItems() {
            const items = get(this.quartileModel, 'quartileDistributionItems', []);
            
            return items;
        },

        percentileDistributionItems() {
            const items = get(this.percentileModel, 'percentileDistributionItems', []);
            
            return items;
        },

        maxCategories: {
            get() {
                return this.maxCategoriesInternal;
            },

            set(value) {
                this.maxCategoriesInternal = value > 0 ? value : 5;
                this.loadCategoryValueDistributions();
            }
        },

        all() {

            return [
                { name: 'A', value: 44.5 },
                { name: 'B', value: 22.5 },
                { name: 'C', value: 13.5 },
            ];
        },

        histogramItems() {
            const items = get(this.histogramModel, 'dateHistogramDistribution.items', []);
            return items;
        },

        histogramDimensions() {
            const series = get(this.histogramModel, 'dateHistogramDistribution.series', []);
            
            // The palette is computed dynamically            
            let palette = this.colorScale.colors(series.length);
            for (let i = 0; i < series.length; i++) {
                let serie = series[i];
                 
                if (!serie.color) {
                    serie.color =  palette[i % palette.length];
                }
            }

            return series;
        },

        histogramItemsPercent() {
            let items = [];

            for (const histogramItem of this.histogramItems) {

                let newItem = { name: histogramItem.name, values: {}};
                let total = 0;
                for (const key in histogramItem.values) {

                    newItem.values[key] = histogramItem.values[key];
                    total = total + histogramItem.values[key];
                }

                
                for (const key in newItem.values) {
                    newItem.values[key] = this.$dxs.math.percentOf(newItem.values[key], total);
                }
                

                items.push(newItem);
            }

            return items;
        },

        histogramItemsSum() {
            let sum = 0;
            this.histogramItems.forEach(e => sum = sum + e.values.Ok);
            return sum;
        },
    },

    async created() {
        
        // Dynamically register the vuex store module for managing the data.
        if (!this.$store.hasModule(DIMENSION_MODULE_ID)){
            this.$store.registerModule(DIMENSION_MODULE_ID, CollectionCategoriesDistributionModule);
        }
        if (!this.$store.hasModule(MEASURES_MODULE_ID)){
            this.$store.registerModule(MEASURES_MODULE_ID, CollectionMeasuresDistributionModule);
        }
        if (!this.$store.hasModule(HISTOGRAM_MODULE_ID)){
            this.$store.registerModule(HISTOGRAM_MODULE_ID, CollectionHistogramDistributionModule);
        }

        await this.initView(this.value);
    }

}
</script>

<i18n>
{
    "en" : {
        "collection-distribution.view.title" : "Results",
        "collection-distribution.view.showResults.title": "Show Results",

        "collection-distribution.view.sections.histogram.title" : "Time Based Distribution",
        "collection-distribution.view.sections.histogram.card.title" : "Distribution",

        "collection-distribution.view.sections.dimensions.title" : "Data Distribution By Dimension",
        "collection-distribution.view.sections.dimensions.subtitle" : "Based on {count} Items",
        "collection-distribution.view.sections.dimensions.card.subtitle" : "Top {count} Values and Remaining Values Sum",
        
        "collection-distribution.view.sections.measures.empty.title" : "Sorry, there are no Measures in this Collection",
        "collection-distribution.view.sections.measures.empty.subtitle" : "This collection does not contain measures for displaying.",
        "collection-distribution.view.sections.measures.title" : "Metric Value Ranges",
        "collection-distribution.view.sections.measures.subtitle" : "Based on {count} Items",

        "collection-distribution.view.progress.time" : "Value Distribution by Time",
        "collection-distribution.view.progress.dimensions" : "Value Distribution by Dimension",
        "collection-distribution.view.progress.measures" : "Value Distribution by Measure",

        "collection-distribution.view.missingDatetimeAttribute.title" : "Time Based Distribution is not Available",
        "collection-distribution.view.missingDatetimeAttribute.subtitle" : "This collection does not contain datetime-related attributes. Therefore it is not possible to create a time based distribution.",

        "data-distribution-histogram-card.component.histogram.info" : "The first {maxIntervals} time intervals are shown. Perhaps more time intervals are available. Please change the filter settings to view them."


    },

    "de" : {
        "collection-distribution.view.title" : "Ergebnis",
        "collection-distribution.view.showResults.title": "Ergebnisse anzeigen",

        "collection-distribution.view.sections.histogram.title" : "Verteilung nach Zeitraum",
        "collection-distribution.view.sections.histogram.card.title" : "Häufigkeit",

        "collection-distribution.view.sections.dimensions.title" : "Datenverteilung nach Dimension",
        "collection-distribution.view.sections.dimensions.subtitle" : "Basierend auf {count} Ergebnissen",
        "collection-distribution.view.sections.dimensions.card.subtitle" : "Top {count} Werte und Summe restlicher Werte",

        "collection-distribution.view.sections.measures.empty.title" : "Keine Metriken vorhanden",
        "collection-distribution.view.sections.measures.empty.subtitle" : "Leider sind in diesem Datenbereich sind keine Metriken zur Anzeige enthalten.",
        "collection-distribution.view.sections.measures.title" : "Wertebereiche Metriken",
        "collection-distribution.view.sections.measures.subtitle" : "Basierend auf {count} Ergebnissen",

        "collection-distribution.view.progress.time" : "Verteilung nach Zeit",
        "collection-distribution.view.progress.dimensions" : "Verteilung nach Dimension",
        "collection-distribution.view.progress.measures" : "Verteilung nach Kennzahl",

        "collection-distribution.view.missingDatetimeAttribute.title" : "Verteilung nach Zeitraum nicht verfügbar",
        "collection-distribution.view.missingDatetimeAttribute.subtitle" : "In diesem Datenbereich gibt es keine Datumsfelder mit Uhrzeit. Es ist deshalb leider nicht möglich, eine Verteilung nach Zeitraum zu erstellen.",
    
        
        "data-distribution-histogram-card.component.histogram.info" : "Es werden die {maxIntervals} ersten Zeitintervalle angezeigt. Möglicherweise sind mehr Zeitintervalle vorhanden, ändern Sie hierzu bitte die Filtereinstellungen."
    }
}
</i18n>