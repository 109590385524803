<template>
  <div class="secondary darken-1">
    <v-breadcrumbs>
      <v-breadcrumbs-item :disabled="true">{{$t("Katalog")}}</v-breadcrumbs-item>
      <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{name: 'mappingJobTemplatesList'}" :disabled="false">{{ $t("Mapping Job Templates") }} </v-breadcrumbs-item>
      <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
      <v-breadcrumbs-item>{{mappingJobTemplate | localizedName($i18n.locale)}}</v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card tile>

        <c-sticky-content>
        <v-toolbar color="white" flat>
            <v-toolbar-items><v-btn icon @click="cancel"><v-icon>arrow_back</v-icon></v-btn></v-toolbar-items>
            <div class="text-h6">{{ $t("mapping-job-templates-editor.view.title") }}: {{ mappingJobTemplate | localizedName($i18n.locale) }}
            </div>
            <v-spacer />
            <v-toolbar-items><v-btn text @click="save" :disabled="!hasEditRole || !pageSizeValid">{{$t("actions.save.label")}}</v-btn>
            </v-toolbar-items><v-toolbar-items><v-btn text @click="cancel" :disabled="!hasEditRole">{{$t("actions.cancel.label")}}</v-btn></v-toolbar-items>
        </v-toolbar>
        <v-divider />
        </c-sticky-content>

        <!--Common Information -->
            <v-tabs 
                v-model="selectedTabIndex" 
                :vertical="$vuetify.breakpoint.mdAndUp" 
                color="grey" 
                :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
                slider-color="grey" 
                show-arrows 
                :fixed-tabs="!$vuetify.breakpoint.mdAndUp"
            >
            <v-tab :key="0">{{$t('mapping-job-templates-editor.view.tabs.general')}}</v-tab>
            <v-tab :key="1">{{$t('mapping-job-templates-editor.view.tabs.filtering')}}</v-tab>
            <v-tab :key="2">{{$t('mapping-job-templates-editor.view.tabs.mapping')}}</v-tab>
            <v-tab :key="3">{{$t('mapping-job-templates-editor.view.tabs.custData')}}</v-tab>
            <v-tab :key="4">{{$t('mapping-job-templates-editor.view.tabs.json')}}</v-tab>

            <v-tabs-items v-model="selectedTabIndex">
                <!-- General -->
                <v-tab-item :key="0">
                    <v-card tile flat>
                        <v-card-text class="text-h5">{{$t('mapping-job-templates-editor.view.tabs.general')}}</v-card-text>
                        <v-divider />
                        <v-card-text>
                            <div class="text-h6">{{$t('mapping-job-templates-editor.view.tabs.general.title')}}</div>
                            <div>{{$t('mapping-job-templates-editor.view.tabs.general.description')}}</div>
                        </v-card-text>
                        <v-container fluid>
                            <v-row justify="center">
                                <v-col cols="12">
                                    <v-text-field :value="mappingJobTemplate.name" disabled filled :label="$t('mapping-job-templates-editor.view.sections.collections.identifier')" />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field :value="mappingJobTemplate.sourceCollectionId" disabled filled :label="$t('mapping-job-templates-editor.view.sections.collections.sourceCollection')" />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field :value="mappingJobTemplate.targetCollectionId" disabled filled :label="$t('mapping-job-templates-editor.view.sections.collections.targetCollection')" />
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-divider />
                        <v-card-text>
                            <div class="text-h6">{{$t('mapping-job-templates-editor.view.tabs.general.page-size')}}</div>
                            <div>{{$t('mapping-job-templates-editor.view.tabs.general.page-size.description')}}</div>
                        </v-card-text>
                        <v-container fluid>   
                            <v-form v-model="pageSizeValid">
                                <v-text-field
                                    v-model="pageSize"
                                    :rules="[
                                        v => v.match(/^[0-9]+$/) || $t('mapping-job-templates-editor.view.tabs.general.page-size.validation.onlyNumbers'),
                                        v => !isNaN(parseFloat(v)) && v >= 5 && v <= 750 || $t('mapping-job-templates-editor.view.tabs.general.page-size.validation.notInRange'),
                                    ]"
                                    single-line
                                    type="number"
                                    :label="$t('mapping-job-templates-editor.view.tabs.general.page-size')"
                                    filled
                                ></v-text-field>
                            </v-form>
                        </v-container>
                    </v-card>
                </v-tab-item>

                <!-- Filter -->
                <v-tab-item :key="1">
                    <v-card tile flat>
                        <v-card-text class="text-h5">{{$t('mapping-job-templates-editor.view.tabs.filtering')}}</v-card-text>
                        <v-divider />
                        <v-card-text>
                            <div class="text-h6">{{$t('mapping-job-templates-editor.view.tabs.filtering.title')}}</div>
                            <div>{{$t('mapping-job-templates-editor.view.tabs.filtering.description')}}</div>
                        </v-card-text>
                        <c-filter-editor
                            v-model="filter"
                            filled
                            :disabled="!hasEditRole"
                        />
                    </v-card>
                </v-tab-item>
                <!-- Mapping -->
                <v-tab-item :key="2">
                    <v-card tile flat>
                        <v-card-text class="text-h5">{{$t('mapping-job-templates-editor.view.tabs.mapping')}}</v-card-text>
                        <v-divider />
                        <v-card-text>
                            <div class="text-h6">{{$t('mapping-job-templates-editor.view.tabs.mapping.title')}}</div>
                            <div>{{$t('mapping-job-templates-editor.view.tabs.mapping.description')}}</div>
                        </v-card-text>
                        <dxs-target-attribute-mapping-list
                            :items="mappings"
                            :source="source"
                            :target="target"
                            :custData="custData"
                            @item-updated="addTargetAttributeMapping($event.item, $event.index)"
                            @item-removed="removeTargetAttributeMapping($event.item, $event.index)"
                            :disabled="!hasEditRole"
                        />
                    </v-card>
                </v-tab-item>

                <!-- Customer specific Data -->
                 <v-tab-item :key="3">
                    <v-card tile flat class="pl-5 pr-5">
                    <v-card-title>{{$t('mapping-job-templates-editor.component.sections.custData.title')}}</v-card-title>
                    <v-divider />
                    <v-card-text>
                        {{$t('mapping-job-templates-editor.component.sections.custData.subtitle')}}
                        </v-card-text>
                        <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <c-params-editor 
                                    title="Kundenspezifische Parameter" 
                                    header-style="lite" 
                                    flat 
                                    :items="custDataItems"
                                    @item-updated="onCustDataUpdated" 
                                    @item-removed="onCustDataRemoved" 
                                    />
                            </v-col>
                        </v-row>
                        </v-container>
                    </v-card>
                 </v-tab-item>

                 <v-tab-item :key="4">
                    <c-json-item-editor-page v-model="response" />
                 </v-tab-item>
            </v-tabs-items>
        </v-tabs>
    </v-card>
  </div>
</template>

<script>
import Roles from '@/roles';
import { get, set, cloneDeep } from 'lodash';
import TargetAttributMappingListComponent from '@/core/components/mapping-job-templates/target-attribute-mapping-list.component.vue';

const MODULE_NAME = "mappingJobTemplatesEditor";

export default {
  name: "mapping-job-templates-editor",

  props: {
    value: {
      type: Object,
      required: false,
      validator: (value) => value.name
    },

    options: {
      type: Object,
      required: false,
    },
  },

  inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

  components: {
      'dxs-target-attribute-mapping-list' : TargetAttributMappingListComponent
  },

  data: () => ({
      selectedTabIndex: 0,

      pageSizeValid: true
  }),


  methods: {
    async save() {

      this.progressIndicatorService.show();
      try {
          await this.$store.dispatch(MODULE_NAME + "/updateMappingJobTemplate", { name: this.name })
      } catch (error) {
          this.errorHandlerService.handleError(error);
      } finally {
          this.progressIndicatorService.hide();
      }
    },

    async refresh() {
        this.progressIndicatorService.show();
        try {

            // First reset ...
            await this.$store.dispatch(MODULE_NAME + "/resetData", { name: this.name })

            // ... then reload
            await this.$store.dispatch(MODULE_NAME + "/loadData", { name: this.name })
        } catch (error) {
            this.errorHandlerService.handleError(error);
        } finally {
            this.progressIndicatorService.hide();
        }
    },

    async addTargetAttributeMapping(mapping, index) {

        this.progressIndicatorService.show();
        try {
            // ... then reload
            await this.$store.dispatch(MODULE_NAME + "/addMapping", { mapping, index })
        } catch (error) {
            this.errorHandlerService.handleError(error);
        } finally {
            this.progressIndicatorService.hide();
        }
    },

    async removeTargetAttributeMapping(mapping, index) {

        this.progressIndicatorService.show();
        try {
            // ... then reload
            await this.$store.dispatch(MODULE_NAME + "/removeMapping", { mapping, index })
        } catch (error) {
            this.errorHandlerService.handleError(error);
        } finally {
            this.progressIndicatorService.hide();
        }
    },

    onCustDataUpdated(event) {

            this.$log.debug(JSON.stringify(event));
            
            if (event && event.item) {
                let clone = cloneDeep(this.custData);
                clone[event.item.name] = event.item.value;

                this.custData = clone;
            }

        },

    onCustDataRemoved(event) {
            
        this.$log.debug(JSON.stringify(event));


        if (event && event.item) {
            let clone = cloneDeep(this.custData);
            delete clone[event.item.name];

            this.custData = clone;
        }
    },


    cancel() {
      this.$router.push({
        name: "mappingJobTemplatesList",
      });
    },
  },

  computed: {

    response() {
      return this.$store.getters[MODULE_NAME + "/response"];
    },

    // ==============================================================================
    // Mapping Template
    // ==============================================================================

    mappingJobTemplate() {
      return this.response.mappingJobTemplate ? this.response.mappingJobTemplate : {};
    },

    source() {
        return this.response.source ?  this.response.source  : {};
    },

    target() {
        return this.response.target ?  this.response.target  : {};
    },

    pageSize: {
        get(){
            return this.mappingJobTemplate.pageSize
        },
        set(pageSize){
            this.$store.dispatch(MODULE_NAME + "/updatePageSize", pageSize)
        }
        
    },

    mappings: {
      get() {
          return this.mappingJobTemplate.mappings ? this.mappingJobTemplate.mappings : [];
      },

    },

    filter: {

        get() {
            return this.mappingJobTemplate.filter ? this.mappingJobTemplate.filter : {
                filterQuery : '*',
                filterQueryLanguage: 'SIMPLE',
                facetFilters: [],
                rangeFilters: []
            };
        },

        set(value) {
            this.$store.dispatch(MODULE_NAME + "/updateFilter", value)
        }
    },

    name() {
      return this.value && this.value.name ? this.value.name : null;
    },

    // ==============================================================================
    // Collection Settings
    // ==============================================================================

    collectionSettingsList: {
        get() {
            console.log(this.response.collectionSettings);
            return this.response.collectionSettings ? this.response.collectionSettings : [];
        }
    },


    sourceCollection: {
      get() {
        return this.response && this.response.sourceCollectionId ? this.response.sourceCollectionId : null;
      }
    },

    targetCollection: {
      get() {
        return this.response && this.response.targetCollectionId ? this.response.targetCollectionId : null;
      }
    },

    custData: {
           
        get() {
            return get(this.mappingJobTemplate, 'custData', {}); 
        },

        set(custData) {
            set(this.mappingJobTemplate, 'custData', custData ? custData : {});
        }
    },

    custDataItems() {

        let items = [];
        for (let key in this.custData) {
            items.push({name: key, value: this.custData[key]})
        }
        return items;
    },  

    hasEditRole() {
        return this.userContextService.hasAllRoles(Roles.MAPPING_JOB_TEMPLATES_EDIT);
    },
  },

  created() {
    this.refresh();
  },

};
</script>

<i18n>
{
    "en" : {
        "mapping-job-templates-editor.view.title": "Job Template",
        "mapping-job-templates-editor.view.sections.collections.title": "Source and Target Collections",
        "mapping-job-templates-editor.view.sections.collections.sourceCollection": "Source Collection",
        "mapping-job-templates-editor.view.sections.collections.identifier": "Technical identifier",
        "mapping-job-templates-editor.view.sections.collections.sourceCollection.hint": "You can select another source collection",
        "mapping-job-templates-editor.view.sections.collections.targetCollection": "Target Collection",
        "mapping-job-templates-editor.view.sections.filter.title": "Filter",


        "mapping-job-templates-editor.view.sections.json.title" : "Job Template: JSON",
        "mapping-job-templates-editor.view.sections.json.subtitle" : "Show the mapping job template JSON representation.",
        "mapping-job-templates-editor.view.sections.json.actions.open"  : "Open JSON",

        "mapping-job-templates-editor.view.tabs.general" : "General",
        "mapping-job-templates-editor.view.tabs.general.title" : "General Data",
        "mapping-job-templates-editor.view.tabs.general.description" : "The following information cannot be changed after creation and is displayed for informational purposes. Please create a new template if you need a different configuration.",   
        
        "mapping-job-templates-editor.view.tabs.general.page-size" : "Page Size",
        "mapping-job-templates-editor.view.tabs.general.page-size.description" : "The page size configures to amount of documents that are combined in a single package for parallel processing. Small page sizes may be required for collections with a high amount of attributes. Big page sizes may increase throughput when the number of attributes allow for it.",   

         "mapping-job-templates-editor.view.tabs.general.page-size.validation.notInRange" : "Only Page sizes between 5 - 750 are allowed.",
        "mapping-job-templates-editor.view.tabs.general.page-size.validation.onlyNumbers" : "Only integers are allowed.",

        "mapping-job-templates-editor.view.tabs.filtering" : "Filter",
        "mapping-job-templates-editor.view.tabs.filtering.title" : "Row Filtering",
        "mapping-job-templates-editor.view.tabs.filtering.description" : "Please define a filter to limit the set of records to be copied from the source to the target. The job copies only records that match the filter expression.",   
    
        "mapping-job-templates-editor.view.tabs.mapping" : "Field Mapping",
        "mapping-job-templates-editor.view.tabs.mapping.title" : "Mapping Target Fields",
        "mapping-job-templates-editor.view.tabs.mapping.description" : "Please define the rules according to which the target fields are to be filled.",

        "mapping-job-templates-editor.view.tabs.custData" : "Custom Parameters",
        "mapping-job-templates-editor.component.sections.custData.title" : "Custom Parameters",
        "mapping-job-templates-editor.component.sections.custData.subtitle" : "You can define additional parameters for the purpose of customizing. Please note: These parameters will not be evaluated from the application and need to be processed by means of customer specific code.",
        
        "mapping-job-templates-editor.view.tabs.json" : "JSON"
    },

    "de" : {
        "mapping-job-templates-editor.view.title": "Job Vorlage",
        "mapping-job-templates-editor.view.sections.collections.title": "Quell- und Zieldatenbereiche",
        "mapping-job-templates-editor.view.sections.collections.identifier": "Technischer Bezeichner",
        "mapping-job-templates-editor.view.sections.collections.sourceCollection": "Quelldatenbereich",
        "mapping-job-templates-editor.view.sections.collections.sourceCollection.hint": "Sie können einen anderen Quelldatenbereich auswählen",
        "mapping-job-templates-editor.view.sections.collections.targetCollection": "Zieldatenbereich",
        "mapping-job-templates-editor.view.sections.filter.title": "Filter",


        "mapping-job-templates-editor.view.sections.json.title" : "Job Template: JSON",
        "mapping-job-templates-editor.view.sections.json.subtitle" : "Zeigen Sie das mapping job template als JSON an.",
        "mapping-job-templates-editor.view.sections.json.actions.open"  : "JSON Öffnen",

        "mapping-job-templates-editor.view.tabs.general" : "Allgemein",
        "mapping-job-templates-editor.view.tabs.general.title" : "Allgemeine Daten",
        "mapping-job-templates-editor.view.tabs.general.description" : "Die nachfolgenden Informationen können nach der Anlage nicht mehr verändert werden und werden zu Informationszwecken angezeigt. Bitte legen Sie ein neues Template an, wenn Sie eine andere Konfiguration benötigen.",   
        
        "mapping-job-templates-editor.view.tabs.general.page-size" : "Seitengröße",
        "mapping-job-templates-editor.view.tabs.general.page-size.description" : "Die Seitengröße konfiguriert die Menge der Dokumente welche in einem Paket für parallele Verarbeitung zusammengefasst werden. Geringe Seitengrößen könnten bei Datenbereichen mit einer hohen Anzahl von Attributen nötig sein um Stabilität und Schnelligkeit zu gewährleisten. Hohe Seitengrößen könnten den Durchsatz erhöhen, falls die Anzahl der Attribute dies erlaubt.",  

        "mapping-job-templates-editor.view.tabs.general.page-size.validation.notInRange" : "Nur Seitengrößen zwischen 5 - 750 sind erlaubt.",
        "mapping-job-templates-editor.view.tabs.general.page-size.validation.onlyNumbers" : "Nur Ganzzahlen dürfen enthalten sein.",

        "mapping-job-templates-editor.view.tabs.filtering" : "Filterung",
        "mapping-job-templates-editor.view.tabs.filtering.title" : "Zeilenfilterung",
        "mapping-job-templates-editor.view.tabs.filtering.description" : "Bitte definieren Sie einen Filter, um die Menge der Datensätze einzuschränken, die von der Quelle ins Ziel kopiert werden sollen. Der Job kopiert nur Datensätze, die dem Filterausdruck entsprechen.",   
    
        "mapping-job-templates-editor.view.tabs.mapping" : "Feld-Mapping",
        "mapping-job-templates-editor.view.tabs.mapping.title" : "Zuordnung Ziel-Felder",
        "mapping-job-templates-editor.view.tabs.mapping.description" : "Bitte definieren Sie die Regeln, nach denen die Ziel-Felder befüllt werden sollen.",

        "mapping-job-templates-editor.view.tabs.custData" : "Kundenspezifische Parameter",
        "mapping-job-templates-editor.component.sections.custData.title" : "Kundenspezifische Parameter",
        "mapping-job-templates-editor.component.sections.custData.subtitle" : "Sie können an dieser Stelle kundenspezifische Parameter für das Customizing hinterlegen. Bitte beachten Sie: Diese Parameter werden durch das Standardsystem nicht ausgewertet. Hierfür ist zusätzlicher kundenspezifischer Code notwendig.",
    
        "mapping-job-templates-editor.view.tabs.json" : "JSON"
    }
}
</i18n>