<template>
<v-sheet color="grey lighten-4">
    <v-container fluid grid-list-md> 
        <v-row justify="center">
            <v-col cols="12">
                <v-card tile>
                    <v-card-text>
                        <div class="text-h6">{{$t('collection-schema.view.cards.schema.title')}}</div>
                        <div class="body-2">{{$t('collection-schema.view.cards.schema.subtitle')}}</div>
                    </v-card-text>
                    <c-schema-table :value="response" />
                </v-card>
            </v-col> 
        </v-row> 
    </v-container>
    
</v-sheet>
</template>

<script>
const MODULE_NAME = "collectionSchema";

export default {

    name: 'collection-schema',

    inject: ['shellState', 'errorHandlerService', 'progressIndicatorService'], 

    props: {
        value: {
            type: Object,
            required: false,
        }        
    },


    watch: {
        
        value(newValue, oldValue) {

            if (newValue.collectionId !== oldValue.collectionId) {
                 this.refresh(newValue.collectionId);
            }   
        }
    },
    

    methods: {
        async refresh(collectionId) {

            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData', {    
                    collectionId : collectionId
                });
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        
    },

    computed: {

        response() {
            let response = this.$store.getters[MODULE_NAME + '/response'];
            return response;
        },

    },

    created() {

        if (this.value && this.value.collectionId) {
            this.refresh(this.value.collectionId);
        }
    }

}
</script>

<i18n>
{
    "en" : {
        "collection-schema.view.cards.schema.title" : "Schema",
        "collection-schema.view.cards.schema.subtitle" : "The schema defines the document's attribute structure."
    },

    "de" : {
        "collection-schema.view.cards.schema.title" : "Schema",
        "collection-schema.view.cards.schema.subtitle" : "Das Schema definiert die Struktur und die Attribute der Dokumente in diesem Datenbereich."
    }
}
</i18n>