import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import api from '@/store/api';
import aggregations from '@/core/views/collection/mock/collection-time-series-agg.mock';
import timeScales from '@/core/views/collection/mock/collection-time-series-timescales.mock';

const MAX_PAGE_SIZE = 500;

export default {

    namespaced: true,

    state: {
        collectionSettings: null,
        schema: null,
        filter: {
            filterQuery  : '*',
            filterQueryLanguage: 'SIMPLE',
            facetFilters : [],
            rangeFilters : []

        },
        form: {
            attribute: null,
            timescale: null,
            measures: [],
            isValid: false,
            sent: false
        },
        timeSeries: null,
        pagination: {from: 0, size: MAX_PAGE_SIZE, searchAfter: []},
        documentCount: 0,
        totalItemCount: 0
    },

    mutations: {

        setCollectionSettings(state, response) {
            state.collectionSettings = get(response, 'collectionSettings', {});
            state.schema = get(response, 'schema', {});
        },

        setDocumentCount(state, documentCount) {
            state.documentCount = (documentCount && documentCount >= 0) ? documentCount : 0;
        },

        setTotalItemCount(state, totalItemCount) {
            state.totalItemCount = (totalItemCount && totalItemCount >= 0) ? totalItemCount : 0;
        },

        increaseDocumentCount(state, documentCount) {
            state.documentCount  = state.documentCount + ( (documentCount && documentCount >= 0) ? documentCount : 0);
        },

        setTimeSeriesResponse(state, response) {
            if (has(response, 'data.result')) {
                response.data.result.forEach(result => {
                    result.values.forEach(value => {
                        value['roundedValue'] = +value.value.toFixed(3)
                    });
                })
            }

            state.timeSeries = {...response, aggregations: aggregations, timeScales: timeScales};
            //state.pagination = {from: 0, size: response.data.pagination.size, searchAfter: response.data.pagination.searchAfter}
        },

        setPagination(state, pagination) {

            // Create a default if no pagination has been set up.
            if (!state.pagination) {
                state.pagination =  {from: 0, size: MAX_PAGE_SIZE, searchAfter: []};
            }

            if (pagination) {

                state.pagination.from = (pagination.from) ? pagination.from : 0;
                state.pagination.size = (pagination.size) ? pagination.size : MAX_PAGE_SIZE;
                state.pagination.searchAfter = (pagination.searchAfter) ? pagination.searchAfter : [];
            }
        },


        setFilterQuery(state, { filterQuery, filterQueryLanguage}) {
            state.filter.filterQuery = filterQuery ? filterQuery : '*';
            state.filter.filterQueryLanguage = filterQueryLanguage ? filterQueryLanguage  : 'SIMPLE';
        },

        setFacetFilters(state, filters) {
            state.filter.facetFilters = filters.facetFilters ? filters.facetFilters : [];
            state.filter.rangeFilters = filters.rangeFilters ? filters.rangeFilters : [];
        },

        setAttribute(state, attribute) {
            state.form.attribute = attribute;
            state.form.isValid = !isEmpty(state.form.attribute) && !isEmpty(state.form.timescale) && !isEmpty(state.form.measures);
            if (!state.form.isValid) {
                state.timeSeries = null;
                state.form.sent = false;
            }
        },

        setTimescale(state, timescale) {
            state.form.timescale = timescale;
            state.form.isValid = !isEmpty(state.form.attribute) && !isEmpty(state.form.timescale) && !isEmpty(state.form.measures);
            if (!state.form.isValid) {
                state.timeSeries = null;
                state.form.sent = false;
            }
        },

        setMeasures(state, measures) {
            state.form.measures = measures;
            state.form.isValid = !isEmpty(state.form.attribute) && !isEmpty(state.form.timescale) && !isEmpty(state.form.measures);
            if (!state.form.isValid) {
                state.timeSeries = null;
                state.form.sent = false;
            }
        },

        setFormSent(state, sent) {
            state.form.sent = sent;
        }
    },

    actions: {

        async loadCollectionSettings(context, { collectionId }) {
            return api.collections.findById(collectionId).then(response => {
                context.commit('setCollectionSettings', response);
            });
        },

        async loadTimeSeriesData(context, { collectionId }) {
            // reset pagination and document count
            context.commit('setPagination', {});
            context.commit('setDocumentCount', 0);

            const request = context.getters.request;
            const formValid = context.getters.formValid;
            if (formValid) {
                /*
                return api.collections.timeSeries.getTimeSeries(collectionId, request).then(response => {
                    context.commit('setTimeSeriesResponse', response);
                    context.commit('setFormSent', true);
                });
                */
                try {

                    try {
                        let response = await api.collections.timeSeries.getTimeSeries(collectionId, request);

                        let items = [];
                        if (response && response.data) {

                            context.commit('setTotalItemCount', response.data.pagination.totalItemCount);
                            items.push(...response.data.result);

                            while (response.data.result && response.data.result.length > 0) {
                                context.dispatch('updatePagination', response);
                                response = await api.collections.timeSeries.getTimeSeries(collectionId, context.getters.request);
                                if (response && response.data) {
                                    items.push(...response.data.result);
                                }
                            }

                            if (response && response.data) {
                                response.data.result = items;
                            }
                        }




                        context.commit('setTimeSeriesResponse', response);
                    } finally {
                        context.commit('setFormSent', true);
                    }

                } catch (error) {
                    return Promise.reject(error);
                }

            }
        },

        updatePagination(context, response) {

            if (response && response.data) {

                if (response.data.pagination) {
                    context.commit('setPagination', response.data.pagination);
                }

                if (response.data.result) {
                    for (let item of response.data.result) {
                        context.commit('increaseDocumentCount', item.docCount);
                    }
                }
            }
        },

        async updateFilterQuery(context, {collectionId, filterQuery}) {
            context.commit('setFilterQuery', filterQuery);
            return context.dispatch('loadTimeSeriesData', {collectionId: collectionId});
        },

        async updateFacetFilters(context, {collectionId, facetFilters}) {

            context.commit('setFacetFilters', facetFilters);
            return context.dispatch('loadTimeSeriesData', {collectionId: collectionId});
        },

        async export(context, {collectionId, format}) {
            context.commit('setPagination', {});
            
            const request = context.getters.request;
            const formValid = context.getters.formValid;
            console.debug(format);
            if (formValid) {
                return api.collections.timeSeries.exportTimeSeries(collectionId, request).then(() => {});
            }
        },
    },

    getters: {
        collectionSettings(state) {
            return state.collectionSettings;
        },

        schema(state) {
            return state.schema;
        },

        filter(state) {
            return state.filter;
        },

        timeSeries(state) {
            return state.timeSeries;
        },

        attribute(state) {
            return state.form.attribute;
        },

        timescale(state) {
            return state.form.timescale;
        },

        measures(state) {
            return state.form.measures;
        },

        formValid(state) {
            return state.form.isValid;
        },

        formSent(state) {
            return state.form.sent;
        },

        docCount(state) {
            return state.documentCount ? state.documentCount : 0;
        },

        totalItemCount(state) {
            return state.totalItemCount ? state.totalItemCount : 0;
        },


        request(state) {

            const dateAttribute = get(state.form.attribute, 'name', null);
            const timeScale = get(state.form.timescale, 'value', null);
            const measures = get(state.form, 'measures', []).map(measure => {
                return {
                    attribute: measure.measure.name,
                    operation: measure.aggregation.name,
                    customData: {
                        'color': measure.color.value
                    }
                }
            });

            const pagination = state.pagination
            return {
                filter: state.filter,
                dateAttribute: dateAttribute,
                timeScaleSeconds: timeScale,
                measures: measures,
                pagination: pagination
            };
        },
    }
};