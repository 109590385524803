import api from '@/store/api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state : {
        account: {},
        accessControlLists: [],
        user: {}
    },

    mutations: {

        setAccount(state, account) {
            set(state, 'account', account);
        },

        setAccessControlLists(state, accessControlLists) {
            set(state, 'accessControlLists', accessControlLists);
        },

        setUser(state, user) {
            set(state, 'user', user); 
        }

    },

    actions: {
    
        async loadData(context, { name }) {

            // First load the account
            try {
                let response = await api.userMgmt.userAccounts.findById(name);
                context.commit('setAccount', get(response, 'data.account', {}));
                context.commit('setUser', get(response, 'data.user', {}));
            } catch (error) {
                context.commit('setAccount', {});
                context.commit('setUser', {});
                throw error;
            }

            // The list of ACLs is required for the purpose of configuration.
            
            try {
                let response = await api.userMgmt.accessControlLists.findAll('*');
                
                const accessControlLists = [];
                for (const item of response.items) {
                    accessControlLists.push(item);
                }

                context.commit('setAccessControlLists', accessControlLists);
            } catch (error) {
                context.commit('setAccessControlLists', []);
                throw error;
            }

        },

        
        async saveData(context) {

            try {

                const payload = context.getters.payload;
                //console.log(">>> VUEX SAVE DATA ", JSON.stringify(payload));
                let response = await api.userMgmt.userAccounts.update(payload.name, payload);
                context.commit('setAccount', get(response, 'data.account', {}));

            } catch (error) {
                context.commit('setAccount', {});
                throw error;

            }
        },

        resetData(context) {
            context.commit('setAccount', {});
        },

        updateModel(context, model) {
            //console.log(' +++ ', JSON.stringify(model, true, 2))
            context.commit('setAccount', get(model, 'account', {}));
        },

        updateAccount(context, account) {
            context.commit('setAccount', account);
        },
        
    },

    getters: {

        model(state) {

            return {
                account: state.account,
                accessControlLists: state.accessControlLists,
                user: state.user,
            };
        },

        account(state) {
            return state.account ? state.account : {}
        },

        payload(state) {
            let payload = get(state, 'account', {});
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        }
    }
}
