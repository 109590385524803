<template>

    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <c-base-dialog 
                    :value="mappingJobTemplate"
                    :title="$t('mapping-job-templates-wizard.view.title')" 
                    :subtitle="$t('mapping-job-templates-wizard.view.subtitle')"
                    color="secondary"
                    @dialog-saved="save"
                    @dialog-canceled="cancel"
                    >
                    <template v-slot:default="props"> 
                        <v-sheet class="ma-5">
                            <c-name-input 
                                v-model="props.item.name"
                                filled 
                            />
                            <c-collection-settings-selector 
                                v-model="props.item.sourceCollectionId"
                                :items="collectionSettings"
                                :label="$t('mapping-job-templates-wizard.view.fields.sourceCollectionId')"
                                :rules="[ v => v !== null && v !== undefined || $t('mapping-job-templates-wizard.view.fields.sourceCollectionId.notEmpty') ]"
                                filled
                                :return-object="false"
                            />
                            <c-collection-settings-selector 
                                v-model="props.item.targetCollectionId"
                                :items="collectionSettings"
                                 :label="$t('mapping-job-templates-wizard.view.fields.targetCollectionId')"
                                 :rules="[ v => v !== null && v !== undefined || $t('mapping-job-templates-wizard.view.fields.targetCollectionId.notEmpty') ]"
                                filled
                                :return-object="false"
                            />
                        </v-sheet>
                    </template>
                </c-base-dialog>
            </v-col>
        </v-row>

    </v-container>

</template>

<script>

const MODULE_NAME = 'mappingJobTemplatesWizard';

export default {

    name: 'mapping-job-templates-wizard',

    inject: ['errorHandlerService', 'progressIndicatorService'],

    methods: {
        async save() {
            
            this.progressIndicatorService.show();
           
            try {
                
                await this.$store.dispatch(MODULE_NAME + "/createMappingJobTemplate");
                
                this.$router.push( { name: 'mappingJobTemplatesEditor', params: {name: this.mappingJobTemplate.name}  });

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push( {
                name: 'mappingJobTemplatesList'
            });
        },

        async refresh() {
            this.progressIndicatorService.show();
           
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadData");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetMappingJobTemplate");
        }
    },

    computed: {



        mappingJobTemplate: {

            get() {
               return this.$store.getters[MODULE_NAME + '/mappingJobTemplate'];
            }, 

            set(item) {
                
                this.$store
                    .dispatch(MODULE_NAME + "/updateMappingJobTemplate", item)
                    .catch(this.errorHandlerService.handleError);
            }
        },

        collectionSettings: {

            get() {
                return this.$store.getters[MODULE_NAME + '/collectionSettings'];
            }
        }
    },

    created() {
        this.reset();
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "mapping-job-templates-wizard.view.title" : "Create New Mapping Job Template",
        "mapping-job-templates-wizard.view.subtitle" : "This wizard will guide you through the required steps for create a new mapping job template.",
        "mapping-job-templates-wizard.view.fields.sourceCollectionId" : "Source Collection",
        "mapping-job-templates-wizard.view.fields.sourceCollectionId.notEmpty" : "The source collection must not be empty. Please select a source collection.",
        "mapping-job-templates-wizard.view.fields.targetCollectionId" : "Target Collection",
        "mapping-job-templates-wizard.view.fields.targetCollectionId.notEmpty" : "The target collection must not be empty. Please select a source collection."
    },

    "de" : {
        "mapping-job-templates-wizard.view.title" : "Neues Mapping Job Template anlegen",
        "mapping-job-templates-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Mapping Job Templates.",
        "mapping-job-templates-wizard.view.fields.sourceCollectionId" : "Quell-Datenbereich",
        "mapping-job-templates-wizard.view.fields.sourceCollectionId.notEmpty" : "Der Quell-Datenbereich darf nicht leer sein. Bitte wählen Sie einen Datenbereich aus.",
        "mapping-job-templates-wizard.view.fields.targetCollectionId" : "Ziel-Datenbereich",
        "mapping-job-templates-wizard.view.fields.targetCollectionId.notEmpty" : "Der Ziel-Datenbereich darf nicht leer sein. Bitte wählen Sie einen Datenbereich aus."
    }
}
</i18n>