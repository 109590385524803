import api from '../../../api/smart-factory.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        workplaceType: createEmptyWorkplaceType(),
        tenantId: null
    },

    mutations: {

        setWorkplaceType(state, workplaceType) {

            if (workplaceType) {

                state.workplaceType.tenantId = workplaceType.tenantId ? workplaceType.tenantId : null;
                state.workplaceType.name = workplaceType.name ? workplaceType.name : null;
                state.workplaceType.i18n = workplaceType.i18n ? workplaceType.i18n : {};
                state.workplaceType.data = workplaceType.data ? workplaceType.data : { custData: {} };
                state.workplaceType.active = workplaceType.active ? workplaceType.active : true;
                state.workplaceType.references = workplaceType.references ? workplaceType.references : {};

            } else {
                state.workplaceType.tenantId = null;
                state.workplaceType.name = null;
                state.workplaceType.i18n = null;
                state.workplaceType.data = null;
                state.workplaceType.active = null;
                state.workplaceType.references = null;
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        }


    },

    actions: {

        async createWorkplaceType(context) {

            try {

                let request = context.getters.request;
                await api.masterdata.workplaceTypes.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetWorkplaceType(context) {
            context.commit('setWorkplaceType', createEmptyWorkplaceType());
        },

        async updateWorkplaceType(context, workplaceType) {

            try {
                context.commit('setWorkplaceType', workplaceType);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.workplaceType;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        workplaceType(state) {
            return state.workplaceType ? state.workplaceType : createEmptyWorkplaceType();
        }

}
}

function createEmptyWorkplaceType() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { custData: {} },
        "active": true,
        "references": {}

    }
}