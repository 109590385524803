<template>
    <v-card tile flat>

      <!-- Toolbar -->
      <c-sticky-content>
        <v-toolbar flat>
            <div class="text-h6">
                {{$t('user-authorization-dashboard.view.title', {name: name})}}
            </div>
            <v-spacer />
            <!-- Toolbar items for larger screen resolutions -->
            <v-toolbar-items v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn text @click="refresh" color="accent"><v-icon class="pr-2">refresh</v-icon> {{$t("user-authorization-dashboard.view.actions.refresh.label")}}</v-btn>
                <v-btn text @click="cancel" color="accent"><v-icon class="pr-2">close</v-icon> {{$t("user-authorization-dashboard.view.actions.cancel.label")}}</v-btn>
            </v-toolbar-items>
            <!-- Toolbar items for small screen resolutions -->
            <v-toolbar-items v-else>
                <v-btn icon @click="refresh" color="accent"><v-icon>refresh</v-icon></v-btn>
                <v-btn icon @click="cancel" color="accent"><v-icon>close</v-icon></v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
      </c-sticky-content>

            <v-card-text v-if="accessControlListsEmpty">
                <dxs-warning-message >
                    <div>{{$t('user-authorization-dashboard.view.warnings.noAcls.description')}}</div>
                </dxs-warning-message>
            </v-card-text>

            <v-card-text>
                <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('user-authorization-dashboard.view.sections.groups.title')}}</div>
                <div>{{$t('user-authorization-dashboard.view.sections.groups.subtitle')}}</div>
            </v-card-text>
            <template v-if="!groupsEmpty">
                <v-simple-table class="pa-5">
                    <thead>
                        <tr>
                            <th>{{$t('user-authorization-dashboard.view.sections.groups.table.cols.name')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(group, index) of groups" :key="'tr_groups_' + index">
                            <td>{{group}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </template>
            <template v-else>
                <v-card-text class="grey lighten-3">
                    {{$t('user-authorization-dashboard.view.sections.groups.table.isEmpty')}}
                </v-card-text>
            </template>
            <v-divider class="mt-5" />
            <v-card-text>
                <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('user-authorization-dashboard.view.sections.collections.title')}}</div>
                <div>{{$t('user-authorization-dashboard.view.sections.collections.subtitle')}}</div>
            </v-card-text>
            <dxs-access-control-list-permission-table :items="accessControlLists" class="pa-5"/>

  </v-card>
</template>

<script>
import { get, isEmpty } from 'lodash';

import AccessControlListPermissionTableComponent from '@/core/components/usermgmt/access-control/access-control-list-permission-table.component';
import UserAuthorizationDashboardModule from '@/core/store/modules/user-mgmt/user-authorization/user-authorization-dashboard.module';
import WarningMessageComponent from '@/components/messages/warning-message.component';

const MODULE_NAME = 'userAuthorizationDashboard';

export default {

    name: 'user-authorization-dashboard',

    props: {
        value: {
            type: Object,
            required: true,
        }
    },

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],
    
    components : {
        'dxs-warning-message' : WarningMessageComponent,
        'dxs-access-control-list-permission-table' : AccessControlListPermissionTableComponent
    },

    
    methods: {

        cancel() {
            this.$router.go(-1);
        },

        async refresh() {
            this.progressIndicatorService.show();
            try {

                await this.$store.dispatch(MODULE_NAME + "/loadData", { name: this.name })
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    computed: {

        name() {
            return get(this.value, 'name');
        },

        model() {
            return this.$store.getters[MODULE_NAME + "/model"];
        },

        userAuthorization() {
            return get(this.model, 'userAuthorization', {});
        },

        user() {
            return get(this.userAuthorization, 'user');
        },

        groups() {
            return get(this.user, 'groups', [])
        },

        accessControlLists() {
            return get(this.userAuthorization, 'accessControlLists');
        },

        accessControlListsEmpty() {
            return isEmpty(this.accessControlLists);
        }
    },

    async created() {

        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, UserAuthorizationDashboardModule);
        }

        await this.refresh();


    }
}
</script>

<i18n>
{
    "en" : {
        "user-authorization-dashboard.view.title" : "Authorization {name}",
        "user-authorization-dashboard.view.subtitle" : "This page contains a comprehensive overview of the user settings.",

        "user-authorization-dashboard.view.actions.refresh.label" : "Refresh",
        "user-authorization-dashboard.view.actions.cancel.label" : "Cancel",

        "user-authorization-dashboard.view.sections.groups.title" : "User Group Assignment",
        "user-authorization-dashboard.view.sections.groups.subtitle" : "The user is a member of the following user groups.",
        "user-authorization-dashboard.view.sections.groups.table.cols.name" : "User Group Name",
        "user-authorization-dashboard.view.sections.groups.table.isEmpty" : "Currently this user is not a member of a user group.",

        "user-authorization-dashboard.view.sections.collections.title" : "Collection Access",
        "user-authorization-dashboard.view.sections.collections.subtitle" : "The user is granted access to the following collections. Please note that access may be granted by direct assignment as well as indirectly by means of a user group.",

        "user-authorization-dashboard.view.warnings.noAcls.description" : "The user has neither directly nor indirectly assigned access control list. Thus, this user cannot access any collections. Please check whether this state is desired."

    },
    
    "de" : {
        "user-authorization-dashboard.view.title" : "Berechtigungen {name}",
        "user-authorization-dashboard.view.subtitle" : "Diese Seite beinhaltet eine kurze Übersicht aller Benutzerberechtigungen.",

        "user-authorization-dashboard.view.actions.refresh.label" : "Neu Laden",
        "user-authorization-dashboard.view.actions.cancel.label" : "Abbrechen",

        "user-authorization-dashboard.view.sections.groups.title" : "Benutzergruppen",
        "user-authorization-dashboard.view.sections.groups.subtitle" : "Der Benutzer ist Mitglied der nachfolgenden Benutzergruppen.",
        "user-authorization-dashboard.view.sections.groups.table.cols.name" : "Name Benutzergruppe",
        "user-authorization-dashboard.view.sections.groups.table.isEmpty" : "Momentan ist der Benutzer Mitglied keiner Grupppe.",

        "user-authorization-dashboard.view.sections.collections.title" : "Zugriffsberechtigungen Datenbereiche",
        "user-authorization-dashboard.view.sections.collections.subtitle" : "Dem Benutzer ist der Zugriff zu den folgenden Datenbereichen zugewiesen. Bitte beachten Sie, dass der Zugriff sowohl durch direkte Zuweisung als auch indirekt über eine Benutzergruppe gewährt werden kann.",

        "user-authorization-dashboard.view.warnings.noAcls.description" : "Dem Benutzer sind weder direkt noch indirekt Zugriffsberechtigungen zugewiesen. Somit kann dieser Benutzer auf keine Datenbereiche zugreifen. Bitte prüfen Sie, ob dieser Zustand gewollt ist."
    }
}
</i18n>