<template>
  <c-editable-item-list
    :title="$t('target-attribute-mapping-list.component.title')" 
    :items="items"
    @item-updated="fireItemUpdated" 
    @item-removed="fireItemRemoved"
    :disabled="disabled"
    :new-item="emptyItem" 
    :readonly="readonly"
    headerStyle="lite"
    editDialogStyle="fullscreen"
    :context-data="{source, target}"
    flat
    :check-duplicate="(e1, e2) => (e1.targetAttributeId === e2.targetAttributeId)"
  >

    <!-- =========================================================== -->
    <!-- LIST BODY                                                   -->
    <!-- =========================================================== -->
    <template v-slot:item-list-body-row="props"> 
          <!-- For regular devices use tabular layout-->
          <template>
                <td>{{props.contextData.target.schema.attributes[props.item.targetAttributeId] | localized-name($i18n.locale)}}</td>
                 <td>{{props.item.targetAttributeId}}</td>
                <td><code>{{props.item.defaultMappingRule}}</code></td>
            </template> 
    </template>
    
    <!-- =========================================================== -->
    <!-- EDITOR                                                      -->
    <!-- =========================================================== -->
    <template v-slot:item-list-editor="props">
            
        <!-- <dxs-attribute-mapping-editor :value="props.item" :newItem="props.isNewItem" :source="props.contextData.source" :target="props.contextData.target"/> -->
        <dxs-target-attribute-mapping-editor 
            v-model="props.item"
            :source="props.contextData.source" 
            :target="props.contextData.target"
            :custData="custData"
        />
    </template>
  </c-editable-item-list>
</template>

<script>
import TargetAttributeMappingEditorComponent from '@/core/components/mapping-job-templates/target-attribute-mapping-editor.component.vue';

export default {

    name: 'target-attribute-mapping-list',

    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        },

        source: {
            type: Object,
            required: true,
            validator: (source) => source.collectionSettings && source.schema 
        },

        target: {
            type: Object,
            required: true,
            validator: (target) => target.collectionSettings && target.schema
        },
        
        value: {
            type: Object,
            required: false,
        },

        custData: {
            type: Object,
            required:false
        },

        readonly : {
            type: Boolean,
            required: false,
            default: false 
        },

        disabled : {
            type: Boolean,
            required: false,
            default: false 
        },
    },

    components: {
        'dxs-target-attribute-mapping-editor' : TargetAttributeMappingEditorComponent
    },

    methods: {
        fireItemUpdated(event) {
            console.log('+++' , JSON.stringify(event));
            this.$emit('item-updated', event);
        },

        fireItemRemoved(event) {
            console.log('+++' , JSON.stringify(event));
            this.$emit('item-removed', event);
        }
    },

    computed: {
        emptyItem() {
            return {
                targetAttributeId: null,
                defaultMappingRule: null,
                mappingRules: []
            }
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "target-attribute-mapping-list.component.title" : "Target Attribute Mappings"
    },

    "de" : {
        "target-attribute-mapping-list.component.title" : "Ziel-Felder Mappings"
    }
}
</i18n>