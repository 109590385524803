<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card tile>
                    <v-card-text class="text-body-1">
                        {{  $t('shift-event-log-result-summary.component.sections.all') }}
                    </v-card-text>
                    <v-divider />
                    <v-simple-table>
                        <tbody>
                            <tr v-for="(item, index) of eventsBySite" :key="`events-site-${index}`">
                                <td class="text-left">{{ $dxs.i18n.localizedName(item, $i18n.locale) }}</td>
                                <td class="text-right"><v-chip label>{{$dxs.formatting.formatInteger(item.value, $i18n.locale)}}</v-chip></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card tile>
                    <v-card-text class="text-body-1">
                        {{  $t('shift-event-log-result-summary.component.sections.shiftEventTypes') }}
                    </v-card-text>
                    <v-divider />
                    <v-lazy>
                        <c-metrics-distribution-chart :items="eventsByShiftEventType.filter(e => e.value > 0)" type="donut" show-value-label />
                    </v-lazy>
                    <v-divider />
                    <v-simple-table>
                        <tbody>
                            <tr v-for="(item, index) of eventsByShiftEventType" :key="`events-shiftEventType-${index}`">
                                <td class="text-left">{{ $dxs.i18n.localizedName(item, $i18n.locale) }}</td>
                                <td class="text-right"><v-chip label>{{$dxs.formatting.formatInteger(item.value, $i18n.locale)}}</v-chip></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card tile>
                    <v-card-text class="text-body-1">
                        {{  $t('shift-event-log-result-summary.component.sections.workplaces') }}
                    </v-card-text>
                    <v-divider />
                    <v-lazy>
                        <c-metrics-distribution-chart :items="eventsByWorkplace.filter(e => e.value > 0)" type="donut" show-value-label />
                    </v-lazy>
                    <v-divider />
                    <v-simple-table>
                        <tbody>
                            <tr v-for="(item, index) of eventsByWorkplace" :key="`events-workplace-${index}`">
                                <td class="text-left">{{ $dxs.i18n.localizedName(item, $i18n.locale) }}</td>
                                <td class="text-right"><v-chip label>{{$dxs.formatting.formatInteger(item.value, $i18n.locale)}}</v-chip></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
    name: 'shift-event-log-result-summary',

    props: {

        items: {
            type: Array,
            required: true
        }
    },

    computed: {

        numberOfEvents() {
            return (isEmpty(this.items)) ? 0 : this.items.length;
        },

        eventsBySite() {
            
            const eventsBySite = [];
            if (this.items) {

                const map = new Map();            
                for (const item of this.items) {

                    if (item.site) {
                        if (!map.has(item.site.name)) {
                    
                            map.set(item.site.name, {
                                name: item.site.name,
                                i18n: item.site.i18n,
                                value: 1
                            });
                        } else {
                            map.get(item.site.name).value = map.get(item.site.name).value + 1;
                        }
                    } else {
                        this.$log.warn(`The item ${JSON.stringify(item)} does not contain a site -> This is not consistent with the components expectations.`);
                        
                    }
                    
                }
                map.forEach(v => eventsBySite.push(v));
            }

            return eventsBySite;
        },

        eventsByShiftEventType() {

            const eventTypes = [];
            if (this.items) {

                const eventTypeMap = new Map();            
                for (const item of this.items) {

                    if (item.shiftEventType) {
                        if (!eventTypeMap.has(item.shiftEventType.name)) {
                    
                            eventTypeMap.set(item.shiftEventType.name, {
                                name: item.shiftEventType.name,
                                i18n: item.shiftEventType.i18n,
                                value: 1
                            });
                        } else {
                            eventTypeMap.get(item.shiftEventType.name).value = eventTypeMap.get(item.shiftEventType.name).value + 1;
                        }
                    } else {
                        this.$log.warn(`The item ${JSON.stringify(item)} does not contain a shiftEventType -> This is not consistent with the components expectations.`);
                        
                    }
                    
                    
                }
                eventTypeMap.forEach(v => eventTypes.push(v));
            }

            return eventTypes;

        },

        workplaces() {

            const workplaces = [];
            if (this.items) {

                const map = new Map();            
                for (const item of this.items) {

                    if (item.workplace) {
                        if (!map.has(item.workplace.name)) {
                    
                            map.set(item.workplace.name, {
                                name: item.workplace.name,
                                i18n: item.workplace.i18n,
                                value: 1
                            });
                        } else {
                            map.get(item.workplace.name).value = map.get(item.workplace.name).value + 1;
                        }
                    } 

                }

                map.forEach(v => workplaces.push(v));
            }

            return workplaces;

        },

        numberEventsWithoutWorkplace() {
            let eventCount = this.numberOfEvents;

            for (const workplace of this.workplaces) {
                eventCount = eventCount - workplace.value;
            }

            return eventCount;
        },

        eventsByWorkplace() {

            const events = [];

            events.push({
                name: '-',
                i18n: {
                    en: {name: this.$t('shift-event-log-result-summary.component.sections.workplaces.noWorkplace', 'en')},
                    de: {name: this.$t('shift-event-log-result-summary.component.sections.workplaces.noWorkplace', 'de')}
                },
                value: this.numberEventsWithoutWorkplace
            });

            this.workplaces.forEach(v => events.push(v));
            return events;
        }
    }
}
</script>

<i18n>
{
    "en" : { 
        "shift-event-log-result-summary.component.sections.all" : "Shift Events By Site",
        "shift-event-log-result-summary.component.sections.shiftEventTypes" : "Shift Events by Shift Event Type",
        "shift-event-log-result-summary.component.sections.workplaces" : "Shift Events by Workplace",
        "shift-event-log-result-summary.component.sections.workplaces.noWorkplace" : "Without Workplace"
    },

    "de" : { 

        "shift-event-log-result-summary.component.sections.all" : "Schichtmeldungen nach Produktionsstandort",
        "shift-event-log-result-summary.component.sections.shiftEventTypes" : "Schichtmeldungen nach Meldungsart",
        "shift-event-log-result-summary.component.sections.workplaces" : "Schichtmeldungen nach Arbeitsplatz",
        "shift-event-log-result-summary.component.sections.workplaces.noWorkplace" : "Ohne Arbeitsplatz"
    }
}    
</i18n>