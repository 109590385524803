const TECHNICAL_SERVICES = {

    RELATIONAL_DB_SERVICE : {

        name: 'DatabaseConnectionsHealthCheck',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Tabellen Service (RDBMS)'
            },

            en: {
                languageTag: 'en',
                name: 'Relational DB Service'
            },
        }
    },

    DOCUMENT_DB_SERVICE: {
        name: 'ElasticsearchClusterHealthCheck',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Dokumenten-DB-Service (NoSQL DB)'
            },

            en: {
                languageTag: 'en',
                name: 'Document Store Service (NoSQL DB)'
            },
        }
    },

    IDENTITY_MGMT_SERVICE: {
        name: 'IdentityMgmtService',

        i18n: {

            de: {
                languageTag: 'de',
                name: 'Benutzer- und Identity-Service'
            },

            en: {
                languageTag: 'en',
                name: 'Identity Management Service'
            },
        }
    },

    MESSAGE_BROKER_SERVICE: {
        name: 'SmallRyeReactiveMessagingReadinessCheck',

        i18n: {

            de: {
                languageTag: 'de',
                name: 'Message Broker Service'
            },

            en: {
                languageTag: 'en',
                name: 'Message Broker Service'
            },
        }
    }
};

const APPLICATION_SERVICES = {

    MASTERDATA_SERVICE : {

        name: 'CatalogRestService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Stammdaten'
            },

            en: {
                languageTag: 'en',
                name: 'Masterdata'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.RELATIONAL_DB_SERVICE
        ]
    },

    QUERY_SERVICE : {

        name: 'QueryRestService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Analysen & Abfragen'
            },

            en: {
                languageTag: 'en',
                name: 'Analytics & Query'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.DOCUMENT_DB_SERVICE
        ]
    },

    INDEX_MGMT_SERVICE : {

        name: 'IndexRestService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Index Verwaltung'
            },

            en: {
                languageTag: 'en',
                name: 'Index Management'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.DOCUMENT_DB_SERVICE,
            TECHNICAL_SERVICES.MESSAGE_BROKER_SERVICE
        ]
    },

    DATA_PREPARATION_SERVICE : {

        name: 'DataPreparationService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Datenharmonisierung und -aufbereitung'
            },

            en: {
                languageTag: 'en',
                name: 'Data Preparation'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.DOCUMENT_DB_SERVICE,
            TECHNICAL_SERVICES.MESSAGE_BROKER_SERVICE
        ]
    },


    DATA_ARCHIVE_SERVICE : {

        name: 'DataArchivingService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Daten-Archivierung'
            },

            en: {
                languageTag: 'en',
                name: 'Data Archiving'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.DOCUMENT_DB_SERVICE,
            TECHNICAL_SERVICES.MESSAGE_BROKER_SERVICE
        ]
    },

    NOTIFICATION_SERVICE : {

        name: 'NotificationRestService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Benachrichtigungen'
            },

            en: {
                languageTag: 'en',
                name: 'Notification Service'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.DOCUMENT_DB_SERVICE,
            TECHNICAL_SERVICES.MESSAGE_BROKER_SERVICE
        ]
    },

    EVENT_BUS_SERVICE : {

        name: 'EventPublisherService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Ereignisse'
            },

            en: {
                languageTag: 'en',
                name: 'Event Bus'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.MESSAGE_BROKER_SERVICE
        ]
    },

    WEBHOOK_MGMT_SERVICE : {

        name: 'WebhookManagementService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Webhook Service'
            },

            en: {
                languageTag: 'en',
                name: 'Webhook Service'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.MESSAGE_BROKER_SERVICE
        ]
    },

    USER_MGMT_SERVICE:  {

        name: 'UserManagementService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Benutzerverwaltung'
            },

            en: {
                languageTag: 'en',
                name: 'User Management Service'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.IDENTITY_MGMT_SERVICE
        ]

    },
};

const GATEWAY_SERVICES = {

    API_GW_SERVICE : {

        name: 'API Gateway',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'API-Gateway'
            },

            en: {
                languageTag: 'en',
                name: 'API Gateway'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.IDENTITY_MGMT_SERVICE
        ]
    },

    DOCUMENT_GW_SERVICE : {

        name: 'DocumentImportService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Daten-Gateway'
            },

            en: {
                languageTag: 'en',
                name: 'Document Gateway'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.IDENTITY_MGMT_SERVICE,
            TECHNICAL_SERVICES.MESSAGE_BROKER_SERVICE,
            TECHNICAL_SERVICES.DOCUMENT_DB_SERVICE,
        ]
    },
}

const BACKGROUND_PROCESSES = {

    MAPPING_JOB_WORKER_SERVICE: {
        name: 'DocumentMappingJobWorkerService',
        i18n: {

            de: {
                languageTag: 'de',
                name: 'Mapping Job Worker Service'
            },

            en: {
                languageTag: 'en',
                name: 'Document Mapping Job Worker Service'
            },
        },

        dependsOn: [
            TECHNICAL_SERVICES.MESSAGE_BROKER_SERVICE
        ]
    },
}

export default {

    technicalServices: [
        TECHNICAL_SERVICES.DOCUMENT_DB_SERVICE,
        TECHNICAL_SERVICES.RELATIONAL_DB_SERVICE,
        TECHNICAL_SERVICES.IDENTITY_MGMT_SERVICE,
        TECHNICAL_SERVICES.MESSAGE_BROKER_SERVICE,
    ],

    applicationServices: [
        APPLICATION_SERVICES.MASTERDATA_SERVICE,
        APPLICATION_SERVICES.DATA_ARCHIVE_SERVICE,
        APPLICATION_SERVICES.DATA_PREPARATION_SERVICE,
        APPLICATION_SERVICES.EVENT_BUS_SERVICE,
        APPLICATION_SERVICES.INDEX_MGMT_SERVICE,
        APPLICATION_SERVICES.NOTIFICATION_SERVICE,
        APPLICATION_SERVICES.QUERY_SERVICE,
        APPLICATION_SERVICES.WEBHOOK_MGMT_SERVICE,
        APPLICATION_SERVICES.USER_MGMT_SERVICE
    ],
    
    backgroundProcesses: [
        BACKGROUND_PROCESSES.MAPPING_JOB_WORKER_SERVICE
    ],

    gatewayServices: [
        GATEWAY_SERVICES.API_GW_SERVICE,
        GATEWAY_SERVICES.DOCUMENT_GW_SERVICE,
    ]
}