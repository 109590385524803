import api from '@/store/api';

export default {

    namespaced: true,

    state: {

        schema: {
            name: null,
        }
    },

    mutations: {

        setSchema(state, schema) {

            if (schema) {
                state.schema.name = schema.name ? schema.name : null;
            } else {
                state.schema.name = null;
            }

        },   
    },

    actions: {
        
        async createSchema(context) {

            try {
                
                let request = context.getters.request;
                await api.catalog.schemas.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetSchema(context) {
            context.commit('setSchema', {name : null})
        },

        async updateSchema(context, schema) {
            
            try {
                context.commit('setSchema', schema);
            } catch (error) {
                return Promise.reject(error);
            }
        },

    },



    getters: {

        
        request(state) {
            return {

                name: state.schema.name,
                indexName: state.schema.name,
                attributes: {},
                i18n: {},
                creationDateTime: null,
                creationUser: null,
                modificationDateTime: null,
                modificationUser: null
            };
        },


        schema(state) {
            return state.schema ? state.schema : { name: null };
        }
    }
}