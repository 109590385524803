import HomeView from '@/core/views/portal.view.vue';

import TenantEditorView from '@/core/views/catalog/tenant/tenant-editor.view';

import CollectionSettingsListView from '@/core/views/catalog/collection-settings/collection-settings-list.view';
import CollectionSettingsAPIView from '@/core/views/catalog/collection-settings/collection-settings-api.view';
import CollectionSettingsWizardView from '@/core//views/catalog/collection-settings/collection-settings-wizard.view';
import CollectionSettingsEditorView from '@/core/views/catalog/collection-settings/collection-settings-editor.view';
import CollectionSettingsDashboardView from '@/core/views/catalog/collection-settings/collection-settings-dashboard.view';
import CollectionSettingsDataUploadView from '@/core/views/catalog/collection-settings/collection-settings-data-upload.view';

import UserListTemplatesView from '@/core/views/catalog/user-list-templates/user-list-templates-list.view';
import MyUserListTemplatesView from '@/core/views/catalog/user-list-templates/my-user-list-templates-list.view';
import UserListTemplatesEditorView from '@/core/views/catalog/user-list-templates/user-list-templates-editor.view';

import TenantListTemplatesView from '@/core/views/catalog/tenant-list-templates/tenant-list-templates-list.view';
import TenantListTemplatesEditorView from '@/core/views/catalog/tenant-list-templates/tenant-list-templates-editor.view';

import DetectionJobTemplatesWizardView from '@/core/views/catalog/detection-job-templates/detection-job-templates-wizard.view';
import DetectionJobTemplatesListView from '@/core/views/catalog/detection-job-templates/detection-job-templates-list.view';
import DetectionJobTemplatesEditorView from '@/core/views/catalog/detection-job-templates/detection-job-templates-editor.view';

import SystemCollectionSettingsListView from '@/core/views/catalog/system-collection-settings/system-collection-settings-list.view';

import NewSchemaWizardView from '@/core/views/catalog/schemas/new-schema-wizard.view';
import SchemaListView from '@/core/views/catalog/schemas/schema-list.view';
import SchemaEditorView from '@/core/views/catalog/schemas/schema-editor.view';


import DeadLetterDetailView from '@/core/views/integration/dead-letters/dead-letter-detail.view';

import DataPipelinesListView from '@/core/views/integration/data-pipelines/data-pipeline-list.view';

import UserGroupsListView from '@/core/views/user-mgmt/user-groups/user-groups-list.view';
import UserGroupAccountEditorView from '@/core/views/user-mgmt/user-group-accounts/user-group-accounts-editor.view';

import UsersListView from '@/core/views/user-mgmt/users/users-list.view';
import UserAccountEditorView from '@/core/views/user-mgmt/user-accounts/user-accounts-editor.view';
import UserAuthorizationDashboardView from '@/core/views/user-mgmt/user-authorization/user-authorization-dashboard.view';


import AccessControlListsListView from '@/core/views/user-mgmt/access-control/access-control-lists-list.view';
import AccessControlListsWizardView from '@/core/views/user-mgmt/access-control/access-control-lists-wizard.view';
import AccessControlListEditorView from '@/core/views/user-mgmt/access-control/access-control-lists-editor.view';

import MappingJobTemplatesEditorView from '@/core/views/catalog/mapping-job-templates/mapping-job-templates-editor.view';
import MappingJobTemplatesListView from '@/core/views/catalog/mapping-job-templates/mapping-job-templates-list.view';
import MappingJobTemplateWizardView from '@/core/views/catalog/mapping-job-templates/mapping-job-templates-wizard.view';

import MappingJobInstanceAdminDashboardView from '@/core/views/data-preparation/mapping-job-instances/mapping-job-instance-admin-dashboard.view';

import MappingJobInstanceDetailsView from '@/core/views/data-preparation/mapping-job-instances/mapping-job-instance-details.view';


import MetricsEditorView from '@/core/views/catalog/metrics/metrics-editor.view';

import CollectionView from '@/core/views/collection/collection.view';
import CollectionDocumentView from '@/core/views/collection/collection-documents.view';
import CollectionSeriesView from '@/core/views/collection/collection-series.view';
import CollectionTimeSeriesView from '@/core/views/collection/collection-time-series.view';
import CollectionSchemaView from '@/core/views/collection/collection-schema.view';
import CollectionCorrelationView from '@/core/views/collection/collection-correlation.view';
import CollectionDistributionViewOld from '@/core/views/collection/collection-distribution-old.view';
import CollectionDistributionView from '@/core/views/collection/collection-distribution.view';
import CollectionDistributionDimensionView from '@/core/views/collection/collection-distribution-dimension.view';
import CollectionKpiView from '@/core/views/collection/collection-kpis.view';
import CollectionStatisticsView from '@/core/views/collection/collection-statistics.view';
import CollectionMetricsView from '@/core/views/collection/collection-metrics.view';

import CollectionTimeSeriesPrint from '@/core/views/collection/collection-time-series.print';
import CollectionSeriesPrint from '@/core/views/collection/collection-series.print';
import CollectionDistributionPrint from '@/core/views/collection/collection-distribution.print';

import CollectionListView from '@/core/views/collection/collection-list.view';

import TimeSeriesExplorerView from '@/core/views/time-series/time-series-explorer.view.vue';
import TimeSeriesCockpitView from '@/core/views/time-series/time-series-cockpit.view.vue';

import DocumentView from '@/core/views/document/document.view';
import DocumentMeasureDashboardView from '@/core/views/document/document-measure-dashboard.view';
import DocumentChangeLogView from '@/core/views/document/document-change-log.view.vue';
import DocumentChangeLogItemView from '@/core/views/document/document-change-log-item.view.vue';

import PrintView from '@/views/print.view';


import PredictionScenarioListView from '@/views/prediction/prediction-scenario-list.view';
import PredictionScenarioDetailView from '@/views/prediction/prediction-scenario-detail.view';
import PredictionScenarioWizardView from '@/views/prediction/prediction-scenario-wizard.view';
import PredictionModelWizardView from '@/views/prediction/prediction-model-wizard.view';
import PredictionModelDashboardView from '@/views/prediction/prediction-model-dashboard.view';

import NotificationsDashboardView from '@/core/views/notifications/notifications-dashboard.view';
import NotificationDetailView from '@/core/views/notifications/notification-detail.view';

import UserInfoView from '@/core/views/user-mgmt/users/user-info.view';
import SystemParamsView from '@/core/views/catalog/system-params/system-params.view';
import ServiceAvailabilityView from '@/core/views/system/service-availability-dashboard.view.vue';
import ServiceLogItemListView from '@/core/views/system/service-log/service-log-item-list.view.vue';
import ServiceLogItemDetailView from '@/core/views/system/service-log/service-log-item-detail.view.vue';
//import SettingsView from '@/core/views/system/settings/settings.view';

import TagWizardView from '@/core/views/catalog/tags/tags-wizard.view';
import TagListView from '@/core/views/catalog/tags/tags-list.view';
import TagEditorView from '@/core/views/catalog/tags/tags-editor.view';

import UploadWizardView from '@/core/views/uploads/upload-wizard.view';


import WebhookExecutionLogDetailView from '@/core/views/webhooks/webhook-execution-log/webhook-execution-log-detail.view';

import WebhookSubscriptionsListView from '@/core/views/webhooks/webhook-subscriptions/webhook-subscriptions-list.view';
import WebhookSubscriptionsEditorView from '@/core/views/webhooks/webhook-subscriptions/webhook-subscriptions-editor.view';
import WebhookSubscriptionsWizardView from '@/core/views/webhooks/webhook-subscriptions/webhook-subscriptions-wizard.view';

import QueryLogDetailView from '@/core/views/system/query-log/query-log-detail.view';





import Roles from '@/roles';

import Vue from 'vue';

export default [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/print',
        name: 'printView',
        component: PrintView,
        children: [
            {
                path: 'collectionTimeSeriesPrint',
                name: 'collectionTimeSeriesPrint',
                components: { printView: CollectionTimeSeriesPrint }
            },
            {
                path: 'collectionSeriesPrint',
                name: 'collectionSeriesPrint',
                components: { printView: CollectionSeriesPrint }
            },
            {
                path: 'collectionDistributionPrint',
                name: 'collectionDistributionPrint',
                components: { printView: CollectionDistributionPrint }
            }
        ]
    },
    {
        path: '/notifications',
        name: 'notificationsDashboard',
        component: NotificationsDashboardView,
        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Notifications'        
                    }, 

                    de: {        
                        name: 'Benachrichtigungen'        
                    }        
                }        
            },        
        }
    },
    {
        path: '/me/list-templates',
        name: 'myUserListTemplatesList',
        component: MyUserListTemplatesView,
        meta: {
            navigation: {

                categoryId: 'amentis-dxs-me',
              
                icon: 'bookmarks',
                order: 160,

                i18n: {

                    'en': {

                        name: 'My List Templates'
                    },

                    'de': {
                        name: 'Meine Listenvorlagen'
                    }
                }
            },
        }
    },
    {
        path: '/me/user-account',
        name: 'MyUserDetailView',
        component: UserAccountEditorView,
        props: () => ({ value: { name: Vue.$user.userName } }),

        meta: {
            navigation: {        

                categoryId: 'amentis-dxs-me',
                icon: 'person',

                i18n: {        
                    en: {        
                        name: 'My User Details'        
                    }, 
    
                    de: {        
                        name: 'Mein Benutzerkonto'        
                    }        
                }        
            },        
            
            authorization: {

                roles: [Roles.USERS_READ]
            }
        }
    },
    {
        path: '/me/user-authorization',
        name: 'MyUserAuthorizationDashboardView',
        component: UserAuthorizationDashboardView,
        props: () => ({ value: { name: Vue.$user.userName } }),

        meta: {
            navigation: {  
                
                categoryId: 'amentis-dxs-me',
                icon: 'badge',

                i18n: {        
                    en: {        
                        name: 'My User Authorization Dashboard'        
                    }, 
    
                    de: {        
                        name: 'Meine Benutzer-Berechtigungen'        
                    }        
                }        
            },        
            
            authorization: {

                roles: [Roles.USERS_READ]
            }
        }
    },

    {
        path: '/notifications/:notificationId',
        name: 'notificationDetail',
        component: NotificationDetailView,
        props: (route) => ({ value: { notificationId: route.params.notificationId } }),
        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Notification Details'        
                    }, 

                    de: {        
                        name: 'Nachricht Detailansicht'        
                    }        
                }        
            },        
        }
    },
    {
        path: '/catalog/params',
        name: 'SystemParams',
        component: SystemParamsView,
        meta: {
            navigation: {

                categoryId: 'amentis-dxs-catalog',
              
                icon: 'settings',
                order: 160,

                i18n: {

                    'en': {

                        name: 'Parameters'
                    },

                    'de': {
                        name: 'Parameter'
                    }
                }
            },

            authorization: {
                roles: [Roles.SYSTEM_PARAMETERS_READ]
            }
        }

    },
    {
        path: '/catalog/tags/new',
        name: 'TagWizardView',
        component: TagWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1000,
    
                i18n: {
    
                    'en': {
    
                        name: 'Create new Tag'
                    },
    
                    'de': {
                        name: 'Neues Tag anlegen'
                    }
                }
            },
    
            authorization: {
    
                roles: [Roles.ACCESS_CONTROL_LISTS_CREATE]
            }
        }
    },
    {
        path: '/catalog/tags',
        name: 'TagListView',
        component: TagListView,
        meta: {
            navigation: {

                categoryId: 'amentis-dxs-catalog',
              
                icon: 'label_important',
                order: 180,

                i18n: {

                    'en': {

                        name: 'Tags'
                    },

                    'de': {
                        name: 'Tags'
                    }
                }
            },

            authorization: {
                roles: [Roles.SYSTEM_PARAMETERS_READ]
            }
        }


    },
    {
        path: '/catalog/tags/:name',
        name: 'TagEditorView',
        component: TagEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {
            navigation: {

                i18n: {

                    'en': {

                        name: 'Tags Details'
                    },

                    'de': {
                        name: 'Tags Detailansicht'
                    }
                }
            },
        }


    },
    {
        path: '/webhooks/webhook-subscriptions',
        name: 'webhookSubscriptionsList',
        component: WebhookSubscriptionsListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-webhooks',
                icon: 'mark_email_read',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Webhook Subscriptions'
                    },

                    'de': {
                        name: 'Webhook-Abonnements'
                    }
                }
            },

            authorization: {

                roles: [Roles.WEBHOOKS_SUBSCRIPTIONS_READ]
            }
            
        }
    },
    {
        path: '/webhooks/webhook-subscriptions/new',
        name: 'webhookSubscriptionsWizard',
        component: WebhookSubscriptionsWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 600,
    
                i18n: {
    
                    'en': {
    
                        name: 'Create new Webhook Subscription'
                    },
    
                    'de': {
                        name: 'Neues Webhook-Abonnement anlegen'
                    }
                }
            },
    
            authorization: {

                roles: [Roles.WEBHOOKS_SUBSCRIPTIONS_CREATE]
            }
        }
    },
    {
        path: '/webhooks/webhook-subscriptions/:name',
        name: 'webhookSubscriptionsEditor',
        component: WebhookSubscriptionsEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        
        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Webhook Subscription Details'
                    }, 
    
                    de: {        
                        name: 'Webhook-Abonnements Detailansicht'
                    }        
                }     
            }       
        }
    },
    {
        path: '/webhooks/webhook-execution-log/',
        name: 'webhookExecutionsList',
        redirect: {
            name: 'collectionDocumentsView',
            params: { collectionId: 'WebhookExecutionLog' }
        },

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-webhooks',
                icon: 'list',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Webhook Log'
                    },

                    'de': {
                        name: 'Webhook-Protokoll'
                    }
                }
            },


            
            authorization: {

                roles: [Roles.WEBHOOKS_SUBSCRIPTIONS_READ]
            }
            
        }
    },
    {
        path: '/webhooks/webhook-execution-log/:id',
        name: 'webhookExecutionLogDetailView',
        component: WebhookExecutionLogDetailView,
        props: (route) => ({ 
            value: { 
                collectionId: 'WebhookExecutionLog',
                documentId: route.params.id 
            } 
        }),
        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Webhook Log Details'
                    }, 
    
                    de: {        
                        name: 'Webhook-Protokoll'
                    }        
                }     
            }       
        }
    },
    /*
    {
        path: '/system/service-logs',
        name: 'serviceLog',
        
        redirect: {
            name: 'collectionDocumentsView',
            params: { collectionId: 'Log' }
        },
        
        meta: {

            navigation: {

                categoryId: 'amentis-dxs-system',
                icon: 'bug_report',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Service Log'
                    },

                    'de': {
                        name: 'Service Log'
                    }
                }
            },


            authorization: {

                roles: [Roles.SYSTEM_LOGS_READ]
            }
        }
    },
    */
    {
        path: '/system/service-logs',
        name: 'serviceLogItemList',
        component: ServiceLogItemListView,
        props: () => ({ value: {collectionId: 'Log' } }),
        
        meta: {

            navigation: {

                categoryId: 'amentis-dxs-system',
                icon: 'bug_report',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Service Log'
                    },

                    'de': {
                        name: 'Service-Protokoll'
                    }
                }
            },


            authorization: {

                roles: [Roles.SYSTEM_LOGS_READ]
            }
        }
    },
    {
        path: '/system/document-change-log/',
        name: 'documentChangeLog',
        redirect: {
            name: 'collectionDocumentsView',
            params: { collectionId: 'DocumentChangeLog' }
        },

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-system',
                icon: 'history',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Document Change Log'
                    },

                    'de': {
                        name: 'Änderungsprotokoll'
                    }
                }
            },

            authorization: {

                roles: [Roles.SYSTEM_CHANGELOG_READ]
            }
            
        }
    },
    {
        path: '/system/query-log/',
        name: 'queryLog',
        redirect: {
            name: 'collectionDocumentsView',
            params: { collectionId: 'QueryLog' }
        },

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-system',
                icon: 'query_stats',
                order: 250,

                i18n: {

                    'en': {

                        name: 'Collection Access Log'
                    },

                    'de': {
                        name: 'Zugriffsprotokoll'
                    }
                }
            },

            authorization: {

                roles: [Roles.SYSTEM_QUERYLOG_READ]
            }
            
        }
    },
    {
        path: '/system/query-log/:id',
        name: 'queryLogDetailView',
        component: QueryLogDetailView,
        props: (route) => ({ 
            value: { 
                collectionId: 'QueryLog',
                documentId: route.params.id 
            } 
        })
    },
    {
        path: '/system/service-logs/:id',
        name: 'serviceLogItemDetail',
        component: ServiceLogItemDetailView,
        props: (route) => ({ 
            value: { 
                collectionId: 'Log',
                documentId: route.params.id 
            } 
        }),

        meta: {

            navigation: {

                i18n: {

                    'en': {
                        name: 'Service Log Item'
                    },

                    'de': {
                        name: 'Service Log Eintrag'
                    }
                }
            },

            authorization: {
                roles: [Roles.SYSTEM_LOGS_READ]
            }
        }
    },
    {
        path: '/system/serviceAvailability',
        name: 'serviceAvailability',
        component: ServiceAvailabilityView,
        meta: {

            navigation: {

                categoryId: 'amentis-dxs-system',
                icon: 'healing',
                order: 300,

                i18n: {

                    'en': { name: 'Service Availability' },
                    'de': { name: 'Serviceverfügbarkeit' }
                }
            },


            authorization: {
                roles: [Roles.SYSTEM_SERVICEAVAILABILITY_READ]
            }
        }
    },

    /*
    {
        path: '/system/settings',
        name: 'settings',
        component: SettingsView,
        meta: {

            navigation: {

                categoryId: 'amentis-dxs-system',
                icon: 'settings',
                order: 200,

                i18n: {

                    'en' : {

                        name: 'System Settings'
                    },

                    'de' : {
                        name: 'System-Einstellungen'
                    }
                }
            },


            authorization: {

                roles: [Roles.SYSTEM_LOGS_READ]
            }
        }
    },
    */
    {
        path: '/fileUploads/new',
        name: 'FileUpload',
        component: UploadWizardView,
        meta: {
            navigation: {

                categoryId: 'amentis-dxs-integration',
                icon: 'file_upload',
                order: 200,

                i18n: {

                    'en': {

                        name: 'File Upload'
                    },

                    'de': {
                        name: 'Datei hochladen'
                    }
                }
            },
            authorization: {

                roles: [Roles.COLLECTIONS_UPLOAD]
            }
        }

    },
    {
        path: '/integration/data-pipelines',
        name: 'DataPipelineList',
        component: DataPipelinesListView,
        meta: {
            navigation: {

                categoryId: 'amentis-dxs-integration',
                icon: 'start',
                order: 100,

                i18n: {

                    'en': {

                        name: 'Data Pipelines'
                    },

                    'de': {
                        name: 'Daten-Pipelines'
                    }
                }
            },
            authorization: {

                roles: [Roles.PIPELINES_READ]
            }
        }

    },
    {
        path: '/integration/deadLetters',
        name: 'deadLetterList',
        redirect: {
            name: 'collectionDocumentsView',
            params: { collectionId: 'DeadLetterDocuments' }
        },
        meta: {
            navigation: {

                categoryId: 'amentis-dxs-integration',
                icon: 'sms_failed',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Invalid Documents'
                    },

                    'de': {
                        name: 'Ungültige Dokumente'
                    }
                }
            },
            authorization: {

                roles: [Roles.DEADLETTERS_READ]
            }
        }
    },
    {
        path: '/integration/deadLetters/documents/:documentId',
        name: 'deadLetterDetail',
        component: DeadLetterDetailView,
        props: (route) => ({ value: { documentId: route.params.documentId } })
    },
    {
        path: '/catalog/tenant',
        name: 'tenantEditor',
        component: TenantEditorView,
        meta: {

            navigation: {

                categoryId: 'amentis-dxs-catalog',
                icon: 'other_houses',
                order: 100,

                i18n: {

                    'en': {
                        name: 'Tenant'
                    },

                    'de': {
                        name: 'Mandant'
                    }
                },
            },

            authorization: {
                roles: [Roles.TENANT_READ]
            }
        }
    },
    {
        path: '/catalog/schemas',
        name: 'schemaList',
        component: SchemaListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-catalog',
                icon: 'schema',
                order: 300,

                i18n: {

                    'en': {

                        name: 'Schemas'
                    },

                    'de': {
                        name: 'Schemata'
                    }
                }
            },

            authorization: {

                roles: [Roles.SCHEMAS_READ]
            }
        }
    },
    {
        path: '/catalog/schemas/new',
        name: 'newSchemaWizard',
        component: NewSchemaWizardView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Create new Schema'
                    },

                    'de': {
                        name: 'Neues Schema anlegen'
                    }
                }
            },

            authorization: {

                roles: [Roles.SCHEMAS_CREATE]
            }
        }
    },
    {
        path: '/catalog/schemas/:name',
        name: 'schemaEditor',
        component: SchemaEditorView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Schema Details'        
                    }, 
    
                    de: {        
                        name: 'Schema Detailansicht'        
                    }        
                }        
            },

            authorization: {

                roles: [Roles.SCHEMAS_READ]
            }
        }
    },
    {
        path: '/catalog/collectionSettings',
        name: 'collectionSettingsList',
        component: CollectionSettingsListView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-catalog',
                icon: 'dataset',
                order: 200,

                i18n: {

                    'en': {

                        name: 'Collections'
                    },

                    'de': {
                        name: 'Datenbereiche'
                    }
                }
            },

            authorization: {

                roles: [Roles.COLLECTION_SETTINGS_READ]
            }
        }
    },
    {
        path: '/catalog/collectionSettings/new',
        name: 'collectionSettingsWizardView',
        component: CollectionSettingsWizardView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 150,

                i18n: {

                    'en': {

                        name: 'Create new Collection Setting'
                    },

                    'de': {
                        name: 'Neuen Datenbereich anlegen'
                    }
                }
            },

            authorization: {

                roles: [Roles.COLLECTION_SETTINGS_CREATE]
            }

        }
    },

    {
        path: '/catalog/collectionSettings/:name',
        name: 'collectionSettingsEditor',
        component: CollectionSettingsEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Collection Settings Details'        
                    }, 

                    de: {        
                        name: 'Datenbereich Detailansicht'        
                    }        
                }        
            },        
        }
    },
    {
        path: '/catalog/collectionSettings/:name/api',
        name: 'collectionSettingsAPI',
        component: CollectionSettingsAPIView,
        props: (route) => ({ value: { name: route.params.name } })
    },
    {
        path: '/catalog/collectionSettings/:name/upload',
        name: 'CollectionSettingsDataUpload',
        component: CollectionSettingsDataUploadView,
        props: (route) => ({ value: { name: route.params.name } })    
    },
    
    {
        path: '/catalog/system-collection-settings',
        name: 'systemCollectionSettingsList',
        component: SystemCollectionSettingsListView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-catalog',
                icon: 'dataset',
                order: 220,

                i18n: {

                    'en': {

                        name: 'System Collections'
                    },

                    'de': {
                        name: 'System-Datenbereiche'
                    }
                }
            },

            authorization: {

                roles: [Roles.COLLECTION_SETTINGS_READ]
            }
        }
    },
    {
        path: '/catalog/userlisttemplates',
        name: 'userListTemplatesList',
        component: UserListTemplatesView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-catalog',
                icon: 'bookmarks',
                order: 300,

                i18n: {

                    'en': {

                        name: 'User List Templates'
                    },

                    'de': {
                        name: 'Listenvorlagen (Benutzer)'
                    }
                }
            },

            authorization: {

                roles: [Roles.USER_LIST_TEMPLATES_READ]
            }
        }
    },
    {
        path: '/catalog/userlisttemplates/:name',
        name: 'userListTemplatesEditor',
        component: UserListTemplatesEditorView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Template Details'        
                    }, 
    
                    de: {        
                        name: 'Vorlagen Detailansicht'        
                    }        
                }        
            },

            authorization: {

                roles: [Roles.USER_LIST_TEMPLATES_READ]
            }
        }
    },
    {
        path: '/me/list-templates/:name',
        name: 'myUserListTemplatesEditor',
        component: UserListTemplatesEditorView,
        props: (route) => ({ value: { name: route.params.name }, verifyUser: true}),

        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Template Details'        
                    }, 
    
                    de: {        
                        name: 'Vorlagen Detailansicht'        
                    }        
                }        
            }
        }
    },
    /**
     * This route will load the associated template and redirect users to the
     * collection cockpit with applied settings.
     */
    {
        path: '/catalog/userlisttemplates/:name/documents',
        name: 'userListTemplatesDocuments',
        component: UserListTemplatesEditorView,
        props: (route) => ({ value: { template: route.params.template, name: route.params.name } }),


    },
    {
        path: '/catalog/tenantlisttemplates',
        name: 'tenantListTemplatesList',
        component: TenantListTemplatesView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-catalog',
                icon: 'bookmarks',
                order: 300,

                i18n: {

                    'en': {

                        name: 'Tenant List Templates'
                    },

                    'de': {
                        name: 'Listenvorlagen (Mandant)'
                    }
                }
            },

            authorization: {

                roles: [Roles.TENANT_LIST_TEMPLATES_READ]
            }
        }
    },
    {
        path: '/catalog/tenantlisttemplates/:name',
        name: 'tenantListTemplatesEditor',
        component: TenantListTemplatesEditorView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Template Details'        
                    }, 
    
                    de: {        
                        name: 'Vorlagen Detailansicht'        
                    }        
                }        
            },

            authorization: {

                roles: [Roles.TENANT_LIST_TEMPLATES_READ]
            }
        }
    },

    {
        path: '/catalog/detectionjobtemplates/new',
        name: 'detectionJobTemplatesWizard',
        component: DetectionJobTemplatesWizardView,

        meta: {
            
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 500,

                i18n: {

                    'en' : {

                        name: 'Create new Mapping Job Template'
                    },

                    'de' : {
                        name: 'Neue Mapping Job Vorlage anlegen'
                    }
                }
            },
            
            authorization: {

                roles: [Roles.DETECTION_JOB_TEMPLATES_CREATE]
            }

        }
    },
    {
        path: '/catalog/detectionjobtemplates',
        name: 'detectionJobTemplatesList',
        component: DetectionJobTemplatesListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-catalog',
                icon: 'crisis_alert',
                order: 300,

                i18n: {

                    'en': {

                        name: 'Detection Job Templates'
                    },

                    'de': {
                        name: 'Detection Job Vorlagen'
                    }
                }
            },

            authorization: {

                roles: [Roles.DETECTION_JOB_TEMPLATES_READ]
            }
        }
    },
    {
        path: '/catalog/detectionjobtemplates/:name',
        name: 'detectionJobTemplatesEditor',
        component: DetectionJobTemplatesEditorView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Detection Job Details'        
                    }, 
    
                    de: {        
                        name: 'Detection Job Detailansicht'        
                    }        
                }        
            },

            authorization: {

                roles: [Roles.DETECTION_JOB_TEMPLATES_READ]
            }
        }
    },
    
    // CollectionSettingsDashboardView
    {
        path: '/catalog/system-collection-settings/:name',
        name: 'systemCollectionSettingsDashboard',
        component: CollectionSettingsDashboardView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'System Collection Settings Details'        
                    }, 

                    de: {        
                        name: 'System-Datenbereich Detailansicht'        
                    }        
                }        
            },        
        }
    },
    {
        path: '/catalog/mappingJobTemplates',
        name: 'mappingJobTemplatesList',
        component: MappingJobTemplatesListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-datapreparation',
                icon: 'mediation',
                order: 150,

                i18n: {

                    'en': {
                        name: 'Mapping Jobs Templates'
                    },

                    'de': {
                        name: 'Mapping Job Vorlagen'
                    }
                }
            },

            authorization: {

                roles: [Roles.MAPPING_JOB_TEMPLATES_READ]
            }

        }
    },

    {
        path: '/catalog/mappingJobTemplates/new',
        name: 'mappingJobTemplatesWizard',
        component: MappingJobTemplateWizardView,

        meta: {
            
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 500,

                i18n: {

                    'en' : {

                        name: 'Create new Mapping Job Template'
                    },

                    'de' : {
                        name: 'Neue Mapping Job Vorlage anlegen'
                    }
                }
            },
            
            authorization: {

                roles: [Roles.COLLECTION_SETTINGS_CREATE]
            }

        }
    },
    {
        path: '/catalog/mappingJobTemplates/:name',
        name: 'mappingJobTemplatesEditor',
        component: MappingJobTemplatesEditorView,
        props: (route) => ({ value: { name: route.params.name } }),
        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'Mapping Job Templates Details'        
                    }, 

                    de: {        
                        name: 'Mapping Job Vorlage Detailansicht'        
                    }        
                }        
            },        
        }
    },

    {
        path: '/usermgmt/access-control-lists/new',
        name: 'accessControlListsWizard',
        component: AccessControlListsWizardView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-wizards',
                icon: 'add',
                order: 1000,
    
                i18n: {
    
                    'en': {
    
                        name: 'Create new Access Control List'
                    },
    
                    'de': {
                        name: 'Neue Zugriffsberechtigung anlegen'
                    }
                }
            },
    
            authorization: {
    
                roles: [Roles.ACCESS_CONTROL_LISTS_CREATE]
            }
        }
    },
    {
        path: '/usermgmt/access-control-lists',
        name: 'accessControlListsList',
        component: AccessControlListsListView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-usermgmt',
                icon: 'badge',
                order: 1000,

                i18n: {

                    'en': {

                        name: 'Access Control Lists'
                    },

                    'de': {
                        name: 'Zugriffsberechtigungen'
                    }
                }
            },

            authorization: {

                roles: [Roles.ACCESS_CONTROL_LISTS_READ]
            }
        }
    },
    {
        path: '/usermgmt/access-control-lists/:name',
        name: 'accessControlListsEditor',
        component: AccessControlListEditorView,

        meta: {
            navigation: {

                i18n: {

                    'en': {

                        name: 'Access Control List Details'
                    },

                    'de': {
                        name: 'Zugriffsberechtigungen Detailansicht'
                    }
                }
            },

            authorization: {

                roles: [Roles.ACCESS_CONTROL_LISTS_READ]
            }
        },

        props: (route) => ({ value: { name: route.params.name } }),

    },

    {
        path: '/usermgmt/users',
        name: 'usersList',
        component: UsersListView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-usermgmt',
                icon: 'person',
                order: 300,

                i18n: {

                    'en': {

                        name: 'Users'
                    },

                    'de': {
                        name: 'Benutzer'
                    }
                }
            },

            authorization: {

                roles: [Roles.USERS_READ]
            }
        }
    },
    {
        path: '/usermgtm/users/:name',
        name: 'usersDetail',
        component: UserAccountEditorView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'User Details'        
                    }, 
    
                    de: {        
                        name: 'Benutzer Detailansicht'        
                    }        
                }        
            },        
            
            authorization: {

                roles: [Roles.USERS_READ]
            }
        }
    },

    //UserAuthorizationDashboardView
    {
        path: '/usermgtm/users/:name/authorization',
        name: 'UserAuthorizationDashboardView',
        component: UserAuthorizationDashboardView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'User Authorization Dashboard'        
                    }, 
    
                    de: {        
                        name: 'Benutzer-Berechtigungen'        
                    }        
                }        
            },        
            
            authorization: {

                roles: [Roles.USERS_READ]
            }
        }
    },

    {
        path: '/usermgmt/userGroups',
        name: 'userGroupsList',
        component: UserGroupsListView,

        meta: {
            navigation: {

                categoryId: 'amentis-dxs-usermgmt',
                icon: 'group',
                order: 300,

                i18n: {

                    'en': {

                        name: 'User Groups'
                    },

                    'de': {
                        name: 'Benutzergruppen'
                    }
                }
            },

            authorization: {

                roles: [Roles.USER_GROUPS_READ]
            }
        }
    },

    {
        path: '/usermgmt/userGroups/:name',
        name: 'userGroupsDetail',
        component: UserGroupAccountEditorView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'User Groups Details'        
                    }, 
    
                    de: {        
                        name: 'Benutzergruppen Detailansicht'        
                    }        
                }        
            },        

            authorization: {

                roles: [Roles.USER_GROUPS_READ]
            }
        }
    },
    {
        path: '/catalog/metrics/:name',
        name: 'metricsEditor',
        component: MetricsEditorView,
        props: (route) => ({ value: { name: route.params.name } })

    },
    {
        path: '/data-preparation/mapping-jobs/admin-dashboard',
        name: 'mappingJobInstanceAdminDashboard',
        component: MappingJobInstanceAdminDashboardView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-datapreparation',
                icon: 'fact_check',
                order: 100,

                i18n: {

                    'en': {
                        name: 'Mapping Jobs Dashboard'
                    },

                    'de': {
                        name: 'Mapping Jobs Dashboard'
                    }
                }
            },

            authorization: {

                roles: [Roles.MAPPING_JOB_TEMPLATES_READ]
            }

        }
    },
    {
        path: '/data-preparation/mapping-jobs/instances',
        name: 'mappingJobInstances',
        redirect: '/collections/MappingJobLog/documents',

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-datapreparation',
                icon: 'view_list',
                order: 300,

                i18n: {

                    'en': {
                        name: 'Job Run History'
                    },

                    'de': {
                        name: 'Ausgeführte Prozesse'
                    }
                }
            },

            authorization: {

                roles: [Roles.MAPPING_JOB_TEMPLATES_READ]
            }

        }
    },
    {
        path: '/data-preparation/mapping-jobs/instances/:name',
        name: 'mappingJobInstanceDetails',
        component: MappingJobInstanceDetailsView,
        props: (route) => ({ value: { name: route.params.name } }),

        meta: {
            navigation: {

                i18n: {
    
                    en: {
                        name: 'Job Dashboard'
                    },
    
                    de: {
                        name: 'Dashboard Prozess'
                    }
                }
            },
        }
        
    },
    {
        path: '/collections',
        name: 'collectionList',
        component: CollectionListView,

        meta: {

            navigation: {

                categoryId: 'amentis-dxs-dataexploration',
                icon: 'dataset',
                order: 150,

                i18n: {

                    'en': {

                        name: 'All Collections'
                    },

                    'de': {
                        name: 'Alle Datenbereiche'
                    }
                }
            },

        }
    },

    {
        path: '/collections/:collectionId/',
        name: 'collection',
        component: CollectionView,
        props: (route) => ({ value: { collectionId: route.params.collectionId, dev: route.query.dev } }),
        children: [
            {
                path: 'documents',
                name: 'collectionDocumentsView',
                components: { collectionRouterView: CollectionDocumentView },
                props: { collectionRouterView: (route) => ({ value: route.params }) },
                meta: {
                    navigation: {
        
                        i18n: {
            
                            en: {
                                name: 'Collection > Document List'
                            },
            
                            de: {
                                name: 'Datenbereiche > Dokumentenliste'
                            }
                        }
                    },
                }
            },
            {
                path: 'timeseries',
                name: 'collectionTimeSeriesView',
                components: { collectionRouterView: CollectionTimeSeriesView },
                props: { collectionRouterView: (route) => ({ value: route.params }) },

                meta: {
                    navigation: {
        
                        i18n: {
            
                            en: {
                                name: 'Collection > Time Series'
                            },
            
                            de: {
                                name: 'Datenbereiche > Zeitreihen'
                            }
                        }
                    },
                }
            },
            {
                path: 'series',
                name: 'collectionSeriesView',
                components: { collectionRouterView: CollectionSeriesView },
                props: { collectionRouterView: (route) => ({ value: route.params }) },

                meta: {
                    navigation: {        
                        i18n: {        
                            en: {        
                                name: 'Collection > Series'        
                            }, 
        
                            de: {        
                                name: 'Datenbereiche > Datenreihen'        
                            }        
                        }        
                    },        
                }
            },
            {
                path: 'stats',
                name: 'collectionStatisticsView',
                components: { collectionRouterView: CollectionStatisticsView },
                props: { collectionRouterView: (route) => ({ value: route.params }) },
                meta: {
                    navigation: {
        
                        i18n: {
            
                            en: {
                                name: 'Collection > Statistics'
                            },
            
                            de: {
                                name: 'Datenbereiche > Statistiken'
                            }
                        }
                    },
                }
            },
            {
                path: 'kpis',
                name: 'collectionKpiView',
                components: { collectionRouterView: CollectionKpiView },
                props: { collectionRouterView: (route) => ({ value: route.params }) }
            },
            {
                path: 'metrics',
                name: 'collectionMetricsView',
                components: { collectionRouterView: CollectionMetricsView },
                props: { collectionRouterView: (route) => ({ value: route.params }) },
                meta: {
                    navigation: {
        
                        i18n: {
            
                            en: {
                                name: 'Collection > Metrics'
                            },
            
                            de: {
                                name: 'Datenbereiche > Metriken'
                            }
                        }
                    },
                }
            },
            {
                path: 'meta',
                name: 'collectionMetaView',
                components: { collectionRouterView: CollectionSchemaView },
                props: { collectionRouterView: (route) => ({ value: route.params }) },
                meta: {
                    navigation: {        
                        i18n: {        
                            en: {        
                                name: 'Collection > Meta Data'        
                            }, 
        
                            de: {        
                                name: 'Datenbereiche > Metadaten'        
                            }        
                        }        
                    },        
                }
            },
            {
                path: 'correlation',
                name: 'collectionCorrelationView',
                components: { collectionRouterView: CollectionCorrelationView },
                props: { collectionRouterView: (route) => ({ value: route.params }) }
            },
            /**
             * For reasons of backward compatibility
             */
            {
                path: 'distribution-old',
                name: 'collectionDistributionViewOld',
                components: { collectionRouterView: CollectionDistributionViewOld },
                props: { collectionRouterView: (route) => ({ value: route.params }) },
                meta: {
                    navigation: {        
                        i18n: {        
                            en: {        
                                name: 'Collection > Distribution'        
                            }, 
        
                            de: {        
                                name: 'Datenbereiche > Verteilung'        
                            }        
                        }        
                    },        
                }
            },
            {
                path: 'distribution',
                name: 'collectionDistributionView',
                components: { collectionRouterView: CollectionDistributionView },
                props: { collectionRouterView: (route) => ({ value: route.params }) },
                meta: {
                    navigation: {        
                        i18n: {        
                            en: {        
                                name: 'Collection > Distribution'        
                            }, 
        
                            de: {        
                                name: 'Datenbereiche > Datenverteilung'        
                            }        
                        }        
                    },        
                }    
            },
            {
                path: 'dimensions/:dimensionId/distribution',
                name: 'collectionDimensionDistribution',
                components: { collectionRouterView: CollectionDistributionDimensionView },
                props: { collectionRouterView: (route) => ({ value: route.params }) },
                meta: {
                    navigation: {        
                        i18n: {        
                            en: {        
                                name: 'Collection > Distribution > Dimension'        
                            }, 
        
                            de: {        
                                name: 'Datenbereiche > Datenverteilung > Dimension'        
                            }        
                        }        
                    },        
                }    
            }

        ],

        meta: {
        }
    },

    {
        path: '/collections/:collectionId/documents/:documentId',
        name: 'documentView',
        component: DocumentView,
        props: (route) => ({ value: { collectionId: route.params.collectionId, documentId: route.params.documentId } }),
        meta: {
            navigation: {

                i18n: {
    
                    en: {
                        name: 'Document Details'
                    },
    
                    de: {
                        name: 'Detailansicht Dokument'
                    }
                }
            },
        }
    },
    {
        path: '/collections/:collectionId/documents/:documentId/measure-dashboard',
        name: 'documentMeasureDashboardView',
        component: DocumentMeasureDashboardView,
        props: (route) => ({ value: { collectionId: route.params.collectionId, documentId: route.params.documentId} }),
    },
    {
        path: '/collections/:collectionId/documents/:documentId/changelog',
        name: 'documentChangeLogView',
        component: DocumentChangeLogView,
        props: (route) => ({ value: { collectionId: route.params.collectionId, documentId: route.params.documentId} }),

        meta: {
            authorization: {
                roles: [Roles.COLLECTIONS_CHANGELOG_READ]
            }
        }
    },
    {
        path: '/collections/:collectionId/documents/:documentId/changelog/:changeLogDocumentId',
        name: 'documentChangeLogItemView',
        component: DocumentChangeLogItemView,
        props: (route) => ({ value: { collectionId: route.params.collectionId, documentId: route.params.documentId, changeLogDocumentId: route.params.changeLogDocumentId} }),

        meta: {
            authorization: {
                roles: [Roles.COLLECTIONS_CHANGELOG_READ]
            }
        }
    },
    {
        path: '/time-series',
        name: 'timeSeriesExplorer',
        component: TimeSeriesExplorerView,
    },
    {
        path : '/time-series/:name/cockpit',
        name : 'timeSeriesCockpit',
        component: TimeSeriesCockpitView
    },
    {
        path: '/prediction-scenarios',
        name: 'predictionScenarioListView',
        component: PredictionScenarioListView,
    },
    {
        path: '/prediction-scenarios/new',
        name: 'predictionScenarioWizardView',
        component: PredictionScenarioWizardView
    },
    {
        path: '/prediction-scenarios/:scenarioId',
        name: 'predictionScenarioDetailView',
        component: PredictionScenarioDetailView,
        props: (route) => ({ modelId: route.params.scenarioId }),
    },
    {
        path: '/prediction-scenarios/:scenarioId/models/new',
        name: 'predictionModelWizardView',
        component: PredictionModelWizardView,
        props: (route) => ({ value: { scenarioId: route.params.scenarioId } }),
    },
    {
        path: '/prediction-scenarios/:scenarioId/models/:modelId',
        name: 'predictionModelDashboardView',
        component: PredictionModelDashboardView,
        props: (route) => ({ value: { predictionId: route.params.scenarioId, modelId: route.params.modelId } }),
    },
    {
        path: '/users/me',
        name: 'myUserAccount',
        component: UserInfoView,

        meta: {
            navigation: {        
                i18n: {        
                    en: {        
                        name: 'My User Account'        
                    }, 

                    de: {        
                        name: 'Mein Benutzerkonto'        
                    }        
                }        
            },        
        }
    },

];
