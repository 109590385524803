<template>
    <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form :title="$t('workplace-editor.view.title', { name: name })" :value="workplace"
            @update-confirmed="save" @update-canceled="cancel" :editable="hasEditRole">

            <template v-slot:default>
                <v-sheet color="grey lighten-3">

                    <v-tabs v-model="selectedTab" :vertical="$vuetify.breakpoint.mdAndUp" color="grey"
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" slider-color="grey"
                        show-arrows :fixed-tabs="!$vuetify.breakpoint.mdAndUp">


                        <!-- Tabs -->
                        <v-tab :key="0">{{ $t('workplace-editor.view.tabs.general') }}</v-tab>
                        <v-tab :key="1">{{ $t('workplace-editor.view.tabs.selection') }}</v-tab>
                        <v-tab :key="2">{{ $t('workplace-editor.view.tabs.json') }}</v-tab>



                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="workplace" :disabled="!hasEditRole" />
                            </v-tab-item>


                            <!-- Selection -->
                            <v-tab-item :key="1">

                                <v-card flat>
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('workplace-editor.view.tabs.settings.title') }}
                                        </div>
                                        <div>{{ $t('workplace-editor.view.tabs.settings.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("workplace-editor.view.sections.batterydata.title") }}
                                        </div>
                                        <div>
                                            {{ $t('workplace-editor.view.sections.batterydata.subtitle') }}
                                        </div>
                                        <p></p>
                                        <v-row>
                                            <v-col cols="12" lg="6">
                                                <c-item-selector :items="sites.items" v-model="siteId"
                                                    :disabled="!hasEditRole" :return-object="false" filled hide-details
                                                    :chips="false"
                                                    :label="$t('workplace-editor.view.tabs.subheadder.sites')"
                                                    :rules="[v => v && !!v.trim() || $t('workplace-editor.view.siteId.validation.notEmpty')]"></c-item-selector>
                                            </v-col>
                                            <v-col cols="12" lg="6">

                                                <c-item-selector :items="workplaceTypes.items" v-model="workplaceTypeId"
                                                    :disabled="!hasEditRole"
                                                    :label="$t('workplace-editor.view.tabs.subheadder.workplacetypes')"
                                                    :rules="[v => v && !!v.trim() || $t('workplace-editor.view.workplacetypeId.validation.notEmpty')]"
                                                    :return-object="false" filled hide-details
                                                    :chips="false"></c-item-selector>
                                            </v-col>


                                        </v-row>

                                    </v-card-text>
                                </v-card>
                                <v-section></v-section>
                            </v-tab-item>
                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="workplace" />

                            </v-tab-item>


                        </v-tabs-items>
                    </v-tabs>

                </v-sheet>



            </template>

        </c-item-editor-form>
    </v-sheet>
</template>
  
<script>
import { get } from 'lodash';
import WorkplaceEditormodule from "../../../store/masterdata/workplaces/workplaces-editor.module";

import Roles from '@/roles';

const MODULE_ID = 'workplaceEditor';

export default {

    name: 'workplace-editor',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
                await this.$store.dispatch(MODULE_ID + "/loadSitesAndWorkplaceTypes");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async save() {
            this.progressIndicatorService.show();
            try {

                await this.$store.dispatch(MODULE_ID + "/saveData");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push({ name: "WorkplaceListView" });
        }
    },

    computed: {

        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.WORKPLACE_EDIT);
        },



        workplace: {
            get() {
                return this.$store.getters[MODULE_ID + "/workplace"];
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_ID + "/updateWorkplace", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },

        sites() {
            return this.$store.getters[MODULE_ID + "/sites"];
        },
        workplaceTypes() {
            return this.$store.getters[MODULE_ID + "/workplaceTypes"];
        },
        workplaceTypeId: {
            get() {
                return this.$store.getters[MODULE_ID + "/workplaceTypeId"];
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_ID + "/updateWorplaceTypeId", item)
                    .catch(this.errorHandlerService.handleError);

            }
        },
        siteId: {
            get() {
                return this.$store.getters[MODULE_ID + "/siteId"];
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_ID + "/updateSiteId", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },


    },

    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, WorkplaceEditormodule);
        }
        this.refresh();

    }
}
</script>

<i18n>
  {
      "en" : {
        "workplace-editor.view.sections.batterydata.title":"Workplace Settings",
        "workplace-editor.view.sections.batterydata.subtitle": "Here you can select the Site and Workplace Type.",
        "workplace-editor.view.tabs.settings.subtitle": "This page contains settings for the selected Workplace.",
        "workplace-editor.view.tabs.settings.title": "Settings",
        "workplace-editor.view.title" : "Workplace {name}",
        "workplace-editor.view.tabs.selection": "Settings",
        "workplace-editor.view.tabs.general" : "General",
        "workplace-editor.view.tabs.settings" : "Settings",
        "workplace-editor.view.tabs.json" : "JSON",
        "workplace-editor.view.tabs.subheadder.sites" : "Site",
        "workplace-editor.view.tabs.subheadder.workplacetypes" : "Workplace Type",
        "workplace-editor.view.tabs.savebutton" : "SAVE",
        "workplace-editor.view.workplacetypeId.validation.notEmpty": "Please asign a Workplace Type.",
        "workplace-editor.view.siteId.validation.notEmpty": "Please asign a Site."
      },
  
      "de" : {
        "workplace-editor.view.sections.batterydata.title":"Arbeitsplatz-Einstellungen",
        "workplace-editor.view.sections.batterydata.subtitle":"Hier können Sie den Produktionsstandort und den Arbeitsplatztyp erfassen.",
        "workplace-editor.view.tabs.settings.subtitle": "Diese Seite enthält Einstellungen zum ausgewählten Arbeitsplatz.",
        "workplace-editor.view.tabs.settings.title": "Einstellungen",
        "workplace-editor.view.title" : "Arbeitsplatz {name}",
        "workplace-editor.view.tabs.selection": "Einstellungen",
        "workplace-editor.view.tabs.general" : "Allgemein",
        "workplace-editor.view.tabs.settings" : "Einstellungen",
        "workplace-editor.view.tabs.json" : "JSON",
        "workplace-editor.view.tabs.subheadder.sites" : "Produktionsstandort",
        "workplace-editor.view.tabs.subheadder.workplacetypes" : "Arbeitsplatztyp",
        "workplace-editor.view.tabs.savebutton" : "ÜBERNEHMEN",
        "workplace-editor.view.workplacetypeId.validation.notEmpty" : "Bitte wählen sie einen Arbeitsplatztyp aus.",
        "workplace-editor.view.siteId.validation.notEmpty": "Bitte wählen sie einen Produktionsstandort aus."
      }
  }
  </i18n>