<template>
    <v-list-item :to="path">
        <v-list-item-action>
            <v-icon :color="iconColor">{{icon}}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title :title="tagName">{{tagName}}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import { get } from 'lodash';

export default {

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    computed: {

        icon() {
            return 'label_important';
        },

        iconColor() {
            return get(this.value, 'color', '#FF0000');
        },

        path() {
            return '/collection-by-tag';
        },

        tagName() {
            return `${this.$dxs.i18n.localizedName(this.value, this.$i18n.locale)} (${this.value.name})`;
        }
    }
}
</script>

<style>

</style>