import api from '../../../api/smart-factory.api';
import { get } from 'lodash';

export default {

    namespaced: true,

    state : {
        pagination:{
            from : 0,
            pageSize : 25, 
            totalItemCount: 50},
        site: createDefaultSite(),
        workplaces : {
            pagination: {
                from : 0,
                pageSize : 5, 
                totalItemCount: 5},
            items: [],

        },
        response: getDefaultResponse(),

        filter: getDefaultFilter()
    },

    mutations: {

        setSite(state, site) {

            console.log("=====================================")
            console.log(JSON.stringify(site, true, 2))
            console.log("=====================================")

            if (site) {
                state.site.tenantId = site.tenantId;
                state.site.name = site.name;
                state.site.i18n = site.i18n;
                state.site.data = site.data;
                state.site.active = site.active;
                state.site.creationDateTime     = site.creationDateTime;
                state.site.creationUser         = site.creationUser;
                state.site.modificationDateTime = site.modificationDateTime;
                state.site.modificationUser     = site.modificationUser;
            }
            
        },
        
        setResponse(state, response) {
            if (response) {

                let items = [];
                if (response.items) {
                    state.response.items = response.items;
                } else {
                    state.response.items = items;
                }

                if (response.tenantId) {
                    state.response.tenantId = response.tenantId;
                }

                if (response.pagination) {
                    state.response.pagination = response.pagination;
                }

            }

        },
        addWorkplaces(state, response) {
            if (response) {
                const items = state.workplaces.items;
                for(let i of response.items){
                    items.push(i);
                }
                state.workplace.items = items;
                state.workplaces.pagination.totalItemCount = response.pagination.totalItemCount;
            } else {
                state.workplaces.items = [];
                state.workplaces.pagination = {
                    from: 0,
                    pageSize: 5,
                    totalItemCount: 10
                };
            }
        },
        setWorkplaces(state, response) {
            if (response) {
                state.workplaces.items= response.items;
                state.workplaces.pagination = response.pagination || {
                    from: 0,
                    pageSize: 5,
                    totalItemCount: 5
                };
            } else {
                state.workplaces.items = [];
                state.workplaces.pagination = {
                    from: 0,
                    pageSize: 5,
                    totalItemCount: 5
                };
            }
        },
        setFilter(state, filter) {

            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter()
            }
        }

    },

    actions: {
        async loadMoreWorkplaces(context, {pagination}) {
            const name = context.getters["name"];
            const response = await api.masterdata.workplaces.findBySiteId(name, pagination.from,pagination.pageSize);
           
            context.commit('addWorkplaces', response);
        },

        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.sites.findById(name);
                await context.commit('setSite', get(response, 'item.site', createDefaultSite()));
                const name_ = await context.getters["name"];
                const pagination = await context.getters["pagination"];
                const workspaces = await api.masterdata.workplaces.findBySiteId(name_,get(pagination,"from",0),get(pagination,"pageSize",5));
                workspaces.pagination.from = pagination.from;
                await context.commit('setWorkplaces', response);
                console.log(workspaces)
                console.log(pagination)
                console.log("**********************************workspaces*******************************")
                console.log(name_)
                context.commit('setWorkplaces',workspaces, createDefaultWorkplace());
            } catch (error) {
                context.commit('setSite', createDefaultSite());
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters.model, 'site');
                console.log(">>> VUEX SAVE DATA ", JSON.stringify(payload));
                let response = await api.masterdata.sites.update(payload);
                context.commit('setSite', get(response, 'item.site', createDefaultSite()));

            } catch (error) {
                console.log(error);
                context.commit('setSite', createDefaultSite());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            console.log('+++ updateModel ', JSON.stringify(model, true, 2));
            context.commit('setSite', get(model, 'site', createDefaultSite()));
        },

        updateSite(context, site) {
            console.log('+++ updateSite ', JSON.stringify(site, true, 2));
            context.commit('setSite', site);
        },

        
        async loadResponse(context, { from, pageSize }) {

            try {
                //
                let response = await api.masterdata.workplaces.findByFilter(context.state.filter, from, pageSize);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async deleteItem(context, { name }) {

            await api.masterdata.workplaces.deleteById(name);
        },

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        }

    },

    getters: {

        pagination(state){
            return get(state, 'pagination',{}) 
        },
        workplacePagination(state){
            return get(state, 'workplaces.pagination',{}) },

        workplaceItems(state) {
            return get(state, 'workplaces.items',[]) 
        },
        workplace(state){
            return get(state, 'workplaces',{pagination:{from:0,pageSize:5,totalItemCount:5},items:[]})
        },
        name(state){
        return state.site.name;
        },
        model(state) {
            return {
                site: state.site
            }
        },
        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        }
    }
}

function createDefaultSite() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {},
        active: null,
        references: {},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }
}

function getDefaultResponse() {

    return {
        "tenantId": "",
        "items": [],
        "pagination": {}
    };
}

    function createDefaultWorkplace() {
        return {
            pagination: {
                from: 0,
                pageSize: 5,
                totalItemCount: 5
            },
            items: []
        };
    }

function getDefaultFilter() {
    return '*';
}