export default [
    {
        name: 'CUSTOM',
        i18n : {
            'en' : {
                name: 'Custom Time Horizon',
                shortName: 'Custom',
                description: 'Define a custom time horizon.'
            },

            'de' : {
                name: 'Individueller Horizont',
                shortName: 'Individuell',
                description: 'Legen Sie einen individuellen Zeithorizont fest'
            }
        }
    },

    {
        name: 'YTD',
        i18n : {
            'en' : {
                name: 'Year to Date (YTD)',
                shortName: 'YTD',
                description: 'Selects the minimum value out of a set of values.'
            },

            'de' : {
                name: 'Aktuelles Jahr (YTD)',
                shortName: 'YTS',
                description: 'Zeithorizont ist das aktuelle Jahr bis zum jetzigen Tag.'
            }
        }
    },

    {
        name: 'MTD',
        i18n : {
            'en' : {
                name: 'Month to Data (MTD)',
                shortName: 'MTD',
                description: 'Computes the average value of a set of values.'
            },

            'de' : {
                name: 'Aktueller Monat',
                shortName: 'MTD',
                description: 'Berechnet den Durschnittswert einer Menge von Werten.'
            }
        }
    },

    {
        name: 'TODAY',
        i18n : {
            'en' : {
                name: 'Today',
                shortName: 'Today',
                description: 'Define a custom '
            },

            'de' : {
                name: 'Heute',
                shortName: 'Heute',
                description: 'Legen Sie eine freie Betrachtungdauer fest'
            }
        }
    },

    {
        name: 'YESTERDAY',
        i18n : {
            'en' : {
                name: 'Yesterday',
                shortName: 'Yesterday',
                description: 'Define a custom '
            },

            'de' : {
                name: 'Gestern',
                shortName: 'Gestern',
                description: 'Legen Sie eine freie Betrachtungdauer fest'
            }
        }
    },

]