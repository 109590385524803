<template>
  <v-sheet class="grey lighten-3">
      
      <c-sticky-content ref="pageBar">
        <!-- ============================================ -->
        <!-- Free Text Filter -->
        <!-- ============================================ -->
        <v-container fluid class="grey lighten-4"> 
            <v-row justify="center" >
                <v-col :cols="12">
                    <c-filter-query-input ref="filterQueryInput" :value="filterQuery" track-by="filterQuery" @filter-query-changed="onFilterQueryChange" :dark="false" />
                </v-col>
            </v-row>
        </v-container>

        <v-divider />

        <!-- ============================================ -->
        <!-- Toolbar -->
        <!-- ============================================ -->
        <v-toolbar flat color="white">
            <v-toolbar-title>{{$t('collection-documents.view.results', {totalItemCount: response.pagination.totalItemCount})}}</v-toolbar-title>
    
            <v-btn icon @click="toggleQuerySummary">
                <v-icon v-if="!querySummary">expand_more</v-icon>
                <v-icon v-else>expand_less</v-icon>
            </v-btn>

            <v-spacer />

            <v-toolbar-items>
                <v-btn icon @click="refresh">
                    <v-icon>refresh</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <template v-if="querySummary">
            <v-divider />
            <c-query-summary :value="response" />
        </template>

        <v-divider />
    </c-sticky-content>
    
    <template v-if="!formSubmitted">
        <dxs-send-query-hint-banner />
    </template>
    <!-- ============================================ -->
    <!-- Content -->
    <!-- ============================================ -->

    <!-- Measures -->
    <template v-else-if="!itemsEmpty">

        <v-subheader>{{$t('collection-statistics.view.attributes.sections.measures')}}</v-subheader>
        <v-container fluid class="grey lighten-3">
            <v-row justify="start">
                <v-col cols="12" sm="6" lg="4" xl="3" v-for="(item, index) of measures" :key="'col_measures_' + index">
                    <v-lazy>
                        <dxs-collection-attribute-stats-tile :value="{ attribute: attributes[item.name], values: item.stats }" />
                    </v-lazy>
                </v-col>
            </v-row>
        </v-container>
        <v-divider />


        <!-- Temporal Dimensions -->
        <v-subheader>{{$t('collection-statistics.view.attributes.sections.dateTime')}}</v-subheader>
        <v-container fluid class="grey lighten-3">
            <v-row justify="start">
                <v-col cols="12" sm="6" lg="4" xl="3" v-for="(item, index) of timeDimensions" :key="'col_timeDimension_' + index">
                    <v-lazy>
                        <dxs-collection-attribute-stats-tile :value="{ attribute: attributes[item.name], values: item.stats }" height="100%"/>
                    </v-lazy>
                </v-col>
            </v-row>
        </v-container>
        <v-divider />
        
        <!-- Categorical Dimensions -->
        <v-subheader>{{$t('collection-statistics.view.attributes.sections.categoricals')}}</v-subheader>
        <v-container fluid class="grey lighten-3">
            <v-row justify="start">
                <v-col cols="12" sm="6" lg="4" xl="3" v-for="(item, index) of categoricalDimensions" :key="'col_catDimension_' + index">
                    <v-lazy>
                        <dxs-collection-attribute-stats-tile :value="{ attribute: attributes[item.name], values: item.stats }" />
                    </v-lazy>
                </v-col>
            </v-row>
        </v-container>
    </template>

    <!-- ============================================ -->
    <!-- No Content -->
    <!-- ============================================ -->
    <template v-else>
        <c-empty-result-tile />
    </template>
    
  </v-sheet>
</template>

<script>
const MODULE_NAME = 'collectionStatistics';
import isEmpty from 'lodash/isEmpty';
import { get, size } from 'lodash';


import CollectionAttributeStatsTile from '@/core/components/collections/collection-attribute-stats-tile.component';
import SendQueryHintBannerComponent from '@/core/components/collections/send-query-hint-banner.component';

export default {

    name: 'collection-statistics',
    
    props: {
        
        value: {
            type: Object,
            required: false,
        },

        options: {
            type: Object,
            required: false
        }
    },

    inject: ['shellState', 'errorHandlerService', 'progressIndicatorService'], 

    components: {
        'dxs-collection-attribute-stats-tile' : CollectionAttributeStatsTile,
        'dxs-send-query-hint-banner' : SendQueryHintBannerComponent
    },

    data: () => ({
        querySummary: false,
        formSent: false,
        filterQuery: {query: ''},
    }),

    watch: {
        $route() {
            this.refresh();
        }
    }, 

    methods: {

        isMeasure(item) {
            
            const attribute = get(this.attributes, item.name, {});
            return attribute.attributeType === 'MEASURE'
        }, 

        isTimeDimension(item) {
            const attribute = get(this.attributes, item.name, {});
            return attribute.attributeType === 'DIMENSION' && (attribute.datatype === 'DATE' || attribute.datatype === 'DATETIME' );
        },

        isCategoricalDimension(item) {

            return !this.isMeasure(item) && !this.isTimeDimension(item);
        },
        

        async onFilterQueryChange(event) {


            // Set the filter query on the vuex store. This will cause the view to update.
            //this.$log.debug(JSON.stringify(event));
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + '/updateFilterQuery', {collectionId: this.collectionId, filterQuery: { filterQuery: event.query, filterQueryLanguage: event.mode } });
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();

            }
        },

        async resetView(collectionId) {
            
            this.progressIndicatorService.show();

            try {

                await this.$store.dispatch(MODULE_NAME + '/resetView');
                await this.$store.dispatch(MODULE_NAME + '/initData', {collectionId: collectionId});
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
                this.submitted = false;
            }
        },

        /**
         * Initially loads collection settings and schema
         */
        async init() {

            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/initData', {collectionId: this.collectionId});
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
                this.submitted = true;
            }
        },

        async refresh() {

            let count = 0;
            let total = size(this.attributes);

            try {
                this.progressIndicatorService.show();
                
                // Reset the previously loaded items.
                await this.$store.dispatch(MODULE_NAME + '/resetItems');

                // Each attribute is loaded separately.
                this.formSent = true;

                for (const attributeName in this.attributes) {
                    count = count + 1;
                    this.progressIndicatorService.updateText({from: count, to: total});
                    try {

                        await this.$store.dispatch(MODULE_NAME + '/loadItem', {    
                            collectionId : this.value.collectionId,
                            attributeName: attributeName
                            }
                        );

                    } catch (error) {
                        this.errorHandlerService.handleError(error);
                    }
                } 
            
            }  catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }

        },

        toggleQuerySummary() {
            this.querySummary = !this.querySummary;
        }
    },

    computed: {

        collectionId() {
            return this.value ? this.value.collectionId : null;
        },

        count() {return 6},

        response() {

            let response = this.$store.getters[MODULE_NAME + '/response'];
            return response;
        },

        items() {
            return get(this.response, 'items', []);
        },

        itemsEmpty() {
            return isEmpty(this.items);
        },

        measures() {
            return this.items.filter(this.isMeasure);
        },

        timeDimensions() {
            return this.items.filter(this.isTimeDimension);
        },

        categoricalDimensions() {
            return this.items.filter(this.isCategoricalDimension);
        },

        schema() {
            return this.response && this.response.schema ? this.response.schema : {};
        },

        attributes() {
            return get(this.schema, 'attributes', {});
        },

        filter() {
            return this.$store.getters[MODULE_NAME + '/filter'];
        },

        formSubmitted() {
            return this.formSent || !this.itemsEmpty;
        },

        
    },

    mounted() {
        console.log('pageBar', this.$refs.pageBar)
    },

    created() {
        // Refresh will trigger loading of the collection settings information.
        // this.refresh();
        this.resetView(this.collectionId);
        this.filterQuery.query = this.filter.filterQuery;
    }
}   
</script>

<i18n>
{
    "en" : {
        "collection-statistics.view.attributes.sections.measures" : "Measures and Metrics",
        "collection-statistics.view.attributes.sections.dateTime" : "Temporal Attributes",
        "collection-statistics.view.attributes.sections.categoricals" : "Categorical Attributes",
        "collection-statistics.view.attributes.sections.result.hint" : "Please note: The attribute statistics are calculated on the basis of heuristic methods for reasons of performance: With very large amounts of data, the statistical values therefore represent approximate values and not exact values."
    },

    "de" : {
        "collection-statistics.view.attributes.sections.measures" : "Metrische Attribute und Kennzahlen",
        "collection-statistics.view.attributes.sections.dateTime" : "Datums- und Uhrzeitbezogene Attribute",
        "collection-statistics.view.attributes.sections.categoricals" : "Kategoriale Attribute",
        "collection-statistics.view.attributes.sections.result.hint" : "Hinweis: Die Attribut-Statistiken werden aus Performance-Gründen auf Basis von heuristischen Verfahren berechnet: Bei sehr großen Datenmengen stellen die nachfolgenden Werte deshalb Näherungswerte und keine exakten Werte dar."
    }
}
</i18n>

