import api from '@/store/api';

import { get } from 'lodash';

export default {

    namespaced: true,

    state: {

        categoryValueDistributions: []
    },

    mutations: {
        
        setCategoryValueDistribution(state, categoryValueDistributions) {
            state.categoryValueDistributions = categoryValueDistributions;
        },

        addCategoryValueDistribution(state, categoryValueDistribution) {

            if (categoryValueDistribution) {
                state.categoryValueDistributions.push(categoryValueDistribution) 
            }
        }
    },

    actions: {
        
        async loadCategoryValueDistributions(context, { collectionId, categoryIdList, filter, size }) {
            
            // First reset the previously loaded results.
            context.commit('setCategoryValueDistribution', []);

            for (const categoryId of categoryIdList) {
                try {
                    const response = await api.collections.dimensions.getDistribution(collectionId, categoryId, filter, size);
   
                    context.commit('addCategoryValueDistribution', get(response, 'data.category', {}));
                } catch (error) {
                    console.log(error);
                }
            }
            
            
        },
    },

    getters: {

        totalItemCount(state) {

            // It is sufficient to only consider the count of a single dimension - in the context of this module, 
            // they share the same filter settings anyway, thus all will be based on the same total count of items.
            let totalItemCount = get(state, 'categoryValueDistributions[0].totalItemCount', 0);
    
            return totalItemCount;
        }, 

        model(state, getters) {
            return {
                categoryValueDistributionItems : state.categoryValueDistributions,
                totalItemCount: getters.totalItemCount
            }
        },

        
    }

}
