<template>
    <v-sheet>
        <template v-if="!documentsEmpty">
            <v-simple-table >
                <thead>
                    <tr >
                        <th v-for="attribute of attributes" :key="attribute.name + '_th'">
                            {{attribute | localized-short-name($i18n.locale)}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    
                    <tr v-for="document, index of documents" :key="'_data_'+ index">
                        <td v-for="attribute of attributes" :key="'_data_'+ index + '_' + attribute.name">
                            {{ getFormattedValueForAttribute(document, attribute) }}

                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </template>
        <template v-else>
            <div class="body-2">{{$t('embedded-documents-list.component.documentsEmpty')}}</div>
        </template>
    </v-sheet>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';

export default {

    name: 'embedded-documents-list',

    props: {
        
        value: {
            type: Object,
            required: false,
        }
    },

    computed: {

        documents() {
            return this.value.documents ? this.value.documents : [];
        },

        documentsEmpty() {
            return _isEmpty(this.documents);
        },

        schema() {
            return this.value.schema ? this.value.schema : {};
        },

        attributes() {
            const attributes = [];
            
            if (this.schema.attributes) {
                for (const key in this.schema.attributes) {
                    attributes.push(this.schema.attributes[key]);
                }
            }
            return attributes.sort( (first, second) => first.ordering - second.ordering );
        },
    },

    methods: {
        getFormattedValueForAttribute(document, attribute) {
             return this.$dxs.attributes.getFormattedValue(document, attribute, this.$i18n.locale);
         },
    }
}
</script>

<i18n>
{
    "en" : {
        "embedded-documents-list.component.documentsEmpty" : "There are no documents to display."
    },

    "de" : {
        "embedded-documents-list.component.documentsEmpty" : "Keine Dokumente zur Anzeige vorhanden."
    }
}
</i18n>