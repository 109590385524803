<template>
  <v-sheet>
      <c-sticky-content>
            <v-toolbar color="secondary darken-1" flat >
                <v-toolbar-items><v-btn icon @click="close"><v-icon>close</v-icon></v-btn></v-toolbar-items>
                <v-breadcrumbs>
                    <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false">{{$t('webhook-execution-log-detail.view.breadcrumbs.root')}}</v-breadcrumbs-item>
                    <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                    <v-breadcrumbs-item>{{documentId | truncate-middle(16)}}</v-breadcrumbs-item>                
                </v-breadcrumbs>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon><v-icon @click="refresh">refresh</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
        </c-sticky-content>
      <c-section class="secondary" :title="$t('webhook-execution-log-detail.view.title', { webhookId: webhookId })" :subtitle="$t('webhook-execution-log-detail.view.subtitle')">

      </c-section>
      <v-container fluid class="grey lighten-3">
          
        <v-row justify="center">
            <v-col cols="12" md="6" lg="4">
                <v-card class="mt-5">
                    <v-card-text v-if="!webhookActive" class="grey lighten-3 text-center">
                        <v-icon x-large color="grey">error</v-icon>
                        <div class="mt-5 text-h6 grey--text">{{$t('webhook-execution-log-detail.view.state.inactive')}}</div>
                    </v-card-text>
                    <v-card-text v-else-if="webhookFailed" class="error white--text text-center">
                        <v-icon x-large color="white">error</v-icon>
                        <div class="mt-5 text-h6">{{$t('webhook-execution-log-detail.view.state.failed')}}</div>
                    </v-card-text>
                    <v-card-text v-else class="text-center">
                        <v-icon x-large>check_circle</v-icon>
                        <div class="mt-5 text-h6">{{$t('webhook-execution-log-detail.view.state.success')}}</div>
                    </v-card-text>
                </v-card>

                <v-card class="mt-5">
                    <v-card-text class="text-h6">
                        {{$t('webhook-execution-log-detail.view.sections.overview.title')}}
                    </v-card-text>
                    <v-divider />
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td>{{$t('webhook-execution-log-detail.view.sections.overview.cols.subscriptionId')}}</td>
                                <td>{{webhookSubscriptionId}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('webhook-execution-log-detail.view.sections.overview.cols.webhookId')}}</td>
                                <td>{{webhookId}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('webhook-execution-log-detail.view.sections.overview.cols.event')}}</td>
                                <td>{{requestEventType}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('webhook-execution-log-detail.view.sections.overview.cols.publishDateTime')}}</td>
                                <td>{{webhookDateTime | localized-date-time-format($i18n.locale)}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('webhook-execution-log-detail.view.sections.overview.cols.retryCount')}}</td>
                                <td>{{webhookRetry}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('webhook-execution-log-detail.view.sections.overview.cols.duration')}}</td>
                                <td>{{webhookDurationMiliseconds}}</td>
                            </tr>
                            <tr>
                                <td>{{$t('webhook-execution-log-detail.view.sections.overview.cols.timeout')}}</td>
                                <td>{{webhookTimeout}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
                
                <v-card class="mt-5">
                    <v-card-text class="text-h6">
                        {{$t('webhook-execution-log-detail.view.sections.subscription.title')}}
                    </v-card-text>
                    <v-card-text>
                        {{$t('webhook-execution-log-detail.view.sections.subscription.description')}}
                    </v-card-text>
                    <v-divider />
                    <v-card-action>
                        <v-btn color="accent" text block @click="openSubscription"><v-icon class="mr-5">open_in_new</v-icon> {{$t('webhook-execution-log-detail.view.sections.subscription.actions.open')}}</v-btn>
                    </v-card-action>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" lg="8">
                <v-card class="mt-5">
                    <v-card-text class="text-h6">
                       Webhook Response
                    </v-card-text>
                    <v-divider />
                    
                    <template v-if="webhookActive">
                        <v-subheader>Response Status</v-subheader>
                        <v-card-text class="text-body-2">
                            {{responseStatus}} 
                        </v-card-text>
                        <v-divider />
                        <v-subheader>Response Body</v-subheader>
                        <v-card-text class="grey lighten-5" v-if="responseBodyText">
                            <pre>{{responseBodyText}}</pre>
                        </v-card-text>
                        <v-card-text class="grey lighten-5" v-else>
                            <pre>{{$t('webhook-execution-log-detail.view.response.body.empty')}}</pre>
                        </v-card-text>
                    </template>
                    <template v-else>
                        <v-card-text vclass="grey lighten-3 text-center">
                            {{$t('webhook-execution-log-detail.view.response.body.inactive')}}
                        </v-card-text>
                    </template>
                </v-card>

                <v-card class="mt-5">
                    <v-card-text class="text-h6">
                       Webhook Request
                    </v-card-text>
                    <v-divider />
                    <v-subheader>Request URL</v-subheader>
                    <v-card-text class="text-body-2">
                        {{requestUrl}} ({{requestMethod}})
                    </v-card-text>
                    <v-divider />
                    <v-subheader>Request Body</v-subheader>
                    <v-card-text class="grey lighten-5">
                        <pre>{{payloadJSON}}</pre>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
      </v-container>
  </v-sheet>
</template>

<script>
import DocumentDetailDataMixin from '@/core/mixins/document-detail-data.mixin';
import { get } from 'lodash';

export default {

    mixins: [DocumentDetailDataMixin],

    methods: {
        close() {
            this.$router.go(-1);
        },

        openSubscription() {
            this.$router.push({
                name: 'webhookSubscriptionsEditor',
                params: { name: this.webhookSubscriptionId}
            })
        }
    },

    computed: {

        webhookId() {
            return get(this.data, 'webhookId');
        },

        webhookSubscriptionId() {
            return get(this.data, 'webhookSubscriptionId');
        },

        webhookActive() {
            return get(this.data, 'webhookActive');
        },

        webhookRetry() {
            return get(this.data, 'webhookRetry');
        },

        webhookDateTime() {
            return get(this.data, 'webhookDateTime');
        },

        webhookDurationMiliseconds() {
            return get(this.data, 'webhookDurationMiliseconds');
        },

        webhookTimeout() {
            return get(this.data, 'webhookTimeout');
        },

        requestEventType() {
            return get(this.data, 'requestEventType');
        },

        requestUrl() {
            return get(this.data, 'webhookUrl');
        },

        requestMethod() {
            return get(this.data, 'webhookHttpMethod')
        },

        payload() {
            
            return JSON.parse(get(this.data, 'requestPayload', '{}'));
        },

        payloadJSON() {
            return JSON.stringify(this.payload, true, 2);
        },

        responseStatus() {
            return get(this.data, 'responseStatus', 0); 
        },

        webhookFailed() {
            return this.responseStatus > 299;
        },

        responseBody() {
             return get(this.data, 'responseBody', '{}');
        },

        responseBodyText() {
              return this.responseBody;
        },
    }
}
</script>

<i18n>
{
    "en" : {
        "webhook-execution-log-detail.view.breadcrumbs.root" : "Webhook Execution Log",
        "webhook-execution-log-detail.view.title" : "Webhook {webhookId}",
        "webhook-execution-log-detail.view.subtitle" : "This dashboard provides a summary on the webhook execution.",
        "webhook-execution-log-detail.view.state.inactive" : "Not Sent! Webhook is Inactive.",
        "webhook-execution-log-detail.view.state.failed" : "Webhook Failed.",
        "webhook-execution-log-detail.view.state.success" : "Webhook Successful",

        "webhook-execution-log-detail.view.sections.overview.title" : "Overview",
        "webhook-execution-log-detail.view.sections.overview.cols.subscriptionId" : "Subscription ID",
        "webhook-execution-log-detail.view.sections.overview.cols.webhookId" : "Webhook ID",
        "webhook-execution-log-detail.view.sections.overview.cols.event" : "Triggered by Event",
        "webhook-execution-log-detail.view.sections.overview.cols.publishDateTime" : "Published On",
        "webhook-execution-log-detail.view.sections.overview.cols.retryCount" : "Retry Count",
        "webhook-execution-log-detail.view.sections.overview.cols.duration" : "Duration",
        "webhook-execution-log-detail.view.sections.overview.cols.timeout" : "Timeout",

        "webhook-execution-log-detail.view.sections.subscription.title" : "Subscription",
        "webhook-execution-log-detail.view.sections.subscription.description" : "Please open the associated webhook subscription for further details.",
        "webhook-execution-log-detail.view.sections.subscription.actions.open" : "Open Subscription",


        "webhook-execution-log-detail.view.response.body.empty" : "The target server did not send a response body for this webhook request.",
        "webhook-execution-log-detail.view.response.body.inactive" : "There is no response data, because this webhook is inactive. Inactive webhooks will be recorded, however they will not be sent to the target server."


    },

    "de" : {
        "webhook-execution-log-detail.view.breadcrumbs.root" : "Webhook-Protokoll",
        "webhook-execution-log-detail.view.title" : "Webhook {webhookId}",
        "webhook-execution-log-detail.view.subtitle" : "Auf diesem Dashboard finden Sie alle Informationen zur Ausführung des Webhooks.",
        "webhook-execution-log-detail.view.state.inactive" : "Nicht gesendet: Webhook ist inaktiv.",
        "webhook-execution-log-detail.view.state.failed" : "Webhook-Ausführung mit Fehler abgebrochen.",
        "webhook-execution-log-detail.view.state.success" : "Webhook-Ausführung war erfolgreich.",

        "webhook-execution-log-detail.view.sections.overview.title" : "Übersicht",
        "webhook-execution-log-detail.view.sections.overview.cols.subscriptionId" : "Abonnement-ID",
        "webhook-execution-log-detail.view.sections.overview.cols.webhookId" : "Webhook-Ausführung-ID",
        "webhook-execution-log-detail.view.sections.overview.cols.event" : "Auslösendes Ereignis",
        "webhook-execution-log-detail.view.sections.overview.cols.publishDateTime" : "Veröffentlicht am",
        "webhook-execution-log-detail.view.sections.overview.cols.retryCount" : "Aktueller Versuch",
        "webhook-execution-log-detail.view.sections.overview.cols.duration" : "Verarbeitungsdauer (Milisekunden)",
        "webhook-execution-log-detail.view.sections.overview.cols.timeout" : "Timeout",

        "webhook-execution-log-detail.view.sections.subscription.title" : "Abonnement",
        "webhook-execution-log-detail.view.sections.subscription.description" : "Bitte öffnen Sie das zugehörige Webhook-Abonnement, um weitere Informationen zu erhalten.",
        "webhook-execution-log-detail.view.sections.subscription.actions.open" : "Abonnement öffnen",

        "webhook-execution-log-detail.view.response.body.empty" : "Der Ziel-Server hat für diesen Webhook keine Antwort geschickt.",
        "webhook-execution-log-detail.view.response.body.inactive" : "Es liegen keine Antwortdaten vor, da dieser Webhook inaktiv ist. Inaktive Webhooks werden nur aufgezeichnet, aber nicht an den Ziel-Server geschickt."
    }
}
</i18n>

