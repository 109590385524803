<template>
    <v-vsheet>
        <v-container fluid class="grey lighten-3 pt-5">
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <c-metrics-distribution-chart :items="materialDistributionChartModel" type="treemap" show-value-label />
                        <v-divider />
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-left">{{$t('Material')}}</th>
                                    <th class="text-left">{{$t('CAS-Nummer')}}</th>
                                    <th class="text-left" colspan="2">{{$t('Anteil %')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) of materialCompositionItems" :key="`tablerow-material-composition-${index}`">
                                    <td>{{item.material.name}}</td>
                                    <td></td>
                                    <td>{{item.materialComposition.share}}</td>
                                    <td width="50%">
                                        <v-progress-linear :color="item.material.data.color" background-color="grey lighten-3" :value="item.materialComposition.share"  height="20"></v-progress-linear>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-for="(item, index) of materialCompositionItems" cols="12" md="4" lg="3" :key="`material-composition-tile-${index}`">
                    <dxs-battery-material-composition-tile :value="item" />
                </v-col>
            </v-row>
        </v-container>
    </v-vsheet>
</template>

<script>
import BatteryMaterialTileComponent from '@/solutions/battery-data-hub/components/material-composition/battery-material-composition-tile.component.vue';
import { get } from 'lodash';

export default {
    name: 'battery-material-composition-page',

    components: {
        'dxs-battery-material-composition-tile' : BatteryMaterialTileComponent
    },

    props: {

        value: {
            type: Object,
            required: false
        }
    },

    computed: {
        
        materialComposition() {
            return get(this.value, 'materialComposition', []);
        },

        materialCompositionItems() {

            const materialComposition = [];
            for (const item of this.materialComposition) {
                materialComposition.push({
                    battery: this.value.battery,
                    materialComposition: item,
                    material: item.material
                });
            }
            
            return materialComposition;
        },

        materialDistributionChartModel() {

            const items = [];
            let sum = 0;
            for (const item of this.materialCompositionItems) {

                items.push({
                    name: item.material.name,
                    color: item.material.data.color,
                    value: item.materialComposition.share
                });

                sum = sum + item.materialComposition.share;
            }

            items.push({
                name: 'Others...',
                color: 'lightgray',
                value: (100 - sum)
            });
            
            return items;
        }
    }

}
</script>