<template>
    <v-card tile :flat="flat">
        <v-card-text>
            <div class="text-h6">{{ title }}</div>
            <div class="text-strong">{{ subtitle }}</div>
        </v-card-text>
        <v-divider />
        <v-card-text class="grey lighten-3">
            <div class="text-subtitle-2 font-weight-black pb-5">{{ $dxs.i18n.localizedName(fireExtinguisherClass ,$i18n.locale) }}</div>
            <div class="text-body-2">{{ $dxs.i18n.localizedDescription(fireExtinguisherClass ,$i18n.locale) }}</div>
        </v-card-text>
        <v-divider />
        <v-subheader>Löschmittel</v-subheader>
        <v-list>
            <v-list-item v-for="(item, index) of extinguishingAgents" :key="`extagent-listitem-${index}`" >
                <v-list-item-avatar>
                    <v-icon>fire_extinguisher</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ $dxs.i18n.localizedName(item, $i18n.locale) }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider />
        <v-card-text>
            <v-btn color="secondary" depressed><v-icon color="white" class="pr-5">arrow_right_alt</v-icon> Weitere Information...</v-btn>
        </v-card-text>
        
    </v-card>
</template>

<script>
import { get } from 'lodash';


export default {
    
    name: 'fire-extinguisher-class-tile',

    props: {

        flat: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: Object,
            required: true, 
        }

    },

    computed: {

        

        title() {
            return this.$t('fire-extinguisher-class-tile.component.title');
        },

        subtitle() {
            return this.$t('fire-extinguisher-class-tile.component.subtitle');
        },

        fireExtinguisherClass() {
            return get(this.value, 'fireExtinguisherClass', {});
        },

        extinguishingAgents() {
            return get(this.fireExtinguisherClass, 'data.extinguishingAgents', []);
        }

    }

}
</script>

<i18n>
{
    "en" : {
        "fire-extinguisher-class-tile.component.title" : "Fire Extinguishing"
    },

    "de" : {
        "fire-extinguisher-class-tile.component.title" : "Löschmittel"
    }
}
</i18n>