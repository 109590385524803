<template>
    <div class="secondary darken-1">

        <v-breadcrumbs>
            <v-breadcrumbs-item :to="{ name: 'notifications' }" :disabled="false">{{$t('navigation.groups.notification')}}</v-breadcrumbs-item>
            <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
            <v-breadcrumbs-item>{{notificationId}}</v-breadcrumbs-item>
        </v-breadcrumbs>

        <c-section :title="$t('notification.view.title')" :subtitle="$t('notification.view.subtitle')"  class="secondary"></c-section>

        <c-notification-tile v-if="!loading" :value="response"></c-notification-tile>
    </div>
</template>

<script>
const MODULE_NAME = 'notificationDetail';

export default {

    name: 'notificationDetail',

    props: {
        value: {
            type: Object,
            required: false,
        },
        
        options: {
            type: Object,
            required: false
        }
    },

    inject: ['shellState', 'errorHandlerService', 'progressIndicatorService'],

    watch: {
        $route() {
            this.refresh();
        }
    }, 

    data: () => ({ }),

    computed: {
        loading() {
            return this.shellState.loading;
        },

        response() {
            let response = this.$store.getters[MODULE_NAME + '/response'];
            return response;
        },

        notificationId() {
            return this.value.notificationId; 
        }
    },

    methods: {
       async refresh() {

            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData', {
                    notificationId: this.value.notificationId,
                });
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    created() {
        // Refresh will trigger loading of the collection settings information.
        this.refresh();
    }
}
</script>
