<template>
    <v-sheet>
        <v-container fluid class="grey lighten-3">
            <v-row>
                <v-col cols="12" lg="4" xl="2">
                    <v-container fluid class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <v-card tile>
                                    <v-card-text>
                                        <div class="text-h6">Total Amount</div>
                                    </v-card-text>
                                    <v-card-text>
                                        <div class="text-left text-h4">
                                            96.2 kg CO₂e / kWh
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-card tile>
                                    <v-card-text>
                                        <div class="text-h6">Performance Class</div>
                                    </v-card-text>
                                    <v-card-text>
                                        <div class="text-left text-h4">{{ carbonFootprintPerformanceClass }}</div>
                                    </v-card-text>
                                </v-card>

                            </v-col>
                        </v-row>
                    </v-container>
                    


                    
                </v-col>
                <v-col cols="12" lg="8" xl="10">
                    <v-card tile>
                        <v-card-text>
                            <div class="text-h6">Carbon Footprint</div>
                                
                        </v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <c-metrics-distribution-chart :items="distributedShares" type="cake" show-value-label />
                                </v-col>
                                <v-col>
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <th>Stage</th>
                                                <th class="text-right">Amount</th>
                                                <th class="text-right">Share</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) of distributedShares" :key="`co2-share-${index}`">
                                                <td>{{ $dxs.i18n.localizedName(item) }}</td>
                                                <td class="text-right">?</td>
                                                <td class="text-right">{{ item.value }}</td>
                                                <td width="50%"><v-progress-linear :color="item.color" background-color="grey lighten-3" :value="item.value" height="20"/></td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>

export default {

    name: 'carbon-footprint-page',

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    computed: {

        carbonFootprintPerformanceClass() {
            return 'B';
        },

        distributedShares() {
            return [
                {
                    name: 'rawMaterial',
                    value: 65.0,
                    color: '#005155'
                },
                {
                    name: 'mainProduction',
                    value: 21.9,
                    color: '#599099'
                },
                {
                    name: 'distribution',
                    value: 7.3,
                    color: '#7fb1bd'
                },
                {
                    name: 'recycling',
                    value: 5.8,
                    color: '#a5d3e3'
                }
            ]
        }
    }
}
</script>

<i18n>
{
    "en" : {

    },

    "de" : {

    }
}
</i18n>