import api from '@/store/api';
import { get } from 'lodash'; 

const COLLECTION_MAPPING_JOB_LOG = "MappingJobLog";
const COLLECTION_MAPPING_JOB_TASK_LOG = "MappingJobTaskLog";
const COLLECTION_MAPPING_JOB_ERROR_LOG = "MappingJobErrorLog";

export default {

    namespaced: true,

    state : {

        jobInstance: {

        },

        tasks: {

        },

        errors: {

        },

        jobTemplate: {

        },

        taskStats: {

        },
    },

    mutations: {

        setJobInstance(state, response) {

            if (response) {
                state.jobInstance = response;
            }
        },

        setJobTemplate(state, response) {

            if (response) {
                state.jobTemplate = response;
            }
        },

        

        setTasks(state, response) {

            if (response) {
                state.tasks = response;
            }
        },

        setTaskStats(state, response) {
            if (response) {
                state.taskStats = response;
            }
        },

        setErrors(state, response) {

            if (response) {
                state.errors = response;
            }
        },
    },


    actions: {

        async loadJobInstance(context, { jobInstanceId }) {
            
            

            try {

                // Mapping Job Instances are simply documents which are stored in a system collection
                const jobInstance = await api.collections.documents.findById(COLLECTION_MAPPING_JOB_LOG, jobInstanceId);
                context.commit('setJobInstance', jobInstance);

                // If the job instance could be loaded, also acquire it's dependent data.
                // -> At the first refresh after starting the job the document is most likely not created yet.
                if (jobInstance && jobInstance.document) {
                    
                    // Template
                    try {
                        const jobTemplateId = get(jobInstance, 'document.data.jobTemplateId');
                        const jobTemplate = await api.catalog.mappingJobTemplates.findById(jobTemplateId);
                        context.commit('setJobTemplate', jobTemplate); 
                        
                    } catch (error) {
                        context.commit('setJobTemplate', {});
                        console.log("No job template found for job instance")
                    }

                    // Tasks

                    const taskFilter = {
                        filterQuery : '*',
                        filterQueryLanguage: 'SIMPLE',
                        facetFilters: [{ name : 'jobInstanceId', filterQuery: jobInstanceId, values : [], filterMode : 'CONTAINS' }],
                        rangeFilters: []
                    };

                    try {
                        // Load first 200 tasks for the given job instance id.
                        const taskQuery = {
                            filter : taskFilter,
                            sort : {
                                sortCriteria : [
                                    {name : 'taskStartDateTime', ordering : 'DESC'}
                                ]
                            },
                            pagination : {
                                from : 0,
                                searchAfter : [],
                                size : 200
                            },
                            queryTimeoutMiliseconds: 25000
                        };
                        
                        const tasks = await api.collections.documents.findByQuery(COLLECTION_MAPPING_JOB_TASK_LOG, taskQuery);
                        context.commit('setTasks', tasks);

                    } catch (error) {
                        context.commit('setTasks', {});
                    }
                    
                    // ==================================================================
                    // Task Stats
                    // ==================================================================
                    // Tasks stats are required for monitoring progress
                   try {
                         const taskStatsQuery  = {
                             attributes: ['documentCountTotal', 'documentCountSuccess', 'documentCountFailed'],
                             filter: taskFilter
                         };

                         const taskStats = await api.collections.stats.getCollectionStatistics(COLLECTION_MAPPING_JOB_TASK_LOG, taskStatsQuery);
                         context.commit('setTaskStats', taskStats);
                    } catch (error) {
                        context.commit('setTaskStats', {});
                    }

                    // ==================================================================
                    // Errors
                    // ==================================================================
                    try {
                        // Load first 200 errors for the given job instance id.
                        const errorQuery = {
                            filter : {
                                filterQuery : '*',
                                filterQueryLanguage: 'SIMPLE',
                                facetFilters: [{ name : 'jobInstanceId', filterQuery: jobInstanceId, values : [], filterMode: 'CONTAINS' }],
                                rangeFilters: []
                            },
                            sort : {
                                sortCriteria : [
                                    {name : 'errorDateTime', ordering : 'DESC'}
                                ]
                            },
                            pagination : {
                                from : 0,
                                searchAfter : [],
                                size : 200
                            },
                            queryTimeoutMiliseconds: 25000
                        };
                        
                        const errors = await api.collections.documents.findByQuery(COLLECTION_MAPPING_JOB_ERROR_LOG, errorQuery);
                        context.commit('setErrors', errors);

                    } catch (error) {
                        context.commit('setErrors', {});
                    }
                }



                
                


            } catch (error) {
                context.commit('setJobInstance', {});
                throw error;
            }

        },
    },

    getters: {

        model(state) {

            // Prepare the task stats
            const taskStats = {};
            const taskStatsItems = get(state.taskStats, 'items', []);
            taskStatsItems.forEach(item => taskStats[item.name] = item.stats);

            return {
                jobInstance: get(state.jobInstance, 'document', {}),
                jobTemplate: get(state.jobTemplate, 'data.template', {}),
                sourceCollection: get(state.jobTemplate, 'data.sourceCollection', {}),
                sourceSchema: get(state.jobTemplate, 'data.sourceSchema', {}),
                targetCollection: get(state.jobTemplate, 'data.targetCollection', {}),
                targetSchema: get(state.jobTemplate, 'data.targetSchema', {}),

                tasks: get(state.tasks, 'items', []),
                taskCountTotal: get(state.tasks, 'pagination.totalItemCount', []),
                errors: get(state.errors, 'items', []),
                errorCountTotal:  get(state.errors, 'pagination.totalItemCount', []),

                taskStats: taskStats,
            }
        },

       
    
    }

}