<template>
  <div class="print">
    <c-sticky-content>
      <v-toolbar color="white" flat>
        <v-btn icon v-on:click="close">
            <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{$t('print.view.label')}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text color="accent" v-on:click="print"><v-icon>print</v-icon>{{$t('print.view.print.label')}}</v-btn>
      </v-toolbar>

      <v-divider />
    </c-sticky-content>

    <div style="height: 75px;"></div>

    <div class="print-paper">
        <router-view name="printView"></router-view>
    </div>

    <div style="height: 75px;"></div>
  </div>
</template>

<script>

export default {
  name: "c-print-view",

  data: () => ({ }),

  methods: {
      close() {
        this.$router.go(-1);
      },

      print() {
          window.print();
      },
  }
};
</script>

<i18n>
{
  "en" : {
    "print.view.print.label" : "Print",
    "print.view.label" : "Print View"
  },

  "de" : {
    "print.view.print.label" : "Drucken",
    "print.view.label" : "Druckansicht"
  }
}
</i18n>