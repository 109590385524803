<template>
    <v-card tile flat class="secondary">
            <v-container fluid class="pa-10" > 
                <v-row justify="center">  
                    <v-col md="10">
                        <v-card tile class="error white--text">
                            <v-card-text />
                            <v-card-text class="text-h6 text-center white--text"><v-icon x-large color="white">sentiment_dissatisfied</v-icon></v-card-text>
                             
                            <v-card-text class="text-h6 text-center white--text">{{$t('view-not-found.view.title')}}</v-card-text>
                            <v-card-text class="text-body2 text-center white--text">{{$t('view-not-found.view.subtitle')}}</v-card-text>
                            <v-card-text />
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
    </v-card>
</template>
    
<script>

export default {
    

    inject: ['shellService'],



}
</script>

<i18n>
{
    "en" : {
        
        "view-not-found.view.title" : "We couldn't find this page ...",
        "view-not-found.view.subtitle" : "We are sorry, but the requested page could not be found. Please use the navigation in order to visit available pages."
    },

    "de" : {
        "view-not-found.view.title" : "Wir können diese Seite leider nicht finden ...",
        "view-not-found.view.subtitle" : "Es tut uns sehr leid, aber die von Ihnen angeforderte Seite scheint leider nicht zu existieren. Bitte benützen Sie die Navigation, um die verfügbaren Seiten zu besuchen."
    }

    
}
</i18n>

