<template>
  <div>
    <c-section
      :title="$t('system-availability-dashboard.servicehealth.label')"
      :subtitle="
        $t('system-availability-dashboard.servicehealth.information.label')
      "
      class="secondary"
    >
      <div class="sticky">
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="6">
              <v-card class="text-center">
                <v-card-text>
                  <v-icon>autorenew</v-icon>
                  {{
                    $t(
                      "system-availability-dashboard.servicehealth.refresh.label", {interval:interval / 1000}
                    )
                  }}
                  {{
                    timestamp | localized-date-time-format($i18n.locale)
                  }}
                  <!-- if wrong auto-format => timestamp | localized-date-time-format($i18n.locale) -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-tabs
        fixed-tabs
        slider-color="white"
        v-model="selectedTab"
        background-color="secondary"
      >
        <v-tab :key="1"><v-icon>view_module</v-icon></v-tab>
        <v-tab :key="2"><v-icon>view_list</v-icon></v-tab>
        <v-tab :key="3"><v-icon>code</v-icon></v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item :key="1" class="secondary lighten-1">
            <v-divider />
            <v-subheader>{{ $t('system-availability-dashboard.servicehealth.servicecategories.gateway') }} </v-subheader>
            <v-container fluid>
                <v-row>
                <v-col v-for="service in gatewayServices" :key="'tile_' + service.name" cols="12" md="4" xl="3">
                     <c-system-availability-tile :value="service" :actual="itemsMap[service.name]" height="100%" />
                </v-col>
                </v-row>
            </v-container>
            <v-divider />
            <v-subheader>{{ $t('system-availability-dashboard.servicehealth.servicecategories.application')  }} </v-subheader>
            <v-container fluid>
                <v-row>
                <v-col v-for="service in applicationServices" :key="'tile_' + service.name" cols="12" md="4" xl="3">
                     <c-system-availability-tile :value="service" :actual="itemsMap[service.name]" height="100%" />
                </v-col>
                </v-row>
            </v-container>
            <v-divider />
            <v-subheader>{{ $t('system-availability-dashboard.servicehealth.servicecategories.backgroundProcess')  }} </v-subheader>
            <v-container fluid>
                <v-row>
                <v-col v-for="service in backgroundProcesses" :key="'tile_' + service.name" cols="12" md="4" xl="3">
                     <c-system-availability-tile :value="service" :actual="itemsMap[service.name]" height="100%" />
                </v-col>
                </v-row>
            </v-container>
            <v-divider />
            <v-subheader>{{ $t('system-availability-dashboard.servicehealth.servicecategories.technical')  }} </v-subheader>
            <v-container fluid>
                <v-row>
                <v-col v-for="service in technicalServices" :key="'tile_' + service.name" cols="12" md="4" xl="3">
                     <c-system-availability-tile :value="service" :actual="itemsMap[service.name]" height="100%" />
                </v-col>
                </v-row>
            </v-container>

        </v-tab-item>


        <v-tab-item :key="2">
          <v-container fluid class="secondary lighten-1">
            <v-row justify="center">
              <v-col cols="12" md="10">
                <v-card tile>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th width="5%">{{ $t("system-availability-dashboard.servicehealth.status.label") }}</th>
                                <th width="10%">{{ $t("system-availability-dashboard.servicehealth.servicename.label") }}</th>
                                <th width="15%">{{ $t("system-availability-dashboard.servicehealth.category.label") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="service in gatewayServices" :key="'tile_' + service.name" cols="12" md="4" xl="3">
                                <td>
                                    <v-icon v-if="itemsMap[service.name].status === 'UP'">check_circle</v-icon>
                                    <v-icon v-else color="error">error</v-icon>
                                </td>
                                <td>{{ service | localized-name($i18n.locale)}}</td>
                                <td>{{ $t('system-availability-dashboard.servicehealth.servicecategories.gateway') }}</td>
                            </tr>
                            <tr v-for="service in applicationServices" :key="'tile_' + service.name" cols="12" md="4" xl="3">
                                <td>
                                    <v-icon v-if="itemsMap[service.name].status === 'UP'">check_circle</v-icon>
                                    <v-icon v-else color="error">error</v-icon>
                                </td>
                                <td>{{ service | localized-name($i18n.locale)}}</td>
                                <td>{{ $t('system-availability-dashboard.servicehealth.servicecategories.application') }}</td>
                            </tr>
                            <tr v-for="service in backgroundProcesses" :key="'tile_' + service.name" cols="12" md="4" xl="3">
                                <td>
                                    <v-icon v-if="itemsMap[service.name].status === 'UP'">check_circle</v-icon>
                                    <v-icon v-else color="error">error</v-icon>
                                </td>
                                <td>{{ service | localized-name($i18n.locale)}}</td>
                                <td>{{ $t('system-availability-dashboard.servicehealth.servicecategories.backgroundProcess') }}</td>
                            </tr>
                            <tr v-for="service in technicalServices" :key="'tile_' + service.name" cols="12" md="4" xl="3">
                                <td>
                                    <v-icon v-if="itemsMap[service.name].status === 'UP'">check_circle</v-icon>
                                    <v-icon v-else color="error">error</v-icon>
                                </td>
                                <td>{{ service | localized-name($i18n.locale)}}</td>
                                <td>{{ $t('system-availability-dashboard.servicehealth.servicecategories.technical') }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item :key="3">
            <v-container fluid class="secondary lighten-1">
                <v-row justify="center">
                    <v-col cols="12" md="10">
                        <v-card tile>
                            <v-toolbar flat>
                                <v-toolbar-title>JSON</v-toolbar-title>
                            </v-toolbar>
                            <v-divider />
                            <v-card-text class="grey lighten-3">
                            <pre>{{JSON.stringify(itemsMap, true, 2)}}</pre>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-tab-item>
      </v-tabs-items>
    </c-section>
  </div>
</template>



<script>
import SystemAvailabilityTileComponent from "@/core/components/system/system-availability-tile.component.vue";
import { get, groupBy, forOwn, camelCase, upperFirst } from "lodash";

import SERVICE_MODEL from '@/core/views/system/service-availability/service-model';

const MODULE_NAME = "serviceAvailability";

export default {
  components: {
    "c-system-availability-tile": SystemAvailabilityTileComponent,
  },
  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "userContextService",
    "messageService",
    "envContextService"
  ],
  data() {
    return {
      timestamp: new Date(),
      selectedTab: 0,
      subServiceCache: [],
      services: [
        {
          category: 'Application Service',
          name: 'Catalog Rest Service',
          status: 'DOWN',
          dependsOn: ['PostgreSQL']
        },
        {
          category: 'Application Service',
          name: 'Index Rest Service',
          status: 'DOWN',
          dependsOn: ['Elastic Search']
        },
        {
          category: 'Application Service',
          name: 'Notification Rest Service', 
          status: 'DOWN',
        },
        {
          category: 'Application Service',
          name: 'Query Rest Service', 
          status: 'DOWN',
        },
        {
          category: 'Application Service',
          name: 'User Management Service',
          status: 'DOWN',
        },
        {
          category: 'Application Service',
          name: 'Document Import Service',
          status: 'DOWN',
        },
        {
          category: 'Application Service',
          name: 'Data Archiving Service',
          status: 'DOWN',
        },
        {
          category: 'Application Service',
          name: 'Data Preparation Service',
          status: 'DOWN',
        },
        {
          category: 'Application Service',
          name: 'Connector Management Service',
          status: 'DOWN',
        },
        {
          category: 'Application Service',
          name: 'Document Mapping Job Worker Service',
          status: 'DOWN',
        },
        {
          category: 'Application Service',
          name: 'Event Publisher Service',
          status: 'DOWN',
        },
        {
          category: 'Application Service',
          name: 'Webhook Management Service',
          status: 'DOWN',
        },
      ],
      subSystems: [
        {
          category: 'Sub System',
          name: 'Database connections health check',
          status: 'DOWN',
          superServices: ['Catalog Rest Service'],
        },
        {
          category: 'Sub System',
          name: 'Elasticsearch cluster health check',
          status: 'DOWN',
          superServices: ['Query Rest Service', 'Index Rest Service', 'Notification Rest Service'],
        },
        {
          category: 'Sub System',
          name: 'SmallRye Reactive Messaging - readiness check',
          status: 'DOWN',
          superServices: ['Document Import Service', 'Data Archiving Service', 'Data Preparation Service', 'Connector Management Service',
          'Document Mapping Job Worker Service', 'Event Publisher Service', 'Index Rest Service', 'Webhook Management Service'],
        }
      ],
      gateways: [
        {
          category: 'Gateways',
          name: 'API-Gateway',
          status: 'DOWN',
        }
      ],
      categories: [
        'Application Services',
        'Sub System'
      ]
    };
  },
  computed: {
    
    serviceModel() {
        return SERVICE_MODEL;
    },

    technicalServices() {
        return get(this.serviceModel, 'technicalServices', []);
    },

    applicationServices() {
        return get(this.serviceModel, 'applicationServices', []);
    },

    gatewayServices() {
        return get(this.serviceModel, 'gatewayServices', []);
    },

    backgroundProcesses() {
        return get(this.serviceModel, 'backgroundProcesses', []);
    },

    response() {
      return this.$store.getters[MODULE_NAME + "/response"];
    },


    items() {
      return get(this.response, "items", []);
    },

    itemsMap() {

        let map = {};
        for (const item of this.items) {
            
            const key = upperFirst(camelCase(item.name));            
            map[key] = item;

            // Check dependencies
            // TODO: This information should really be sent from the gateway analogous to the
            // way application services are handled. This code would then become obsolete.
            const dependencies =  get(item, 'subServices' ,{});
            for (const serviceKey in dependencies) {
                map[upperFirst(camelCase(serviceKey))] = {
                    name: serviceKey,
                    status: dependencies[serviceKey] ? dependencies[serviceKey] : 'UP'
                }
            }

        }

        // DXS-703: The API Gateway is not delivered from the backend currently. 
        map['API Gateway'] = {
            "category": "",
            "name": "API Gateway",
            "status": "UP",
            "subServices": {}
        }


        return map;
    },

    allItems() {
      let result = []
        for(let actual of this.items){
          result.push(actual);
        }
        if(result.length != this.services.length){
          let resultNames = []
          for(let service of result){
            resultNames.push(service.name)
          }
          for(let service of this.services){
            if(!resultNames.includes(service.name))
              result.push(service)
          }
        }
      return result;  
    },
    // The combined subSystems of prop and actual subSystems
    allSubSystems() {
      let result = []
      for(let actual of this.subServices){
        result.push(actual);
      }
      if(result.length != this.subSystems.length){
        let resultNames = []
        for(let service of result){
          resultNames.push(service.name)
        }
        for(let subSystem of this.subSystems){
          if(!resultNames.includes(subSystem.name))
            result.push(subSystem)
        }
      }
      return result;
    },
    subSystemNames(){
      let result = [];
      for(let system of this.subSystems){
        result.push(system.name);
      }
      return result;
    },
    itemsGroupedByCategory() {
      return groupBy(this.allServices, (item) => item.category);
    },
    unavailableServices() {
      let services = [];
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].status == "DOWN") {
          services.push(this.items[i].name);
        }
      }
      return services;
    },
    //The actual subSystems with a status
    subServices() {
      let subServiceList = [];
      for (let item of this.items) {
        forOwn(get(item, "subServices", {}), (value, key) => {
          let service = {
            category: this.$t("system-availability-dashboard.servicehealth.subssystem.label"),
            name: key,
            status: value,
            superServices: [item.name],
          };
          if (!subServiceList.some((item) => item.name === service.name)) {
            subServiceList.push(service);
          } else {
            let i = subServiceList.findIndex(
              (item) => item.name === service.name
            );
            subServiceList[i].superServices.push(item.name);
          }
        });
      }
      return subServiceList;
    },
    allActualServices() {
      let array = [...this.items, ...this.subServices];
      return array.sort((a, b) => a.name.localeCompare(b.name));
    },
    allServices(){
      let array = [...this.services, ...this.subSystems, ...this.gateways];
      return array.sort((a, b) => a.name.localeCompare(b.name));
    },
    interval(){
      return this.envContextService.serviceHealthCheckIntervalMS;
    }
  },

  methods: {
    async refresh() {
      this.progressIndicatorService.show();

      try {
        await this.$store.dispatch(MODULE_NAME + "/loadResponse");
        this.gateways[0].status = 'UP'
        this.dateTimeNow();
      } catch (error) {
        this.gateways[0].status = 'DOWN'
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },
    getActualItem(item){
      let actualItem = {};
      if(item.name == 'API-Gateway'){
        return this.gateways[0];
      }
      for(let x of this.allActualServices){
        if(x.name == item.name){
          actualItem = x;
          break;
        }
      }
      return actualItem;
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    dateTimeNow() {
      this.timestamp = new Date();
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  created() {
    this.refresh();
    this.timer = setInterval(this.refresh, this.interval);
  },
};
</script>

<i18n>
{
    "en":{
        "system-availability-dashboard.servicehealth.label": "Service Health",
        "system-availability-dashboard.servicehealth.information.label": "Please find the health status of the dashboard services below.",
        "system-availability-dashboard.servicehealth.refresh.label": "This dashboard will automatically refresh every {interval} seconds. Last Refresh: ",
        "system-availability-dashboard.servicehealth.unavailable.label": "Service(s) not available.",
        "Application Service": "Application Services",
        "Gateways": "Gateways",
        "SmallRye Reactive Messaging - readiness check": "Kafka Service Health Check",
        "system-availability-dashboard.servicehealth.subssystem.label":"Sub System",
        "system-availability-dashboard.servicehealth.status.label":"Status",
        "system-availability-dashboard.servicehealth.servicename.label":"Service Name",
        "system-availability-dashboard.servicehealth.category.label":"Category",

        "system-availability-dashboard.servicehealth.servicecategories.application": "Application Services",
        "system-availability-dashboard.servicehealth.servicecategories.technical": "Technical Services",
        "system-availability-dashboard.servicehealth.servicecategories.gateway": "Gateway Service",
        "system-availability-dashboard.servicehealth.servicecategories.backgroundProcess": "Background Processing"

    },
    "de":{
        "system-availability-dashboard.servicehealth.label": "Serviceverfügbarkeit",
        "system-availability-dashboard.servicehealth.information.label": "Eine Übersicht über den Servicestatus der Dienste finden Sie unten.",
        "system-availability-dashboard.servicehealth.refresh.label": "Das Dashboard wird alle {interval} Sekunden aktualisiert. Letzte Aktualisierung: ",
        "system-availability-dashboard.servicehealth.unavailable.label": "Dienst(e) nicht verfügbar.",
        "Application Service": "Anwendungsdienste",
        "Gateways": "Gateways",
        "system-availability-dashboard.servicehealth.subssystem.label":"Technische Services",
        "system-availability-dashboard.servicehealth.status.label":"Status",
        "system-availability-dashboard.servicehealth.servicename.label":"Service-Name",
        "system-availability-dashboard.servicehealth.category.label":"Kategorie",

        "system-availability-dashboard.servicehealth.servicecategories.application": "Anwendungs-Services",
        "system-availability-dashboard.servicehealth.servicecategories.technical": "Technische Services",
        "system-availability-dashboard.servicehealth.servicecategories.gateway": "Gateways",
        "system-availability-dashboard.servicehealth.servicecategories.backgroundProcess": "Hintergrund-Prozesse"

    }
}
</i18n>