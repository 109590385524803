import { get, isArray, isObject } from 'lodash';
import { DateTime } from 'luxon';
import api from '../../../api/smart-factory.api';

import coreApi from '@/core/api/v2.api';


export default {

    namespaced: true,

    state: {

        model: {
            site: { 
                name: null,
                i18n: { }
            },

            shiftEventTypes: [],
            workplaces: [],
        },

        item: {
            siteId: null,
            dateTime: null,
            shiftEventTypeId: null,
            workplaceId: null,
            description: null,
        }
    },

    mutations: {

        setSiteId(state, siteId) {

            if (siteId) {
                state.item.siteId = siteId;
            } else {
                state.item.siteId = null;
            }
        },

        setDateTime(state, dateTime) {

            if (dateTime) {
                state.item.dateTime = dateTime;
            } else {
                state.item.dateTime = null;
            }
        },

        setShiftEventTypeId(state, shiftEventTypeId) {

            if (shiftEventTypeId) {
                state.item.shiftEventTypeId = shiftEventTypeId;
            } else {
                state.item.shiftEventTypeId = null;
            }
        },

        setWorkplaceId(state, workplaceId) {

            if (workplaceId) {
                state.item.workplaceId = workplaceId;
            } else {
                state.item.workplaceId = null;
            }
        },

        setDescription(state, description) {

            if (description) {
                state.item.description = description;
            } else {
                state.item.description = null;
            }
        },

        setSite(state, site) {

            const item = get(site, 'item.site', {});
            if (isObject(item)) {
                state.model.site = item;
            } else {
                state.model.site = {};
            }
        },

        setWorkplaces(state, workplaces) {

            const items = get(workplaces, 'items', []);
            if (isArray(items)) {
                state.model.workplaces = items;
            } else {
                state.model.workplaces = [];
            }
        },

        setShiftEventTypes(state, shiftEventTypes) {

            const items = get(shiftEventTypes, 'items', []);
            if (isArray(items)) {
                state.model.shiftEventTypes = items;
            } else {
                state.model.shiftEventTypes = [];
            }
        }
    },

    actions: {

        async saveData(context) {
            
           const item =  context.getters.item;
           
           // Prepare the document request
           const request = {
                
                // Map to expected names.
                data: {
                    site: item.siteId,
                    dateTime: item.dateTime,
                    eventType: item.shiftEventTypeId,
                    workplace: item.workplaceId,
                    description: item.description
                },

                // Left empty intentionally.
                i18n: {

                }
           };

           console.log('Request: >>>>>>>>> ', JSON.stringify(request));
           await coreApi.collections.documents.create('ShiftEventLog', request);
        },

        resetData(context) {

            context.commit('setSiteId', null);
            context.commit('setDateTime', null);
            context.commit('setShiftEventTypeId', null);
            context.commit('setWorkplaceId', null);
            context.commit('setDescription', null);

            context.commit('setSite', null);
            context.commit('setWorkplaces', null);
            context.commit('setShiftEventTypes', null);
            
        },

        async loadData(context, {siteId}) {

            context.commit('setSiteId', siteId);
            context.commit('setDateTime', DateTime.now().toISO());

            const siteResponse = await api.masterdata.sites.findById(siteId);
            context.commit('setSite', siteResponse);

            const workplaceResponse = await api.masterdata.workplaces.findBySiteId(siteId, 0, 50);
            context.commit('setWorkplaces', workplaceResponse);

            const shiftEventTypeResponse = await api.masterdata.shiftEventTypes.findByFilter('*', 0, 50);
            context.commit('setShiftEventTypes', shiftEventTypeResponse);
        },


        updateSiteId(context, siteId) {
            context.commit('setSiteId', siteId);
        },

        updateDateTime(context, dateTime) {
            context.commit('setDateTime', dateTime);
        },

        updateShiftEventTypeId(context, shiftEventTypeId) {
            context.commit('setShiftEventTypeId', shiftEventTypeId);
        },

        updateWorkplaceId(context, workplaceId) {
            context.commit('setWorkplaceId', workplaceId);
        },

        updateDescription(context, description) {
            context.commit('setDescription', description);
        },
    },

    getters: {

        model(state) {
            return state.model;
        },

        item(state) {
            return state.item;
        }
    }
}