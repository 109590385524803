import api from '@/store/api';

import { cloneDeep } from 'lodash';
export default {

    namespaced: true,

    state : {

        mappingJobTemplate: getDefaultMappingTemplate(),
        sourceCollection: {},
        sourceSchema: {},
        targetCollection: {},
        targetSchema: {},
    },

    mutations: {

        setResponse(state, response) {

            if (response.data) {
                state.mappingJobTemplate = response.data.template ? response.data.template : {} ;
                state.sourceCollection = response.data.sourceCollection ? response.data.sourceCollection : {} ;
                state.sourceSchema = response.data.sourceSchema ? response.data.sourceSchema : {} ;
                state.targetCollection = response.data.targetCollection ? response.data.targetCollection : {} ;
                state.targetSchema = response.data.targetSchema ? response.data.targetSchema : {} ;
            } else {
                state.frjsdjfshjfhjsdjfsjfj
            }
            
        },

        setFilter(state, filter) {
            if (state.mappingJobTemplate) {
                state.mappingJobTemplate.filter = filter;
            }
        },

        setMappings(state, mappings) {
            if (state.mappingJobTemplate) {
                state.mappingJobTemplate.mappings = mappings;
            }
        },

        setPageSize(state, pageSize) {
            if (state.mappingJobTemplate) {
                state.mappingJobTemplate.pageSize = pageSize;
            }
        }
    },

    actions: {

        async loadData(context, { name }) {

            try {
                let response = await api.catalog.mappingJobTemplates.findById(name);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async resetData(context) {
            context.commit('setResponse', getDefaultResponse());
        },

        async updateMappingJobTemplate(context, {name}) {
            
            try {
                let payload = context.getters.mappingJobTemplate;
                let response = await api.catalog.mappingJobTemplates.update(name, payload);
                context.commit('setResponse', response);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateFilter(context, filter) {
            context.commit('setFilter', filter);
        },

        async addMapping(context, { mapping, index }) {
            
            // Cloning of the mappings is crucial - Vue's reactivity system will not
            // detect the change otherwise.
            let mappingJobTemplate = context.getters.mappingJobTemplate;
            let mappings = cloneDeep(mappingJobTemplate.mappings);
            mappings[index] = mapping;

            context.commit('setMappings', mappings)
        },

        async removeMapping(context, { mapping }) {

             // Cloning of the mappings is crucial - Vue's reactivity system will not
            // detect the change otherwise.
            let mappingJobTemplate = context.getters.mappingJobTemplate;
            let mappings = cloneDeep(mappingJobTemplate.mappings);

            // Remove by filtering
            mappings = mappings.filter(e => e.targetAttributeId !== mapping.targetAttributeId)

            context.commit('setMappings', mappings);

        },

        updatePageSize(context, pageSize){
            context.commit('setPageSize', pageSize)
        }
    },

    getters: {

        response(state) {
            return {
                mappingJobTemplate : state.mappingJobTemplate ? state.mappingJobTemplate : {},
                source: {
                    collectionSettings: state.sourceCollection,
                    schema: state.sourceSchema
                },

                target: {
                    collectionSettings: state.targetCollection,
                    schema: state.targetSchema
                }
            };
        },

        mappingJobTemplate(state) {
            return state.mappingJobTemplate ? state.mappingJobTemplate : getDefaultMappingTemplate();
        }

    }
}

function getDefaultResponse() {
    
    return {
        data: getDefaultMappingTemplate()
    };
}

function getDefaultMappingTemplate() {
    return {

        name: null,
        sourceCollectionId: null,
        targetCollectionId: null,

        filter: {
            filterQuery: "*", 
            filterQueryLanguage: "SIMPLE",
            facetFilters: [],  
            rangeFilters: []
        },


        mappings: [],

        pageSize: 100,

        custData: {},

        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null
    };
}

