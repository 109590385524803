import api from '@/store/api';

export default {

    namespaced: true,

    state: {

        collectionPermission: createEmptyCollectionPermission(),

        collectionSettings: []
    },

    mutations: {

        setCollectionPermission(state, collectionPermission) {

            if (collectionPermission) {
                state.collectionPermission.name = collectionPermission.name ? collectionPermission.name : null;
                state.collectionPermission.collectionId = collectionPermission.collectionId ? collectionPermission.collectionId : null;
            } else {
                state.collectionPermission.name = null;
                state.collectionPermission.collectionId = null;
            }

        },   

        setCollectionSettings(state, collectionSettings) {
            state.collectionSettings = collectionSettings ? collectionSettings : [];
        }
    },

    actions: {
        
        async loadData(context) {

            try {
                let response = await api.catalog.collectionSettings.findAll('*');
                
                let collectionSettings = [];
                for (let item of response) {
                    collectionSettings.push(item.collectionSettings);
                }

                context.commit('setCollectionSettings', collectionSettings);

            } catch (error) {
                return Promise.reject(error);
            }
        },

        async createCollectionPermission(context) {

            try {
                
                let request = context.getters.request;
                await api.catalog.collectionPermissions.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetCollectionPermission(context) {
            context.commit('setCollectionPermission', createEmptyCollectionPermission)
        },

        updateCollectionPermission(context, collectionPermission) {
            context.commit('setCollectionPermission', collectionPermission);
        }

    },



    getters: {

        
        request(state) {

            let request = createEmptyCollectionPermission();

            request.name = state.collectionPermission.name;
            request.collectionId = state.collectionPermission.collectionId;
            
            return request;
        },

        collectionSettings(state) {
            return state.collectionSettings ? state.collectionSettings  : [];
        },

        collectionPermission(state) {
            return state.collectionPermission ? state.collectionPermission : createEmptyCollectionPermission();
        }
    }
}

function createEmptyCollectionPermission() {

    return  {
        name: null,
        collectionId: null,
        active: true,
        effectiveFrom: new Date().toISOString(),
        effectiveUntil: '2100-01-01T00:00:00Z',
        userGroupIds: [],
        userIds: [],
    };
}