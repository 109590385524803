<template>
    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <c-base-dialog :value="workplaceType" :title="$t('workplaceTypes-wizard.view.title')"
                    :subtitle="$t('workplaceTypes-wizard.view.subtitle')" color="secondary" @dialog-saved="save"
                    @dialog-canceled="cancel">
                    <template v-slot:default="props">
                        <v-card flat>
                            <v-card-text>
                                <c-name-input v-model="props.item.name" filled />
                            </v-card-text>
                        </v-card>
                    </template>
                </c-base-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import WorkplaceTypeWizardModule from "../../../store/masterdata/workplace-types/workplace-types-wizard.module";

const MODULE_NAME = "workplaceTypesWizard";

export default {
    name: "workplace-types-wizard",

    inject: [
        "errorHandlerService",
        "progressIndicatorService",
        "envContextService",
    ],

    methods: {
        async save() {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + "/createWorkplaceType");

                this.$router.push({
                    name: "WorkplaceTypesEditorView",
                    params: { name: this.workplaceType.name },
                });
                this.reset();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.reset();
            this.$router.push({
                name: "WorkplaceTypeListView",
            });
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetWorkplaceType");
            this.setTenantId(this.tenantId);
        },
    },

    computed: {
        workplaceType: {
            get() {
                return this.$store.getters[MODULE_NAME + "/workplaceType"];
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_NAME + "/updateWorkplaceType", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },

        tenantId() {
            return this.envContextService.tenantId;
        }

    },

    created() {
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, WorkplaceTypeWizardModule);
        }

        this.reset();
    },
};
</script>
  
<i18n>
  {
      "en" : {
          "workplaceTypes-wizard.view.title" : "Create New Workplace Type",
          "workplaceTypes-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Workplace Type."
      },
  
      "de" : {
          "workplaceTypes-wizard.view.title" : "Neuen Arbeitsplatztyp anlegen",
          "workplaceTypes-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Arbeitsplatztypen."
      }
  }
  </i18n>
  
  
  