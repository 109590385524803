<template>
    <v-sheet  color="secondary">
        <c-section :title="$t('tags-list.view.sections.list.title')" :subtitle="$t('tags-list.view.sections.list.subtitle')" class="secondary">

            <v-card class="mt-5 mb-5" tile>
                <c-item-list
                    :title="$t('tags-list.view.toolbar.title')" 
                    :items="items" 
                    :pagination="pagination"
                    :disable-edit-item="!hasEditRole && !hasReadRole" 
                    :disable-delete-item="!hasDeleteRole"
                    :supportsDeleteItem="true"
                    :supportsAddItem="true"
                    @refresh-list="refresh"
                    @previous-page="onPreviousPage"
                    @next-page="onNextPage"
                    @item-edited="onItemEdited" 
                    @item-deleted="onItemDeleted"
                    @item-added="onItemAdded" 
                    @item-list-filter-applied="onFilterChanged"
                >

                    <template v-slot:item-list-table-head-columns>
                        <th class="text-center" >{{$t('tags-list.view.columns.tagPreview')}}</th>
                    </template>

                    <template v-slot:item-list-table-body-columns="{item}">
                        <td class="text-center">
                            <c-item-tag :value="item" /> 
                        </td>
                    </template>


                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('tags-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('tags-list.view.help.description')}}</v-card-text>
            </v-card>
        </c-section>
        
    </v-sheet>
</template>

<script>
const MODULE_ID = 'tagsList';

import TagsListModule from '@/core/store/modules/catalog/tags/tags-list.module';
import Roles from '@/roles';
import { get } from 'lodash';



export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    computed: {

        model() {
            return this.$store.getters[MODULE_ID + '/model'];
        },

        items() {
            return this.model.items;
        },

        pagination() {
            return this.model.pagination;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.TAGS_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.TAGS_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.TAGS_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.TAGS_DELETE);
        }
    },

    methods: {

        async refresh() {
            this.loadPage(0, 50);
        },

        async onNextPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadResponse", {from, pageSize});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemAdded() {
            this.$router.push({
                name: 'TagWizardView',
            });
        },

        onItemEdited(event) {

            if (event) {
                this.$router.push({
                    name: 'TagEditorView',
                    params: {
                        name: get(event, 'item.name', {})
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to user list template editor.")
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },


        async onItemDeleted(event) {

            try {
               let context = {
                userId : event.item.userId,
                collectionId : event.item.collectionId,
                name : event.item.name
               }
               await this.$store.dispatch(MODULE_ID + "/deleteItem", context)
               await this.refresh()
            }  catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    // ==================================================================
    // HOOKS
    // ==================================================================
    created() {
        // Dynamically register the vuex store module for managing the data.
        if (!this.$store.hasModule(MODULE_ID)){
            this.$store.registerModule(MODULE_ID, TagsListModule);
        }

        this.refresh();
    }

}
</script>

<i18n>
{
    "en" : {
        "tags-list.view.sections.list.title" : "Tag Management",
        "tags-list.view.sections.list.subtitle" : "You may use this dialog for deleting user list templates.",
        "tags-list.view.toolbar.title" : "Tags",

        "tags-list.view.columns.tagPreview" : "Preview",


        "tags-list.view.help.title" : "What are Tags?",
        "tags-list.view.help.description" : "Tags allow you to easily add metadata to objects, e.g. in order to put collections into user-defined categories."

    },

    "de" : {
        "tags-list.view.sections.list.title" : "Verwaltung Tags ",
        "tags-list.view.sections.list.subtitle" : "Sie können auf diesem Dialog Tags anlegen, editieren und löschen.",
        "tags-list.view.toolbar.title" : "Tags",

        "tags-list.view.columns.tagPreview" : "Vorschau",


        "tags-list.view.help.title" : "Was sind Tags?",
        "tags-list.view.help.description" : "Mit Tags können Sie Objekten auf einfache Weise Metadaten hinzufügen, z.B. um Datenbereiche in Kategorien einzuteilen."
    }
}
</i18n>