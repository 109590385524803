
<template>
  <div class="secondary darken-1 pt-10 pa-20">
      <v-card tile >
      <!-- for navigation -->
      <c-sticky-content>
        <v-toolbar color="white" flat>
            <v-toolbar-items><v-btn icon @click="cancel"><v-icon>arrow_back</v-icon></v-btn></v-toolbar-items>
            <div class="text-h6">{{ $t("dead-letter-detail.documentId") }}: {{documentId}} </div>
            <v-spacer />
            <v-toolbar-items><v-btn text @click="cancel">{{$t("dead-letter-detail.cancel.label")}}</v-btn></v-toolbar-items>
        </v-toolbar>
      </c-sticky-content>
        <!-- Collection -->
        <v-card class="pa-5" outlined>
            <v-card-title>{{ $t("dead-letter-detail.collectionTitle") }}</v-card-title>
            <v-card-text class="text-h6 grey--text"> 
               {{ $t("dead-letter-detail.collectionSubtitle") }} {{collectionId}}
                <div class="text-h6 grey--text text-left">   </div>  
                <div class="text-h6 grey--text text-left"><v-btn class=" ma-5 white--text" absolute dark top right color="accent" v-bind="attrs" v-on="on" @click="switchToCollection"><v-icon left>open_in_new</v-icon>{{ $t("dead-letter-detail.collection.open.icon") }}</v-btn></div>
            </v-card-text>
        </v-card>
        <!-- Error message -->
        <v-card class="pa-5" outlined>
            <v-card-title>{{ $t("dead-letter-detail.errorMessage") }}</v-card-title>
            <v-card-text> <div class="text-h6 grey--text text-left"> {{errorMessage}} </div> </v-card-text>
        </v-card>
        <!-- Document Data JSON View  -->
        <v-card class="pa-5">  
            <v-card-title class="subheader">{{ $t("dead-letter-detail.documentData") }}</v-card-title>
            <v-row class="ml-5 mt-5">
                <prism-editor class="my-editor" :value="documentData" :highlight="highlighter" line-numbers readonly></prism-editor>
            </v-row>
            <!------- Dialog --------->
            <v-dialog v-model="dialog" persistent fullscreen scrollable>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class=" ma-5 white--text" absolute dark top right color="accent" v-bind="attrs" v-on="on" :disabled="!hasEditRole">
                        <v-icon left>edit</v-icon> {{ $t("dead-letter-detail.JSONedit") }} 
                    </v-btn>
                </template>
                 
                <v-card>
                    <v-card-title class="text-h6"> {{$t("dead-letter-detail.dialog.titel")}}</v-card-title>
                    <v-card-subtitle mt-5>{{$t("dead-letter-detail.dialog.subTitel")}} <v-spacer/> {{$t("dead-letter-detail.dialog.hint")}}</v-card-subtitle>
                    <div class="text-right red--text mt-5" v-if="editedJson && jsonError">{{jsonError}}</div>
                    <v-toolbar flat color="white" >
                        <v-spacer/>
                        <v-btn color="accent" text 
                            @click="dialog = false" v-on:click="upload" :disabled="disabled" > {{$t("dead-letter-detail.dialog.upload.label")}} </v-btn>
                        <v-btn color="grey" text 
                            @click="dialog = false"> {{$t("dead-letter-detail.cancel.label")}} </v-btn>
                    </v-toolbar>

                    <v-divider />
                    
                    <!-- Editor -->
                    <prism-editor class="my-editor" @input="checkJsonFormat" v-model="editedJson" :highlight="highlighter" line-numbers></prism-editor> 
                      
                    
                </v-card>
            </v-dialog>
        </v-card>
      </v-card>
  </div>
</template>
<script>
import get from 'lodash/get';
// import Prism Editor
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
//import highlighting library 
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-json'; // must for json
import 'prismjs/themes/prism-tomorrow.css'; // prism-okaidia.css also has highlight of numerical

const MODULE_NAME = "deadLetter";
import Roles from '@/roles';

export default {

    inject: ["errorHandlerService", "messageService", "userContextService"],

    components: {
      PrismEditor,
    },
    props: {
        value: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            dialog: false,
            editedJson: this.jsonData,
            jsonError: null,
            disabled: false, 
        }  
    },
    watch: {
        jsonData(editedValue) {
            this.editedJson = editedValue;
        }
    }, 
    computed: {
        response() {
            let response = this.$store.getters[MODULE_NAME + '/response'];   
            return response;
        },
        documentId() {
            return this.value.documentId;   
        },
        document() {
            return this.response.document ? this.response.document : {};
        },
        errorMessage() { // errorMessage will not be updated because the bad request error is catched
            return get(this.document, 'data.errorMessage', 'no error');
        },
        collectionId() {
            return get(this.document, 'data.collectionId', null);
           
        },
        documentData() {
            let json = get(this.document, 'data.documentData', '');
            try {
                return JSON.stringify(JSON.parse(json), true, 2);
              } catch (error) {
                return json;
            }
        },
        jsonData: {
            get() {
              let json = get(this.document, 'data.documentData', '');
              try {
                return JSON.stringify(JSON.parse(json), true, 2); 
              } catch (error) {
                  return json;
              }
            }
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.DEADLETTERS_EDIT);
        },
    },
    methods: {
        highlighter(jsonData) {
            return highlight(jsonData, languages.json); 
        },
        checkJsonFormat() {
            try {
                JSON.parse(this.editedJson);
                this.jsonError = null;
                this.disabled = false;
            } catch(error) {
                this.jsonError = error;
                this.disabled = true;
            }
        },  
        updateDocument() {
            this.document.data.documentData = this.editedJson;
        },
        async refresh() {
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse", this.value.documentId);
                this.editedJson = this.jsonData;
            } catch (error) {
                this.errorHandlerService.handleError(error);
            }
        },
        async upload() {
            try {
            
                this.updateDocument();

                let documentData = JSON.parse(this.document.data.documentData);
                let data = documentData.data ? documentData.data : {};
                let i18n = documentData.i18n ? documentData.i18n : {};
                let embedded = documentData.embedded ? documentData.embedded : {};

                let documentRecord = {
                    data : data,
                    i18n : i18n,
                    embedded : embedded,
                }
                
                // Reingest the edited dead letter
                await this.$store.dispatch(MODULE_NAME + "/reingest", {collectionId: this.collectionId, documentRecord} );

                let reingestResponse = this.$store.getters[MODULE_NAME + '/reingestResponse'];   

            if ( reingestResponse ) {
                this.errorMessage = null;
                this.messageService.show({
                    title: this.$t('dead-letter-detail.message.title', this.$i18n.locale),  
                    text: this.$t('dead-letter-detail.message.text', this.$i18n.locale)
                });
            }
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } 
        },
        switchToCollection() {
            if (this.collectionId) {
                try { 
                    let routeData = this.$router.resolve({path: '/collections/' + this.collectionId + '/documents'})
                    window.open(routeData.href);
                } catch(error){
                    this.$router.push({path: '/collections/' + this.collectionId + '/documents' })
                    this.errorHandlerService.handleError(error);
                }
            }
        },
        cancel() {
            this.$router.push({name: "deadLetterList"});
        },
    },
    created() {
        this.refresh();
    }
}
</script>
<style>
  /* required class */
  .my-editor {
    color: #ccc;
    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 16px;
    line-height: 1.5;
  }
  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }
</style>

<i18n>
{
    "en": {
        "dead-letter-detail.documentId": "Document ID",
        "dead-letter-detail.collectionTitle": "Collection",
        "dead-letter-detail.collectionSubtitle": "This document belongs to collection: ",
        "dead-letter-detail.collection.open.icon": "open collection",
        "dead-letter-detail.errorMessage": "Error Message",
        "dead-letter-detail.documentData": "Document Data",
        "dead-letter-detail.JSONedit": "Edit",
        "dead-letter-detail.dialog.titel": "Edit Document",
        "dead-letter-detail.dialog.subTitel": "In this dialog, you can edit and upload the document. Each change will be saved automatically.",
        "dead-letter-detail.dialog.hint": "Hint: You can quickly find specific words with the hotkey 'CTRL+F' ",
        "dead-letter-detail.dialog.upload.label": "Upload",
        "dead-letter-detail.cancel.label": "Cancel",
        "dead-letter-detail.switch.to.collection": "Click the icon to view the collection",
        "dead-letter-detail.message.title": "Upload successfully",
        "dead-letter-detail.message.text": "You can click the icon in \"Collection\" above to view the document"


    },
    "de": {
        "dead-letter-detail.documentId": "Dokument ID",
        "dead-letter-detail.collectionTitle": "Datenbereich",
        "dead-letter-detail.collectionSubtitle": "Dieses Dokument gehört zum Datenbereich: ",
        "dead-letter-detail.collection.open.icon": "Datenbereich öffnen",
        "dead-letter-detail.errorMessage": "Fehlermeldung",
        "dead-letter-detail.documentData": "Dokumentdaten",
        "dead-letter-detail.JSONedit": "Editieren",
        "dead-letter-detail.dialog.titel": "Dokument editieren",
        "dead-letter-detail.dialog.subTitel": "Hier können Sie das Dokument bearbeiten und hochladen. Das Dokument wird automatisch nach jeder Änderung gespeichert",
        "dead-letter-detail.dialog.hint": "Hinweis: Mit der Tastenkombination 'STRG + F' können Sie schnell bestimmte Wörten finden",
        "dead-letter-detail.dialog.upload.label": "Hochladen",
        "dead-letter-detail.cancel.label": "Abbrechen",
        "dead-letter-detail.switch.to.collection": "Klicken Sie auf das Symbol, um sich den Datenbereich anzusehen",
        "dead-letter-detail.message.title": "Hochladen erfolgreich",
        "dead-letter-detail.message.text": "Sie können oben auf das Symbol in der \"Datenbereich\" klicken, um das Dokument anzuzeigen"
    }
}
</i18n> 
