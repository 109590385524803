import { get, set } from 'lodash';
import api from '../../../api/battery-data-hub.api';

export default {

    namespaced: true,

    state: {
        batteryMaterial: createEmptyBaterryMaterial(),
        tenantId: null,
        hazards:{items:[]},
        casIdentifier: null
    },

    mutations: {

        setBatteryMaterial(state, batteryMaterial) {

            if (batteryMaterial) {
                state.batteryMaterial.tenantId = batteryMaterial.tenantId ? batteryMaterial.tenantId : null;
                state.batteryMaterial.name = batteryMaterial.name ? batteryMaterial.name : null;
                state.batteryMaterial.i18n = batteryMaterial.i18n ? batteryMaterial.i18n : {};
                state.batteryMaterial.data.custData = batteryMaterial.data.custData ? batteryMaterial.data.custData : {};
                state.batteryMaterial.active = batteryMaterial.active ? batteryMaterial.active : true;
                state.batteryMaterial.references = batteryMaterial.references ? batteryMaterial.references : {};
                state.batteryMaterial.data.casIdentifier = batteryMaterial.data.casIdentifier ? batteryMaterial.data.casIdentifier : null;

            } else {
                state.batteryMaterial.tenantId = null;
                state.batteryMaterial.name = null;
                state.batteryMaterial.i18n = null;
                state.batteryMaterial.data = null;
                state.batteryMaterial.active = null;
                state.batteryMaterial.references = null;
                state.batteryMaterial.data.casIdentifier = null;
                state.batteryMaterial.data.custData = {};
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.batteryMaterial.tenantId = tenantId;

            } else {
                state.batteryMaterial.tenantId = null;
            }
        },
        setCasIdentifier(state, casIdentifier) {
            if (casIdentifier) {
                state.batteryMaterial.data.casIdentifier = casIdentifier;

            }
        },
        setHazardousSubstanceClassifications(state, hazards){
            
            if (hazards) {
                    state.hazards.items = hazards.items;
    
            }
        },
        setHazardousSubstanceClassification(state,hazard){
            if (hazard) {
                set(state,"batteryMaterial.references.hazardoussubstanceclassification",hazard)
            }
        }


    },


    actions: {

        async createBatteryMaterial(context) {

            try {
                let request = context.getters.request;
                await api.masterdata.batteryMaterials.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetBatteryMaterial(context) {
            context.commit('setBatteryMaterial', createEmptyBaterryMaterial());
        },

        async updateBatteryMaterial(context, batteryMaterial) {

            try {
                context.commit('setBatteryMaterial', batteryMaterial);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateCasIdentifier(context, casIdentifier) {
            context.commit('setCasIdentifier', casIdentifier);
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async loadHazardousSubstanceClassifications(context){
            try {
                let hazards = await api.masterdata.hazardousSubstanceClassifications.findByFilter('*', 0, 100);
                context.commit('setHazardousSubstanceClassifications',hazards)
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async updateHazard(context, hazard){
            context.commit('setHazardousSubstanceClassification', hazard);
        },

    },



    getters: {

        request(state) {
            let payload = state.batteryMaterial;

            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        batteryMaterial(state) {
            return state.batteryMaterial ? state.batteryMaterial : createEmptyBaterryMaterial();
        },

        casIdentifier(state) {
            return state.batteryMaterial.data.casIdentifier;
        },
        hazard(state){
            return get(state,"batteryMaterial.references.hazardoussubstanceclassification",null)
        },
        hazardous(state){
            return state.hazards.items;
        }

    }
}

function createEmptyBaterryMaterial() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "casIdentifier": null
        },
        "active": true,
        "references": {
            hazardoussubstanceclassification:null
        }

    }
}