<template>
  <c-editable-item-list 
        :title="$t('embedded-collections-editor.component.title')" 
        :items="items"
        :new-item="{ name: null, schemaId: null, i18n: {} }"
        header-style="lite"
        :readonly="readonly"
        :disabled="disabled"
        editDialogStyle="fullscreen"
        @input="onInput"
        :flat="true"
    >

        <!-- Custom Header -->
        <template v-slot:item-list-head>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>{{$t('embedded-collections-editor.component.columns.collectionId')}}</th>
                    <th>{{$t('embedded-collections-editor.component.translatedName')}}</th>
                    <th>{{$t('embedded-collections-editor.component.schemaId')}}</th>
                </tr>
            </thead>
        </template>

        <!-- Custom Body -->
        <template v-slot:item-list-body-row="props"> 
            <td :title="props.item.name">{{props.item.name}}</td>
            <td>{{props.item | localized-name($i18n.locale)}}</td>
            <td>{{schemaMap[props.item.schemaId] | localized-name($i18n.locale)}}</td>
        </template>

        <!-- Custom Editor -->
        <template v-slot:item-list-editor="props">
            <v-card tile flat>
                <v-card-text>
                    <c-name-input
                         v-model="props.item.name"
                         filled
                         :disabled="!props.isNewItem"
                     />
                </v-card-text>
                <v-card-text>
                     <c-item-selector 
                        v-model="props.item.schemaId"
                        :items="schemas"
                        :label="$t('embedded-collections-editor.component.item.schemaId.label', $i18n.locale)"
                        :disabled="!props.isNewItem"
                        filled
                        :rules="[v => !!v || $t('embedded-collections-editor.component.item.schemaId.validation.error', $i18n.locale)]"
                    />
                </v-card-text>
                <v-divider />
                
                <c-i18n-editor
                    v-model="props.item.i18n"
                    header-style="lite"
                    flat
                    return-object
                />

            </v-card>
       </template>
  </c-editable-item-list>
</template>

<script>
/**
 * This component is an editor for adding embedded collection definitions.
 */
export default {

    props: {
    
        items: {
            type: [Object, Array],
            required: false,
            default: () => []
        },

        readonly : {
            type: Boolean,
            required: false,
            default: false 
        },

        disabled : {
            type: Boolean,
            required: false,
            default: false 
        },

        schemas: {
            type: [Object, Array],
            required: true,
            default: () => []
        },
    },

    model: {
        prop: 'items',
        event: 'input'
    },

    methods: {

        onInput(event) {
            //Propage the event
            this.$emit('input', event);
        }
    },

    computed: {

        schemaMap() {

            const map = {};
            if (this.schemas) {
                for (const schema of this.schemas) {
                    map[schema.name] = schema;
                }
            }
            
            return map;

        }
    }

}
</script>

<i18n>
{
    "en" : {
        "embedded-collections-editor.component.title" : "Embedded Collections",

        "embedded-collections-editor.component.columns.collectionId" : "Collection ID",
        "embedded-collections-editor.component.translatedName" : "Name",
        "embedded-collections-editor.component.schemaId" : "Schema",

        "embedded-collections-editor.component.item.schemaId.label" : "Schema",
        "embedded-collections-editor.component.item.schemaId.validation.error" : "The schema must not be empty. Please select a schema in order to proceed"

    },

    "de" : {
        "embedded-collections-editor.component.title" : "Eingebettete Datenbereiche",

        "embedded-collections-editor.component.columns.collectionId" : "Technische ID",
        "embedded-collections-editor.component.translatedName" : "Name",
        "embedded-collections-editor.component.schemaId" : "Schema",

        "embedded-collections-editor.component.item.schemaId.label" : "Schema",
        "embedded-collections-editor.component.item.schemaId.validation.error" : "Das Schema darf nicht leer sein. Bitte wählen Sie ein Schema aus, um fortzufahren."
    }
}
</i18n>