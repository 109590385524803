import api from '../../../api/smart-factory.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        shiftEventType: createEmptyShiftEventType(),
        tenantId: null
    },

    mutations: {

        setShiftEventType(state, shiftEventType) {

            if (shiftEventType) {

                state.shiftEventType.tenantId = shiftEventType.tenantId ? shiftEventType.tenantId : null;
                state.shiftEventType.name = shiftEventType.name ? shiftEventType.name : null;
                state.shiftEventType.i18n = shiftEventType.i18n ? shiftEventType.i18n : {};
                state.shiftEventType.data = shiftEventType.data ? shiftEventType.data : { custData: {} };
                state.shiftEventType.active = shiftEventType.active ? shiftEventType.active : true;
                state.shiftEventType.references = shiftEventType.references ? shiftEventType.references : {};

            } else {
                state.shiftEventType.tenantId = null;
                state.shiftEventType.name = null;
                state.shiftEventType.i18n = null;
                state.shiftEventType.data = null;
                state.shiftEventType.active = null;
                state.shiftEventType.references = null;
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        }


    },

    actions: {

        async createShiftEventType(context) {

            try {

                let request = context.getters.request;
                await api.masterdata.shiftEventTypes.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetShiftEventType(context) {
            context.commit('setShiftEventType', createEmptyShiftEventType());
        },

        async updateShiftEventType(context, shiftEventType) {

            try {
                context.commit('setShiftEventType', shiftEventType);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.shiftEventType;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        shiftEventType(state) {
            console.log(JSON.stringify(state))
            return state.shiftEventType ? state.shiftEventType : createEmptyShiftEventType();
        }

}
}

function createEmptyShiftEventType() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { custData: {} },
        "active": true,
        "references": {}

    }
}