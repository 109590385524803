import { render, staticRenderFns } from "./access-control-list-permission-table.component.vue?vue&type=template&id=f5493b9c"
import script from "./access-control-list-permission-table.component.vue?vue&type=script&lang=js"
export * from "./access-control-list-permission-table.component.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./access-control-list-permission-table.component.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports