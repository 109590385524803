<template>
    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <c-base-dialog :value="batteryMaterial" :title="$t('battery-material-wizard.view.title')"
                    :subtitle="$t('battery-material-wizard.view.subtitle')" color="secondary" @dialog-saved="save"
                    @dialog-canceled="cancel">
                    <template v-slot:default="props">
                        <v-card flat>
                            <v-card-text>
                                <c-name-input v-model="props.item.name" filled />
                                
                                <v-row> 
                                    <v-col cols="12">
                                        <c-item-selector :items="hazards" v-model="props.item.references.hazardoussubstanceclassification" :return-object="false" filled
                                            :chips="false"
                                            :label="$t('batteryMaterial-wizard.view.subheader.hazard')"></c-item-selector>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>

                </c-base-dialog>
            </v-col>

        </v-row>
    </v-container>
</template>
  
<script>
import BatteryMaterialWizardModule from "../../../store/masterdata/battery-materials/battery-material-wizard.module";

const MODULE_NAME = "batteryMaterialWizard";

export default {
    name: "battery-material-wizard",

    inject: [
        "errorHandlerService",
        "progressIndicatorService",
        "envContextService",
    ],


    methods: {
        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/createBatteryMaterial");

                this.$router.push({
                    name: "BatteryMaterialEditorView",
                    params: { name: this.batteryMaterial.name },
                });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push({
                name: "BatteryMaterialListView",
            });
        },

        setTenantId(tenantId) {
            this.$store.dispatch(MODULE_NAME + "/updateTenantId", tenantId);
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetBatteryMaterial");
            this.setTenantId(this.tenantId);
        },
    },

    computed: {
        batteryMaterial: {
            get() {
                return this.$store.getters[MODULE_NAME + "/batteryMaterial"] || {};
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_NAME + "/updateBatteryMaterial", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },

        casIdentifier: {
            get() {
                return this.$store.getters[MODULE_NAME + "/casIdentifier"];
            },

            set(item) {

                this.$store
                    .dispatch(MODULE_NAME + "/updateCasIdentifier", item)
                    .catch(this.errorHandlerService.handleError);

            },
        },


        tenantId() {
            return this.envContextService.tenantId;
        },
        hazard: {
            get() {
                return this.$store.getters[MODULE_NAME + '/hazard'];
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateHazard", value);
            }
        },

        hazards: {
            get() {
                return this.$store.getters[MODULE_NAME + '/hazardous'];
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateHazard", value);
            }
        },

    },

    async created() {
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, BatteryMaterialWizardModule);
        }
        await this.$store.dispatch(MODULE_NAME + "/loadHazardousSubstanceClassifications");
       
    },
};
</script>
  
<i18n>
  {
      "en" : {
          "battery-material-wizard.view.title" : "Create New Battery Material",
          "battery-material-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new Battery Material.",
          "battery-material-wizard.view.casnumber.text" : "CAS-Number",
          "batteryMaterial-wizard.view.subheader.hazard" : "Hazardous Substance Classification"
      },
  
      "de" : {
          "battery-material-wizard.view.title" : "Neues Batterie-Material anlegen",
          "battery-material-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Batterie-Materials.",
          "battery-material-wizard.view.casnumber.text" : "CAS-Nummer",
          "batteryMaterial-wizard.view.subheader.hazard" : "Gefahrenstoffklasse"
      }
  }
  </i18n>
  
  
  