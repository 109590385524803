<template>
  <v-sheet color="secondary">
    <c-section
      :title="$t('users-list.view.sections.list.title')"
      :subtitle="$t('users-list.view.sections.list.subtitle')"
      class="secondary"
    >
      <v-card class="mt-5 mb-5" tile>
        <c-item-list
          :title="$t('users-list.view.toolbar.title')"
          :items="items"
          :pagination="pagination"
          :disable-add-item="!hasCreateRole"
          :disable-edit-item="!hasEditRole && !hasReadRole"
          :disable-delete-item="!hasDeleteRole"
          @refresh-list="refresh"
          @previous-page="onPreviousPage"
          @next-page="onNextPage"
          @item-added="onItemAdded"
          @item-edited="onItemEdited"
          @item-list-filter-applied="onFilterChanged"

          :supports-delete-item="false"
          :supports-add-item="false"
        >
            <template v-slot:item-list-table-head-columns>
                <th>{{$t('users-list.view.columns.fullName')}}</th>
                <th>{{$t('users-list.view.columns.eMail')}}</th>
                <th class="text-center">{{$t('users-list.view.columns.authorization')}}</th>
            </template>

            <template v-slot:item-list-table-body-columns="props">
                <td>{{getFullName(props.item)}}</td>
                <td>{{props.item && props.item.eMail ? props.item.eMail : '-'}}</td>
                <td class="text-center"><v-btn icon @click="goToUserDashboard(props.item.name)"><v-icon small>badge</v-icon></v-btn></td>
            </template>
        </c-item-list>
        <v-card-text></v-card-text>
        <v-divider />
        <v-subheader class="grey lighten-5">{{
          $t("users-list.view.help.title")
        }}</v-subheader>
        <v-card-text class="grey lighten-5">{{
          $t("users-list.view.help.description")
        }}</v-card-text>
      </v-card>
    </c-section>
  </v-sheet>
</template>

<script>
import Roles from "@/roles";

const MODULE_NAME = "usersList";

export default {
  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "userContextService",
  ],

  computed: {
    response() {
      return this.$store.getters[MODULE_NAME + "/response"];
    },

    items() {
      return this.response.items;
    },

    pagination() {
        return this.response.pagination;
    },

    hasReadRole() {
      return this.userContextService.hasAllRoles(
        Roles.USERS_READ
      );
    },

    hasEditRole() {
      return this.userContextService.hasAllRoles(
        Roles.USERS_EDIT
      );
    },

    hasCreateRole() {
      return this.userContextService.hasAllRoles(
        Roles.USERS_CREATE
      );
    },

    hasDeleteRole() {
      return this.userContextService.hasAllRoles(
        Roles.USERS_DELETE
      );
    },

    
  },


  methods: {

    getFullName(item) {
       

        if (item.lastName && item.firstName) {
            return item.lastName + ', ' + item.firstName;
        } else if (item.lastName) {
            return item.lastName;
        } else if (item.firstName) {
            return item.firstName;
        } else {
            return '-';
        }

        
    },

    async refresh() {
        this.loadPage(0, 50);
    },

    async onNextPage({from, pageSize}) {
        this.loadPage(from, pageSize);
    },

    async onPreviousPage({from, pageSize}) {
        this.loadPage(from, pageSize);
    },

    async loadPage(from, pageSize) {
        this.progressIndicatorService.show();
        try {
            await this.$store.dispatch(MODULE_NAME + "/loadResponse", {from, pageSize});
        } catch (error) {
            this.errorHandlerService.handleError(error);
        } finally {
            this.progressIndicatorService.hide();
        }
    },

    onItemEdited(event) {
      if (event) {
        this.$router.push({
          name: 'usersDetail',
          params: {
            name: event.item.name,
          },
        });
      } else {
        this.$log.debug(
          "Event object is undefined. Can't navigate to schema editor."
        );
      }
    },

    async onFilterChanged(event) {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter);
        await this.refresh();
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    goToUserDashboard(userId) {
        this.$router.push({
            name: 'UserAuthorizationDashboardView',
            params: {name: userId}
        });
    }

  },

  created() {
    this.refresh();
  },
};
</script>

<i18n>
{
    "en" : {

        "users-list.view.sections.list.title" : "Users",
        "users-list.view.sections.list.subtitle" : "Overview for all users",
        "users-list.view.toolbar.title" : "Users",

        "users-list.view.columns.fullName" : "User Name",
        "users-list.view.columns.eMail" : "eMail",
        "users-list.view.columns.authorization" : "Authorization Status",


        "users-list.view.help.title" : "What are Users?",
        "users-list.view.help.description" : "User accounts contain a user's privileges for system access."

    },

    "de" : {

        "users-list.view.sections.list.title" : "Benutzer",
        "users-list.view.sections.list.subtitle" : "Übersicht der verfügbaren Benutzer",
        "users-list.view.toolbar.title" : "Benutzer",

        "users-list.view.columns.fullName" : "Benutzername",
        "users-list.view.columns.eMail" : "E-Mail",
         "users-list.view.columns.authorization" : "Berechtigungsstatus",

        "users-list.view.help.title" : "Was sind Benutzer?",
        "users-list.view.help.description" : "Benutzerkonten enthalten die Berechtigungen eines Benutzers für den Systemzugriff."
    }
}
</i18n>