<template>
    <v-card tile flat>
        <c-query-configuration-panel 
            v-model="selectedListTemplate" 
            @list-settings-changed="refresh"
            @list-settings-reset="onResetTemplate"
            :title="$t('collection-distribution.view.title')"
            :collection-settings="collectionSettings"
            :schema="schema"
            :templates="listTemplates"
            :disabled="false"
            hide-sort-settings
            hide-view-settings
            >

            
            <!-- Extend Toolbar -->
            <template v-slot:toolbar-items-prepend="{disabled}">
                
                <v-btn icon @click="refresh" :disabled="disabled">
                    <v-icon>refresh</v-icon>
                </v-btn>
            
            </template>

            <template v-slot:content>
                


                <!-- ************************************************************* -->
                <!-- VALUE DISTRIBUTION                                       -->
                <!-- ************************************************************* -->
                <c-section class="grey lighten-3" :title="$t('collection-distribution-dimension.view.label', {collectionId: $dxs.i18n.localizedName(collectionSettings, $i18n.locale), 
                                                                    attributeId:  $dxs.i18n.localizedName(dimensionAttribute, $i18n.locale)})"
                                                                    :subtitle="$t('collection-distribution-dimension.view.sections.drilldown.subtitle', {count: categoryItemsSum})" >
                    <dxs-data-distribution-bar :items="categoryItems" background-color="#eeeeee" relative-values />
                    
                    
                    <v-container class="pt-10">
                        <v-row justify="center">
                            <v-col cols="12" md="6" lg="4" v-for="(item, index) of nonZeroCategoryItems" :key="'distribution_item_' + index">
                                <v-card>
                                <v-card-text >
                                    <div class="text-body">Top {{index + 1}}</div>
                                    <div class="text-h6">{{$dxs.i18n.localizedName(dimensionAttribute, $i18n.locale)}} {{$dxs.i18n.localizedName(item, $i18n.locale)}}</div>
                                </v-card-text>
                                <c-metrics-distribution-chart :items="[item, {name: '...', value: categoryItemsSum - item.value, i18n: {}, color: '#eeeeee'}]" type="donut" />
                                <v-card-text class="text-center text-h3 pb-5" :style="'color:' + item.color">
                                    {{$dxs.formatting.formatInteger(item.value, $i18n.locale)}}
                                </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    -
                    
                </c-section>


                <!-- ************************************************************* -->
                <!-- TIME-BASED DISTRIBUTION                                       -->
                <!-- ************************************************************* -->
                <template v-if="!timeDimensionsEmpty">
  
                    <c-section class="grey lighten-2" :title="$t('collection-distribution-dimension.view.sections.histogram.title', {collectionId: $dxs.i18n.localizedName(collectionSettings, $i18n.locale), 
                                                                    attributeId:  $dxs.i18n.localizedName(dimensionAttribute, $i18n.locale)})"
                                                                    :subtitle="$t('collection-distribution-dimension.view.sections.drilldown.subtitle', {count: categoryItemsSum})" >
               

                        <div class="text-center">
                            <v-btn-toggle  tile color="grey" >
                                <v-btn v-for="(item, index) of timeDimensions" 
                                    :key="'timeDimensionBtn_' + index"
                                    :value="item.name"
                                    @click="clickedTimeDimension(item.name)">
                                    {{$dxs.i18n.localizedName(item, $i18n.locale)}}
                                   
                                </v-btn>
                            </v-btn-toggle>
                        </div>

                        <v-container fluid class="grey lighten-2">
                            <v-row justify="center">
                                <v-col>
                                    <v-lazy>
                                        <dxs-data-distribution-histogram-card :title="$t('collection-distribution-dimension.view.sections.histogram.card.title')" 
                                        :items="histogramItems" :series="histogramDimensions" :time-scales="timeScalesOrDefault" v-model="selectedTimeInterval" @click="refreshHistogram">
                                            <template v-slot:card-header v-if="histogramIntervalCount >= maxHistogramIntervals">
                                                <v-banner single-line 
                                                color="accent" 
                                                icon="info" 
                                                icon-color="white" 
                                                class="white--text">
                                                    {{$t("data-distribution-histogram-card.component.histogram.info", {maxIntervals: maxHistogramIntervals})}}
                                                </v-banner>
                                            </template> 
                                        </dxs-data-distribution-histogram-card>
                                    </v-lazy>
                                </v-col> 
                            </v-row>
                        </v-container>
                </c-section>
                </template>
                <template v-else>
                        <v-sheet>
                            <v-card tile flat class="grey lighten-2">
                                <v-card-text>
                                    <p />
                                    <div class="text-center"><v-icon color="accent" large>warning</v-icon></div>
                                    <div class="title text-center">{{$t('collection-distribution-dimension.view.missingDatetimeAttribute.title')}}</div>
                                    <div class="text-center">{{$t('collection-distribution-dimension.view.missingDatetimeAttribute.subtitle')}}</div>
                                </v-card-text>
                            </v-card>
                        </v-sheet>
                    </template>


            </template>

    
        </c-query-configuration-panel>


    </v-card>
</template>

<script>
import { newInstance as listTemplateDataMixin }  from '@/core/mixins/list-template-data/list-template-data.mixin';
import { newInstance as collectionSettingsDataMixin }   from '@/core/mixins/collection-settings-data/collection-settings-data.mixin';
import chroma from "chroma-js";
import { first, get, isEmpty } from 'lodash';

import CollectionHistogramDistributionModule from '@/core/store/modules/collections/collection-histogram-distribution.module';

import CollectionCategoriesDistributionModule from '@/core/store/modules/collections/collection-categories-distribution.module';

import DataDistributionBarComponent from '@/core/components/data-exploration/distribution/data-distribution-bar.component';
import DataDistributionHistogramCardComponent from '@/core/components/data-exploration/distribution/data-distribution-histogram-card.component';

const HISTOGRAM_MODULE_ID = 'collection-distribution-histogram';
const DIMENSION_MODULE_ID = 'collection-distribution-dimensions';

const DEFAULT_TIME_SCALE = [
    { name: '10s', value: 10, unit: 's' },
    { name: '15s', value: 15, unit: 's' },
    { name: '30s', value: 30, unit: 's' },
    { name: '1m',  value: 1, unit: 'm' },
    { name: '15m', value: 15, unit: 'm' },
    { name: '30m', value: 30, unit: 'm' },
    { name: '1h', value: 1, unit: 'h' },
    { name: '2h', value: 2, unit: 'h' },
    { name: '3h', value: 3, unit: 'h' },
    { name: '4h', value: 4, unit: 'h' },
    { name: '6h', value: 6, unit: 'h' },
    { name: '12h', value: 12, unit: 'h' },
    { name: '14h', value: 24, unit: 'h' },
    
]

/**
 *  A distribution dashboard which focuses on a single dimension of a given collection.
 */
export default {

    name: 'collection-distribution-dimension',

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    components : {
        'dxs-data-distribution-bar' : DataDistributionBarComponent,
        // 'dxs-data-distribution-metric-card' : DataDistributionMetricCardComponent,
        'dxs-data-distribution-histogram-card' : DataDistributionHistogramCardComponent
    },


    data() {
        return {

            selectedTimeDimension: "",
            selectedTimeInterval: null,
            selectedDimensionId: [get(this.value, 'dimensionId', null)],

            maxHistogramIntervals: 100
            
        };
    },

    mixins: [
        collectionSettingsDataMixin({moduleId: 'collection-distribution-collectionSettings'}),
        listTemplateDataMixin({moduleId: 'collection-distribution-listTemplates'})
        
    ],

    inject: ['progressIndicatorService', 'errorHandlerService', 'envContextService', 'userContextService'],

    computed : {

        dimensionId() {
            return get(this.value, 'dimensionId');
        },

        dimensionAttribute() {
            return get(this.schemaAttributes, this.dimensionId, {})
        },

        colorScale() {
            return chroma.bezier(['#005155', '#A5D3E3',]).scale().correctLightness();
        },

        collectionId() {
            return get(this.value, 'collectionId', null);
        },

        categoryModel() {
            return this.$store.getters[DIMENSION_MODULE_ID + '/model']
        },

        categoryItems() {

            // The view focuses on a single dimension: Therefore we will only deal with a single item.
            const category = first( get(this.categoryModel, 'categoryValueDistributionItems', []) );

            if ( isEmpty(get(category, 'items'))) return [];

            // The palette is computed dynamically            
            const palette = this.colorScale.colors(this.maxCategories);
            const items = [];
            for (let i = 0; i < this.maxCategories; i++) {
                
                let e = get(category, 'items[' + i + ']', {name: '-', i18n: {}, value: 0.0, color: 'grey'} );
                if (!e.color) {
                    e.color = palette[i % palette.length];
                }
                items.push(e);
            }

            if (category.remainingItemsSummary) {
                
                const other = category.remainingItemsSummary;
                items.push({
                    name: other.name,
                    i18n: other.i18n,
                    value: other.value,
                    color: '#FCD299'
                });

            }

            
            return items;
        },

        nonZeroCategoryItems() {
            return this.categoryItems.filter(e => e.value > 0);
        },

        categoryItemsSum() {
            let sum = 0;
            for (const item of this.categoryItems) {
                sum = sum + item.value;
            }
            return sum;
        },

        maxCategories() {
            return 5;
        },

        timeDimensionsEmpty() {
            return isEmpty(this.timeDimensions);
        },

        timeDimensions() {
            
            let timeDimensions = [];
            for (const attribute of this.schemaAttributeList) {

                if (this.$dxs.attributes.isDimension(attribute) && this.$dxs.attributes.isTypeDateTime(attribute)) {
                    timeDimensions.push(attribute);
                }
                
            }  
            
            return timeDimensions;
        },

        histogramModel() {
            return this.$store.getters[HISTOGRAM_MODULE_ID + '/model']
        }, 

        histogramItems() {
            const items = get(this.histogramModel, "dateHistogramDistribution.items", []);
            return items;
        },

        histogramIntervalCount() {
            return this.histogramItems.length;
        },

        histogramDimensions() {
            const series = get(this.histogramModel, 'dateHistogramDistribution.series', []);
            
            // The palette is computed dynamically            
            let palette = this.colorScale.colors(series.length);
            for (let i = 0; i < series.length; i++) {
                let serie = series[i];
                 
                if (!serie.color) {
                    serie.color =  palette[i % palette.length];
                }
            }

            return series;
        },

        /**
         * Time Scales are configured as part of the client settings in the collection settings. 
         */
        timeScales() {

            return get(this.collectionSettings, 'clientSettings.distribution.timeIntervals', [])
        },

        timeScalesOrDefault() {

            return isEmpty(this.timeScales) ? DEFAULT_TIME_SCALE : this.timeScales;
        },

        
    },

    methods : {

        async onResetTemplate() {

            
            const emptyTemplate = this.createEmptyListTemplate(this.schema);
            
            this.selectedListTemplate = emptyTemplate;

        }, 

        async loadDateHistogramDistribution()  {

            await this.$store.dispatch(HISTOGRAM_MODULE_ID + '/loadDateHistogramDistribution', {
                collectionId: this.collectionId,
                filter: this.selectedListTemplate.filterSettings,
                histogramSize: this.maxHistogramIntervals,
                dateTimeDimensionId: this.selectedTimeDimension,
                timeInterval: this.selectedTimeInterval,
                dimensions: this.selectedDimensionId
            });

        },

        /**
         * Loads the category value distribution for the currently selected dimension.
         */
        async loadCategoryValueDistribution() {

            await this.$store.dispatch(DIMENSION_MODULE_ID + '/loadCategoryValueDistributions', {
                collectionId: this.collectionId,
                categoryIdList: [this.dimensionId],
                filter: this.selectedListTemplate.filterSettings,
                size: this.maxCategories
            });
        }, 

        async refreshHistogram(){
            if (this.collectionId) {
                this.progressIndicatorService.show();
                this.$store.dispatch(HISTOGRAM_MODULE_ID + "/clearHistogramData", {})
                try {
                    // Load analytical histogram data.
                    await this.loadDateHistogramDistribution();

                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                }
            }
        },

        /**
         * Initializes the view, e.g. by loading masterdata.
         */
        async initView( {collectionId, listTemplate} ) {
            this.progressIndicatorService.show();

            try {

                //await this.$store.dispatch(MODULE_NAME + '/resetView');
                
                //===================================================================================
                // Load Metadata
                //===================================================================================
                await this.loadCollectionSettings(collectionId);
                await this.loadListTemplates(this.collectionId);
                
                const timeDimension = get(this.timeDimensions, '[0]', null);
                if(timeDimension){
                    this.selectedTimeDimension = timeDimension.name;    
                }

                const timeInterval = get(this.timeScalesOrDefault, '[0]', null);
                if(timeInterval){
                    this.selectedTimeInterval = timeInterval;    
                }
                //===================================================================================
                // Init List Template Configuration based on Metadata
                //===================================================================================

                if (isEmpty(listTemplate)) {
                    // Use a default template in case the parameter has not been set.
                    const emptyTemplate = this.createEmptyListTemplate(this.schema);
                    this.selectedListTemplate  = emptyTemplate;
                } else {
                    this.selectedListTemplate = listTemplate;
                }

                await this.refresh();

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async refresh() {
            if (this.collectionId) {


                this.progressIndicatorService.show();
        
                try {

                    this.$store.dispatch(HISTOGRAM_MODULE_ID + "/clearHistogramData", {})
                    
                    // Load analytical data.
                    await this.loadDateHistogramDistribution();

                    // Load value distribution
                    await this.loadCategoryValueDistribution();



                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                }
            }
        },

        clickedTimeDimension(item){
            this.selectedTimeDimension = item;
            this.refreshHistogram();
        }
    },

    async created() {
        
        // Dynamically register the vuex store module for managing the data.
        if (!this.$store.hasModule(DIMENSION_MODULE_ID)){
            this.$store.registerModule(DIMENSION_MODULE_ID, CollectionCategoriesDistributionModule);
        }

        // if (!this.$store.hasModule(MEASURES_MODULE_ID)){
        //     this.$store.registerModule(MEASURES_MODULE_ID, CollectionMeasuresDistributionModule);
        // }
        if (!this.$store.hasModule(HISTOGRAM_MODULE_ID)){
            this.$store.registerModule(HISTOGRAM_MODULE_ID, CollectionHistogramDistributionModule);
        }

        this.$store.dispatch(HISTOGRAM_MODULE_ID + "/clearHistogramData", {})
       

        await this.initView(this.value);
    }
}
</script>

<i18n>
{
    "en" : {
        "collection-distribution-dimension.view.label" :  "Drilldown: {collectionId} > {attributeId}",

        "collection-distribution-dimension.view.missingDatetimeAttribute.title" : "Time Based Distribution is not Available",
        "collection-distribution-dimension.view.missingDatetimeAttribute.subtitle" : "This collection does not contain datetime-related attributes. Therefore it is not possible to create a time based distribution.",

        "data-distribution-histogram-card.component.histogram.info" : "The first {maxIntervals} time intervals are shown. Perhaps more time intervals are available. Please change the filter settings to view them.",

        "collection-distribution-dimension.view.sections.histogram.title" : "Time Based Distribution: {collectionId} > {attributeId}",
        "collection-distribution-dimension.view.sections.histogram.card.title" : "Distribution",

        "collection-distribution-dimension.view.sections.drilldown.subtitle" : "Based on {count} Items"
    },

    "de" : {
        "collection-distribution-dimension.view.label" :  "Aufriss: {collectionId} > {attributeId}",

        "collection-distribution-dimension.view.missingDatetimeAttribute.title" : "Verteilung nach Zeitraum nicht verfügbar",
        "collection-distribution-dimension.view.missingDatetimeAttribute.subtitle" : "In diesem Datenbereich gibt es keine Datumsfelder mit Uhrzeit. Es ist deshalb leider nicht möglich, eine Verteilung nach Zeitraum zu erstellen.",

        "data-distribution-histogram-card.component.histogram.info" : "Es werden die {maxIntervals} ersten Zeitintervalle angezeigt. Möglicherweise sind mehr Zeitintervalle vorhanden, ändern Sie hierzu bitte die Filtereinstellungen.",

        "collection-distribution-dimension.view.sections.histogram.title" : "Verteilung nach Zeitraum: {collectionId} > {attributeId}",
        "collection-distribution-dimension.view.sections.histogram.card.title" : "Häufigkeit",
        
        "collection-distribution-dimension.view.sections.drilldown.subtitle" : "Basierend auf {count} Ergebnissen"

    }
}
</i18n>