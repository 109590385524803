import api from '@/store/api';

export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(),

        filter: getDefaultFilter()
    },

    mutations: {

        setResponse(state, response) {

            if (response) {
                state.response = response;
            }
            
        },

        setFilter(state, filter) {
            
            if (filter) {
                state.filter = filter;
            } else {
                state.filter = {
                    filterExpression : '*'
                }
            }
        }
    },

    actions: {

        async loadResponse(context) {

            try {
                let response = await api.prediction.findAllPredictionScenarios();
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }
            
        }, 

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        }
    },

    getters: {
        
        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        }
    }
}

function getDefaultResponse() {

    return {
        items: []
    };
}

function getDefaultFilter() {
    return { filterExpression : '*' };
}