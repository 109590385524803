<template>
    <v-sheet class="secondary darken-1" >

        <c-sticky-content>
            <v-toolbar color="secondary darken-1" flat >
                <v-toolbar-items><v-btn icon @click="close"><v-icon>close</v-icon></v-btn></v-toolbar-items>
                <v-breadcrumbs>
                    <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false">{{$t('collection-view.breadcrumbs.root')}}</v-breadcrumbs-item>
                    <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                    <!-- <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false">{{collectionSettings | localizedName($i18n.locale)}}</v-breadcrumbs-item> -->
                    <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                    <!-- <v-breadcrumbs-item>{{document | localizedName($i18n.locale)}}</v-breadcrumbs-item>    -->
                                 
                </v-breadcrumbs>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon><v-icon @click="refresh">refresh</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
        </c-sticky-content>
        <c-section v-if="items.length > 0"
        :title="$t('document.changeLog.view.title')" :subtitle="$t('document.changeLog.view.subtitle')" class="secondary">
            
        <v-tabs v-model="selectedTab" background-color="secondary" fixed-tabs>
            <v-tab :key="0">{{$t('document.log.view.tabs.timeline.title')}}</v-tab>
            <v-tab :key="1">{{$t('document.log.view.tabs.table.title')}}</v-tab>
            <v-tabs-items v-model="selectedTab">
                <v-card flat>
                    <v-row justify="center" class="ma-1">
                        <v-col :cols="12" :sm="5">
                            <c-date-time-selector :value="fromInput" @selection-changed="fromInput = $event.date" :label="$t('time-range-filter.component.labels.from', $i18n.locale)" />
                        </v-col>
                        <v-col :cols="12" :sm="5">
                            <c-date-time-selector :value="untilInput" @selection-changed="untilInput = $event.date" :label="$t('time-range-filter.component.labels.until', $i18n.locale)"/>
                        </v-col>
                        <v-col :cols="12" :sm="2" class="text-center mt-3">
                            <v-btn @click="onFilterApply" color="accent" >{{$t('time-range-filter.component.actions.apply', $i18n.locale)}}</v-btn>
                        </v-col>
                    </v-row>    
                </v-card>
                <v-divider/>
                <v-tab-item :key="0">
                <v-timeline
                    :reverse="true"
                    align-center
                    :dense="$vuetify.breakpoint.smAndDown"
                    class="grey lighten-3"
                >
                    <v-timeline-item
                    v-for="(item, i) in items"
                    :key="i"
                    icon="update"
                    fill-dot
                    color="accent"
                    class="mx-15"
                    >
                    <template v-slot:opposite>
                            <p>{{$t(item.data.modificationType)}} 
                            <span class="font-weight-bold">{{item.data.modificationDateTime | localized-date-time-format($i18n.locale)}}</span>
                            {{$t("document.log.by.label")}} <span class="font-weight-bold">{{item.data.modificationUserId}}</span></p>
                            <v-btn
                                color="black"
                                class="mr-5 mt-5"
                                outlined
                                @click="showConfirmationDialog(item)"
                            >
                                {{$t("document.log.restore.button.label")}}
                            </v-btn>
                            <v-btn
                                right
                                color="black"
                                class="mt-5"
                                outlined
                                @click="viewChangeLogItem(item)"
                            >
                                {{$t("document.log.view.button.label")}}
                            </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="text-h6">
                            
                        </v-card-title>
                        <v-card-text class="white text--primary">
                            <template 
                            v-if="$vuetify.breakpoint.smAndDown">
                                <p>{{$t(item.data.modificationType)}} 
                                <span class="font-weight-bold">{{item.data.modificationDateTime | localized-date-time-format($i18n.locale)}}</span>
                                {{$t("document.log.by.label")}} <span class="font-weight-bold">{{item.data.modificationUserId}}</span></p>                        
                            </template>
                            <c-document-attribute-table :value="table(item)" :options="{showDescription: false}" :display-type="displayType" />
                            <!-- <v-divider/> -->
                            
                        </v-card-text>
                        <v-card-actions v-if="$vuetify.breakpoint.smAndDown">
                            <v-btn
                                color="accent"
                                class="mr-5 mt-5"
                                text
                                @click="showConfirmationDialog()"
                            >
                                {{$t("document.log.restore.button.label.smAndDown")}}
                            </v-btn>
                        <v-btn
                            right
                            color="accent"
                            class="mt-5"
                            text
                            @click="viewChangeLogItem(item)"
                        >
                            {{$t("document.log.view.button.label.smAndDown")}}
                        </v-btn>  
                                                         
                        </v-card-actions>   
                    </v-card>
                    </v-timeline-item>
                </v-timeline> 
                </v-tab-item>
            <v-tab-item :key="1">
                <c-data-table 
                    :value="tableSettings"
                    @open-clicked="onOpenClicked"
                >
                    <template v-slot:data-table-header-actions>
                        <th class="text-center">{{$t("document.log.restore.button.label.smAndDown")}}</th>
                        <th class="text-left">{{$t("document.log.modificationDateTime.label")}}</th>
                    </template>
                    <template v-slot:data-table-body-actions="{item}">
                        <td class="text-center">
                            <v-btn icon @click="onTableRestoreClicked(item)"><v-icon small>history</v-icon></v-btn>
                        </td>
                        <td class="text-left">
                            {{item.modificationDateTime | localized-date-time-format($i18n.locale)}}
                        </td>
                    </template>
                </c-data-table>
            </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        </c-section>
        <v-card tile v-if="existingItems && items.length == 0">
            <v-row justify="center" class="ma-1">
                <v-col :cols="12" :sm="5">
                    <c-date-time-selector :value="fromInput" @selection-changed="fromInput = $event.date" :label="$t('time-range-filter.component.labels.from', $i18n.locale)" />
                </v-col>
                <v-col :cols="12" :sm="5">
                    <c-date-time-selector :value="untilInput" @selection-changed="untilInput = $event.date" :label="$t('time-range-filter.component.labels.until', $i18n.locale)"/>
                </v-col>
                <v-col :cols="12" :sm="2" class="text-center mt-3">
                    <v-btn @click="onFilterApply" color="accent" >{{$t('time-range-filter.component.actions.apply', $i18n.locale)}}</v-btn>
                </v-col>
            </v-row> 
            <v-card-text>
                <p />
                <div class="text-center"><v-icon color="accent" large>warning</v-icon></div>
                <div class="title text-center">{{$t('empty-result-tile.component.title')}}</div>
                <div class="text-center">{{$t('empty-result-tile.component.subtitle.timerange')}}</div>
            </v-card-text> 
        </v-card>
        <v-card tile v-else-if="items.length == 0">
            <v-card-text>
                <p />
                <div class="text-center"><v-icon color="accent" large>warning</v-icon></div>
                <div class="title text-center">{{$t('empty-result-tile.component.title')}}</div>
                <div class="text-center">{{$t('empty-result-tile.component.subtitle')}}</div>
            </v-card-text> 
        </v-card>
        <v-dialog v-model="displayConfirmationDialog" width="420" >
            <v-card tile>
                <v-toolbar flat>
                   <v-toolbar-title>{{$t('mapping-job-instance-admin.view.confirmationDialog.title')}}</v-toolbar-title>
                   <v-spacer />
                   <v-toolbar-items>
                       <v-btn icon @click="onCancel"><v-icon>close</v-icon></v-btn>
                    </v-toolbar-items> 
                </v-toolbar>
                <v-card-text class="pa-5">
                    {{$t('mapping-job-instance-admin.view.confirmationDialog.text')}}
                </v-card-text>
                <v-card-actions class="text-right pa-5">
                    <v-btn text class="accent" @click="onConfirm(item)">{{$t('mapping-job-instance-admin.view.confirmationDialog.actions.confirm')}}</v-btn>
                    <v-btn text @click="onCancel">{{$t('mapping-job-instance-admin.view.confirmationDialog.actions.cancel')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-sheet>
    
</template>

<script>
const MODULE_NAME = 'documentChangeLog';
import Roles from '@/roles';
import get from 'lodash/get';
import forOwn from 'lodash/forOwn';
import { DateTime } from 'luxon';
export default {

    name: 'document-detail-page',
    
    
    props: {
        
        value: {
            type: Object,
            required: false,
        },
    },

    inject: ['progressIndicatorService', 'envContextService', 'errorHandlerService', 'userContextService'],

    data: () => ({
        
        displayConfirmationDialog : false,
        selectedItem: null,
        selectedTab: 0,
        rangeFilter: {},
        from: DateTime.local().plus({ months: -1 }).toISO(),
        until: DateTime.local().toISO(),
        existingItems: false,
     }),

    

    computed: {

        documentId(){
            return this.value.documentId;
        },
        collectionId(){
            return this.value.collectionId;
        },
        response(){
             return this.$store.getters[MODULE_NAME + '/response'];
        },
      
        documents(){
            return this.$store.getters[MODULE_NAME + '/documents'];
        },
        items(){
            return get(this.documents, 'items', []);
        },
        originalItems(){
            let result = [];
            for(let item of this.items){
                result.push(this.getOriginalDocument(item));
            }
            return result;
        },
        schema(){
            return get(this.$store.getters[MODULE_NAME + '/schema'], 'data.schema', {});
        },
        displayType(){
            return this.$vuetify.breakpoint.xsOnly ? "list" : "table";
        },
       
        fromInput: {

            get() {
                return this.from;
            },

            set(value) {
                this.from = value;
            }
        },

        untilInput: {

            get() {
                return this.until;
            },

            set(value) {
                this.until = value;
            }
        },
        hasRestorePermission() {
            return this.userContextService.hasAllRoles(Roles.COLLECTIONS_CHANGELOG_RESTORE);
        },
        tableSettings() {
            
           let originalResponseItem = {
                items              : this.originalItems,
                meta               : this.schema,
                viewSettings       : {
                    attributes : []
                },
              
           }
           forOwn(this.schema.attributes, attribute => originalResponseItem.viewSettings.attributes.push({
                    value: attribute,
                    display: !!get(attribute, 'clientSettings.displayTable', true)
                }));
           return originalResponseItem;

        },

    },

    methods: {
        
        close() {
                if (window) {
                    window.close();
                }
                
            },  
            
        async refresh(){
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/loadDocuments', {collectionId: this.value.collectionId, documentId: this.value.documentId, rangeFilter: this.rangeFilter});
                await this.$store.dispatch(MODULE_NAME + '/loadSchema', {collectionId: this.value.collectionId});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        table(item){
            let tableForm = {
                document: {},
                collectionSettings: {},
                schema: {}
            };
            
            tableForm.document = this.getOriginalDocument(item);
            tableForm.schema = this.schema;
            return tableForm;
        },
        getOriginalDocument(item){
            let document = get(item, 'data.document', null);
            if(document){
                return JSON.parse(document);
            }else{
                return {};
            }
        },
        getChangeLogItem(item){
            let result = " ";
            for(let logItem of this.items){
                let originalDocument = this.getOriginalDocument(logItem);
                let originalId = get(originalDocument, 'modificationDateTime', "original modificationDateTime not found");
                let itemid = get(item, 'item.modificationDateTime', "item modificationDateTime not found");
                if(originalId === itemid){
                    result = logItem;
                    break;
                }
            }
            return result;
        },
        onOpenClicked(event) {
            let changeLogId = get(this.getChangeLogItem(event), 'id', "changeLogId not found");
            const changeLogItem = {
                id: changeLogId
            }
            this.viewChangeLogItem(changeLogItem);
        },
        onTableRestoreClicked(item){
            let formatItem = {item: item}
            let changeLogItem = this.getChangeLogItem(formatItem)
            this.showConfirmationDialog(changeLogItem);
        },
        viewChangeLogItem(item){
            this.$router.push({
                name: 'documentChangeLogItemView',
                params: {
                    collectionId: "DocumentChangeLog", documentId: this.value.documentId , changeLogDocumentId: item.id
                }
            });
        },
        showConfirmationDialog(item) {
            this.displayConfirmationDialog = true;
            this.selectedItem = item;
        },
        hideConfirmationDialog() {
            this.displayConfirmationDialog = false;
        },
        onCancel() {
           this.hideConfirmationDialog(); 
        },
        onConfirm() {
            this.restoreState(this.selectedItem);
            this.hideConfirmationDialog(); 
        },
       
        async restoreState(item) {
            this.progressIndicatorService.show();
            
            try {
                let originalDocument = this.getOriginalDocument(item)
                let document = [
                    {data: get(originalDocument, 'data', {}),
                    i18n: get(originalDocument, 'i18n', {}),
                    embedded:get(originalDocument, 'embedded', {})
                    }
                ]
                await this.$store.dispatch(MODULE_NAME + '/restoreDocument', {collectionId: this.value.collectionId, documents: document});
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
         onFilterApply() {
            const rangeFilter = {
                type: 'TEMPORAL',
                name: "modificationDateTime",
                from: this.from,
                until: this.until
            }
            this.rangeFilter = rangeFilter;
            this.refresh();
            if(this.items.length > 0){
                this.existingItems = true
            }else{
                this.existingItems = false
            }
        },

        },

    created() {
        this.refresh();
    },
}
</script>

<i18n>
{
    "en" : {

        "document.view.toolbar.title" : "Document",
        "document.changeLog.view.title" :  "Change Log",
        "document.changeLog.view.subtitle" :  "The timeline below contains information regarding the changes to the selected document.",
        "document.log.restore.button.label" : "Restore",
        "document.log.restore.button.label.smAndDown" : "Restore",
        "document.log.view.button.label" : "View Document",
        "document.log.view.button.label.smAndDown": "View",
        "document.log.by.label" : "by",
        "document.log.modificationDateTime.label": "Change Date",
        "document.log.preview.label": "State",
        "document.log.view.tabs.timeline.title": "Timeline",
        "document.log.view.tabs.table.title": "Table",
        "UPDATE" : "Updated on ",
        "DELETE" : "Deleted on ",

        "empty-result-tile.component.title" : "No Changes Found",
        "empty-result-tile.component.subtitle" : "We are very sorry, but we couldn't find any changes for this document.",
        "empty-result-tile.component.subtitle.timerange" : "We are very sorry, but we couldn't find any changes for this time range.",

        "mapping-job-instance-admin.view.confirmationDialog.title" : "Restore document?",
        "mapping-job-instance-admin.view.confirmationDialog.text" : "You are about to restore an older state of a document, which will overwrite the current state. This job can't be interrupted once started. Please confirm your action by clicking the button below.",
        "mapping-job-instance-admin.view.confirmationDialog.actions.confirm" : "Restore",
        "mapping-job-instance-admin.view.confirmationDialog.actions.cancel" : "Cancel",

        "time-range-filter.component.actions.apply" : "Apply",
        "time-range-filter.component.labels.from" : "From (Incl.)",
        "time-range-filter.component.labels.until" : "Until (Incl.)"
        
    },

    "de" : {
        "document.view.toolbar.title" : "Dokument",
        "document.changeLog.view.title" :  "Änderungsprotokoll",
        "document.changeLog.view.subtitle" :  "Die nachfolgende Zeitlinie enthält die Informationen zu Änderungen des ausgewählten Dokuments.",
        "document.log.restore.button.label" : "Wiederherstellen",
        "document.log.restore.button.label.smAndDown" : "Wiederherstellen",
        "document.log.view.button.label" : "Dokument anzeigen",
        "document.log.view.button.label.smAndDown" : "Anzeigen",
        "document.log.by.label" : "von",
        "document.log.modificationDateTime.label": "Geändert am",
        "document.log.preview.label": "Zustand",
        "document.log.view.tabs.timeline.title": "Zeitlinie",
        "document.log.view.tabs.table.title": "Tabelle",
        "UPDATE" : "Aktualisiert am ",

        "DELETE" : "Gelöscht am ",

        "empty-result-tile.component.title" : "Keine Änderungen",
        "empty-result-tile.component.subtitle" : "Es tut uns sehr leid, aber wir können zu diesem Dokument keine Änderungen finden.",
        "empty-result-tile.component.subtitle.timerange" : "Es tut uns sehr leid, aber wir können für diesen Zeitraum keine Änderungen finden.",


        "mapping-job-instance-admin.view.confirmationDialog.title" : "Dokument wiederherstellen?",
        "mapping-job-instance-admin.view.confirmationDialog.text" : "Sie sind im Begriff, einen älteren Zustand dieses Dokuments wiederherzustellen, der den aktuellen Stand überschreiben wird. Dieser Auftrag kann nicht unterbrochen werden, sobald er gestartet ist. Bitte bestätigen Sie Ihre Aktion, indem Sie auf die Schaltfläche unten klicken.",
        "mapping-job-instance-admin.view.confirmationDialog.actions.confirm" : "Wiederherstellen",
        "mapping-job-instance-admin.view.confirmationDialog.actions.cancel" : "Abbrechen",

        "time-range-filter.component.actions.apply" : "Anwenden",
        "time-range-filter.component.labels.from" : "Von (inkl.)",
        "time-range-filter.component.labels.until" : "Bis (inkl.)"
    }
}
</i18n>