<template>
    <v-sheet  color="secondary">
        <c-section :title="$t('tenant-list-templates-list.view.sections.list.title')" :subtitle="$t('tenant-list-templates-list.view.sections.list.subtitle')" class="secondary">

            <v-card class="mt-5 mb-5" tile>
                <c-item-list
                    :title="$t('tenant-list-templates-list.view.toolbar.title')" 
                    :items="items" 
                    :pagination="pagination"
                    :disable-edit-item="!hasEditRole && !hasReadRole" 
                    :disable-delete-item="!hasDeleteRole"
                    :supportsDeleteItem="true"
                    :supportsAddItem="false"
                    @refresh-list="refresh"
                    @previous-page="onPreviousPage"
                    @next-page="onNextPage"
                    @item-edited="onItemEdited" 
                    @item-deleted="onItemDeleted"
                    @item-list-filter-applied="onFilterChanged"
                >
                    <template v-slot:item-list-table-head-columns>
                        <th class="text-center" >{{$t('tenant-list-templates-list.view.columns.tenantId')}}</th>
                        <th class="text-left" >{{$t('tenant-list-templates-list.view.columns.collectionId')}}</th>
                        <th class="text-left" >{{$t('tenant-list-templates-list.view.columns.templateId')}}</th>
                        <th class="text-center" >{{$t('tenant-list-templates-list.view.columns.bookmark')}}</th>
                    </template>

                    <template v-slot:item-list-table-body-columns="props">
                        <td class="text-center">{{props.item ? props.item.tenantId : '-'}}</td>
                        <td class="text-left">{{props.item ? props.item.collectionId : '-'}}</td>
                        <td class="text-left">{{props.item ? props.item.templateId : '-'}}</td>
                        <td class="text-center"><v-icon small>{{props.item.bookmark ? 'bookmark' : '-'}}</v-icon></td>
                    </template>

                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('tenant-list-templates-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('tenant-list-templates-list.view.help.description')}}</v-card-text>
            </v-card>
        </c-section>
        
    </v-sheet>
</template>

<script>
const MODULE_ID = 'tenantListTemplatesList';
import Roles from '@/roles';
import { get } from 'lodash';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    computed: {

        response() {
            return this.$store.getters[MODULE_ID + '/response'];
        },

        items() {
            return this.response.items;
        },

        pagination() {
            return this.response.pagination;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.TENANT_LIST_TEMPLATES_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.TENANT_LIST_TEMPLATES_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.TENANT_LIST_TEMPLATES_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.TENANT_LIST_TEMPLATES_DELETE);
        }
    },

    methods: {

        async refresh() {
            this.loadPage(0, 50);
        },

        async onNextPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadResponse", {from, pageSize});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemEdited(event) {

            if (event) {
                this.$router.push({
                    name: 'tenantListTemplatesEditor',
                    params: {
                        name: get(event, 'item.name', {})
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to tenant list template editor.")
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onItemDeleted(event) {

            try {
               let context = {
                tenantId : event.item.tenantId,
                collectionId : event.item.collectionId,
                name : event.item.name
               }
               await this.$store.dispatch(MODULE_ID + "/deleteTemplate", context)
               await this.refresh()
            }  catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "tenant-list-templates-list.view.sections.list.title" : "Tenant List Template Management",
        "tenant-list-templates-list.view.sections.list.subtitle" : "You may use this dialog for deleting tenant list templates.",
        "tenant-list-templates-list.view.toolbar.title" : "Tenant List Templates",

        "tenant-list-templates-list.view.columns.collectionId" : "Collection",
        "tenant-list-templates-list.view.columns.tenantId" : "Tenant",
        "tenant-list-templates-list.view.columns.templateId": "Template ID",
        "tenant-list-templates-list.view.columns.bookmark" : "Bookmark",

        "tenant-list-templates-list.view.help.title" : "What are tenant List Templates?",
        "tenant-list-templates-list.view.help.description" : "These Tenant List Templates consist of saved filter-, view- & sort-settings of a tenant for specific collections. Tenant List Templates can be used to apply a set of settings in a collection."

    },

    "de" : {
        "tenant-list-templates-list.view.sections.list.title" : "Verwaltung Listenvorlagen (Mandant)",
        "tenant-list-templates-list.view.sections.list.subtitle" : "Sie können Listenvorlagen einsehen oder löschen.",
        "tenant-list-templates-list.view.toolbar.title" : "Listenvorlagen (Mandant)",

        "tenant-list-templates-list.view.columns.collectionId" : "Datenbereich",
        "tenant-list-templates-list.view.columns.tenantId" : "Mandant",
        "tenant-list-templates-list.view.columns.templateId": "Template ID",
        "tenant-list-templates-list.view.columns.bookmark" : "Favorit",

        "tenant-list-templates-list.view.help.title" : "Was sind Listenvorlagen (Mandant)?",
        "tenant-list-templates-list.view.help.description" : "Diese Listenvorlagen enthalten die abgespeicherten Einstellungen für einen gesamten Mandanten zur Filterung, Ansicht und Sortierung für bestimmte Datenbereiche. Listenvorlagen können verwendet werden, um gespeicherte Einstellungen in einem Datenbereich wiederverwenden zu können."
    }
}
</i18n>