import api from '@/store/api';

export default {

    namespaced: true,

    state: {

        response: {
            
            data: []
        },


    },

    mutations: {

        setResponse(state, response) {
            state.response.data =  [];

            if (response) {
                for (let item of response.items) {
                    state.response.data.push(item);
                }
            }
        },

    },

    actions: {

        async load(context) {

            try {

                // IMPORTANT: Use this endpoint in order to get a filtered list where collection 
                // permissions have been already applied.
                let response = await api.collections.findAllByFilter('*');
                context.commit('setResponse', response);
                
            } catch (error) {

                console.log("An error has occured" + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', []);
                return Promise.reject(error);
              }
        },
    },

    getters: {

        response(state) {
            return state.response ? state.response : { data: [] };
        },
    }
}