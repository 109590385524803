<template>
    <div class="grey lighten-3">

        <c-sticky-content>
            <v-toolbar color="white" flat>
                <v-toolbar-items>
                    <v-btn icon @click="cancel"><v-icon>close</v-icon></v-btn>
                </v-toolbar-items>
                <div class="text-h6">
                    {{ $t("catalog.common.collection.label") }}:
                    {{ $dxs.i18n.localizedName(this.collectionSettings, $i18n.locale) }}
                </div>
            </v-toolbar>
            <v-divider />
        </c-sticky-content>

        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <v-card tile>
                        <v-card-title>{{ $t('collection-settings-data-upload.view.upload.title') }}</v-card-title>
                        <v-divider />
                        <v-subheader>{{ $t('collection-settings-data-upload.view.upload.tecnicalPipeline') }}</v-subheader>
                        <v-stepper flat>
                            <v-stepper-header>
                                <v-stepper-step step="1">
                                    {{ documentRecord.topic }}
                                    <small>Topic</small>
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step step="2">
                                    {{ document.topic }}
                                    <small>Topic</small>
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step step="3">
                                    {{ deadLetter.topic }}
                                    <small>Topic</small>
                                </v-stepper-step>
                            </v-stepper-header>
                        </v-stepper>
                        <c-json-input color="accent" filled
                            :label="$t('collection-settings-data-upload.view.fields.payload.label')" v-model="jsonPayload"
                            ref="jsonInput">
                        </c-json-input>
                        {{ validation.message }}
                        <v-divider />
                        <v-card-actions>
                            <v-btn class="mr-3" block color="accent" @click="resetPayload">
                                <v-icon class="material-symbols-outlined">restart_alt</v-icon>
                                Reset
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions>
                            <v-btn :disabled="!jsonPayloadValid() || isJsonPayloadEmpty()" block color="accent"
                                @click="send"><v-icon class="mr-3">cloud_upload</v-icon>Upload</v-btn>
                        </v-card-actions>
                        <v-divider />
                        <v-subheader>{{ $t('collection-settings-data-upload.view.subheadder.results') }}</v-subheader>

                        <c-validation-result-panel v-model="response" flat />

                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-expansion-panels :popout="false">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <div>
                                    <v-icon color="accent" class="mr-2">help</v-icon>{{
                                        $t("collection-settings-data-upload.view.help.title") }}
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-card tile flat>
                                    <v-tabs fixed-tabs slider-color="accent" v-model="selectedTab" color="accent"
                                        background-color="grey lighten-3">
                                        <v-tab :key="1"><v-icon class="mr-3">view_list</v-icon> {{
                                            $t('collection-settings-data-upload.view.help.tabs.attributes') }}</v-tab>

                                        <v-tab :key="2"><v-icon class="mr-3">view_module</v-icon> {{
                                            $t('collection-settings-data-upload.view.help.tabs.jsonSchema') }}</v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="selectedTab">
                                        <v-tab-item :key="1">
                                            <c-schema-table :value="{ schema: schema }" />
                                        </v-tab-item>

                                        <v-tab-item :key="2">
                                            <c-json-schema-card tile flat :value="schema" />
                                        </v-tab-item>


                                    </v-tabs-items>
                                </v-card>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { newInstance as mixin } from '@/core/mixins/collection-settings-data/collection-settings-data.mixin';
import { get, isEmpty } from 'lodash';
import { DateTime } from 'luxon';

const MODULE_NAME = 'collectionSettingsDataUpload';

export default {
    mixins: [mixin({ moduleId: MODULE_NAME + 'mixin' })],


    props: {
        /**
            * Expected structure:
            * 
            * {
            *     name: string
            * }
            */

        value: {
            type: Object,
            required: true
        }
    },

    inject: ["errorHandlerService", "progressIndicatorService"],

    data() {
        return {
            jsonPayload: JSON.stringify([{}]),
            validation: {
                error: false,
                message: null
            },

            selectedTab: 1
        };
    },

    computed: {

        collectionId() {
            return get(this.value, 'name');
        },
        documentRecord() {
            return get(this.collectionSettings, "dataIngestion.documentRecord", {});
        },
        document() {
            return get(this.collectionSettings, "dataIngestion.document", {});
        },
        deadLetter() {
            return get(this.collectionSettings, "dataIngestion.deadLetter", {});
        },
        response() {
            return this.$store.getters[MODULE_NAME + "/response"];
        },
        jsonSchema() {
            const currDate = Date.now();
            const date = new Date(currDate);
            const currDateTime = DateTime.now();
            const dateTime = new DateTime(currDateTime);
            const jsonSchema = {};
            for (const attribute of this.schemaAttributeList) {
                if (!attribute.computed) {
                    jsonSchema[attribute.name] = null;
                    if (attribute.datatype === 'INTEGER') {
                        jsonSchema[attribute.name] = 1.0;
                    } else if (attribute.datatype === 'DECIMAL') {
                        jsonSchema[attribute.name] = 1.09;
                    } else if (attribute.datatype === 'DATETIME') {
                        jsonSchema[attribute.name] = dateTime.toISO();
                    } else if (attribute.datatype === 'STRING') {
                        jsonSchema[attribute.name] = `Value for ${attribute.name}`;
                    }
                    else if (attribute.datatype === 'DATE') {
                        jsonSchema[attribute.name] = date.toISOString();
                    }
                    else if (attribute.datatype === 'BOOLEAN') {
                        jsonSchema[attribute.name] = true;
                    }

                }
            }
            return jsonSchema;
        },
    },

    methods: {
        init() {
            if (this.collectionId) {
                this.refresh();
            }
        },

        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.loadCollectionSettings(this.collectionId);
                this.$store.dispatch(MODULE_NAME + "/setResponse");
                this.resetPayload();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        jsonPayloadValid() {
            if (this.$refs.jsonInput) {
                return this.$refs.jsonInput.isValid();
            } else {
                return false;
            }
        },
        isJsonPayloadEmpty() {
            return isEmpty(this.jsonPayload);
        },
        cancel() {
            this.$router.go(-1);
        },
        async send() {
            this.progressIndicatorService.show();
            try {
                const payload = `{"data":  ${this.jsonPayload}}`;
                await this.$store.dispatch(MODULE_NAME + "/uploadJson", { collectionId: this.collectionId, mesg: JSON.parse(payload) });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }

        },
        resetPayload() {
            this.jsonPayload = JSON.stringify([this.jsonSchema], null, 2);
            this.validation.error = false;
            this.validation.message = null;
            this.$store.dispatch(MODULE_NAME + "/resetResponse");

            if (this.$refs.jsonInput) {
                // Reset the internal validation state of the input component
                this.$refs.jsonInput.validJsonData = true;
                this.$refs.jsonInput.textAreaHint = null;
                this.$refs.jsonInput.color = 'accent';
            }

        },

    },
    created() {
        this.init();
    }
}
</script>

<i18n>
{
"en" : {
"collection-settings-data-upload.view.upload.title" : "Data Upload",
"collection-settings-data-upload.view.fields.payload.label" : "JSON Payload",
"collection-settings-data-upload.view.help.title" : "Help",
"collection-settings-data-upload.view.help.tabs.attributes" : "Attribute Documentation",
"collection-settings-data-upload.view.help.tabs.jsonSchema" : "JSON Schema",
"collection-settings-data-upload.view.subheadder.results" : "Results",
"collection-settings-data-upload.view.upload.tecnicalPipeline": "Technical Pipeline"
},
"de" : {
"collection-settings-data-upload.view.upload.title" : "Daten-Upload",   
"collection-settings-data-upload.view.fields.payload.label" : "JSON Nachricht",
"collection-settings-data-upload.view.help.title" : "Eingabehilfe",
"collection-settings-data-upload.view.help.tabs.attributes" : "Attribut-Dokumentation",
"collection-settings-data-upload.view.help.tabs.jsonSchema" : "JSON Schema",
"collection-settings-data-upload.view.subheadder.results" : "Ergebnisse",
"collection-settings-data-upload.view.upload.tecnicalPipeline": "Technische Pipeline"
}
}
</i18n>
