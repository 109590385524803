import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state: {
        name:"",
        tenantId: "",
        substanceRecyclingShare: createEmptyResponse(),
        batterySubstances: createEmptyBatterySubstances(),
        batteryModels:  createEmptyBatteryModels()
    },

    mutations: {

        async setBatterySubstanceId(state, batterySubstanceId) {
            if (batterySubstanceId) {
                //const payload = await api.masterdata.batterySubstance.findById(batterySubstanceId);
                state.substanceRecyclingShare.references.batterysubstance = batterySubstanceId;
                console.log(state.substanceRecyclingShare.name);
                
            } else {
                state.substanceRecyclingShare.references.batterysubstance = null;
            }
        },

        async setbatteryModelId(state, batteryModelId) {
            if (batteryModelId) {
                //const payload = await api.masterdata.batteryModels.findById(batteryModelId);
                state.substanceRecyclingShare.references.batterymodel = batteryModelId;
                console.log(state.substanceRecyclingShare.name);
            } else {
                state.substanceRecyclingShare.references.batterymodel = null;
            }
        },


        setSubstanceRecyclingShare(state, substanceRecyclingShare) {

            if (substanceRecyclingShare) {
                state.name = substanceRecyclingShare.name ? substanceRecyclingShare.name : null;
                state.substanceRecyclingShare.tenantId = substanceRecyclingShare.tenantId ? substanceRecyclingShare.tenantId : null;
                state.substanceRecyclingShare.name = substanceRecyclingShare.name ? state.name : null;
                state.substanceRecyclingShare.i18n = substanceRecyclingShare.i18n ? substanceRecyclingShare.i18n : {};
                state.substanceRecyclingShare.data = substanceRecyclingShare.data ? substanceRecyclingShare.data : { custData: {} };
                state.substanceRecyclingShare.data.primaryMaterialShare = substanceRecyclingShare.data.primaryMaterialShare ? Number(substanceRecyclingShare.data.primaryMaterialShare) :0;
                state.substanceRecyclingShare.data.preConsumerShare = substanceRecyclingShare.data.preConsumerShare ? Number(substanceRecyclingShare.data.preConsumerShare) : 0;
                state.substanceRecyclingShare.data.postConsumerShare = substanceRecyclingShare.data.postConsumerShare ? Number(substanceRecyclingShare.data.postConsumerShare) : 0;
                state.substanceRecyclingShare.active = substanceRecyclingShare.active ? substanceRecyclingShare.active : true;
                state.substanceRecyclingShare.references = substanceRecyclingShare.references ? substanceRecyclingShare.references : {};
                state.substanceRecyclingShare.creationDateTime = substanceRecyclingShare.creationDateTime ? substanceRecyclingShare.creationDateTime : null;
                state.substanceRecyclingShare.creationUser = substanceRecyclingShare.creationUser ? substanceRecyclingShare.creationDateTime : null;
                state.substanceRecyclingShare.modificationDateTime = substanceRecyclingShare.modificationDateTime ? substanceRecyclingShare.modificationDateTime : null;
                state.substanceRecyclingShare.modificationUser = substanceRecyclingShare.modificationUser ? substanceRecyclingShare.modificationUser : null;

            } else {
                state.substanceRecyclingShare.tenantId = null;
                state.substanceRecyclingShare.name = null;
                state.substanceRecyclingShare.i18n = null;
                state.substanceRecyclingShare.data = null;
                state.substanceRecyclingShare.active = null;
                state.substanceRecyclingShare.references = null;
                state.substanceRecyclingShare.creationDateTime = null;
                state.substanceRecyclingShare.modificationDateTime = null;
                state.substanceRecyclingShare.creationUser         = null;
                state.substanceRecyclingShare.modificationUser = null;
            }
        },
        setbatteryModels(state, batteryModels) {
            if (batteryModels) {
                let items = [];
                if (batteryModels.items) {
                    state.batteryModels.items = batteryModels.items;
                } else {
                    state.batteryModels.items = items;
                }
                if (batteryModels.tenantId) {
                    state.batteryModels.tenantId = batteryModels.tenantId;
                }
                if (batteryModels.pagination) {
                    state.batteryModels.pagination = batteryModels.pagination;
                }
            }
        },
        setBatterySubstances(state, batterySubstances) {
            if (batterySubstances) {
                let items = [];                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                if (batterySubstances.items) {
                    state.batterySubstances.items = batterySubstances.items;
                } else {
                    state.batterySubstances.items = items;
                }  
                if (batterySubstances.tenantId) {
                    state.batterySubstances.tenantId = batterySubstances.tenantId;
                }
                if (batterySubstances.pagination) {
                    state.batterySubstances.pagination = batterySubstances.pagination;
                }
            }

        },     

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;
                state.substanceRecyclingShare.tenantId = tenantId;
 
            } else {
                state.tenantId = null;
                state.substanceRecyclingShare.tenantId = tenantId;
            }
        },
        setPrimaryMaterialShare(state,item){
            if (item) {
                state.substanceRecyclingShare.data.primaryMaterialShare = item;
            } else {
                state.substanceRecyclingShare.data.primaryMaterialShare = null;
            }
        },
        setPreConsumerShare(state,item){
            if (item) {
                state.substanceRecyclingShare.data.primaryMaterialShare = item;
            } else {
                state.substanceRecyclingShare.data.primaryMaterialShare = null;
            }
        },
        setPostConsumerShare(state,item){
            if (item) {
                state.substanceRecyclingShare.data.primaryMaterialShare = item;
            } else {
                state.substanceRecyclingShare.data.primaryMaterialShare = null;
            }
        }
        ,
        setName(state,item){
            state.substanceRecyclingShare.name = item;
            state.name = item;
        }
    },

    actions:{


        async loadData(context) {

            try {
                const batteryModels = await api.masterdata.batteryModels.findByFilter('*', 0, 100);
                const batterySubstances = await api.masterdata.batterySubstances.findByFilter('*', 0, 100);
                context.commit('setBatterySubstances', batterySubstances);
                context.commit('setbatteryModels', batteryModels);
            } catch (error) {
                context.commit('setbatteryModels', createEmptyBatteryModels());
                context.commit('setBatterySubstances', createEmptyBatterySubstances());
                throw error;
            }
        },

        async createSubstanceRecyclingShare(context) {
            try {
                let request = context.getters.request;
                request.name = "";
                const response = await api.masterdata.substancesRecyclingShares.create(request, "");
                console.log("*****************************")
                console.log(response)
                await context.commit("setName",response.item.substanceRecyclingShare.name)
                
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateBatteryModelId(context, batteryModelId) {
            try {
                context.commit('setBatteryModelId', batteryModelId);
            } catch (error) {
                return Promise.reject(error);
            }

        },

        async updateBatterySubstanceId(context, batterySubstanceId) {
            try {
                context.commit('setBatterySubstanceId', batterySubstanceId);
            } catch (error) {
                return Promise.reject(error);
            }

        },
       
        async resetSubstanceRecyclingShare(context) {
            context.commit('setSubstanceRecyclingShare', createEmptyResponse());
        },

        async updateSubstanceRecyclingShare(context, substanceRecyclingShare) {
            try {
                context.commit('setSubstanceRecyclingShare', substanceRecyclingShare);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateBatterySubstances(context, batterySubstances) {

            try {
                context.commit('setBatterySubstances', batterySubstances);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updatePrimaryMaterialShare(context, item){
        try {
            context.commit('setPrimaryMaterialShare', item);
        } catch (error) {
            return Promise.reject(error);
        }},

    
        async updatePreConsumerShare(context, item){
            try {
                context.commit('setPreConsumerShare', item);
            } catch (error) {
                return Promise.reject(error);
            }},


        async updatePostConsumerShare(context, item){
                try {
                    context.commit('setPostConsumerShare', item);
                } catch (error) {
                    return Promise.reject(error);
                }},
                    
    },



    getters: {
        substanceName(state){
            return state.substanceRecyclingShare.name ? state.substanceRecyclingShare.name : null;
        
        },
        request(state) {
            let payload = state.substanceRecyclingShare;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },
        preConsumerShare(state){

            return state.substanceRecyclingShare.data.preConsumerShare ? state.substanceRecyclingShare.data.preConsumerShare : 0;
        },
        postConsumerShare(state){
        return state.substanceRecyclingShare.data.postConsumerShare ? state.substanceRecyclingShare.data.postConsumerShare : 0;
        },
        primaryMaterialShare(state){
        return state.substanceRecyclingShare.data.primaryMaterialShare ? state.substanceRecyclingShare.data.primaryMaterialShare : 0;
        },

        substanceRecyclingShare(state) {
            return state.substanceRecyclingShare ? state.substanceRecyclingShare : createEmptyResponse();
        },

        batteryModels(state) {
            return state.batteryModels ? state.batteryModels : createEmptyBatteryModels();
        },
        
        batterySubstances(state) {
            return state.batterySubstances ? state.batterySubstances : createEmptyBatterySubstances();
        },

        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },
        batteryModelId(state) {
            return get(state, 'substanceRecyclingShare.references.batterymodel');
        },
        batterySubstanceId(state) {
            return get(state, 'substanceRecyclingShare.references.batterysubstance');
        },

    }
}

function createEmptyResponse() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            primaryMaterialShare: 0,
            preConsumerShare:0,
            postConsumerShare: 0 },
        "active": true,
        "references": {
            batterymodel: null,
            batterysubstance: null
        }

    }
}


function createEmptyBatterySubstances() {

    return {

        "items": ["Plutonium","Bromium","Natrium"]
        
    }
}

function createEmptyBatteryModels() {

    return {

        "items": ["Model 3","Model S","Model T"]
        
    }
}