import api from '../../../api/battery-data-hub.api';
import { get } from 'lodash';

export default {

    namespaced: true,

    state: {

        batteryStatus: createDefaultBatteryStatus(),

    },

    mutations: {

        setBatterySatus(state, batteryStatus) {

            console.log("===========BATTERYSTATUS==============");
            console.log(JSON.stringify(batteryStatus, true, 2))
            console.log("===========BATTERYSTATUS==============");

            if (batteryStatus) {
                state.batteryStatus.creationDateTime = batteryStatus.creationDateTime ? batteryStatus.creationDateTime : null;
                state.batteryStatus.creationUser = batteryStatus.creationUser ? batteryStatus.creationUser : null;
                state.batteryStatus.modificationDateTime = batteryStatus.modificationDateTime ? batteryStatus.modificationDateTime : null;
                state.batteryStatus.modificationUser = batteryStatus.modificationUser ? batteryStatus.modificationUser : null;
                state.batteryStatus.tenantId = batteryStatus.tenantId ? batteryStatus.tenantId : null;
                state.batteryStatus.name = batteryStatus.name ? batteryStatus.name : null;
                state.batteryStatus.i18n = batteryStatus.i18n ? batteryStatus.i18n : {};
                state.batteryStatus.active = batteryStatus.active ? batteryStatus.active : true;
                state.batteryStatus.references = batteryStatus.references ? batteryStatus.references : {};
                state.batteryStatus.data.color = batteryStatus.data.color ? batteryStatus.data.color : null;
            }

        },
        setColor(state, color) {
            if (color) {
                state.batteryStatus.data.color = color;
            }
        }

    },



    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.batteryStatus.findById(name);

                context.commit('setBatterySatus', get(response, 'item.batteryStatus', createDefaultBatteryStatus()));
            } catch (error) {
                context.commit('setBatterySatus', createDefaultBatteryStatus());
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters, 'batteryStatus');
                let response = await api.masterdata.batteryStatus.update(payload);

                context.commit('setBatterySatus', get(response, 'item.batteryStatus', createDefaultBatteryStatus()));
                console.log('##RESPONSE####', JSON.stringify(get(response, 'item.batteryStatus', createDefaultBatteryStatus()), null, 2));


            } catch (error) {
                console.log(error);
                context.commit('setBatterySatus', createDefaultBatteryStatus());
                throw error;

            }
        },


        async updateBatteryStatus(context, batteryStatus) {
            context.commit('setBatterySatus', batteryStatus, createDefaultBatteryStatus());
        },


        async updateColor(context, color) {
            context.commit('setColor', color);
        }
    },

    getters: {

        batteryStatus(state) {
            return state.batteryStatus;

        },
        color(state) {
            return state.batteryStatus.data.color;
        },



    }
}

function createDefaultBatteryStatus() {
    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "color": null
        },
        "active": true,
        "references": {}

    }
}