import api from '@/store/api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(),
    },

    mutations: {

        setResponse(state, response) {

            if (response) {
                state.response = response;
            } else {
                state.response = getDefaultResponse();
            }

        },

        setWebhookSubscription(state, webhookSubscription) {
            set(state, 'response.data.webhookSubscription', webhookSubscription ? webhookSubscription : {});
        }
    },

    actions: {

        async loadData(context, {name}) {

            try {
                let response = await api.webhooks.webhookSubscriptions.findById(name);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }
        },

        updateModel(context, {webhookSubscription}) {

            context.commit('setWebhookSubscription', webhookSubscription);
        },

        async saveData(context) {
            
            try {

                let payload = context.getters.webhookSubscription;
                let response = await api.webhooks.webhookSubscriptions.update(payload.name, payload);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }
        }
    },

    getters: {

        model(state) {

            const webhookSubscription = get(state, 'response.data.webhookSubscription', {});

            return {
                webhookSubscription: webhookSubscription
            }
        },

        webhookSubscription(state, getters) {
            let payload = getters.model.webhookSubscription;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        }
    }
}

function getDefaultResponse() {

    return {
    };
}