<template>
  <v-sheet color="secondary darken-1">
      <c-item-editor-form 
        :title="$t('user-accounts-editor.view.title', {name:name})" 
        :editable="true" 
        v-model="model"
        @update-confirmed="save"
        @update-canceled="cancel">
            <template v-slot:default>
                <v-sheet color="grey lighten-3">
                    
                    <v-tabs 
                        v-model="selectedTab" 
                        :vertical="$vuetify.breakpoint.mdAndUp" 
                        color="grey" 
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
                        slider-color="grey" 
                        show-arrows 
                        :fixed-tabs="!$vuetify.breakpoint.mdAndUp">
                        

                        <!-- Tabs -->
                        
                        <v-tab :key="0">{{$t('user-accounts-editor.view.sections.general.title')}}</v-tab>
                        <v-tab :key="1">{{$t('user-accounts-editor.view.sections.acls.title')}}</v-tab>
                        <v-tab :key="2">{{$t('user-accounts-editor.view.sections.groups.title')}}</v-tab>
                        <v-tab :key="3">{{$t('user-accounts-editor.view.sections.json.title')}}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="account"/>
                            </v-tab-item>

                            <!-- Collection Access -->
                            <v-tab-item :key="1">
                                <dxs-access-control-list-assignment-editor-page v-model="model" />
                            </v-tab-item>

                            <!-- Assigned User Groups -->
                            <v-tab-item :key="2">
                                <dxs-user-group-assignment-page v-model="model" />
                            </v-tab-item>

                            <v-tab-item :key="3">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>

                            

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
      </c-item-editor-form>
      
  </v-sheet>
</template>
      
<script>
const MODULE_NAME = 'userAccountsEditor';
import Roles from "@/roles";
/*import { get, isEmpty } from 'lodash';*/

import UserAccountsEditorModule from '@/core/store/modules/user-mgmt/user-accounts/user-accounts-editor.module';
import AccessControlListAssignmentEditorPageComponent from '@/core/components/usermgmt/access-control/access-control-list-assignment-editor-page.component';
import UserGroupAssignmentPageComponent from '@/core/components/usermgmt/users/user-group-assignment-page.component';

export default {

    props: {
        value: {
            type: Object,
            required: false,
            validator: (value) => value.name
        }
    },

    components : {
     //   'dxs-user-groups-editor' : UserGroupEditorComponent
        'dxs-access-control-list-assignment-editor-page' : AccessControlListAssignmentEditorPageComponent,
        'dxs-user-group-assignment-page' : UserGroupAssignmentPageComponent
    },

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    
    data: () => ({
        selectedTab : 0
    }),

    methods: {

        async refresh() {
            this.progressIndicatorService.show();
            try {

                // First reset ...
                await this.$store.dispatch(MODULE_NAME + "/resetData")

                // ... then reload 
                await this.$store.dispatch(MODULE_NAME + "/loadData", { name: this.name })
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async save() {
            
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/saveData")
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push({
                name: 'usersList',
            });
        },

    },

    computed: {

        name: {
            get() {
                return this.value ? this.value.name : null;
            }
        },

        model: {

            get() {
                return this.$store.getters[MODULE_NAME + "/model"];
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateModel", value);
            }
        },

        account: {
            get() {
                return this.$store.getters[MODULE_NAME + "/account"];
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateAccount", value);
            }
        },
        

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.USER_GROUPS_EDIT);
        },


    },

    created() {

        // Dynamically register the corresponding vuex module on component creation.
        if (!this.$store.hasModule(MODULE_NAME)){
            this.$store.registerModule(MODULE_NAME, UserAccountsEditorModule);
        }

        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "user-accounts-editor.view.title" : "User {name}",

        "user-accounts-editor.view.sections.groups.title" : "Groups",
        "user-accounts-editor.view.sections.general.title" : "General",
        "user-accounts-editor.view.sections.acls.title" : "Access Control",
        "user-accounts-editor.view.sections.json.title" : "JSON"

    },

    "de" : {
        "user-accounts-editor.view.title" : "Benutzer {name}",

        "user-accounts-editor.view.sections.groups.title" : "Gruppen",
        "user-accounts-editor.view.sections.general.title" : "Allgemein",
        "user-accounts-editor.view.sections.acls.title" : "Zugriffsteuerung",
        "user-accounts-editor.view.sections.json.title" : "JSON"
    }
}
</i18n>