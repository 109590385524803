import apiV2 from '@/core/api/v2.api';

import { set, cloneDeep } from 'lodash';
export default {

    namespaced: true,

    state : {
        detectionJobTemplate: getDefaultDetectionTemplate(),
        collectionSettings: {},
        schema: {},
        tenant: {},
        systemParameters: []
    },

    mutations: {

        setResponse(state, response) {

            if (response.data) {

                state.response = response ? response : {};

                state.detectionJobTemplate = response.data.detectionJobTemplate ? response.data.detectionJobTemplate : {} ;
                state.collectionSettings = response.data.collectionSettings ? response.data.collectionSettings : {} ;
                state.schema = response.data.schema ? response.data.schema : {} ;
                state.tenant = response.data.tenant ? response.data.tenant : {} ;
            }
            
        },

        setSystemParameters(state, systemParameters){
            set(state, 'systemParameters', systemParameters);
        },

        setDetectionJobTemplate(state, template){
            set(state, 'detectionJobTemplate', template);
        },

        setActive(state, active){
            if(state.detectionJobTemplate){
                state.detectionJobTemplate.active = active;
            }
        },

        setTimeWindowSizeSeconds(state, timeWindowSizeSeconds) {
            if (state.detectionJobTemplate) {
                state.detectionJobTemplate.source.timeWindowSizeSeconds = timeWindowSizeSeconds;
            }
        },

        setGroupBy(state, groupBy) {
            if (state.detectionJobTemplate) {
                state.detectionJobTemplate.source.groupBy = groupBy;
            }
        },

        setAggregation(state, aggregation) {
            if (state.detectionJobTemplate) {
                state.detectionJobTemplate.source.aggregation = aggregation;
            }
        },

        setPartitioningStrategy(state, partitioningStrategy) {
            if (state.detectionJobTemplate) {
                state.detectionJobTemplate.logging.partitioningStrategy = partitioningStrategy;
            }
        },

        setLoggingCollection(state, loggingCollection) {
            if (state.detectionJobTemplate) {
                state.detectionJobTemplate.logging.collectionId = loggingCollection;
            }
        },

        setLoggingSchema(state, loggingSchema) {
            if (state.detectionJobTemplate) {
                state.detectionJobTemplate.logging.schemaId = loggingSchema;
            }
        },

        setFilter(state, filter) {
            if (state.detectionJobTemplate) {
                state.detectionJobTemplate.source.filter = filter;
            }
        },

        setSchedule(state, schedule) {
            if (state.detectionJobTemplate) {
                state.detectionJobTemplate.schedule = schedule;
            }
        },

        setRules(state, rules) {
            if (state.detectionJobTemplate) {
                state.detectionJobTemplate.rules = rules;
            }
        }
        
    },

    actions: {

        async loadData(context, { name }) {

            try {
                let response = await apiV2.catalog.detectionJobTemplates.findByName(name);
                let systemParameters = await apiV2.catalog.systemParameters.findAll('*', 0, 50);
                context.commit('setResponse', response);
                context.commit('setSystemParameters', systemParameters);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async saveData(context, {name}) {
            
            try {
                let payload = context.getters.detectionJobTemplate;
                let response = await apiV2.catalog.detectionJobTemplates.update(name, payload);
                context.commit('setResponse', response);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        resetData(context) {
            context.commit('setResponse', getDefaultResponse());
        },

        async addRule(context, { rule, index }) {
            
            // Cloning of the rules is crucial - Vue's reactivity system will not
            // detect the change otherwise.
            let detectionJobTemplate = context.getters.detectionJobTemplate;
            let rules = cloneDeep(detectionJobTemplate.rules);
            rules[index] = rule;

            context.commit('setRules', rules)
        },

        async removeRule(context, { rule }) {

            // Cloning of the rules is crucial - Vue's reactivity system will not
           // detect the change otherwise.
           let detectionJobTemplate = context.getters.detectionJobTemplate;
           let rules = cloneDeep(detectionJobTemplate.rules);

           // Remove by filtering
           rules = rules.filter(e => e.name !== rule.name)

           context.commit('setRules', rules);

       },

        updateDetectionJobTemplate(context, template) {
            context.commit('setDetectionJobTemplate', template);
        },

        updateActive(context, active) {
            context.commit('setActive', active);
        },

        updateTimeWindowSizeSeconds(context, timeWindowSizeSeconds){
            context.commit('setTimeWindowSizeSeconds', timeWindowSizeSeconds)
        },

        updateGroupBy(context, groupBy) {
            context.commit('setGroupBy', groupBy);
        },

        updateAggregation(context, aggregation) {
            context.commit('setAggregation', aggregation);
        },

        updateFilter(context, filter) {
            context.commit('setFilter', filter);
        },

        updatePartitioningStrategy(context, partitioningStrategy) {
            context.commit('setPartitioningStrategy', partitioningStrategy);
        },

        updateSchedule(context, schedule) {
            context.commit('setSchedule', schedule);
        },

        updateLoggingCollection(context, loggingCollection) {
            context.commit('setLoggingCollection', loggingCollection);
        },

        updateLoggingSchema(context, loggingSchema) {
            context.commit('setLoggingSchema', loggingSchema);
        },

    },

    getters: {

        response(state) {
            return {
                detectionJobTemplate: state.detectionJobTemplate ? state.detectionJobTemplate : {},
                collectionSettings: state.collectionSettings ? state.collectionSettings : {},
                schema: state.schema ? state.schema : {},
                tenant: state.tenant ? state.tenant : {},
            }
        },

        detectionJobTemplate(state) {
            return state.detectionJobTemplate ? state.detectionJobTemplate : getDefaultDetectionTemplate();
        },

        systemParameters(state) {
            return state.systemParameters ? state.systemParameters : [];
        }

    }
}

function getDefaultResponse() {
    
    return {
        data: getDefaultDetectionTemplate()
    };
}

function getDefaultDetectionTemplate() {
    return {
                
        name: "",
        i18n: {},
        active: false,
        source: {
            collectionId: "",
            dateTimeAttribute: "",
            timeWindowSizeSeconds: 10,
            filter: {
                facetFilters: [],
                filterQuery: "*",
                filterQueryLanguage: "SIMPLE",
                rangeFilters: []
            },
            aggregation: [],
            groupBy: []
        },
        logging: {
            collectionId: "",
            schemaId: "",
            partitioningStrategy: "PARTITION_BY_MONTH"
        },
        schedule: {
            secondsExpression: "10",
            minutesExpression: "*",
            hoursExpression: "*",
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true,
            saturday: true,
            sunday: true
        },
        rules: [
            // {
            //     name: "",
            //     i18n: {},
            //     severity: "LOW",
            //     expression: "",
            //     active: false,
            //     creationDateTime: null,
            //     creationUser: null,
            //     modificationDateTime: null,
            //     modificationUser: null
            // }
        ],
        customData: {},

        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null


    };
}

