<template>
    <c-section 
        :title="$t('system-params.view.title')" 
        :subtitle="$t('system-params.view.subtitle')" 
        class="secondary">
        <c-params-editor 
            class="ma-10" 
            :items="items" 
            :disableAddItem="!hasCreateRole"
            :disableEditItem="!hasEditRole"
            :disableDeleteItem="!hasDeleteRole"
            @item-updated="update"
            @item-removed="remove"/>
    </c-section> 
</template>

<script>
import Roles from '@/roles';

const MODULE_NAME = "systemParams";

export default {

    inject: ["errorHandlerService", "progressIndicatorService", "userContextService"],

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            let items = this.response.items;
            items = items.sort( (a,b) => a.name < b.name ? -1 : 1 );
            return items;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.SYSTEM_PARAMETERS_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.SYSTEM_PARAMETERS_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.SYSTEM_PARAMETERS_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.SYSTEM_PARAMETERS_DELETE);
        }
    },

    methods: {

        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse")
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        async update(event) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateItem", { item : event.item} ) 
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        async remove(event) {
            this.$log.debug("view ", JSON.stringify(event));
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/removeItem", { item : event.item} ) // item.value
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "system-params.view.title" : "System Parameters",
        "system-params.view.subtitle" : "System parameters are globally defined on a per-tenant basis. This parameters will be used for customizing purpose and will not be evaluated by the standard system."
    },

    "de" : {
        "system-params.view.title" : "Systemparameter",
        "system-params.view.subtitle" : "Die definierten Systemparameter gelten für den gesamten Mandanten. Diese Parameter werden nur für das Customizing verwendet und nicht durch das Standardsystem ausgewertet."

    }

}
</i18n>