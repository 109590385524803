<template>
    <v-sheet>
        <c-item-editor-form :title="$t('battery-model-editor.view.title', { name: name })" :value="batteryModel"
            @update-confirmed="save" @update-canceled="cancel" :editable="hasEditRole" :hideSaveAction="!hasEditRole">
            <template v-slot:default="props">

                <v-sheet color="grey lighten-3">

                    <v-tabs v-model="navigation.selectedTab" :vertical="$vuetify.breakpoint.mdAndUp" color="grey"
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" slider-color="grey"
                        show-arrows :fixed-tabs="!$vuetify.breakpoint.mdAndUp">


                        <!-- Tabs -->
                        <v-tab :key="0">{{ $t('battery-model-editor.view.tabs.general') }}</v-tab>
                        <v-tab :key="1">{{ $t('battery-model-editor.view.tabs.batterydata') }}</v-tab>
                        <v-tab :key="2">{{ $t('battery-model-editor.view.tabs.batteryConformity') }}</v-tab>
                        <v-tab :key="3">{{ $t('battery-model-editor.view.tabs.batterymaterialcomposition') }}</v-tab>
                        <v-tab :key="4">{{ $t('battery-model-editor.view.tabs.json') }}</v-tab>


                        <v-tabs-items v-model="navigation.selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="batteryModel" :disabled="!props.editable" />
                            </v-tab-item>

                            <!-- Settings -->
                            <v-tab-item :key="1">
                                <v-card flat>
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('battery-model-editor.view.tabs.batterydata.title') }}
                                        </div>
                                        <div>{{ $t('battery-model-editor.view.tabs.batterydata.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("battery-model-editor.view.sections.batterydata.title") }}
                                        </div>
                                        <div>
                                            {{ $t('battery-model-editor.view.sections.batterydata.subtitle') }}
                                        </div>
                                        <p></p>
                                        <c-item-selector v-model="batteryCategory" :items="batteryCategories"
                                            :disabled="!props.editable" :return-object="false"
                                            :rules="[v => v && !!v.trim() || $t('battery-model-editor.view.category.valuecheck')]"
                                            :label="$t('battery-model-editor.view.category')" filled>
                                        </c-item-selector>

                                        <c-item-selector v-model="manufacturerId" :items="manufacturer.items"
                                            :return-object="false"
                                            :rules="[v => v && !!v.trim() || $t('battery-model-editor.view.manufacturer.valuecheck')]"
                                            :label="$t('battery-model-editor.view.manufactuerer')" filled>
                                        </c-item-selector>

                                        <v-text-field v-model="batteryWeight" color="accent" :disabled="!props.editable"
                                            :label="$t('battery-model-editor.view.weight.text')" filled type="number" />


                                        <v-text-field v-model="batteryChemistry" color="accent" :disabled="!props.editable"
                                            :label="$t('battery-model-editor.view.tabs.chemistry.text')" filled />

                                    </v-card-text>


                                </v-card>
                            </v-tab-item>

                            <v-tab-item :key="2">
                                <v-container fluid>

                                    <dxs-conformity-editor-page v-model="conformityEditorPageModel"
                                        :disabled="!props.editable" />

                                    <v-card flat>
                                        <v-card-text>
                                            <c-string-value-editor headerStyle="lite" :items="testReports"
                                                @item-removed="handleItemRemoved" @item-updated="handleItemUpdated"
                                                :editor-description="$t('string-value.component.editordescription.text')"
                                                :editor-title="$t('string-value.component.editortext')"
                                                text-field-label="Link"
                                                :text-field-header="$t('string-value.component.header.text')"
                                                :title="$t('string-value.component.title')"
                                                :disabled="!props.editable"></c-string-value-editor>
                                        </v-card-text>
                                    </v-card>
                                </v-container>
                            </v-tab-item>
                            <!--COMPOSITION-->
                            <v-tab-item :key="3">
                                <v-container fluid>
                                    <v-card flat>
                                        <v-card-text>
                                            <dxs-battery-material-composition-editor v-model="items" :battery-materials="batteryMaterials"
                                                @material-removed="handleMaterialRemoved" :disabled="!props.editable">
                                            </dxs-battery-material-composition-editor>
                                        </v-card-text>
                                    </v-card>
                                </v-container>
                            </v-tab-item>

                            <!-- JSON -->
                            <v-tab-item :key="4">
                                <c-json-item-editor-page v-model="batteryModel" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
        </c-item-editor-form>
    </v-sheet>
</template>

<script>
import { get } from 'lodash';

import ConformityEditorPageComponent from '@/solutions/battery-data-hub/components/masterdata/battery-models/conformity-editor-page.component.vue';
import BatteryMaterialCompositionEditor from '@/solutions/battery-data-hub/components/material-composition/battery-material-composition-editor.component.vue';
import BatteryModelEditorModule from "../../../store/masterdata/battery-models/battery-models-editor.module";

import Roles from '@/roles';

const MODULE_ID = 'batteryModelEditor';

export default {
    name: 'battery-model-editor',
    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    components: {
        'dxs-conformity-editor-page': ConformityEditorPageComponent,
        'dxs-battery-material-composition-editor': BatteryMaterialCompositionEditor

    },

    props: {

        value: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            model: {
                euDeclarationOfConformityId: this.euDeclarationOfConformityId,
                euDeclarationOfConformityLink: this.euDeclerationOfConformity,

            },
            navigation: {
                selectedTab: 0
            },
            changedIndexList: []




        }
    },

    computed: {

        conformityEditorPageModel: {

            get() {
                return {
                    euDeclarationOfConformityId: this.idOfEuDeclerationOfConformity,
                    euDeclarationOfConformityLink: this.euDeclerationOfConformity
                }
            },
            set(value) {
                this.idOfEuDeclerationOfConformity = value.euDeclarationOfConformityId;
                this.euDeclerationOfConformity = value.euDeclarationOfConformityLink;
            }
        },

        batteryModel: {
            get() {
                return this.$store.getters[MODULE_ID + '/batteryModel'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryModel", value);
            }
        },

        batteryWeight: {
            get() {
                return this.$store.getters[MODULE_ID + '/batteryWeight'];
            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryWeight", value);
            }
        },

        batteryChemistry: {
            get() {
                return this.$store.getters[MODULE_ID + '/batteryChemistry'];
            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryChemistry", value);
            }
        },

        idOfEuDeclerationOfConformity: {
            get() {
                return this.$store.getters[MODULE_ID + '/idOfEuDeclerationOfConformity'];
            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateIdOfEuDeclerationOfConformity", value);
            }
        },

        euDeclerationOfConformity: {
            get() {

                return this.$store.getters[MODULE_ID + '/euDeclerationOfConformity'];
            },
            set(value) {

                this.$store.dispatch(MODULE_ID + "/updateEuDeclerationOfConformity", value);
            }
        },

        testReports: {
            get() {

                return this.$store.getters[MODULE_ID + '/testReports'];

            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateTestReports", value);
            }
        },
        batteryMaterialCompositionItems: {

            get() {
                return this.$store.getters[MODULE_ID + '/batteryMaterialCompositionItems'];

            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryMaterialCompositions", value);
            }

        },
        items: {
            get() {
                let flatenedMaterialCompositions = [];
                this.batteryMaterialCompositionItems.forEach(value => {
                    flatenedMaterialCompositions.push({
                        share: get(value, 'data.share', null),
                        batterymaterialId: get(value, 'references.batterymaterialId', null),
                        name: get(value, 'name', null),
                        tenantId: get(value, 'tenantId', null)
                    });
                });
                return flatenedMaterialCompositions;
            },
            set(value) {
                if (Array.isArray(value)) {
                    value.forEach(valueItem => {
                        let itemFound = false;

                        this.batteryMaterialCompositionItems.forEach(item => {
                            if (item.references.batterymaterialId === valueItem.batterymaterialId) {
                                itemFound = true;
                                if (item.data.share !== valueItem.share) {
                                    item.data.share = valueItem.share;
                                }
                            }
                        });

                        if (!itemFound) {
                            this.createNewBatteraMaterial(valueItem);

                        }
                    });
                }

                this.$store.dispatch(MODULE_ID + "/updateBatteryMaterialCompositions", this.batteryMaterialCompositionItems);

            }

        },
        batteryMaterials: {
            get() {

                return this.$store.getters[MODULE_ID + '/batteryMaterials'];
            }
        },
        batteryCategories: {
            get() {
                return this.$store.getters[MODULE_ID + "/batteryCategories"];
            }
        },
        batteryCategory: {
            get() {
                return this.$store.getters[MODULE_ID + "/batteryCategory"];
            },
            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryCategory", value);
            }
        },
        manufacturer() {
            return this.$store.getters[MODULE_ID + "/manufacturer"];
        },

        manufacturerId: {
            get() {
                return this.$store.getters[MODULE_ID + "/manufacturerId"];
            },

            set(item) {
                this.$store
                    .dispatch(MODULE_ID + "/updateManufacturerId", item)
                    .catch(this.errorHandlerService.handleError);
            },
        },


        name() {
            return get(this.value, 'name');
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.BATTERYMODEL_EDIT);
        },
    },

    methods: {

        async refresh() {
            this.progressIndicatorService.show();
            try {
                this.$log.debug(`Loading information for battery model ${this.name}`);
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
                await this.$store.dispatch(MODULE_ID + "/loadManufacturers");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async save() {
            this.progressIndicatorService.show();
            try {
                this.removeDeletedItems();
                await this.$store.dispatch(MODULE_ID + "/saveData", { name: this.name });
                this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        handleItemUpdated({ item, index }) {
            const updatedTestReports = [...this.testReports];
            updatedTestReports.splice(index, 1, item);
            this.testReports = updatedTestReports;
        },
        handleItemRemoved({ index }) {
            const updatedTestReports = [...this.testReports];
            updatedTestReports.splice(index, 1);
            this.testReports = updatedTestReports;
        },
        handleMaterialRemoved(event) {
            this.changedIndexList.push(event.index);
        },
        removeDeletedItems() {
            if (this.changedIndexList.length > 0) {
                this.changedIndexList.forEach(index => {
                    this.batteryMaterialCompositionItems.splice(index, 1)
                });
            }
            this.changedIndexList = [];
        },

        createNewBatteraMaterial(valueItem) {
            this.batteryMaterialCompositionItems.push({
                "creationDateTime": null,
                "creationUser": null,
                "modificationDateTime": null,
                "modificationUser": null,
                "active": true,
                "data": {
                    "share": valueItem.share
                },
                "i18n": {},
                "name": "",
                "references": {
                    "batterymaterialId": valueItem.batterymaterialId,
                    "batterymodelId": this.name
                }, "tenantId": valueItem.tenantId
            });

        },


        cancel() {
            this.$router.push({
                name: "BatteryModelListView",
            });
        }
    },
    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, BatteryModelEditorModule);
        }

        this.refresh();

    }
}
</script>

<i18n>
{
    "en" : {
        "battery-model-editor.view.tabs.batterydata.title": "Settings",
        "battery-model-editor.view.sections.batterydata.title": "Battery Data",
        "battery-model-editor.view.tabs.batterydata.subtitle" : "This page contains settings for the selected Battery Model.",
        "battery-model-editor.view.sections.batterydata.subtitle": "Please enter battery data such as manufacturer, weight or chemistry here.",
        "string-value.component.title" : "Links to the Test Reports",
        "battery-model-editor.view.title" : "Battery Model {name}",
        "battery-model-editor.view.tabs.general" : "General",
        "battery-model-editor.view.tabs.batteryConformity" : "Battery Data",
        "battery-model-editor.view.tabs.batteryConformity" : "Conformity",
        "battery-model-editor.view.tabs.json" : "JSON",
        "battery-model-editor.view.weight.text" : "Battery Weight in kg",
        "battery-model-editor.view.tabs.batterydata" : "Settings",
        "battery-model-editor.view.tabs.chemistry.text" :"Battery Chemistry",
       "string-value.component.editordescription.text": "Here you can enter the Link to the Test Report.",
       "string-value.component.editortext" : "Link to the Test Report",
       "string-value.component.header.text": "Edit Link",
       "battery-model-editor.view.tabs.batterymaterialcomposition": "Material",
       "battery-model-editor.view.category.valuecheck": "Please assign a Battery Category.",
       "battery-model-editor.view.category":"Battery Category",
       "battery-model-editor.view.manufactuerer":"Manufacturer",
       "battery-model-editor.view.manufacturer.valuecheck" : "Please assign a Manufacturer."
    },

    "de" : {
        "battery-model-editor.view.tabs.batterydata.title": "Einstellungen",
        "battery-model-editor.view.sections.batterydata.title": "Batteriedaten",
        "battery-model-editor.view.tabs.batterydata.subtitle" : "Diese Seite enthält Einstellungen zum ausgewählten Batterie-Modell.",
        "battery-model-editor.view.sections.batterydata.subtitle": "Bitte weisen Sie hier Daten zur Batterie, wie Hersteller, Gewicht oder Chemie zu.",
        "battery-model-editor.view.title" : "Batterie-Modell {name}",
        "string-value.component.title" : "Links zu den Testberichten",
        "battery-model-editor.view.tabs.general" : "Allgemein",
        "battery-model-editor.view.tabs.batterydata" : "Einstellungen",
        "battery-model-editor.view.tabs.batteryConformity" : "Konformität",
        "battery-model-editor.view.weight.text" : "Batteriegewicht in kg",
        "battery-model-editor.view.tabs.chemistry.text" :"Batteriechemie",
        "string-value.component.editordescription.text": "Hier können Sie den Link zum Testbericht anpassen.",
        "string-value.component.editortext" : "Link zum Testbericht",
        "string-value.component.header.text": "Link bearbeiten",
        "battery-model-editor.view.tabs.batterymaterialcomposition": "Material",
        "battery-model-editor.view.category.valuecheck": "Bitte wählen Sie eine Batterie-Kategorie.",
        "battery-model-editor.view.category":"Batterie-Kategorie",
        "battery-model-editor.view.manufactuerer":"Hersteller",
        "battery-model-editor.view.manufacturer.valuecheck" : "Bitte wählen Sie einen Hersteller."


    }
}
</i18n>