<template>
  <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form 
            :title="$t('substances-recycling-share-editor.view.title', { name: name })" 
            :value="substancesRecyclingShare"
            @update-confirmed="save"
            @update-canceled="cancel"
            :editable="hasEditRole"
            >
            
            <template v-slot:default>
                <v-sheet color="grey lighten-3">
                    
                    <v-tabs 
                        v-model="selectedTab" 
                        :vertical="$vuetify.breakpoint.mdAndUp" 
                        color="grey" 
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
                        slider-color="grey" 
                        show-arrows 
                        :fixed-tabs="!$vuetify.breakpoint.mdAndUp">
                        

                        <!-- Tabs -->
                        <v-tab :key="0">{{$t('substances-recycling-share-editor.view.tabs.general')}}</v-tab>
                        <v-tab :key="1">{{$t('substances-recycling-share-editor.view.tabs.settings')}}</v-tab>
                        <v-tab :key="2">{{$t('substances-recycling-share-editor.view.tabs.json')}}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="substancesRecyclingShare" />
                            </v-tab-item>

                            <!-- Settings -->
                            <v-tab-item :key="1">
                                <v-card flat>
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('substances-recycling-share-editor.view.sections.props.title') }}</div>
                                        <div>{{ $t('substances-recycling-share-editor.view.sections.props.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("substances-recycling-share-editor.view.sections.values.title") }}</div>
                                    <div>{{ $t('substances-recycling-share-editor.view.sections.values.subtitle') }}</div>
                                    <p></p>
                                    <p></p>
                                        <v-row>
                                            <v-col cols="12" lg="4">
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.values.pre.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.values.pre.subtitle') }}</div>
                                                <c-percentage-input v-model="substancesRecyclingShare.data.preConsumerShare"      :label = "$t('substance-recycling-share-editor.view.subheader.preConsumerShare')" filled> </c-percentage-input>
                                            </v-col>
                                            
                                            <v-col cols="12" lg="4">
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.values.post.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.values.post.subtitle') }}</div>
                                                <c-percentage-input v-model="substancesRecyclingShare.data.postConsumerShare"     :label = "$t('substance-recycling-share-editor.view.subheader.postConsumerShare')" filled> </c-percentage-input>
                                            </v-col>

                                            <v-col cols="12" lg="4">
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.values.prim.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.values.prim.subtitle') }}</div>
                                                <c-percentage-input v-model="substancesRecyclingShare.data.primaryMaterialShare"  :label = "$t('substance-recycling-share-editor.view.subheader.primaryMaterialShare')" filled> </c-percentage-input>
                                            </v-col>
                                        </v-row>
                                       
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("substances-recycling-share-editor.view.sections.props.title") }}</div>
                                    <div>{{ $t('substances-recycling-share-editor.view.sections.props.subtitle') }}</div>
                                    <p></p>
                                    <p></p>
                              <v-row> 
                                  <v-col cols="12" >
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.props.models.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.props.models.subtitle') }}</div>
                                      <c-item-selector :items="batteryModels.items" v-model="substancesRecyclingShare.references.batterymodel" :return-object="false"
                                          :chips="false" filled
                                          :label="$t('substance-recycling-share-editor.view.subheader.model')">BatteryModels</c-item-selector>
                                  </v-col>
                                  <v-col cols="12" >
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("substances-recycling-share-editor.view.sections.props.substances.title") }}</div>
                                                <div>{{ $t('substances-recycling-share-editor.view.sections.props.substances.subtitle') }}</div>
                                      <c-item-selector :items="batterySubstances.items" v-model="substancesRecyclingShare.references.batterysubstance" :return-object="false" filled
                                          :chips="false"
                                          :label="$t('substance-recycling-share-editor.view.subheader.substance')">BatterySubstances</c-item-selector>
                                  </v-col>
                              </v-row>
                          </v-card-text>
                      </v-card>
                            </v-tab-item>
                            

                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>
                            
                            

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
             
                
                 
            </template>
        </c-item-editor-form>
  </v-sheet>
</template>


<script>
import { cloneDeep, get } from 'lodash';

import SubstancesRecyclingShareEditorModule from "../../../store/masterdata/substance-recycling-share/substance-recycling-shares-editor.module";

import Roles from '@/roles';

const MODULE_ID = 'SubstancesRecyclingShareEditor';

export default {

    name: 'substance-recycling-share-editor',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],



    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        nextPage(){
        this.$router.push({
          name: "SubstancesRecyclingShareEditorView",
          params: {
            name: this.substancesRecyclingShare.name,
          },
        })
        },


        async save() {
            this.progressIndicatorService.show();
            try{
                await this.$store.dispatch(MODULE_ID + "/saveData");
                } catch(error){
                    const err = Error(this.$t('substances-recycling-share-editor.view.subheader.errorm'))
                    this.errorHandlerService.handleError(err);
                } finally {
                    this.progressIndicatorService.hide();
                }
        },

        cancel() {
            this.$router.push({
                name: "SubstancesRecyclingShareListView",
            });
        }
    },

    computed: {

        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.RECYCLINGSHARE_EDIT);
        },

        substancesRecyclingShare: {

            get() {
                return this.$store.getters[MODULE_ID + '/substancesRecyclingShare'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateSubstancesRecyclingShare", value);
            }
        },

        model() {
            return cloneDeep(this.substancesRecyclingShare);
        },

        batterySubstances : {

            get() {
                return this.$store.getters[MODULE_ID + '/batterySubstances'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatterySubstance", value);
            }
        },

        batteryModels : {

            get() {
                return this.$store.getters[MODULE_ID + '/batteryModels'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateBatteryModel", value);
            }
        }

    },

   async created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, SubstancesRecyclingShareEditorModule);
        }
        await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
        this.refresh();

    }
}
</script>

<i18n>
{
    "en" : {
        "substances-recycling-share-editor.view.title" : "Substance Recycling Share {name}",
        "substance-recycling-shares-editor.view.subtitle" : "This editor will guide you through the required steps for creating a new Substance Recycling Share.",
        "substance-recycling-share-editor.view.subheader.model" : "Battery Model",
        "substance-recycling-share-editor.view.subheader.substance" : "Battery Substance",
        "battery-model-editor.view.fields.schema.validation.notEmpty" : "Please assign a Battery Model.",
        "battery-substance-editor.view.fields.schema.validation.notEmpty" : "Please assign a Substance.",
        "substance-recycling-share-editor.view.subheader.primaryMaterialShare" : "Primary Material Share",
        "substance-recycling-share-editor.view.subheader.preConsumerShare" : "Pre-Consumer-Recycling Share",
        "substance-recycling-share-editor.view.subheader.postConsumerShare" : "Post-Consumer-Recycling Share",
        "substances-recycling-share-editor.view.tabs.general" : "General",
        "substances-recycling-share-editor.view.tabs.settings" : "Settings",
        "substances-recycling-share-editor.view.sections.values.title":"Shares",
        "substances-recycling-share-editor.view.sections.values.subtitle":"Here you can manage the respective share of this Substance Recycling Share.",
        "substances-recycling-share-editor.view.sections.values.pre.title":"Pre-Consumer-Recycling Share",
        "substances-recycling-share-editor.view.sections.values.pre.subtitle":"The Substance Recycling Share before the consumer used the battery.",
        "substances-recycling-share-editor.view.sections.values.post.title":"Post-Consumer-Recycling Share",
        "substances-recycling-share-editor.view.sections.values.post.subtitle":"The Substance Recycling Share after the consumer used the battery.",
        "substances-recycling-share-editor.view.sections.values.prim.title":"Primary Material Share",
        "substances-recycling-share-editor.view.sections.values.prim.subtitle":"The actual share of the substance within the battery.",
        "substances-recycling-share-editor.view.sections.props.title":"Properties",
        "substances-recycling-share-editor.view.sections.props.subtitle":"Here you can manage the properties of this Substance Recycling Share.",
        "substances-recycling-share-editor.view.sections.props.models.title":"Battery Model",
        "substances-recycling-share-editor.view.sections.props.models.subtitle":"Here you can manage the respective Battery Model of this Substance Recycling Share.",
        "substances-recycling-share-editor.view.sections.props.substances.title":"Battery Substance",
        "substances-recycling-share-editor.view.sections.props.substances.subtitle":"Here you can manage the respective Battery Model of this Substance Recycling Share.",
        "substances-recycling-share-editor.view.tabs.json" : "JSON",
        "substances-recycling-share-editor.view.subheader.errorm" : "This Battery Substance in combination with this Battery Model already exists."
        
    },

    "de" : {
        "substances-recycling-share-editor.view.title" : "Wiederverwertbarkeitsanteil {name}",
        "substance-recycling-shares-editor.view.title" : "Neuen Wiederverwertbarkeitsanteil anlegen",
        "substance-recycling-shares-editor.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Wiederverwertbarkeitsanteils.",
        "substance-recycling-share-editor.view.subheader.model" : "Batterie-Modell",
        "substance-recycling-share-editor.view.subheader.substance" : "Batterie-Element",
        "battery-model-editor.view.fields.schema.validation.notEmpty" : "Bitte ordnen Sie dem Wiederverwertbarkeitsanteil ein Batterie-Modell zu.",
        "battery-substance-editor.view.fields.schema.validation.notEmpty" : "Bitte ordnen Sie dem Wiederverwertbarkeitsanteil ein Batterie-Element zuordnest.",
        "substance-recycling-share-editor.view.subheader.primaryMaterialShare" : "Material Anteil",
        "substance-recycling-share-editor.view.subheader.preConsumerShare" : "Materialanteil vor Nutzung",
        "substance-recycling-share-editor.view.subheader.postConsumerShare" : "Materialanteil nach Nutzung",
        "substances-recycling-share-editor.view.tabs.general" : "Allgemein",
        "substances-recycling-share-editor.view.tabs.settings" : "Einstellungen",
        "substances-recycling-share-editor.view.sections.values.title":"Anteile",
        "substances-recycling-share-editor.view.sections.values.subtitle":"Hier können Sie die zu diesem Wiederverwertbarkeitsanteil gehörigen Anteile verwalten.",
        "substances-recycling-share-editor.view.sections.values.pre.title":"Materialanteil vor Nutzung",
        "substances-recycling-share-editor.view.sections.values.pre.subtitle":"Der Wiederverwertbarkeitsanteil vor der Nutzung der Batterie durch den Nutzer.",
        "substances-recycling-share-editor.view.sections.values.post.title":"Materialanteil nach Nutzung",
        "substances-recycling-share-editor.view.sections.values.post.subtitle":"Der Wiederverwertbarkeitsanteil nach der Nutzung der Batterie durch den Nutzer.",
        "substances-recycling-share-editor.view.sections.values.prim.title":"Material Anteil",
        "substances-recycling-share-editor.view.sections.values.prim.subtitle":"Der tatsächliche wiedervertbare Anteil des Elementes in der Batterie.",
        "substances-recycling-share-editor.view.sections.props.title":"Eigenschaften",
        "substances-recycling-share-editor.view.sections.props.subtitle":"Hier können Sie die Eigenschaften des Wiederverwertbarkeitsanteils verwalten.",
        "substances-recycling-share-editor.view.sections.props.models.title":"Batterie-Model",
        "substances-recycling-share-editor.view.sections.props.models.subtitle":"Hier können Sie das Batterie-Model des Wiederverwertbarkeitsanteils festlegen.",
        "substances-recycling-share-editor.view.sections.props.substances.title":"Batterie-Element",
        "substances-recycling-share-editor.view.sections.props.substances.subtitle":"Hier können Sie das Batterie-Element des Wiederverwertbarkeitsanteils festlegen.",
        "substances-recycling-share-editor.view.tabs.json" : "JSON",
        "substances-recycling-share-editor.view.subheader.errorm" : "Dieses Batterie-Element mit diesem Batterie-Modell existiert bereits."
    }
}
</i18n>