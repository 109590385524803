<template>
  <v-text-field 
    v-model="text"
    :label="$t('name-text-field.component.label')" 
    :hint="$t('name-text-field.component.hint')"  
    :rules="validators" 
    filled />
</template>

<script>

export default {

    props: {
        value: {
            type: String,
            required: true,
        },
    },

    data() {

        return {
            textInternal: this.value ? this.value : ''
        }
    },

    methods: {

        fireInput() {
            this.$emit('input', this.textInternal);
        }
    },

    computed: {

        validators() {
            return [
                v => !!v || this.$t('name-text-field.component.msgs.stringNotEmpty', this.$i18n.locale),
                v => (v && v.match(/^[0-9a-zA-Z__\\-]+$/)) || this.$t('name-text-field.component.msgs.stringNotAlphanumeric', this.$i18n.locale), 
            ];
        },

        text: {

            get() {
                return this.textInternal;
            },

            set(text) {

                this.textInternal = text;
                this.fireInput();
            }
        }
    },


}
</script>
