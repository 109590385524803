import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import api from '@/store/api';

export default {

    namespaced : true,

    state: {

        collectionSettings: null,
        schema: null,

        filter: {
            filterQuery  : '*',
            filterQueryLanguage: 'SIMPLE',
            facetFilters : [],
            rangeFilters : []
             
        },

        pagination: {},

        measure: null,
        aggregation: null,
        color: null,

        measureAttribute: null,

        groupingAttributes: [],

        rowAttributes: [],

        maxNumCols: 50,

        maxNumRows: 5,

        data: [],

        meta: {},

        facets: {},

        chartType: 'COLUMN_CHART',

        formSent: false,

    },

    mutations: {

        setCollectionSettings(state, response) {
            state.collectionSettings = get(response, 'collectionSettings', {});
            state.schema = get(response, 'schema', {});
        },

        setMeasure(state, measure) {
            state.measure = measure;
        },

        setAggregation(state, aggregation) {
            state.aggregation = aggregation;
        },

        setColor(state, color) {
            state.color = color;
        },

        setFormSent(state, sent) {
            state.formSent = sent;
        },

        setResponse(state, response) {

            state.data = get(response, 'data', []); 
            state.meta = get(response, 'meta', {}); 
            state.facets = get(response, 'facets', {}); 
            
            state.filter = get(response, 'filter', {
                filterQuery  : '*',
                filterQueryLanguage: 'SIMPLE',
                facetFilters :[],
                rangeFilters :[]
            });

            state.pagination = get(response, 'pagination', {}); 

            state.measureAttribute   = get(response, 'measureAttribute', null); 
            state.groupingAttributes = get(response, 'columnAttributes', []);
            state.rowAttributes      = get(response, 'rowAttributes', []);
        },

        setFilterQuery(state, { filterQuery, filterQueryLanguage}) {
            state.filter.filterQuery = filterQuery ? filterQuery : '*';
            state.filter.filterQueryLanguage = filterQueryLanguage ? filterQueryLanguage  : 'SIMPLE';
        },

        setFacetFilters(state, filters) {
            state.filter.facetFilters = filters.facetFilters ? filters.facetFilters : [];
            state.filter.rangeFilters = filters.rangeFilters ? filters.rangeFilters : [];
        },

        setMeasureAttribute(state, measureAttribute) {
            // Reset the measure attributes list.
            state.measureAttribute = (measureAttribute) ? measureAttribute : null;
        },

        setGroupingAttributes(state, groupingAttributes) {
            // Reset the grouping attributes list.
            state.groupingAttributes = [];

            for (let groupingAttribute of groupingAttributes) {
                state.groupingAttributes.push(groupingAttribute);
            }
        },

        setRowAttributes(state, rowAttributes) {
            state.rowAttributes = [];

            for (let rowAttribute of rowAttributes) {
                state.rowAttributes.push(rowAttribute);
            }
        },

        setMaxNumCols(state, maxNumCols) {
            state.maxNumCols = (maxNumCols > 0) ? maxNumCols : 50;
        },

        setMaxNumRows(state, maxNumRows) {
            state.maxNumRows = (maxNumRows > 0) ? maxNumRows : 5;
        }        
    },

    actions: {

        async loadCollectionSettings(context, { collectionId }) {
            return api.collections.findById(collectionId).then(response => {
                context.commit('setCollectionSettings', response);
            });
        },

        async loadData(context, {collectionId}) {
            const formValid = context.getters.formValid;
            if (formValid) {
                const request = context.getters.request;
                return api.collections.series.getSeries(collectionId, request).then(response => {
                    context.commit('setResponse' , response)
                    context.commit('setFormSent', true);
                });
            } 
        },

        async resetData(context) {
            // The empty object will be handled be the setter accordingly,
            // so the state will return to default values.
            context.commit('setResponse', {});
        }, 

        async updateFilterQuery(context, {collectionId, filterQuery}) {
            context.commit('setFilterQuery', filterQuery);
            return context.dispatch('loadData', {collectionId});
        },

        async updateFacetFilters(context, {collectionId, facetFilters}) {
            context.commit('setFacetFilters', facetFilters);
            return context.dispatch('loadData', {collectionId});
        },

        async export(context, {collectionId, format}) {
            const request = {series: context.getters.request, format: format};
            const formValid = context.getters.formValid;

            if (formValid) {
                return api.collections.series.exportSeries(collectionId, request).then(() => {});
            }
        },
    },

    getters: {
        collectionSettings(state) {
            return state.collectionSettings;
        },

        schema(state) {
            return state.schema;
        },

        measure(state) {
            return state.measure;
        },

        aggregation(state) {
            return state.aggregation;
        },

        color(state) {
            return state.color;
        },

        formSent(state) {
            return state.formSent;
        },

        formValid(state) {
            return  !isEmpty(state.measure) && 
                    !isEmpty(state.aggregation) &&
                    !isEmpty(state.color) &&
                    !isEmpty(state.filter) && 
                    !isEmpty(state.groupingAttributes)
        },

        request(state) {
            return { 
                filter: state.filter,
                measureAttribute: {
                    attribute: state.measure.name,
                    customData: {
                        chartType: "BAR_CHART",
                        color: state.color.value,
                    },
                    operation: state.aggregation.name
                },
                columnAttributes: state.groupingAttributes,
                rowAttributes: state.rowAttributes,
                maxNumCols: state.maxNumCols,
                maxNumRows: state.maxNumRows
            };
        },

        filter(state) {
            
            return state.filter;  
        },

        response(state) {

            // Enrich the original state with additional info for
            // visualization.
            let data = {
                labels : state.data && state.data.labels ? state.data.labels : [],
                series: [],
                color: get(state, 'color.value', '')
            };

            if (state.data && state.data.series) {

                // Clone the actual items in order to circumvent unintentional modification of 
                // state.
                for (let s of state.data.series) {

                    let copy = cloneDeep(s);
                    copy.chartType = state.chartType;

                    const colorMaxBrightnessValue = '#f9f9f9';
                    copy.color = get(state, 'color.value', colorMaxBrightnessValue)
                
                    if (copy.series && copy.series.length > 0) {
                        copy.series[0].color = copy.color;
                        for (let i = 1; i < copy.series.length; i++) {
                            const color = shadeColor(copy.series[i - 1].color, 19);
                            copy.series[i].color = checkColorBrighterThan(color, colorMaxBrightnessValue) ? copy.color : color;
                        }
                    }
                    

                    data.series.push(copy);
                }
            }

            return {

                data    : data,
                meta    : state.meta,
                facets  : state.facets,
                filter  : state.filter,
                pagination: state.pagination,
            };
        },

        viewSettings(state) {

            return {
                chartType : state.chartType
            }
        },

        measureAttributes(state) {
            return state.measureAttribute;
        },

        groupingAttributes(state) {
            return state.groupingAttributes;
        },

        rowAttributes(state) {
            return state.rowAttributes;
        },

        maxNumRows(state) {
            return state.maxNumRows;
        },

        maxNumCols(state) {
            return state.maxNumCols;
        }
    }

};

function shadeColor(color, percent) {
    var num = parseInt(color.replace("#",""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = (num >> 8 & 0x00FF) + amt,
      G = (num & 0x0000FF) + amt;

    return '#' + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
}

function checkColorBrighterThan(colorValue, colorMaxBrightnessValue) {
    return (getBrightnessByColor(colorValue) > getBrightnessByColor(colorMaxBrightnessValue));
} 

function getBrightnessByColor(color) {
    var m = color.substr(1).match(/(\S{2})/g);
    var r = parseInt(m[0], 16), 
        g = parseInt(m[1], 16), 
        b = parseInt(m[2], 16);
    if (typeof r != "undefined") return ((r*299)+(g*587)+(b*114))/1000;
}