import { floor, get } from "lodash";

export const Charts = {

  
chartmodel(aggregations, documents, lastRequest) {
    let timeDimension = get(lastRequest, 'timeDimensionAttribute', 'Time Dimension Attribute not found');
    let measure = get(lastRequest, 'measureAttribute', 'Measure Attribute not found');
    let items = []
    for(let document of documents){
        let documentData = get(document, 'data', {});
        let timeStamp = get(documentData, timeDimension, 'Time Dimension not found');
        let value = get(documentData, measure, 'Measure not found')
        items.push({dateTime : timeStamp, values: {average: aggregations['average'],
                                                        max: aggregations['max'], 
                                                        min: aggregations['min'],
                                                        value: value}});
    }
    return {
        items: items,
        series: {
            value: {
                name: 'value',
                  i18n: {
                      'en' : {
                          name: 'Actual Value'
                      },
    
                      'de' : {
                          name: 'Ist-Wert'
                      }
                  },
                  visualSettings: { line: {color: '#205072' }, chartType: 'line' }
              },
            average: {
              name: 'average',
                i18n: {
                    'en' : {
                        name: 'Average'
                    },
  
                    'de' : {
                        name: 'Durchschnitt'
                    }
                },
                visualSettings: { line: {color: '#FBC78D' } }

            },
            max: {
              name: 'max',
                i18n: {
                    'en' : {
                        name: 'Maximum'
                    },
  
                    'de' : {
                        name: 'Maximum'
                    }
                },
                visualSettings: { line: {color: '#A7D676' } }
            },
            min: {
              name: 'min',
                i18n: {
                    'en' : {
                        name: 'Minimum'
                    },
  
                    'de' : {
                        name: 'Minimum'
                    }
                },
                visualSettings: { line: {color: '#85CBCC' } }
            },
            
        }
      }
  },

  boxPlotChartmodel(quartiles){
    let items = []
    let q1 = quartiles["25.0"];
    let q2 = quartiles["50.0"];
    let q3 = quartiles["75.0"];

    items.push({ name: "", values : { q1: floor(q1, 3), q2: floor(q2, 3),  q3: floor(q3, 3)  } });

    return items
    
  }

} 