import { render, staticRenderFns } from "./battery-pack-wizard.view.vue?vue&type=template&id=f90b38ae&scoped=true"
import script from "./battery-pack-wizard.view.vue?vue&type=script&lang=js"
export * from "./battery-pack-wizard.view.vue?vue&type=script&lang=js"
import style0 from "./battery-pack-wizard.view.vue?vue&type=style&index=0&id=f90b38ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f90b38ae",
  null
  
)

/* custom blocks */
import block0 from "./battery-pack-wizard.view.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports