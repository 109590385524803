<template>
    <v-sheet  color="secondary">

        <c-section :title="$t('user-groups-list.view.sections.list.title')" :subtitle="$t('user-groups-list.view.sections.list.subtitle')" class="secondary">

            <v-card class="mt-5 mb-5" tile>
                <c-item-list 
                    :title="$t('user-groups-list.view.toolbar.title')" 
                    :items="items" 
                    :pagination="pagination"
                    :disable-add-item="!hasCreateRole" 
                    :disable-edit-item="!hasEditRole && !hasReadRole" 
                    :disable-delete-item="!hasDeleteRole"
                    @refresh-list="refresh"
                    @previous-page="onPreviousPage"
                    @next-page="onNextPage"
                    @item-added="onItemAdded" 
                    @item-edited="onItemEdited"  
                    @item-list-filter-applied="onFilterChanged"
                    :supports-delete-item="false"
                    :supports-add-item="false"

                >
                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('user-groups-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('user-groups-list.view.help.description')}}</v-card-text>
            </v-card>
        </c-section>
        
    </v-sheet>


</template>

<script>
import Roles from '@/roles';

const MODULE_NAME = 'userGroupsList';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        items() {
            return this.response.items;
        },

        pagination() {
            return this.response.pagination;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.USER_GROUPS_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.USER_GROUPS_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.USER_GROUPS_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.USER_GROUPS_DELETE);
        }
    },

    methods: {

        async refresh() {
            this.loadPage(0, 50);
        },

        async onNextPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse", {from, pageSize});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemEdited(event) {

            if (event) {
                this.$router.push({
                    name: 'userGroupsDetail',
                    params: {
                        name : event.item.name,
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to schema editor.")
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemAdded() {
            this.$router.push({ name: 'mappingJobTemplatesWizard'});
        }
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {

        "user-groups-list.view.sections.list.title" : "User Groups",
        "user-groups-list.view.sections.list.subtitle" : "Overview for all user groups",
        "user-groups-list.view.toolbar.title" : "User Groups",


        "user-groups-list.view.help.title" : "What are User Groups?",
        "user-groups-list.view.help.description" : "Users can be grouped together in groups in order to manage user privileges more efficiently."

    },

    "de" : {

        "user-groups-list.view.sections.list.title" : "Benutzergruppen",
        "user-groups-list.view.sections.list.subtitle" : "Übersicht der verfügbaren Benutzergruppen",
        "user-groups-list.view.toolbar.title" : "Benutzergruppen",

        "user-groups-list.view.help.title" : "Was sind Benutzergruppen?",
        "user-groups-list.view.help.description" : "In Benutzergruppen werden verschiedene Benutzer zum Zweck der effizienten Verwaltung von Benutzerrechten zusammengefasst."
    }
}
</i18n>