import api from '@/store/api';
import { set } from 'lodash';
export default {

    namespaced: true,

    state: {

        accessControlList: createEmptyAccessControlList()
    },

    mutations: {

        setAccessControlList(state, accessControlList) {

            if (accessControlList) {
                state.accessControlList.name = accessControlList.name ? accessControlList.name : null;
            } else {
                state.accessControlList.name = null;
            }

        },   


    },

    actions: {
    
        async createAccessControlList(context) {

            try {
                
                let request = context.getters.request;
                await api.userMgmt.accessControlLists.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetAccessControlList(context) {
            context.commit('setAccessControlList', createEmptyAccessControlList());
        },

        async updateAccessControlList(context, accessControlList) {
            
            try {
                context.commit('setAccessControlList', accessControlList);
            } catch (error) {
                return Promise.reject(error);
            }
        },

    },



    getters: {

        
        request(state) {
            let payload = state.accessControlList;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        accessControlList(state) {
            return state.accessControlList ? state.accessControlList : createEmptyAccessControlList();
        }
    }
}

function createEmptyAccessControlList() {

    return {
        
        "name": null,
        
        "i18n": {
        },

        "collectionAccess": {
          "collectionAccessRules": []
        },

        "creationDateTime": null,
        "creationUser": null,
        "modificationDateTime": null,
        "modificationUser": null,

      }
}