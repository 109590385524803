<template>
    <v-tabs 
        v-model="selectedTab"
        centered 
        align-with-title
        grow
        color="grey" 
        background-color="grey lighten-3">
        
        <v-tab>{{$t('webhook-expression-help.component.tab.context')}}</v-tab>
        <v-tab>{{$t('webhook-expression-help.component.tab.functions')}}</v-tab>

        <v-tabs-items v-model="selectedTab">

            <!-- Context-->
            <v-tab-item>
                <v-simple-table >
                    <thead>
                        <tr>
                            <th>{{$t('webhook-expression-help.component.tab.field')}}</th>
                            <th>{{$t('webhook-expression-help.component.tab.datatype')}}</th>
                            <th>{{$t('webhook-expression-help.component.tab.note')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="3" class="grey lighten-3">Webhook</td>
                        </tr>
                        <tr>
                            <td><code>$webhook.webhookDateTime</code></td>
                            <td>DATETIME</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><code>$webhook.webhookId</code></td>
                            <td>STRING</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">Webhook-Event</td>
                        </tr>
                        <tr>
                        <tr>
                            <td><code>$event.eventType</code></td>
                            <td>STRING</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><code>$event.data</code></td>
                            <td>MAP</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><code>$event.eventDateTime</code></td>
                            <td>DATETIME</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><code>$event.eventId</code></td>
                            <td>STRING</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">Webhook Subscription</td>
                        </tr>
                        <tr>
                            <td><code>$webhookSubscription.name</code></td>
                            <td>STRING</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><code>$webhookSubscription.custParams</code></td>
                            <td>MAP</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">Tenant Context</td>
                        </tr>
                        <tr>
                            <td><code>$tenant.tenantId</code></td>
                            <td>STRING</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><code>$tenant.stage</code></td>
                            <td>STRING</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><code>$tenant.custParams</code></td>
                            <td>MAP</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="3" class="grey lighten-3">Time Context</td>
                        </tr>
                        <tr>
                            <td><code>$now</code></td>
                            <td>DATETIME</td>
                            <td></td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-tab-item>

            <!-- Functions -->
            <v-tab-item>
                <dxs-webhook-expression-functions-help/>
            </v-tab-item>
        </v-tabs-items>
    </v-tabs>
</template>

<script>
import WebhookFunctionsHelpComponent from './webhook-functions-help.component';


//import { get } from 'lodash';

export default {

    name: 'webhook-expression-help',

    props: {

    },

    data: () => ({
        selectedTab: 0
    }),
    components: {
      'dxs-webhook-expression-functions-help' : WebhookFunctionsHelpComponent
    },
    methods: {

        isNumeric(attribute) {
            return (attribute.datatype === 'DECIMAL' || attribute.datatype === 'INTEGER');
        }
    },

    computed: {


    }
}
</script>

<i18n>
 {
     "en": {
        "webhook-expression-help.component.tab.context" : "Context",
        "webhook-expression-help.component.tab.functions" : "Functions",
        "webhook-expression-help.component.tab.field" : "Field",
        "webhook-expression-help.component.tab.description" : "Description",
        "webhook-expression-help.component.tab.datatype" : "Datatype",
        "webhook-expression-help.component.tab.note" : "Note"

       
     },

     "de" : {
        "webhook-expression-help.component.tab.context" : "Kontext",
        "webhook-expression-help.component.tab.functions" : "Funktionen",
        "webhook-expression-help.component.tab.field" : "Feld",
        "webhook-expression-help.component.tab.description" : "Beschreibung",
        "webhook-expression-help.component.tab.datatype" : "Datentyp",
        "webhook-expression-help.component.tab.note" : "Erläuterung"
     }
 }
</i18n>