import api from '@/store/api';

export default {

    namespaced: true,

    state: {

        response: {

            collectionSettings: null,
            schema: null,
            scenario: null,
            models: []
        },
    },

    mutations: {

        response(state, response) {
            if (response) {
                state.response = response
            }
        },

    },

    actions: {


        async findPredictionScenarioById(state, {scenarioId}) {

            try {

                let response = await api.prediction.findPredictionScenarioById(scenarioId);
                state.commit('response', response);

            } catch (error) {

                console.log("An error has occured while fetching prediction model definition with modelId %s " + error, scenarioId);

                // Pass an empty response so nothing will be added actually.
                state.commit('response', null);
            }
        },



        async updateI18n(state, i18n) {
            try {

                let response = await api.prediction.updateI18n(state.getters.model.modelId, i18n);
                state.commit('response', response);

            } catch (error) {

                console.log("An error has occured while updating i18n." + error);
            }
        },

        async deletePredictionModelConfiguration(context, configurationId) {
            try {

                let modelId = context.getters.model.modelId;
                await api.prediction.deletePredictionModelConfiguration(modelId, configurationId);
                context.dispatch("findModelById", {modelId})

            } catch (error) {

                console.log("An error has occured while creating prediction model definition" + error);
            }
        },

    },

    getters: {

        response(state) {
            let response = state.response;
            return response ? response : {};
        },

        collectionSettings(state, getters) {
            let collectionSettings =  getters.response.collectionSettings;
            return collectionSettings ? collectionSettings : {};

        },

        schema(state, getters) {
            let schema =  getters.response.schema;
            return schema ? schema : {};

        },

        scenario(state, getters) {
            let scenario =  getters.response.scenario;
            return scenario ? scenario : {};

        },

        models(state, getters) {
            let models =  getters.response.models;
            return models ? models : [];

        },

        i18n(state, getters) {
            let i18n = getters.scenario.i18n;
            return i18n ? i18n : {};
        },
    }
}