import api from '../../../api/battery-data-hub.api';
import { get } from 'lodash';

export default {

    namespaced: true,

    state: {

        batteryMaterial: createDefaultBatteryMaterial(),
        casIdentifier: null,
        hazards:{items:[]}
    },

    mutations: {
        setHazardousSubstanceClassifications(state,hazards){
            console.log(hazards);
            console.log("********************hazards");
            if (hazards) {
                let items = [];
                if (hazards.items) {
                    state.hazards.items = hazards.items;
                } else {
                    state.hazards.items = items;
                }
    
                if (hazards.tenantId) {
                    state.hazards.tenantId = hazards.tenantId;

                }
    
                if (hazards.pagination) {
                    state.hazards.pagination = hazards.pagination;
                }
    
            }
        },
        setHazardousSubstanceClassification(state,hazard){
            if (hazard) {
                state.batteryMaterial.references.hazardoussubstanceclassification = hazard
            }
        },
        setBatteryMaterial(state, batteryMaterial) {

            console.log("=====================================")
            console.log(JSON.stringify(batteryMaterial, true, 2))
            console.log("=====================================")

            if (batteryMaterial) {
                state.batteryMaterial.creationDateTime = batteryMaterial.creationDateTime ? batteryMaterial.creationDateTime : null;
                state.batteryMaterial.creationUser = batteryMaterial.creationUser ? batteryMaterial.creationUser : null;
                state.batteryMaterial.modificationDateTime = batteryMaterial.modificationDateTime ? batteryMaterial.modificationDateTime : null;
                state.batteryMaterial.modificationUser = batteryMaterial.modificationUser ? batteryMaterial.modificationUser : null;
                state.batteryMaterial.tenantId = batteryMaterial.tenantId ? batteryMaterial.tenantId : null;
                state.batteryMaterial.name = batteryMaterial.name ? batteryMaterial.name : null;
                state.batteryMaterial.i18n = batteryMaterial.i18n ? batteryMaterial.i18n : {};
                state.batteryMaterial.active = batteryMaterial.active ? batteryMaterial.active : true;
                state.batteryMaterial.references = batteryMaterial.references ? batteryMaterial.references : {};
                state.batteryMaterial.data.casIdentifier = batteryMaterial.data.casIdentifier ? batteryMaterial.data.casIdentifier : null;
            }

        },
        setCasIdentifier(state, casIdentifier) {
            if (casIdentifier) {
                state.batteryMaterial.data.casIdentifier = casIdentifier;
            }
        }

    },



    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.batteryMaterials.findById(name);
                let hazards = await api.masterdata.hazardousSubstanceClassifications.findByFilter('*', 0, 100);
                await context.commit('setBatteryMaterial', get(response, 'item.batteryMaterial', createDefaultBatteryMaterial()));
                await context.commit('setHazardousSubstanceClassifications', hazards);
            } catch (error) {
                context.commit('setBatteryMaterial', createDefaultBatteryMaterial());
                context.commit('setHazardousSubstanceClassifications', null);
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters, 'batteryMaterial');

                let response = await api.masterdata.batteryMaterials.update(payload);
                context.commit('setBatteryMaterial', get(response, 'item.batteryMaterial', createDefaultBatteryMaterial()));

            } catch (error) {
                console.log(error);
                context.commit('setBatteryMaterial', createDefaultBatteryMaterial());
                throw error;

            }
        },
        async updateHazard(context, hazard){
            context.commit('setHazardousSubstanceClassification', hazard);
        },

        async updateBatteryMaterial(context, batteryMaterial) {
            context.commit('setBatteryMaterial', batteryMaterial, createDefaultBatteryMaterial());
        },

        async updateCasIdentifier(context, casIdentifier) {
            context.commit('setCasIdentifier', casIdentifier);
        }
    },

    getters: {

        batteryMaterial(state) {
            return state.batteryMaterial;

        },
        casIdentifier(state) {
            return state.batteryMaterial.data.casIdentifier;
        },
        hazardousSubstanceClassifications(state){
            return state.hazards;
        },
        hazard(state){
            return state.batteryMaterial.references.hazardoussubstanceclassification;
        }

    }
}

function createDefaultBatteryMaterial() {
    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "casIdentifier": null
        },
        "active": true,
        "references": {
            hazardoussubstanceclassification:null
        }

    }
}