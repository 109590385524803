<template>
    <v-sheet>
  
          <!-- ****************************** -->
          <!-- EDITOR                         -->
          <!-- ****************************** -->
          <c-item-editor-form 
              :title="$t('manufacturers-editor.view.title', { name: name })" 
              :value="model"
              @update-confirmed="save"
              @update-canceled="cancel"
              :editable="hasEditRole"
              >
              
              <template v-slot:default>
                  <v-sheet color="grey lighten-3">
                      
                      <v-tabs 
                          v-model="selectedTab" 
                          :vertical="$vuetify.breakpoint.mdAndUp" 
                          color="grey" 
                          :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
                          slider-color="grey" 
                          show-arrows 
                          :fixed-tabs="!$vuetify.breakpoint.mdAndUp">
                          
  
                          <!-- Tabs -->
                          <v-tab :key="0">{{$t('manufacturers-editor.view.tabs.general')}}</v-tab>
                          <v-tab :key="1">{{$t('manufacturers-editor.view.tabs.json')}}</v-tab>
  
  
                          <v-tabs-items v-model="selectedTab">
  
                              <!-- General -->
                              <v-tab-item :key="0">
                                  <c-name-and-i18n-item-editor-page v-model="manufacturers" />
                              </v-tab-item>
  
                              <!-- JSON -->
                              <v-tab-item :key="1">
                                  <c-json-item-editor-page v-model="model" />
                              </v-tab-item>
  
                          </v-tabs-items>
  
                      </v-tabs>
                  </v-sheet>
               
                  
                   
              </template>
          </c-item-editor-form>
    </v-sheet>
  </template>
  
  <script>
  import { get } from 'lodash';
  import ManufacturersEditorModule from "../../../store/masterdata/manufacturers/manufacturers-editor.module";
  
  import Roles from '@/roles';
  
  const MODULE_ID = 'ManufacturersEditor';
  
  export default {
      
      name: 'manufacturers-editor',
  
      inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],
  
      props: {
  
          value: {
              type: Object,
              required: true
          }
      },
  
      data: () => ({
          selectedTab : 0
      }),
  
      methods: {
          async refresh() {
              this.progressIndicatorService.show();
              try {
                 this.$log.debug(`Loading information for Battery Substance ${this.name}`);
                  await this.$store.dispatch(MODULE_ID + "/loadData", {name: this.name})
              } catch (error) {
                  this.errorHandlerService.handleError(error);
              } finally {
                  this.progressIndicatorService.hide();
              }
          },
  
  
  
          async save() {
              this.progressIndicatorService.show();
              try {
                 
                  await this.$store.dispatch(MODULE_ID + "/saveData");
              } catch (error) {
                  this.errorHandlerService.handleError(error);
              } finally {
                  this.progressIndicatorService.hide();
              }
          },
  
          cancel() {
      this.$router.push({
        name: "ManufacturersListView",
      });
      }},
  
      computed: {
  
          name: {
  
              get() {
                  return get(this.value, 'name');
              }
          },
  
          hasEditRole() { 
             return this.userContextService.hasRole(Roles.MANUFACTURERS_EDIT);
          },
  
          model: {
  
              get() {
                  return this.$store.getters[MODULE_ID + '/model'];
              },  
              
              set(value) {
                  this.$store.dispatch(MODULE_ID + "/updateModel", value);
              }
          },
  
          manufacturers: {
              get() {
                  return get(this.model, 'manufacturers');
              },
  
              set(value) {
                  this.$store.dispatch(MODULE_ID + "/updateManufacturers", value);
              }
          },
  
          
      },
  
      created() {
  
          // Register the module dynamically with the view.
          if (!this.$store.hasModule(MODULE_ID)) {
              this.$store.registerModule(MODULE_ID, ManufacturersEditorModule);
          }
  
          this.refresh();
          
      }
  }
  </script>
  
  <i18n>
  {
      "en" : {
          "manufacturers-editor.view.title" : "Manufacturers {name}",
  
          "manufacturers-editor.view.tabs.general" : "General",
          "manufacturers-editor.view.tabs.settings" : "Settings",
          "manufacturers-editor.view.tabs.json" : "JSON"
      },
  
      "de" : {
          "manufacturers-editor.view.title" : "Hersteller {name}",
  
          "manufacturers-editor.view.tabs.general" : "Allgemein",
          "manufacturers-editor.view.tabs.settings" : "Einstellungen",
          "manufacturers-editor.view.tabs.json" : "JSON"
      }
  }
  </i18n>