<template>
    <v-card tile :flat="flat">
        <v-card-text>
            <div class="text-h6">{{ title }}</div>
            <div class="text-strong">{{ subtitle }}</div>
        </v-card-text>
        <c-metrics-distribution-chart :items="recyclingShares" type="donut" show-value-label />
        <v-divider />
        <v-container fluid>
            <v-row>
                <v-col cols="12" lg="4">
                    <div class="text-caption grey--text text-right">Total Quantity</div>
                    <div class="text-caption grey--text text-right">(mg)</div>
                    <div class="text-h5 grey--text text-right">{{ batterySubstanceQuantity.totalQuantityMilligram }}</div>
                </v-col>
                <v-col cols="12" lg="4">
                    <div class="text-caption grey--text text-right">Quantity Pre-Consumer</div>
                    <div class="text-caption grey--text text-right">(mg)</div>
                    <div class="text-h5 grey--text text-right">{{ batterySubstanceQuantity.totalQuantityMilligram }}</div>
                </v-col>
                <v-col cols="12" lg="4">
                    <div class="text-caption grey--text text-right">Quantity Post-Consumer</div>
                    <div class="text-caption grey--text text-right">(mg)</div>
                    <div class="text-h5 grey--text text-right">{{ batterySubstanceQuantity.totalQuantityMilligram }}</div>
                </v-col>
            </v-row>

        </v-container>                          

    </v-card>
</template>

<script>
import { get, isEmpty } from 'lodash';

export default {
    
    name: 'substance-recycling-data-tile',

    props: {

        flat: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: Object,
            required: true, 
        }

    },

    computed: {

        substance() {
            return get(this.value, 'substance', {});
        },

        batterySubstanceQuantity(){
            return get(this.value, 'batterySubstanceQuantity', {});
        },

        title() {
            return this.$dxs.i18n.localizedName(this.substance, this.$i18n.locale)
        },

        subtitle() {
            return this.substance.element ? this.substance.element : this.substance.name;
        },

        recyclingShares() {
            const items = [];

            if (!isEmpty(this.batterySubstanceQuantity)){

                const preConsumerShare = this.batterySubstanceQuantity.preConsumerRecyclingShare;
                const postConsumerShare = this.batterySubstanceQuantity.postConsumerRecyclingShare;
                const primaryMaterialShare = 100 - preConsumerShare - postConsumerShare;

                items.push({
                    name: 'pre-consumer-share',
                    i18n: { en: { name: 'Pre-Consumer Share'}},
                    color: '#005155',
                    value: preConsumerShare,
                });
                
                items.push({
                    name: 'post-consumer-share',
                    i18n: { en: { name: 'Post-Consumer Share'}},
                    color: '#7fb1bd',
                    value: postConsumerShare,
                });

                items.push({
                    name: 'primary-material-share',
                    i18n: { en: { name: 'Primary Material Share'}},
                    color: 'lightgray',
                    value: primaryMaterialShare,
                });
            }

            

            return items;
        }
    }

}
</script>