<template>
    <v-card :tile="tile" :flat="flat" height="100%" :class="{'grey lighten-3' : statEmpty}">
       <v-container fluid class="pa-3">
            <v-row>
                <v-col cols="9" class="text-left">
                    <div class="text-h6 grey--text">{{title}}</div>
                    <div class="text-subtitle-1 grey--text" v-if="!statEmpty">
                       {{$t('data-distribution-metric-card.component.subtitle', {min: $dxs.formatting.formatDecimal(minValue, $i18n.locale), 
                       max: $dxs.formatting.formatDecimal(maxValue, $i18n.locale)})}}</div>
                </v-col>
                <v-col cols="3" class="text-right">

                </v-col>
            </v-row>
            
        </v-container>
        
        <template v-if="!statEmpty">
            <v-row>
                <v-col cols="12">
                    <c-box-plot-chart :items="[value]"  layout="horizontal" :showCategoryAxis="false" upper="max" lower="min"/>
                </v-col>
            </v-row>
            <v-divider />
            <v-container fluid>
                <v-row class="pa-3">
                    <v-col cols="4">
                        <div class="text-body-2 grey--text text-left">{{$t('data-distribution-metric-card.component.labels.firstQuartil')}}</div>
                        <div class="text-h5 grey--text text-left">{{$dxs.formatting.formatDecimal(firstQuartil, $i18n.locale)}}</div>
                        
                    </v-col>
                    <v-col cols="4">
                        <div class="text-body-2 grey--text text-left">{{$t('data-distribution-metric-card.component.labels.secondQuartil')}}</div>
                        <div class="text-h5 grey--text text-left">{{$dxs.formatting.formatDecimal(secondQuartil, $i18n.locale)}}</div>
                        
                    </v-col>
                    <v-col cols="4">
                        <div class="text-body-2 grey--text text-left">{{$t('data-distribution-metric-card.component.labels.thirdQuartil')}}</div>
                        <div class="text-h5 grey--text text-left">{{$dxs.formatting.formatDecimal(thirdQuartil, $i18n.locale)}}</div>
                        
                    </v-col>
                </v-row>
                <v-row class="pa-3">
                    <v-col cols="4">
                        <div class="text-body-2 grey--text text-left">{{$t('data-distribution-metric-card.component.labels.minValue')}}</div>
                        <div class="text-h5 grey--text text-left">{{$dxs.formatting.formatDecimal(minValue, $i18n.locale)}}</div>
                        
                    </v-col>
                    <v-col cols="4">
                        <div class="text-body-2 grey--text text-left">{{$t('data-distribution-metric-card.component.labels.maxValue')}}</div>
                        <div class="text-h5 grey--text text-left">{{$dxs.formatting.formatDecimal(maxValue, $i18n.locale)}}</div>
                        
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-else>
            <v-card-text />
            <v-card-text class="grey lighten-3 text-center pa-5">
                <div>
                    <v-icon x-large color="grey lighten-1">domain_disabled</v-icon>
                    <div class="text-subtitle-1 grey--text pt-5">{{$t('data-distribution-metric-card.component.labels.noData.title')}}</div>
                </div>
            </v-card-text>
        </template>
    </v-card>
</template>

<script>
import { get } from 'lodash'
export default {

    props: {

        title: {
            type: String,
            required: true
        },
        
        /**
         * Requires the given structure:
         * {
         *  name: String,
         *  values: {
         *      q1: Number,
         *      q2: Number,
         *      q3: Number,
         *      min: Number,
         *      max: Number
         *  }
         * }
         */
        value : {
            type: Object,
            required: true,
        },

        tile : {
            type: Boolean,
            required: false,
            default: false
        },

        flat : {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        firstQuartil() {
            return get(this.value, 'values.q1',)
        },

        secondQuartil() {
            return get(this.value, 'values.q2',)
        },

        thirdQuartil() {
            return get(this.value, 'values.q3',)
        },

        minValue() {
            return get(this.value, 'values.min',)
        },

        maxValue() {
            return get(this.value, 'values.max',)
        },

        statEmpty() {
            let result = true
            for (let key of Object.entries(this.value.values)) {
                if (key[1] !== 0){
                    result = false
                }
            }
            return result;
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "data-distribution-metric-card.component.labels.firstQuartil" : "First Quartile",
        "data-distribution-metric-card.component.labels.secondQuartil" : "Second Quartile",
        "data-distribution-metric-card.component.labels.thirdQuartil" : "Third Quartile",
        "data-distribution-metric-card.component.labels.minValue" : "Min. Value",
        "data-distribution-metric-card.component.labels.maxValue" : "Max. Value",

        "data-distribution-metric-card.component.subtitle" : "Values between {min} and {max}",
        
        "data-distribution-metric-card.component.labels.noData.title" : "This attribute does not contain any values for display."
    },

    "de" : {
        "data-distribution-metric-card.component.labels.firstQuartil" : "Erstes Quartil",
        "data-distribution-metric-card.component.labels.secondQuartil" : "Zweites Quartil",
        "data-distribution-metric-card.component.labels.thirdQuartil" : "Drittes Quartil",
        "data-distribution-metric-card.component.labels.minValue" : "Minimum",
        "data-distribution-metric-card.component.labels.maxValue" : "Maximum",

        "data-distribution-metric-card.component.subtitle" : "Werte zwischen {min} und {max}",

        "data-distribution-metric-card.component.labels.noData.title" : "Dieses Attribut besitzt keine Werte zur Anzeige."
    }
}
</i18n>