<template>
  <div class="secondary darken-1" >

        <c-section :title="$t('user-info.view.title')" :subtitle="$t('user-info.view.subtitle')" class="secondary">
            
            <v-container fluid class="pt-10 pb-10">
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <v-card tile height="100%">
                            <v-card-title class="text-center">{{$t('user-info.view.sections.userInfo')}}</v-card-title>
                            <v-card-text />
                            <v-card-text class="text-center">
                                <v-avatar size="128">
                                    <img :src="user.pictureUrl" />
                                </v-avatar>
                                <p />
                                <div class="text-h5">                                   
                                    {{user.name}}
                                </div>
                                <div class="text-h6">                                   
                                    {{user.userName}}
                                </div>
                            </v-card-text>
                            
                           
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="text-center">E-Mail</v-list-item-subtitle>
                                        <v-list-item-title class="text-center">{{user.email}}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="4">
                       <v-card tile height="100%">
                            <v-card-title>{{$t('user-info.view.sections.groups')}} ({{userGroups.length}})</v-card-title>
                            <v-divider />
                             <v-simple-table>
                                <tbody>
                                    <tr v-for="group of userGroups" :key="'tr_' + group">
                                        <td>{{group}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card> 
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-card tile >
                            <v-card-title>{{$t('user-info.view.sections.roles')}} ({{roles.length}})</v-card-title>
                            <v-divider />
                             <v-simple-table>
                                <tbody>
                                    <tr v-for="role of roles" :key="'tr_' + role">
                                        <td>{{role}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>

            </v-container>
        </c-section>

  </div>
</template>

<script>
import Vue from 'vue';

export default {

 
    computed: {

        user() {
            /** globals */
            return Vue.$user;
        },

        roles() {
            return this.user.roles;
        },  

        userGroups() {
            return this.user.userGroups;
        }
    },


}
</script>

<i18n>
{
    "en" : {

        "user-info.view.title" : "User Account",
        "user-info.view.subtitle" : "This page contains all information on the user account.",
        "user-info.view.sections.roles" : "Assigned Roles",
        "user-info.view.sections.groups" : "Assigned User Groups",
        "user-info.view.sections.userInfo" : "User Info"

     },

    "de" : {

        "user-info.view.title" : "Benutzerkonto",
        "user-info.view.subtitle" : "Diese Seite enthält alle Informationen zum Benutzerkonto.",
        "user-info.view.sections.roles" : "Zugewiesene Rollen",
        "user-info.view.sections.groups" : "Zugewiesene Gruppen",
        "user-info.view.sections.userInfo" : "Allgemeine Informationen"
    }
}
</i18n>