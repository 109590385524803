<template>
    
  <div>
    <c-section
      :title="$t('battery-category-list.view.title')"
      :subtitle="$t('battery-category-list.view.subtitle')"
      class="secondary"
    >
      <v-card class="mt-5 mb-5" tile>
        <c-item-list
          :title="$t('battery-category-list.view.toolbar.title')"
          :items="items"
          :pagination="pagination"
          @refresh-list="refresh"
          @previous-page="onPreviousPage"
          @next-page="onNextPage"
          @item-added="onItemAdded"
          @item-edited="onItemEdited"
          @item-deleted="onItemDeleted"
          @item-list-filter-applied="onFilterChanged"

          :disable-add-item="!hasCreateRole"
          :disable-edit-item="!hasEditRole && !hasReadRole"
          :disable-delete-item="!hasDeleteRole"
          supports-delete-item
        >
        </c-item-list>
        <v-card-text></v-card-text>
        <v-divider />
        <v-subheader class="grey lighten-5">{{
          $t("battery-category-list.view.help.title")
        }}</v-subheader>
        <v-card-text class="grey lighten-5">{{
          $t("battery-category-list.view.help.description")
        }}</v-card-text>
      </v-card>
    </c-section>
  </div>
</template>

<script>
import Roles from "@/roles";
import { get } from "lodash";

import BatteryCategoryListModule from "../../../store/masterdata/battery-categories/battery-categories-list.module"

const MODULE_ID = "BatteryCategoriesList";

export default {
  name: "battery-categories-list",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "userContextService",
  ],

  data: () => ({
    collectionSetting: {},

    displayConfirmationDialog: false,

    displayDeleteDialog: false,
  }),

  computed: {
    response() {
      return this.$store.getters[MODULE_ID + "/response"];
    },

    items() {
      return this.response.items;
    },

    pagination() {
      return get(this.response, "pagination", {});
    },

    hasReadRole() {
      return this.userContextService.hasAllRoles(Roles.BATTERYCATEGORY_READ);
    },

    hasEditRole() {
      return this.userContextService.hasAllRoles(Roles.BATTERYCATEGORY_EDIT);
    },

    hasCreateRole() {
      return this.userContextService.hasAllRoles(Roles.BATTERYCATEGORY_CREATE);
    },

    hasDeleteRole() {
      return this.userContextService.hasAllRoles(Roles.BATTERYCATEGORY_DELETE);
    },
  },

  methods: {
    async refresh() {
      this.loadPage(0, 50);
    },

    async onNextPage({ from, pageSize }) {
      this.loadPage(from, pageSize);
    },

    async onPreviousPage({ from, pageSize }) {
      this.loadPage(from, pageSize);
    },

    async loadPage(from, pageSize) {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_ID + "/loadResponse", {
          from,
          pageSize,
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    onItemEdited(event) {
      if (event) {
        this.$router.push({
          name: "BatteryCategoryEditorView",
          params: {
            name: event.item.name,
          },
        });
      } else {
        this.$log.debug(
          "Event object is undefined. Can't navigate to prediction scenario."
        );
      }
    },

    onItemAdded() {
      this.$router.push({ name: "BatteryCategoryWizardView" });
    },

    async onItemDeleted({ item }) {
      this.progressIndicatorService.show();
      try {
        // Delete the item, then reload the list in order to reflect change.
        await this.$store.dispatch(MODULE_ID + "/deleteItem", item);
        this.refresh();
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    async onFilterChanged(event) {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter);
        await this.refresh();
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    }
  },

  created() {
    // Dynamically register the vuex store module for managing the data.
    if (!this.$store.hasModule(MODULE_ID)) {
      this.$store.registerModule(MODULE_ID, BatteryCategoryListModule);
    }

    this.refresh();
  },
};
</script>

<i18n>
{
    "en" : {
        "battery-category-list.view.title" : "Battery Category Management",
        "battery-category-list.view.subtitle" : "You may use this dialog for creating, editing or deleting Battery Category.",
        "battery-category-list.view.toolbar.title" : "Battery Category",

        "battery-category-list.view.columns.open" : "Open",
        "battery-category-list.view.columns.apis" : "APIs",

        "battery-category-list.view.help.title" : "What are Battery Categories?",
        "battery-category-list.view.help.description" : "A Battery Category could be any kind of classification of a battery; for example: EVBATTERY or MICROBATTERY."

    },

    "de" : {
        "battery-category-list.view.title" : "Verwaltung Batterie-Kategorien",
        "battery-category-list.view.subtitle" : "Sie können neue Batterie-Kategorien anlegen, eine Batterie-Kategorie editieren oder löschen.",
        "battery-category-list.view.toolbar.title" : "Batterie-Kategorien",

        "battery-category-list.view.columns.open" : "Öffnen",
        "battery-category-list.view.columns.apis" : "APIs",

        "battery-category-list.view.help.title" : "Was sind Batterie-Kategorien?",
        "battery-category-list.view.help.description" : "Batterie-Kategorien sind Klassifizierungen von Batterien; Beispielsweise EVBATTERIE oder MIKROBATTERIE."

    }
}
</i18n>