import api from '../../../api/smart-factory.api';
import { get } from 'lodash';

export default {

    namespaced: true,

    state: {
        workplace: createEmptyResponse(),
        tenantId: null,
        sites: [],
        workplaceTypes: []
    },

    mutations: {

        setSiteId(state, siteId) {
            if (!state.workplace.references) {
                state.workplace.references = {};
            }
            state.workplace.references.siteId = siteId;
        },

        setWorkplaceTypeId(state, workplaceTypeId) {
            if (!state.workplace.references) {
                state.workplace.references = {};
            }
            state.workplace.references.workplaceTypeId = workplaceTypeId;
        },


        setWorkplace(state, workplace) {

            if (workplace) {
                state.workplace.tenantId = workplace.tenantId ? workplace.tenantId : null;
                state.workplace.name = workplace.name ? workplace.name : null;
                state.workplace.i18n = workplace.i18n ? workplace.i18n : {};
                state.workplace.data = workplace.data ? workplace.data : { custData: {} };
                state.workplace.active = workplace.active ? workplace.active : true;
                state.workplace.references.siteId = workplace.references.siteId ? workplace.references.siteId : null;
                state.workplace.references.workplaceTypeId = workplace.references.workplaceTypeId ? workplace.references.workplaceTypeId : null;
                state.workplace.creationDateTime = workplace.creationDateTime ? workplace.creationDateTime : null;
                state.workplace.modificationDateTime = workplace.modificationDateTime ? workplace.modificationDateTime : null;
                state.workplace.modificationUser = workplace.modificationUser ? workplace.modificationUser : null;

            } else {
                state.workplace.tenantId = null;
                state.workplace.name = null;
                state.workplace.i18n = null;
                state.workplace.data = null;
                state.workplace.active = null;
                state.workplace.references.siteId = null;
                state.workplace.references.workplaceTypeId = null;
                state.workplace.creationDateTime = null;
                state.workplace.modificationDateTime = null;
                state.workplace.modificationUser = null;
            }

        },
        setSites(state, sites) {
            if (sites) {

                let items = [];
                if (sites.items) {
                    state.sites.items = sites.items;
                } else {
                    state.sites.items = items;
                }

                if (sites.tenantId) {
                    state.sites.tenantId = sites.tenantId;
                }

                if (sites.pagination) {
                    state.sites.pagination = sites.pagination;
                }

            }
        },

        setWorkplaceTypes(state, workplaceTypes) {
            if (workplaceTypes) {

                let items = [];
                if (workplaceTypes.items) {
                    state.workplaceTypes.items = workplaceTypes.items;
                } else {
                    state.workplaceTypes.items = items;
                }

                if (workplaceTypes.tenantId) {
                    state.workplaceTypes.tenantId = workplaceTypes.tenantId;
                }

                if (workplaceTypes.pagination) {
                    state.workplaceTypes.pagination = workplaceTypes.pagination;
                }

            }
        },


    },

    actions: {

        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.workplaces.findById(name);

                context.commit('setWorkplace', get(response, 'item.workPlace', createEmptyResponse()));
            } catch (error) {
                context.commit('setWorkplace', createEmptyResponse());
                throw error;
            }
        },


        async loadSitesAndWorkplaceTypes(context) {

            try {
                let sites = await api.masterdata.sites.findByFilter('*', 0, 100);
                context.commit('setSites', sites);
            } catch (error) {
                context.commit('setSites', []);
                throw error;
            }
            try {
                let workplaceTypes = await api.masterdata.workplaceTypes.findByFilter('*', 0, 100);
                context.commit('setWorkplaceTypes', workplaceTypes);
            } catch (error) {
                context.commit('setWorkplaceTypes', []);
                throw error;
            }

        },


        async saveData(context) {

            try {

                let request = context.getters.workplace;
                await api.masterdata.workplaces.update(request);
            } catch (error) {
                console.log("An error has occured" + error);
                return Promise.reject(error);
            }
        },

        async updateSiteId(context, siteId) {
            try {
                context.commit('setSiteId', siteId);
            } catch (error) {
                return Promise.reject(error);
            }

        },
        async updateWorplaceTypeId(context, workplacetypeId) {
            try {
                context.commit('setWorkplaceTypeId', workplacetypeId);
            } catch (error) {
                return Promise.reject(error);
            }

        },

        async resetWorkplace(context) {
            context.commit('setWorkplace', createEmptyResponse());
        },

        async updateWorkplace(context, workplace) {

            try {
                context.commit('setWorkplace', workplace);
            } catch (error) {
                return Promise.reject(error);
            }
        },

    },




    getters: {

        request(state) {
            let payload = {};

            payload.tenantId = get(state, 'workplace.tenantId');
            payload.name = get(state, 'workplace.name');
            payload.i18n = get(state, 'workplace.i18n');
            payload.data = get(state, 'workplace.data');
            payload.active = get(state, 'workplace.active');
            payload.references = get(state, 'workplace.references');
            payload.creationDateTime = get(state, 'workplace.creationDateTime');
            payload.creationUser = get(state, 'workplace.creationUser');
            payload.modificationDateTime = get(state, 'workplace.modificationDateTime');
            payload.modificationUser = get(state, 'workplace.modificationUser');

            return payload;
        },

        workplace(state) {
            return state.workplace ? state.workplace : createEmptyResponse();
        },

        sites(state) {
            return state.sites ? state.sites : [];
        },

        workplaceTypes(state) {
            return state.workplaceTypes ? state.workplaceTypes : [];
        },

        siteId(state) {
            return get(state, 'workplace.references.siteId');
        },
        workplaceTypeId(state) {
            return get(state, 'workplace.references.workplaceTypeId');
        }

    }
}

function createEmptyResponse() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { "custData": {} },
        "active": true,
        "creationDateTime": null,
        "creationUser": null,
        "modificationDateTime": null,
        "modificationUser": null,
        "references": {
            "workplaceTypeId": null,
            "siteId": null
        }

    }
}
