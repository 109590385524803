import apiV2 from '@/core/api/v2.api';
export default {
    namespaced: true,
    state: {
        response: {},

    },

    mutations: {
        setResponse(state, response) {
            state.response = response;
        },
        resetResponse(state) {
            state.response = {};
        }
       

    },
    actions: {


        async  uploadJson(context, {collectionId, mesg }) {
           
            try {
                let response = await apiV2.pipelines.uploadJson(collectionId, mesg);
                context.commit('setResponse', response);
            }
            catch (error) {
                context.commit('setResponse', emptyResponse());
                return Promise.reject(error);
            }
        }, 
        resetResponse(context) {
            context.commit('resetResponse');
        }

        

    },

    getters: {
        response(state) {
            return state.response;
        },
        

    }


}
function emptyResponse() {

    return {
        count: {
            success: null,
            failed: null
        },
        items: [
            {
                data: {},
                outcome: null,
                messages: []
            }
        ]

    }
}