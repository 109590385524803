import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';
import * as moment from 'moment'
export default {

    namespaced: true,

    state : {
        tenantId : "",
        batteryPack: createEmptyResponse(),
        manufacturerplantid : null,
        manufacturerplant : {"items": []},
        batterystatus : null,
        batterystatuss : {"items": []},
        batteryModel : {"items": []}
    },

    mutations: {
        setBatteryPack(state, batteryPack) {

            if (batteryPack) {
                state.batteryPack.tenantId = batteryPack.tenantId;
                state.batteryPack.name = batteryPack.name;
                state.batteryPack.i18n = batteryPack.i18n;
                state.batteryPack.data = batteryPack.data;
                state.batteryPack.references = batteryPack.references;
                state.batteryPack.active = batteryPack.active;
                state.batteryPack.creationDateTime     = batteryPack.creationDateTime;
                state.batteryPack.creationUser         = batteryPack.creationUser;
                state.batteryPack.modificationDateTime = batteryPack.modificationDateTime;
                state.batteryPack.modificationUser     = batteryPack.modificationUser;
            }
            
        },      
        async setManufacturerplantId(state, manufacturerId) {
            if (manufacturerId) {
                //const payload = await api.masterdata.manufacturers.findById(manufacturerId);
                state.batteryPack.references.manufacturerplantsid = manufacturerId;
                state.manufacturerplantsid = manufacturerId;
            } else {
                state.batteryPack.references.manufacturer = null;
            }
        },
        setManufacturerplants(state, manufacturerplant){ 
            console.log(manufacturerplant)
            console.log("*************************manufacturerplant*************************")
            if (manufacturerplant) {

            let items = [];
            if (manufacturerplant.items) {
                state.manufacturerplant.items = manufacturerplant.items;
            } else {
                state.manufacturerplant.items = items;
            }

            if (manufacturerplant.tenantId) {
                state.manufacturerplant.tenantId = manufacturerplant.tenantId;
            }

            if (manufacturerplant.pagination) {
                state.manufacturerplant.pagination = manufacturerplant.pagination;
            }

        }
        },
        async setbatterystatus(state, batterystatus) {
            console.log("set has been called")
            if (batterystatus) {
                console.log("status has been found in set")
                state.batteryPack.references.batterystatus = batterystatus;
                state.batterystatus = batterystatus;
            } else {
                state.batterystatus = null;
                state.batteryPack.references.batterystatus = null;
            }
        },
        async setBatteryModel(state, batterymodel) {
            if (batterymodel) {
                state.batteryPack.references.batteryModel = batterymodel;
            } else {
                state.batteryPack.references.batteryModel = null;
            }
        },
        async setBatteryModels(state, batterymodel) {
            if (batterymodel) {
                state.batteryModel = batterymodel;
            } else {
                state.batteryPack.references.batteryModel = null;
            }
        },
        async setWeight(state, weight) {
            if (weight) {
                state.batteryPack.data.weightKg = weight;
            } else {
                state.batteryPack.data.weightKg = null;
            }
        },
        async setManufacturingDate(state, batterystatus) {
            console.log("***********************************DATE")
            console.log(batterystatus)
            if (batterystatus) {
                state.batteryPack.data.manufacturingDate = batterystatus;
            } else {
                state.batteryPack.data.manufacturingDate  = null;
            }
        },
        setbatterystatuss(state, batterystatuss){ 
            if (batterystatuss) {
            if (batterystatuss.items) {
                state.batterystatuss.items = get(batterystatuss,"items",[]);
            } else {
                let items = [];
                state.batterystatuss.items = items;
            }

            if (batterystatuss.tenantId) {
                state.batterystatuss.tenantId = batterystatuss.tenantId;
            }

            if (batterystatuss.pagination) {
                state.batterystatuss.pagination = batterystatuss.pagination;
            }

        }
        },

        setTenantId(state, tenantId) {
            console.log("****************TenantId******************")
            console.log(tenantId)
            if (tenantId) {
                state.tenantId = tenantId;
                state.batteryPack.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        },
        

    },

    actions:{

        async loadData(context) {

            try {
                let manufacturerplant = await api.masterdata.manufacturerplant.findByFilter('*', 0, 100);
                let batterystatus = await api.masterdata.batteryStatus.findByFilter('*', 0, 100);
                let batteryModel = await api.masterdata.batteryModels.findByFilter('*', 0, 100);

                
                await context.commit('setManufacturerplants', manufacturerplant);
                await context.commit('setbatterystatuss', batterystatus);
                await context.commit('setBatteryModels', batteryModel);
            } catch (error) {
                context.commit('setManufacturerplants', createEmptyResponse());
                context.commit('setbatterystatuss', createEmptyResponse());
                await context.commit('setBatteryModels', createEmptyResponse());
                throw error;
            }
        },


        async createBatteryPack(context) {

            try {
                
                let request = context.getters.request;
                console.log(request)
                request.data.manufacturingDate = request.data.manufacturingDate ? request.data.manufacturingDate : moment().format("YYYY-MM-DD")
                
                await api.masterdata.batteryPacks.create(request);
            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async updateManufacturerplantId(context, manufacturerId) {
            try {
                context.commit('setManufacturerplantId', manufacturerId);
            } catch (error) {
                return Promise.reject(error);
            }

        },
       
        async resetBatteryPack(context) {
            await context.commit('setBatteryPack', createEmptyResponse());
            await context.commit('setbatterystatus', null);
            await context.commit('setBatteryModel', null);
        },

        async updateBatteryPack(context, batteryPack) {

            try {
                context.commit('setBatteryPack', batteryPack);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async updatebatterystatus(context, batterystatus) {
            console.log("update has been called.")
            try {
                context.commit('setbatterystatus', batterystatus);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async updateBatteryModel(context, batteryModel) {

            try {
                context.commit('setBatteryModel', batteryModel);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async updateManufacturingDate(context, batterystatus) {

            try {
                context.commit('setManufacturingDate', batterystatus);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateBatteryPacks(context, batteryPack) {

            try {
                context.commit('setBatteryPack', batteryPack);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        async updateWeight(context, weight) {

            try {
                context.commit('setWeight', weight);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        },
        

    },



    getters: {

        request(state) {
            let payload = state.batteryPack;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },
        batteryPack(state) {
            return state.batteryPack ? state.batteryPack : createEmptyResponse();
        },
        manufacturingDate(state){
            return state.batteryPack.data.manufacturingDate ? state.batteryPack.data.manufacturingDate : null
        },
        weight(state){
            return state.batteryPack.data.weightKg;
        },
        model(state) {
                 return {
                    batteryPack: state.batteryPack
            }
        },
        manufacturerplants(state) {
            return state.manufacturerplant ? state.manufacturerplant : {items:[]};
        },
        batterystatuss(state) {
            return state.batterystatuss ? state.batterystatuss : {items:[]};
        },

        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },
        manufacturerplantid(state) {
            return get(state, 'batteryPack.reference.manufacturerplantsid',get(state,'manufacturerplantid'));
        },
        batterystatus(state) {
            return get(state, 'batteryPack.reference.batterystatus',get(state,'batterystatus'));
        },
        
        batteryModel(state){
            return state.batteryModel? state.batteryModel : [];
        }
    }
}


function createEmptyResponse() {
    return {
        tenantId: null,
        name: null,
        i18n: {},
        data: {
            batteryPassportId: null,
            manufacturerSerialId: null,
            manufacturingDate: null,
            weightKg:null},
        active: true,
        references: {
            manufacturerplantsid : null,
            batterystatus : null},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }}
