<template>
    <v-sheet class="secondary darken-1" >
        
        <c-section :title="$t('document.view.title', $i18n.locale)" :subtitle="$t('document.view.subtitle', $i18n.locale)" class="secondary">
          
            <v-tabs v-model="selectedTab" background-color="secondary" fixed-tabs>

                <v-tab :key="0">{{$t('document.view.tabs.dashboard.title')}}</v-tab>
                <v-tab :key="1">{{$t('document.view.tabs.json.title')}}</v-tab>

                <v-tabs-items v-model="selectedTab">
                    <v-tab-item :key="0">
                        <!-- Document Information -->
                        <v-container fluid  class="grey lighten-3"> 
                            <v-row justify="center" >

                                <v-col :cols="12" :md="4" :xl="3">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6">{{$t('document.view.tiles.commonInfo.title', $i18n.locale)}}</div>
                                        </v-card-text>
                                        <v-subheader>{{$t('document.view.attributes.title', $i18n.locale)}}</v-subheader>
                                        <v-card-text>
                                            {{document | localizedName($i18n.locale)}}
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader>{{$t('document.view.attributes.id', $i18n.locale)}}</v-subheader>
                                        <v-card-text>
                                            {{document.id}}
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader>{{$t('document.view.attributes.partitionId', $i18n.locale)}}</v-subheader>
                                        <v-card-text>
                                            {{document.partitionId}} 
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader>{{$t('document.view.attributes.collectionId', $i18n.locale)}}</v-subheader>
                                        <v-card-text>
                                            {{collectionSettings | localizedName($i18n.locale)}} ({{ collectionSettings.collectionId }} )
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader>{{$t('document.view.attributes.index', $i18n.locale)}}</v-subheader>
                                        <v-card-text>
                                            {{indexName}}
                                        </v-card-text>
                                        
                                        <!--
                                        <v-card-text v-if="document.tags ? document.tags.length < 1 : true">
                                            <i>{{$t('document.view.attributes.noTags', $i18n.locale)}}</i>
                                        </v-card-text>
                                        <v-card-text v-else>
                                            <v-label v-for="tag of document.tags" :key="'label_' + tag"/>
                                        </v-card-text>
                                        -->
                                    </v-card>
                                </v-col>

                                <v-col :cols="12" :md="4" :xl="3">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6">{{$t('document.view.tiles.changeLog.title', $i18n.locale)}}</div>
                                        </v-card-text>
                                        
                                        <v-subheader>
                                        {{$t('document.view.changelog.creation')}} 
                                        </v-subheader>
                                        <v-card-text> {{document.creationDateTime | localized-date-time-format($i18n.locale)}} {{$t('document.view.changelog.byUser') }} {{document.creationUserId}}</v-card-text>
                                        <v-divider />
                                        <v-subheader>
                                        {{$t('document.view.changelog.modification')}} 
                                        </v-subheader>
                                        <v-card-text>{{document.modificationDateTime | localized-date-time-format($i18n.locale)}} {{$t('document.view.changelog.byUser') }} {{document.modificationUserId}}</v-card-text>
                                        <v-divider />
                                    
                                    </v-card>
                                </v-col>

                                <v-col :cols="12" :md="4" :xl="3">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6">{{$t('document.view.tiles.sourceSystemInfo.tile', $i18n.locale)}}</div>
                                        </v-card-text>
                                        <v-subheader>{{$t('document.view.tiles.source.system.title', $i18n.locale)}}</v-subheader>
                                        <v-card-text v-if="meta.sourceSystemInfo">

                                        </v-card-text>
                                        <v-card-text v-else>
                                            {{$t('document.view.msgs.sourceSystemInfoMissing', $i18n.locale)}}
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader>{{$t('document.view.attributes.site', $i18n.locale)}}</v-subheader>
                                        <v-card-text v-if="meta.geoLocationInfo">

                                        </v-card-text>
                                        <v-card-text v-else>
                                            {{$t('document.view.msgs.geoLocationInfoMissing', $i18n.locale)}}
                                        </v-card-text>
                                        <v-divider />
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                        
                        <!-- Document Information -->
                        <v-container fluid  class="grey lighten-3">
                            <v-row justify="center" >

                                <v-col :cols="12" :md="12" :xl="9">
                                    <v-card tile>
                                        <v-card-text>
                                            <div class="text-h6">{{$t('document.view.tiles.attributeInfo.tile', $i18n.locale)}}</div>
                                        </v-card-text>
                                        <v-divider />
                                        <v-tabs  background-color="grey lighten-3" color="grey">
                                            <v-tab>{{$t('document.view.sections.attributes.tabs.overview')}}</v-tab>
                                            <v-tab>{{$t('document.view.sections.attributes.tabs.json')}}</v-tab>

                                            <v-tab-item>
                                                <c-document-attribute-table 
                                                    :value="response" 
                                                    :options="{showDescription: true}" 
                                                    :display-type="attributeTableDisplayType" />
                                            </v-tab-item>
                                            <v-tab-item>
                                                <v-card tile>
                                                    <pre>
                                                        {{JSON.stringify({ data: response.document.data, embedded: this.embeddedDocuments }, null, 3) }}
                                                    </pre>
                                                    <!--
                                                    <c-document-attribute-table :value="response" />
                                                    -->
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs>
                                        
                                    </v-card>
                                </v-col>
                            </v-row> 
                        </v-container>

                        <!-- Embedded documents -->
                        <v-container fluid  class="grey lighten-3">
                            <v-row justify="center" >

                                <v-col :cols="12" :md="12" :xl="9">
                                    <v-card tile>
                                        <v-card-text>
                                            <div class="text-h6">{{$t('document.view.sections.embedded.title', $i18n.locale)}}</div>
                                        </v-card-text>

                                        <template v-if="!embeddedCollectionsEmpty">
                                            <v-card-text>
                                            {{$t('document.view.sections.embedded.subtitle', $i18n.locale)}}
                                            </v-card-text>
                                            
                                            <v-expansion-panels accordion multiple>
                                                <v-expansion-panel v-for="(e, index) of embeddedCollections" :key="'embeddedCollection_' + index" class="elevation-0">
                                                    <v-expansion-panel-header>
                                                        {{e | localized-name($i18n.locale) }} ({{getDocumentsForEmbeddedCollection(e).length}})
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content class="pa-0">
                                                        <dxs-embedded-documents-list :value="{ documents: getDocumentsForEmbeddedCollection(e), schema: getSchemaForEmbeddedCollection(e)}" />
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        
                                        </template>

                                        <!-- No embedded documents -->
                                        <template v-else>
                                            <v-card-text>
                                            {{$t('document.view.sections.embedded.noEmbeddedDocuments', $i18n.locale)}}
                                            </v-card-text>
                                        </template>
                                    </v-card>
                                </v-col>
                            </v-row> 
                        </v-container>
                    </v-tab-item>
                    <v-tab-item>
                        <v-container class="grey lighten-3" fluid>
                            <v-row justify="center">
                                <v-col cols="12" lg="9">
                                    <v-card tile>
                                        <v-toolbar flat>
                                            <v-toolbar-title>{{$t('document.view.tabs.json.title')}}</v-toolbar-title>
                                        </v-toolbar>
                                        <v-divider />
                                        <v-card-text>
                                            <pre>{{JSON.stringify(document, true, 2)}}</pre>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        
        
            
        </c-section>
    </v-sheet>
</template>

<script>

import _isEmpty from 'lodash/isEmpty';
import EmbeddedDocumentsListComponent from '@/core/components/document/embedded-documents-list.component';

export default {

    name: 'document-detail-page',
    
    components : {
        'dxs-embedded-documents-list' : EmbeddedDocumentsListComponent,
    },
    
    props: {
        
        value: {
            type: Object,
            required: false,
        },
    },

    inject: ['progressIndicatorService', 'envContextService'],

    data: () => ({
        selectedTab: 0
     }),

    watch: {
        $route() {
            this.refresh();
        },
    }, 

    computed: {

        response(){
            return this.value
        },
        document() {

            return this.response.document ? this.response.document : {};
        },

        meta() {

            return this.response.meta ? this.response.meta : {};
        },

        collectionSettings() {
            return this.response.collectionSettings ? this.response.collectionSettings : {};
        },

        schema() {
            return this.response.schema ? this.response.schema : {};
        },

        measures() {
            return this.$dxs.attributes.getAttributeListFromSchema(this.schema, e => e.attributeType === 'MEASURE');
        },

        embeddedCollections() {
            return this.collectionSettings.embeddedCollections ? this.collectionSettings.embeddedCollections : {}; 
        },

        embeddedCollectionsEmpty() {
            return _isEmpty(this.embeddedCollections);
        },

        embeddedSchemas() {
            return this.response.embeddedSchemas ? this.response.embeddedSchemas : {};
        },

        embeddedDocuments() {
            return this.document.embedded ? this.document.embedded : {};
        },

        embeddedDocumentsEmpty() {
            return _isEmpty(this.embeddedDocuments);
        },

        indexName() {
            let indexName = this.envContextService.tenantId + '-' + this.collectionSettings.name.toLowerCase() + '-' + this.document.partitionId;
            if (this.collectionSettings.systemCollection) {
                indexName = 'platform-' + indexName; 
            }
            return indexName;
        }

    },

    methods: {
        
        

        getSchemaForEmbeddedCollection(embeddedCollection) {
            return this.embeddedSchemas[embeddedCollection.name] 
                 ? this.embeddedSchemas[embeddedCollection.name]
                 : {};
        },

         getDocumentsForEmbeddedCollection(embeddedCollection) {

             return this.embeddedDocuments[embeddedCollection.name]
                  ? this.embeddedDocuments[embeddedCollection.name]
                  : [];
         },

         goBack() {
             this.$router.go(-1);
         },

    },
}
</script>

<i18n>
{
    "en" : {

        "document.view.toolbar.title" : "Document",

        "document.view.tabs.dashboard.title" : "Dashboard",
        "document.view.tabs.json.title" : "JSON",

        "document.view.sections.attributes.tabs.overview" : "Overview",
        "document.view.sections.attributes.tabs.json" : "JSON",

        "document.view.attributes.partitionId" : " Partition Key",
        "document.view.attributes.collectionId" : "Collection",
        "document.view.attributes.index" : "Index",

        "document.view.tiles.changeLog.title" : "Change History",

        "document.view.tiles.source.system.title" : "Source System",

        "document.view.changelog.creation" : "Created",
        "document.view.changelog.modification" : "Last Modification",
        "document.view.changelog.byUser" : "by User " ,

        "document.view.tiles.metrics.title" : "Metrics",
        "document.view.tiles.metrics.subtitle" : "Compare this document's metrics with the collection's metrics.",

        "document.view.sections.embedded.title" : "Embedded Documents",
        "document.view.sections.embedded.subtitle" : "The embedded documents are contained in the tabs below. Please click a tab in order to open a list of embedded documents. ",
        "document.view.sections.embedded.noEmbeddedDocuments" : "This document does not contain further embedded documents."
    },

    "de" : {
        "document.view.toolbar.title" : "Dokument",
        "document.view.sections.attributes.tabs.overview" : "Übersicht",
        "document.view.sections.attributes.tabs.json" : "Als JSON",

        "document.view.attributes.partitionId" : " Partitionschlüssel",
        "document.view.attributes.collectionId" : "Datenbereich",
        "document.view.attributes.index" : "Index",

        "document.view.tiles.changeLog.title" : "Änderungshistorie",

        "document.view.tiles.source.system.title" : "Datenquelle",
        "document.view.tiles.source.system.title" : "Quellsystem",

        "document.view.changelog.creation" : "Angelegt am",
        "document.view.changelog.modification" : "Letzte Änderung",
        "document.view.changelog.byUser" : "von Anwender " ,

        "document.view.tiles.metrics.title" : "Metriken",
        "document.view.tiles.metrics.subtitle" : "Vergleichen Sie die Metriken dieses Dokuments mit den Werten des gesamten Datenbereichs.",

        "document.view.sections.embedded.title" : "Eingebettete Dokumente",
        "document.view.sections.embedded.subtitle" : "Sie finden nachfolgend die eingebetteten Dokumente. Klicken Sie auf einen der folgenden Reiter, um die Dokumente anzuzeigen. ",
        "document.view.sections.embedded.noEmbeddedDocuments" : "Dieses Dokument enthält keine weiteren eingebetteten Dokumente."
    }
}
</i18n>