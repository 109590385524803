import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';

export default {
    namespaced: true,

    state : {

        manufacturerplant: createDefaultManufacturerplant(),
        manufacturerId : null,
        manufacturers : createEmptyManufacturers()
    },

    mutations: {  

        setManufacturerplant(state, manufacturerplant) {
            state.manufacturerId = manufacturerplant.references.manufacturer;
            if (manufacturerplant) {
                state.manufacturerplant.tenantId = manufacturerplant.tenantId;
                state.manufacturerplant.name = manufacturerplant.name;
                state.manufacturerplant.i18n = manufacturerplant.i18n;
                state.manufacturerplant.data = manufacturerplant.data;
                state.manufacturerplant.references = manufacturerplant.references;
                state.manufacturerplant.active = manufacturerplant.active;
                state.manufacturerplant.creationDateTime     = manufacturerplant.creationDateTime;
                state.manufacturerplant.creationUser         = manufacturerplant.creationUser;
                state.manufacturerplant.modificationDateTime = manufacturerplant.modificationDateTime;
                state.manufacturerplant.modificationUser     = manufacturerplant.modificationUser;
            }
            
        },
        
        async updateManufacturerplants(context, manufacturerplant) {

            try {
                context.commit('setManufacturerplant', manufacturerplant);
            } catch (error) {
                return Promise.reject(error);
            }
        },        
        async setManufacturerId(state, manufacturerId) {
            if (manufacturerId) {
                //const payload = await api.masterdata.manufacturers.findById(manufacturerId);
                state.manufacturerplant.references.manufacturer = manufacturerId;
                state.manufacturerId = manufacturerId;
            } else {
                state.manufacturerplant.references.manufacturer = null;
            }
        },
        setManufacturers(state, manufacturers){ 
            if (manufacturers) {

            let items = [];
            if (manufacturers.items) {
                state.manufacturers.items = manufacturers.items;
            } else {
                state.manufacturers.items = items;
            }

            if (manufacturers.tenantId) {
                state.manufacturers.tenantId = manufacturers.tenantId;
            }

            if (manufacturers.pagination) {
                state.manufacturers.pagination = manufacturers.pagination;
            }

        }
        }

    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.manufacturerplant.findById(name);
                console.log(response )
                    let manufacturers = await api.masterdata.manufacturers.findByFilter('*', 0, 100);
                    context.commit('setManufacturers', manufacturers)
                
                //get(response, 'item.manufacturer.name', createDefaultManufacturerplant())
                context.commit('setManufacturerplant', get(response, 'item.manufacturerPlant', createDefaultManufacturerplant()));
                context.commit('setManufacturerId', get(response, 'item.manufacturer.name', null));
            
            } catch (error) {
                context.commit('setManufacturerplant', createDefaultManufacturerplant());
                context.commit('setManufacturerId', '');
                context.commit('setManufacturers', []);
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters.model, 'manufacturerplant');
                let response = await api.masterdata.manufacturerplant.update(payload);
                context.commit('setManufacturerplant', get(response, 'item.manufacturerPlant', createDefaultManufacturerplant()));

            } catch (error) {
                console.log(error);
                context.commit('setManufacturerplant', createDefaultManufacturerplant());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            context.commit('setManufacturerplant', model);
        },

        updateManufacturerplant(context, manufacturerplant) {
            context.commit('setManufacturerplant', manufacturerplant);
        },
        async updateManufacturerId(context, manufacturerId) {
            try {
                context.commit('setManufacturerId', manufacturerId);
            } catch (error) {
                return Promise.reject(error);
            }

        },
    },

    getters: {
        
        request(state) {
            let payload = state.manufacturerplant;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },
        manufacturerplant(state) {
            return state.manufacturerplant ? state.manufacturerplant : createDefaultManufacturerplant();
        },
        
        model(state) {
                 return {
                    manufacturerplant: state.manufacturerplant
            }
        },
        manufacturers(state) {
            return state.manufacturers ? state.manufacturers : {items:[]};
        },

        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },
        manufacturerId(state) {
            return get(state, 'manufacturerplant.referencemanufacturer',get(state,'manufacturerId'));
        },
        
    }
}

function createDefaultManufacturerplant() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {},
        active: null,
        references: {},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }}

    
function createEmptyManufacturers() {

    return {

        "items": ["Healthy","Edible","Bad Smell"]
        
    }}
