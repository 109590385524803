<template>
<div>
  <table width="100%">
    <tfoot>
      <tr>
        <td id="footer-spacer"></td>
      </tr>
    </tfoot>
    <thead>
      <tr>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
            <div class="page">
              <table class="text-center" width="100%">
                <tr><td><dxs-logo-comoponent type="PRINT_HEADER"></dxs-logo-comoponent></td></tr>
                <tr><td><h3>{{collectionSettings | localized-name($i18n.locale)}}</h3></td></tr>
                <tr><td><h4>{{$t('collection-series.print.printDate')}}: {{printDate}}</h4></td></tr>
                <tr><td>{{$t('collection-series.print.totalItemCount', {totalItemCount: totalItemCountFormatted})}}</td></tr>
              </table>
              <table width="100%">
                <tr>
                  <td>
                    <img class="print-img full-width" v-bind:src="image">
                    <!-- <c-data-time-series-chart class="d-none" ref="timeSeriesChart" :printMode="true" :value="timeSeries"></c-data-time-series-chart> -->
                  </td>
                </tr>
              </table>
          </div>
          
          <div class="page mt-10">
            <table class="text-center" width="100%">
              <tr>
                <td><h4 class="mb-5">{{$t('dashboard.view.displayFilter.title')}}</h4></td>
              </tr>
              <tr>
                <td>
                  <table class="table-print">
                    <thead>
                      <tr scope="row">
                        <th>{{$t('filter.i18n.name')}}</th>
                        <th>{{$t('value.i18n.name')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{$t('collection-time-series.print.reference.label')}}</td>
                        <td>{{selectedAttribute | localized-name($i18n.locale)}}</td>
                      </tr>
                      <tr>
                        <td>{{$t('collection-time-series.print.timescale.label')}}</td>
                        <td>{{selectedTimeScale | localized-name($i18n.locale)}}</td>
                      </tr>
                      <tr>
                        <td>Suchabfrage</td>
                        <td>{{filter.filterQuery}}</td>
                      </tr>
                      <tr v-for="facetFilter in filter.facetFilters" :key="facetFilter.name">
                        <td>{{attributes[facetFilter.name] | localized-name($i18n.locale)}}</td>
                        <td>{{facetFilter.filterQuery}}</td>
                      </tr>
                      <tr v-for="rangeFilter in filter.rangeFilters" :key="rangeFilter.name">
                        <td>{{attributes[rangeFilter.name] | localized-name($i18n.locale)}}</td>
                        <td>{{$t('collection-series.print.from')}} {{rangeFilter.from}} {{$t('collection-series.print.until')}} {{rangeFilter.until}}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          <div v-if="showTable" class="page mt-10">
            <table class="text-center" width="100%">
              <tr>
                <td><h4 class="mb-5">{{$t('collection-time-series.print.aggreationData')}}</h4></td>
              </tr>
              <tr>
                <td>
                  <table class="table-print">
                    <thead>
                      <tr scope="row">
                        <td></td>
                        <td class="text-right" v-for="measure in measures" :key="measure.attribute + '_' + measure.operation">
                          {{attributes[measure.attribute] | localized-name($i18n.locale)}} {{getAggregation(measure.operation) | localized-name($i18n.locale)}}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="timeSerie in timeSeries.data.result" :key="timeSerie.dateTime">
                        <td>{{timeSerie.dateTime.replace('T', '\n')}}</td>
                        <td class="text-right" v-for="measure in measures" :key="measure.attribute + '_' + measure.operation">
                          {{getMeasureValue(timeSerie.values, measure) | numeric-format('#,###.##', $i18n.locale)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="print-footer text-center">
    <div class="footer-item">
      {{$t('collection-series.print.printedOn')}}: {{printDate}}
      <div class="mt-2">
        <dxs-logo-comoponent type="PRINT_FOOTER"></dxs-logo-comoponent>
      </div>
    </div>
    <div class="footer-item">{{$t('collection-series.print.printedBy')}}: {{user.userName}}</div>
    <div class="footer-item">
      <span class="d-block">{{collectionSettings | localized-name($i18n.locale)}}</span>
      <span class="d-block black--text"><strong>{{$t('common.print.confidential')}}</strong></span>
    </div>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import get from 'lodash/get';
import LogoComponent from '@/components/logo.component'
const MODULE_NAME = "collectionTimeSeries";

export default {
  data: () => ({
  }),

  components: {
    'dxs-logo-comoponent' : LogoComponent
  },

  computed: {
    image() {
      return this.$route.params.image;
    },

    showTable() {
      return this.$route.params.type === 'ALL';
    },

    timeSeries() {
      return this.$store.getters[MODULE_NAME + "/timeSeries"];
    },

    totalItemCount() {
      return get(this.timeSeries, 'data.pagination.totalItemCount', 0);
    },

    totalItemCountFormatted() {
      return this.$dxs.formatting.formatInteger(this.totalItemCount, this.$i18n.locale);
    },

    collectionSettings() {
      return get(this.timeSeries, 'collectionSettings', {});
    },

    measures() {
      return get(this.timeSeries.request.timeSeries, 'measures', []);
    },

    attributes() {
      return get(this.schema, 'attributes', {});
    },

    selectedAttribute() {
      return this.attributes[this.timeSeries.request.timeSeries.dateAttribute]
    },

    selectedTimeScale() {
      return this.timeScales.find(timeScale => timeScale.value === this.timeSeries.request.timeSeries.timeScaleSeconds);
    },

    schema() {
      return this.$store.getters[MODULE_NAME + "/schema"]
    },

    filter() {
      return this.$store.getters[MODULE_NAME + "/filter"]
    },

    user() {
      return Vue.$user;
    },

    printDate() {
      const d = new Date();
      return `${d.toLocaleDateString(this.$i18n.locale)} ${d.toLocaleTimeString(this.$i18n.locale)}`;
    },

    aggregations() {
      return get(this.timeSeries, 'aggregations', {});
    },

    timeScales() {
      return get(this.timeSeries, 'timeScales', []);
    },
  },

  methods: {
    getAggregation(operation) {
      return this.aggregations.find(aggregation => aggregation.name === operation);
    },

    getMeasureValue(source, target) {
      return get(source.find(measureSource => measureSource.key.attribute === target.attribute && measureSource.key.operation === target.operation), 'value', undefined);
    },
    
  },

  beforeMount() {
    /** allow only access when chart image and print type is set
     * navigate to root page
     */
    if (!this.image && !this.showTable) {
      this.$router.push("/");
    }
  }
};
</script>
<style lang="scss" scoped>
.table {
  width: 100%;
}
</style>

<i18n>
{
  "en" : {
    "collection-time-series.print.timescale.label" : "Time scale",
    "collection-time-series.print.reference.label" : "Reference point",
    "collection-time-series.print.aggreationData" : "Aggregation data"
  },

  "de" : {
    "collection-time-series.print.timescale.label" : "Zeitskala",
    "collection-time-series.print.reference.label" : "Bezugspunkt",
    "collection-time-series.print.aggreationData" : "Aggregationsdaten"
  }
}
</i18n>