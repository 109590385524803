<template>
    <v-sheet  color="secondary">
        <c-section :title="$t('user-list-templates-list.view.sections.list.title')" :subtitle="$t('user-list-templates-list.view.sections.list.subtitle')" class="secondary">

            <v-card class="mt-5 mb-5" tile>
                <c-item-list
                    :title="$t('user-list-templates-list.view.toolbar.title')" 
                    :items="items" 
                    :pagination="pagination"
                    :disable-edit-item="!hasEditRole && !hasReadRole" 
                    :disable-delete-item="!hasDeleteRole"
                    :supportsDeleteItem="true"
                    :supportsAddItem="false"
                    @refresh-list="refresh"
                    @previous-page="onPreviousPage"
                    @next-page="onNextPage"
                    @item-edited="onItemEdited" 
                    @item-deleted="onItemDeleted"
                    @item-list-filter-applied="onFilterChanged"
                >
                    <template v-slot:item-list-table-head-columns>
                        <th class="text-center" >{{$t('user-list-templates-list.view.columns.userId')}}</th>
                        <th class="text-left" >{{$t('user-list-templates-list.view.columns.collectionId')}}</th>
                        <th class="text-left" >{{$t('user-list-templates-list.view.columns.templateId')}}</th>
                        <th class="text-center" >{{$t('user-list-templates-list.view.columns.bookmark')}}</th>
                    </template>

                    <template v-slot:item-list-table-body-columns="props">
                        <td class="text-center">{{props.item ? props.item.userId : '-'}}</td>
                        <td class="text-left">{{props.item ? props.item.collectionId : '-'}}</td>
                        <td class="text-left">{{props.item ? props.item.templateId : '-'}}</td>
                        <td class="text-center"><v-icon small>{{props.item.bookmark ? 'bookmark' : '-'}}</v-icon></td>
                    </template>

                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{$t('user-list-templates-list.view.help.title')}}</v-subheader>
                <v-card-text class="grey lighten-5">{{$t('user-list-templates-list.view.help.description')}}</v-card-text>
            </v-card>
        </c-section>
        
    </v-sheet>
</template>

<script>
const MODULE_NAME = 'userListTemplatesList';
import Roles from '@/roles';
import { get } from 'lodash';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        pagination() {
            return this.response.pagination;
        },

        items() {
            return this.response.items;
        },

        hasReadRole() {
            return this.userContextService.hasAllRoles(Roles.USER_LIST_TEMPLATES_READ);
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.USER_LIST_TEMPLATES_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasAllRoles(Roles.USER_LIST_TEMPLATES_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasAllRoles(Roles.USER_LIST_TEMPLATES_DELETE);
        }
    },

    methods: {

        async refresh() {
            this.loadPage(0, 50);
        },

        async onNextPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({from, pageSize}) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse", {from, pageSize});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemEdited(event) {

            if (event) {
                this.$router.push({
                    name: 'userListTemplatesEditor',
                    params: {
                        name: get(event, 'item.name', {})
                    }
                });
            } else {
                this.$log.debug("Event object is undefined. Can't navigate to user list template editor.")
            }
        },

        async onFilterChanged(event) {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/updateFilter", event.filter)
                await this.refresh()
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onItemDeleted(event) {

            try {
               let context = {
                userId : event.item.userId,
                collectionId : event.item.collectionId,
                name : event.item.name
               }
               await this.$store.dispatch(MODULE_NAME + "/deleteTemplate", context)
               await this.refresh()
            }  catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "user-list-templates-list.view.sections.list.title" : "User List Template Management",
        "user-list-templates-list.view.sections.list.subtitle" : "You may use this dialog for deleting user list templates.",
        "user-list-templates-list.view.toolbar.title" : "User List Templates",

        "user-list-templates-list.view.columns.collectionId" : "Collection",
        "user-list-templates-list.view.columns.userId" : "User",
        "user-list-templates-list.view.columns.templateId": "Template ID",
        "user-list-templates-list.view.columns.bookmark" : "Bookmark",

        "user-list-templates-list.view.help.title" : "What are User List Templates?",
        "user-list-templates-list.view.help.description" : "These User List Templates consist of saved filter-, view- & sort-settings of a user for specific collections. User List Templates can be used to apply a set of settings in a collection."

    },

    "de" : {
        "user-list-templates-list.view.sections.list.title" : "Verwaltung Listenvorlagen (Benutzer)",
        "user-list-templates-list.view.sections.list.subtitle" : "Sie können Listenvorlagen einsehen oder löschen.",
        "user-list-templates-list.view.toolbar.title" : "Listenvorlagen (Benutzer)",

        "user-list-templates-list.view.columns.collectionId" : "Datenbereich",
        "user-list-templates-list.view.columns.userId" : "Benutzer",
        "user-list-templates-list.view.columns.templateId": "Template ID",
        "user-list-templates-list.view.columns.bookmark" : "Favorit",

        "user-list-templates-list.view.help.title" : "Was sind Listenvorlagen (Benutzer)?",
        "user-list-templates-list.view.help.description" : "Diese Listenvorlagen enthalten die abgespeicherten Einstellungen zu Filterung, Ansicht und Sortierung vom Benutzer für bestimmte Datenbereiche. Listenvorlagen können verwendet werden, um gespeicherte Einstellungen in einem Datenbereich wiederverwenden zu können."
    }
}
</i18n>