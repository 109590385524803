
export default [

    { name: '#B4D6C1', value: '#B4D6C1' },
    { name: '#8DC3A7', value: '#8DC3A7' },
    { name: '#6BAF92', value: '#6BAF92' },
    { name: '#6BAF92', value: '#6BAF92' },
    { name: '#358873', value: '#358873' },
    { name: '#207567', value: '#207567' },
    { name: '#39FF14', value: '#39FF14' },
    { name: '#FF073A', value: '#FF073A' },
    { name: '#800000', value: '#800000' },





];