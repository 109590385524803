<template>

    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <c-base-dialog 
                    :value="tag"
                    :title="$t('tags-wizard.view.title')" 
                    :subtitle="$t('tags-wizard.view.subtitle')"
                    color="secondary"
                    @dialog-saved="save"
                    @dialog-canceled="cancel"
                    >
                    <template v-slot:default="props"> 
                        
                        <v-card flat>
                            <v-card-text>
                            <c-name-input 
                                v-model="props.item.name"
                                filled 
                            />
                        </v-card-text>
                        <v-divider />
                        <v-subheader>Define Tag Color</v-subheader>
                        <c-color-single-selector :items="tagColors" v-model="props.item.color" flat color="white" />
                        </v-card>
                    </template>
                </c-base-dialog>
            </v-col>
        </v-row>

    </v-container>

</template>

<script>
import TagsWizardModule from '@/core/store/modules/catalog/tags/tags-wizard.module';
import ColorItems from '@/core/constants/colors.constants';

const MODULE_NAME = "tagsWizard";


export default {

    name: 'tags-wizard',

    inject: ['errorHandlerService', 'progressIndicatorService'],

    methods: {
        async save() {
            
            this.progressIndicatorService.show();
           
            try {
                
                await this.$store.dispatch(MODULE_NAME + "/createTag");
                
                this.$router.push( { name: 'TagEditorView', params: {name: this.tag.name}  });

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push( {
                name: 'TagListView'
            });
        },


        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetTag");
        }
    },

    computed: {

        tagColors() {
            return ColorItems;
        },


        tag: {

            get() {
               return this.$store.getters[MODULE_NAME + '/tag'];
            }, 

            set(item) {
                
                this.$store
                    .dispatch(MODULE_NAME + "/updateTag", item)
                    .catch(this.errorHandlerService.handleError);
            }
        },
    },

    created() {

        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, TagsWizardModule);
        }

        this.reset();
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "tags-wizard.view.title" : "Create New Tag",
        "tags-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new tag."
    },

    "de" : {
        "tags-wizard.view.title" : "Neues Tag anlegen",
        "tags-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage eines neuen Tags."
    }
}
</i18n>


