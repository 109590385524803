import apiV2 from '@/core/api/v2.api';

export default {

    namespaced: true,

    state: {

        detectionJobTemplate: createEmptyDetectionJobTemplate(),

        collectionSettingsList: [],

        collectionSettings: {},

        schemas: {}
    },

    mutations: {

        setDetectionJobTemplate(state, detectionJobTemplate) {

            if (detectionJobTemplate) {
                state.detectionJobTemplate = detectionJobTemplate ? detectionJobTemplate : null;
            } else {
                state.detectionJobTemplate = null;
            }

        },

        setCollectionSettingsList(state, collectionSettingsList) {
            state.collectionSettingsList = collectionSettingsList ? collectionSettingsList : [];
        },

        setCollectionSettings(state, collectionSettings) {
            state.collectionSettings = collectionSettings ? collectionSettings : [];
        },

        setSchemas(state, schemas) {
            state.schemas = schemas ? schemas : {};
        }
    },

    actions: {

        async loadData(context, {from, pageSize}) {

            try {
                let response = await apiV2.catalog.collectionSettings.findAll('*', from, pageSize);
                let collectionSettingsList = [];
                for (let item of response.items) {
                    collectionSettingsList.push(item);
                }

                context.commit('setCollectionSettingsList', collectionSettingsList);

            } catch (error) {
                return Promise.reject(error);
            }
        },

        async loadCollectionSettings(context, {collectionId}) {

            try {
                let response = await apiV2.catalog.collectionSettings.findById(collectionId);

                let collectionSettings = response;

                context.commit('setCollectionSettings', collectionSettings);

            } catch (error) {
                return Promise.reject(error);
            }
        },

        async loadAllSchemas(context) {

            try {
                let response = await apiV2.catalog.schemas.findAll('*', 0, 50);

                let schemas = response;

                context.commit('setSchemas', schemas);

            } catch (error) {
                return Promise.reject(error);
            }
        },

        async createDetectionJobTemplate(context) {

            try {

                let request = context.getters.request;
                await apiV2.catalog.detectionJobTemplates.create(request.name, request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetDetectionJobTemplate(context) {
            context.commit('setDetectionJobTemplate', createEmptyDetectionJobTemplate());
        },

        async resetState(context) {
            context.commit('setDetectionJobTemplate', createEmptyDetectionJobTemplate());
            context.commit('setCollectionSettingsList', []);
            context.commit('setCollectionSettings', {});
        },

        async updateDetectionJobTemplate(context, detectionJobTemplate) {

            try {
                context.commit('setDetectionJobTemplate', detectionJobTemplate);
            } catch (error) {
                return Promise.reject(error);
            }
        },

    },



    getters: {


        request(state) {
            return {
                
                name: state.detectionJobTemplate.name,
                i18n: {},
                active: false,
                source: {
                    collectionId: state.detectionJobTemplate.collectionId,
                    dateTimeAttribute: state.detectionJobTemplate.dateTimeAttribute,
                    timeWindowSizeSeconds: 10,
                    filter: {
                        facetFilters: [],
                        filterQuery: "*",
                        filterQueryLanguage: "SIMPLE",
                        rangeFilters: []
                    },
                    aggregation: [],
                    groupBy: []
                },
                logging: {
                    collectionId: state.detectionJobTemplate.name,
                    schemaId: state.detectionJobTemplate.name,
                    partitioningStrategy: "PARTITION_BY_MONTH"
                },
                schedule: {
                    secondsExpression: "10",
                    minutesExpression: "*",
                    hoursExpression: "*",
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: true,
                    sunday: true
                },
                rules: [
                    // {
                    //     name: "",
                    //     i18n: {},
                    //     severity: "LOW",
                    //     expression: "",
                    //     active: false
                    // }
                ],
                customData: {},

                creationDateTime: null,
                creationUser: null,
                modificationDateTime: null,
                modificationUser: null


            };
        },

        collectionSettingsList(state) {
            return state.collectionSettingsList ? state.collectionSettingsList : [];
        },

        collectionSettings(state) {
            return state.collectionSettings ? state.collectionSettings : {};
        },

        schema(state) {
            return state.collectionSettings.schema ? state.collectionSettings.schema : {};
        },

        schemas(state) {
            return state.schemas ? state.schemas : [];
        },

        detectionJobTemplate(state) {
            return state.detectionJobTemplate ? state.detectionJobTemplate : createEmptyDetectionJobTemplate();
        }
    }
}

function createEmptyDetectionJobTemplate() {

    return {
        name: null,
        collectionId: null,
        dateTimeAttribute: null
    };
}