import api from '../../../api/battery-data-hub.api';
import { get, set } from 'lodash';

export default {
    namespaced: true,

    state : {

        substanceRecyclingShare: createDefaulResponse(),
        batteryModel : createEmptyHazardousSubstanceClassifications(),
        batterySubstance : createEmptyHazardousSubstanceClassifications()

    },

    mutations: {  

        setSubstancesRecyclingShare(state, substanceRecyclingShare) {
            console.log("*********************************** Share was SET***************")
            console.log(substanceRecyclingShare)
            if (substanceRecyclingShare) {
                
            state.substanceRecyclingShare = substanceRecyclingShare
                state.substanceRecyclingShare.tenantId = substanceRecyclingShare.tenantId;
                state.substanceRecyclingShare.name = substanceRecyclingShare.name;
                state.substanceRecyclingShare.i18n = substanceRecyclingShare.i18n;
                state.substanceRecyclingShare.data = substanceRecyclingShare.data;
                state.substanceRecyclingShare.references = substanceRecyclingShare.references;
                state.substanceRecyclingShare.active = substanceRecyclingShare.active;
                state.substanceRecyclingShare.creationDateTime     = substanceRecyclingShare.creationDateTime;
                state.substanceRecyclingShare.creationUser         = substanceRecyclingShare.creationUser;
                state.substanceRecyclingShare.modificationDateTime = substanceRecyclingShare.modificationDateTime;
                state.substanceRecyclingShare.modificationUser     = substanceRecyclingShare.modificationUser;
            }
            
        },
        setBatteryModels(state, batteryModel){ 
            if (batteryModel) {

            let items = [];
            if (batteryModel.items) {
                state.batteryModel.items = batteryModel.items;
            } else {
                state.batteryModel.items = items;
            }

            if (batteryModel.tenantId) {
                state.batteryModel.tenantId = batteryModel.tenantId;
            }

            if (batteryModel.pagination) {
                state.batteryModel.pagination = batteryModel.pagination;
            }

        }
        },
        setBatterySubstances(state, batterySubstance){ 
            if (batterySubstance) {

            let items = [];
            if (batterySubstance.items) {
                state.batterySubstance.items = batterySubstance.items;
            } else {
                state.batterySubstance.items = items;
            }

            if (batterySubstance.tenantId) {
                state.batterySubstance.tenantId = batterySubstance.tenantId;
            }

            if (batterySubstance.pagination) {
                state.batterySubstance.pagination = batterySubstance.pagination;
            }

        }
        },
        setBatteryModel(state,model){
            state.substanceRecyclingShare.references.batterymodel = model;
        },
        setBatterySubstance(state,substance){
            state.substanceRecyclingShare.references.batterysubstance = substance;
        }
    },

    actions: {
        async checkForBatterySubstanceAndModel(context){
            
            const name = context.getters["substancesRecyclingShare"].name
            const substance = context.getters["batterySubstance"]
            const list = await api.masterdata.substancesRecyclingShares.findByModel(name)
            for(let item of list){
                if(get(item,"references.batterysubstance",null)=== substance){
                    if(item.name != name){
                        return true;
                    }
                }
            }
            throw Error("no duplicates");
        },
 
        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.substancesRecyclingShares.findById(name);
                const batteryModel = await api.masterdata.batteryModels.findByFilter('*', 0, 100);
                const batterySubstance = await api.masterdata.batterySubstances.findByFilter('*', 0, 100);
                await context.commit('setSubstancesRecyclingShare', get(response,"item.substanceRecyclingShare"));
                context.commit('setBatteryModels', batteryModel)
                context.commit('setBatterySubstances', batterySubstance);
            
            } catch (error) {context.commit('setSubstancesRecyclingShare', null)
                context.commit('setBatteryModels', null)
                context.commit('setBatterySubstances', null);
                throw error; 
            }
        },

        async saveData(context) {

            try {

                const payload = context.getters.request;
                console.log(payload);
                console.log("*****************REQUEST UPDATE***************************");
                const response = await api.masterdata.substancesRecyclingShares.update(payload);
                console.log(response);
                console.log("*****************response UPDATE***************************");
                context.commit('setSubstancesRecyclingShare', response.item.substanceRecyclingShare);
            } catch (error) {
                console.log(error);
                context.commit('setSubstancesRecyclingShare', createDefaulResponse());
                throw error;

            }
        },
        async updateBatterySubstance(context, batterySubstance) {
            try {
                context.commit('setBatterySubstance', batterySubstance);
            } catch (error) {
                return Promise.reject(error);
            }
        },        
        async updateBatteryModel(context, model) {
            try {
                context.commit('setBatteryModel', model);
            } catch (error) {
                return Promise.reject(error);
            }
        }, 
        async updateSubstancesRecyclingShare(context,share){
            try {
                context.commit('setSubstancesRecyclingShare', share);
            } catch (error) {
                return Promise.reject(error);
            }
        }
    },

    getters: {
        
        request(state) {
            let payload = state.substanceRecyclingShare;
            payload.name ? set(payload, "name", payload.name) : set(payload, "name", null);
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creatisonUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },
        substancesRecyclingShare(state){
            return state.substanceRecyclingShare ? state.substanceRecyclingShare : createDefaulResponse()
        },
        batterySubstance(state) {
            return state.substanceRecyclingShare.references.batterysubstance ? state.substanceRecyclingShare.references.batterysubstance : null;
        },
        batterySubstances(state) {
            return state.batterySubstance ? state.batterySubstance : null;
        },
        batteryModels(state) {
            return state.batteryModel ? state.batteryModel : null;
        },
        batteryModel(state) {
            return state.substanceRecyclingShare.references.batterymodel ? state.substanceRecyclingShare.references.batterymodel : null;

        },

        tenantId(state) {
            return state.tenantId ? state.tenantId : null;
        },
        
    }
}

function createDefaulResponse() {
    return {
        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            primaryMaterialShare: null,
            preConsumerShare:null,
            postConsumerShare: null },
        "active": true,
        "references": {
            batterymodel: null,
            batterysubstance: null
        }

    }}

    
function createEmptyHazardousSubstanceClassifications() {

    return {

        "items": ["Healthy","Edible","Bad Smell"]
        
    }}
