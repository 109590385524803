<template>
    <div>
        <div class="secondary darken-1" >
            <v-breadcrumbs>
                <v-breadcrumbs-item :to="{ name: 'notifications' }" :disabled="false">{{$t('navigation.groups.notification')}}</v-breadcrumbs-item>
            </v-breadcrumbs>
            <c-section :title="$t('notification-dashboard.view.title')" :subtitle="$t('notification-dashboard.view.subtitle')"  class="secondary"></c-section>
        </div>
        <v-card tile flat>
            <v-container fluid grid-list-md class="grey lighten-4"> 
                <v-row>
                    <v-col cols="12" md="8">
                        <c-filter-query-input :disabled="loading" :loading="loading" :value="{ query: '*' }" @filter-query-changed="onFilterQueryChange" :dark="false" />
                    </v-col>
                    <v-col cols="12" md="4">
                        <c-item-selector 
                            v-model="selectedSortItem"
                            return-object
                            :items="sortItems"
                            :label="$t('notifications-dashboard.view.sort.label', $i18n.locale)"
                            solo
                        />
                    </v-col>
                </v-row>
            </v-container>
            <v-divider />
            
            <v-toolbar flat color="white">
                <v-toolbar-title>{{$t('collection-documents.view.results', {totalItemCount: pagination.totalItemCount})}}</v-toolbar-title>

                <v-spacer />

                <v-toolbar-items>
                    <v-btn icon @click="refresh">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />

            <v-container fluid class="grey lighten-3">
                <v-row v-if="itemsEmpty" justify="center">
                    <v-col cols="12"  >
                        <c-empty-result-tile />
                    </v-col> 
                </v-row>
                <v-row v-else justify="center">
                    <v-col cols="12"  md="9">
                        <v-card tile>
                            <v-list>
                                <template v-for="(item, index) of items">
                                    <v-list-item :to="'/notifications/' + item.id" two-line  :key="'listItem-' + index">
                                        <v-list-item-avatar>
                                            <v-icon>mail</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.data.title}}</v-list-item-title>
                                            <v-list-item-subtitle><span>
                                                {{$t('notifications-dashboard.view.messageList.item.subtitle', {publishDate: $dxs.i18n.localizedDateTimeFormat(item.data.publishDate, $i18n.locale), publisherId: item.data.publisherId})}}</span></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider  :key="'listItemDivider-' + index" />
                                </template>
                            </v-list>
                        </v-card>
                    </v-col> 
                </v-row>
                <v-row>
                    <v-col cols="12">
                    <c-query-result-pagination-panel
                            :items="items"
                            :pagination="pagination"
                             @load-next-page="onLoadMore"
                        />
                        </v-col>
                </v-row>
            </v-container>
                
            
        </v-card>
    </div>  
</template>

<script>
import forOwn from 'lodash/forOwn';

const MODULE_NAME = "notificationsDashboard";
const VIEW_TYPE_TILE = "VIEW_TYPE_TILE";
const VIEW_TYPE_LIST = "VIEW_TYPE_LIST";

export default {

    name: 'notifications',

    props: {
        value: {
            type: Object,
            required: false,
        },
        
        options: {
            type: Object,
            required: false
        }
    },

    inject: ['shellState', 'errorHandlerService', 'messageService', 'progressIndicatorService'],

    
    watch: {
        'value': 'onValueUpdate',
    },

    data: () => ({
        height: 600,
        viewType: VIEW_TYPE_LIST,
        toggleDisplayModeGroup: 0,
        filterDialog: false,
        viewSettingsSidebar: false,
        sortSettingsSidebar: false,
        showDeleteConfirmation: false,
        querySummary: false,

        // Selected ID for deletion.
        selectedId: null,

        viewSettings: {
            attributes: []
        }
    }),

    computed: {
        loading() {
            return this.shellState.loading;
        },


        collectionId() {
            return this.value ? this.value.collectionId : null;
        },

        filter() {
            let filter = this.$store.getters[MODULE_NAME + '/filter'];
            return filter;
        },

        response() {
            let response = this.$store.getters[MODULE_NAME + '/response'];
            return response;
        },

        items() {
            return this.response.items;
        },

        itemsEmpty() {
            return this.response && this.response.items && this.response.items.length < 1;
        },

        meta() {
            return this.response.meta;
        },

        links() {
            return this.response.links ? this.response.links : {}; 
        },

        attributes() {

            return this.meta.attributes;
        },

        facets() {

            let facets = [];
            forOwn(this.response.facets, (value, key) => {

                let facet = {
                    name: key, 
                    attribute: this.meta.attributes[key],
                    values: value
                };

                facets.push(facet);
            });

            return { facets: facets };
        },

        pagination() {
            return this.response.pagination;
        },
        
        moreElements() {

            return this.items.length < this.pagination.totalItemCount;
        },

        viewTypeSelectionItems() {
            return [
                {text: 'List View', value: VIEW_TYPE_LIST, icon: 'view_list' },
                {text: 'Tile View', value: VIEW_TYPE_TILE, icon: 'view_module' },
            ];
        },

        isViewTypeTile() {
            return this.viewType === VIEW_TYPE_TILE;
        },

        isViewTypeList() {
            return this.viewType === VIEW_TYPE_LIST;
        },

        devMode() {
            return this.options && this.options.dev;
        },

        moduleName() {
            return this.value.name;
        },

        csvDownloadLink() {
            return this.links.csv;
        },

        selectedSortItem: {

            get() {
                let sort = this.$store.getters[MODULE_NAME + '/sort'];
                return sort;
            },

            set(sortItem) {
                this.$store.dispatch(MODULE_NAME + '/updateSort', { sort: sortItem });
            }
        },

        sortItems() {

            return [
                {
                    name: 'sort_by_date_desc',
                    i18n : {
                        de : { name: 'Sortieren nach Datum (absteigend)'},
                        en : { name: 'Sort By Date (Descending)'}
                    },
                    sortCriteria: [
                        {name: "publishDate", ordering: "DESC"}
                    ]
                },
                {
                    name: 'sort_by_date_asc',
                    i18n : {
                        de : { name: 'Sortieren nach Datum (aufsteigend)'},
                        en : { name: 'Sort By Date (Asscending)'}
                    },
                    sortCriteria: [
                        {name: "publishDate", ordering: "ASC"}
                    ]
                }
            ];
        }
    },

    methods: {

        toogleQuerySummary() {

            this.querySummary = !this.querySummary;
        },

        onFilterQueryChange(event) {

            // Set the filter query on the vuex store. This will cause the view to update.
            //this.$log.debug(JSON.stringify(event));
            this.$store.dispatch(MODULE_NAME + '/updateFilterQuery', {collectionId: this.collectionId, filterQuery: { filterQuery: event.query, filterQueryLanguage: event.mode } });
        },

        onFilterChanged(event) {
            this.$log.debug("Filter Changed::: " + JSON.stringify(event));
            this.$store.dispatch(MODULE_NAME + '/updateFacetFilters', {collectionId: this.collectionId, facetFilters: event});
        },

        onSortSettingsChanged(event) {
            //this.$log.debug(JSON.stringify(event));
            this.$store.dispatch(MODULE_NAME + '/updateSort', {collectionId: this.collectionId, sort: event});
            // Set the sort on the vuex store. This will cause the view to update.
        },

        onViewSettingsChanged(event) {

            //this.$log.debug(JSON.stringify(event));
            this.$store.dispatch(MODULE_NAME + '/updateViewSettings', event);
            
            this.viewSettingsSidebar = false;
        },

        add() {
            // This method assumes that the detail dialog is implemented as a subpath of the list dialog.
           
            let fullPath =  this.$route.fullPath;
            if (!fullPath.endsWith('/')) {
                fullPath = fullPath + '/';
            } 
            this.$router.push(fullPath  + 'new');
        },


        async resetView(collectionId) {
            
            this.progressIndicatorService.show();

            try {

                await this.$store.dispatch(MODULE_NAME + '/resetView');
                await this.$store.dispatch(MODULE_NAME + '/loadData', {collectionId: collectionId});
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async refresh() {

            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData', {collectionId: this.collectionId});
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async exportDocument(format) {

            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/exportDocument', {collectionId: this.collectionId, format: format});

                this.messageService.show({
                    title: this.$t('collection-documents.view.messages.export.success.title', this.$i18n.locale),
                    text: this.$t('collection-documents.view.messages.export.success.message', this.$i18n.locale)
                })
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onLoadMore() {

            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/loadMore', {collectionId: this.collectionId});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },


        async deleteItem(id) {
            
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/deleteItemById', id);
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async deleteAll() {
            
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/deleteAll');
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        setViewType(viewType) {
            this.viewType = viewType;
        },

        toogleViewType() {

            if (this.isViewTypeList) {
                this.setViewTypeTile();
            } else {
                this.setViewTypeList();            
            }
        },

        setViewTypeTile() {
            this.setViewType(VIEW_TYPE_TILE);
        },

        setViewTypeList() {
            this.setViewType(VIEW_TYPE_LIST);
        },

        onOpenClicked(event) {
            if (event && event.item) {
                // Unfortunatetly there is currently not better way to open new tabs in vue router.
                let route = this.$router.resolve({ path: '/notifications/' + event.item.id });
                window.open(route.href);
            }
        },

        onEditClicked(event) {
            this.goToDetailsView(event.item);
        },

        onDeleteClicked(event) {

            // Delete this item.
            //this.$log.debug(JSON.stringify(event));
            this.selectedId = event.id;

            this.showDeleteConfirmation = true;
        },

        onApproveDeletion() {

            this.showDeleteConfirmation = false;
            this.deleteItem(this.selectedId).finally(() => this.selectedId = null);

            
        },

        onCancelDeletion() {
            this.selectedId = null;
            this.showDeleteConfirmation = false;
            
        },

        async onValueUpdate(newValue, oldValue) {

            if (newValue.collectionId !== oldValue.collectionId) {
                
                // A change to the value indicates that a reload should be triggered.
                this.$log.info('Resetting view for new value ' + JSON.stringify(newValue));

                await this.resetView(newValue.collectionId);
            }
        },

        onDeleteCollection() {
            this.deleteAll();
        },

        goToDetailsView(item) {

            // This method assumes that the detail dialog is implemented as a subpath of the list dialog.
           
            let fullPath =  this.$route.fullPath;
            if (!fullPath.endsWith('/')) {
                fullPath = fullPath + '/';
            } 
            this.$router.push(fullPath  + item.id);
        },

        
    },

    created() {

        this.refresh();
    },
}
</script>

<i18n>
{
    "en": {
        "notifications-dashboard.view.messageList.item.subtitle" : "Sent from {publisherId} on {publishDate}",
        "notifications-dashboard.view.sort.label" : "Sort by..."
    },

    "de": {
        "notifications-dashboard.view.messageList.item.subtitle" : "Von {publisherId} am {publishDate}",
        "notifications-dashboard.view.sort.label" : "Sorrtieren nach..."
    }
}
</i18n>