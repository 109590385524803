import api from '@/store/api';

export default {

    namespaced: true,

    state: {
        response: emptyResponse()
    },

    mutations: {


        setResponse(state, response) {

            // Push additional data into the response
            if (response) {

                state.response.data.collectionSettings = response.collectionSettings;
                state.response.data.schema = response.schema;
            
            } else {
                state.response = emptyResponse();
            }
        },



    },

    actions: {

        async loadResponse(context, {name}) {

            try {

                let response = await api.catalog.collectionSettings.findById(name);
                context.commit('setResponse', response);
                
            } catch (error) {
                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', emptyResponse());
                return Promise.reject(error);
            }
        },

        
    },

    getters: {

        response(state) {
            return state.response;
        },

        
    }
}

function emptyResponse() {

    return {

        data: {

            collectionSettings: {

                name: null,
                partitionIdTemplate: null,
                idTemplate: null,
                i18n: {},
                custData: {},
            },

            schema: {

                i18n: {},
                attributes: {}
            },
        }

    };
}