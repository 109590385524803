import api from '@/store/api';

export default {

    namespaced: true,

    state: {

        response: {
            
            collectionSettings: {},

            schema: {}
        },
    },

    mutations: {

        setResponse(state, response) {
            // Push additional data into the response
            if (response) {

                state.response = response;
            
            } else {
                state.respone = {
                    collectionSettings: {},
                    schema: {}
                }
            }
        },

    },

    actions: {

        async loadData(context, {collectionId}) {

            try {

                let response = await api.collections.findById(collectionId); 
                context.commit('setResponse', response);
                
            } catch (error) {

                console.log("An error has occured" + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', {collectionSettings: {}, schema: {}} );
              }
        },
    },

    getters: {

        response(state) {
            return state.response;
        },
    }
}