
export default  [

    {name: '#DFEAE2', value: '#DFEAE2'},
    {name: '#B4D6C1', value: '#B4D6C1'},
    {name: '#8DC3A7', value: '#8DC3A7'},
    {name: '#6BAF92', value: '#6BAF92'},
    {name: '#6BAF92', value: '#6BAF92'},
    {name: '#358873', value: '#358873'},
    {name: '#207567', value: '#207567'},
    {name: '#2D4205', value: '#2D4205'},

    {name: '#CA6869', value: '#CA6869'},
    {name: '#F49889', value: '#F49889'},
    {name: '#F0B799', value: '#F0B799'},
    {name: '#FB5C4D', value: '#FB5C4D'},
    {name: '#743749', value: '#743749'},
    {name: '#B22C45', value: '#B22C45'},

    {name: '#F4D6A0', value: '#F4D6A0'},
    {name: '#CCC99E', value: '#CCC99E'},
    {name: '#FEF8DD', value: '#FEF8DD'},
    {name: '#FFE7C7', value: '#FFE7C7'},
    {name: '#F7D8BA', value: '#F7D8BA'},
    {name: '#FFF2B2', value: '#FFF2B2'},
    {name: '#FFF2B2', value: '#FFF2B2'},
    {name: '#FFE14C', value: '#FFE14C'},
    {name: '#FFD400', value: '#FFD400'},
    {name: '#FFB100', value: '#FFB100'},
    {name: '#FF9500', value: '#FF9500'},

    {name: '#ACDDDE', value: '#ACDDDE'},
    {name: '#CAF1DE', value: '#CAF1DE'},
    {name: '#E1F8DC', value: '#E1F8DC'},

    {name: '#07728D', value: '#07728D'},
    
    


];