import api from '@/store/api';

export default {

    namespaced : true,

    state: {

        collectionSettings: {},
        schema: {}
    },

    mutations: {

        setResponse(state, response) {

            if (response) {

                state.collectionSettings = response.collectionSettings ? response.collectionSettings : {};
                state.schema = response.schema ? response.schema : {};

            } else {

                state.collectionSettings = {};
                state.schema = {};
            } 
        },  

    },

    actions: {

        async loadData(context, { collectionId }) {


            return api.collections
                      .findById(collectionId)
                      .then(response =>  context.commit('setResponse', response));
                      
           
        },

    },

    getters: {

        response(state) {

            return {
                collectionSettings  : state.collectionSettings,
                schema              : state.schema
            }
        }
    }


}
