import api from '@/store/api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state : {
        userAuthorization: {}
    },

    mutations: {

        setUserAuthorization(state, userAuthorization) {
            set(state, 'userAuthorization', userAuthorization);
        },
    },

    actions: {
    
        async loadData(context, { name }) {

            // First load the account
            try {
                let response = await api.userMgmt.userAuthorization.findById(name);
                console.log(">>> ", JSON.stringify(response));
                context.commit('setUserAuthorization', get(response, 'data', {}));
            } catch (error) {
                context.commit('setUserAuthorization', {});
                throw error;
            }
        }
    },

    getters: {

        model(state) {

            return {
                userAuthorization: state.userAuthorization
            };
        },
    }
}