<template>
  <v-sheet>
        <!-- ****************************** -->
        <!-- BREADCRUMBS                    -->
        <!-- ****************************** -->
        <v-breadcrumbs class="secondary darken-1">
            <v-breadcrumbs-item :disabled="true">{{$t('tenant-list-template-editor.view.breadcrumb.root')}}</v-breadcrumbs-item>
            <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
            <v-breadcrumbs-item>{{name}}</v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-divider />

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form 
            :title="$t('tenant-list-template-editor.view.title', {name: name})" 
            v-model="model"
            @update-confirmed="save"
            @update-canceled="cancel"
            :editable="hasEditRole"
            >
            
            <template v-slot:default>
                <v-sheet color="grey lighten-3">
                    
                    <v-tabs 
                        v-model="selectedTab" 
                        :vertical="$vuetify.breakpoint.mdAndUp" 
                        color="grey" 
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
                        slider-color="grey" 
                        show-arrows 
                        :fixed-tabs="!$vuetify.breakpoint.mdAndUp">
                        

                        <!-- Tabs -->
                        <v-tab :key="0">{{$t('tenant-list-template-editor.view.tabs.general')}}</v-tab>
                        <v-tab :key="1">{{$t('tenant-list-template-editor.view.tabs.settings')}}</v-tab>
                        <v-tab :key="2">{{$t('tenant-list-template-editor.view.tabs.json')}}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="template">
                                    <template v-slot:content-append>
                                        <v-divider/>
                                        <v-card>
                                            <v-card-text>
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{
                                                    $t("tenant-list-template-editor.view.tabs.general.bookmark")
                                                    }}
                                                </div>
                                                 <v-switch
                                                    v-model="bookmark"
                                                    inset
                                                    :label="$t('tenant-list-template-editor.view.tabs.general.bookmark.subtitle')"
                                                    color="accent"
                                                ></v-switch>
                                            </v-card-text>
                                            <v-divider/>
                                            <v-card-text>
                                                <div class="text-subtitle-1 font-weight-medium pb-3">
                                                    {{ $t("tenant-list-template-editor.view.tabs.general.information") }}
                                                </div>
                                                <v-text-field
                                                    disabled
                                                    filled
                                                    :label="$t('tenant-list-template-editor.view.tabs.general.collectionId')"
                                                    persistent-hint
                                                    :value="collectionId"
                                                />
                                                <v-text-field
                                                    disabled
                                                    filled
                                                    :label="$t('tenant-list-template-editor.view.tabs.general.templateId')"
                                                    persistent-hint
                                                    :value="name"
                                                />
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </c-name-and-i18n-item-editor-page>
                            </v-tab-item>

                            <!-- Settings Access -->
                            <v-tab-item :key="1">
                                <c-settings-item-editor-page v-model="model" :schema="schema"/>
                            </v-tab-item>

                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
   
        </c-item-editor-form>
    </v-sheet>
</template>

<script>
import { get } from 'lodash';
import Roles from '@/roles';
import TenantListTemplatesEditorModule from '@/core/store/modules/catalog/tenant-list-templates/tenant-list-templates-editor.module';

const MODULE_NAME = 'tenantListTemplatesEditor';

export default {

    name: 'tenant-list-template-editor',
    
    props: {
        
        value: {
            type: Object,
            required: true,
        }
    },

    components: {
        
    },

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    data: () =>({
        selectedTab : 0
     }),

 

    computed: {

        template: {
            get() {
                return get(this.model, 'tenantListTemplate', {});
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateTenantListTemplate", value);
            }
        },

        schema() {
            return get(this.model, 'schema', {});
        },

        collectionId() {
            return get(this.template, 'collectionId', "");
        },

        templateId(){
            return get(this.template, 'templateId', "");
        },

        name(){
            return get(this.value, 'name', "");
        },

        hasReadRole() {
            return this.userContextService.hasRole(Roles.TENANT_LIST_TEMPLATES_READ);
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.TENANT_LIST_TEMPLATES_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasRole(Roles.TENANT_LIST_TEMPLATES_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasRole(Roles.TENANT_LIST_TEMPLATES_DELETE);
        },

        bookmark: {
            get() {
                return this.$store.getters[MODULE_NAME + '/bookmark'];
            },
            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateBookmark", value);
            }
            
        },

        model: {

            get() {
                return this.$store.getters[MODULE_NAME + '/model'];
            }, 
            
            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateModel", value);
            },
        },

     
    },

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                let context = {
                    name : this.name
               }
                await this.$store.dispatch(MODULE_NAME + "/loadData", context)
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },



        async save() {
            
            this.progressIndicatorService.show();
            try {
                let context = {
                    name : this.name
               }
                await this.$store.dispatch(MODULE_NAME + "/saveData", context)
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.go(-1)
        }
    },

      created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, TenantListTemplatesEditorModule);
        }

        this.refresh();
        
    }

}
</script>

<i18n>
{
    "en" : {
        "tenant-list-template-editor.view.title" : "Tenant List Template {name}",

        "tenant-list-template-editor.view.tabs.general" : "General",
        "tenant-list-template-editor.view.tabs.settings" : "Settings",
        "tenant-list-template-editor.view.tabs.json" : "JSON",

        "tenant-list-template-editor.view.tabs.general.collectionId": "Collection",
        "tenant-list-template-editor.view.tabs.general.templateId": "Template",

        "tenant-list-template-editor.view.tabs.general.information": "Information",
        "tenant-list-template-editor.view.tabs.general.bookmark": "Bookmark",

        "tenant-list-template-editor.view.tabs.general.bookmark.subtitle": "Bookmarked templates are prioritized in the template menu of the corresponding collection.",

        "tenant-list-template-editor.view.breadcrumb.root" : "Tenant List Templates"
    },

    "de" : {
        "tenant-list-template-editor.view.title" : "Listenvorlage (Benutzer): {name}",
        "tenant-list-template-editor.view.tabs.general" : "Allgemein",
        "tenant-list-template-editor.view.tabs.settings" : "Einstellungen",
        "tenant-list-template-editor.view.tabs.json" : "JSON",

        "tenant-list-template-editor.view.tabs.general.collectionId": "Datenbereich",
        "tenant-list-template-editor.view.tabs.general.templateId": "Vorlage",

        "tenant-list-template-editor.view.tabs.general.information": "Information",
        "tenant-list-template-editor.view.tabs.general.bookmark": "Favorit",

        "tenant-list-template-editor.view.tabs.general.bookmark.subtitle": "Favorisierte Vorlagen werden bevorzugt im Vorlagenmenü des zugehörigen Datenbereichs angezeigt.",

        "tenant-list-template-editor.view.breadcrumb.root" : "Zugriffsberechtigungen"
    }
}
</i18n>