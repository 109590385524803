import api from '@/store/api';

export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(),

        filter: getDefaultFilter()
    },

    mutations: {

        setResponse(state, response) {

            if (response) {
                state.response = response;
            }

        },

        setFilter(state, filter) {

            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter();
            }
        },
    },

    actions: {

        async loadResponse(context, {from, pageSize}) {

            try {
                let response = await api.catalog.tenantListTemplates.findByFilter(context.state.filter, from, pageSize);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        },

        async deleteTemplate(context, {collectionId, name}) {

            try {
                let response = await api.catalog.tenantListTemplates.delete(collectionId, name);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }
        }
    },

    getters: {

        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        },
        request(state) {
            return {

                name: state.schema.name,
                indexName: state.schema.name,
                attributes: {},
                i18n: {}
            };
        },
       
    }
}

function getDefaultResponse() {

    return {
        items: []
    };
}

function getDefaultFilter() {
    return '*';
}