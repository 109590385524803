import api from '../../../api/battery-data-hub.api';
import { get } from 'lodash';



export default {

    namespaced: true,

    state : {

        manufacturers: createDefaultManufacturers(),
    },

    mutations: {

        setManufacturers(state, setManufacturers) {

            if (setManufacturers) {
                state.manufacturers.tenantId = setManufacturers.tenantId;
                state.manufacturers.name = setManufacturers.name;
                state.manufacturers.i18n = setManufacturers.i18n;
                state.manufacturers.data = setManufacturers.data;
                state.manufacturers.active = setManufacturers.active;
                state.manufacturers.references = setManufacturers.references;
                state.manufacturers.creationDateTime     = setManufacturers.creationDateTime;
                state.manufacturers.creationUser         = setManufacturers.creationUser;
                state.manufacturers.modificationDateTime = setManufacturers.modificationDateTime;
                state.manufacturers.modificationUser     = setManufacturers.modificationUser;
            } else {
                state.manufacturers.tenantId = null;
                state.manufacturers.name = null;
                state.manufacturers.i18n = {};
                state.manufacturers.data = {"custData" : {}};
                state.manufacturers.active = null;
                state.manufacturers.references = {};
                state.manufacturers.creationDateTime     = setManufacturers.creationDateTime;
                state.manufacturers.creationUser         = setManufacturers.creationUser;
                state.manufacturers.modificationDateTime = setManufacturers.modificationDateTime;
                state.manufacturers.modificationUser     = setManufacturers.modificationUser;
            }
            
        }

    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.manufacturers.findById(name);
                context.commit('setManufacturers', get(response, 'item.manufacturer', createDefaultManufacturers()));
            } catch (error) {
                context.commit('manufacturersId', createDefaultManufacturers());
                throw error;
            }
        },

        async saveData(context) {

            try {
                const payload = get(context.getters.model, 'manufacturers');
                let response = await api.masterdata.manufacturers.update(payload);
                context.commit('setManufacturers', get(response, 'item.manufacturer', createDefaultManufacturers()));

            } catch (error) {
                context.commit('setManufacturers', createDefaultManufacturers());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            context.commit('setManufacturers', get(model, 'manufacturers', createDefaultManufacturers()));
        },

        updateManufacturers(context, manufacturers) {
            context.commit('setManufacturers', manufacturers);
        }
    },

    getters: {
        
        model(state) {
            return {
                manufacturers: state.manufacturers
            }
        },
        
    }
}

function createDefaultManufacturers() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {custData: {}},
        active: null,
        references: {},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }}
