<template>
    <v-card tile flat>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="4">
                    <v-sheet class="grey lighten-3 pa-3 text-body-2">
                        <v-chip color="grey" label>{{ $t('battery-material-matrix-filter-summary.component.batterymodel')
                        }}</v-chip>
                        <span class="ml-3 font-italic">{{ batteryModelsFilterItemsCommaSeparatedList }}</span>
                    </v-sheet>

                </v-col>

                <v-col cols="12" md="4">
                    <v-sheet class="grey lighten-3 pa-3 text-body-2">
                        <v-chip color="grey" label>{{ $t('battery-material-matrix-filter-summary.component.manifacturer')
                        }}</v-chip>
                        <span class="ml-3 font-italic">{{ manufacturerFilterItemsCommaSeparatedList }}</span>
                    </v-sheet>
                </v-col>

                <v-col cols="12" md="4">
                    <v-sheet class="grey lighten-3 pa-3 text-body-2">
                        <v-chip color="grey" label>{{ $t('battery-material-matrix-filter-summary.component.materials')
                        }}</v-chip>
                        <span class="ml-3 font-italic">{{ materialsFilterItemsCommaSeparatedList }}</span>
                    </v-sheet>

                </v-col>


            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { get, isEmpty } from 'lodash';

export default {

    props: {

        value: {
            type: Object,
            required: true,
        }
    },

    computed: {

        filter() {
            return get(this.value, 'filter', {});
        },


        batteryModelsFilterItems() {
            return get(this.filter, 'batteryModelsFilterItems', []);
        },

        batteryModelsFilterItemsMap() {
            return get(this.value, 'batteryModelsFilterItems', {})
        },

        batteryModelsFilterItemsCommaSeparatedList() {
            if (isEmpty(this.batteryModelsFilterItems)) {
                return '*';
            } else {
                return `${this.batteryModelsFilterItems.map(v => this.$dxs.i18n.localizedName(this.batteryModelsFilterItemsMap[v], this.$i18n.locale)).join('; ')}`;
            }
        },



        manufacturerFilterItems() {
            return get(this.filter, 'manufacturerFilterItems', []);
        },

        manufacturerFilterItemsMap() {
            return get(this.value, 'manufacturerFilterItems', {})
        },

        manufacturerFilterItemsCommaSeparatedList() {

            if (isEmpty(this.manufacturerFilterItems)) {
                return '*';
            } else {
                return `${this.manufacturerFilterItems.map(v => this.$dxs.i18n.localizedName(this.manufacturerFilterItemsMap[v], this.$i18n.locale)).join('; ')}`;
            }


        },


        materialsFilterItems() {
            return get(this.filter, 'materialsFilterItems', []);
        },

        materialsFilterItemsMap() {
            return get(this.value, 'materialsFilterItems', {})
        },
        materialsFilterItemsCommaSeparatedList() {
            if (isEmpty(this.materialsFilterItems)) {
                return '*';
            } else {
                return `${this.materialsFilterItems.map(v => this.$dxs.i18n.localizedName(this.materialsFilterItemsMap[v], this.$i18n.locale)).join('; ')}`;
            }

        },
    }
}
</script>

<i18n>
{
    "en" : {
        "battery-material-matrix-filter-summary.component.manifacturer": "Manufactures",
        "battery-material-matrix-filter-summary.component.batterymodel": "Battery Models",
        "battery-material-matrix-filter-summary.component.materials": "Materials"
    },

    "de" : {
        "battery-material-matrix-filter-summary.component.manifacturer": "Hersteller",
        "battery-material-matrix-filter-summary.component.batterymodel": "Battery Modelle",
        "battery-material-matrix-filter-summary.component.materials": "Materialien"
    }
}
</i18n>