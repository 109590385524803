import api from '../../../api/battery-data-hub.api';
import { get } from 'lodash';



export default {

    namespaced: true,

    state : {

        batteryCategory: createDefaultBatteryCategory(),
    },

    mutations: {

        setBatteryCategory(state, setBatteryCategory) {

            if (setBatteryCategory) {
                state.batteryCategory.tenantId = setBatteryCategory.tenantId;
                state.batteryCategory.name = setBatteryCategory.name;
                state.batteryCategory.i18n = setBatteryCategory.i18n;
                state.batteryCategory.data = setBatteryCategory.data;
                state.batteryCategory.active = setBatteryCategory.active;
                state.batteryCategory.references = setBatteryCategory.references;
                state.batteryCategory.creationDateTime     = setBatteryCategory.creationDateTime;
                state.batteryCategory.creationUser         = setBatteryCategory.creationUser;
                state.batteryCategory.modificationDateTime = setBatteryCategory.modificationDateTime;
                state.batteryCategory.modificationUser     = setBatteryCategory.modificationUser;
            } else {
                state.batteryCategory.tenantId = null;
                state.batteryCategory.name = null;
                state.batteryCategory.i18n = {};
                state.batteryCategory.data = {"custData" : {}};
                state.batteryCategory.active = null;
                state.batteryCategory.references = {};
                state.batteryCategory.creationDateTime     = setBatteryCategory.creationDateTime;
                state.batteryCategory.creationUser         = setBatteryCategory.creationUser;
                state.batteryCategory.modificationDateTime = setBatteryCategory.modificationDateTime;
                state.batteryCategory.modificationUser     = setBatteryCategory.modificationUser;
            }
            
        }

    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.batteryCategories.findById(name);
                context.commit('setBatteryCategory', get(response, 'item.batteryCategory', createDefaultBatteryCategory()));
            } catch (error) {
                context.commit('batteryCategoryId', createDefaultBatteryCategory());
                throw error;
            }
        },

        async saveData(context) {

            try {
                const payload = get(context.getters.model, 'batteryCategory');
                console.log(JSON.stringify(response))
                console.log(JSON.stringify(payload))
                let response = await api.masterdata.batteryCategories.update(payload);
                context.commit('setBatteryCategory', get(response, 'item.batteryCategory', createDefaultBatteryCategory()));

            } catch (error) {
                console.log(error);
                context.commit('setBatteryCategory', createDefaultBatteryCategory());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            console.log('+++ updateModel ', JSON.stringify(model, true, 2));
            context.commit('setBatteryCategory', get(model, 'batteryCategory', createDefaultBatteryCategory()));
        },

        updateBatteryCategory(context, batteryCategory) {
            console.log('+++ updatebatteryCategory ', JSON.stringify(batteryCategory, true, 2));
            context.commit('setBatteryCategory', batteryCategory);
        }
    },

    getters: {
        
        model(state) {
            return {
                batteryCategory: state.batteryCategory
            }
        },
        
    }
}

function createDefaultBatteryCategory() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {custData: {}},
        active: null,
        references: {},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }}
