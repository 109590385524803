<template>
  <v-card
    v-bind="$attrs"
    tile
  >
    <v-card-text>
        <div class="text-h6" v-if="serviceUp">{{ value | localized-name($i18n.locale) }}</div>
        <div class="error white--text text-h6" v-else>{{ value | localized-name($i18n.locale) }}</div>
        <div class="text-title" >{{ value.name }}</div>
    </v-card-text>

    <v-card-text v-if="serviceUp">
      <v-icon large color="grey" class="ma-1">{{ up }}</v-icon>
      {{ $t("system-availability-tile.component.status.ok.label") }}
    </v-card-text>
    <v-card-text v-else class="error white--text">
      <v-icon large color="white" class="ma-1">{{ down }}</v-icon>
      {{ $t("system-availability-tile.component.status.error.label") }}
    </v-card-text>

    <template v-if="hasDependentServices">
        <v-divider></v-divider>
        <v-subheader>{{ $t("system-availability-tile.component.dependencies.label") }}</v-subheader>
        <v-card-text>
            <v-chip label v-for="dependency in dependentServices" :key="'chip_'+ dependency.name" class="ma-2">
                {{dependency | localized-name($i18n.locale)}}
            </v-chip>
        </v-card-text>
    </template>

    <template v-if="value.superServices">
        <v-divider></v-divider>
        <v-subheader>{{ $t("system-availability-tile.component.dependencies.label") }}</v-subheader>
        <v-card-text>
            <v-chip  label  v-for="superService in value.superServices" :key="'chip_'+ superService" class="ma-2">
                {{superService}}
            </v-chip>
        </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { get, isEmpty } from "lodash";
export default {
  
  props: {
    value: {
      type: Object,
      required: true,
    },
    actual: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      up: "check_circle",
      down: "error",
      live: "warning",
    };
  },

  computed: {
    status() {
      return get(this.actual, "status", "DOWN") ? get(this.actual, "status", "DOWN") : "DOWN";
    },

    serviceUp() {
        return this.status === 'UP';
    },

    serviceDown() {
        return this.status === 'DOWN';
    },

    dependentServices() {
        return get(this.value, 'dependsOn', []);
    },

    hasDependentServices() {
        return !isEmpty(this.dependentServices);
    }


  },
};
</script>

<i18n>
{
    "en":{
        "system-availability-tile.component.status.ok.label": "The service is available.",
        "system-availability-tile.component.status.error.label": "The service is currently not available.",
        "system-availability-tile.component.status.live.label": "The service is available, underlying services are not!",
        "system-availability-tile.component.dependencies.label": "Dependencies",
        "SmallRye Reactive Messaging - readiness check": "Kafka Service Health Check"
    },
    "de":{
        "system-availability-tile.component.status.ok.label": "Der Service ist verfügbar.",
        "system-availability-tile.component.status.error.label": "Der Service ist derzeit nicht verfügbar.",
        "system-availability-tile.component.status.live.label": "Der Service ist derzeit verfügbar, die Subdienste sind es nicht!",
        "system-availability-tile.component.dependencies.label": "Abhängigkeiten",
        "SmallRye Reactive Messaging - readiness check": "Kafka Service Health Check"
    }
}
</i18n>