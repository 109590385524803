<template>
<v-sheet class="secondary darken-1">
    
    <c-sticky-content>
        <v-toolbar color="secondary darken-1" flat >
            <v-toolbar-items>
                <v-btn icon><v-icon @click="cancel">close</v-icon></v-btn>
            </v-toolbar-items>
            <v-breadcrumbs>
                <v-breadcrumbs-item>Mapping Jobs</v-breadcrumbs-item>
                <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                <v-breadcrumbs-item disabled>{{mappingJobInstanceId | truncate-middle(20)}}</v-breadcrumbs-item>
            </v-breadcrumbs>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon><v-icon @click="refresh">refresh</v-icon></v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
    </c-sticky-content>
    <c-section :title="mappingJobInstanceId | truncate-middle(42)" subtitle="Auf diesem Dashboard finden Sie alle Informationen zur Prozessausführung" class="secondary">
        
        <v-container fluid>
            <!-- Layout Fix: v-switch must be centered with this css -->
            <div class="row align-center justify-center ma-5">
                <v-switch 
                    inset 
                    v-model="autoRefresh" 
                    color="secondary lighten-3"  
                    :label="autoRefresh ? $t('mapping-job-instance-details.view.actions.autoUpdate.ON') : $t('mapping-job-instance-details.view.actions.autoUpdate.OFF')" />
            </div>
        </v-container>
<!--
        <v-stepper alt-labels  flat>
            <v-stepper-header>
                <v-stepper-step step="1">Started</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2">Running</v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" complete-icon="complete" complete>Finished</v-stepper-step>
            </v-stepper-header>
        </v-stepper>
-->

        <template v-if="!jobExists">
            <v-container fluid >
                <v-row justify="center" >
                    <v-col cols="12" lg="10">
                        <v-card tile>
                            <v-card-text class="text-center">
                                <v-icon x-large color="accent">info</v-icon>
                            </v-card-text>
                            <v-card-text class="text-h6 text-center">{{$t('mapping-job-instance-details.view.noData.title')}}</v-card-text>
                            <v-card-text class="text-center">
                               {{$t('mapping-job-instance-details.view.noData.subtitle')}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>

        <template v-else>
            <v-tabs v-model="selectedTab" color="secondary darken-2" background-color="secondary" fixed-tabs>
                
                <v-tab :key="0">{{$t('mapping-job-instance-details.view.tabs.dashboard.title')}}</v-tab>
                <v-tab :key="1">{{$t('mapping-job-instance-details.view.tabs.json.title')}}</v-tab>

                <v-tabs-items v-model="selectedTab" class="grey lighten-3">
                    <v-tab-item :key="0">
                        <v-container fluid>
                            <v-row justify="center" v-if="!mappingJobTemplateExists">
                                <v-col cols="12" lg="10">
                                    <v-card color="accent" tile>
                                        <v-card-text class="text-body-1 white--text">
                                            <v-icon>info</v-icon> 
                                            {{ $t('mapping-job-instance-details.view.info.reindex') }}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row> 
                            <v-row justify="center">
                                <v-col cols="12" lg="10">
                                    <v-card tile>
                                        <v-card-text>
                                            <div class="text-h6">{{$t('mapping-job-instance-details.view.jobStatus.title')}}</div>
                                            <div>{{$t('mapping-job-instance-details.view.jobStatus.subtitle')}}</div>
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader>{{$t('mapping-job-instance-details.view.jobStatus.progress')}}</v-subheader>
                                        <v-card-text>
                                            <v-progress-linear striped color="accent" rounded background-color="grey lighten-3" :value="jobProgressPercent" height="35" >
                                                <div>{{sumDocumentCountTasks | integer-format($i18n.locale) }} / {{ documentCount | integer-format($i18n.locale)}} ({{ jobProgressPercent | floor(2) }} %)</div>
                                            </v-progress-linear>
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader>{{$t('mapping-job-instance-details.view.jobStatus.tasks.title')}}</v-subheader>
                                        <v-card-text>
                                            {{$t('mapping-job-instance-details.view.jobStatus.tasks.subtitle')}}
                                        </v-card-text>
                                        <v-simple-table>
                                            <thead>
                                                <tr>
                                                    <th>{{$t('mapping-job-instance-details.view.jobStatus.tasks.cols.taskId')}}</th>
                                                    <th>{{$t('mapping-job-instance-details.view.jobStatus.tasks.cols.taskStartDateTime')}}</th>
                                                    <th>{{$t('mapping-job-instance-details.view.jobStatus.tasks.cols.taskEndDateTime')}}</th>
                                                    <th class="text-right">{{$t('mapping-job-instance-details.view.jobStatus.tasks.cols.taskDuration')}}</th>
                                                    <th class="text-right">{{$t('mapping-job-instance-details.view.jobStatus.tasks.cols.documentCountTotal')}}</th>
                                                    <th class="text-right">{{$t('mapping-job-instance-details.view.jobStatus.tasks.cols.documentCountSuccess')}}</th>
                                                    <th class="text-right">{{$t('mapping-job-instance-details.view.jobStatus.tasks.cols.documentCountFailed')}}</th>
                                                    <th>{{$t('mapping-job-instance-details.view.jobStatus.tasks.cols.documentRatioSuccessFailed')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(task, index) of tasks" :key="'task_tr_' + index">
                                                    <td :title="task.data.taskInstanceId">{{task.data.taskInstanceId | truncate-middle(24)}}</td>
                                                    <td>{{task.data.taskStartDateTime | localized-date-time-format($i18n.locale)}}</td>
                                                    <td>{{task.data.taskEndDateTime | localized-date-time-format($i18n.locale)}}</td>
                                                    <td class="text-right">{{getDurationMiliseconds(task.data.taskStartDateTime, task.data.taskEndDateTime)}}</td>
                                                    <td class="text-right">{{task.data.documentCountTotal | integer-format($i18n.locale)}}</td>
                                                    <td class="text-right">{{task.data.documentCountSuccess | integer-format($i18n.locale)}}</td>
                                                    <td class="text-right">{{task.data.documentCountFailed | integer-format($i18n.locale)}}</td>
                                                    <td :title="  getRatioOkNok(task) | floor(2)  ">
                                                        <v-progress-linear :value="getRatioOkNok(task) | floor(2)" color="success" background-color="error" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr class="grey lighten-3">
                                                    <td colspan="3" class="text-right">{{$t('mapping-job-instance-details.view.jobStatus.tasks.total')}}</td>

                                                    <td class="text-right">-</td>
                                                    <td class="text-right">{{sumDocumentCountTasks | integer-format($i18n.locale)}}</td>
                                                    <td class="text-right">{{sumDocumentCountTasksSuccess | integer-format($i18n.locale)}}</td>
                                                    <td class="text-right">{{sumDocumentCountTasksFailed | integer-format($i18n.locale)}}</td>
                                                    <td :title="  getRatioOkNokJob() | floor(2)  ">
                                                         <v-progress-linear :value="getRatioOkNokJob() | floor(2)" color="success" background-color="error" />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </v-simple-table>
                                        <v-divider />
                                        <v-card-actions>
                                            <v-btn text color="accent" block @click="openTaskList"><v-icon class="mr-5">arrow_downward</v-icon>{{$t('mapping-job-instance-details.view.jobStatus.tasks.actions.allTasks')}}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                               
                            <v-row justify="center">
                                <v-col cols="12" md="5">
                                    <v-card tile height="100%">
                                        <v-card-text class="text-h6">{{$t('mapping-job-instance-details.view.dataset.documentCount.title')}}</v-card-text>
                                        <v-card-text class="text-h2 text-center">{{ documentCount }}</v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-card tile height="100%">
                                        <v-card-text class="text-h6">{{$t('mapping-job-instance-details.view.jobInfo.title')}}</v-card-text>
                                        <v-card-text><div>{{$t('mapping-job-instance-details.view.jobInfo.subtitle')}}</div></v-card-text>
                                        <v-simple-table>
                                            <tbody>
                                                <tr>
                                                    <td>{{$t('mapping-job-instance-details.view.jobInfo.jobInstanceId')}}</td>
                                                    <td>{{mappingJobInstanceId}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('mapping-job-instance-details.view.jobInfo.UserId')}}</td>
                                                    <td>{{mappingJobInstanceData.jobStartedByUserId}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('mapping-job-instance-details.view.jobInfo.startDateTime')}}</td>
                                                    <td>{{mappingJobInstanceData.jobStartDateTime | localized-date-time-format($i18n.locale)}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('mapping-job-instance-details.view.jobInfo.jobParams')}}</td>
                                                    <td>{{mappingJobInstanceData.jobParams}}</td> 
                                                </tr>
                                            </tbody>
                                        </v-simple-table>

                                    </v-card>
                                </v-col>
                                
                            </v-row>

                            <!-- ERROR LOG -->
                            <v-row justify="center">
                                <v-col cols="12" lg="10">
                                    <v-card tile>
                                        <v-card-text>
                                            <div class="text-h6">{{$t('mapping-job-instance-details.view.errors.title')}}</div>
                                            <div>{{$t('mapping-job-instance-details.view.errors.subtitle')}}</div>
                                        </v-card-text>
                                        <v-card-text v-if="errorsEmpty">
                                            {{$t('mapping-job-instance-details.view.errors.noData.title')}}
                                        </v-card-text>
                                        <v-simple-table v-else>
                                            <thead>
                                                <tr>
                                                    <th class="text-center">{{$t('mapping-job-instance-details.view.errors.cols.details')}}</th>
                                                    <th>{{$t('mapping-job-instance-details.view.errors.cols.dateTime')}}</th>
                                                    <th>{{$t('mapping-job-instance-details.view.errors.cols.documentId')}}</th>
                                                    <th>{{$t('mapping-job-instance-details.view.errors.cols.errorType')}}</th>
                                                    <th>{{$t('mapping-job-instance-details.view.errors.cols.message')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(error, index) of errors" :key="'tr_error_' + index">
                                                <tr>
                                                    <td class="text-center"><v-icon @click="showErrorDetail(error)">bug_report</v-icon></td>
                                                    <td>{{error.data.errorDateTime | localized-date-time-format($i18n.locale)}}</td>
                                                    <td>{{error.data.sourceDocumentId}}</td>
                                                    <td>{{error.data.errorType | truncate(64)}}</td>
                                                    <td>{{error.data.errorMessage | truncate-middle(128)}}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                        <v-divider />
                                        <v-card-actions>
                                            <v-btn text color="accent" block @click="openErrorList"><v-icon class="mr-5">arrow_downward</v-icon>{{$t('mapping-job-instance-details.view.errors.actions.allErrors')}}</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row justify="center">

                                <!-- DATASET -->
                                <v-col cols="12" lg="5">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6">{{$t('mapping-job-instance-details.view.dataset.title')}}</div>
                                            <div>{{$t('mapping-job-instance-details.view.dataset.subtitle')}}</div>
                                        </v-card-text>

                                        <v-subheader>{{$t('mapping-job-instance-details.view.dataset.filter.title', { filterQLType: localizedSourceFilterQLType})}}</v-subheader>
                                        <v-card-text class="grey lighten-3">
                                            <pre>{{sourceFilter}}</pre>
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader>{{$t('mapping-job-instance-details.view.dataset.sourceCollection.title')}}</v-subheader>
                                        <v-card-text v-if="mappingJobTemplateExists">
                                            {{ sourceCollection | localized-name($i18n.locale) }} ({{ sourceCollection.name }})
                                        </v-card-text>
                                        <v-card-text v-else>
                                            {{ sourceCollectionName }}
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader>{{$t('mapping-job-instance-details.view.dataset.documentCount.title')}}</v-subheader>
                                        <v-card-text>
                                            {{documentCount}}
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" lg="5">

                                    <!-- TEMPLATE -->
                                    <v-card v-if="mappingJobTemplateExists" tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6">{{$t('mapping-job-instance-details.view.template.title')}}</div>
                                            <div>{{$t('mapping-job-instance-details.view.template.subtitle')}}</div>
                                            <div></div>
                                        </v-card-text>
                                        <v-simple-table>
                                            <tbody>
                                                <tr>
                                                    <td>{{$t('mapping-job-instance-details.view.template.items.template')}}</td>
                                                    <td>{{mappingJobTemplate | localized-name($i18n.locale)}}</td>
                                                    <td>{{mappingJobTemplate.name}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('mapping-job-instance-details.view.template.items.sourceCollection')}}</td>
                                                    <td>{{sourceCollection | localized-name($i18n.locale)}}</td>
                                                    <td>{{sourceCollection.name}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('mapping-job-instance-details.view.template.items.sourceSchema')}}</td>
                                                    <td>{{sourceSchema | localized-name($i18n.locale)}}</td>
                                                    <td>{{sourceSchema.name}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('mapping-job-instance-details.view.template.items.targetCollection')}}</td>
                                                    <td>{{targetCollection | localized-name($i18n.locale)}}</td>
                                                    <td>{{targetCollection.name}}</td>
                                                </tr>
                                                <tr>
                                                    <td>{{$t('mapping-job-instance-details.view.template.items.targetSchema')}}</td>
                                                    <td>{{targetSchema | localized-name($i18n.locale)}}</td>
                                                    <td>{{targetSchema.name}}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-card>
                                        
                                    <v-card v-else tile height="100%">
                                        <v-card-text>
                                            <div class="text-h6">{{$t('mapping-job-instance-details.view.template.title')}}</div>
                                            <div>{{$t('mapping-job-instance-details.view.template.reindex')}}</div>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-tab-item>

                    <v-tab-item :key="1">
                        <v-container>
                            <v-row justify="center">
                                <v-col cols="12" lg="9">
                                    <v-card tile>
                                        <v-toolbar flat>
                                            <v-toolbar-title>{{$t('mapping-job-instance-details.view.json.jobInstance.title')}}</v-toolbar-title>
                                        </v-toolbar>
                                        <v-divider />
                                        <v-card-text>
                                            <pre>{{JSON.stringify(mappingJobInstance, true, 2)}}</pre>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" lg="9">
                                    <v-card tile>
                                        <v-toolbar flat>
                                            <v-toolbar-title>{{$t('mapping-job-instance-details.view.json.jobTemplate.title')}}</v-toolbar-title>
                                        </v-toolbar>
                                        <v-divider />
                                        <v-card-text>
                                            <pre>{{JSON.stringify(mappingJobTemplate, true, 2)}}</pre>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </template>
    </c-section>

    <v-dialog v-model="showErrorDialog" width="800" >
        <c-error-detail-card tile flat :value="selectedError" @close="hideErrorDetail"  />
    </v-dialog>

</v-sheet>
</template>

<script>
import { get, isEmpty } from 'lodash';
const MODULE_NAME = "mappingJobInstanceDetails";

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0,
        autoRefreshState: false,
        autoRefreshTimer: null,

        selectedErrorTab: 0,
        /*
        selectedError: { 
            errorDateTime: new Date(),
            context: { 'type' : 'manual', timeout: 60000},
            errorType: 'java.lang.Exception',
            errorMessage: ' A very long message describing this error. A very long message describing this error.A very long message describing this errorA very long message describing this errorA very long message describing this errorA very long message describing this errorA very long message describing this errorA very long message describing this error'
            },
            */
        showErrorDialog: false,
        selectedError: null
    }),

    methods: {

        showErrorDetail(error) {
            this.showErrorDialog = true;

            const errorData = get(error, 'data', {});
            const errorContext = {};
            for (const [key, value] of Object.entries(errorData)) {

                if (key === 'errorStackTrace' || key === 'errorMessage') continue;
                errorContext[key] = value;
            }

            this.selectedError = {
                errorType : get(errorData, 'errorType', '?'),
                errorDateTime : get(errorData, 'errorDateTime', '?'),
                errorMessage : get(errorData, 'errorMessage', '?'),
                stackTrace : get(errorData, 'errorStackTrace', ''),
                context : errorContext
            };
        },

        hideErrorDetail() {
            this.showErrorDialog = false;
            this.selectedError = null;
        },
        
        async refresh() {

            const jobInstanceId = this.mappingJobInstanceId;
            this.$log.debug("Loading data for Mapping Job Instance " + jobInstanceId);
            
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + "/loadJobInstance", { jobInstanceId: jobInstanceId })
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async cancel() {
            this.$router.go(-1);
        },

        getDurationMiliseconds(startDateTime, endDateTime) {

             const start = new Date(startDateTime);
             const end = new Date(endDateTime);

             return end.valueOf() - start.valueOf()
        },

        getRatioOkNok(task) {
            const documentCountSuccess = task.data.documentCountSuccess;
            const documentCountFailed  = task.data.documentCountFailed;

            return (documentCountSuccess / (documentCountSuccess + documentCountFailed) ) * 100;
        },

        getRatioOkNokJob() {
            return (this.sumDocumentCountTasksSuccess / (this.sumDocumentCountTasksSuccess  + this.sumDocumentCountTasksFailed)) * 100;
        },

        openTaskList() {
            this.$router.push({
                name: 'collectionDocumentsView', 
                params: {
                    collectionId: 'MappingJobTaskLog'
                }
            })
        },

        openErrorList() {
            this.$router.push({
                name: 'collectionDocumentsView', 
                params: {
                    collectionId: 'MappingJobErrorLog'
                }
            })
        },
    },


    computed: {

        model() {
            return this.$store.getters[MODULE_NAME + '/model'];
        },

        tasks() {
            return get(this.model, 'tasks', []);
        },

        taskStats() {
            return get(this.model, 'taskStats', {});
        },
        
        tasksEmpty() {
            return isEmpty(this.tasks);
        },

        errors() {
            return get(this.model, 'errors', []);
        },

        errorsEmpty() {
            return isEmpty(this.errors);
        },

        mappingJobInstance() {
            return get(this.model, 'jobInstance', {});
        },

        mappingJobInstanceData() {
            return get(this.mappingJobInstance, 'data', {});
        },

        jobExists() {
            return !isEmpty(this.mappingJobInstance);
        },

        sourceFilter() {
            return get(this.mappingJobInstanceData, 'sourceFilter', null);
        },

        sourceFilterQLType() {
            return get(this.mappingJobInstanceData, 'sourceFilterQLType', null);
        },

        localizedSourceFilterQLType() {
            return this.$t('mapping-job-instance-details.view.dataset.sourceFilterQLType.' + this.sourceFilterQLType);
        },

        documentCount() {
            return get(this.mappingJobInstanceData, 'documentCount', null);
        },

        sumDocumentCountTasks() {
            return get(this.taskStats, 'documentCountTotal.sum', 0);
        },

        sumDocumentCountTasksSuccess() {
            return get(this.taskStats, 'documentCountSuccess.sum', 0);
        },

        sumDocumentCountTasksFailed() {
            return get(this.taskStats, 'documentCountFailed.sum', 0);
        },

        jobProgressPercent() {
            return (this.sumDocumentCountTasks / this.documentCount) * 100;
        },

        mappingJobInstanceId() {
            return get(this.value, 'name', null);
        },

        mappingJobTemplate() {
            return get(this.model, 'jobTemplate', {});
        },

        mappingJobTemplateExists() {
            return !isEmpty(this.mappingJobTemplate);
        },

        sourceCollection() {
            return get(this.model, 'sourceCollection', {});
        },

        sourceCollectionName() {
            return get(this.mappingJobInstanceData, 'sourceCollection', {});
        },

        sourceSchema() {
            return get(this.model, 'sourceSchema', {});
        },

        targetCollection() {
            return get(this.model, 'targetCollection', {});
        },

        targetSchema() {
            return get(this.model, 'targetSchema', {});
        },

        autoRefresh: {

            get() {
                return this.autoRefreshState;
            },

            set(value) {
                this.autoRefreshState = value;
                if (this.autoRefreshState) {
                    this.autoRefreshTimer = setInterval(this.refresh, 30000);
                } else {
                    if (this.autoRefreshTimer) {
                        clearInterval(this.autoRefreshTimer);
                        this.autoRefreshTimer = null
                    }
                }
            }
        }

    },

    created() {
        
        this.refresh();
        this.autoRefresh = false;
    },

    beforeDestroy() {
        if (this.autoRefreshTimer) {
            clearInterval(this.autoRefreshTimer);
        }
    }
    
}
</script>

<i18n>
{
    "en" : {

        "mapping-job-instance-details.view.actions.autoUpdate.OFF" : "Automatic Refresh of View is disabled.",
        "mapping-job-instance-details.view.actions.autoUpdate.ON" : "Automatic Refresh of View every 30 Seconds.",

        "mapping-job-instance-details.view.noData.title" : "No data is currently available for this process",
        "mapping-job-instance-details.view.noData.subtitle" : "Please refresh this view at a later time for process execution information.",

        "mapping-job-instance-details.view.tabs.dashboard.title" : "Dashboard",
        "mapping-job-instance-details.view.tabs.json.title" : "JSON",

        "mapping-job-instance-details.view.jobStatus.title" : "Job Status & Progress",
        "mapping-job-instance-details.view.jobStatus.subtitle" : "This card shows information on the current's job execution status and it's progress.",
        "mapping-job-instance-details.view.jobStatus.progress" : "Overall progress",
        
        "mapping-job-instance-details.view.jobStatus.tasks.title" : "Tasks",
        "mapping-job-instance-details.view.jobStatus.tasks.subtitle" : "Mapping jobs are split into tasks. Tasks are processed in parallel for reasons of performance. This table contains the 50 most current tasks for this mapping job instance. In order to load all tasks of this mapping job instance, please click the button below.",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.taskId" : "ID",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.taskStartDateTime" : "Start",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.taskEndDateTime" : "End",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.taskDuration" : "Duration (ms)",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.documentCountTotal" : "Documents Total",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.documentCountSuccess" : "Documents OK",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.documentCountFailed" : "Documents NOK",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.documentRatioSuccessFailed" : "Ratio OK/NOK",
        "mapping-job-instance-details.view.jobStatus.tasks.actions.allTasks" : "All Tasks...",
        "mapping-job-instance-details.view.jobStatus.tasks.total" : "Total",

        "mapping-job-instance-details.view.jobInfo.title" : "Job Info",
        "mapping-job-instance-details.view.jobInfo.subtitle" : "This card shows common information for this job.",
        "mapping-job-instance-details.view.jobInfo.jobInstanceId" : "Process Instance Id",
        "mapping-job-instance-details.view.jobInfo.UserId" : "Started from user",
        "mapping-job-instance-details.view.jobInfo.startDateTime" : "Started on",
        "mapping-job-instance-details.view.jobInfo.jobParams" : "Calling Parameters",

        "mapping-job-instance-details.view.dataset.title" : "Dataset",
        "mapping-job-instance-details.view.dataset.subtitle" : "This card shows information on the dataset which is processed by this job.",
        "mapping-job-instance-details.view.dataset.filter.title" : "Filter Expression in Query Language {filterQLType}",
        "mapping-job-instance-details.view.dataset.sourceCollection.title" : "Source Collection",
        "mapping-job-instance-details.view.dataset.documentCount.title" : "Document Count",
        "mapping-job-instance-details.view.dataset.documentCount.text" : "{documentCount} documents are processed by this mapping job instance.",
        "mapping-job-instance-details.view.dataset.sourceFilterQLType.SIMPLE" : "Simple Filter Expression",
        "mapping-job-instance-details.view.dataset.sourceFilterQLType.LUCENE" : "Lucene Filter Expression",

        "mapping-job-instance-details.view.template.title" : "Job Template",
        "mapping-job-instance-details.view.template.subtitle" : "This card contains information on the template used for this job.",
        "mapping-job-instance-details.view.template.reindex" : "Reindex instances do not contain information on the template used for this job.",
        "mapping-job-instance-details.view.template.items.template" : "Template",
        "mapping-job-instance-details.view.template.items.sourceCollection" : "Source Collection",
        "mapping-job-instance-details.view.template.items.sourceSchema" : "Source Schema",
        "mapping-job-instance-details.view.template.items.targetCollection" : "Target Collection",
        "mapping-job-instance-details.view.template.items.targetSchema" : "Target Schema",

        "mapping-job-instance-details.view.errors.title" : "Errors",
        "mapping-job-instance-details.view.errors.subtitle" : "This card contains a list of errors which occured during execution of the mapping job.",
        "mapping-job-instance-details.view.errors.noData.title" : "There are no errors.",
        "mapping-job-instance-details.view.errors.actions.allErrors" : "All Errors ...",
        "mapping-job-instance-details.view.errors.cols.details" : "Details",
        "mapping-job-instance-details.view.errors.cols.dateTime" : "Date & Time",
        "mapping-job-instance-details.view.errors.cols.documentId" : "Document ID",
        "mapping-job-instance-details.view.errors.cols.errorType" : "Error Type",
        "mapping-job-instance-details.view.errors.cols.message" : "Error Msg",

        "mapping-job-instance-details.view.json.jobInstance.title" : "Job Instance",
        "mapping-job-instance-details.view.json.jobTemplate.title" : "Job Template",

        "mapping-job-instance-details.view.info.reindex" : "This job instance does not contain information about the mapping job template. With high probability thats due to the fact, that this instance is a reindex job."
    },

    "de" : {

        "mapping-job-instance-details.view.actions.autoUpdate.OFF" : "Die Ansicht wird nicht automatisch aktualisiert.",
        "mapping-job-instance-details.view.actions.autoUpdate.ON" : "Die Ansicht wird alle 30 Sekunden automatisch aktualisiert",

        "mapping-job-instance-details.view.noData.title" : "Zu diesem Prozess liegen aktuell noch keine Daten vor",
        "mapping-job-instance-details.view.noData.subtitle" : "Bitte aktualisieren Sie diese Ansicht zu einem späteren Zeitpunkt, um Informationen über die Prozessausführung zu erhalten.",

        "mapping-job-instance-details.view.tabs.dashboard.title" : "Dashboard",
        "mapping-job-instance-details.view.tabs.json.title" : "JSON",
 
        "mapping-job-instance-details.view.jobStatus.title" : "Prozess-Status",
        "mapping-job-instance-details.view.jobStatus.subtitle" : "Diese Karte zeigt Informationen zum Status des Prozesses und zum Fortschritt der Verarbeitung.",
        "mapping-job-instance-details.view.jobStatus.progress" : "Gesamtfortschritt",

        "mapping-job-instance-details.view.jobStatus.tasks.title" : "Tasks",
        "mapping-job-instance-details.view.jobStatus.tasks.subtitle" : "Prozesse werden in Tasks unterteilt. Tasks werden parallel verarbeitet. Die folgende Tabelle zeigt Ihnen die letzten 50 Tasks dieses Prozesses. ",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.taskId" : "ID",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.taskStartDateTime" : "Start",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.taskEndDateTime" : "Ende",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.taskDuration" : "Dauer (ms)",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.documentCountTotal" : "Anz. Dokumente",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.documentCountSuccess" : "Anz. Dokumente OK",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.documentCountFailed" : "Anz. Dokumente NOK",
        "mapping-job-instance-details.view.jobStatus.tasks.cols.documentRatioSuccessFailed" : "Ratio OK/NOK",
        "mapping-job-instance-details.view.jobStatus.tasks.actions.allTasks" : "Alle Tasks anzeigen ...",
        "mapping-job-instance-details.view.jobStatus.tasks.total" : "Summe",

        "mapping-job-instance-details.view.dataset.title" : "Daten",
        "mapping-job-instance-details.view.dataset.subtitle" : "Diese Karte zeigt Informationen zu den verarbeiteten Daten der Prozess-Instanz.",
        "mapping-job-instance-details.view.dataset.filter.title" : "Filterausdruck in Abfragesprache {filterQLType}",
        "mapping-job-instance-details.view.dataset.documentCount.title" : "Anzahl Dokumente",
        "mapping-job-instance-details.view.dataset.sourceCollection.title" : "Quell-Datenbereich",
        "mapping-job-instance-details.view.dataset.sourceFilterQLType.SIMPLE" : "Volltext-Filterausdruck",
        "mapping-job-instance-details.view.dataset.sourceFilterQLType.LUCENE" : "Lucene-Filterausdruck",

        "mapping-job-instance-details.view.jobInfo.title" : "Prozess-Info",
        "mapping-job-instance-details.view.jobInfo.subtitle" : "Diese Karte zeigt allgemeine Kontext-Informationen zum Prozess an.",
        "mapping-job-instance-details.view.jobInfo.jobInstanceId" : "Prozess-Instanz ID",
        "mapping-job-instance-details.view.jobInfo.UserId" : "Gestartet von User",
        "mapping-job-instance-details.view.jobInfo.startDateTime" : "Gestartet am",
        "mapping-job-instance-details.view.jobInfo.jobParams" : "Aufruf-Parameter",

        "mapping-job-instance-details.view.template.title" : "Prozess-Vorlage",
        "mapping-job-instance-details.view.template.subtitle" : "Diese Karte enthält Information zur verwendeten Prozess-Vorlage",
        "mapping-job-instance-details.view.template.reindex" : "Neugeladene Instanzen enthalten keine Informationen zur Prozess-Vorlage.",
        "mapping-job-instance-details.view.template.items.template" : "Prozess-Vorlage",
        "mapping-job-instance-details.view.template.items.sourceCollection" : "Quell-Datenbereich",
        "mapping-job-instance-details.view.template.items.sourceSchema" : "Quell-Schema",
        "mapping-job-instance-details.view.template.items.targetCollection" : "Ziel-Datenbereich",
        "mapping-job-instance-details.view.template.items.targetSchema" : "Ziel-Schema",

        "mapping-job-instance-details.view.errors.title" : "Fehler",
        "mapping-job-instance-details.view.errors.subtitle" : "Diese Karte zeigt eine Liste der Fehler, die bei der Verarbeitung aufgetreten sind.",
        "mapping-job-instance-details.view.errors.noData.title" : "Es liegen keine Fehler in der Verarbeitung vor.",
        "mapping-job-instance-details.view.errors.actions.allErrors" : "Alle Fehler anzeigen ...",

        "mapping-job-instance-details.view.errors.cols.details" : "Details",
        "mapping-job-instance-details.view.errors.cols.dateTime" : "Zeitpunkt",
        "mapping-job-instance-details.view.errors.cols.documentId" : "Dokumenten-ID",
        "mapping-job-instance-details.view.errors.cols.errorType" : "Fehlertyp",
        "mapping-job-instance-details.view.errors.cols.message" : "Fehlertext",

        "mapping-job-instance-details.view.json.jobInstance.title" : "Prozess-Instanz",
        "mapping-job-instance-details.view.json.jobTemplate.title" : "Prozess-Vorlage",

        "mapping-job-instance-details.view.info.reindex" : "Diese Prozess-Instanz enthält keine Informationen über die Prozess-Vorlage. Wahrscheinlich liegt dies daran, dass diese Instanz eine Neuindizierung ist."

    }
}
</i18n>