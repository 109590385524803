import api from '@/store/api';

import { v4 as uuidv4  } from 'uuid';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {

        webhookSubscription: createEmptyWebhookSubscription()
    },

    mutations: {

        setWebhookSubscription(state, webhookSubscription) {

            if (webhookSubscription) {
                state.webhookSubscription.name = webhookSubscription.name ? webhookSubscription.name : null;
            } else {
                state.webhookSubscription.name = null;
            }

        },   


    },

    actions: {
    
        async createWebhookSubscription(context) {

            try {
                
                let request = context.getters.request;
                await api.webhooks.webhookSubscriptions.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetWebhookSubscription(context) {
            context.commit('setWebhookSubscription', createEmptyWebhookSubscription());
        },

        async updateWebhookSubscription(context, webhookSubscription) {
            
            try {
                context.commit('setWebhookSubscription', webhookSubscription);
            } catch (error) {
                return Promise.reject(error);
            }
        },

    },



    getters: {

        
        request(state) {
            let payload = state.webhookSubscription;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        webhookSubscription(state) {
            return state.webhookSubscription ? state.webhookSubscription : createEmptyWebhookSubscription();
        }
    }
}

function createEmptyWebhookSubscription() {

    return {
        "active": false,
        "clientSecret": uuidv4(),
        "headers":{},
        "custParams":{},
        "eventTypeSelector": '*',
        "i18n":{},
        "method": 'POST',
        "httpVersion": 'HTTP_1_1',
        "name": null,
        "retries":5,
        "timeoutMilliseconds":5000,
        "urlTemplate":'https://',
        "payload":"{\"tenantId\": \"{{ $tenant.tenantId }}\", \"webhookId\": \"{{ $webhook.webhookId }}\",\"webhookSubscriptionId\": \"{{ $webhookSubscription.name }}\",\"webhookDateTime\": \"{{ $now }}\", \"payload\":  {{ $event.data }}}",
        "contentType": "application/json",
        "creationDateTime": null,
        "creationUser": null,
        "modificationDateTime": null,
        "modificationUser": null

    };
}