import api from '@/store/api';
import { set } from 'lodash';

const DEFAULT_COLOR = '#caf1de';



export default {

    namespaced: true,

    state: {

        tag: createEmptyTag()
    },

    mutations: {

        setTag(state, tag) {

            if (tag) {
                state.tag.name = tag.name ? tag.name : null;
                state.tag.color = tag.color ? tag.color : DEFAULT_COLOR;
            } else {
                state.tag.name = null;
            }

        },   


    },

    actions: {
    
        async createTag(context) {

            try {
                
                let request = context.getters.request;
                await api.catalog.tags.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetTag(context) {
            context.commit('setTag', createEmptyTag());
        },

        async updateAccessControlList(context, accessControlList) {
            
            try {
                context.commit('setTag', accessControlList);
            } catch (error) {
                return Promise.reject(error);
            }
        },

    },



    getters: {

        
        request(state) {
            let payload = state.tag;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        tag(state) {
            return state.tag ? state.tag : createEmptyTag();
        }
    }
}

function createEmptyTag() {

    return {
        
        "name": null,
        
        "i18n": {
        },

        color: DEFAULT_COLOR

      }
}