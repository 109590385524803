export default [
    // {
    //     i18n: {
    //         "en": {
    //             "description": "",
    //             "languageTag": "en",
    //             "name": "Sum",
    //             "shortName": "Sum"
    //         },
    //         "de": {
    //             "description": "",
    //             "languageTag": "de",
    //             "name": "Summe",
    //             "shortName": "Summe"
    //         },
    //     },
    //     name: "SUM",
    // },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "Average",
                "shortName": "avg."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "Durchschnitt",
                "shortName": "Durchschn."
            },
        },
        name: "AVERAGE",
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "Minimum",
                "shortName": "Min."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "Minimum",
                "shortName": "Min."
            },
        },
        name: "MIN",
    },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "Maximum",
                "shortName": "Max."
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "Maximum",
                "shortName": "Max."
            },
        },
        name: "MAX",
    },
    // {
    //     i18n: {
    //         "en": {
    //             "description": "",
    //             "languageTag": "en",
    //             "name": "Count",
    //             "shortName": "Count"
    //         },
    //         "de": {
    //             "description": "",
    //             "languageTag": "de",
    //             "name": "Zählen",
    //             "shortName": "Zählen"
    //         },
    //     },
    //     name: "COUNT",
    // },
    // {
    //     i18n: {
    //         "en": {
    //             "description": "",
    //             "languageTag": "en",
    //             "name": "Cardinality",
    //             "shortName": "Cardinality"
    //         },
    //         "de": {
    //             "description": "",
    //             "languageTag": "de",
    //             "name": "Kardinalität",
    //             "shortName": "Kardinalität"
    //         },
    //     },
    //     name: "CARDINALITY",
    // },
    // {
    //     i18n: {
    //         "en": {
    //             "description": "",
    //             "languageTag": "en",
    //             "name": "VARIANCE",
    //             "shortName": "VARIANCE"
    //         },
    //         "de": {
    //             "description": "",
    //             "languageTag": "de",
    //             "name": "Varianz",
    //             "shortName": "Varianz"
    //         },
    //     },
    //     name: "VARIANCE",
    // },
    {
        i18n: {
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "Median",
                "shortName": "Median"
            },
            "de": {
                "description": "",
                "languageTag": "de",
                "name": "Median",
                "shortName": "Median"
            },
        },
        name: "MEDIAN",
    },
    // {
    //     i18n: {
    //         "en": {
    //             "description": "",
    //             "languageTag": "en",
    //             "name": "Percentile 25",
    //             "shortName": "Percentile 25"
    //         },
    //         "de": {
    //             "description": "",
    //             "languageTag": "de",
    //             "name": "Perzentil 25",
    //             "shortName": "Perzentil 25"
    //         },
    //     },
    //     name: "PERCENTILE_25",
    // },
    // {
    //     i18n: {
    //         "en": {
    //             "description": "",
    //             "languageTag": "en",
    //             "name": "Percentile 75",
    //             "shortName": "Percentile 75"
    //         },
    //         "de": {
    //             "description": "",
    //             "languageTag": "de",
    //             "name": "Perzentil 75",
    //             "shortName": "Perzentil 75"
    //         },
    //     },
    //     name: "PERCENTILE_75",
    // },
    // {
    //     i18n: {
    //         "en": {
    //             "description": "",
    //             "languageTag": "en",
    //             "name": "Percentile 90",
    //             "shortName": "Percentile 90"
    //         },
    //         "de": {
    //             "description": "",
    //             "languageTag": "de",
    //             "name": "Perzentil 90",
    //             "shortName": "Perzentil 90"
    //         },
    //     },
    //     name: "PERCENTILE_90",
    // },
    // {
    //     i18n: {
    //         "en": {
    //             "description": "",
    //             "languageTag": "en",
    //             "name": "Percentile 95",
    //             "shortName": "Percentile 95"
    //         },
    //         "de": {
    //             "description": "",
    //             "languageTag": "de",
    //             "name": "Perzentil 95",
    //             "shortName": "Perzentil 95"
    //         },
    //     },
    //     name: "PERCENTILE_95",
    // },
    // {
    //     i18n: {
    //         "en": {
    //             "description": "",
    //             "languageTag": "en",
    //             "name": "Percentile 99",
    //             "shortName": "Percentile 99"
    //         },
    //         "de": {
    //             "description": "",
    //             "languageTag": "de",
    //             "name": "Perzentil 99",
    //             "shortName": "Perzentil 99"
    //         },
    //     },
    //     name: "PERCENTILE_99",
    // }
]
