<template>
    <v-card :tile="tile" :flat="flat">
        <v-card-text>
            <div class="text-h6">{{title}}</div>
        </v-card-text>
        <slot name="card-header">
           <!-- Add further items -->
        </slot>
        <v-tabs color="grey" background-color="white" centered fixed-tabs>
            <v-tabs-slider color="grey"></v-tabs-slider>
            <v-tab @change="selectedMode = 'absolute'">{{$t("data-distribution-histogram-card.component.mode.absolute")}}</v-tab>
            <v-tab @change="selectedMode = 'relative'">{{$t("data-distribution-histogram-card.component.mode.relative")}}</v-tab>
        </v-tabs>

        <v-divider />
        
        <!-- Chart -->
        <c-metrics-chart 
            :items="chartValues" 
            :dimensions="series" 
            stack-data 
            show-value-label 
            value-formatter="0,0,0.00"
            :category-axis-label-formatter="formatDateTimeLabel"
        />
        
        <!-- Time Scale -->
        <v-card-text class="text-center" v-if="showTimeScalesAsToggleButtons">
            <v-btn-toggle v-model="selectedTimeScale" tile color="grey" >
                    <v-btn v-for="(scale, index) of sortedTimeScales" :value="scale" :key="'timeScale_btn_' + index" @click="clickedTimeScale(scale)">
                        <c-time-scale-label :value="scale" />
                    </v-btn>
            </v-btn-toggle>
        </v-card-text>
        <v-card-text class="text-center" v-else>
            <c-item-selector
                v-model="selectedTimeScale"
                :items="sortedTimeScales"
                filled
                :label="$t('data-distribution-histogram-card.component.timeInterval.selection.title')"
            >
                <template v-slot:selection="{item}">
                    <c-time-scale-label :value="item" />
                </template>
                <template v-slot:item="{item, on, attrs}">
                    <v-list-item  v-on="on" v-bind="attrs" color="white">
                    <v-list-item-content>
                        <v-list-item-title>
                            <c-time-scale-label :value="item" />
                        </v-list-item-title>
                        
                    </v-list-item-content>
                </v-list-item>
                </template>
            </c-item-selector>
        </v-card-text>


        <v-divider />

        <!-- Table -->
        
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-center">&nbsp;</th>
                    <template v-for="(item, index) of values">
                        <th :key="'th_table_' + index" class="text-right pt-2 pb-2" v-if="!item.gapItem">
                            {{$dxs.formatting.formatTime(item.name, $i18n.locale)}} <br /> {{$dxs.formatting.formatDate(item.name, $i18n.locale)}} <br /> &nbsp;
                        </th>
                        <th :key="'th_table_' + index" class="text-right pt-2 pb-2" v-else>
                             {{$dxs.formatting.formatTime(item.name, $i18n.locale)}} <br /> {{$dxs.formatting.formatDate(item.name, $i18n.locale)}} <br /> ...
                        </th>
                    </template>
                </tr>
            </thead>
            
            <tbody>
                <tr v-for="(item, trIndex) of series" :key="'tr_' + trIndex">
                    <td class="text-center"><v-chip label :color="item.color">{{item.name}}</v-chip></td>
                    <td v-for="(tdItem, tdIndex) of values" :key="'td_table_' + trIndex + '_' + tdIndex" class="text-right">
                        {{$dxs.formatting.formatInteger(tdItem.values[item.name], $i18n.locale)}}
                    </td>
                </tr>
                
                <tr class="grey lighten-3">
                    <td class="text-center"><v-chip label color="white">Summe</v-chip></td>
                    <template v-for="(item, index) of values">
                        <td :key="'td_sum_' + index" class="text-right" v-if="!item.gapItem">
                            {{$dxs.formatting.formatInteger(sum(item), $i18n.locale)}}
                        </td>
                        <td :key="'td_sum_' + index" class="text-right" v-else>
                            &nbsp;
                        </td>
                    </template>
                </tr>
                
            </tbody>
        </v-simple-table>

    </v-card>
</template>

<script>
import { cloneDeep } from 'lodash';


export default {

    props: {

        title: {
            type: String,
            required: true
        },

        items : {
            type: Array,
            required: true,
            default: () => []
        },

        series : {
            type: Array,
            required: true,
            default: () => {}
        },

        timeScales : {
            type: Array,
            required: true,
        },

        tile : {
            type: Boolean,
            required: false,
            default: false
        },

        flat : {
            type: Boolean,
            required: false,
            default: false
        },

        mode: {
            type: String,
            required: false,
            default: 'absolute'
        },

        value: {
            type: Object,
            required: true
        }
    },

    data() {

        return {
            selectedTimeScaleInternal: {},
            selectedMode: 'absolute'
        };
        
    },

    watch: {

        mode: {
            immediate: true,
            handler(value) {
                this.selectedMode = value ? value : 'absolute';
            }
        },

        value: {
            deep: true,
            immediate: true,

            // DO NOT SET THE COMPUTED ATTRIBUTE 'selectedTimeScale' BECAUSE OTHERWISE WE WILL END UP IN AN INFINITE LOOP
            // ALWAYS USE selectedTimeScaleInternal IN WATCHERS
            handler(value) {
                this.selectedTimeScaleInternal = value ? cloneDeep(value) : null;
            }
        }
    },

    methods: {
        fireInput() {
            this.$emit('input', cloneDeep(this.selectedTimeScaleInternal));
        },

        fireClick() {
            this.$emit('click');
        },
        
        sum(item) {

            let sum = 0;
            if (item && item.values) {
                for (const name in item.values) {
                    sum = sum + item.values[name];
                }
            }
            return sum;
        },

        clickedTimeScale(scale) {
            this.selectedTimeScale = scale;
            this.fireClick();
        },

        formatDateTimeLabel(item, locale) {
            
            let label = '?';
            if (item.name) {
                label = `${this.$dxs.formatting.formatTime(item.name, locale)}\r\n${this.$dxs.formatting.formatDate(item.name, locale)}`
            } else {
                this.$log.warn(`The item ${JSON.stringify(item)} does not contain a name field and can't be formatted: Using a placeholder value instead.`);
            }
            
            if(item.gapItem){
                label = label + '\r\n' + "..."
            }

            return label;
        },

        sortNumericalArrayAsc(array){
            
            array.sort(function(a, b) {
                return a.value - b.value;
            });
            return array;
        }
    },

    computed: {

        showTimeScalesAsToggleButtons() {
            if(this.timeScales){
                if (this.$vuetify.breakpoint.smAndDown && this.timeScales.length <= 2) return true;
                else if (this.$vuetify.breakpoint.mdOnly && this.timeScales.length <= 6) return true;
                else if (this.$vuetify.breakpoint.lgOnly && this.timeScales.length <= 12) return true;
                else if (this.$vuetify.breakpoint.xlOnly && this.timeScales.length <= 14) return true;
            }
            return false;
        },

        values() {
            
            // Return the items according to given mode.
            if (this.selectedMode === 'relative') {
                return this.relativeValueItems;
            } else {
                return this.items;
            }
        },

        chartValues() {

            const histogramItems = [];

            for(const item of this.values){
                let histogramItem = {
                    name: item.name,
                    values: {},
                    gapItem: item.gapItem
                };
                for(const key in item.values){
                    const value = item.values[key];
                    if(value){
                        histogramItem.values[key] = value
                    }
                }
                histogramItems.push(histogramItem);
            }

            return histogramItems;
        },

        relativeValueItems() {

            let relativeItems = [];
            for (const item of this.items) {
                
                let relativeItem = {
                    name: item.name, 
                    values: {},
                    gapItem: item.gapItem
                }; 

                let total = 0;
                for (const key in item.values) {
                    total = total + item.values[key];
                }

                for (const key in item.values) {
                    relativeItem.values[key] = this.$dxs.rounding.round(this.$dxs.math.percentOf(item.values[key], total), 2);
                }

                relativeItems.push(relativeItem);
            }

            return relativeItems;
        },

        selectedTimeScale: {

            get(){
                return this.selectedTimeScaleInternal;
            },

            set(selectedTimeScale){
                this.selectedTimeScaleInternal = selectedTimeScale;
                this.fireInput();
            }
        },

        sortedTimeScales() {
            let resultArray = [];
            let millisArray = [];
            let secondsArray = [];
            let minutesArray = [];
            let hoursArray = [];
            let daysArray = [];

            for(const timeScale of this.timeScales){
                switch(timeScale.unit) {
                    case 'ms':
                        millisArray.push(timeScale);
                        break;
                    case 's':
                        secondsArray.push(timeScale);
                        break;
                    case 'm':
                        minutesArray.push(timeScale);
                        break;
                    case 'h':
                        hoursArray.push(timeScale);
                        break;
                    case 'd':
                        daysArray.push(timeScale);
                        break;
                    }
            }

            millisArray = this.sortNumericalArrayAsc(millisArray);
            secondsArray = this.sortNumericalArrayAsc(secondsArray);
            minutesArray = this.sortNumericalArrayAsc(minutesArray);
            hoursArray = this.sortNumericalArrayAsc(hoursArray);
            daysArray = this.sortNumericalArrayAsc(daysArray);

            resultArray = resultArray.concat(millisArray, secondsArray, minutesArray, hoursArray, daysArray);
            
            return resultArray;
        }
    },

    created() {
        this.selectedMode = this.mode ? this.mode : 'absolute';
    }

}
</script>

<i18n>
{
    "en" : {

        "data-distribution-histogram-card.component.mode.absolute" : "ABSOLUTE",
        "data-distribution-histogram-card.component.mode.relative" : "RELATIVE",
        "data-distribution-histogram-card.component.timeInterval.selection.title" : "Time Interval",
        "data-distribution-histogram-card.component.histogram.subtitle" : "Time Range: {min} - {max}"
    },

    "de" : {

        "data-distribution-histogram-card.component.mode.absolute" : "ABSOLUT",
        "data-distribution-histogram-card.component.mode.relative" : "PROZENTUAL",
        "data-distribution-histogram-card.component.timeInterval.selection.title" : "Zeitintervall",
        "data-distribution-histogram-card.component.histogram.subtitle" : "Zeitraum: {min} - {max}"
    }
}
</i18n>