<template>
    <v-card tile>
        <v-card-text>
            
            <div><v-chip label color="grey">{{ $dxs.i18n.localizedName(shiftEventType, $i18n.locale) }}</v-chip></div>
        </v-card-text>
        <v-card-text>
            <div class="text-h6">{{ description }}</div>
        </v-card-text>
        <v-card-text>
            <div class="text-body-2"><span>{{ $dxs.i18n.localizedName(site, $i18n.locale) }}</span>  <span v-if="hasWorkplace"> > {{$dxs.i18n.localizedName(workplace, $i18n.locale)}}</span></div>
            <div class="text-body-2">{{ $dxs.formatting.formatDateTime(dateTime, $i18n.locale) }}</div>
        </v-card-text>
        <v-divider />
        
        <v-card-text class="text-caption">
           <div>{{ $t('shift-event-tile.component.footer.createdBy', { userId: modificationUserId, date: $dxs.formatting.formatDateTime(modificationDateTime, $i18n.locale) } )  }}</div> 
           <div>{{ $t('shift-event-tile.component.footer.eventId', {id: id}) }}</div>
        </v-card-text>
    </v-card>
</template>

<script>
import { get, isEmpty } from 'lodash';

export default {

    props: {

        value: {
            type: Object,
            required: false,
            default: () => ({
                shiftEvent: {},
                site: {},
                workplace: null,
            })
        }
    },

    computed: {

        shiftEvent() {
            return get(this.value, 'shiftEvent', {});
        },

        id() {
            return get(this.shiftEvent, 'id', null);
        },

        shiftEventData() {
            return get(this.shiftEvent, 'data', {});
        },


        description() {
            return get(this.shiftEventData, 'description');
        },

        modificationUserId() {
            return get(this.shiftEvent, 'modificationUserId', '?');
        },

        modificationDateTime() {
            return get(this.shiftEvent, 'modificationDateTime', '?');
        },

        workplace() {
            return get(this.value, 'workplace', {});
        },

        hasWorkplace() {
            return !isEmpty(this.workplace);
        },

        site() {
            return get(this.value, 'site', {});
        },


        shiftEventType() {
            return get(this.value, 'shiftEventType', {});
        },

        dateTime() {
            return get(this.shiftEventData, 'dateTime', null);
        }

    }
}
</script>

<i18n>
{
    "en" : {
        "shift-event-tile.component.workplace" : "{site} > {workplace}.",
        "shift-event-tile.component.footer.createdBy" : "Last modified at {date} by {userId}.",
        "shift-event-tile.component.footer.eventId" : "Event ID: {id}"
    },

    "de" : {
        "shift-event-tile.component.workplace" : "{site} > {workplace}.",
        "shift-event-tile.component.footer.createdBy" : "Geändert am {date} durch {userId}.",
        "shift-event-tile.component.footer.eventId" : "Ereignis-ID: {id}"
    }
}    
</i18n>