import axiosModule from 'axios';

/**
 * The central, preconfigured axios instance for making REST calls to the API gateway.
 */
export const axios =  axiosModule.create({});

// Add OpenIDConnect token interceptor.
axios.interceptors.request.use( (config) => {

    const accessToken = localStorage.getItem('vue-token');
    const locale = localStorage.getItem('amentis-locale');
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (locale) {
        config.headers.common['Accept-Language'] = locale;
        config.headers.common['Content-Language'] = locale;
    }

    config.headers.common['amentis-dxs-clientlocale'] = locale;
    config.headers.common['amentis-dxs-clienttimezone'] = timeZone;

    // Set the max content length to 4 times the standard size.
    //config.maxContentLength = (2000 * 4);
 

    return config;
})

axios.interceptors.response.use( 
    (response) => {

        return response;
    },
    (error) => {
        
        console.error(`Detected ERROR in HTTP communication with backend: ${error}`);
        console.error(`Stacktrace: ${error.stack ? error.stack : 'No stacktrace available.'}`);
        return Promise.reject(error);
    }
)

