<template>
    <img class="logo" v-bind:src="logo">
</template>

<script>
const TENANT_ID = process.env.VUE_APP_SSO_REALM;
const LOGO_TYPES = {
    'PRINT_HEADER': 'print-header-logo',
    'PRINT_FOOTER': 'print-footer-logo',
    'DEFAULT': 'logo'
}
export default {

    props: {
        type: {
            type: String,
            required: false,
            default: 'DEFAULT',
            enum: [Object.keys(LOGO_TYPES).join(',')]
        }
    },
    
    methods: {
        getLogo(tenantId, name, ext = 'png') {
            /** search for all logos in path /assets/log
             * match only 'png' and 'svg' extensions
             */
            var images = require.context('../assets/logo', true, /(\.png$|\.svg$)/)
            return images('./' + tenantId + '/' + name + '.' + ext)
        }
    },

    computed: {
        logo() {
            const type = LOGO_TYPES[this.type];
            let logo = undefined;
            try {
                logo = this.getLogo(TENANT_ID, type);
            } catch (e) {
                // logo not found, set the default amentis existing logo
                logo = this.getLogo('amentis', 'logo', 'svg')
            }

            return logo;
        }
    }
}
</script>

<style lang="scss" scoped>
    .logo {
        width: 100px;
        max-width: 100px;
        margin-bottom: 16px;
    }
</style>