<template>
    <div>
        <c-sticky-content>
                <v-toolbar color="secondary darken-1" flat >
                    <v-toolbar-items><v-btn icon @click="close"><v-icon>close</v-icon></v-btn></v-toolbar-items>
                    <v-toolbar-items><v-btn icon @click="back"><v-icon>arrow_back</v-icon></v-btn></v-toolbar-items>
                    <v-breadcrumbs>
                        <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false">{{$t('collection-view.breadcrumbs.root')}}</v-breadcrumbs-item>
                        <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                        <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false">{{collectionSettings | localizedName($i18n.locale)}}</v-breadcrumbs-item>
                        <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                        <v-breadcrumbs-item>{{document.id | localizedName($i18n.locale)}}</v-breadcrumbs-item>                
                    </v-breadcrumbs>
                    
                </v-toolbar>
        </c-sticky-content>
        <dxs-document-view :value="response"/>
    </div>
</template>

<script>
const MODULE_NAME_DETAIL = 'documentDetail';
const MODULE_NAME_CHANGE = 'documentChangeLog';
import get from 'lodash/get';
import DocumentViewComponent from '@/core/components/document/document-view.component';
import router from '../../../router';

export default {

    name: 'document-detail-page',
    
    components : {
        'dxs-document-view' : DocumentViewComponent
    },
    
    props: {
        
        value: {
            type: Object,
            required: false,
        },

    },

    inject: ['progressIndicatorService', 'envContextService'],

    computed: {
        response() {
            let response = this.$store.getters[MODULE_NAME_DETAIL + '/response'];
            
            if(response){
                let document = get(response, 'document.data.document', null);
                response.document = JSON.parse(document); 
                response.schema = this.schema;
            }else{
                response = {};
            }
            // console.log(">>> " + JSON.stringify(response))
            
            return response;
        },   
        collectionSettings() {
            
            return this.response.collectionSettings ? this.response.collectionSettings : {};
        },
        document() {
            return this.response.document ? this.response.document : {};
        },
        schema(){
            return get(this.$store.getters[MODULE_NAME_CHANGE + '/schema'], 'data.schema', {});
        },
             
    },
    methods: {
        async refresh() {
            this.progressIndicatorService.show();

            try {
                
                // console.log(">>> " + JSON.stringify(this.$route.params.value.changeLogDocumentId))
                await this.$store.dispatch(MODULE_NAME_DETAIL + '/loadData', {
                    collectionId : 'DocumentChangeLog',
                    documentId: this.value.changeLogDocumentId,
                });
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        close() {
             if (window) {
                window.close();
             }
             
         },
         back() {
            if(router) {
                router.go(-1);
            }
            
         }
        
    },

    created() {
        
        // Refresh will trigger loading of the collection settings information.
        this.refresh();
    },

    
}
</script>