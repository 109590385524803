
import Configuration from '@/utils/configuration';

import { axios } from '@/store/axios';

const BACKEND_HOST = Configuration.value('BACKEND_HOST');
const BACKEND_PORT = Configuration.value('BACKEND_PORT');
const TENANT_ID = Configuration.value('TENANT_ID');
const API_VERSION = 'v2';
const BACKENDROOT = BACKEND_HOST + (BACKEND_PORT ? (':' + BACKEND_PORT) : '');
const BACKEND = BACKENDROOT + '/' + API_VERSION + '/' + TENANT_ID;

const TIMEOUT = 6000;

export default {

    masterdata: {

        /**
        * The endpoint for managing sites.
        * @since DXS 3.4.0
        */
        sites: {

            create(request) {
                let url = BACKEND + "/smartfactory/masterdata/site/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/smartfactory/masterdata/site/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/smartfactory/masterdata/site';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(siteId) {
                let url = BACKEND + '/smartfactory/masterdata/site/' + siteId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(siteId) {
                let url = BACKEND + '/smartfactory/masterdata/site/' + siteId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },

        shiftEvents: {
            

            create(request) {
                let url = BACKEND + "/smartfactory/masterdata/shift-events/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/smartfactory/masterdata/shift-events/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/smartfactory/masterdata/shift-events';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(shiftEventId) {    
                let url = BACKEND + '/smartfactory/masterdata/shift-events/' + shiftEventId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(shiftEventId) {
                let url = BACKEND + '/smartfactory/masterdata/shift-events/' + shiftEventId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        shiftEventTypes: {
            

            create(request) {
                let url = BACKEND + "/smartfactory/masterdata/shift-event-types/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/smartfactory/masterdata/shift-event-types/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/smartfactory/masterdata/shift-event-types';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(shiftEventTypeId) {
                let url = BACKEND + '/smartfactory/masterdata/shift-event-types/' + shiftEventTypeId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(shiftEventTypeId) {
                let url = BACKEND + '/smartfactory/masterdata/shift-event-types/' + shiftEventTypeId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },

        workplaceTypes:{
            create(request) {
                let url = BACKEND + "/smartfactory/masterdata/workplace-types/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

           
            update: (request) => {
                let url = BACKEND + "/smartfactory/masterdata/workplace-types/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/smartfactory/masterdata/workplace-types';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(workplacetypeId) {
                let url = BACKEND + '/smartfactory/masterdata/workplace-types/' + workplacetypeId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(workplacetypeId) {
                let url = BACKEND + '/smartfactory/masterdata/workplace-types/' + workplacetypeId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
        workplaces:{

            create(request) {
                let url = BACKEND + "/smartfactory/masterdata/workplaces/" + request.name;

                return axios
                    .post(url, request, { timeout: TIMEOUT })
                    .then((response) => {
                        return response.data;
                    })
                    .catch((error) => {
                        return Promise.reject(error);
                    });
            },

            update: (request) => {
                let url = BACKEND + "/smartfactory/masterdata/workplaces/" + request.name;

                const requestConfig = {
                    timeout: TIMEOUT
                };

                return axios.put(url, request, requestConfig).then(response => {
                    return response.data;
                }).catch(error => {
                    return Promise.reject(error);
                });
            },

            findByFilter: (filter, from, pageSize) => {

                let url = BACKEND + '/smartfactory/masterdata/workplaces';

                return axios.get(url, { timeout: TIMEOUT, params: { filter: filter, from: from, pageSize: pageSize } })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },



            findById(workplaceId) {
                let url = BACKEND + '/smartfactory/masterdata/workplaces/' + workplaceId;

                return axios.get(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            findBySiteId(siteId, from, pageSize) {
                let url = BACKEND + `/smartfactory/masterdata/workplaces/references/site/${siteId}`;
                

                return axios.get(url, { timeout: TIMEOUT ,params: { from: from, pageSize: pageSize }})
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            },

            deleteById(workplaceId) {
                let url = BACKEND + '/smartfactory/masterdata/workplaces/' + workplaceId;

                return axios.delete(url, { timeout: TIMEOUT })
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        return Promise.reject(error);
                    });
            }

        },
    }

}
