<template>
    <div class="secondary darken-1">

        <c-section
           :title="$t('prediction-model-wizard.view.sections.title')"
           :subtitle="$t('prediction-model-wizard.view.sections.subtitle')"
           class="secondary">

            <v-form ref="from" v-model="formValid" lazy-validation>
            <v-container fluid class="secondary">
                <v-row justify="center">
                    <v-col cols="12" lg="9">
                        <v-card tile flat>
                            <v-toolbar flat>
                                <v-toolbar-title>Assistent</v-toolbar-title>
                                <v-spacer />
                                <v-toolbar-items>
                                    <v-btn icon text @click="showCancelWizardDialog = true"><v-icon>close</v-icon></v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-card-text v-if="!formValid" class="error white--text">
                               <v-icon color="white">warning</v-icon> &nbsp; Bitte beheben Sie die angezeigten Fehler, um fortzufahren.
                            </v-card-text>
                            <v-stepper v-model="steps" class="elevation-0">
                            
                            <v-stepper-header class="elevation-0">
                                <v-stepper-step :complete="steps > 1" step="1" :color="stepperColor" >
                                   Bezeichner
                                </v-stepper-step>
                                 <v-divider />

                                <v-stepper-step :complete="steps > 2" step="2" :color="stepperColor">
                                   Attribute
                                </v-stepper-step>
                                <v-divider />

                                <v-stepper-step :complete="steps > 3" step="3" :color="stepperColor">
                                    Ausreißer
                                </v-stepper-step>
                                <v-divider />

                                <v-stepper-step :complete="steps > 4" step="4" :color="stepperColor">
                                     Validierung
                                </v-stepper-step>
                                <v-divider />

                                <v-stepper-step :complete="steps > 5" step="5" :color="stepperColor">
                                    Training starten
                                </v-stepper-step>
                            </v-stepper-header>
                                
                            <v-stepper-items flat>
                                <!-- Step 1 -->
                                

                                <v-stepper-content step="1">
                                    <v-card tile flat>
                                        <v-card-text class="text-h6">Bezeichner festlegen</v-card-text>
                                        <v-card-text>
                                        <strong>Lassen Sie uns loslegen!</strong>
                                        <p />    
                                        Dieser Assistent führt Sie durch die Vorbereitung des Trainings Ihres Vorhersagemodells. 
                                        Geben Sie der Trainingskonfiguration zuerst einen eindeutigen Bezeichner, um diese später identifizieren zu können.
                                        </v-card-text>
                                        <v-card-text>
                                            <dxs-name-text-field v-model="modelId" />
                                        </v-card-text>
                                    </v-card>
                                </v-stepper-content>

                                <!-- ================================================================================ -->
                                <!-- Choose Attributes to use as features                                             -->
                                <!-- ================================================================================ -->
                                <v-stepper-content step="2">
                                     <v-card tile flat>
                                        <v-card-text class="text-h6">Attribute verwenden</v-card-text>
                                        <v-card-text>
                                            Bitte bestimmen Sie die Attribute, die Sie für die Vorhersage verwenden möchten. Beachten Sie, dass eine begrenzte Auswahl 
                                            voneinander unabhängiger Attribute meist zu einer höheren Vorhersagegenauigkeit des Modells führt, als viele, voneinander abhängige Attribute.
                                            Zusätzliche Attribute erhöhen zudem die notwendige Zeit zur Training eines qualitativ hochwertigen Vorhersagemodells.
                                        </v-card-text>
                                        <v-card-text v-if="tooFewSelectedFeatures">
                                            <dxs-warning-message  >
                                                Sie verwenden momentan weniger als  <strong>3</strong> Attribute zur Vorhersage in Ihrem Modell.
                                                In vielen Fällen kann eine zu kleine Anzahl von Attributen die Vorhersagequalität des Modells negativ beeinflussen. 
                                                Wir empfehlen Ihnen deshalb, weitere Attribute auszuwählen.
                                            </dxs-warning-message>
                                        </v-card-text>
                                        <v-simple-table>
                                            <thead>
                                                <tr>
                                                    <th>Verwenden</th>
                                                    <th>Name</th>
                                                    <th class="d-none d-md-table-cell">Techn. Name</th>
                                                    <th class="d-none d-sm-table-cell">Datentyp</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(f, i) of features" :key="'tr_feature_' + f.name">
                                                    <td><v-checkbox color="accent" @change="changeSelection({index: i, selected: $event})" /></td>
                                                    <td>{{f | localized-name($i18n.locale)}}</td>
                                                    <td class="d-none d-md-table-cell">{{f.name}}</td>
                                                    <td class="d-none d-sm-table-cell">{{f.datatype}}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                        <v-card-text />
                                        
                                        
                                     </v-card>
                                </v-stepper-content>


                                <v-stepper-content step="3">
                                    <v-card tile flat>
                                        <v-card-text class="text-h6">Ausreißer erkennen und behandeln</v-card-text>
                                        <v-card-text>
                                        Legen Sie fest, wie Sie mit Ausreißern umgehen wollen. 
                                        Ausreißer sind Datenpunkte, die deutlich von anderen Datenpunkten abweichen. Diese Abweichungen sind oftmals das Ergebnis falscher Messungen oder manueller Eingabefehler. 
                                        Das Vorhandensein von Außreißern führt in den meisten Fällen zu einer Verschlechterung der Vorhersagequalität eines Modells. 
                                        <p />
                                        Das System kann die Trainingsdaten automatisch nach Ausreißern durchsuchen und die betroffenen Datensätze vom Training ausschließen.
                                        Wir empfehlen Ihnen diese Option zu aktivieren.
                                        </v-card-text>
                                        <v-card-text>
                                            <v-switch v-model="handleOutliers" inset :label="$t('prediction-model-wizard.view.steps.outliers.detection.switch.' + (handleOutliers ? 'on' : 'off') , $i18n.locale)" color="accent" />
                                        </v-card-text>
                                        <dxs-warning-message  v-if="!handleOutliers">
                                           Sie haben die automatische Erkennung und Behandlung von Ausreißern ausgeschaltet. Hierdurch kann sich die Vorhersagegenauigkeit des Modells stark verschlechtern.
                                           Wir empfehlen Ihnen, diese Option wieder zu aktivieren.
                                        </dxs-warning-message >
                                    </v-card>
  
                                </v-stepper-content>

                                <v-stepper-content step="4">
                                    <v-card tile flat>
                                        <v-card-text class="text-h6">
                                            Modell validieren
                                        </v-card-text>
                                        <v-card-text>
                                            In diesem Schritt legen Sie Einstellungen zur Validierung Ihres Modells fest. 
                                            Hierbei werden Kennzahlen ermittelt, die es Ihnen ermöglichen, die Vorhersagegenauigkeit des erzeugten Modells zu beurteilen.
                                        </v-card-text>
                                        <v-divider />
                                        <v-subheader><strong>Vorhersagegenauigkeit des Modells prüfen</strong></v-subheader> 
                                        <v-card-text>
                                            Nach dem Training evaluiert das System die Vorhersagequalität Modell anhand von Stichproben. 
                                            Legen Sie hierfür bitte anhand des untenstehenden Reglers fest, wieviel Prozent der Trainingsdaten als Stichproben genutzt werden sollen.
                                        </v-card-text>
                                        <v-card-text>
                                            <v-slider v-model="testTrainingSplitRatio" 
                                                thumb-label="always"
                                                step="5"
                                                track-color="grey"
                                                color="accent" 
                                                label="Test-Training-Ratio bestimmen (%)" 
                                                :min="0" 
                                                :max="100"
                                                hint="Legen Sie den prozentualen Anteil der Daten fest, die zum Testen des Modells verwendet werden sollen."
                                                persistent-hint
                                                />
                                        </v-card-text>
                                        <v-card-text v-if="testTrainingSplitRatio < 10" class="warning">
                                            <strong>Achtung</strong>
                                            <p />
                                            Sie verwenden nur <strong>{{testTrainingSplitRatio}}%</strong> der Daten zur Validierung des Modells. 
                                            Die Aussagekraft der Validierung ist hierdurch stark eingeschränkt. 
                                            <p />
                                            Wir empfehlen Ihnen diesen Wert zu erhöhen. Sinnvolle Werte liegen typischerweise zwischen 10% und 40% der Datenmenge
                                        </v-card-text>
                                        <v-card-text v-if="testTrainingSplitRatio > 40" class="warning">
                                            <strong>Achtung</strong>
                                            <p />
                                            Sie verwenden  <strong>{{testTrainingSplitRatio}}%</strong> der Daten zur Validierung des Modells. 
                                            Die Aussagekraft der Validierung ist hierdurch stark eingeschränkt. 
                                            <p />
                                            Wir empfehlen Ihnen diesen Wert zu reduzieren. Sinnvolle Werte liegen typischerweise zwischen 10% und 40% der Datenmenge
                                        </v-card-text>
                                        <v-subheader><strong>Vorhersagegenauigkeit mit Kreuzvalidierung erhöhen</strong></v-subheader> 
                                        <v-card-text>
                                        Die Kreuzvalidierung des Modells führt automatisch mehrere Trainingsdurchläufe mit wechselnden Daten durch. Hierdurch
                                        wird die Genauigkeit des Models präziser ermittelt, das Training dauert jedoch länger. Wir empfehlen die Verwendung 
                                        dieser Option für eine hohe Verlässlichkeit der Vorhersagequalität.
                                        </v-card-text>
                                        <v-card-text>
                                            <v-switch v-model="crossValidation" inset :label="$t('prediction-model-wizard.view.steps.evaluation.crossValidation.switch.' + (crossValidation ? 'on' : 'off') , $i18n.locale)" color="accent" />
                                        </v-card-text>
                                         <v-card-text v-if="!crossValidation" class="warning">
                                            <strong>Achtung</strong>
                                            <p />
                                            Sie haben die Kreuzvalidierung ausgeschaltet. Dies kann dazu führen, dass die Vorhersagegenauigkeit des Modells nicht präzise ermittelt wird. 
                                            Wir empfehlen Ihnen deshalb diese Option wieder zu aktivieren.
                                            <br />
                                           
                                        </v-card-text>
                                    </v-card>
                                    
                                    <p />
                                    <div >
                                        
                                    </div>
                                    
                                    <div>
                                        
                                    </div>
                                    <div class="body-2">
                                       
                                    </div>
                                </v-stepper-content>

                                <v-stepper-content step="5">
                                    <v-card tile flat>
                                        <v-card-text class="text-h6">Training starten</v-card-text>
                                        <v-card-text><strong>Herzlichen Glückwunsch: Sie haben es fast geschafft!</strong> 
                                        <br />
                                        Alle notwendigen Daten sind erfasst. Drücken Sie zum Abschluss den nachfolgenden Knopf, um den Trainingsvorgang zu starten.
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn x-large color="accent" @click="startTraining"><v-icon>play_arrow</v-icon> &nbsp; Training starten</v-btn>
                                        </v-card-actions>
                                        <v-card-text>
                                            Durch Drücken des Knopfs beginnen Sie die Durchführung des Trainings. Bitte beachten Sie, dass dies abhängig von der Menge
                                            der betrachteten Daten ein langlaufender Prozess ist, der einige Zeit zur Fertigstellung benötigt. 
                                            Nach Abschluss des Trainings stehen Ihnen Auswertungen zum Training des Modells bereit, die Sie zur Beurteilung der Vorhersagequalität verwenden können.
                                        </v-card-text>
                                        
                                    </v-card>
                                </v-stepper-content>

                            </v-stepper-items>
                            
                         </v-stepper>
                         <v-divider />
                         <v-card-actions>
                            <v-btn text color="grey"   @click="steps = steps - 1" :disabled="!formValid || steps == 1">Zurück</v-btn>
                            <v-btn text color="accent" @click="steps = steps + 1" :disabled="!formValid || steps == 5">Weiter</v-btn>
                         </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            </v-form>
        </c-section>

        <v-dialog v-model="showCancelWizardDialog" persistent max-width="600">
        <v-card tile>
            <v-toolbar flat>
            <v-toolbar-title>{{$t('prediction-model-wizard.view.dialogs.cancelProcess.title', $i18n.locale)}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
            {{$t('prediction-model-wizard.view.dialogs.cancelProcess.subtitle', $i18n.locale)}}
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="cancelTraining">{{$t('prediction-model-wizard.view.dialogs.cancelProcess.actions.cancel', $i18n.locale)}}</v-btn>
            <v-btn text @click="continueTraining">{{$t('prediction-model-wizard.view.dialogs.cancelProcess.actions.continue', $i18n.locale)}}</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </div>
</template>

<script>
import NameTextField from '@/components/common/name-text-field.component';
import WarningMessage from '@/components/messages/warning-message.component';

const MODULE_NAME = 'predictionModelWizard';

export default {

    props: {
        value: {
        type: Object,
        required: false,
        },

        options: {
        type: Object,
        required: false,
        },
    },

    inject: ['progressIndicatorService'],

    components: {
        'dxs-name-text-field' : NameTextField,
        'dxs-warning-message' : WarningMessage
    },

    data: () => ({
        formValid: true,
        steps: 1,
        showCancelWizardDialog: false,
       
    }),

    methods: {

        cancelTraining() {

            this.showCancelWizardDialog = false;
            this.$router.go(-1);
        },

        continueTraining() {
            this.showCancelWizardDialog = false;
        },

        async startTraining() {
            
            this.$log.debug('Training Request is sent to the backend.');
            
            try {
                await this.$store.dispatch(MODULE_NAME + "/saveModel");
                
                // Once the call has returned successfully we can redirect the user to the dashboard 
                // where training results can be displayed.
                let params = {
                    scenarioId: this.value.scenarioId,
                    modelId: this.$store.getters[MODULE_NAME + '/modelId']
                };

                this.$router.push({ name: 'predictionModelDashboardView', params: params });

            } catch (error) {
                console.log(error)
            }
        },

        changeSelection(event){
            this.$log.debug('Selected feature at index ' + JSON.stringify(event));

            let value = { feature: this.features[event.index] };
            if (event.selected) {
                this.$store.dispatch(MODULE_NAME + "/addFeature",  value)
            } else {
                this.$store.dispatch(MODULE_NAME + "/removeFeature", value)
            }

        },

        /**
        * Loads the prediction scenario for this training configuration.
        */
        async loadPrediction(scenarioId) {

            this.progressIndicatorService.show();
            
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadPrediction", { scenarioId : scenarioId });
            } catch (error) {
                this.$log.error('Failed to load data: ' + error)
            } finally {
                this.progressIndicatorService.hide();
            }

        }
    },

    computed: {
        
        stepperColor() {
            return this.formValid ? 'accent' : 'error';
        },

        prediction() {
            return this.$store.getters[MODULE_NAME + '/prediction'];
        },

        /** 
         * Returns the available features for defining a training configuration.
         */
        features() {
            return (this.prediction && this.prediction.schema) ? this.prediction.schema.attributes : []
        },

        modelId: {
            
            get() {
                
                let config = this.$store.getters[MODULE_NAME + '/modelConfiguration'];
                
                return config && config.modelId ? config.modelId : '';

            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateModelId", { modelId : value })
            }
        },

        selectedFeatures : {

            get() {
                let config = this.$store.getters[MODULE_NAME + '/modelConfiguration'];
                
                return config && config.features ? config.features : [];
            }
        },
        
        tooFewSelectedFeatures() {
            return this.selectedFeatures.length < 3 
        },

        testTrainingSplitRatio: {
            
            get() {
                let config = this.$store.getters[MODULE_NAME + '/modelConfiguration'];
                return config && config.testTrainingSplitRatio ? config.testTrainingSplitRatio : 25;
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateTestTrainingSplitRatio", { testTrainingSplitRatio : value })
            }
        },


        crossValidation: {
            
            get() {
                let config = this.$store.getters[MODULE_NAME + '/modelConfiguration'];
                return config ? config.crossValidation : true;
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateCrossValidation", { crossValidation : value })
            }
        },

        handleOutliers: {
            
            get() {
                let config = this.$store.getters[MODULE_NAME + '/modelConfiguration'];
                return config ? config.handleOutliers : true;
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateHandleOutliers", { handleOutliers : value })
            }
        },
    },

    created() {
        
        if (this.value && this.value.scenarioId) {
            this.loadPrediction(this.value.scenarioId);
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "prediction-model-wizard.view.sections.title" : "Create New Model",
        "prediction-model-wizard.view.sections.subtitle" : "This assistent will guide you through the required steps for creating and training a new predicition model. Once the training has been finished the model can be used for actual prediction.",

        "prediction-model-wizard.view.steps.outliers.detection.switch.on" : "Detect Outliers (higher quality of prediction)",
        "prediction-model-wizard.view.steps.outliers.detection.switch.off" : "Ignore Outliers",

        "prediction-model-wizard.view.steps.evaluation.crossValidation.switch.on" : "Enable Cross-Validation (higher quality of prediction)",
        "prediction-model-wizard.view.steps.evaluation.crossValidation.switch.off" : "Disable Cross-Validation",

        "prediction-model-wizard.view.dialogs.cancelProcess.title" : "Cancel Model Creation",
        "prediction-model-wizard.view.dialogs.cancelProcess.subtitle" : "You want to cancel the process of creating a new model? Your progress will not be stored and the model can't be used for actual prediction.",
        "prediction-model-wizard.view.dialogs.cancelProcess.actions.cancel" : "Yes (Creation is Cancelled)",
        "prediction-model-wizard.view.dialogs.cancelProcess.actions.continue" : "No"
    },

    "de" : {
        "prediction-model-wizard.view.sections.title" : "Neues Modell anlegen",
        "prediction-model-wizard.view.sections.subtitle" : "Dieser Assistent führt Sie durch die Schritte zum Training eines Vorhersagemodells. Nach Abschluss des Trainings können Sie das Modell für die Vorhersage verwenden.",

        "prediction-model-wizard.view.steps.outliers.detection.switch.on" : "Ausreißer automatisch erkennen (Höhere Genauigkeit)",
        "prediction-model-wizard.view.steps.outliers.detection.switch.off" : "Ausreißer ignorieren",

        "prediction-model-wizard.view.steps.evaluation.crossValidation.switch.on" : "Kreuzvalidierung eingeschaltet (Höhere Genauigkeit des Ergebnisses)",
        "prediction-model-wizard.view.steps.evaluation.crossValidation.switch.off" : "Kreuzvalidierung ausgeschaltet",

        "prediction-model-wizard.view.dialogs.cancelProcess.title" : "Trainingsvorgang abbrechen",
        "prediction-model-wizard.view.dialogs.cancelProcess.subtitle" : "Möchten Sie den Trainingsvorgang wirklich abbrechen? Ihre Änderungen werden nicht gespeichert und Ihr Modell kann nicht zur Vorhersage genutzt werden.",
        "prediction-model-wizard.view.dialogs.cancelProcess.actions.cancel" : "Ja, Training abbrechen",
        "prediction-model-wizard.view.dialogs.cancelProcess.actions.continue" : "Nein, Training fortführen"
    }
}
</i18n>