export default [
    {
        name: 'MAX',
        i18n : {
            'en' : {
                name: 'Maximum',
                shortName: 'Max',
                description: 'Selects the maximum value out of a set of values.'
            },

            'de' : {
                name: 'Maximum',
                shortName: 'Max',
                description: 'Wählt den maximalen Wert aus einer Menge von Werten aus.'
            }
        }
    },

    {
        name: 'MIN',
        i18n : {
            'en' : {
                name: 'Minimum',
                shortName: 'Min',
                description: 'Selects the minimum value out of a set of values.'
            },

            'de' : {
                name: 'Minimum',
                shortName: 'Min',
                description: 'Wählt den minimalen Wert aus einer Menge von Werten aus.'
            }
        }
    },

    {
        name: 'AVG',
        i18n : {
            'en' : {
                name: 'Average',
                shortName: 'Avg',
                description: 'Computes the average value of a set of values.'
            },

            'de' : {
                name: 'Durchschnitt',
                shortName: 'Durch.',
                description: 'Berechnet den Durschnittswert einer Menge von Werten.'
            }
        }
    },

    {
        name: 'MEDIAN',
        i18n : {
            'en' : {
                name: 'Median',
                shortName: 'Med',
                description: 'Computes the median value of a set of values.'
            },

            'de' : {
                name: 'Median',
                shortName: 'Med',
                description: 'Berechnet den Median einer Menge von Werten.'
            }
        }
    },

    {
        name: 'SUM',
        i18n : {
            'en' : {
                name: 'Sum',
                shortName: 'Sum',
                description: 'Computes the sum of a set of values.'
            },

            'de' : {
                name: 'Summe',
                shortName: 'Sum',
                description: 'Berechnet die Summe einer Menge von Werten.'
            }
        }
    },

    {
        name: 'COUNT',
        i18n : {
            'en' : {
                name: 'Count',
                shortName: 'Count',
                description: 'Counts the number of elements'
            },

            'de' : {
                name: 'Anzahl',
                shortName: 'Anzahl',
                description: 'Zählt das Vorkommen von Werten'
            }
        }
    },
]