<template>
  <v-app light>

   

    <!-- ========================================= -->
    <!-- NAVIGATION                                -->
    <!-- ========================================= -->
    <v-navigation-drawer fixed temporary v-model="drawer" width="300px" app>
        <v-list dense>
      <!-- ========================================= -->
      <!-- User Information  -->
      <!-- ========================================= -->
      
        <v-list-item :inactive="!userManagementEnabled" @click="account">
          <v-list-item-avatar v-if="userAvatarUrl">
            <img :src="userAvatarUrl">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{userName}}</strong>
            </v-list-item-title>
            <v-list-item-title>{{userEMail}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

      <!-- ========================================= -->
      <!-- Overview Page                             -->
      <!-- ========================================= -->
        <v-list-item to="/" color="accent">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{$t('home.view.title')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      <!-- ========================================= -->
      <!-- Notification Page                         -->
      <!-- ========================================= -->
        <v-list-item to="/notifications" color="accent">
          <v-list-item-action>
              <v-icon>notifications</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{$t('navigation.groups.notification')}}</v-list-item-title>
            <v-list-item-subtitle>{{$tc('navigation.groups.notification.subtitle', notificationsCount)}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

    <!-- ========================================= -->
    <!-- My DXS                                   -->
    <!-- ========================================= -->
    <dxs-navigation-item-group 
         :title="$t('navigation.groups.me')" 
         :routes="meRoutes" 
         icon="account_circle" />
    
    <!-- ========================================= -->
    <!-- Custom Extensions                         -->
    <!-- ========================================= -->
    <dxs-navigation-item-group 
        :title="$t('navigation.groups.custom.extensions')" 
        :routes="customRoutes" 
        icon="apps" />

    <!-- ========================================= -->
    <!-- Solutions                                 -->
    <!-- ========================================= -->

    <!-- Smart Factory Services -->
    <v-subheader>{{$t('navigation.groups.solutions.smartFactoryServices')}}</v-subheader>
    <dxs-navigation-item-group 
        :title="$t('navigation.groups.solutions.smartFactoryServices.apps')" 
        :routes="smartFactoryAppRoutes" 
        icon="apps" />

    <dxs-navigation-item-group 
        :title="$t('navigation.groups.solutions.smartFactoryServices.masterdata')" 
        :routes="smartFactoryMasterdataRoutes" 
        icon="factory" />

    <!-- Battery Data Hub -->
    <v-subheader>{{$t('navigation.groups.solutions.batteryDataHub')}}</v-subheader>
    <dxs-navigation-item-group 
        :title="$t('navigation.groups.solutions.batteryDataHub.apps')" 
        :routes="batteryDataHubAppRoutes" 
        icon="apps" />

    <dxs-navigation-item-group 
        :title="$t('navigation.groups.solutions.batteryDataHub.masterdata')" 
        :routes="batteryDataHubMasterdataRoutes" 
        icon="battery_full" />



      <!-- ========================================= -->
      <!-- Data Exploration and Analytics            -->
      <!-- ========================================= -->


    <v-subheader>{{$t('navigation.section.analytics')}}</v-subheader>
    <dxs-navigation-item-group 
        :title="$t('navigation.groups.dataExploration')" 
        :routes="dataExplorationRoutes" 
        icon="explore">

        <!-- Tags -->
        <template v-if="!tagsEmpty">
            <v-subheader class="grey lighten-5">Nach Kategorien</v-subheader>
            <dxs-collection-tag-navigation-item 
                v-for="tag of tags"
                :key="'collectionFilter_' + tag.name"
                :value="tag" 
                class="grey lighten-5"
                />
            <v-divider />
        </template>

    </dxs-navigation-item-group>

    <v-subheader>{{$t('navigation.section.integration')}}</v-subheader>

      <!-- ========================================= -->
      <!-- Integration                                   -->
      <!-- ========================================= -->
      <dxs-navigation-item-group 
         :title="$t('navigation.groups.integration')" 
         :routes="integrationRoutes" 
         icon="integration_instructions"/>

      <!-- ========================================= -->
      <!-- Data Preparation                          -->
      <!-- ========================================= -->

        
      <dxs-navigation-item-group 
         :title="$t('navigation.groups.dataPreparation')" 
         :routes="dataPreparationRoutes" 
          icon="mediation"
          />

    <!-- ========================================= -->
    <!-- Webhooks                                  -->
    <!-- ========================================= -->     
    <dxs-navigation-item-group 
        :title="$t('navigation.groups.webhooks')" 
        :routes="webhookRoutes" 
        icon="outbox" />

    <v-subheader>{{$t('navigation.section.system')}}</v-subheader>

      <!-- ========================================= -->
      <!-- Catalog                                   -->
      <!-- ========================================= -->
      
      <dxs-navigation-item-group 
         :title="$t('navigation.groups.catalog')" 
         :routes="catalogRoutes" 
         icon="menu_book"/>


         

      <!-- ========================================= -->
      <!-- Users & Permissions                       -->
      <!-- ========================================= -->
        <dxs-navigation-item-group 
         :title="$t('navigation.groups.userMgmt')" 
         :routes="userMgmtRoutes" 
          icon="groups" />

      <!-- ========================================= -->
      <!-- System                                   -->
      <!-- ========================================= -->
      <dxs-navigation-item-group 
         :title="$t('navigation.groups.system')" 
         :routes="systemRoutes" 
         icon="settings" />

      
      
      <v-divider />
      <v-list-item @click="logout">
          <v-list-item-action>
              <v-icon>logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
              <v-list-item-title>{{$t('application.shell.logout')}}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>


      </v-list>
    </v-navigation-drawer>

    <!-- ========================================= -->
    <!-- APP BAR                                   -->
    <!-- ========================================= -->
    <v-app-bar fixed elevate-on-scroll color="primary lighten-1" dark app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="userAuthorizationDataAvailable"/>
        <span class="title ml-3 mr-5"><span class="font-weight-light">{{ $t(shellTitle) }}</span></span>
        <v-spacer />

        <v-toolbar-items class="align-center">
          <v-row justify="space-around">

            <!-- Wizards -->
            <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on"><v-icon>add_circle</v-icon></v-btn>
                  </template>

                  <dxs-navigation-item-list
                      :title="$t('navigation.groups.wizards')" 
                      :routes="wizardRoutes" 
                      icon="add_circle" 
                    />  
            </v-menu>

            <!--



            -->

            <!-- Notifications -->
            <v-btn icon :to="'/notifications'">
                <v-icon>notifications</v-icon>
            </v-btn>


            <!-- <v-btn icon @click="displayMessage('He Rulez')"><v-icon>warning</v-icon></v-btn>  -->

            <!-- User Settings -->
              <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on"><v-icon>person</v-icon></v-btn>
                  </template>

                  <v-list>
                        <v-list-item :inactive="!userManagementEnabled" @click="account">
                          <v-list-item-avatar v-if="userAvatarUrl">
                              <img :src="userAvatarUrl">
                          </v-list-item-avatar>
                          <v-list-item-content>
                              <v-list-item-title class="body-2">
                                  <strong>{{userName}}</strong>
                              </v-list-item-title>
                              <v-list-item-title class="body-2">{{userEMail}}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      
                      <v-list-item @click="goToUserAccount">
                          <v-list-item-action>
                              <v-icon>account_circle</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title class="body-2">{{$t('application.shell.userAccount')}}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="userManagementEnabled" @click="account">
                          <v-list-item-action>
                              <v-icon>settings</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title class="body-2">{{$t('application.shell.userAccount.edit')}}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item @click="goToUserAuthorizationDashboard">
                          <v-list-item-action>
                              <v-icon>badge</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title class="body-2">{{$t('application.shell.userAuthorizations')}}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="loadUserAuthorization">
                          <v-list-item-action>
                              <v-icon>refresh</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title class="body-2">{{$t('application.shell.userAuthorizations.reload')}}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item @click="showInfoDialog = true">
                          <v-list-item-action>
                              <v-icon>info</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title class="body-2">{{$t('application.shell.about')}}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item @click="logout" color="accent">
                          <v-list-item-action>
                              <v-icon>logout</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title class="body-2">{{$t('application.shell.logout')}}</v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                  </v-list>
              </v-menu>

            <!-- Language Selector -->
            <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on"><v-icon>language</v-icon></v-btn>
            </template>

            <v-list>
              <v-list-item v-for="locale of locales" :key="locale" @click="setShellLocale(locale)">
                <v-list-item-icon>
                    <v-icon v-if="equalsShellLocale(locale)">radio_button_checked</v-icon>
                    <v-icon v-else>radio_button_unchecked</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="body-2">{{ $t('shell.locales.' + locale) }}</v-list-item-title>
              </v-list-item>
            </v-list>

        </v-menu>
          </v-row>
      </v-toolbar-items>

    </v-app-bar>

    <!-- ========================================= -->
    <!-- CONTENT AREA                              -->
    <!-- ========================================= -->
    
    <v-main class="secondary">

        
        <template v-if="userAuthorizationDataAvailable">
            <!-- Main Dialog Area -->
            <dxs-viewport>
                <router-view ></router-view>
            </dxs-viewport>
        </template>

        <!-- Authorization Data Could not be Loaded -->
        <template v-else>
            <v-container fluid color="grey lighten-3">
                <v-row justify="center">
                    <v-col cols="12" lg="9">
                        <v-card tile>
                            <v-card-title>Authorization Data is Not Available</v-card-title>
                            <v-card-text>
                                The authorization data could not be loaded. For reasons of security, the application is therefore disabled.
                                Please click the button below in order to try to reload the authorization data.
                            </v-card-text>
                            <v-card-text>
                                <v-btn x-large color="accent" @click="loadUserAuthorization"><v-icon>refresh</v-icon> Reload</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
 
        </template>
      
    </v-main>

    <!-- Footer -->
    <v-footer :padless="true">
      <v-card tile flat width="100%">
      <!-- Site Map -->
      <div class="secondary darken-1 text-center">

        <br />
        <br />
        <!--
        <dxs-site-map-section  :value="siteMapModel" />
        -->
        <br />
      </div>

      <div class="secondary darken-3 text-center">
          <br />
          <span class="white--text caption">{{ $t('shell.copyright', { year: copyrightYear }) }}</span>
          <br />
          <br />
      </div>
      </v-card>
    </v-footer>

    <!-- ========================================== -->
    <!-- Generic Error Handling Dialog -->
    <!-- ========================================== -->
    <v-dialog v-model="showErrorDialog" width="640" persistent>

      <v-card tile text>

          <!-- Header -->
          <v-card-text class="error"/>
          <v-card-text class="error white--text">
              <div class="title"> {{$t('application.shell.dialogs.error.title')}}</div>
              <div class="subtitle">{{$t('application.shell.dialogs.error.subtitle')}}</div>
          </v-card-text>

          <!-- DXS-274: Error Information is handled by the embedded component -->
          <c-error-info-card :value="error" tile flat />

          <!-- Footer -->
          <v-divider />
          <v-card-actions>
              <v-btn block text @click="clearError">{{$t('application.shell.dialogs.error.action.close')}}</v-btn>
          </v-card-actions>

        </v-card>
    </v-dialog>

    <!-- ========================================== -->
    <!-- Generic Message Panel -->
    <!-- ========================================== -->
    <dxs-message-panel :value="messagePanelConfig" />

    <!-- =========================================== -->
    <!-- About Dialog                                -->
    <!-- =========================================== -->
    <v-dialog v-model="showInfoDialog" width="640" persistent>
        <v-card flat tile>
            <v-toolbar flat>
                <v-toolbar-title>{{$t('application.shell.about')}}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn @click="showInfoDialog = false" icon><v-icon>close</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-list>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-subtitle>Version / Build</v-list-item-subtitle>
                        <v-list-item-title>{{version}} / {{build}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-subtitle>Tenant</v-list-item-subtitle>
                        <v-list-item-title>{{tenantId}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-subtitle>Stage</v-list-item-subtitle>
                        <v-list-item-title>Production</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider />
            <v-card-text class="text-center text-caption pt-5">
              {{ $t('shell.copyright', { year: copyrightYear }) }}
            </v-card-text>
        </v-card>
    </v-dialog>


    <!-- Display overlay -->

    <dxs-user-message-toast v-if="showUserMessageDialog" :value="userMessage" class="user-message" />



    <!-- ========================================== -->
    <!-- Generic Progress Indicator -->
    <!-- ========================================== -->
    <dxs-progress-indicator :loading="state.loading" :label="state.loadingText" />


  </v-app>
</template>

<script>
import MessagePanel from '@/message-panel';
import ProgressIndicator from '@/progress-indicator';
import ApplicationShellViewport from '@/application-shell-viewport';
import NavigationItemGroupComponent from '@/core/components/navigation-item-group.component';
import NavigationItemListComponent from '@/core/components/navigation-item-list.component';
import CollectionTagNavigationItemComponent from '@/core/components/collection-tag-navigation-item.component';
import UserMessageToastComponent from '@/core/components/shell/user-message-toast.component';

import Vue from 'vue';
import Configuration from '@/utils/configuration';

import { get, isEmpty } from 'lodash';

const MODULE_NAME = "applicationShell";

export default {
  name: "c-app-shell",

  components: {
    'dxs-message-panel': MessagePanel,
    'dxs-progress-indicator': ProgressIndicator,
    'dxs-navigation-item-group' : NavigationItemGroupComponent,
    'dxs-navigation-item-list' : NavigationItemListComponent,
    'dxs-viewport' : ApplicationShellViewport,
    'dxs-user-message-toast' : UserMessageToastComponent,
    'dxs-collection-tag-navigation-item' : CollectionTagNavigationItemComponent
  },

  props: ["locales"],

  data: () => ({
    drawer: false,
    messagePanelConfig: {},

    showErrorDialog: false,
    error: null,

    showUserMessageDialog: false,
    userMessage: null,

    showInfoDialog: false,

    state: {
      loading: false,
      loadingText: undefined
    },

    shellTitle: "shell.title",


    build: process.env.VUE_APP_BUILD_NUMBER,
    version: process.env.VUE_APP_VERSION,

    copyrightYear: process.env.VUE_APP_COPYRIGHT_YEAR
  }),

  // Provide must be a function as it provides instance-specific information (see title)
  provide: function () {
    return {
        shellService: {
        setTitle: this.setShellTitle,
        getTitle: this.getShellTitle,

        setLocale: this.setShellLocale,
        getLocale: this.getShellLocale,

        getViewActions: this.getViewActions,
        // loading: this.state.loading
        },

        shellState: this.state,


        /**
         * This service provides a means to display messages (e.g. info toasts, etc.) to the current user.
         */
        messageService: {

            send: this.displayMessage,

          /* 
          * config: title, text, type e.g info, warn, error
          */
         show: (config) => this.messagePanelConfig = config
        },

        themeService: {

            get primary() {
                return get(this.$vuetify, 'theme.themes.light.primary');
            },

            get secondary() {
                return get(this.$vuetify, 'theme.themes.light.secondary');
            },

            get accent() {
                return get(this.$vuetify, 'theme.themes.light.accent');
            }
        },

        /**
         * This service is used to provide a globally consistent way of displaying a progress loader.
         */
        progressIndicatorService: {
            show: this.showProgressIndicator,

            hide: this.hideProgressIndicator,

            updateText: this.updateProgressIndicatorText
        },

        storageService: {

            setItem(key, value) {

                if (localStorage) {
                    localStorage.setItem('amentis-'  + key, JSON.stringify(value));
                }
            },

            hasItem(key) {

                if (localStorage) {
                    return localStorage.getItem('amentis-'  + key) !== null;
                }

                // When no localStorage is available
                return false; 
            },

            getItem(key, defaultValue) {
                
                if (this.hasItem(key)) {

                   let item = localStorage.getItem('amentis-'  + key);
                   item = JSON.parse(item);
                   return item ? item : defaultValue;
                }

                return defaultValue ? defaultValue : null;

            }
        },

        /**
         * This service is used to implement a globally consistent error handling routine.
         * <code>
         * try {
         * 
         *  this.callSomeMethod('Hello World');
         * } catch (error) {
         *  this.errorHandlerService.handleError(error);
         * }
         * </code>
         */
        errorHandlerService: {
            handleError: this.handleError,
        },

        /**
         * This service grants access to the currently logged in user 
         * (info and access privileges).
         */
        userContextService : Vue.$user,

        routerService : {
        
            /**
             * Returns true, if the current User's authorization is sufficient for navigating this route,
             * false otherwise.
             */
            isAuthorizedForRoute(route) {

                    if (route && route.meta && route.meta.authorization) {

                    let authorization = route.meta.authorization;
                    if (authorization.roles) {
                        const authorized = Vue.$user.hasAllRoles(authorization.roles);
                        return authorized;
                    }
                }

                // If no configuration applies, simply return true.
                return true;
            }
        },

        /**
         * This service grants read-only access to build-time environment variables.
         */
        envContextService: {

            get(name) {
                return Configuration.value(name);
            },

            get locales() {
                return this.locales;
            },

            get tenantId() {
                return this.get('TENANT_ID');
            },

            get apiGatewayHost() {
                return this.get('BACKEND_HOST');
            },

            get apiGatewayPort() {
                return this.get('BACKEND_PORT');
            },

            get apiGatewayHostPort() {
                let hostPort = this.apiGatewayHost;
                if (this.apiGatewayPort) {
                    hostPort = hostPort.concat(':', this.apiGatewayPort)
                }

                return hostPort;
            },
            get serviceHealthCheckIntervalMS(){
              let intervalTime = this.get('SERVICE_HEALTH_CHECK_INTERVAL_MS')
              // service health check interval time mus be at least 10 seconds, 
              // otherwise a default interval of 9 seconds will be set
              if(!intervalTime || intervalTime < 10000){
                // this.$log.warn('service health check interval time mus be at least 10 seconds, otherwise a default interval of 9 seconds will be set');
                intervalTime = 90000;
              }
              return  intervalTime;
            }
        },

        /**
         * A service for checking permissions for working with collections.
         */
        collectionPermissionService: {

            hasReadPermission: this.hasReadPermission,

            hasExportPermission: this.hasExportPermission,

            hasDeletePermission: this.hasDeletePermission,

            hasImportPermission: this.hasImportPermission,
        }

    };
  },

  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        
        // Set the window title
        const navigation = get(to, 'meta.navigation', { i18n: { en: { name: 'amentis Data Insights'} } });
        const title = this.$dxs.i18n.localizedName(navigation, this.$i18n.locale);
        this.setWindowTitle(title);
        
        // Update notifications count.
        this.setNotificationsCount();
      }
    }
  },

  computed: {

    loading() {
      return this.state.loading;
    },

    tenantId: {
      get() {
        return Configuration.value('TENANT_ID');
      }
    },

    collections() {
      let collections = this.$store.getters["collections/response"].data;
      let locale = this.$i18n.locale;

      // Apply sorting
      collections.sort((first, second) => {
        const s1 = this.$core.filters.localizedName(
          first.collectionSettings,
          locale
        );
        const s2 = this.$core.filters.localizedName(
          second.collectionSettings,
          locale
        );
        return s1.localeCompare(s2, locale);
      });

      return collections;
    },

    meRoutes() {

        return this.$router.getRoutes().filter(route => {

            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-me'
        });
        
    },

    wizardRoutes() {

        return this.$router.getRoutes().filter(route => {

            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-wizards'
        });
    },

    appRoutes() {
      return this.$router.getRoutes().filter(route => {

        return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-apps'

      });
    },

    smartFactoryAppRoutes() {
      return this.$router.getRoutes().filter(route => {

        return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-solutions-smartfactoryservices-apps'

      });
    },

    smartFactoryMasterdataRoutes() {
      return this.$router.getRoutes().filter(route => {

        return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-solutions-smartfactoryservices-masterdata'

      });
    },

    batteryDataHubAppRoutes() {

      return this.$router.getRoutes().filter(route => {

        return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-solutions-batterydatahub-apps'

      });
    },

    batteryDataHubMasterdataRoutes() {
      return this.$router.getRoutes().filter(route => {

        return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-solutions-batterydatahub-masterdata'

      });
    },

    catalogRoutes() {
        return this.$router.getRoutes().filter(route => {

            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-catalog'
        });
    },

    dataExplorationRoutes() {
        return this.$router.getRoutes().filter(route => {

            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-dataexploration'
        });
    },

    integrationRoutes() {
        return this.$router.getRoutes().filter(route => {

            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-integration'
        });
    },

    userMgmtRoutes() {

        return this.$router.getRoutes().filter(route => {
            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-usermgmt'
        });
    },

    dataPreparationRoutes() {
        return this.$router.getRoutes().filter(route => {
            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-datapreparation'
        });
    },

    systemRoutes() {
        return this.$router.getRoutes().filter(route => {

            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-system'
        });
    },

    webhookRoutes() {
        return this.$router.getRoutes().filter(route => {

            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-webhooks'
        });
    },


    customRoutes() {
        return this.$router.getRoutes().filter(route => {

            return route.meta && route.meta.navigation && route.meta.navigation.categoryId === 'amentis-dxs-custom'
        });
    },

    notificationsCount() {
      return this.$store.getters["applicationShell/notificationsCount"];
    },

    planningAreas() {
      return this.$store.getters["sites/siteCollection"];
    },

    viewActionsExist() {
      return this.viewActions && this.viewActions.length > 0;
    },

    shellActionsExist() {
      return this.shellActions && this.shellActions.length > 0;
    },

    siteCollectionItems() {
      return this.$store.getters["sites/siteCollectionItems"];
    },

    siteCollectionEmpty() {
      return this.siteCollectionItems.length > 0;
    },

    user() {
        return Vue.$user;
    },

    userName() {
      return Vue.$user.name;
    },

    userEMail() {
      return Vue.$user.email;
    },

    userAvatarUrl() {
      return Vue.$user.pictureUrl ? Vue.$user.pictureUrl : null;
    },

    userManagementEnabled() {
      return Vue.$user.userManagementEnabled ? Vue.$user.userManagementEnabled : false;
    },

    collectionRuleMap() {
        return this.$store.getters[MODULE_NAME + '/collectionRuleMap'];
    },

    userAuthorizationDataAvailable() {
        return this.$store.getters[MODULE_NAME + '/userAuthorizationDataAvailable'];
    },

    tags() {
        return this.$store.getters[MODULE_NAME + '/tags'];
    },

    tagsEmpty() {
        return isEmpty(this.tags);
    }
    

  },

  methods: {

    setShellTitle(shellTitle) {
      this.shellTitle = shellTitle;
    },

    getShellTitle() {
      return this.shellTitle;
    },

    getViewActions() {
      return this.viewActions;
    },

    setShellLocale(locale) {
      this.$i18n.locale = locale;

      // Set this value to the locale storage, so other views can fetch it from there.
      if (localStorage) {
        localStorage.setItem("amentis-locale", locale);
      }
    },

    getShellLocale() {
      return this.$i18n.locale;
    },

    equalsShellLocale(locale) {
      return this.getShellLocale() === locale;
    },

    setWindowTitle(title){

        if (document) {
            document.title = title;
        }
    }, 

    showProgressIndicator()  {
        this.state.loadingText = undefined;
        this.state.loading = true;
    },

    hideProgressIndicator()  {
        this.state.loadingText = undefined;
        this.state.loading = false;
    },

    updateProgressIndicatorText(text) {
        this.state.loadingText = text;
    },

    logout() {
      Vue.$user.logout();
    },

    goToUserAccount() {
        this.$router.push({name: 'myUserAccount'})
    },

    goToUserAuthorizationDashboard() {
      this.$router.push({name: 'MyUserAuthorizationDashboardView'})
    },

    account() {
      Vue.$user.account();
    },

    handleError(error) {
      this.$log.error(error.toJSON ? error.toJSON() : error );
      this.error = error;

      this.showErrorDialog = true;
    },

    clearError() {
      this.showErrorDialog = false;
      this.error = null;
    },

    setNotificationsCount() {
      this.$store.dispatch("applicationShell/loadNotificationsCount");
    },

    displayMessage(message, duration=5000) {
        this.userMessage = message;
        this.showUserMessageDialog = true;

        // Reset message after timeout
        setTimeout(() => {
            this.showUserMessageDialog = false;
            this.userMessage = null;
        }, duration);
    },

    // =================================================================
    // Methods for collection access
    // =================================================================
    hasReadPermission({collectionId}) {
        return get(this.collectionRuleMap, collectionId + '.readPermission', false);
    },

    hasExportPermission({collectionId}) {
        return get(this.collectionRuleMap, collectionId + '.exportPermission', false);
    },

    hasDeletePermission({collectionId}) {
        return get(this.collectionRuleMap, collectionId + '.deletePermission', false);
    },

    hasImportPermission({collectionId}) {
        return get(this.collectionRuleMap, collectionId + '.importPermission', false);
    },

    /**
     * Load the user authorization at startup time.
     */
    async loadUserAuthorization() {
        
        this.showProgressIndicator();
        try {
            this.$log.debug(`User authorization for user ${this.user.userName}: Preparing backend call for loading authorization data.`);
            await this.$store.dispatch(MODULE_NAME + '/loadUserAuthorization', {userId: this.user.userName});
            this.$log.debug(`User Authorization for user ${this.user.userName}: Successfully loaded authorization data from backend.`);
        } catch (error) {
            this.$log.debug(`User Authorization for user ${this.user.userName}: Failed to load authorization data from backend.`);
            this.handleError(error);
        } finally {
            this.hideProgressIndicator();
        }
    },

    async loadTags() {
        
        this.showProgressIndicator();
        try {
            await this.$store.dispatch(MODULE_NAME + '/loadTags');
        } catch (error) {
            this.handleError(error);
        } finally {
            this.hideProgressIndicator();
        }
    }

  },

  async created() {

    // ==========================================
    // Rehydrate the view
    // ==========================================
    // In case a locale has been set, rehydrate the view.
    if (localStorage) {
      let locale = localStorage.getItem("amentis-locale");
      if (locale) {
        this.$i18n.locale = locale;
      }
    }


    this.$log.debug(`Loading notifications...`)
    // ==========================================
    // Initialize notifications
    // ==========================================
    await this.setNotificationsCount();

    // ==========================================
    // Initialize user authorization
    // ==========================================
    this.$log.debug(`Loading user access authorization...`)
    await this.loadUserAuthorization();

    this.$log.debug(`Loading tags...`)
    await this.loadTags();
  },
};
</script>

<style scoped>

.user-message {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
    z-index: 1000;
}


</style>
