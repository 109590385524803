<template>
    <v-sheet>
        <!-- ****************************** -->
        <!-- BREADCRUMBS                    -->
        <!-- ****************************** -->
        <v-breadcrumbs class="secondary darken-1">
            <v-breadcrumbs-item :disabled="true">{{$t('access-control-lists-editor.view.breadcrumb.root')}}</v-breadcrumbs-item>
            <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
            <v-breadcrumbs-item>{{name}}</v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-divider />

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form 
            :title="$t('access-control-lists-editor.view.title', {name: name})" 
            v-model="model"
            :editable="hasEditRole"
            @update-confirmed="save"
            @update-canceled="cancel"
            >
            
            <template v-slot:default>
                <v-sheet color="grey lighten-3">
                    
                    <v-tabs 
                        v-model="selectedTab" 
                        :vertical="$vuetify.breakpoint.mdAndUp" 
                        color="grey" 
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
                        slider-color="grey" 
                        show-arrows 
                        :fixed-tabs="!$vuetify.breakpoint.mdAndUp">
                        

                        <!-- Tabs -->
                        <v-tab :key="0">{{$t('access-control-lists-editor.view.tabs.general')}}</v-tab>
                        <v-tab :key="1">{{$t('access-control-lists-editor.view.tabs.collections')}}</v-tab>
                        <v-tab :key="2">{{$t('access-control-lists-editor.view.tabs.json')}}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="accessControlList"/>
                            </v-tab-item>

                            <!-- Collection Access -->
                            <v-tab-item :key="1">
                                <dxs-collection-access-editor-page v-model="model" />
                            </v-tab-item>

                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
   
        </c-item-editor-form>
    </v-sheet>
</template>


<script>
import { get } from 'lodash';

import CollectionAccessEditorPage from '@/core/components/usermgmt/access-control/collection-access-editor-page.component';
import AccessControlListsEditorModule from '@/core/store/modules/user-mgmt/access-control/access-control-lists-editor.module';
import Roles from '@/roles';

const MODULE_NAME = 'accessControlListEditor';

export default {

    props: {

        value: {
            type: Object,
            required: true
        }
    },


    data: () => ({
        selectedTab : 0
    }),

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    components: {
      
      'dxs-collection-access-editor-page' : CollectionAccessEditorPage
    },

    computed: {

        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        hasEditRole: {
            get() {
                return this.userContextService.hasRole(Roles.ACCESS_CONTROL_LISTS_EDIT);
            }
        },
        
        model: {

            get() {
                return this.$store.getters[MODULE_NAME + '/model'];
            }, 
            
            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateModel", value);
            },
        },

        accessControlList: {
            get() {
                return get(this.model, 'accessControlList');
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateAccessControlList", value);
            }
        },


    },

    methods: {

        async refresh() {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadData", {name: this.name})
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async save() {
            
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/saveData")
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push({
                name: 'accessControlListsList'
            })
        }
    },

    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, AccessControlListsEditorModule);
        }

        this.refresh();
        
    }
}
</script>

<i18n>
{
    "en" : {
        "access-control-lists-editor.view.title" : "Access Control List {name}",

        "access-control-lists-editor.view.tabs.general" : "General",
        "access-control-lists-editor.view.tabs.collections" : "Collections",
        "access-control-lists-editor.view.tabs.json" : "JSON",

        "access-control-lists-editor.view.breadcrumb.root" : "Access Control Lists"
    },

    "de" : {
        "access-control-lists-editor.view.title" : "Zugriffsberechtigungen {name}",
        "access-control-lists-editor.view.tabs.general" : "Allgemein",
        "access-control-lists-editor.view.tabs.collections" : "Datenbereiche",
        "access-control-lists-editor.view.tabs.json" : "JSON",

        "access-control-lists-editor.view.breadcrumb.root" : "Zugriffsberechtigungen"
    }
}
</i18n>
