<template>
  <c-editable-item-list 
    flat 
    header-style="none" 
    :title="$t('time-series-item-editor.component.title')"
    :items="items"
    :new-item="{ collectionId: null, measureId: null, timeDimensionId: null, color: null, scriptExpression: null, filterExpression: '*'}"
    @input="onInput"
    ref="itemList"
    edit-dialog-style="fullscreen"
    > 
      
        <template v-slot:item-list-body-row="{item}">
            <td style="width:30px;">
                <c-color-label :value="item.color" :showLabel="false" />
            </td> 
            <td>
                {{timeSeriesItemToText(item)}}
            </td>
        </template> 


        <template v-slot:item-list-editor-title="{isNewItem}">
            <div v-if="isNewItem" class="title">{{$t('time-series-item-editor.component.header.title.newItem')}}</div>
            <div v-else class="title">{{$t('time-series-item-editor.component.header.title.editItem')}}</div>
          </template>

        <template v-slot:item-list-editor="{item,  isNewItem}">
            <!--
            <v-stepper v-model="currentStep" vertical flat>
                
                <v-stepper-step :step="1" :complete="currentStep > 1">{{$t('time-series-item-editor.component.editor.steps.1')}}</v-stepper-step>
                <v-stepper-content :step="1">
                    <v-card tile flat>
                        <v-card-text>
                            <div class="mb-5">{{$t('time-series-item-editor.component.editor.steps.1')}}</div>
                            <c-item-selector 
                                :label="$t('time-series-item-editor.component.fields.collection')" 
                                v-model="item.collectionId" 
                                :items="collections"
                                filled 
                                :disabled="!isNewItem" />
                            
                        </v-card-text>
                        <v-card-action>
                            <v-btn color="accent" @click="currentStep = currentStep + 1">Weiter</v-btn>
                        </v-card-action>
                    </v-card>
                </v-stepper-content>

                <v-stepper-step :step="2" :complete="currentStep > 2">{{$t('time-series-item-editor.component.editor.steps.2')}}</v-stepper-step>
                <v-stepper-content :step="2">
                    <v-card>
                        <v-card-text>
                            <div class="mt-5 mb-5">{{$t('time-series-item-editor.component.editor.steps.2')}}</div>
                            <c-item-selector 
                                :label="$t('time-series-item-editor.component.fields.measure')" 
                                v-model="item.measureId" 
                                :items="collectionsMap[item.collectionId] ? collectionsMap[item.collectionId].measures : []"
                                filled 
                                :disabled="!isNewItem" />
                            <c-item-selector 
                                :label="$t('time-series-item-editor.component.fields.timeDimension')" 
                                v-model="item.timeDimensionId"
                                :items="collectionsMap[item.collectionId] ? collectionsMap[item.collectionId].timeDimensions : []"
                                filled />

                        </v-card-text>
                        <v-card-action>
                            <v-btn color="accent" @click="currentStep = currentStep + 1">Weiter</v-btn>
                        </v-card-action>
                    </v-card>
                </v-stepper-content>

                <v-stepper-step :step="3" :complete="currentStep > 3">{{$t('time-series-item-editor.component.editor.steps.3')}}</v-stepper-step>
                <v-stepper-content :step="3">
                    <v-card>
                        <v-card-text>
                            <div class="mt-5 mb-5">{{$t('time-series-item-editor.component.editor.steps.3')}}</div>
                            <v-text-field :label="$t('time-series-item-editor.component.fields.filter')" filled v-model="item.filterExpression" />
                        </v-card-text>
                        <v-card-action>
                            <v-btn color="accent" @click="currentStep = currentStep + 1">Weiter</v-btn>
                        </v-card-action>
                    </v-card>
                </v-stepper-content>


                <v-stepper-step :step="4" :complete="currentStep > 4">{{$t('time-series-item-editor.component.editor.steps.4')}}</v-stepper-step>
                <v-stepper-content :step="4">
                    <v-card>
                        <v-card-text>
                             
                            <div class="mt-5 mb-5">{{$t('time-series-item-editor.component.editor.steps.4')}}</div>
                            <v-textarea :label="$t('time-series-item-editor.component.fields.expression')" filled v-model="item.scriptExpression"></v-textarea>
                        </v-card-text>
                        <v-card-action>
                            <v-btn color="accent" @click="currentStep = currentStep + 1">Weiter</v-btn>
                        </v-card-action>
                    </v-card>
                </v-stepper-content>

            </v-stepper>
            -->
     
            <v-card tile flat>
                
                <v-card-text>
                    <div class="text-h6">{{$t('time-series-item-editor.component.editor.steps.1')}}</div>
                    <div class="mb-5">{{$t('time-series-item-editor.component.wizard.step1')}}</div>
                    <c-item-selector 
                        :label="$t('time-series-item-editor.component.fields.collection')" 
                        v-model="item.collectionId" 
                        :items="collections"
                        filled 
                        :disabled="!isNewItem" 
                        :rules="[v => !!v || 'Must not be empty']"/>
                    
                </v-card-text>

                <v-card-text v-if="collectionSelectionComplete(item)">
                    <div class="text-h6">{{$t('time-series-item-editor.component.editor.steps.3')}}</div>
                    <div class="mt-5 mb-5">{{$t('time-series-item-editor.component.wizard.step3')}}</div>
                    <v-text-field :label="$t('time-series-item-editor.component.fields.filter')" filled v-model="item.filterExpression" :rules="[v => !!v || 'Must not be empty']" />
                </v-card-text>

                <v-card-text  v-if="collectionSelectionComplete(item)">
                    <div class="text-h6">{{$t('time-series-item-editor.component.editor.steps.2')}}</div>
                    <div class="mt-5 mb-5">{{$t('time-series-item-editor.component.wizard.step2')}}</div>
                    <c-item-selector 
                        :label="$t('time-series-item-editor.component.fields.measure')" 
                        v-model="item.measureId" 
                        :items="collectionsMap[item.collectionId].measures"
                        filled 
                        :disabled="!isNewItem" 
                        :rules="[v => !!v || 'Must not be empty']" />
                    <c-item-selector 
                        :label="$t('time-series-item-editor.component.fields.timeDimension')" 
                        v-model="item.timeDimensionId"
                        :items="collectionsMap[item.collectionId].timeDimensions"
                        filled 
                        :rules="[v => !!v || 'Must not be empty']"
                        />
                    <c-color-selector :label="$t('time-series-item-editor.component.fields.color')" filled  v-model="item.color" :return-object="false" :rules="[v => !!v || 'Must not be empty']" />
                    <v-divider />
                    <v-textarea :label="$t('time-series-item-editor.component.fields.expression')" filled v-model="item.scriptExpression"></v-textarea>
                </v-card-text>

          </v-card>

      </template>
  </c-editable-item-list>
</template>

<script>
import { get, isEmpty } from 'lodash';

export default {

    name: 'time-series-item-editor',

    props: {

        items: {
            type: Array,
            required: false,

        },

        /**
         * [{
         *      collectionSettings: { ... },
         *      schema: { ... }
         * }]
         */
        collectionData: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    model: {
        prop: 'items',
        event: 'input'
    },


    data() {
        return {
           currentStep: 1
        }

    },

    computed: {

        

        collections() {
            
            const collections = [];
            for (const c of this.collectionData) {

                const collection = {

                    name: c.collectionSettings.name,
                    i18n: c.collectionSettings.i18n,

                    measures: [],
                    timeDimensions: []
                };

                const attributes = get(c, 'schema.attributes', {});
                for (const name in attributes) {

                    const attribute = attributes[name];
                    if (this.$dxs.attributes.isMeasure(attribute)) collection.measures.push(attribute);
                    else if (this.$dxs.attributes.isDimension(attribute) && this.$dxs.attributes.isTypeDateTime(attribute)) collection.timeDimensions.push(attribute);
                }

                // In order to be used in time series, a collection must contain at least one measure and at least one time dimension.
                // Otherwise the collection must not be presented to the user.
                if (!isEmpty(collection.measures) && !isEmpty(collection.timeDimensions)) {
                    collections.push(collection);
                }
                
            }

            return collections;
        },

        collectionsMap() {

            let collectionMap = {};
            for (const collection of this.collections) {
                collectionMap[collection.name] = collection;
            }

            return collectionMap;
        },
    },

    

    methods: {

        /**
         * Will open the "Add new Item" dialog. 
         */
        addNewItem() {
             this.$refs.itemList.onAddItem();
        },

        onInput(event) {
            //Propage the event
            this.$emit('input', event);
        },

        getSchema(collectionId) {

            if (!collectionId) return {};

        },

        collectionSelectionComplete(item) {
            return item.collectionId;
        },

        timeSeriesItemDefinitionComplete(item) {
            return this.collectionSelectionComplete(item) && item.measureId && item.timeDimensionId && item.color;
        },
 
        getTimeSeriesCollectionName(item) {
            
            const collection = this.collectionData.find(e => e.collectionSettings.collectionId === item.collectionId); 
            if (collection) {
                return this.$dxs.i18n.localizedName(collection.collectionSettings, this.$i18n.locale);
            } else {
                return 'Error occured: Collection Not Found';
            }
           
        },

        getTimeSeriesMeasureAndTimeDimension(item) {
            
            const collection = this.collectionData.find(e => e.collectionSettings.collectionId === item.collectionId); 
            if (collection) {

                //const collectionSettings = collection.collectionSettings;
                const schema             = collection.schema;

                const measureName = this.$dxs.i18n.localizedName(schema.attributes[item.measureId], this.$i18n.locale);
                const timeDimensionName = this.$dxs.i18n.localizedName(schema.attributes[item.timeDimensionId], this.$i18n.locale);

                return measureName + ' / ' + timeDimensionName;
            } else {
                return 'Error occured: Collection Not Found';
            }
        },

        /**
         * Produces a text representation of the time series object which uses internationalized names.
         */
        timeSeriesItemToText(item) {
            
            const collection = this.collectionData.find(e => e.collectionSettings.collectionId === item.collectionId);  
            if (collection) {

                const collectionSettings = collection.collectionSettings;
                const schema             = collection.schema;

                const name = this.$dxs.i18n.localizedName(collectionSettings, this.$i18n.locale);
                const measureName = this.$dxs.i18n.localizedName(schema.attributes[item.measureId], this.$i18n.locale);
                const timeDimensionName = this.$dxs.i18n.localizedName(schema.attributes[item.timeDimensionId], this.$i18n.locale);
                
                let text = name;

                if (item.filterExpression) { 
                    text = text + ' (' + this.$dxs.formatting.truncateMiddle(item.filterExpression, 32) + ')' 
                }
                text = text + ': ' + measureName +  ' / ' + timeDimensionName;

                return text;

            } else {
                return 'Unknown Collection';
            }
        }
    },




}
</script>

<i18n>
{
    "en" : {
        "time-series-item-editor.component.title" : "Time Series",

        "time-series-item-editor.component.header.title.editItem" : "Edit Time Series Definition",
        "time-series-item-editor.component.header.title.newItem" : "New Time Series Definition",

        "time-series-item-editor.component.editor.steps.1" : "Select Collection",
        "time-series-item-editor.component.editor.steps.2" : "Select Metrics",
        "time-series-item-editor.component.editor.steps.3" : "Define Filter",
        "time-series-item-editor.component.editor.steps.4" : "Postprocessing (Optional)",

        "time-series-item-editor.component.fields.collection" : "Collection",
        "time-series-item-editor.component.fields.measure" : "Measure",
        "time-series-item-editor.component.fields.timeDimension" : "Date and Time Dimension",
        "time-series-item-editor.component.fields.color" : "Color",
        "time-series-item-editor.component.fields.expression" : "Expression",
        "time-series-item-editor.component.fields.filter" : "Filter Expression (Lucene QL)"
    },

    "de" : {
        "time-series-item-editor.component.title" : "Datenreihen",

        
        "time-series-item-editor.component.header.title.editItem" : "Datenreihe bearbeiten",
        "time-series-item-editor.component.header.title.newItem" : "Neue Datenreihe anlegen",

        "time-series-item-editor.component.editor.steps.1" : "Datenbereich auswählen",
        "time-series-item-editor.component.editor.steps.2" : "Metriken auswählen",
        "time-series-item-editor.component.editor.steps.3" : "Filterung festlegen",
        "time-series-item-editor.component.editor.steps.4" : "Nachverarbeitung (Optional)",

        "time-series-item-editor.component.wizard.step1" : "Legen Sie bitte zuerst fest, welcher Datenbereich verwendet werden soll.",
        "time-series-item-editor.component.wizard.step2" : "Legen Sie nun fest, welche Kennzahl und welcher Zeitbezug für die Anzeige verwendet werden sollen.",
        "time-series-item-editor.component.wizard.step3" : "Durch die optionale Angabe eines Filters können Sie einschränken, welche Dokumente aus dem Datenbereich geladen werden sollen. Die Angabe eines optionalen Skripts erlaubt die Anpassung der Datenpunkte (z.B. Rundung) nach dem Laden der Daten.",
        "time-series-item-editor.component.fields.collection" : "Datenbereich",
        "time-series-item-editor.component.fields.measure" : "Kennzahl",
        "time-series-item-editor.component.fields.timeDimension" : "Datumsbezug",
        "time-series-item-editor.component.fields.color" : "Farbe",
        "time-series-item-editor.component.fields.expression" : "Skript",
        "time-series-item-editor.component.fields.filter" : "Filter-Ausdruck (Lucene QL)",
        "time-series-item-editor.component.fields.filter.hint" : "Durch die Definition eines Filters können Sie die Anzahl der Dokumente einschränken, die in dieser Datenreihe betrachtet werden."
    }
}
</i18n>