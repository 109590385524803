<template>
    <v-sheet>
    <!-- ****************************** -->
    <!-- BREADCRUMBS                    -->
    <!-- ****************************** -->
    <v-breadcrumbs class="secondary darken-1">
      <v-breadcrumbs-item :disabled="true">{{$t("Katalog")}}</v-breadcrumbs-item>
      <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
      <v-breadcrumbs-item :to="{name: 'detectionJobTemplatesList'}" :disabled="false">{{ $t("Detection Job Templates") }} </v-breadcrumbs-item>
      <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
      <v-breadcrumbs-item>{{detectionJobTemplate | localizedName($i18n.locale)}}</v-breadcrumbs-item>
    </v-breadcrumbs>
        <c-item-editor-form 
            :title="$t('detection-job-templates-editor.view.title', {name: $dxs.i18n.localizedName(detectionJobTemplate, $i18n.locale)})" 
            v-model="response"
            @update-confirmed="save"
            @update-canceled="cancel"
            :editable="hasEditRole"
            >
        <template v-slot:default>
            <v-card-text  class="warning ma-3" v-if="!active">      
                <div class="text-body-2 white--text">
                    <v-icon color="white" class="pr-3">warning</v-icon>
                    {{$t('detection-job-templates-editor.view.warnings.notActive')}}
                </div>
            </v-card-text>
            <v-sheet color="grey lighten-3">
                <v-tabs 
                    v-model="selectedTabIndex" 
                    :vertical="$vuetify.breakpoint.mdAndUp" 
                    color="grey" 
                    :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
                    slider-color="grey" 
                    show-arrows 
                    :fixed-tabs="!$vuetify.breakpoint.mdAndUp"
                >
                <v-tab :key="0">{{$t('detection-job-templates-editor.view.tabs.general')}}</v-tab>
                <v-tab :key="1">{{$t('detection-job-templates-editor.view.tabs.detectionJobSource')}}</v-tab>
                <v-tab :key="2">{{$t('detection-job-templates-editor.view.tabs.detectionJobRules')}}</v-tab>
                <v-tab :key="3">{{$t('detection-job-templates-editor.view.tabs.detectionJobSchedule')}}</v-tab>
                <v-tab :key="4">{{$t('detection-job-templates-editor.view.tabs.detectionJobLogging')}}</v-tab>
                <v-tab :key="5">{{$t('detection-job-templates-editor.view.tabs.custData')}}</v-tab>
                <v-tab :key="6">{{$t('detection-job-templates-editor.view.tabs.json')}}</v-tab>

                <v-tabs-items v-model="selectedTabIndex">
                    <!-- General -->
                    <v-tab-item :key="0">
                        <c-name-and-i18n-item-editor-page v-model="detectionJobTemplate">
                            <template v-slot:content-append>
                                <v-divider/>
                                <v-card tile flat>
                                    <v-card-text>
                                        <v-container fluid>
                                            <v-switch 
                                                v-model="active" 
                                                :label="$t('detection-job-templates-editor.view.tabs.general.active.description')" 
                                                inset 
                                                color="accent" 
                                            />
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </c-name-and-i18n-item-editor-page>
                    </v-tab-item>

                    <!-- Detection Job Source -->
                    <v-tab-item :key="1">
                        <v-card tile flat>
                            <v-card-text class="text-h5">{{$t('detection-job-templates-editor.view.tabs.detectionJobSource')}}</v-card-text>
                            <v-divider/>
                            <v-container fluid>
                                <v-row justify="center">
                                    <v-col cols="12" md="6">
                                        <v-text-field :value="detectionJobTemplate.source.collectionId" disabled filled :label="$t('detection-job-templates-editor.view.sections.collections.sourceCollection')" />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field :value="detectionJobTemplate.source.dateTimeAttribute" disabled filled :label="$t('detection-job-templates-editor.view.sections.collections.dateTimeAttribute')" />
                                    </v-col>
                                </v-row>
                            </v-container>
                            
                            <v-divider />
                            <v-card-text>
                                <div class="text-h6">{{$t('detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.title')}}</div>
                                <div>{{$t('detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.description')}}</div>
                            </v-card-text>
                            <v-container fluid>   
                                <v-text-field
                                        v-model="timeWindowSizeSeconds"
                                        :rules="[
                                            v => v.match(/^[0-9]+$/) || $t('detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.validation.onlyNumbers'),
                                            v => !isNaN(parseFloat(v)) && v >= 1 || $t('detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.validation.notInRange'),
                                            v => !!v || $t('detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.validation.notEmpty'),
                                        ]"
                                        single-line
                                        type="number"
                                        :label="$t('detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.title')"
                                        filled
                                    ></v-text-field>
                            </v-container>
                            <v-divider/>
                            <v-card-text>
                                <div class="text-h6">{{$t('detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.title')}}</div>
                                <div>{{$t('detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.description')}}</div>
                            </v-card-text>
                            <v-container fluid> 
                                <c-item-multi-selector 
                                    chips
                                    v-model="aggregation"
                                    :label="$t('detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.title')"
                                    filled
                                    :items="aggregations"
                                />
                            </v-container>
                            <v-divider/>
                            <v-card-text>
                                <div class="text-h6">{{$t('detection-job-templates-editor.view.tabs.detectionJobSource.groupBy.title')}}</div>
                                <div>{{$t('detection-job-templates-editor.view.tabs.detectionJobSource.groupBy.description')}}</div>
                            </v-card-text>
                            <v-container fluid> 
                                <c-item-multi-selector 
                                    chips
                                    v-model="groupBy"
                                    :label="$t('detection-job-templates-editor.view.tabs.detectionJobSource.groupBy.title')"
                                    filled
                                    :items="dimensionList"
                                />
                            </v-container>
                            <v-divider />
                            <v-card-text>
                                <div class="text-h6">{{$t('detection-job-templates-editor.view.tabs.detectionJobSource.filtering.title')}}</div>
                                <div>{{$t('detection-job-templates-editor.view.tabs.detectionJobSource.filtering.description')}}</div>
                            </v-card-text>
                            <c-filter-editor
                                v-model="filter"
                                filled
                                :disabled="!hasEditRole"
                            />
                        </v-card>
                    </v-tab-item>

                    <!-- Detection Job Rules -->
                    <v-tab-item :key="2">
                        <v-card tile flat>
                            <v-card-text class="text-h5">{{$t('detection-job-templates-editor.view.tabs.detectionJobRules')}}</v-card-text>
                            <v-divider />
                            <dxs-detection-rules-list
                            :context="context"
                            :items="rules"
                            @item-updated="addRule($event.item, $event.index)"
                            @item-removed="removeRule($event.item, $event.index)"
                            :disabled="!hasEditRole"
                            />
                 
                        </v-card>
                    </v-tab-item>

                    <!-- Detection Job Scheduling -->
                    <v-tab-item :key="3">
                        
                        <v-card tile flat>
                            <v-card-text class="text-h5">{{$t('detection-job-templates-editor.view.tabs.detectionJobSchedule')}}</v-card-text>
                            <v-divider/>
                            <c-scheduler-expression-editor v-model="schedule" filled show-cron-expression/>
                        </v-card>
                    </v-tab-item>

                    <!-- Detection Job Logging -->
                    <v-tab-item :key="4">
                        <v-card tile flat>
                            <v-card-text class="text-h5">{{$t('detection-job-templates-editor.view.tabs.detectionJobLogging')}}</v-card-text>
                            <v-divider />
                            <v-card-text>
                                <div class="text-h6">{{$t('detection-job-templates-editor.view.tabs.detectionJobLogging.title')}}</div>
                            </v-card-text>
                            <v-container fluid>
                                <v-row justify="center">
                                    <v-col cols="12">
                                        <v-text-field v-model="loggingCollection" filled :label="$t('detection-job-templates-editor.view.tabs.detectionJobLogging.loggingCollection')" />
                                    </v-col>
                                    <v-col cols="12" >
                                        <v-text-field v-model="loggingSchema" filled :label="$t('detection-job-templates-editor.view.tabs.detectionJobLogging.loggingSchema')" />
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-divider/>
                            <v-card-text>
                                <div class="text-h6">{{$t('detection-job-templates-editor.view.tabs.detectionJobLogging.partitioningStrategy.title')}}</div>
                                <div>{{$t('detection-job-templates-editor.view.tabs.detectionJobLogging.partitioningStrategy.description')}}</div>
                            </v-card-text>
                            <c-item-radio-single-selector 
                                v-model="partitioningStrategy"  
                                :items="partitioningStrategies"
                                hideFilter
                                />
                        </v-card>
                    </v-tab-item>

                    

                    <!-- Customer specific Data -->
                    <v-tab-item :key="5">
                        <v-card tile flat class="pl-5 pr-5">
                        <v-card-title>{{$t('detection-job-templates-editor.component.sections.custData.title')}}</v-card-title>
                        <v-divider />
                        <v-card-text>
                            {{$t('detection-job-templates-editor.component.sections.custData.subtitle')}}
                            </v-card-text>
                            <v-container fluid>
                            <v-row>
                                <v-col cols="12">
                                    <c-params-editor 
                                        title="Kundenspezifische Parameter" 
                                        header-style="lite" 
                                        flat 
                                        :items="custDataItems"
                                        @item-updated="oncustDataUpdated" 
                                        @item-removed="oncustDataRemoved" 
                                        />
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>

                    <!-- JSON -->
                    <v-tab-item :key="6">
                        <c-json-item-editor-page v-model="response" />
                    </v-tab-item>
                </v-tabs-items>
            </v-tabs>
        </v-sheet>
        </template>
    </c-item-editor-form>
    </v-sheet>
</template>

<script>
import Roles from '@/roles';
import { get, set, cloneDeep } from 'lodash';
import DetectionJobTemplatesEditorModule from '@/core/store/modules/catalog/detection-job-templates/detection-job-templates-editor.module';
import DetectionRulesListComponent from '@/core/components/catalog/detection-job-templates/detection-rules-list.component.vue';

const MODULE_NAME = "detectionJobTemplatesEditor";

const OPERATIONS = ["AVERAGE", "MEDIAN", "MIN", "MAX", "SUM"/*, "PERCENTILE_25", "PERCENTILE_75", "PERCENTILE_90", "PERCENTILE_95", "PERCENTILE_99"*/];

export default {
  name: "detection-job-templates-editor",

  props: {
    value: {
      type: Object,
      required: false,
      validator: (value) => value.name
    },

    options: {
      type: Object,
      required: false,
    },
  },

  inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

  components: {
      'dxs-detection-rules-list' : DetectionRulesListComponent
  },

  data: () => ({
      selectedTabIndex: 0,

      partitioningStrategies: [{
                        name: 'PARTITION_BY_MONTH', 
                        i18n: {
                            en : {
                                name: 'Partition By Month'
                            },

                            de :  {
                                name: 'Monatliche Partitionierung'
                            }
                        }
                    },{
                        name: 'PARTITION_BY_DAY', 
                        i18n: {
                            en : {
                                name: 'Partition By Day'
                            },

                            de :  {
                                name: 'Tägliche Partitionierung'
                            }
                        }
                    }]
    

  }),


  methods: {
    async save() {

      this.progressIndicatorService.show();
      try {
          await this.$store.dispatch(MODULE_NAME + "/saveData", { name: this.name })
      } catch (error) {
          this.errorHandlerService.handleError(error);
      } finally {
          this.progressIndicatorService.hide();
      }
    },

    async refresh() {
        this.progressIndicatorService.show();
        try {

            // First reset ...
            await this.$store.dispatch(MODULE_NAME + "/resetData", { name: this.name })

            // ... then reload
            await this.$store.dispatch(MODULE_NAME + "/loadData", { name: this.name })
        } catch (error) {
            this.errorHandlerService.handleError(error);
        } finally {
            this.progressIndicatorService.hide();
        }
    },

    async addRule(rule, index) {

        this.progressIndicatorService.show();
        try {
            await this.$store.dispatch(MODULE_NAME + "/addRule", { rule, index })
        } catch (error) {
            this.errorHandlerService.handleError(error);
        } finally {
            this.progressIndicatorService.hide();
        }
    },

    async removeRule(rule, index) {

        this.progressIndicatorService.show();
        try {
            await this.$store.dispatch(MODULE_NAME + "/removeRule", { rule, index })
        } catch (error) {
            this.errorHandlerService.handleError(error);
        } finally {
            this.progressIndicatorService.hide();
        }
    },

    oncustDataUpdated(event) {

            this.$log.debug(JSON.stringify(event));
            
            if (event && event.item) {
                let clone = cloneDeep(this.custData);
                clone[event.item.name] = event.item.value;

                this.custData = clone;
            }

        },

    oncustDataRemoved(event) {
            
        this.$log.debug(JSON.stringify(event));


        if (event && event.item) {
            let clone = cloneDeep(this.custData);
            delete clone[event.item.name];

            this.custData = clone;
        }
    },

    isDimension(value) {
      return this.$dxs.attributes.isDimension(value);
    },

    isMeasure(value) {
      return this.$dxs.attributes.isMeasure(value);
    },


    cancel() {
      this.$router.push({
        name: "detectionJobTemplatesList",
      });
    },
  },

  computed: {

    response() {
      return this.$store.getters[MODULE_NAME + "/response"];
    },

    systemParameters() {
      return this.$store.getters[MODULE_NAME + "/systemParameters"];
    },

    detectionJobTemplate: {
            get() {
                return get(this.response, 'detectionJobTemplate', {});
            },

            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateDetectionJobTemplate", value);
            }
        },

    schema(){
        return this.response.schema;
    },

    context(){
        return {response: this.response, systemParameters: this.systemParameters, custData: this.custData};
    },

    name() {
      return this.value && this.value.name ? this.value.name : null;
    },

    collectionSettingsList: {
        get() {
            return this.response.collectionSettings ? this.response.collectionSettings : [];
        }
    },

    timeWindowSizeSeconds: {
        get(){
            return this.detectionJobTemplate.source.timeWindowSizeSeconds
        },
        set(timeWindowSizeSeconds){
            this.$store.dispatch(MODULE_NAME + "/updateTimeWindowSizeSeconds", timeWindowSizeSeconds)
        }
        
    },
    

    groupBy: {
      get() {
        return get(this.detectionJobTemplate, 'source.groupBy', []); 
      },

      set(value) {
        this.$store.dispatch(MODULE_NAME + "/updateGroupBy", value);
      },
    },

    aggregation: {
      get() {
        let aggregationList = [];
        const aggregations = get(this.detectionJobTemplate, 'source.aggregation', []); 
        // Combine name and operation and add to result list
        for (let agg of aggregations){
            let item = {
                name: "",
                i18n: {}
            };
            let aggName = agg.aggregationOperation + "_" + agg.attributeName;
            set(item, 'name', aggName);
            aggregationList.push(item);
        }
        return aggregationList;
      },

      set(value) {
        let aggregationList = []
        // Divide into name and operation and add to result list
        for(const item of value){
            let toSet = {};
            const lastIndex = item.lastIndexOf("_");
            const aggregationOperation = item.substring(0, lastIndex);
            const attributeName = item.substring(lastIndex + 1);

            set(toSet, 'attributeName', attributeName);
            set(toSet, 'aggregationOperation', aggregationOperation);
            aggregationList.push(toSet);
        }
        this.$store.dispatch(MODULE_NAME + "/updateAggregation", aggregationList);
      },
    },

    partitioningStrategy: {
      get() {
        return get(this.detectionJobTemplate, 'logging.partitioningStrategy', []); 
      },

      set(value) {
        this.$store.dispatch(MODULE_NAME + "/updatePartitioningStrategy", value);
      },
    },

    
    loggingCollection: {
        get(){
            return get(this.detectionJobTemplate, 'logging.collectionId', []); 
        },
        set(loggingCollectionId){
            this.$store.dispatch(MODULE_NAME + "/updateLoggingCollection", loggingCollectionId)
        }
        
    },

    loggingSchema: {
        get(){
            return get(this.detectionJobTemplate, 'logging.schemaId', []); 
        },
        set(loggingSchemaId){
            this.$store.dispatch(MODULE_NAME + "/updateLoggingSchema", loggingSchemaId)
        }
        
    },

    rules: {
      get() {
          return get(this.detectionJobTemplate, 'rules', []);
      },
    },

    schedule: {
      get() {
        return get(this.detectionJobTemplate, 'schedule', []); 
      },

      set(value) {
        this.$store.dispatch(MODULE_NAME + "/updateSchedule", value);
      },
    },

    attributes() {
      const attributes = get(this.schema, "attributes", {});
      const attributeList = [];
      Object.keys(attributes).forEach((key) => {
        attributeList.push(attributes[key]);
      });

      return attributeList;
    },

    measureList(){
      var result = [];
      for (let attribute of this.attributes) {
        if (this.isMeasure(attribute)) {
          result.push(attribute);
        }
      }
      return result;
    },

    dimensionList() {
      var result = [];
      for (let attribute of this.attributes) {
        if (this.isDimension(attribute)) {
          result.push(attribute);
        }
      }
      return result;
    },

    aggregations() {
        let aggs = [];
        for (let measure of this.measureList) {
            for (const operation of OPERATIONS) {
                let item = {
                    name: operation + "_" + measure.name,
                    i18n: {
                        de: {
                            name: this.$t('detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.' + operation, "de", {name: this.$dxs.i18n.localizedName(measure, "de")})
                        },
                        en: {
                            name: this.$t('detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.' + operation, "en", {name: this.$dxs.i18n.localizedName(measure, "en")})
                        }
                    }
                };
                aggs.push(item);
            }
        }

        return aggs;
    },

    custData: {
           
        get() {
            return get(this.detectionJobTemplate, 'customData', {}); 
        },

        set(custData) {
            set(this.detectionJobTemplate, 'customData', custData ? custData : {});
        }
    },

    active: {
        get() {
            return get(this.detectionJobTemplate, 'active', null); 
        },

        set(active) {
            this.$store.dispatch(MODULE_NAME + "/updateActive", active);
        }
    },

    filter: {

        get() {
            return get(this.detectionJobTemplate, 'source.filter', 
                {
                    filterQuery : '*',
                    filterQueryLanguage: 'SIMPLE',
                    facetFilters: [],
                    rangeFilters: []
                }
            ); 
        },

        set(value) {
            this.$store.dispatch(MODULE_NAME + "/updateFilter", value)
        }
    },

    custDataItems() {

        let items = [];
        for (let key in this.custData) {
            items.push({name: key, value: this.custData[key]})
        }
        return items;
    },  

    hasEditRole() {
        return this.userContextService.hasAllRoles(Roles.MAPPING_JOB_TEMPLATES_EDIT);
    },
  },

  created() {

    // Register the module dynamically with the view.
    if (!this.$store.hasModule(MODULE_NAME)) {
        this.$store.registerModule(MODULE_NAME, DetectionJobTemplatesEditorModule);
    }

    this.refresh();
  },

};
</script>

<i18n>
{
    "en" : {
        "detection-job-templates-editor.view.title": "Job Template: {name}",
        "detection-job-templates-editor.view.sections.collections.title": "Source and Target Collections",
        "detection-job-templates-editor.view.sections.collections.sourceCollection": "Source Collection",
        "detection-job-templates-editor.view.sections.collections.identifier": "Technical identifier",
        "detection-job-templates-editor.view.sections.collections.sourceCollection.hint": "You cdetectionan select another source collection",
        "detection-job-templates-editor.view.sections.collections.dateTimeAttribute": "Date-Time-Attribute",
        "detection-job-templates-editor.view.tabs.general.active.description" : "Active",   
        "detection-job-templates-editor.view.sections.filter.title": "Filter",

        "detection-job-templates-editor.view.sections.json.title" : "Job Template: JSON",
        "detection-job-templates-editor.view.sections.json.subtitle" : "Show the detection job template JSON representation.",
        "detection-job-templates-editor.view.sections.json.actions.open"  : "Open JSON",

        "detection-job-templates-editor.view.tabs.general" : "General",
        "detection-job-templates-editor.view.tabs.general.title" : "General Data",
        "detection-job-templates-editor.view.tabs.general.description" : "The following information cannot be changed after creation and is displayed for informational purposes. Please create a new template if you need a different configuration.",   
        
        "detection-job-templates-editor.view.tabs.general.information" : "Information",

        "detection-job-templates-editor.view.tabs.detectionJobSource" : "Source",
        "detection-job-templates-editor.view.tabs.detectionJobSource.title" : "Detection Job Source",
        "detection-job-templates-editor.view.tabs.detectionJobSource.description" : "Detection Job Source",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.title" : "Aggregation",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.description" : "The aggregation(s) to use for each time window",

        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.MIN" : "Minimum({name})",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.MAX" : "Maximum({name})",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.MEDIAN" : "Median({name})",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.AVERAGE" : "Average({name})",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.SUM" : "Sum({name})",

        "detection-job-templates-editor.view.tabs.detectionJobSource.filtering.title" : "Row Filtering",
        "detection-job-templates-editor.view.tabs.detectionJobSource.filtering.description" : "Please define a filter to limit the set of records to be copied from the source to the target. The job copies only records that match the filter expression.", 
        "detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.title" : "Time Window Size (Seconds)",
        "detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.description" : "Please define a time window (in seconds). The job will aggregate all values of the documents fitting in this time window.", 
        "detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.validation.onlyNumbers" :  "Please enter a time window size in seconds. Only positive integers are allowed.", 
        "detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.validation.notInRange" :  "The time window needs to be at least one second long.", 
        "detection-job-templates-editor.view.tabs.detectionJobSource.groupBy.title" : "Group By",
        "detection-job-templates-editor.view.tabs.detectionJobSource.groupBy.description" : "Choose attributes to group documents by. If none are selected, a single group with all documents (fitting the filter criteria) will be formed.",  

        "detection-job-templates-editor.view.tabs.detectionJobLogging" : "Logging",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.title" : "Detection Job Logging Collection & Schema",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitioningStrategy.title" : "Partitioning Strategy",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitioningStrategy.description" : "The partitioning strategy will be used by the system when creating the logging collection.",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitionByMonth.title" : "Partition By Month",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitionByDay.title" : "Partition By Day",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitionByMonth.subtitle" : "Partition By Month",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitionByDay.subtitle" : "Partition By Day",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.loggingCollection" : "Logging Collection",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.loggingSchema" : "Logging Schema",

        "detection-job-templates-editor.view.tabs.detectionJobSchedule" : "Schedule",

        "detection-job-templates-editor.view.tabs.detectionJobRules" : "Rules",

        "detection-job-templates-editor.view.tabs.custData" : "Custom Parameters",
        "detection-job-templates-editor.component.sections.custData.title" : "Custom Parameters",
        "detection-job-templates-editor.component.sections.custData.subtitle" : "You can define additional parameters for the purpose of customizing. Please note: These parameters will not be evaluated from the application and need to be processed by means of customer specific code.",
        
        "detection-job-templates-editor.view.tabs.json" : "JSON",

        "detection-job-templates-editor.view.warnings.notActive" : "This Detection Job Template is currently not active. It will not produce any alerts."
    },

    "de" : {
        "detection-job-templates-editor.view.title": "Job Vorlage: {name}",
        "detection-job-templates-editor.view.sections.collections.title": "Quell- und Zieldatenbereiche",
        "detection-job-templates-editor.view.sections.collections.identifier": "Technischer Bezeichner",
        "detection-job-templates-editor.view.sections.collections.sourceCollection": "Quelldatenbereich",
        "detection-job-templates-editor.view.sections.collections.sourceCollection.hint": "Sie können einen anderen Quelldatenbereich auswählen",
        "detection-job-templates-editor.view.sections.collections.dateTimeAttribute": "Zeitstempel-Attribut",
        "detection-job-templates-editor.view.tabs.general.active.description" : "Aktiv",   
        "detection-job-templates-editor.view.sections.filter.title": "Filter",


        "detection-job-templates-editor.view.sections.json.title" : "Job Template: JSON",
        "detection-job-templates-editor.view.sections.json.subtitle" : "Zeigen Sie das detection job template als JSON an.",
        "detection-job-templates-editor.view.sections.json.actions.open"  : "JSON Öffnen",

        "detection-job-templates-editor.view.tabs.general" : "Allgemein",
        "detection-job-templates-editor.view.tabs.general.title" : "Allgemeine Daten",
        "detection-job-templates-editor.view.tabs.general.description" : "Die nachfolgenden Informationen können nach der Anlage nicht mehr verändert werden und werden zu Informationszwecken angezeigt. Bitte legen Sie ein neues Template an, wenn Sie eine andere Konfiguration benötigen.",

        "detection-job-templates-editor.view.tabs.general.information" : "Information",

        "detection-job-templates-editor.view.tabs.detectionJobSource" : "Quelle",
        "detection-job-templates-editor.view.tabs.detectionJobSource.title" : "Detection Job Quelle",
        "detection-job-templates-editor.view.tabs.detectionJobSource.description" : "Detection Job Quelle",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.title" : "Aggregation",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.description" : "Aggreagtion(en) die für jedes Zeitfenster genutzt werden sollen.",
        
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.MIN" : "Minimum({name})",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.MAX" : "Maximum({name})",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.MEDIAN" : "Median({name})",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.AVERAGE" : "Durchschnitt({name})",
        "detection-job-templates-editor.view.tabs.detectionJobSource.aggregation.SUM" : "Summe({name})",
        
        "detection-job-templates-editor.view.tabs.detectionJobSource.filtering.title" : "Zeilenfilterung",
        "detection-job-templates-editor.view.tabs.detectionJobSource.filtering.description" : "Bitte definieren Sie einen Filter, um die Menge der Datensätze einzuschränken, die von der Quelle ins Ziel kopiert werden sollen. Der Job kopiert nur Datensätze, die dem Filterausdruck entsprechen.",
        "detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.title" : "Zeitfenster Größe (Sekunden)",
        "detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.description" : "Bitte definieren Sie ein Zeitfenster in Sekunden. Der Job aggregiert alle Werte der Dokumente die in das Zeitfenster passen.",                                                                                                                                                                                                                       
        "detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.validation.onlyNumbers" :  "Bitte geben die ein Zeitfenster in Sekunden ein. Nur positive Ganzzahlen sind erlaubt.", 
        "detection-job-templates-editor.view.tabs.detectionJobSource.timeWindowSizeSeconds.validation.notInRange" :  "Das Zeitfenster muss mindestens eine Sekunde lang sein.", 
        "detection-job-templates-editor.view.tabs.detectionJobSource.groupBy.title" : "Gruppierung",
        "detection-job-templates-editor.view.tabs.detectionJobSource.groupBy.description" : "Wählen Sie die Attribute wonach Dokumente gruppiert werden sollen. Falls keine Attribute gewählt werden, wird eine einzige Gruppe mit allen Dokumenten (welche in das Filterkriterium passen) erstellt.",  
        
        "detection-job-templates-editor.view.tabs.detectionJobLogging" : "Logging",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.title" : "Detection Job Logging Datenbereich & Schema",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitioningStrategy.title" : "Partitionsstrategie",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitioningStrategy.description" : "Die Partitionsstrategie wird verwendet beim Erstellen des Logging-Datenbereichs.",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitionByMonth.title" : "Nach Monat partitionieren",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitionByDay.title" : "Nach Tag partitionieren",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitionByMonth.subtitle" : "Nach Monat partitionieren",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.partitionByDay.subtitle" : "Nach Tag partitionieren",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.loggingCollection" : "Logging Datenbereich",
        "detection-job-templates-editor.view.tabs.detectionJobLogging.loggingSchema" : "Logging Schema",

        "detection-job-templates-editor.view.tabs.detectionJobSchedule" : "Zeitplan",

        "detection-job-templates-editor.view.tabs.detectionJobRules" : "Regeln",

        "detection-job-templates-editor.view.tabs.custData" : "Kundenspezifische Parameter",
        "detection-job-templates-editor.component.sections.custData.title" : "Kundenspezifische Parameter",
        "detection-job-templates-editor.component.sections.custData.subtitle" : "Sie können an dieser Stelle kundenspezifische Parameter für das Customizing hinterlegen. Bitte beachten Sie: Diese Parameter werden durch das Standardsystem nicht ausgewertet. Hierfür ist zusätzlicher kundenspezifischer Code notwendig.",
    
        "detection-job-templates-editor.view.tabs.json" : "JSON",

        "detection-job-templates-editor.view.warnings.notActive" : "Diese Detection Job Vorlage ist aktuell nicht aktiviert und wird somit keine Warnungen ausgeben."
    }
}
</i18n>