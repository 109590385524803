<template>
  <v-card tile flat>
      <v-card-text>
          <div class="text-h6">{{$t('logging-config-page.component.title')}}</div>
          <div>{{$t('logging-config-page.component.subtitle')}}</div>
      </v-card-text>
      <v-divider />

      <!-- AUDIT LOG -->
      <v-card-text>
        <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('logging-config-page.component.sections.documentChangeLogging.title')}}</div>
        <div>{{$t('logging-config-page.component.sections.documentChangeLogging.subtitle')}}</div>
        <div class="grey--text">{{$t('logging-config-page.component.sections.documentChangeLogging.fields')}}</div>
    </v-card-text>
        <v-card-text v-if="documentChangeLogging === 'ON'">
        <dxs-warning-message>
        {{$t('logging-config-page.component.sections.documentChangeLogging.ON.warning')}}
        </dxs-warning-message>
    </v-card-text>
    <v-list>
         <v-radio-group mandatory v-model="documentChangeLogging" :disabled="disabled">
            <v-list-item three-line>
                <v-list-item-action>
                    <v-radio key="ON" value="ON" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{$t('logging-config-page.component.sections.documentChangeLogging.ON.title')}}</v-list-item-title>
                    <v-list-item-subtitle>{{$t('logging-config-page.component.sections.documentChangeLogging.ON.subtitle')}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item three-line>
                <v-list-item-action>
                    <v-radio key="OFF" value="OFF" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{$t('logging-config-page.component.sections.documentChangeLogging.OFF.title')}}</v-list-item-title>
                    <v-list-item-subtitle>{{$t('logging-config-page.component.sections.documentChangeLogging.OFF.subtitle')}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>        
         </v-radio-group>
    </v-list>
    

    <v-divider />
    
    <!-- QUERY LOG -->
    <v-card-text>
        <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('logging-config-page.component.sections.queryLogging.title')}}</div>
        <div>{{$t('logging-config-page.component.sections.queryLogging.subtitle')}}</div>
         <div class="grey--text">{{$t('logging-config-page.component.sections.queryLogging.fields')}}</div>
    </v-card-text>
    <v-list>
        <v-radio-group mandatory v-model="queryLogging" :disabled="disabled">
        <v-list-item three-line> 
            
            <v-list-item-action>
                <v-radio key="ON" value="ON" />
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{$t('logging-config-page.component.sections.queryLogging.ON.title')}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('logging-config-page.component.sections.queryLogging.ON.subtitle')}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <v-list-item three-line>
            <v-list-item-action>
                <v-radio key="OFF" value="OFF" />
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{$t('logging-config-page.component.sections.queryLogging.OFF.title')}}</v-list-item-title>
                <v-list-item-subtitle>{{$t('logging-config-page.component.sections.queryLogging.OFF.subtitle')}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        </v-radio-group>
    </v-list>
  </v-card>
</template>

<script>
import { get, set, cloneDeep } from 'lodash';
import WarningMessageComponent from '@/components/messages/warning-message.component';

export default {

    name: 'logging-config-page',

    props: {

        value: {
            type: Object,
            required: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    components: {
        'dxs-warning-message' : WarningMessageComponent
    }, 

    data() {

        return {
            model : cloneDeep(this.value)
        }
    },

    methods: {

        fireInput() {
            this.$emit('input', cloneDeep(this.model));
        }

    },

    computed: {
        queryLogging: {

            get() {
                return get(this.model, 'queryLogging', 'ON')
            },

            set(value) {
                set(this.model, 'queryLogging', value)
                this.fireInput();
            }
        },

        documentChangeLogging: {

            get() {
                return get(this.model, 'documentChangeLogging', 'ON')
            },

            set(value) {
                set(this.model, 'documentChangeLogging', value)
                this.fireInput();
            }
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "logging-config-page.component.title" : "Logging",
        "logging-config-page.component.subtitle" : "This page contains configuration for system activity logging.",
        "logging-config-page.component.sections.documentChangeLogging.title" : "Collection Change Log",
        "logging-config-page.component.sections.documentChangeLogging.subtitle" : "The change log records all changes to documents and makes the changes available chronologically. This setting can be overwritten on the collection level.",
        "logging-config-page.component.sections.documentChangeLogging.fields" : "Recorded Data: Change Date and Time, User ID, Change Type, Collection ID, Document ID, Document Contents (JSON)",
        "logging-config-page.component.sections.documentChangeLogging.ON.title" : "Change Logging On",
        "logging-config-page.component.sections.documentChangeLogging.ON.subtitle" : "The system logs all changes to documents in this tenant.",
         "logging-config-page.component.sections.documentChangeLogging.ON.warning" : "Document change logging requires an significant amount of resources, especially when applied to fast-growing datasets such as timeseries data. We therefore stronlgy recommend to enable document change logging only on a per-collection basis.",
        "logging-config-page.component.sections.documentChangeLogging.OFF.title" : "Change Logging Off (Standard)",
        "logging-config-page.component.sections.documentChangeLogging.OFF.subtitle" : "The system logs no changes to documents in this tenant.",
        "logging-config-page.component.sections.queryLogging.title" : "Collection Access Log",
        "logging-config-page.component.sections.queryLogging.subtitle" : "The access log records all accesses to collections and makes the data available for e.g. performance and usage statistics.",
         "logging-config-page.component.sections.queryLogging.fields" : "Recorded Data: Data and Time, User ID,  Collection ID, Query (JSON), Duration, Hit Count",
        "logging-config-page.component.sections.queryLogging.ON.title" : "Access Logging On",
        "logging-config-page.component.sections.queryLogging.ON.subtitle" : "The system logs all access to collections in this tenant.",
        "logging-config-page.component.sections.queryLogging.OFF.title" : "Access Logging Off",
        "logging-config-page.component.sections.queryLogging.OFF.subtitle" : "The system logs no access to collections in this tenant."
    },

    "de" : {
        "logging-config-page.component.title" : "Protokollierung",
        "logging-config-page.component.subtitle" : "Diese Seite enthält Einstellungen zur Protokollierung der System-Aktivitäten.",
        "logging-config-page.component.sections.documentChangeLogging.title" : "Änderungsprotokoll Datenbereiche",
        "logging-config-page.component.sections.documentChangeLogging.subtitle" : "Das Änderungsprotokoll zeichnet alle Änderungen an Dokumenten auf und stellt die Änderungen chronologisch zur Verfügung. Diese Einstellung kann an auf Ebene des Datenbereichs überschrieben werden.",
        "logging-config-page.component.sections.documentChangeLogging.fields" : "Aufgezeichnete Datenfelder: Zeitpunkt, Benutzerkennung, Art der Änderung, Datenbereich-ID, Dokumenten-ID, Dokumenten-Inhalt (JSON)",
        "logging-config-page.component.sections.documentChangeLogging.ON.title" : "Protokollierung einschalten",
        "logging-config-page.component.sections.documentChangeLogging.ON.subtitle" : "Das System protokolliert alle Änderungen an Dokumenten in diesem Mandanten.",
         "logging-config-page.component.sections.documentChangeLogging.ON.warning" : "Die Änderungsprotokollierung erfordert eine signifikante Menge an Ressourcen, die insbesondere bei schnell wachsenden Datenmengen wie z.B. Zeitreihen, hohe Kosten verursachen können. Wir empfehlen deshalb nachdrücklich das Änderungsprotokoll nicht für den gesamten Mandaten zu aktivieren. Aktivieren Sie die Änderungsprotokollierung bei Bedarf stattdessen nur auf ausgewählten Datenbereichen.",
        "logging-config-page.component.sections.documentChangeLogging.OFF.title" : "Protokollierung ausschalten (Standard)",
        "logging-config-page.component.sections.documentChangeLogging.OFF.subtitle" : "Das System protokolliert keine Änderungen an Dokumenten in diesem Mandanten.",
        "logging-config-page.component.sections.queryLogging.title" : "Zugriffsprotokoll Datenbereiche",
        "logging-config-page.component.sections.queryLogging.subtitle" : "Das Zugriffsprotokoll zeichnet alle Zugriffe auf Datenbereiche auf und stellt die Änderungen z.B. für Performance - und Nutzungsstatistiken zur Verfügung.",
        "logging-config-page.component.sections.queryLogging.fields" : "Aufgezeichnete Datenfelder: Zeitpunkt, Benutzerkennung, Datenbereich-ID, Abfrage (JSON), Ausführungsdauer, Anzahl der Treffer",
        "logging-config-page.component.sections.queryLogging.ON.title" : "Protokollierung einschalten",
        "logging-config-page.component.sections.queryLogging.ON.subtitle" : "Das System protokolliert alle Zugriffe auf Datenbereiche in diesem Mandanten.",
        "logging-config-page.component.sections.queryLogging.OFF.title" : "Protokollierung ausschalten",
        "logging-config-page.component.sections.queryLogging.OFF.subtitle" : "Das System protokolliert keine Zugriffe auf Datenbereiche in diesem Mandanten."
    }
}
</i18n>