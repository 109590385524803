import Vue from 'vue';
import Vuex from 'vuex';

import CollectionsModule from '@/store/modules/collections.module';


import DocumentDetailModule from '@/store/modules/document-detail.module';
import DocumentChangeLogModule from './modules/document-change-log.module';


import PredictionScenarioListModule from '@/store/modules/prediction/prediction-scenario-list.module';
import PredictionScenarioWizardModule from '@/store/modules/prediction/prediction-scenario-wizard.module';
import PredictionScenarioDetailModule from '@/store/modules/prediction/prediction-scenario-detail.module';
import PredictionModelWizardModule from '@/store/modules/prediction/prediction-model-wizard.module';
import NotificationDetailModule from './modules/notification-detail.module';
import ApplicationShellModule from './modules/application-shell.module';

import CoreModules from '@/core/store/index';
import CustomModules from '@/cust/store/index';


Vue.use(Vuex)

const modules = {

  collections: CollectionsModule,
  notificationDetail: NotificationDetailModule,
  applicationShell: ApplicationShellModule,

  documentDetail: DocumentDetailModule,
  documentChangeLog: DocumentChangeLogModule,




  // ==============================================================
  // Catalog
  // ============================================================== 



  predictionScenarioList: PredictionScenarioListModule,
  predictionScenarioWizard: PredictionScenarioWizardModule,
  predictionScenarioDetail: PredictionScenarioDetailModule,
  predictionModelWizard: PredictionModelWizardModule,
};

// ====================================================================
// Register CORE Modules
// ====================================================================
for (let moduleKey in CoreModules) {

  // Add the modules which are defined in the core index.js
  modules[moduleKey] = CoreModules[moduleKey];
}

// ====================================================================
// Register CUST Modules
// ====================================================================
for (let moduleKey in CustomModules) {

  // Add the modules which are defined in the core index.js
  modules[moduleKey] = CustomModules[moduleKey];
}

// Publish the consolidated modules. 
export default new Vuex.Store({

  modules: modules
})
