import api from '@/store/api';
import { set } from 'lodash';
import { get } from 'lodash';
import { cloneDeep } from 'lodash';

export default {

    namespaced: true,

    state : {

        collectionPermission: getDefaultCollectionPermission(),
        collectionSettings: {},
        schema: {}
    },

    mutations: {

        setResponse(state, response) {

            if (response && response.data) {
                state.collectionPermission = response.data.collectionPermission;
                state.collectionSettings   = response.data.collectionSettings;
                state.schema               = response.data.schema;
            } else {

                let defaultResponse = getDefaultResponse();
                state.collectionPermission = defaultResponse.data.collectionPermission;
                state.collectionSettings   = defaultResponse.data.collectionSettings;
                state.schema               = defaultResponse.data.schema;

            }

        },

        setName(state, name) {
            set(state, 'collectionPermission.name', name);
        },

        setEffectiveFrom(state, effectiveFrom) {
            set(state, 'collectionPermission.effectiveFrom', effectiveFrom);
        },

        setEffectiveUntil(state, effectiveUntil) {
            set(state, 'collectionPermission.effectiveUntil', effectiveUntil);
        },

        setActive(state, active) {
            set(state, 'collectionPermission.active', active);
        },

        setUserIds(state, userIds) {
            set(state, 'collectionPermission.userIds', userIds ? userIds : []);
        },

        setUserGroupIds(state, userGroupIds) {
            set(state, 'collectionPermission.userGroupIds', userGroupIds ? userGroupIds : []);
        },

    },

    actions: {

        async loadData(context, { name }) {

            try {
                let response = await api.catalog.collectionPermissions.findById(name);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async resetData(context) {
            context.commit('setResponse', getDefaultResponse());
        },

        async updateCollectionPermission(context) {
            
            try {
                let payload = context.getters.collectionPermission;
                let response = await api.catalog.collectionPermissions.update(payload.name, payload);
                context.commit('setResponse', response);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        },

        updateEffectiveFrom(context, effectiveFrom) {
            context.commit('setEffectiveFrom', effectiveFrom);
        },

        updateEffectiveUntil(context, effectiveUntil) {
            context.commit('setEffectiveUntil', effectiveUntil);
        },

        updateActive(context, active) {

            context.commit('setActive', active);
        },

        addUserId(context, userId) {

            // Make you to deep clone the array before modifying it.
            let userIds = cloneDeep(get(context.state, 'collectionPermission.userIds', []));
            userIds.push(userId);

            context.commit('setUserIds', userIds);
        },

        removeUserId(context, userId) {

            // Make you to deep clone the array before modifying it.
            let userIds = cloneDeep(get(context.state, 'collectionPermission.userIds', []));
            userIds = userIds.filter(e => e !== userId);

            context.commit('setUserIds', userIds);
        },

        addUserGroupId(context, userGroupId) {

            // Make you to deep clone the array before modifying it.
            let userGroupIds = cloneDeep(get(context.state, 'collectionPermission.userGroupIds', []));
            userGroupIds.push(userGroupId);

            context.commit('setUserGroupIds', userGroupIds);
        },

        removeUserGroupId(context, userGroupId) {

            // Make you to deep clone the array before modifying it.
            let userGroupIds = cloneDeep(get(context.state, 'collectionPermission.userGroupIds', []));
            userGroupIds = userGroupIds.filter(e => e !== userGroupId);

            context.commit('setUserGroupIds', userGroupIds);
        }

    },

    getters: {

        collectionPermission(state) {

            let payload = state.collectionPermission;
            return payload ? payload : getDefaultCollectionPermission();
        },

        response(state) {
            return {
                data : {
                    collectionPermission: state.collectionPermission ? state.collectionPermission : getDefaultCollectionPermission(),
                    collectionSettings: state.collectionSettings ? state.collectionSettings : {},
                    schema: state.schema ? state.schema : {}
                }
            };
        },

    }
}


function getDefaultCollectionPermission() {
    
    return {
        name: null,
        collectionId: null,
        active: true,
        effectiveFrom: null,
        effectiveUntil: null,
        userGroupIds: [],
        userIds: [],
        i18n: {}
    }
} 

function getDefaultResponse() {
    
    return {
        data: {
            collectionPermission:  getDefaultCollectionPermission(),
            collectionSettings: {},
            schema: {}
        }
    };
}



