<template>
    <v-card flat tile :color="color" class="pt-5 pb-5">
        <v-card-text>
            <div class="text-h6 text-center">{{ title }}</div>
        </v-card-text>
        <v-card-text>
            <slot>
                <!-- Add content -->
            </slot>
        </v-card-text>
    </v-card>
</template>

<script>
export default {

    props: {

        title : {
            type: String,
            required: true
        },

        color: {
            type: String,
            required: false,
            default: 'grey lighten-3'
        }
    }
}
</script>