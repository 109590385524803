<template>
  <v-card tile>
    <v-card-text>
      <div class="text-h6" v-if="getGroupSize > 0" :title="name">{{ name  | truncate-middle($vuetify.breakpoint.smAndDown ? 16 : 28)}}</div>
      <div class="text-h6" v-else>{{ $t("collection-metrics.view.group.singleGroup.label") }}</div>
      <div class="text-body">
        {{ allElements | integer-format($i18n.locale) }} {{$t("collection-metrics.view.group.elements.number.label")}}
      </div>
      <div>
        {{$t("collection-metrics.view.group.chart.description", {numberOfDocuments, allElements})}}
      </div>
    </v-card-text>
    <c-metrics-time-series-chart 
        :value="chart" 
        legend="bottom" 
        class="pb-5" 
        height="300px" 
        fallbackChartType="line" 
        :showValueAxis="false" 
        :scaleValueAxis="false" 
        :key="chartKey"
        />
        
    <v-divider />

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              {{ $t("collection-metrics.view.group.metric.label") }}
            </th>
            <th class="text-right pr-15">
              {{ $t("collection-metrics.view.group.value.label") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, key) in cardAggregations"
            :key="key"
          >
            <td class="text-center">{{ $t(key) }}</td>
            <td class="text-right pr-15">{{ value | decimal-format($i18n.locale)}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-divider />
    <v-card-actions>
        <slot name="collection-group-tile-actions" />
    </v-card-actions>
  </v-card>
</template>
<script>
const MODULE_NAME = "collectionMetrics";
import { get, pick } from 'lodash';
import { Charts } from '@/core/components/collections/metric-charts.lib.js';
export default {
  props: {
    value: {
      type: Object,
      required: true,
      validator: (v) => v.group && v.request
    },
  },
  data() {
    return {
        dialog: false,
        chartKey: 0
    };
  },
  computed: {
    request(){
      return get(this.value, "request", {});
    },
    lastRequest(){
      return this.$store.getters[MODULE_NAME + "/lastRequest"];
    },
    chart(){
      this.forceRerender()
      return Charts.chartmodel(this.cardAggregations, this.documents, this.lastRequest);
    },
    name(){
      return get(this.value, "group.name", "Name not found");
    },
    documents(){
      return get(this.value, "group.documents", []);
    },
    numberOfDocuments(){
      return this.documents.length;
    },
    allElements(){
      return get(this.aggregations, "count", " ");
    },
    aggregations(){
      return get(this.value, "group.metrics", []);
    },
    cardAggregations(){
      let toContain = ["average", "min", "max"];
      let result = pick(this.aggregations, toContain);
      return result;
    },
    timeDimension(){
      return get(this.request, "timeDimensionAttribute", {});
    },
    getGroupSize(){
      return get(this.request, "groupAttributes", []).length;
    }  
  },
  methods: {
    forceRerender(){
      // Changes chartKey attribute to force rerender the chart component by :key="chartKey"
      // When missing to so, the chart won´t resize correctly and the visualization looks crappy
      this.chartKey += 1;
    }
  }
};
</script>

<i18n>
    {
        "en" : {
            "collection-metrics.view.group.elements.number.label": "Elements",
            "collection-metrics.view.group.metric.label": "Metric",
            "collection-metrics.view.group.value.label": "Value",
            "collection-metrics.view.group.singleGroup.label": "All Elements",
            "collection-metrics.view.group.chart.description": "Displayed: {numberOfDocuments} values",
            "average": "Average",
            "min": "Minimum",
            "max": "Maximum"
            
        },
        "de" : {
            "collection-metrics.view.group.elements.number.label": "Elemente",
            "collection-metrics.view.group.metric.label": "Metrik",
            "collection-metrics.view.group.value.label": "Wert",
            "collection-metrics.view.group.singleGroup.label": "Alle Elemente",
            "collection-metrics.view.group.chart.description": "Angezeigt: {numberOfDocuments} Werte",
            "average": "Durchschnitt",
            "min": "Minimum",
            "max": "Maximum"
            
        }
    }
</i18n>