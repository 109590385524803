<template>
    <v-sheet color="grey lighten-3">
        <c-sticky-content>
            <v-toolbar color="secondary darken-1" flat >
                
                <v-breadcrumbs>
                    <v-breadcrumbs-item>Mapping Jobs</v-breadcrumbs-item>
                    <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                    <v-breadcrumbs-item disabled>Dashboard</v-breadcrumbs-item>
                </v-breadcrumbs>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon><v-icon @click="refresh">refresh</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
        </c-sticky-content>
        <c-section :title="$t('mapping-job-instance-admin.view.title')" :subtitle="$t('mapping-job-instance-admin.view.subtitle')" class="secondary">
            <v-container fluid>
                <v-row justify="center">
                    <v-col cols="12">
                        <v-card tile>
                            <v-toolbar flat>
                                <v-toolbar-title>{{$t('mapping-job-instance-admin.view.mappingJobs.title')}}</v-toolbar-title>
                                <v-spacer />
                                <v-toolbar-items>
                                    <v-btn icon @click="add"><v-icon>add</v-icon></v-btn>                                    
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-card-text>
                                {{$t('mapping-job-instance-admin.view.cards.jobs.subtitle')}}
                            </v-card-text>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th width="100px">{{$t('mapping-job-instance-admin.view.mappingJobs.table.cols.start')}}</th>
                                        <th width="100px">{{$t('mapping-job-instance-admin.view.mappingJobs.table.cols.template')}}</th>
                                        <th>{{$t('mapping-job-instance-admin.view.mappingJobs.table.cols.id')}}</th>
                                        <th>{{$t('mapping-job-instance-admin.view.mappingJobs.table.cols.name')}}</th>
                                        <th>{{$t('mapping-job-instance-admin.view.mappingJobs.table.cols.source')}}</th>
                                        <th>{{$t('mapping-job-instance-admin.view.mappingJobs.table.cols.target')}}</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) of mappingJobTemplates" :key="'mappingJob_tr_' + index">
                                        <td><v-btn icon small @click="showConfirmationDialog(item)"><v-icon>play_circle_filled</v-icon></v-btn></td>
                                        <td><v-btn icon @click="openJobTemplate(item.name)"><v-icon small>open_in_new</v-icon></v-btn></td>
                                        <td>{{item.name}}</td>
                                        <td>{{item | localized-name($i18n.locale)}}</td>
                                        <td>{{item.sourceCollectionId}}</td>
                                        <td>{{item.targetCollectionId}}</td>
                                        
                                    </tr>
                                </tbody>
                            </v-simple-table>

                        </v-card>
                    </v-col>
                    <v-col cols="12">
                       <v-card tile>
                           <v-toolbar flat>
                               <v-toolbar-title >{{$t('mapping-job-instance-admin.view.mappingJobInstances.title', {count: mappingJobInstanceItems.length, totalItemCount : mappingJobInstanceTotalCount })}}</v-toolbar-title>
                                <v-spacer />
                                <v-toolbar-items>
                                    <v-btn icon @click="openToMappingJobInstanceList"><v-icon>open_in_new</v-icon></v-btn>
                                </v-toolbar-items>
                           </v-toolbar>
                           <v-simple-table>
                               <thead>

                                   <tr>
                                       <th>&nbsp;</th>
                                       <th>{{$t('mapping-job-instance-admin-dashboard.view.executions.cols.jobStartDateTime')}}</th>
                                       <th>{{$t('mapping-job-instance-admin-dashboard.view.executions.cols.jobStartedByUserId')}}</th>
                                       <th>{{$t('mapping-job-instance-admin-dashboard.view.executions.cols.jobInstanceId')}}</th>
                                       <th>{{$t('mapping-job-instance-admin-dashboard.view.executions.cols.jobTemplateId')}}</th>
                                       <th class="text-right">{{$t('mapping-job-instance-admin-dashboard.view.executions.cols.documentCount')}}</th>
                                       <th>{{$t('mapping-job-instance-admin-dashboard.view.executions.cols.sourceCollection')}}</th>
                                       <th>{{$t('mapping-job-instance-admin-dashboard.view.executions.cols.targetCollection')}}</th>
                                   </tr>
                               </thead>
                               <tbody>
                                   <tr v-for="(item, index) of mappingJobInstanceItems" :key="'tr_mappingJobInstance_' + index">
                                       <td><v-btn icon @click="openJobInstanceId(item.data.jobInstanceId)"><v-icon small>open_in_new</v-icon></v-btn></td>
                                       <td>{{item.data.jobStartDateTime | localized-date-time-format($i18n.locale)}}</td>
                                       <td>{{item.data.jobStartedByUserId}}</td>
                                       <td :title="item.data.jobInstanceId">{{item.data.jobInstanceId | truncate-middle(25)}}</td>
                                       <td :title="item.data.jobTemplateId">{{item.data.jobTemplateId | truncate-middle(25)}}</td>
                                       <td class="text-right">{{item.data.documentCount | integer-format($i18n.locale)}}</td>
                                       <td :title="item.data.sourceCollection">{{item.data.sourceCollection | truncate-middle(25)}}</td>
                                       <td :title="item.data.targetCollection">{{item.data.targetCollection | truncate-middle(25)}}</td>
                                   </tr>
                               </tbody>
                           </v-simple-table>
                       </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </c-section>

        <v-dialog v-model="displayConfirmationDialog" width="420" >
            <v-card tile>
                <v-toolbar flat>
                   <v-toolbar-title>{{$t('mapping-job-instance-admin.view.confirmationDialog.title', {name : selectedTemplate ? selectedTemplate.name : null})}}</v-toolbar-title>
                   <v-spacer />
                   <v-toolbar-items>
                       <v-btn icon @click="onCancel"><v-icon>close</v-icon></v-btn>
                    </v-toolbar-items> 
                </v-toolbar>
                <v-card-text class="pa-5">
                    {{$t('mapping-job-instance-admin.view.confirmationDialog.text')}}
                </v-card-text>
                <v-card-actions class="text-right pa-5">
                    <v-btn text class="accent" @click="onConfirm">{{$t('mapping-job-instance-admin.view.confirmationDialog.actions.confirm')}}</v-btn>
                    <v-btn text @click="onCancel">{{$t('mapping-job-instance-admin.view.confirmationDialog.actions.cancel')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-sheet>
</template>

<script>
import { get } from 'lodash';
const MODULE_NAME = 'mappingJobInstanceAdminDashboard';

export default {

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    data: () => ({
        selectedTemplate: null,
        displayConfirmationDialog : false
    }),

    computed: {
        model() {
            return this.$store.getters[MODULE_NAME + '/model'];
        },

        mappingJobTemplates() {
            return get(this.model, 'mappingJobTemplates.items', []);
        },

        mappingJobInstances() {
            return get(this.model, 'mappingJobInstances');
        },

        mappingJobInstanceItems() {
            return get(this.mappingJobInstances, 'items', []);
        },

        mappingJobInstanceTotalCount() {
            return get(this.mappingJobInstances, 'pagination.totalItemCount', 0);
        }
    },

    methods: {

        async startJob(jobTemplateId) {
            
            this.progressIndicatorService.show();
            try {
                const jobInstanceId = await this.$store.dispatch(MODULE_NAME + "/startJob", {jobTemplateId: jobTemplateId});

                // In case everything went as expected, navigate forward to the  job status dashboard. 
                this.$router.push({
                    name: 'mappingJobInstanceDetails',
                    params: { name: jobInstanceId} 
                });

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }

            

        },

        async refresh() {
            
            this.progressIndicatorService.show();
            
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadData");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        openToMappingJobInstanceList() {
            this.$router.push({name: 'mappingJobInstances'});
        },

        openJobTemplate(jobTemplateId) {
            this.$router.push({
                name: 'mappingJobTemplatesEditor',
                params: {
                    name: jobTemplateId
                }
            });
        },

        openJobInstanceId(jobInstanceId) {
            this.$router.push({
                 name: 'mappingJobInstanceDetails',
                params: { name: jobInstanceId} 
            });
        },

        add() {
            this.$router.push({name: 'mappingJobTemplatesWizard'});
        },

        showConfirmationDialog(template) {
            this.selectedTemplate = template;
            this.displayConfirmationDialog = true;
        },

        hideConfirmationDialog() {
            this.selectedTemplate = null;
            this.displayConfirmationDialog = false;
        },

        onConfirm() {

            this.startJob(this.selectedTemplate.name);
            this.hideConfirmationDialog(); 
        },

        onCancel() {
           this.hideConfirmationDialog(); 
        }
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "mapping-job-instance-admin.view.title" : "Mapping Job Admin Dashboard",
        "mapping-job-instance-admin.view.subtitle" : "This dashboard allows for starting and managing job instances.",

        "mapping-job-instance-admin.view.cards.jobs.subtitle" : "You can start jobs from here. To do this, please click on the icon to run the job. If you need a new job, you must first create a new job template for it.",

        "mapping-job-instance-admin.view.mappingJobs.title" : "Mapping Jobs",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.start" : "Start Job",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.id" : "Technical Job ID",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.name" : "Name",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.source" : "Source Collection",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.target" : "Target Collection",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.template" : "Template",

        "mapping-job-instance-admin.view.mappingJobInstances.title" : "{count} lastest executions from  {totalItemCount} total",
        "mapping-job-instance-admin-dashboard.view.executions.cols.jobStartDateTime" : "Start Date Time",
        "mapping-job-instance-admin-dashboard.view.executions.cols.jobStartedByUserId" : "Started By",
        "mapping-job-instance-admin-dashboard.view.executions.cols.jobInstanceId" : "Job ID",
        "mapping-job-instance-admin-dashboard.view.executions.cols.jobTemplateId" : "Template ID",
        "mapping-job-instance-admin-dashboard.view.executions.cols.documentCount" : "Doc. Count",
        "mapping-job-instance-admin-dashboard.view.executions.cols.sourceCollection" : "Source Collection",
        "mapping-job-instance-admin-dashboard.view.executions.cols.targetCollection" : "Target Collection",

        "mapping-job-instance-admin.view.confirmationDialog.title" : "Start Job {name}?",
        "mapping-job-instance-admin.view.confirmationDialog.text" : "You are about to start a potentially long running job which requires a significant amount of computational resources. This job can't be interrupted once started. Please confirm your action by clicking the button below.",
        "mapping-job-instance-admin.view.confirmationDialog.actions.confirm" : "Start now",
        "mapping-job-instance-admin.view.confirmationDialog.actions.cancel" : "Cancel"
        
    },

    "de" : {
        "mapping-job-instance-admin.view.title" : "Mapping Job Admin Dashboard",
        "mapping-job-instance-admin.view.subtitle" : "Dieses Dashboard ermöglicht das Starten und Verwalten von Job-Instanzen.",

        "mapping-job-instance-admin.view.cards.jobs.subtitle" : "Sie können von hier aus Jobs starten. Bitte klicken Sie hierfür auf das Symbol zum Ausführen des Jobs. Wenn Sie einen neuen Job benötigen, müssen Sie hierfür zuerst eine neue Vorlage anlegen.",

        "mapping-job-instance-admin.view.mappingJobs.title" : "Mapping Jobs",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.id" : "Technische Job ID",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.start" : "Ausführen",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.name" : "Bezeichnung",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.source" : "Quelldatenbereich",
        "mapping-job-instance-admin.view.mappingJobs.table.cols.target" : "Zieldatenbereich",
         "mapping-job-instance-admin.view.mappingJobs.table.cols.template" : "Template",

        "mapping-job-instance-admin.view.mappingJobInstances.title" : "Letzte {count} Läufe von insgesamt {totalItemCount}",
        "mapping-job-instance-admin-dashboard.view.executions.cols.jobStartDateTime" : "Startzeitpunkt",
        "mapping-job-instance-admin-dashboard.view.executions.cols.jobStartedByUserId" : "Gestartet von",
        "mapping-job-instance-admin-dashboard.view.executions.cols.jobInstanceId" : "Prozess-ID",
        "mapping-job-instance-admin-dashboard.view.executions.cols.jobTemplateId" : "Template-ID",
        "mapping-job-instance-admin-dashboard.view.executions.cols.documentCount" : "Anz. Dok.",
        "mapping-job-instance-admin-dashboard.view.executions.cols.sourceCollection" : "Quelldatenbereich",
        "mapping-job-instance-admin-dashboard.view.executions.cols.targetCollection" : "Zieldatenbereich",

        "mapping-job-instance-admin.view.confirmationDialog.title" : "Den Job {name} ausführen?",
        "mapping-job-instance-admin.view.confirmationDialog.text" : "Sie sind im Begriff, einen potenziell lang laufenden Job auszuführen, der eine beträchtliche Menge an Rechenressourcen erfordert. Dieser Auftrag kann nicht unterbrochen werden, sobald er gestartet ist. Bitte bestätigen Sie Ihre Aktion, indem Sie auf die Schaltfläche unten klicken.",

        "mapping-job-instance-admin.view.confirmationDialog.actions.confirm" : "Jetzt ausführen",
        "mapping-job-instance-admin.view.confirmationDialog.actions.cancel" : "Abbrechen"
    }
}
</i18n>