import api from '@/store/api';
import { get } from 'lodash';

export default {

    namespaced: true,

    name: "portal-store-module",

    state : {
        userListTemplates: []
    },

    mutations: {

        setUserListTemplates(state, userListTemplates) {

            state.userListTemplates = userListTemplates ? userListTemplates : [];
        }
        
    },

    actions: {
        
        async loadUserListTemplates(context) {

            try {
                let response = await api.me.listTemplates.findByFilter('*');
                context.commit('setUserListTemplates', response);
            } catch (error) {
                context.commit('setUserListTemplates', []);
                throw error;
            }

        },
    },

    getters: {

        listTemplates(state) {

            let listTemplates = get(state.userListTemplates, 'items', []);
            return listTemplates;
        },
    }
}