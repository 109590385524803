<template>
  <v-sheet>
    <c-section :title="$t('tenant-editor.view.title')" :subtitle="$t('tenant-editor.view.subtitle')" class="secondary"></c-section>
    <v-divider />
    <c-sticky-content>
        <v-toolbar>
            <v-toolbar-title>{{$t('tenant-editor.view.title')}}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
                <v-btn text @click="save">{{$t('tenant-editor.view.actions.save')}}</v-btn>
                <v-btn text>{{$t('tenant-editor.view.actions.cancel')}}</v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-divider />
    </c-sticky-content>

    <v-tabs 
        v-model="selectedTab" 
        :vertical="$vuetify.breakpoint.mdAndUp" 
        color="grey" 
        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" 
        slider-color="grey" 
        show-arrows 
        :fixed-tabs="!$vuetify.breakpoint.mdAndUp">

        <v-tab :key="0">{{$t('tenant-editor.view.tabs.general')}}</v-tab>
        <v-tab :key="1">{{$t('tenant-editor.view.tabs.dataProtection')}}</v-tab>
        <v-tab :key="2">{{$t('tenant-editor.view.tabs.protocol')}}</v-tab>
        <v-tab :key="3">{{$t('tenant-editor.view.tabs.json')}}</v-tab>

        <v-tabs-items v-model="selectedTab">

            <v-tab-item :key="0">
               <c-name-and-i18n-item-editor-page v-model="generalSettings" :disabled="!hasEditRole"/> 
               <!--
                <v-card tile flat>
                    <v-card-text class="text-h5">Allgemein</v-card-text>
                    <v-card-text>
                        Diese Seite beinhaltet die allgemeinen Einstellungen des Mandanten.
                    </v-card-text>
                    <v-divider />
                    <v-text-field disabled filled label="ID" value="amentis" />
                </v-card>       
                -->          

            </v-tab-item>
            
            <v-tab-item :key="1">
                <dxs-data-protection-config-page v-model="dataProtection" :disabled="!hasEditRole" />
            </v-tab-item>

            <v-tab-item :key="2">
                <dxs-logging-config-page v-model="logging" :disabled="!hasEditRole" />
            </v-tab-item>

            <v-tab-item :key="3">
                <c-json-item-editor-page v-model="response" />
            </v-tab-item>

        </v-tabs-items>

    </v-tabs>
  </v-sheet>

</template>

<script>
import DataProtectionConfigPageComponent from '@/core/components/catalog/tenant/data-protection-config-page.component.vue';
import LoggingConfigPageComponent from '@/core/components/catalog/tenant/logging-config-page.component.vue';

import Roles from '@/roles';

import { get } from 'lodash';

const MODULE_NAME = 'tenantEditor';

export default {

    data: () => ({
        selectedTab: 0,

        collectionAccessOption: 'ON'
    }),

    
    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    components: {

        'dxs-data-protection-config-page' : DataProtectionConfigPageComponent,
        'dxs-logging-config-page' : LoggingConfigPageComponent
    }, 

    methods: {

        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData');
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + '/saveData');
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        }
    },

    computed: {

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        data() {
            return get(this.response, 'data', {});
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.TENANT_EDIT);
        },

        hasReadRole() {
            return this.userContextService.hasRole(Roles.TENANT_READ);
        },

        generalSettings: {
            
            get() {
                const generalSettings = {
                    name: get(this.data, 'name'),
                    i18n: get(this.data, 'i18n', {})
                }

                return generalSettings;
            },

            set(value) {

                this.$store.dispatch(MODULE_NAME + '/updateGeneralSettings', value);
            }
            
        },

        dataProtection: {
           get() { 
               return get(this.data, 'dataProtection', {}) 
           },

           set(value) {
                this.$store.dispatch(MODULE_NAME + '/updateDataProtection', value);
           }
        },

        logging: {
            get() { 
               return get(this.data, 'logging', {}) 
           },

           set(value) {
                this.$store.dispatch(MODULE_NAME + '/updateLogging', value);
           }
        }
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : { 
        "tenant-editor.view.title" : "Tenant",
        "tenant-editor.view.subtitle" : "This dialog contains all settings of this tenant",
        "tenant-editor.view.actions.save" : "Save",
        "tenant-editor.view.actions.cancel" : "Cancel",
        
        "tenant-editor.view.tabs.general" : "General",
        "tenant-editor.view.tabs.dataProtection" : "Data Protection",
        "tenant-editor.view.tabs.protocol" : "Protocols",
        "tenant-editor.view.tabs.json" : "JSON" 

    },

    "de" : {
        "tenant-editor.view.title" : "Mandant",
        "tenant-editor.view.subtitle" : "Sie finden nachfolgend alle Einstellungen Ihres Mandanten",
        "tenant-editor.view.actions.save" : "Speichern",
        "tenant-editor.view.actions.cancel" : "Abbrechen",

        "tenant-editor.view.tabs.general" : "Allgemein",
        "tenant-editor.view.tabs.dataProtection" : "Datensicherheit",
        "tenant-editor.view.tabs.protocol" : "Protokollierung",
        "tenant-editor.view.tabs.json" : "JSON" 
    }
}
</i18n>