<template>

    <v-card tile>
            <v-card-text class="text-h6">{{$t(value.group)}}</v-card-text>
            <slot></slot>
            <v-simple-table>
            <template>
            <thead>
                <tr>
                <th class="text-left">
                    {{$t("collection-metrics-tiles.component.metric.label")}}
                </th>
                <th class="text-right">
                    {{$t("collection-metrics-tiles.component.value.label")}}
                </th>
                </tr> 
            </thead>
            <tbody>
                <tr
                v-for="(value, key) in value.aggs" 
                :key="'metric_card_' + key"
                >
                <td class="text-left">
                    {{$t(key)}}
                </td>
                <td class="text-right">
                    {{value | decimal-format($i18n.locale)}} 
                </td>

                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>


</template>


<script>
// import { get, pick } from 'lodash';
export default {
  props: {
    value:{
      type: Object,
      required: true
    }
  },
  data() {
    return {
        
    };
  },

  computed: {
    
  
  },
  methods: {
   
  }
};
</script>

<i18n>
    {
        "en" : {
            "collection-metrics-tiles.component.metric.label": "Metric",
            "collection-metrics-tiles.component.value.label": "Value",
            "collection-metrics-tiles.component.common.label": "Common",
            "collection-metrics-tiles.component.quartiles.label": "Quartiles",
            "25.0": "First Quartile",
            "50.0": "Second Quartile",
            "75.0": "Third Quartile",
            "count": "Count",
            "min": "Minimum",
            "max": "Maximum",
            "average": "Average",
            "sum": "Sum",
            "sum_of_squares": "Sum of Squares",
            "variance": "Variance",
            "variance_population": "Variance Popoulation",
            "variance_sampling": "Variance Sampling",
            "std_deviation": "Standard Deviation",
            "std_deviation_population": "Std Deviation Population",
            "std_deviation_sampling": "Std Deviation Sampling",
            "std_deviation_bounds_upper": "Std Deviation Bounds Upper",
            "std_deviation_bounds_lower": "Std Deviation Bounds Lower",
            "std_deviation_bounds_upper_population": "Std Deviation Bounds Upper Population",
            "std_deviation_bounds_lower_population": "Std Deviation Bounds Lower Population",
            "std_deviation_bounds_upper_sampling": "Std Deviation Bounds Upper Sampling",
            "std_deviation_bounds_lower_sampling": "Std Deviation Bounds Lower Sampling"
        },
        "de" : {
          "collection-metrics-tiles.component.metric.label": "Metrik",
          "collection-metrics-tiles.component.value.label": "Wert",
          "collection-metrics-tiles.component.common.label": "Allgemein",
          "collection-metrics-tiles.component.quartiles.label": "Quartile",
            "25.0": "Erstes Quartil",
            "50.0": "Zweites Quartil",
            "75.0": "Drittes Quartil",
            "count": "Anzahl",
            "min": "Minimum",
            "max": "Maximum",
            "average": "Durchschnitt",
            "sum": "Summe",
            "sum_of_squares": "Quadratsumme",
            "variance": "Varianz",
            "variance_population": "Varianz (Popoulation)",
            "variance_sampling": "Varianz (Stichprobe)",
            "std_deviation": "Standardabweichung",
            "std_deviation_population": "Standardabweichung (Population)",
            "std_deviation_sampling": "Standardabweichung (Stichprobe)",
            "std_deviation_bounds_upper": "Standardabweichung Obergrenze",
            "std_deviation_bounds_lower": "Standardabweichung Untergrenze",
            "std_deviation_bounds_upper_population": "Standardabweichung Obergrenze Population",
            "std_deviation_bounds_lower_population": "Standardabweichung Untergrenze Population",
            "std_deviation_bounds_upper_sampling": "Standardabweichung Obergrenze Sampling",
            "std_deviation_bounds_lower_sampling": "StandardabweichungUntergrenze Sampling"
        }
    }
</i18n>

