<template>
    <v-text-field
        v-model="ruleExpression"
        :label="fieldLabel"
        :hint="hint"
        :persisten-hint="persistenHint"
        :filled="filled"
        color="accent"
        :rules="[ e => !!e || $t('detection-rules-expression-input.component.validation.notEmpty')]"
        />
</template>

<script>

export default {

    name: 'detection-rules-expression-input',

    props: {
        value: {
            type: String,
            required: false,
            default: null
        },

        label: {
            type: String, 
            required: false,
        },

        hint : {
            type: String, 
            required: false,
        },

        persistenHint: {
            type: Boolean,
            required: false,
            default: false
        },

        filled: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {

        return {
            internalRuleExpression: this.value
        };
    },

    methods: {

        fireInput() {
            this.$emit('input', this.internalRuleExpression);
        }
    },

    

    computed: {

        ruleExpression: {

            get() {
                return this.internalRuleExpression;
            },

            set(value) {
                this.internalRuleExpression = value;
                this.fireInput();
            }
        },

        fieldLabel() {
            return this.label ? this.label : this.$t('detection-rules-expression-input.component.label')
        },
    }

}
</script>

<i18n>
{
    "en" : {
        "detection-rules-expression-input.component.label" : "Rule Expression",
        "detection-rules-expression-input.component.validation.notEmpty" : "The rule expression must not be empty. Please enter a valid expression."
    },

    "de" : {
        "detection-rules-expression-input.component.label" : "Regelausdruck",
        "detection-rules-expression-input.component.validation.notEmpty" : "Der Regelausdruck darf nicht leer sein. Bitte geben Sie einen gültigen Ausdruck ein."
    }
}
</i18n>