<template>
  <v-card class="warning" tile flat>
      <v-card-text>
          <strong>{{$t('warning-message.component.title')}}</strong>
      </v-card-text>
      <v-card-text>
        <slot />
      </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<i18n>
{
    "en" : {

        "warning-message.component.title" : "Warning"
    },

    "de" : {
        "warning-message.component.title" : "Warnung"
    }
}
</i18n>