
<template>

    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12">
                <c-metrics-chart 
                    :items="relativeValues ? relativeChartItems : chartItems" 
                    :dimensions="chartSeries" 
                    stack-data 
                    show-value-label 
                    :show-category-axis="false"
                    value-formatter="0,0,0.00"
                    horizontal
                    :background-color="backgroundColor"
                    height="300px"
                />
            </v-col>
        </v-row>
        <v-row justify="center">
            
            <v-col cols="12" md="3" xl="2" v-for="(item, index) of items" :key="'item_col_' + index">

                <div class="text-center" :style="'color:' + item.color ">
                    <span class="text-h6" :title="$dxs.i18n.localizedName(item, $i18n.locale)"> {{$dxs.formatting.truncateMiddle($dxs.i18n.localizedName(item, $i18n.locale), 32)}}</span>
                    <br />
                    <span class="text-h3">{{$dxs.formatting.formatInteger(item.value, $i18n.locale)}}</span>
                    <br />
                    {{$dxs.formatting.formatDecimal($dxs.rounding.round($dxs.math.percentOf(item.value, itemSum), 2), $i18n.locale)}}% {{$t("data-distribution-bar.component.item.of")}} {{$dxs.formatting.formatInteger(itemSum, $i18n.locale)}}
                </div>

            </v-col>
            
        </v-row>

    </v-container>

</template>

<script>
import { get } from 'lodash';
import { isEmpty } from 'lodash';

/**
 * This component will render the distribution in a single, segmented bar. 
 * It is particularly well suited for giving a quick overview with using only a minimum of space.
 */
export default {

    props: {
        
        items : {
            type: Array,
            required: true,
            default: () => []
        },

        backgroundColor: {
            type: String,
            required: false,
            default: 'white'
        },

        relativeValues: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    computed: {

        /**
         * Returns an array of items without zero values: These values represent slots from a busines perspective
         * but cause problems with the UI components and make no sense from the point of visualization.
         */
        filteredItems() {
            return this.items.filter(e => e.value > 0);
        },

        itemSum() {

            let sum = 0;
            for (const item of this.items) {
                sum = sum + item.value;
            }
            return sum;
        },
        
        chartItems() {

            const chartItem = {
                name: 'collectionOverview',
                
                i18n: {
                    de: { name: 'Gesamtübersicht (Absolute Verteilung)'},
                    en: { name: 'Overview (Absolute Values)'},
                },
                values: {}
            };

            if (isEmpty(this.filteredItems)) {
                chartItem.values.empty = 0;
            } else {
                for (const item of this.filteredItems) {
                    chartItem.values[item.name] = item.value;
                }
            }

            return [chartItem];
        },

        

        relativeChartItems() {

            const chartItem = {
                name: 'collectionOverview',
                
                i18n: {
                    de: { name: 'Gesamtübersicht (Prozentuale Verteilung)'},
                    en: { name: 'Overview (Percentage Values)'},
                },
                values: {}
            };

            if (isEmpty(this.filteredItems)) {
                chartItem.values.empty = 0;
            } else {
                for (const item of this.filteredItems) {
                    chartItem.values[item.name] =  this.$dxs.rounding.round(this.$dxs.math.percentOf(item.value, this.itemSum), 2);
                }
            }

           

            return [chartItem];
        },

        chartSeries() {

            const chartSeries = [];
            if (isEmpty(this.filteredItems)) {
                chartSeries.push({
                    name: 'empty',
                    i18n: {},
                    color: 'grey'
                })
            } else {
                for (const item of this.filteredItems) {
                    chartSeries.push({
                        name: item.name,
                        i18n: get(item, 'i18n', {}),
                        color: get(item, 'color', 'grey')
                    });
                }
            }
            
            return chartSeries;
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "data-distribution-bar.component.item.of": "of"
    },

    "de" : {
        "data-distribution-bar.component.item.of": "von"
    }
}
</i18n>