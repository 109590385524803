import api from '@/store/api';
import apiV2 from '@/core/api/v2.api';

const DeadLetterCollectionId = "DeadLetterDocuments";

export default {

    namespaced: true,

    state: {
        response: getDefaultResponse(),
        reingestResponse: null,
    },

    mutations: {
        setResponse(state, response) {
            state.response = response;
        },
        setReingestResponse(state, response) {
            state.reingestResponse = response;
        }
    },
    actions: {
        async loadResponse(context, documentId) {
            try {
                let response = await api.collections.documents.findById(DeadLetterCollectionId, documentId);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }
        },

        async reingest(context, { collectionId, documentRecord }) {
            try {
                let response = await apiV2.collections.documents.create(collectionId, documentRecord);
                context.commit('setReingestResponse', response);
            } catch (error) {
                console.log("An error occurred while uploading the edited dead letter " + error);
                throw error;
            }
        }
    },
    getters: {
        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },
        reingestResponse(state) {
            return state.reingestResponse ? state.reingestResponse : null
        }
    }
}
function getDefaultResponse() {
    return {
        result: null
    };
}
