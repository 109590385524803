<template>
    
    <v-sheet class="secondary darken-3" >
        
        <c-sticky-content>
                <v-toolbar color="secondary darken-3" flat>
                    <v-toolbar-title class="white--text" v-if="!headerArea.visible">{{ batteryPassportId }}</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-btn icon @click="refresh" color="white"><v-icon>refresh</v-icon></v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider v-if="!headerArea.visible"/>
        </c-sticky-content>

        <v-sheet class="secondary darken-3" v-intersect="onIntersectHeaderArea">
            
            <div class="text-h6 white--text text-center pt-5">
                Battery Passport
            </div>
            <div class="text-h5 secondary--text text-center pb-5">
                {{ batteryPassportId }}
            </div>
        </v-sheet>
<!--
        <v-img src="@/assets/img/header-application.svg" />
-->    
        
        

        <v-container class="secondary darken-3 mt-5 mb-5" fluid>
            <v-row>
                <v-col cols="12" md="6" lg="3">
                    <v-card color="secondary darken-4" flat>
                        <v-card-text>
                            <div class="text-strong white--text">Weight</div>
                            <div class="text-h6 white--text">349 kg</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                    <v-card color="secondary darken-4" flat>    
                        <v-card-text>
                            <div class="text-strong white--text">Carbon Footprint</div>
                            <div class="text-h6 white--text">96.2 kg CO₂e / kWh</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                    <v-card color="secondary darken-4" flat>
                        <v-card-text>
                            <div class="text-strong white--text">Manufacturer</div>
                            <div class="text-h6 white--text">Acme Batteries Corp.</div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" lg="3">
                    <v-card color="secondary darken-4" flat>
                        <v-card-text>
                            <div class="text-strong white--text">Battery Chemistry</div>
                            <div class="text-h6 white--text">NCM333</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-card flat tile>
            <v-tabs 
                v-model="navigation.selectedTab"
                centered 
                color="accent" 
                background-color="secondary darken-3"
                dark
                :show-arrows="$vuetify.breakpoint.smAndDown"
                >
        

                <v-tab>{{$t('battery-passport-dashboard.view.navigation.general.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.composition.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.carbonFootprint.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.circularity.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.supplyChain.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.compliance.title')}}</v-tab>
                <v-tab>{{$t('battery-passport-dashboard.view.navigation.performance.title')}}</v-tab>

                <v-tabs-items v-model="navigation.selectedTab">


                    <v-tab-item>
                        <v-container fluid class="grey lighten-3 pt-5">
                            <v-row>
                                <v-col lg="4" cols="12">
                                    <v-card>
                                        <v-card-text>
                                            <div class="text-h6 secondary--text">Identifikation</div>
                                        </v-card-text>
                                        <v-subheader>Modell-Nummer</v-subheader>
                                        <v-card-text>M-41698615</v-card-text>


                                        <v-subheader>Serien-Nummer</v-subheader>
                                        <v-card-text>992356610548948</v-card-text>

                                    </v-card>
                                </v-col>
                                <v-col lg="8" cols="12">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-card>
                                                    <v-card-text>
                                                        <div class="text-h6 grey--text">Status</div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-card>
                                                    <v-card-text>
                                                        <div class="text-h6 grey--text">Typ</div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="6">
                                                <v-card>
                                                    <v-card-text>
                                                        <div class="text-h6 grey--text">Hersteller</div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-card>
                                                    <v-card-text>
                                                        <div class="text-h6 grey--text">Werk</div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-container>

                                    
                                </v-col>
                            </v-row>
                        </v-container>
                       
                    </v-tab-item>

                    <v-tab-item>
                        <dxs-battery-material-composition-page :value="batteryMaterialCompositionPageModel" />
                    </v-tab-item>
                    
                    <v-tab-item>
                        <dxs-carbon-footprint-page :value="{}" />
                    </v-tab-item>
                    
                    <v-tab-item>
                        <dxs-battery-circularity-page :value="batteryCircularityPageModel" />
                    </v-tab-item>
                    
                    <v-tab-item>
                        <dxs-supply-chain-page :value="supplyChainPageModel" />
                    </v-tab-item>
                    
                    <v-tab-item>
                        <dxs-battery-compliance-page :value="batteryCompliancePageModel" />
                    </v-tab-item>
                    
                    <v-tab-item></v-tab-item>

                </v-tabs-items> 
            </v-tabs>
        </v-card>
                 



    </v-sheet>
</template>

<script>

import BatteryMaterialCompositionPage from './pages/battery-material-composition.page.vue';
import BatteryCircularityPage from './pages/battery-circularity.page.vue';
import CarbonFootprintPage from './pages/carbon-footprint.page.vue';
import SupplyChainPage from './pages/supply-chain.page.vue';
import BatteryCompliancePage from './pages/battery-compliance.page.vue';

import { get } from 'lodash';

export default {

    name: 'battery-passport-dashboard',

    data() {

        return {

            headerArea: {
                visible: true
            },

            navigation: {
                selectedTab: 0
            }
        }
    },

    components: {
        'dxs-battery-material-composition-page' : BatteryMaterialCompositionPage,
        'dxs-battery-circularity-page' : BatteryCircularityPage,
        'dxs-carbon-footprint-page' : CarbonFootprintPage,
        'dxs-supply-chain-page' : SupplyChainPage,
        'dxs-battery-compliance-page' : BatteryCompliancePage
    },

    methods: {
        onIntersectHeaderArea(entries) {
            // More information about these options
            // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
            this.headerArea.visible = entries[0].isIntersecting;
        }
    },

    computed: {

        model() {

            return { 
                battery: {

                    data: {
                        batteryPassportId: 'fbd5ddcb-452f-4e5a-9dd2-23f0bad898d4'
                    }
                }, 
                
                materialComposition:[
                    { material: { name: 'Ni', data: { color : '#005155' } }, share: 4.90 },
                    { material: { name: 'Pb', data: { color : '#599099' } }, share: 6.74 },
                    { material: { name: 'Co', data: { color : '#7fb1bd' } }, share: 1.3 },
                    { material: { name: 'Li', data: { color : '#a5d3e3' } }, share: 4.9 }
                ],

                batteryModel: {

                    conformity: {
                        links: {
                            euDeclarationOfConformance: {
                                name: 'euDeclarationOfConformance',
                                i18n: { en: { name: 'EU Declaration of Conformance'}},
                                url: 'https://eu.gov/batteries/declarationConformance'
                            }

                        }
                    }
                    
                },
                
                safetyRequirements: {
                    
                    name: 'general',
                    
                    // See https://vanguard-fire.com/what-are-the-5-different-classes-of-fires/
                    fireExtinguisherClass: {
                        name: 'FlammableGases', 
                        i18n: {
                            en: { name: 'Flammable Gases', description: 'This class involves flammable gases, e.g. The best way to deal with these types of fires is by smothering them or removing oxygen using foam or CO2 fire suppression equipment.' },
                        },

                        data:{
                            
                            class: {
                                EU: 'C',

                                US: 'B',

                                AU: 'B'
                            },

                            extinguishingAgents: [
                                {
                                    name: 'DryChemicalPowder' 
                                }, 
                                {
                                    name: 'CarbonDioxide'
                                }
                            ],
                            
                            link: 'https://en.wikipedia.org/wiki/Class_B_fire'
                        }


                    }
                    
                },

                endOfLife: {

                    endUserWastePreventionInfo: {
                        
                        name: 'endUserWastePreventionInfo',
                        i18n: {
                            en: {
                                name: 'Role of end-users in contributing to waste prevention'
                            }
                        },
                        url: 'https://battery-waste.info'
                    },

                    endUserWasteCollectionInfo: {
                        
                        name: 'endUserWasteCollectionInfo',
                        i18n: {
                            en: {
                                name: 'Role of end-users in contributing to the separate collection of waste batteries'
                            }
                        },
                        url: 'https://battery-waste.info'
                    },

                    generalInfo: {
                        
                        name: 'generalInfo',
                        i18n: {
                            en: {
                                name: 'Information on separate collection, take back, collection points and preparing for re-use, preparing for repurposing and recycling operations'
                            }
                        },
                        url: 'https://battery-waste.info'
                    }


                    
                },

                substances: [

                    { 
                        substance: { 
                            name: 'Ni', 
                            i18n: { de: { name: 'Nickel'} } 
                        },
                        
                        batterySubstanceQuantity: {
                            totalQuantityMilligram: 8000,
                            preConsumerRecyclingShare: 17.0,
                            postConsumerRecyclingShare: 7.0
                        }
                    },
                    { 
                        substance: { 
                            name: 'Co', 
                            i18n: { de: { name: 'Kobalt'} } 
                        },
                        
                        batterySubstanceQuantity: {
                            totalQuantityMilligram: 8000,
                            preConsumerRecyclingShare: 10.0,
                            postConsumerRecyclingShare: 9.0
                        }
                    },
                    { 
                        substance: { 
                            name: 'Li', 
                            i18n: { de: { name: 'Lithium'} } 
                        },
                        
                        batterySubstanceQuantity: {
                            totalQuantityMilligram: 6000,
                            preConsumerRecyclingShare: 14.0,
                            postConsumerRecyclingShare: 10.0
                        }
                    },
                    { 
                        substance: { 
                            name: 'Pb', 
                            i18n: { de: { name: 'Blei'} } 
                        },
                        
                        batterySubstanceQuantity: {
                            totalQuantityMilligram: 12000,
                            preConsumerRecyclingShare: 11.0,
                            postConsumerRecyclingShare: 6.0
                        }
                    }

                ]
            }   
        },

        batteryPassportId() {
            return get(this.model, 'battery.data.batteryPassportId', null);
        },  

        batteryMaterialCompositionPageModel() {
            return {
                battery: this.model.battery,

                materialComposition: this.model.materialComposition
            }
        },

        batteryCircularityPageModel() {

            return {
                battery: this.model.battery,

                substances: this.model.substances,
                
                safetyRequirements: this.model.safetyRequirements,

                endOfLife: this.model.endOfLife
            }
        },

        supplyChainPageModel() {

            return {

                dueDiligenceReport: {

                    name: 'DueDiligenceReport',
                    i18n: {
                        en: { name: 'Due Diligence Report'}
                    },
                    url: 'Due Diligence Report',
                },

                additionalVoluntary: [
                    
                    { 
                        name: 'ThirdPartySupplyChainAssurances',
                        i18n: { en: { name: 'Third party supply chain assurances'} },
                        url: 'http://api.cloud.amentis-solutions.com/files/3rdparty.pdf'
                    },
                    { 
                        name: 'EUTaxonomyDisclosureStmt',
                        i18n: { en: { name: 'EU Taxonomy disclosure statement'} },
                        url: 'http://api.cloud.amentis-solutions.com/files/eutaxonomydisclosurestmt.pdf'
                    },
                    { 
                        name: 'SustainabilityReport',
                        i18n: { en: { name: 'Sustainability Report'} },
                        url: 'http://api.cloud.amentis-solutions.com/files/sustainability.pdf'
                    }
                
                ]
            }
        },

        batteryCompliancePageModel() {
            
            return {
                conformity: this.model.batteryModel.conformity
                
            }
        }

    },



}
</script>

<i18n>
{
    "en" : {
        "battery-passport-dashboard.view.navigation.general.title" : "General",
        "battery-passport-dashboard.view.navigation.composition.title" : "Material Composition",
        "battery-passport-dashboard.view.navigation.carbonFootprint.title" : "Carbon Footprint",
        "battery-passport-dashboard.view.navigation.circularity.title" : "Circularity",
        "battery-passport-dashboard.view.navigation.supplyChain.title" : "Supply Chain",
        "battery-passport-dashboard.view.navigation.compliance.title" : "Compliance",
        "battery-passport-dashboard.view.navigation.performance.title" : "Performance"
    }
}
</i18n>