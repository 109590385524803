import api from '@/store/api';
import { get,  find, isEmpty, isEqual } from 'lodash';

export default {

    namespaced: true,
    
    state : {
        collectionSettings: [],
        schemas: [],
        

        timeSeries: []
    },

    mutations: {

        setCollectionSettings(state, collectionSettings) {
            state.collectionSettings = !isEmpty(collectionSettings) ? collectionSettings : [];
        },

        setSchemas(state, schemas) {
            state.schemas = !isEmpty(schemas) ? schemas : [];
        },

        setTimeSeries(state, timeSeries) {
            state.timeSeries = !isEmpty(timeSeries) ? timeSeries : [];
        },

        addTimeSeriesData(state, timeSeries) {

            console.log('+++ ', JSON.stringify(timeSeries));

            if (!isEmpty(timeSeries)) {
                // Time Series is always identified by a key.
                const key = {
                    collectionId: timeSeries.query.collectionId,
                    measureId: timeSeries.query.measureId,
                    dateTimeDimensionId: timeSeries.query.dateTimeDimensionId,
                    filterQuery: timeSeries.query.filter.filterQuery
                };

                // If a value for the given key already exists, append the values.
                let existing = state.timeSeries.find(e => isEqual(e.key, key));
                if (existing) {
                    for (const item of timeSeries.items) {
                        existing.items.push(item);
                    }
                } else {

                    // Otherwise create a new series item.
                    state.timeSeries.push({key: key, items: timeSeries.items})
                }
            }
            
        }
    },

    actions: {

        async loadMasterdata(context) {

            try {
                // Use the 'collections' endpoint in order to have the backend apply data permissions.
                const collectionSettings = await api.collections.findAllByFilter('*');
                context.commit('setCollectionSettings', get(collectionSettings, 'items', []));

                const schemas = await api.catalog.schemas.findAll('*');
                context.commit('setSchemas', get(schemas, 'items', []));
            
            } catch (error) {

                context.commit('setCollectionSettings', []);
                context.commit('setSchemas', []);

                Promise.reject(error);
            }
        },

        async resetTimeSeries(context) {
            context.commit('setTimeSeries', [])
        },

        async loadTimeSeries(context, {fromDate, untilDate, timeSeriesItem}) {

            // Build the request object from the given contextual parameters
            const request = {
                collectionId: timeSeriesItem.collectionId,
                measureId: timeSeriesItem.measureId,
                dateTimeDimensionId: timeSeriesItem.timeDimensionId,

                filter: {
                    filterQuery: '*',
                    filterQueryLanguage: 'LUCENE',
                    facetFilters: [],
                    rangeFilters: [{ 
                        type: 'TEMPORAL', 
                        name: timeSeriesItem.timeDimensionId, 
                        from: fromDate, 
                        until: untilDate 
                    }]
                },

                // Load at most 1k data points per query.
                pagination: {
                    from: 0,
                    size: 1000,
                    searchAfter: []
                },

                // 50 Seconds until query timeout occurs
                queryTimeoutMiliseconds: 50 * 1000,
            };


            try {

                const response = await api.collections.timeSeries2.get(timeSeriesItem.collectionId, request);


                context.commit('addTimeSeriesData', get(response, 'data.timeSeries', {}));

            } catch (error) {

                Promise.reject(error);
            }
        }
    },

    getters: {

        collections(state) {
            
            const collectionSettings = state.collectionSettings;
            const schemas = state.schemas;

            const items = [];

            for (const collectionSetting of collectionSettings) {
                items.push({

                    collectionSettings : collectionSetting,
                    schema: find(schemas, e => e.name === collectionSetting.schemaId)
                });
            }

            return items;
        }, 

        model(state, getters) {
            

            return {
                collections: getters.collections
            }
        },

        /**
         * Computes the chart model based on the actual time series information.
         * 
         * @param {*} state 
         */
        chartModel(state, getters) {


            const timeSeries = get(state, 'timeSeries', []);
  
            const chartModel = {
                items: [],
                series: {}
            };

            const itemMap = {};

            for (const t of timeSeries ) {

                // Add a new series to the chart model
                const series = {
                    name: t.key.collectionId + '/' + t.key.measureId + '/' + t.key.filterQuery,
                    i18n: { }
                };

                // Compute localization
                const collection = findCollection(getters.collections, t.key.collectionId);

                for (const locale of ['en', 'de', 'zh']) {
                    console.log("Jalla " + locale)
                    series.i18n[locale] = computeI18N(collection, t.key, locale);
                }

                chartModel.series[series.name] = series;

                // Merge the items from the time series into a temporary map.
                // We use the full timestamp in order to distinguish entries.
                for (const item of t.items) {


                    if (!itemMap[item.dateTime]) {
                        itemMap[item.dateTime] = { dateTime: item.dateTime, values: {} }
                    }
                    
                    itemMap[item.dateTime].values[series.name] = item.value;
                    //console.log(">>>itemMap" + item.dateTime)
                }
            }

            // Push the merged items into the chart model
            for (const key in itemMap) {
                chartModel.items.push(itemMap[key]);
            }

            // Sort the items in the chart model
            chartModel.items.sort((first, second) => first.dateTime.localeCompare(second.dateTime));

            return chartModel;
        }
    }
}

function findCollection(collections, collectionId) {

    return collections.find(e => e.collectionSettings.collectionId === collectionId);
}

function computeI18N(collection, key, locale) {

    let name = get(collection.collectionSettings.i18n, locale + '.name', collection.collectionSettings.collectionId)
             + '/'
             + get(collection.schema.attributes, key.measureId + '.i18n.' + locale + '.name', key.measureId)
             + ' (' + key.filterQuery + ')';

    return {
        languageTag: locale,
        name: name
    };
}
