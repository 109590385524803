<template>

    <v-container fluid class="secondary pt-10 pb-10">
        <v-row justify="center">
            <v-col cols="12" lg="9">
                <c-base-dialog 
                    :value="accessControlList"
                    :title="$t('access-control-lists-wizard.view.title')" 
                    :subtitle="$t('access-control-lists-wizard.view.subtitle')"
                    color="secondary"
                    @dialog-saved="save"
                    @dialog-canceled="cancel"
                    >
                    <template v-slot:default="props"> 
                        <v-sheet class="ma-5">
                            <c-name-input 
                                v-model="props.item.name"
                                filled 
                            />
                        </v-sheet>
                    </template>
                </c-base-dialog>
            </v-col>
        </v-row>

    </v-container>

</template>

<script>
import AccessControlListsWizardModule from '@/core/store/modules/user-mgmt/access-control/access-control-lists-wizard.module';

const MODULE_NAME = "accessControlListsWizard";

export default {

    name: 'access-control-lists-wizard',

    inject: ['errorHandlerService', 'progressIndicatorService'],

    methods: {
        async save() {
            
            this.progressIndicatorService.show();
           
            try {
                
                await this.$store.dispatch(MODULE_NAME + "/createAccessControlList");
                
                this.$router.push( { name: 'accessControlListsEditor', params: {name: this.accessControlList.name}  });

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push( {
                name: 'accessControlListsList'
            });
        },

        async refresh() {
            this.progressIndicatorService.show();
           
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadData");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        reset() {
            this.$store.dispatch(MODULE_NAME + "/resetAccessControlList");
        }
    },

    computed: {



        accessControlList: {

            get() {
               return this.$store.getters[MODULE_NAME + '/accessControlList'];
            }, 

            set(item) {
                
                this.$store
                    .dispatch(MODULE_NAME + "/updateAccessControlList", item)
                    .catch(this.errorHandlerService.handleError);
            }
        },
    },

    created() {

        if (!this.$store.hasModule(MODULE_NAME)) {
            this.$store.registerModule(MODULE_NAME, AccessControlListsWizardModule);
        }

        this.reset();
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "access-control-lists-wizard.view.title" : "Create New Access Control List",
        "access-control-lists-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new access control list."
    },

    "de" : {
        "access-control-lists-wizard.view.title" : "Neue Zugriffsberechtigung anlegen",
        "access-control-lists-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte zur Anlage einer neuen Zugriffsberechtigung."
    }
}
</i18n>




<style>

</style>