<!-- *********************************************************************************** -->
<!-- An expandable panel which includes an editor area for time series.                  -->
<!-- *********************************************************************************** -->
<template>
    <v-card :flat="flat" tile>
        <v-toolbar flat>
            <div class="text-body-2"><v-icon class="pr-3">timeline</v-icon> {{$t('time-series-panel.component.title')}} ({{timeSeriesItems.length}}  )</div>
            <v-spacer />
            <v-toolbar-items>
                <v-btn icon @click="showTimeSeriesItemPane = !showTimeSeriesItemPane">
                    <v-icon v-if="!showTimeSeriesItemPane">arrow_drop_down</v-icon>
                    <v-icon v-else>arrow_drop_up</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-divider v-show="showTimeSeriesItemPane" />
        <dxs-time-series-item-editor v-show="showTimeSeriesItemPane" v-model="computedItems" :collectionData="collectionData" ref="timeSeriesItemEditor" />

    </v-card> 
</template>

<script>
import { cloneDeep } from 'lodash';
import TimeSeriesItemEditorComponent from './time-series-item-editor.component.vue';

export default {

    name: 'time-series-panel',

    props: {

        items: {
            type: Array,
            required: true,
            default: () => []
        },

        /**
         * [{
         *      collectionSettings: { ... },
         *      schema: { ... }
         * }]
         */
        collectionData: {
            type: Array,
            required: true,
            default: () => []
        },

        flat: {
            type: Boolean,
            required: false,
            default: false
        },

        filled: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    model: {
        prop: 'items',
        event: 'input'
    },


    components: {
        'dxs-time-series-item-editor' : TimeSeriesItemEditorComponent
    },

    data() {

        return {
            timeSeriesItems: cloneDeep(this.items),
            showTimeSeriesItemPane: false
        };
    },

    methods: {

        /**
         * Will open the "Add new Item" dialog.
         */
        addNewItem() {
            console.log('>>>', this.$refs)
            this.$refs.timeSeriesItemEditor.addNewItem();
        },

    },

    computed: {

        computedItems: {

            get() {
                return this.timeSeriesItems;
            },

            set(items) {
                this.timeSeriesItems = items;
                this.$emit('input', cloneDeep(this.timeSeriesItems));
            }
        },
    }
}
</script>

<i18n>
{
    "en" : {
        "time-series-panel.component.title" : "Time Series"
    },

    "de" : {
        "time-series-panel.component.title" : "Zeitreihen"
    }
}
</i18n>