<template>
    <v-list-item :to="path">
        <v-list-item-action>
            <v-icon>{{navigation.icon}}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title :title="navigation | localized-name($i18n.locale)">{{navigation | localized-name($i18n.locale)}}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>


export default {

    props: {

        route: {
            type: Object,
            required: true
        }
    },



    computed: {

        path() {
            return this.buildPath(this.route); 
        },

        meta() {
            return this.route && this.route.meta ? this.route.meta : {};
        },

        navigation() {
            return this.meta.navigation ? this.meta.navigation : {}; 
        },

        authorization() {
            return this.meta.authorization ? this.meta.authorization : {}; 
        }
    },

    methods: {

        /**
         * Routes can be nested, i.e. parts of the path are actually located at their parent route.
         * Therefore this method is required in order to construct the actual path in a hierarchical way.
         * @param {*} route The route to be used. 
         */
        buildPath(route) {
            
            if (!route) return null;

            // This is the actual path.
            let path = route.path ? route.path : null;

            // In case we are dealing with a nested route, we need to add the parent's path, too.
            if (route.parent) {

                const parentPath = this.buildPath(route.parent);
                if (parentPath) {
                    path = parentPath + path;
                }
            }

            this.$log.debug(`Found path for route: ${route.name ? route.name : '?' } : ${path}`);
            return path; 
        }
    }
}
</script>

<style>

</style>