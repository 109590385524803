<template>
    <div>
        <v-card tile>
            <v-card-text class="accent white--text">
                <div class="text-h6 text-center">{{metricDefinition | localized-name($i18n.locale)}}</div>
                 <div class="text-caption text-center">{{metricFrom }} - {{metricUntil }}</div>
                <div class="text-center mt-2">
                    <span class="text-h3">{{metric.value}}</span>
                    &nbsp; 
                    <span class="text-h6">kWh</span>
                </div>
            </v-card-text>
            <v-divider />

            <c-metrics-line-chart :items="metricTimeSeries" :legend="false"  class="pb-5"/>

            <!--
            <v-card-toolbar>
               <v-toolbar-items></v-toolbar-items>
            </v-card-toolbar>
            <v-card-text>
                <div class="text-h5 text-left">{{metricDefinition | localized-name($i18n.locale)}}</div>
                <div class="text-body-2 text-left">   {{metricDefinition.name}} | {{ metric.totalItemCount | integer-format($i18n.locale) }} Dokumente </div>
            </v-card-text>
            <v-card-text>
                <div class="text-h3 text-right">{{metric.value}}</div>
                <div class="text-body-2 text-right">{{metric.from }} - {{metric.until }}</div>
            </v-card-text>
            -->
            
            <v-divider />
            <!--
            <v-card-text >
                
               <div class="text-caption text-left">{{metricDefinition | localized-description($i18n.locale)}} </div>
        
            </v-card-text>
            <v-card-text />
            -->
            
            
            <v-divider />
            <v-card-actions>
                <v-btn icon @click="showInfoDialog = true"><v-icon>fullscreen</v-icon></v-btn>
            </v-card-actions>
        

        </v-card>

        <v-dialog v-model="showInfoDialog" fullscreen>
            <v-card tile>
                <v-toolbar flat>
                    <v-toolbar-title>{{metricDefinition | localized-name($i18n.locale)}}</v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items>
                        <v-btn icon @click="showInfoDialog = false"><v-icon>close</v-icon></v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-subheader>Dokumentation</v-subheader>
                <v-card-text>
                    {{metricDefinition | localized-description($i18n.locale)}}
                </v-card-text>
                <v-divider />
                <v-subheader>Zeitraum</v-subheader>
                <v-card-text>
                    Datenbereich: | Collection: 
                </v-card-text>
                <c-metrics-line-chart :items="metricTimeSeries" :legend="false"  class="pb-5"/>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { get } from 'lodash';

export default {

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data() {

        return {
            showInfoDialog: false,
        }
    },

    computed: {

        metricDefinition() {
            return get(this.value, 'metricDefinition', {});
        },

        metric() {
            return get(this.value, 'metric', { value: 0.0})
        },

        metricFrom() {
            return this.$dxs.formatting.formatDateTime(get(this.metric, 'from'), this.$i18n.locale);
           // return '2020.09.21 00:50';
        },

        metricUntil() {

            return this.$dxs.formatting.formatDateTime(get(this.metric, 'until'), this.$i18n.locale);
            //return '2021.08.21 12:31';
        },

        metricTimeSeries() {
            return [
                {dateTime: '2021-08-02T12:00:00.0+01:00', values: { current: 25.5 } },
                {dateTime: '2021-08-02T12:00:02.0+01:00', values: { current: 26 } },
                {dateTime: '2021-08-02T12:00:04.0+01:00', values: { current: 27.08 } },
                {dateTime: '2021-08-02T12:00:06.0+01:00', values: { current: 45 } },
                {dateTime: '2021-08-02T12:00:10.0+01:00', values: { current: 38.9 } },
                {dateTime: '2021-08-02T12:00:23.0+01:00', values: { current: 31.4 } },
                {dateTime: '2021-08-02T12:00:38.0+01:00', values: { current: 46 } },
                {dateTime: '2021-08-02T12:00:59.0+01:00', values: { current: 42.33  }},
                {dateTime: '2021-08-02T12:01:24.0+01:00', values: { current: 43.109 } },
                {dateTime: '2021-08-02T12:01:28.0+01:00', values: { current: 42 } },
                {dateTime: '2021-08-02T12:01:37.0+01:00', values: { current: 29 } },
            ];
        },

        i18n() {
            return get(this.metricsDefinition, 'i18n', {})
        }
    }
}
</script>

<style>

</style>