<template>
    <v-card tile flat color="white">
        
        <!-- Toolbar -->
        <v-toolbar flat>
            <div class="title">{{title | truncate-middle(titleTruncation)}} ({{paginationEmpty ? totalItemCount : $t('item-list.component.pagination.title', {currentPageFrom, currentPageTo, totalItemCount})}})</div>
            <v-spacer />

            <!-- Small Screen Resolutions -->
            <v-toolbar-items v-if="$vuetify.breakpoint.smAndDown">
                <v-btn v-if="supportsAddItem" icon color="accent"  @click="onAddNewItem" :disabled="disableAddItem"><v-icon>add</v-icon></v-btn>
                <v-btn v-if="supportsRefreshList" icon color="accent"  @click="onRefreshList" :disabled="disableRefreshList"><v-icon>refresh</v-icon></v-btn>
            </v-toolbar-items>

            <v-toolbar-items v-else>
                <v-btn v-if="supportsAddItem" color="accent" text   @click="onAddNewItem" :disabled="disableAddItem" ><v-icon class="pr-2">add</v-icon>{{$t('item-list.component.actions.add')}}</v-btn>
                <v-btn v-if="supportsRefreshList" color="accent" text  @click="onRefreshList" :disabled="disableRefreshList" ><v-icon class="pr-2">refresh</v-icon>{{$t('item-list.component.actions.refresh')}}</v-btn>
            </v-toolbar-items>

            <v-toolbar-items v-if="!paginationEmpty">
                <v-btn @click="onPreviousPage" icon color="accent" :disabled="firstPage"><v-icon>chevron_left</v-icon></v-btn>
                <v-btn @click="onNextPage" icon color="accent" :disabled="lastPage"><v-icon>chevron_right</v-icon></v-btn>
            </v-toolbar-items>
            
        </v-toolbar>
        <v-divider />

        <!-- Searchbar -->
        <v-sheet color="grey lighten-5 pa-5" v-if="supportsFilterItems">
            <v-text-field solo flat background-color="grey lighten-2" v-model="filter" :hide-details="true" :label="$t('item-list.component.filter.label')">
                <template v-slot:prepend-inner>
                    <v-icon v-if="$vuetify.breakpoint.smAndUp">search</v-icon>
                </template>
                <template v-slot:append>
                    <v-btn color="white" small class="align-self-center" :elevation="0"  @click="onFilterApplied">{{ $t($vuetify.breakpoint.xsOnly ? 'item-list.component.actions.applyFilter.xs' : 'item-list.component.actions.applyFilter') }}</v-btn>
                </template>
            </v-text-field>
        </v-sheet>
        <v-divider />

        <!-- Content -->
        
        <template v-if="!itemListEmpty">
            
            <slot name="item-list" v-bind:items="itemList">
                <template v-if="$vuetify.breakpoint.mdAndUp">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th v-if="supportsEditItem" width="5%">&nbsp;</th>
                                <th v-if="showDeleteAction" width="5%">&nbsp;</th>
                                <th v-if="!hideNameColumn">{{$t('item-list.component.columns.name')}}</th>
                                <th v-if="!hideI18nNameColumn">{{$t('item-list.component.columns.i18nName')}}</th>
                                <slot name="item-list-table-head-columns">
                                <th width="25%" v-if="!hideBatterySubstanceColumn">{{$t('item-list.component.columns.substance')}}</th>
                                <th width="19%" v-if="!hideBatteryModelColumn">{{$t('item-list.component.columns.model')}}</th>
                                <!-- Use this slot in order to add additional columns to the table header -->
                                </slot>
                                <th width="15%">{{$t('item-list.component.columns.modificationDateTime')}}</th>
                                <th width="15%">{{$t('item-list.component.columns.modificationUser')}}</th>
                                <th v-if="supportsJsonView" class="text-center">JSON</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item of itemList" :key="'tr_' + item.name">
                                    <td v-if="supportsEditItem"><v-btn icon @click="onEdit(item)" :disabled="disableEditItem"><v-icon small>{{editItemIcon}}</v-icon></v-btn></td>
                                    <td v-if="showDeleteAction"><v-btn icon @click="onDelete(item)" :disabled="disableDeleteItem"><v-icon small>{{deleteItemIcon}}</v-icon></v-btn></td>
                                    <td v-if="!hideNameColumn">{{item.name}}</td>
                                    <td v-if="!hideI18nNameColumn">{{item | localized-name($i18n.locale)}}</td>
                                    <slot name="item-list-table-body-columns" v-bind:item="item" v-bind:index="index">
                                    <td v-if="!hideBatterySubstanceColumn"><button @click="fireRouteSubstance(item)"> {{item.references.batterysubstance }}</button></td>
                                    <td v-if="!hideBatteryModelColumn"><button @click="fireRouteModel(item)"> {{ item.references.batterymodel }}</button></td>
                                    <!-- Use this slot in order to add additional columns to the table header -->
                                    </slot>
                                    <td>{{item.modificationDateTime | localized-date-time-format($i18n.locale)}}</td>
                                    <td>{{item.modificationUser}}</td>
                                    <td v-if="supportsJsonView" class="text-center"><v-btn icon @click="showJsonView(item)" :disabled="disabled"><v-icon small>data_object</v-icon></v-btn></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </template>

                <!-- ******************************************************* -->
                <!-- Alternative Representation for small screen resolutions -->
                <!-- ******************************************************* -->
                <template v-else>
                    <v-list>
                        <template v-for="item of itemList">
                        <slot name="list-item">
                            <v-list-item three-line :key="'listItem_' + item.name">
                                <v-list-item-action>
                                    <v-btn icon @click="onEdit(item)" :disabled="disableEditItem"><v-icon>{{editItemIcon}}</v-icon></v-btn>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <slot name="list-item-title" v-bind:item="item">{{item.references.batterysubstance}}</slot>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <slot name="list-item-subtitle" v-bind:item="item">{{item.references.batterymodel}}</slot>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>{{getModificationSubtitle(item)}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-menu bottom >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon v-bind="attrs" v-on="on"><v-icon>more_vert</v-icon></v-btn>
                                        
                                        </template>

                                        <v-list>
                                            <v-list-item v-if="showDeleteAction" @click="onDelete(item)" :disabled="disableDeleteItem">
                                                <v-list-item-action><v-btn icon ><v-icon>{{deleteItemIcon}}</v-icon></v-btn></v-list-item-action>
                                                <v-list-item-title>{{$t('item-list.component.actions.delete')}}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="supportsJsonView"  @click="showJsonView(item)">
                                                <v-list-item-action><v-btn icon><v-icon>data_object</v-icon></v-btn></v-list-item-action>
                                                <v-list-item-title>{{$t('item-list.component.actions.json')}}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-list-item-action>
                            </v-list-item>
                        </slot>
                        </template>
                    </v-list>
                </template>
            </slot>

            <!-- ******************************************************* -->
            <!-- Pagination                                              -->
            <!-- ******************************************************* -->
            <template v-if="!paginationEmpty">
                <v-divider />

                <v-container fluid class="grey lighten-5 pa-3">
                    <v-row>
                        <v-col cols="6" class="text-body-2 text-left">
                            {{$t('item-list.component.pagination.currentPage', {
                                currentPageFrom,
                                currentPageTo,
                                totalItemCount

                            })}}
                            
                        </v-col>
                        <v-col cols="6" class="text-body-2 text-right">
                            <v-btn @click="onPreviousPage" icon :disabled="firstPage"><v-icon>chevron_left</v-icon></v-btn>
                            <v-btn @click="onNextPage" icon :disabled="lastPage"><v-icon>chevron_right</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-container>

            </template>
        </template>

      <!-- Empty Content -->
        <template v-else>
            <v-container fluid class="grey lighten-5">
                    <v-row justify="center">
                        <v-col cols="10">
                            <div class="subtitle-1 text-center">{{$t('item-list.component.empty.title', $i18n.locale)}}</div>
                            <div class="text-center" v-if="supportsAddItem">{{$t('item-list.component.empty.subtitle', $i18n.locale)}}</div>
                        </v-col>
                    </v-row>
                </v-container>
        </template>
    <v-divider />

        <!-- ============================================================= -->
        <!-- JSON DIALOG  -->
        <!-- ============================================================= -->
        <v-dialog v-model="jsonView" fullscreen>
          <v-card flat tile>
              <v-toolbar flat>
                  <v-toolbar-title> {{jsonViewItem | localized-name($i18n.locale)}}: {{$t('item-list.component.dialog.json.title')}}</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                      <v-btn icon @click="copyJsonToClipboard"><v-icon>content_copy</v-icon></v-btn>
                      <v-btn icon @click="hideJsonView"><v-icon>close</v-icon></v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <v-divider />
              <v-card-text class="grey lighten-3 pa-5">
                  <pre>{{JSON.stringify(jsonViewItem, true, 2)}}</pre>
              </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="showDeleteConfirmationDialog" persistent max-width="480">
            <slot name="delete-dialog" v-bind:item="item" v-bind:onDeleteConfirmed="onDeleteConfirmed" v-bind:onDeleteCanceled="onDeleteCanceled">
                <v-card tile flat>
                    <v-card-text />
                    <v-card-text>
                        <div class="title">{{$t('item-list.component.confirmRemove.title')}}</div>
                    </v-card-text>
                    <v-card-text />
                    <v-card-text>
                        <div class="body">{{$t('item-list.component.confirmRemove.description')}}</div>
                    </v-card-text>
                    <v-card-text />
                    <v-card-actions class="text-right">
                        <v-spacer />
                        <v-btn text color="accent" @click="onDeleteConfirmed">{{$t('item-list.component.confirmRemove.actions.confirm')}}</v-btn>
                        <v-btn text color="grey darken-1" @click="onDeleteCanceled">{{$t('item-list.component.confirmRemove.actions.cancel')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </slot>
        </v-dialog> 
  </v-card>
</template>

<script>
import { isEmpty } from 'lodash';

export default {

    name : 'item-list',

    props: {

        items: {

            type: Array,
            required: true
        },

        pagination: {
            type: Object,
            required: false,
            default: () => ({})
        },

        title: {

            type: String,
            required: true
        },

        editItemIcon : {
            type: String,
            required: false,
            default: 'edit'
        },

        deleteItemIcon : {
            type: String,
            required: false,
            default: 'delete'
        },

        supportsAddItem: {

            type: Boolean,
            required: false,
            default: true,
            deprecated: true
        },

        hideNameColumn: {

            type: Boolean,
            required: false,
            default: false
        },

        hideI18nNameColumn: {

            type: Boolean,
            required: false,
            default: false
        },

        hideBatteryModelColumn: {

            type: Boolean,
            required: false,
            default: false
        },

        hideBatterySubstanceColumn: {

            type: Boolean,
            required: false,
            default: false
        },

        disableAddItem: {
            type: Boolean,
            required: false,
            default: false,
        },

        supportsEditItem: {

            type: Boolean,
            required: false,
            default: true,
            deprecated: true
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        disableEditItem: {
            type: Boolean,
            required: false,
            default: false,
        },

        supportsDeleteItem: {

            type: Boolean,
            required: false,
            default: true,
            deprecated: true
        },

        disableDeleteItem: {
            type: Boolean,
            required: false,
            default: false,
        },

        supportsFilterItems: {

            type: Boolean,
            required: false,
            default: true,
            deprecated: true
        },

        supportsJsonView: {

            type: Boolean,
            required: false,
            default: true,
            deprecated: true
        },

        supportsRefreshList: {
            type: Boolean,
            required: false,
            default: true,
            deprecated: true
        },

         disableRefreshList: {
            type: Boolean,
            required: false,
            default: false,
        },

         hideDeleteAction: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({

        showFilter: true,
        filter: null,
        jsonView: false,
        jsonViewItem: null,
        showDeleteConfirmationDialog: false
    }),

    methods: {

        onRefreshList() {
            this.$emit('refresh-list', {

            });
        },

        onAddNewItem() {
            this.$emit('item-added', {

            });
        },
        
        onEdit(item) {
            this.$emit('item-edited', {
                item: item
            });
        }, 

        onDelete(item) {
            this.item = item;
            this.showDeleteConfirmationDialog = true;
        },

        onDeleteConfirmed() {
            this.$emit('item-deleted', {
                item: this.item
            });

            this.hideDeleteConfirmationDialog();
        },

        onDeleteCanceled() {
           this.hideDeleteConfirmationDialog(); 
        }, 

        hideDeleteConfirmationDialog() {
            this.showDeleteConfirmationDialog = false;
            this.item = null;
        },

        onFilterApplied() {
            this.$emit('item-list-filter-applied', {
                filter: this.filter ? this.filter : '*'
            });
        },
        fireRouteSubstance(event){
            console.log("1")
            this.$emit('route-substance-emitted', {
                target: "BatteryModelEditorView",
                item: event
            });
        },
        fireRouteModel(event){
            this.$emit('route-model-emitted', {
                target: "BatteryModelEditorView",
                item: event
            });
        },

        onPreviousPage() {

            // Compute the next index to read from.
            let newIndex = this.pagination.from - this.pagination.pageSize;
            if (newIndex < 0) newIndex = 0;

            this.$emit('previous-page', {
                from: newIndex,
                pageSize: this.pagination.pageSize
            });
        },

        onNextPage() {

            // Compute the next index to read from.
            let newIndex = this.pagination.from + this.pagination.pageSize;
            if (newIndex > this.pagination.totalItemCount) newIndex = this.pagination.totalItemCount;

            this.$emit('next-page', {
                from: newIndex,
                pageSize: this.pagination.pageSize
            });
        },

        showJsonView(item) {
            this.jsonView = true;
            this.jsonViewItem = item;
        },

        hideJsonView() {
            this.jsonView = false;
            this.jsonViewItem = null;
        },

        getModificationSubtitle(item) {

            if (!item.modificationDateTime && !item.modificationUser) {
                return this.$t('item-list.component.columns.modificationUserAndDateTimeUnknown');
            
            } else {
                let dateTime = this.$dxs.i18n.localizedDateTimeFormat(item.modificationDateTime, this.$i18n.locale);
                let userId   = item.modificationUser;

                return this.$t('item-list.component.columns.modificationUserAndDateTime', {dateTime, userId});
            }
        },

        copyJsonToClipboard() {
            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(JSON.stringify(this.jsonViewItem, true, 2));
            }
        },

        

    },

    computed: {

        itemList() {

            return this.items ? this.items : [];
        },

        itemCount() {
            return this.itemList.length;
        },

        itemListEmpty() {
            return this.itemCount < 1;
        },

        titleTruncation() {

            if (this.$vuetify.breakpoint.xsOnly) return 16;
            else if (this.$vuetify.breakpoint.smOnly) return 24;
            else if (this.$vuetify.breakpoint.mdOnly) return 32;  
            else return 48;
        },

        showDeleteAction() {
            if(this.hideDeleteAction){
                return false
            }else{
                return this.supportsDeleteItem
            }
        },

        paginationEmpty() {
            return isEmpty(this.pagination);
        },

        totalItemCount() {

            if (!this.paginationEmpty) {
                return this.pagination.totalItemCount
            } else {
                return this.itemCount;
            }
        },

        currentPageFrom() {
            // Add + 1 in order to avoid the User unfriendly start from index 0
            return !this.paginationEmpty ? this.pagination.from + 1 : 0;
        },

        currentPageTo() {

            if (this.paginationEmpty) {
                return 0;
            }

            return (this.pagination.from + this.pagination.pageSize <= this.pagination.totalItemCount) 
                 ? this.pagination.from + this.pagination.pageSize
                 : this.pagination.totalItemCount;

        },

        /**
         * Returns true, if the current model represents the first page of the data set. Otherwise false is returned.
         */
        firstPage() {   
            return !this.paginationEmpty ? this.pagination.from === 0 : true;
        },

        /**
         * Returns true, if the current model represents the last page of the data set. Otherwise false is returned.
         */
        lastPage() {
            return !this.paginationEmpty ? this.pagination.from + this.pagination.pageSize >= this.pagination.totalItemCount : true;
        }


    }
}
</script>

<i18n>
{
  "en": {
    "item-list.component.empty.title": "No elements are currently present.",
    "item-list.component.empty.subtitle" : "You may add new elements by using the + button.",
    "item-list.component.actions.json" : "Show JSON",
    "item-list.component.actions.add" : "Add New Item",
    "item-list.component.actions.add.xs" : "Add",
    "item-list.component.actions.delete" : "Delete Item",
    "item-list.component.actions.delete.xs" : "Delete",
    "item-list.component.actions.applyFilter" : "Apply Filter",
    "item-list.component.actions.applyFilter.xs" : "Apply",
    "item-list.component.actions.refresh" : "Reload List",
    "item-list.component.columns.name" : "Tech. ID",
    "item-list.component.columns.i18nName" : "Name",
    "item-list.component.columns.modificationDateTime" : "Last Modified on",
    "item-list.component.columns.modificationUser" : "Modified by",
    "item-list.component.columns.modificationUserAndDateTime" : "Last modification on {dateTime} by {userId}.",
    "item-list.component.columns.modificationUserAndDateTimeUnknown" : "No modification information available",
    "item-list.component.filter.label" : "Enter Search Expression",
    "item-list.component.dialog.json.title" : "JSON (Technical Representation)",
    
    "item-list.component.confirmRemove.title" : "Do you want to remove this item?",
    "item-list.component.confirmRemove.description" : "Please confirm removal of this item. Warning: Removal of an item can't be undone and might cause loss of data.",
    "item-list.component.confirmRemove.actions.confirm" : "Remove Item",
    "item-list.component.confirmRemove.actions.cancel" : "Cancel",

    "item-list.component.pagination.title" : "{currentPageFrom} to {currentPageTo} of {totalItemCount} total",
    "item-list.component.pagination.currentPage" : "Displaying items from {currentPageFrom} to {currentPageTo} of {totalItemCount} total",
    "item-list.component.columns.substance":"Battery Substance",
    "item-list.component.columns.model":"Battery Model"
  },

  "de": {
    "item-list.component.empty.title": "Momentan sind keine Elemente vorhanden.",
    "item-list.component.empty.subtitle" : "Sie können durch die + Schaltfläche neue Elemente hinzufügen.",
    "item-list.component.actions.json" : "Als JSON anzeigen",
    "item-list.component.actions.add" : "Element hinzufügen",
    "item-list.component.actions.add.xs" : "Hinzufügen",
    "item-list.component.actions.delete" : "Element löschen",
    "item-list.component.actions.delete.xs" : "Löschen",
    "item-list.component.actions.applyFilter" : "Filter anwenden",
    "item-list.component.actions.applyFilter.xs" : "Anwenden",
    "item-list.component.actions.refresh" : "Liste Neu Laden",
    "item-list.component.columns.name" : "Techn. Bezeichner",
    "item-list.component.columns.i18nName" : "Name",
    "item-list.component.columns.modificationDateTime" : "Geändert am",
    "item-list.component.columns.modificationUser" : "Geändert von",
    "item-list.component.columns.modificationUserAndDateTime" : "Letzte Änderung am {dateTime} von {userId}.",
    "item-list.component.columns.modificationUserAndDateTimeUnknown" : "Es liegen keine Informationen zur letzten Änderung vor.",
    "item-list.component.filter.label" : "Suchausdruck eingeben",
    "item-list.component.dialog.json.title" : "JSON (Technische Darstellung)",

    "item-list.component.confirmRemove.title" : "Möchten Sie dieses Element wirklich löschen?",
    "item-list.component.confirmRemove.description" : "Bitte bestätigen Sie das Löschen dieses Elements. Bitte beachten Sie hierbei: Das Löschen eines Elements kann nicht rückgängig gemacht werden und kann zu Datenverlust führen.",
    "item-list.component.confirmRemove.actions.confirm" : "Element löschen",
    "item-list.component.confirmRemove.actions.cancel" : "Abbrechen",

    "item-list.component.pagination.title" : "{currentPageFrom} bis {currentPageTo} von insg. {totalItemCount}",
    "item-list.component.pagination.currentPage" : "Die Liste zeigt die Elemente {currentPageFrom} bis {currentPageTo} von insg. {totalItemCount} an.",
    "item-list.component.columns.substance":"Batterie-Element",
    "item-list.component.columns.model":"Batterie-Modell"
  }
}
</i18n>