import api from '@/store/api';

export default {

    namespaced: true,

    state : {

        items: [],

        pagination: {
            from: 0,
            searchAfter: [],
            size: 50
        },
    },

    mutations: {

        setItems(state, items) {
            state.items = items ? items : [];
        },

        appendItems(state, items) {
            
            // In order to play it safe, make sure there is an actual instance.
            if (!state.items) {
                state.items = [];
            }

            // Push additional items
            // Note: Due to reasons with vuex it is necessary to push each item separately inside of a loop
            // It is not sufficient to push the array as is.
            for (let item of items) {
                state.items.push(item);
            }
        },

        setPagination(state, pagination) {
            state.pagination = pagination ? pagination : null;

        },
    },

    actions: {

        /**
         * Starts export of the currently selected data.
         * @param {*} context 
         * @param {*} param1 
         * @returns 
         */
        async exportData(context, {collectionId, listTemplate, format, charset}) {

            const body = {
                filter: listTemplate.filterSettings,
                sort: listTemplate.sortSettings,
                format: format,
                charset: charset
            }

            return api.collections.documents.exportDocument(collectionId, body).then(() => {});
        },


        async loadData(context, {collectionId, listTemplate}) {

            // Reset pagination and items from a potentially previous request.            
            context.commit('setPagination', null);
            context.commit('setItems', []);
            
            const query = prepareQuery(listTemplate.filterSettings, listTemplate.sortSettings, context.getters.nextPageInfo);
            
            const response = await api.collections.documents.findByQuery(collectionId, query);
            context.commit('setItems', response.items);
            context.commit('setPagination', response.pagination);

        },

        async loadMoreData(context, {collectionId, listTemplate}) {

            const query = prepareQuery(listTemplate.filterSettings, listTemplate.sortSettings, context.getters.nextPageInfo);

            const response = await api.collections.documents.findByQuery(collectionId, query);
            context.commit('appendItems', response.items);
            context.commit('setPagination', response.pagination);
        },

        async deleteDocumentById(context, {collectionId, documentId} ) {
            
            try {

                await api.collections.documents.deleteById(collectionId, documentId);
                return true;

            } catch (error) {

                console.log("An error has occured while deleting " + error);
                return false;
            }
        },

        resetData(context) {
            context.commit('setItems', []);
            context.commit('setPagination', { 
                from: 0,
                searchAfter: [],
                size: 50
            });
        }
    },

    getters: {
        
        nextPageInfo(state) {
            return state.pagination 
                 ? { from: 0, searchAfter: state.pagination.searchAfter, size: state.pagination.size }
                 : { from: 0, searchAfter: [], size: 50 };
        },

        documentListModel(state) {

            return {
                items: state.items,
                pagination: state.pagination
            };
        },

    }
}

function prepareQuery(filter, sort, pagination) {

    return {
        filter,
        sort,
        pagination,
        queryTimeoutMiliseconds: 25 * 1000 // 25 seconds
    }
}