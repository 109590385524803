<template>

    <v-list-item>
        <v-list-item-action>
            <v-btn icon @click="onUploadClicked" :disabled="disabledUpload" ><v-icon>cloud_upload</v-icon></v-btn>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>
              {{title}}
            </v-list-item-title>
            <v-list-item-subtitle>{{subtitle}}</v-list-item-subtitle>
        </v-list-item-content>

    </v-list-item>

</template>

<script>
import { get } from 'lodash';

export default {

    name: 'dxs-collection-data-pipeline-tile',

    props: {
        
        /**
         * An object of the form:
         * 
         * {
         *     name: string; 
         *     i18n: Record<string, string>
         *     dataIngestion: {
         *        documentRecord: {
         *           topic: string;
         *        },
         * 
         *        document: {
         *           topic: string;
         *        },
         * 
         *        deadLetter: {
         *           topic: string;
         *        }
         *     }
         * }
         */
        value: {
            type: Object,
            required: true    
        },

        flat: {
            type: Boolean,
            required: false,
            default: true
        },

        disabledUpload: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    methods: {
        
        onUploadClicked() {
            this.$emit('upload-clicked', { pipelineName: this.pipelineName, dataIngestion: get(this.value, 'dataIngestion', {}) });
        }
    },

    computed: {
        
        title() {
            return get(this.value, 'name', '?');
        },

        subtitle() {
            return `${get(this.value, 'dataIngestion.documentRecord.topic', '?')} > ${get(this.value, 'dataIngestion.document.topic', '?')}  > ${get(this.value, 'name', '?').toLowerCase()}-*`
        },

        pipelineName() {
            return get(this.value, 'name');
        },


    }
}
</script>