<template>
    <v-sheet class="secondary darken-3" >

            <v-sheet class="secondary darken-3">
                <v-toolbar flat color="secondary darken-3">
                    <v-spacer />
                </v-toolbar>
                <div class="text-h5 secondary--text text-center pt-5">
                    {{ title }}
                </div>
                <div class="text-body-1 white--text text-center pb-5">
                    {{ subtitle }}
                </div>
            </v-sheet>
        
            


        <slot>
            <v-card>
                <v-card-text class="txt-h6">Default Implementation</v-card-text>
                <v-card-text>Please implement the default slot in order to replace this card with your actual content.</v-card-text>
            </v-card>
        </slot>    
        
    </v-sheet>
</template>

<script>
export default {

    name: 'app-form',

    props: {

        title: { 
            type: String,
            required: true
        },

        subtitle: {
            type: String,
            required: true
        },

    },

    data() {

        return {

            headerArea: {
                visible: true
            },
        }
    },

    methods: {
        onIntersectHeaderArea(entries) {
            // More information about these options
            // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
            this.headerArea.visible = entries[0].isIntersecting;
        }
    },
}
</script>