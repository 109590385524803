<template>
    <div>
        <c-section :title="$t('workplace-types-list.view.title')" :subtitle="$t('workplace-types-list.view.subtitle')"
            class="secondary">
            <v-card class="mt-5 mb-5" tile>
                <c-item-list :title="$t('workplace-types-list.view.toolbar.title')" :items="items" :pagination="pagination"
                    @refresh-list="refresh" @previous-page="onPreviousPage" @next-page="onNextPage"
                    @item-added="onItemAdded" @item-edited="onItemEdited" @item-deleted="onItemDeleted"
                    @item-list-filter-applied="onFilterChanged" :disable-add-item="!hasCreateRole"
                    :disable-edit-item="!hasEditRole && !hasReadRole" :disable-delete-item="!hasDeleteRole"
                    supports-delete-item>


                </c-item-list>
                <v-card-text></v-card-text>
                <v-divider />
                <v-subheader class="grey lighten-5">{{
                    $t("workplace-types-list.view.help.title")
                }}</v-subheader>
                <v-card-text class="grey lighten-5">{{
                    $t("workplace-types-list.view.help.description")
                }}</v-card-text>
            </v-card>
        </c-section>
    </div>
</template>
  
<script>
import Roles from "@/roles";
import { get } from "lodash";

import WorkplaceTypesListModule from "../../../store/masterdata/workplace-types/workplace-types-list.module";

const MODULE_ID = "workplaceTypesList";

export default {
    name: "workplace-types-list",

    inject: [
        "errorHandlerService",
        "progressIndicatorService",
        "userContextService",
    ],

    data: () => ({
        collectionSetting: {},

        displayConfirmationDialog: false,

        displayDeleteDialog: false,
    }),

    computed: {
        response() {
            return this.$store.getters[MODULE_ID + "/response"];
        },

        items() {
            return this.response.items;
        },

        pagination() {
            return get(this.response, "pagination", {});
        },


        hasReadRole() {
            return this.userContextService.hasRole(Roles.WORKPLACETYPES_READ);
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.WORKPLACETYPES_EDIT);
        },

        hasCreateRole() {
            return this.userContextService.hasRole(Roles.WORKPLACETYPES_CREATE);
        },

        hasDeleteRole() {
            return this.userContextService.hasRole(Roles.WORKPLACETYPES_DELETE);
        },
    },

    methods: {
        async refresh() {
            this.loadPage(0, 50);
        },

        async onNextPage({ from, pageSize }) {
            this.loadPage(from, pageSize);
        },

        async onPreviousPage({ from, pageSize }) {
            this.loadPage(from, pageSize);
        },

        async loadPage(from, pageSize) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadResponse", {
                    from,
                    pageSize,
                });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        onItemEdited(event) {
            if (event) {
                this.$router.push({
                    name: "WorkplaceTypesEditorView",
                    params: {
                        name: event.item.name,
                    },
                });
            } else {
                this.$log.debug(
                    "Event object is undefined. Can't navigate to prediction scenario."
                );
            }
        },

        onItemAdded() {
            this.$router.push({ name: "WorkplaceTypesWizardView" });
        },

        async onItemDeleted({ item }) {
            this.progressIndicatorService.show();
            try {
                // Delete the item, then reload the list in order to reflect change.
                await this.$store.dispatch(MODULE_ID + "/deleteItem", item);
                this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterChanged(event) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter);
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        }
    },

    created() {
        // Dynamically register the vuex store module for managing the data.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, WorkplaceTypesListModule);
        }

        this.refresh();
    },
};
</script>
  
<i18n>
  {
      "en" : {
          "workplace-types-list.view.title" : "Workplace Type Management",
          "workplace-types-list.view.subtitle" : "You may use this dialog for creating, editing or deleting Workplace Types.",
          "workplace-types-list.view.toolbar.title" : "Workplace Types",
  
          "workplace-types-list.view.columns.open" : "Open",
          "workplace-types-list.view.columns.apis" : "APIs",
  
          "workplace-types-list.view.help.title" : "What are Workplace Types?",
          "workplace-types-list.view.help.description" : "A Workplace Type classifies a Workplace. Each Workplace is linked to a specific Workplace Type.",
          "workplace-types-list.view.dialogs.delete.actions.confirm" : "Confirm",
          "workplace-types-list.view.dialogs.delete.actions.cancel" : "Cancel"
  
  
      },
  
      "de" : {
          "workplace-types-list.view.title" : "Verwaltung Arbeitsplatztypen",
          "workplace-types-list.view.subtitle" : "Sie können neue Arbeitsplatztypen anlegen, einen Arbeitsplatztyp editieren oder löschen.",
          "workplace-types-list.view.toolbar.title" : "Arbeitsplatztypen",
          "workplace-types-list.view.columns.open" : "Öffnen",
          "workplace-types-list.view.columns.apis" : "APIs",
          "workplace-types-list.view.help.title" : "Was sind Arbeitsplatztypen?",
          "workplace-types-list.view.help.description" : "Ein Arbeitsplatztyp klassifiziert einen Arbeitsplatz. Jeder Arbeitsplatz ist mit einem bestimmten Arbeitsplatztypen verknüpft.",
          "workplace-types-list.view.dialogs.delete.actions.confirm" : "Bestätigen",
          "workplace-types-list.view.dialogs.delete.actions.cancel" : "Abbrechen"
  
      }
  }
  </i18n>