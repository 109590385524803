import api from '@/store/api';

export default {

    namespaced: true,

    state: {

        mappingJobTemplate: createEmptyMappingJobTemplate(),

        collectionSettings: []
    },

    mutations: {

        setMappingJobTemplate(state, mappingJobTemplate) {

            if (mappingJobTemplate) {
                state.mappingJobTemplate.name = mappingJobTemplate.name ? mappingJobTemplate.name : null;
            } else {
                state.mappingJobTemplate.name = null;
            }

        },   

        setCollectionSettings(state, collectionSettings) {
            state.collectionSettings = collectionSettings ? collectionSettings : [];
        }
    },

    actions: {
        
        async loadData(context) {

            try {
                let response = await api.catalog.collectionSettings.findAll('*');
                
                let collectionSettings = [];
                for (let item of response) {
                    collectionSettings.push(item.collectionSettings);
                }

                context.commit('setCollectionSettings', collectionSettings);

            } catch (error) {
                return Promise.reject(error);
            }
        },

        async createMappingJobTemplate(context) {

            try {
                
                let request = context.getters.request;
                await api.catalog.mappingJobTemplates.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetMappingJobTemplate(context) {
            context.commit('setMappingJobTemplate', createEmptyMappingJobTemplate());
        },

        async updateMappingJobTemplate(context, mappingJobTemplate) {
            
            try {
                context.commit('setMappingJobTemplate', mappingJobTemplate);
            } catch (error) {
                return Promise.reject(error);
            }
        },

    },



    getters: {

        
        request(state) {
            return {

                name: state.mappingJobTemplate.name,
                sourceCollectionId: state.mappingJobTemplate.sourceCollectionId,
                targetCollectionId: state.mappingJobTemplate.targetCollectionId,
                
                mappings: [],

                filter: {
                    filterQuery: "*", 
                    filterQueryLanguage: "SIMPLE",
                    facetFilters: [],  
                    rangeFilters: []
                },

                pageSize: 100,

                custData: {},

                creationDateTime: null,
                creationUser: null,
                modificationDateTime: null,
                modificationUser: null


            };
        },

        collectionSettings(state) {
            return state.collectionSettings ? state.collectionSettings  : [];
        },

        mappingJobTemplate(state) {
            return state.mappingJobTemplate ? state.mappingJobTemplate : createEmptyMappingJobTemplate();
        }
    }
}

function createEmptyMappingJobTemplate() {

    return {
        name: null,
        sourceCollectionId: null,
        targetCollectionId: null
    };
}