<template>
    <v-card tile flat>
        <v-card-text>

            <v-text-field  v-model="documentRecordTopic" :label="$t('data-ingestion-settings-editor.component.fields.documentRecordTopic.label')" filled disabled></v-text-field>

            <v-text-field v-model="documentTopic" :label="$t('data-ingestion-settings-editor.component.fields.documentTopic.label')" filled disabled></v-text-field>

            <v-text-field v-model="deadLetterTopic" :label="$t('data-ingestion-settings-editor.component.fields.deadLetterTopic.label')" filled disabled></v-text-field>

        </v-card-text>
    </v-card>
</template>

<script>
import { get } from 'lodash';
export default {

    props: {

        value: {
            type: Object,
            required: false,
            default: () => { }
        },

        readonly : {
            type: Boolean,
            required: false,
            default: false 
        },

        disabled : {
            type: Boolean,
            required: false,
            default: false 
        },
    },

    computed: {

        documentTopic: {

            get() {
                return get(this.value, 'document.topic', null);
            },

            set(value) {
                console.log(value);
            }
        },

        documentRecordTopic: {

            get() {
                return get(this.value, 'documentRecord.topic', null);
            },

            set(value) {
                console.log(value);
            }
        },

        deadLetterTopic: {

            get() {
                return get(this.value, 'deadLetter.topic', null);
            },

            set(value) {
                console.log(value);
            }
        },

    }
}
</script>

<i18n>
{
    "en" : {
        "data-ingestion-settings-editor.component.fields.documentRecordTopic.label" : "Document Record Topic",
        "data-ingestion-settings-editor.component.fields.documentTopic.label" : "Document  Topic",
        "data-ingestion-settings-editor.component.fields.deadLetterTopic.label" : "Dead Letter Topic"
    },

    "de" : {

    }
}
</i18n>