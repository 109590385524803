import api from '../../../api/battery-data-hub.api';
import { get } from 'lodash';



export default {

    namespaced: true,

    state : {

        hazardousSubstanceClassification: createDefaultHazardousSubstanceClassification(),
    },

    mutations: {

        setHazardousSubstanceClassification(state, setHazardousSubstanceClassification) {

            if (setHazardousSubstanceClassification) {
                state.hazardousSubstanceClassification.tenantId = setHazardousSubstanceClassification.tenantId;
                state.hazardousSubstanceClassification.name = setHazardousSubstanceClassification.name;
                state.hazardousSubstanceClassification.i18n = setHazardousSubstanceClassification.i18n;
                state.hazardousSubstanceClassification.data = setHazardousSubstanceClassification.data;
                state.hazardousSubstanceClassification.active = setHazardousSubstanceClassification.active;
                state.hazardousSubstanceClassification.references = setHazardousSubstanceClassification.references;
                state.hazardousSubstanceClassification.creationDateTime     = setHazardousSubstanceClassification.creationDateTime;
                state.hazardousSubstanceClassification.creationUser         = setHazardousSubstanceClassification.creationUser;
                state.hazardousSubstanceClassification.modificationDateTime = setHazardousSubstanceClassification.modificationDateTime;
                state.hazardousSubstanceClassification.modificationUser     = setHazardousSubstanceClassification.modificationUser;
            } else {
                state.hazardousSubstanceClassification.tenantId = null;
                state.hazardousSubstanceClassification.name = null;
                state.hazardousSubstanceClassification.i18n = {};
                state.hazardousSubstanceClassification.data = {"custData" : {}};
                state.hazardousSubstanceClassification.active = null;
                state.hazardousSubstanceClassification.references = {};
                state.hazardousSubstanceClassification.creationDateTime     = setHazardousSubstanceClassification.creationDateTime;
                state.hazardousSubstanceClassification.creationUser         = setHazardousSubstanceClassification.creationUser;
                state.hazardousSubstanceClassification.modificationDateTime = setHazardousSubstanceClassification.modificationDateTime;
                state.hazardousSubstanceClassification.modificationUser     = setHazardousSubstanceClassification.modificationUser;
            }
            
        }

    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.hazardousSubstanceClassifications.findById(name);
                context.commit('setHazardousSubstanceClassification', get(response, 'item.hazardousSubstanceClassification', createDefaultHazardousSubstanceClassification()));
            } catch (error) {
                context.commit('hazardousSubstanceClassificationId', createDefaultHazardousSubstanceClassification());
                throw error;
            }
        },

        async saveData(context) {

            try {
                const payload = get(context.getters.model, 'hazardousSubstanceClassification');
                console.log(JSON.stringify(response))
                console.log(JSON.stringify(payload))
                let response = await api.masterdata.hazardousSubstanceClassifications.update(payload);
                context.commit('setHazardousSubstanceClassification', get(response, 'item.hazardousSubstanceClassification', createDefaultHazardousSubstanceClassification()));

            } catch (error) {
                console.log(error);
                context.commit('setHazardousSubstanceClassification', createDefaultHazardousSubstanceClassification());
                throw error;

            }
        },
        
        
        updateModel(context, model) {
            console.log('+++ updateModel ', JSON.stringify(model, true, 2));
            context.commit('setHazardousSubstanceClassification', get(model, 'hazardousSubstanceClassification', createDefaultHazardousSubstanceClassification()));
        },

        updateHazardousSubstanceClassification(context, hazardousSubstanceClassification) {
            console.log('+++ updatehazardousSubstanceClassification ', JSON.stringify(hazardousSubstanceClassification, true, 2));
            context.commit('setHazardousSubstanceClassification', hazardousSubstanceClassification);
        }
    },

    getters: {
        
        model(state) {
            return {
                hazardousSubstanceClassification: state.hazardousSubstanceClassification
            }
        },
        
    }
}

function createDefaultHazardousSubstanceClassification() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {custData: {}},
        active: null,
        references: {},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }}
