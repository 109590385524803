<template>
  <v-card tile height="100%">

    <!-- =============================== -->
    <!-- TITLE -->
    <!-- =============================== -->
    <v-container fluid>
        <v-row>
            <v-col cols="9">
                <div class="text-h6 " :title="attribute | localizedName($i18n.locale)">
                {{ attribute | localizedName($i18n.locale) | truncate-middle($vuetify.breakpoint.smAndDown ? 16 : 28)}} 
                </div>
                <div >{{attribute.name}} </div>
            </v-col>
            <v-col cols="3" class="text-right">
                <v-chip v-if="attribute.optional" small :label="true" tag>Optional</v-chip>
            </v-col>
        </v-row>
    </v-container>
    <v-card-text>

        
    </v-card-text>
    <v-divider />

    <!-- =============================== -->
    <!-- COUNT  -->
    <!-- =============================== -->
    <v-card-text>
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="text-left" >
                    <div class="text-caption">{{$t('collection-attribute-stats-tile.component.countTotal.label')}}</div>
                    <div class="text-h4">{{countTotal | integer-format($i18n.locale)}}</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6" class="text-left" >
                    <div class="text-caption">{{$t('collection-attribute-stats-tile.component.count.label')}}</div>
                    <div class="text-h6">{{count | integer-format($i18n.locale)}}</div>
                </v-col>
                <v-col cols="12" md="6"  class="text-left" >
                    <div class="text-caption">{{$t('collection-attribute-stats-tile.component.countNull.label')}}</div>
                    <div class="text-h6">{{countNull | integer-format($i18n.locale)}} </div>
                </v-col>
            </v-row>
            <!--
            <v-row>
                <v-col cols="12" class="text-left" >
                    <div class="text-caption">{{$t('collection-attribute-stats-tile.component.countNull.label')}}</div>
                    <div class="text-h5">{{cardinality | integer-format($i18n.locale)}} </div>
                </v-col>
            </v-row>
            -->
        </v-container>
    </v-card-text>
    <v-divider />
    <c-pie-chart donut height="350px" :items="chartModel" />
    <template v-if="measure">
    <v-divider />
    <v-simple-table>
        <tbody>
            <tr>
                <td>{{$t('collection-attribute-stats-tile.component.min.label')}}</td>
                <td class="text-right pr-5">{{ min | round(2) | decimal-format($i18n.locale)}}</td>
            </tr>
            <tr>
                <td>{{$t('collection-attribute-stats-tile.component.max.label')}}</td>
                <td class="text-right pr-5">{{ max | round(2) | decimal-format($i18n.locale)}}</td>
            </tr>
            <tr>
                <td>{{$t('collection-attribute-stats-tile.component.avg.label')}}</td>
                <td class="text-right pr-5">{{ avg | round(2) | decimal-format($i18n.locale)}}</td>
            </tr>
            <tr>
                <td>{{$t('collection-attribute-stats-tile.component.median.label')}}</td>
                <td class="text-right pr-5">{{ median | round(2) | decimal-format($i18n.locale)}}</td>
            </tr>
            <tr>
                <td>{{$t('collection-attribute-stats-tile.component.sum.label')}}</td>
                <td class="text-right pr-5">{{ sum | round(2) | decimal-format($i18n.locale)}}</td>
            </tr>
            <tr>
                <td>{{$t('collection-attribute-stats-tile.component.stddev.label')}}</td>
                <td class="text-right pr-5">{{ stddev | round(2) | decimal-format($i18n.locale)}}</td>
            </tr>
            <tr>
                <td>{{$t('collection-attribute-stats-tile.component.variance.label')}}</td>
                <td class="text-right pr-5">{{ variance | round(2) | decimal-format($i18n.locale)}}</td>
            </tr>
        </tbody>
    </v-simple-table>
    </template>



    <!-- =============================== -->
    <!-- CHART -->
    <!-- =============================== -->
    <!--
    <v-divider />
    <v-subheader>{{$t('collection-attribute-stats-tile.component.distribution.title')}}</v-subheader>
    <v-card-text>
        <template v-if="pieChart">
             <v-lazy><c-donut-chart :height="350" :value="chartBuckets" /></v-lazy>
        </template>
        <template v-else>
            <v-lazy><c-histogram-chart :value="chartBuckets" /></v-lazy>
        </template>

    </v-card-text>
    <v-card-text />
    -->

    <!-- Table -->
    <!--
    <v-divider />
    <v-simple-table>
        <thead>
            <tr>
                <th class="text-left">{{$t('collections.common.view.value.title')}}</th>
                <th class="text-right">{{$t('collections.common.view.frequency-percentage.title')}}</th>
                <th class="text-right">{{$t('collections.common.view.frequency-absolute.title')}}</th>
            </tr>
        </thead>
 <tbody>
            <tr v-for="bucket of tableBuckets" :key="'tr_bucket_' + bucket.name">
                <td class="text-left" :title="bucket.name">{{bucket.name | truncate(24)}}</td>
                <td class="text-right">{{bucket.count | round(2) | decimal-format($i18n.locale)}}</td>
                <td class="text-right">{{bucket.absoluteCount | integer-format($i18n.locale)}}</td>
            </tr>
            <tr v-for="index in numberEmptyTableBuckets" :key="'tr_bucket_empty_' + index">
                <td class="text-left">-</td>
                <td class="text-right">{{ 0 | round(2) |  decimal-format($i18n.locale)}}</td>
                <td class="text-right">0</td>
            </tr>
            <tr>
                <td class="text-left">{{otherBucket.name}}</td>
                <td class="text-right">{{otherBucket.count | round(2) | decimal-format($i18n.locale)}}</td>
                <td class="text-right">{{otherBucket.absoluteCount | integer-format($i18n.locale)}}</td>
            </tr>
        </tbody>

    </v-simple-table>
    -->

  </v-card>
</template>

<script>

import { get }from 'lodash';
export default {

    name: 'collection-attribute-stats-tile',

    props: {
        
        value: {
            type: Object,
            required: false,
            validator: e => e.attribute && e.values
        },

        options: {
            type: Object,
            required: false
        },

    },


    computed: {

        attribute() {
            return get(this.value, 'attribute', {});
        },

        measure() {
            return this.$dxs.attributes.isMeasure(this.attribute);
        },

        metrics() {
            return get(this.value, 'values', {});
        },

        count() {
            return get(this.metrics, 'count', 0);
        },

        countNull() {
            return get(this.metrics, 'countNull', 0);
        },

        countTotal() {
            return this.count + this.countNull;
        },

        min() {
            return get(this.metrics, 'min');
        },

        max() {
            return get(this.metrics, 'max');
        },

        avg() {
            return get(this.metrics, 'average');
        },

        sum() {
            return get(this.metrics, 'sum');
        },

        median() {
            return get(this.metrics, 'median');
        },

        variance() {
            return get(this.metrics, 'variance');
        },

        stddev() {
            return get(this.metrics, 'standardDeviation');
        },

        cardinality() {
            return get(this.metrics, 'cardinality');
        },

        chartModel() {
            return [
                { 
                   name: 'Count', 
                   value: this.count,
                   i18n: {
                       'en' : { name : 'With Values' },
                       'de' : { name : 'Mit Werten'}
                   }, 
                   style: { color: '#00897B'}
                },
                { 
                    name: 'Count Null', 
                    value: this.countNull, 
                    i18n: {
                       'en' : { name : 'Without Values' },
                       'de' : { name : 'Ohne Werte'}
                   }, 
                    style: { color: '#B2DFDB'}
                } 
            ];
        },

 

    
    }
}
</script>

<i18n>
{
    "en" : {
        
        "collection-attribute-stats-tile.component.countTotal.label" : "Count Total",
        "collection-attribute-stats-tile.component.count.label" : "With Values",
        "collection-attribute-stats-tile.component.countNull.label" : "Without Values",
        "collection-attribute-stats-tile.component.cardinality.label" : "Cardinality",

        "collection-attribute-stats-tile.component.min.label" : "Minimum",
        "collection-attribute-stats-tile.component.max.label" : "Maximum",
        "collection-attribute-stats-tile.component.avg.label" : "Average",
        "collection-attribute-stats-tile.component.median.label" : "Median",
        "collection-attribute-stats-tile.component.sum.label" : "Sum",
        "collection-attribute-stats-tile.component.variance.label" : "Variance",
        "collection-attribute-stats-tile.component.stddev.label" : "Standard Deviation"
    }, 
    
    "de" : {
        
        "collection-attribute-stats-tile.component.countTotal.label" : "Anzahl gesamt",
        "collection-attribute-stats-tile.component.count.label" : "Mit Werten",
        "collection-attribute-stats-tile.component.countNull.label" : "Ohne Werte",
        "collection-attribute-stats-tile.component.cardinality.label" : "Kardinalität",

        "collection-attribute-stats-tile.component.min.label" : "Minimum",
        "collection-attribute-stats-tile.component.max.label" : "Maximum",
        "collection-attribute-stats-tile.component.avg.label" : "Durchschnitt",
        "collection-attribute-stats-tile.component.median.label" : "Median",
        "collection-attribute-stats-tile.component.sum.label" : "Summe",
        "collection-attribute-stats-tile.component.variance.label" : "Varianz",
        "collection-attribute-stats-tile.component.stddev.label" : "Standardabweichung"
    }
}
</i18n>