import api from '@/store/api';
import { cloneDeep, get, isEmpty } from "lodash";

export default {

    namespaced: true,

    state : {

        selectedListTemplate: {

            name: null,

            scope: 'user',
            
            filterSettings: {

            },
    
            viewSettings: {
    
            },
    
            sortSettings: {
    
            },    
        },

        listTemplates: []
    },

    mutations: {

        setName(state, name) {
            state.selectedListTemplate.name = !isEmpty(name) ? name : null;
        },

        setScope(state, scope) {
            state.selectedListTemplate.scope =  !isEmpty(scope) ? scope : 'user';
        },

        setFilterSettings(state, filterSettings) {
            if (!isEmpty(filterSettings)) {
                state.selectedListTemplate.filterSettings = filterSettings;
            }
        },

        setViewSettings(state, viewSettings) {
            if (!isEmpty(viewSettings)) {
                state.selectedListTemplate.viewSettings = viewSettings;
            }
        },

        setSortSettings(state, sortSettings) {
            if (!isEmpty(sortSettings)) {
                state.selectedListTemplate.sortSettings = sortSettings;
            }
        },

        setListTemplates(state, listTemplates) {
            if (!isEmpty(listTemplates)) {
                state.listTemplates = listTemplates;
            }
        }
    },

    actions: {

        async loadListTemplates(context, {collectionId}) {
            
            // Load User-specific templates
            const response = await api.me.listTemplates.findByCollectionId(collectionId, '*');
            if (response.items) {
                
                // Transform items to canonical template form.
                const templates = [];
                for (const item of response.items) {

                    templates.push({
                        name: item.templateId,
                        i18n: item.i18n,
                        bookmark: item.bookmark,
                        scope: 'user',
                        filterSettings: item.filterSettings,
                        sortSettings: item.sortSettings,
                        viewSettings: item.viewSettings,
                        technicalId: item.name,
                    })
                }
                context.commit('setListTemplates', templates);
            }

            // Tenant-level templates will be added later on...

        },

        async applyListTemplate(context, {name, scope, filterSettings, viewSettings, sortSettings}) {
            
            context.commit('setName', name);
            context.commit('setScope', scope);
            context.commit('setFilterSettings', filterSettings);
            context.commit('setViewSettings', viewSettings);
            context.commit('setSortSettings', sortSettings);
        },

        async saveListTemplate(context, { userId, collectionId, listTemplate }) {

            // We need to transform the given list template.
            const payload = { 
                templateId: listTemplate.name,
                collectionId: collectionId,
                userId: userId,
                i18n: get(listTemplate ,'i18n', {}),
                bookmark: get(listTemplate ,'bookmark', false),
                filterSettings: listTemplate.filterSettings,
                viewSettings: listTemplate.viewSettings,
                sortSettings: listTemplate.sortSettings,
                custData: get(listTemplate ,'custData', {}),  
                creationDateTime: null,
                creationUser: null,
                modificationDateTime: null,
                modificationUser: null
            }

            const response = await api.catalog.userListTemplates.update(userId, collectionId, listTemplate.name, payload);       

            return response;

        },

        async saveTenantListTemplate(context, {collectionId, listTemplate }) {

            // We need to transform the given list template.
            const payload = { 
                templateId: listTemplate.name,
                collectionId: collectionId,
                i18n: get(listTemplate ,'i18n', {}),
                bookmark: get(listTemplate ,'bookmark', false),
                filterSettings: listTemplate.filterSettings,
                viewSettings: listTemplate.viewSettings,
                sortSettings: listTemplate.sortSettings,
                custData: get(listTemplate ,'custData', {}),  
                creationDateTime: null,
                creationUser: null,
                modificationDateTime: null,
                modificationUser: null
            }

            const response = await api.catalog.tenantListTemplates.update(collectionId, listTemplate.name, payload);       

            return response;

        } 
    },

    getters: {

        selectedListTemplate(state) {
            return cloneDeep(state.selectedListTemplate);
        },

        listTemplates(state) {
            return cloneDeep(state.listTemplates);
        }
    }
}