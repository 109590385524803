<template>
   <c-item-editor-page :title="$t('user-group-assignment-page.component.title')" :subtitle="$t('user-group-assignment-page.component.subtitle')" class="mb-5" flat>
        <v-card tile flat class="mr-3">
            <v-card-text>
                <div class="text-subtitle-1 font-weight-medium pb-3">{{$t('user-group-assignment-page.component.sections.groups.title')}}</div>
                <div>{{$t('user-group-assignment-page.component.sections.groups.subtitle')}}</div>
            </v-card-text>
            <template v-if="!groupsEmpty">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th>{{$t('user-group-assignment-page.component.sections.groups.table.cols.name')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(group, index) of groups" :key="'tr_groups_' + index">
                            <td>{{group}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </template>
            <template v-else>
                <v-card-text class="grey lighten-3">
                    {{$t('user-group-assignment-page.component.sections.groups.table.isEmpty')}}
                </v-card-text>
            </template>

        </v-card>
   </c-item-editor-page>
</template>

<script>
import { get, isEmpty } from 'lodash';
export default {

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    computed: {

        groups() {
            return get(this.value, 'user.groups', []);
        },

        groupsEmpty() {
            return isEmpty(this.groups);
        }
        
    }
}
</script>

<i18n>
{
    "en" : {
        "user-group-assignment-page.component.title" : "Benutzergruppen",
        "user-group-assignment-page.component.subtitle" : "This page contains a comprehensive overview of the user's assigned groups.",

        "user-group-assignment-page.component.sections.groups.title" : "User Group Assignment",
        "user-group-assignment-page.component.sections.groups.subtitle" : "The user is a member of the following user groups.",
        "user-group-assignment-page.component.sections.groups.table.cols.name" : "User Group Name",
        "user-group-assignment-page.component.sections.groups.table.isEmpty" : "Currently this user is not a member of a user group."


    },
    
    "de" : {
        "user-group-assignment-page.component.title" : "Benutzergruppen",
        "user-group-assignment-page.component.subtitle" : "Diese Seite beinhaltet eine kurze Übersicht der zugeordneten Benutzergruppen.",

        "user-group-assignment-page.component.sections.groups.title" : "Benutzergruppen",
        "user-group-assignment-page.component.sections.groups.subtitle" : "Der Benutzer ist Mitglied der nachfolgenden Benutzergruppen.",
        "user-group-assignment-page.component.sections.groups.table.cols.name" : "Name Benutzergruppe",
        "user-group-assignment-page.component.sections.groups.table.isEmpty" : "Momentan ist der Benutzer Mitglied keiner Grupppe."
    }
}
</i18n>