import api from '@/store/api';
import { set } from 'lodash';
export default {

    namespaced: true,

    state: {
        response: emptyResponse(),
        schemas: [],
    },

    mutations: {

        setResponse(state, response) {

            // Push additional data into the response
            if (response) {

                state.response.data.collectionSettings = response.collectionSettings;
                state.response.data.schema = response.schema;

                if (!response.collectionSettings['parameter']) {
                    state.response.data.collectionSettings.parameter = {};
                }

                if (!response.collectionSettings['custData']) {
                    state.response.data.collectionSettings.custData = {};
                }

                if (!response.collectionSettings['embeddedCollections']) {
                    state.response.data.collectionSettings.embeddedCollections = [];
                }
            
            } else {
                state.response = emptyResponse();
            }
        },

        setSchemas(state, schemas){

            if (schemas) {
                state.schemas = schemas;
            } else  {
                state.schemas = [];
            }
        },

        setDocumentChangeLogging(state, logging) {
            set(state, 'response.data.collectionSettings.loggingSettings.documentChangeLogging', logging);
        },

        setQueryLogging(state, logging) {
            set(state, 'response.data.collectionSettings.loggingSettings.queryLogging', logging);
        }
    },

    actions: {

        async loadData(context, {name}) {

            try {

                let response = await api.catalog.collectionSettings.findById(name);
                context.commit('setResponse', response);

                // Schemas are required for editing embedded collections.
                let schemas = await api.catalog.schemas.findAll();
                context.commit('setSchemas', schemas.items ? schemas.items : []);
                
            } catch (error) {
                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', emptyResponse());
                context.commit('setSchemas', []);
                return Promise.reject(error);
            }
        },

    },

    getters: {
        request(state) {
            return state.response.data.collectionSettings;
        },

        model(state) {
            return {
                collectionSettings: state.response.data.collectionSettings,
                schema: state.response.data.schema
            };
        }
    }
}

function emptyResponse() {

    return {
        
        data: {

            collectionSettings: {

                name: null,
                partitionIdTemplate: null,
                idTemplate: null,
                i18n: {},
                custData: {},
                clientSettings: {
                    listDialogUrlTemplate: "/collections/${collectionId}/documents",
                    detailDialogUrlTemplate: "/collections/${collectionId}/documents/${documentId}"
                  },
                loggingSettings: {
                    documentChangeLogging: "TENANT",
                    queryLogging: "TENANT"
                },
                embeddedCollections: [],
            },

            schema: {

                i18n: {},
                attributes: {}
            },
        }

    };
}