<template>
    <v-sheet class="secondary darken-3">

        <c-sticky-content>
            <v-toolbar flat>
                <v-toolbar-title> {{ $t('battery-material-matrix.general.title') }}</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon @click="showFilter"><v-icon>filter_list</v-icon></v-btn>
                    <v-btn icon @click="refresh"><v-icon>refresh</v-icon></v-btn>
                    <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <dxs-battery-material-matrix-filter-summary :value="filterSummary" v-if="$vuetify.breakpoint.mdAndUp" />
            <v-divider />
        </c-sticky-content>

        <v-container>
            <v-card flat>
                <!-- *************************** -->
                <!-- Filter -->
                <!-- *************************** -->
                <v-navigation-drawer fixed right temporary v-model="filterDialog.display" width="500">
                    <v-card tile height="100%">
                        <v-toolbar flat>
                            <v-toolbar-title>Filter</v-toolbar-title>
                            <v-spacer />
                            <v-toolbar-items>
                                <v-btn color="accent" @click="applyFilter" text>
                                    Anwenden</v-btn>
                                <v-btn color="grey" @click="hideFilter" icon><v-icon>close</v-icon></v-btn>
                            </v-toolbar-items>
                        </v-toolbar>

                        <v-subheader>{{ $t('battery-material-matrix-itemselector.batterymodel') }}</v-subheader>
                        <v-card-text>
                            <c-item-checkbox-multi-selector v-model="batteryModelsFilter"
                                :items="batteryModelsFilterItems" />
                        </v-card-text>

                        <v-subheader>{{ $t('battery-material-matrix-itemselector.manufacturer') }}</v-subheader>
                        <v-card-text>
                            <c-item-checkbox-multi-selector v-model="manufacturerFilter" :items="manufacturerFilterItems" />
                        </v-card-text>

                        <v-subheader>{{ $t('battery-material-matrix-itemselector.material') }}</v-subheader>
                        <v-card-text>
                            <c-item-checkbox-multi-selector v-model="materialsFilter" :items="materialsFilterItems" />
                        </v-card-text>

                    </v-card>
                </v-navigation-drawer>

                <v-container fluid>
                    <v-simple-table fixed-header height="300px">

                        <thead>
                            <tr>
                                <th> {{ $t('battery-material-matrix.tableheader.batterymodel') }}</th>
                                <th> {{ $t('battery-material-matrix.tableheader.manufacturer') }}</th>
                                <th> {{ $t('battery-material-matrix.tableheader.packs') }}</th>
                                <th class="text-left" v-for="(item, index) in materials.items" :key="'material-' + index">
                                    <div class="text-center">
                                        <p></p>
                                        <v-chip class="text-caption">
                                            {{ `${item.name}` }}
                                        </v-chip>
                                        <span class="text-caption">{{ `${item.data.casIdentifier
                                            ? item.data.casIdentifier : '-'}` }}</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(item, index) in batteryMaterialMatrixData.items" :key="item.name + '-' + index">
                                <td>{{ item.batterymodel.name }}</td>
                                <td>{{ item.manufacturer.name }}</td>
                                <td>{{ item.batterymodel.batterypacks }}</td>

                                <td :style="{ backgroundColor: hasBatteryMaterial(item, material) ? 'white' : 'whitesmoke' }"
                                    class="text-center" v-for="(material, materialIndex) in materials.items"
                                    :key="'share-' + materialIndex">
                                    {{ $dxs.formatting.formatDecimal(
                                        findMaterialShare(item, material) || '',
                                        $i18n.locale) }}
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-container>
            </v-card>
        </v-container>
    </v-sheet>
</template>

<script>

import BatteryMaterialMatrixFilterComponent from '@/solutions/battery-data-hub/components/battary-material-matrix/battery-material-matrix-filter-summary.component';
import { get } from 'lodash';
import BatteryMaterialMatrixModule from '../../../store/masterdata/battery-material-matrix/battery-material-matrix.module';

const MODULE_ID = 'batteryMaterialMatrix';
export default {
    name: 'battery-material-matrix',

    inject: [
        "errorHandlerService",
        "progressIndicatorService",
        "userContextService",
    ],

    components: {
        'dxs-battery-material-matrix-filter-summary': BatteryMaterialMatrixFilterComponent
    },


    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data() {

        return {

            headerArea: {
                visible: true
            },
            filterDialog: {
                display: false,
            }

        }
    },



    computed: {

        batteryModels() {
            return this.$store.getters[MODULE_ID + '/batteryModels'];
        },
        materials() {
            return this.$store.getters[MODULE_ID + '/materials'];
        },
        batteryMaterialMatrixData() {
            return this.$store.getters[MODULE_ID + '/batteryMaterialMatrixData'];
        },



        // ============================================
        // Filter Start
        // ============================================

        batteryModelsFilterItems() {
            return this.$store.getters[MODULE_ID + '/batteryModelsFilterItems'];
        },
        manufacturerFilterItems() {
            return this.$store.getters[MODULE_ID + '/manufacturerFilterItems'];
        },
        materialsFilterItems() {
            return this.$store.getters[MODULE_ID + '/materialsFilterItems'];

        },

        filterSummary() {
            return {
                filter: this.filter,
                batteryModelsFilterItems: this.batteryModelsMap,
                manufacturerFilterItems: this.manufacturerMap,
                materialsFilterItems: this.materialsFilterItemsMap
            }
        },


        filter() {
            return this.$store.getters[MODULE_ID + '/filter'];
        },

        batteryModelsFilter: {
            get() {
                return get(this.filter, 'batteryModelsFilterItems');
            },
            set(batteryModelsFilterItems) {
                this.$store.dispatch(MODULE_ID + '/updateBatteryModelsFilterItems', batteryModelsFilterItems);
            }
        },

        manufacturerFilter: {
            get() {
                return get(this.filter, 'manufacturerFilterItems');
            },
            set(manufacturerFilterItems) {
                this.$store.dispatch(MODULE_ID + '/updateManufacturerFilterItems', manufacturerFilterItems);
            }
        },

        materialsFilter: {
            get() {
                return get(this.filter, 'materialsFilterItems');
            },
            set(materialsFilterItems) {
                this.$store.dispatch(MODULE_ID + '/updateMaterialsFilterItems', materialsFilterItems);
            }
        },


        batteryModelsMap() {

            const batteryModelsMap = {};
            for (const batteryModel of this.batteryModelsFilterItems) {
                batteryModelsMap[batteryModel.name] = batteryModel;
            }

            return batteryModelsMap;

        },

        manufacturerMap() {

            const manufacturerMap = {};
            for (const manufacturer of this.manufacturerFilterItems) {
                manufacturerMap[manufacturer.name] = manufacturer;
            }

            return manufacturerMap;

        },

        materialsFilterItemsMap() {
            const materialsMap = {};
            for (const material of this.materialsFilterItems) {
                materialsMap[material.name] = material;
            }

            return materialsMap;
        },
        // ============================================
        // Filter End
        // ============================================

    },
    methods: {

        hasBatteryMaterial(item, material) {
            return item.materials.some(m => m.name === material.name && m.share);
        },

        findMaterialShare(item, material) {
            const foundMaterial = item.materials.find(m => m.name === material.name);
            if (foundMaterial) {
                return foundMaterial.share;
            }
            return null;
        },

        showFilter() {
            this.filterDialog.display = true;
        },
        hideFilter() {
            this.filterDialog.display = false;
        },
        async applyFilter() {
            await this.refresh();
            this.hideFilter();
        },
        close() {
            this.$router.go(-1);
        },

        async refresh() {

            this.$log.debug(`Refresh: Resetting model and reloading data with current filter settings.`)
            this.progressIndicatorService.show();

            try {
                this.$log.debug(`Resetting the model ${this.name}`);
                await this.$store.dispatch(`${MODULE_ID}/resetAllLists`);
                this.$log.debug(`Loading shift event logs for site ${this.name}`);
                await this.loadAllLists();

            } catch (error) {
                this.errorHandlerService.handleError(error);

            } finally {
                this.progressIndicatorService.hide();
            }
        },



        // Load all lists to combine data for BatteryMaterilaMatrix.
        async loadAllLists() {

            //clear all lists.
            await this.$store.dispatch(MODULE_ID + '/resetAllLists');

            // A List of actions in Vuex store.
            const actionMap = {
                "loadMaterials": "materials",
                "loadBatteryModels": "batteryModels",
                "loadManufacturer": "manufacturer",
                "loadBatteryMaterialMatrixData": "batteryMaterialMatrixData"
            }
            this.progressIndicatorService.show();
            try {
                for (const action in actionMap) {
                    let from = 0;
                    const pageSize = 50;
                    let loadMore = true;
                    while (loadMore) {
                        this.progressIndicatorService.updateText(`${actionMap[action]} from ${from} to ${from + pageSize}`);
                        await this.$store.dispatch(MODULE_ID + `/${action}`, {
                            from: from,
                            pageSize: pageSize,


                        });
                        const totalItemCount = this.$store.getters[MODULE_ID + `/${actionMap[action]}`].pagination.totalItemCount;
                        const currentItemCount = this.$store.getters[MODULE_ID + `/${actionMap[action]}`].items.length;

                        if (currentItemCount < totalItemCount) {
                            from = from + pageSize;
                        }
                        else {
                            loadMore = false;
                        }
                    }
                }

            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

    },
    created() {
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, BatteryMaterialMatrixModule);
            this.$log.debug(`Vuex Store ${MODULE_ID} has been registed.`);
        }

        this.loadAllLists();

    },
    destroyed() {
        // Unregister the vuex store if it has been registered before.
        if (this.$store.hasModule(MODULE_ID)) {
            this.$store.unregisterModule(MODULE_ID);
            this.$log.debug(`Vuex Store ${MODULE_ID} has been unregistered.`);
        }
    }
};
</script>

<i18n>
{
    "en":{
        "battery-material-matrix.general.title":"Battery Material Matrix",
        "battery-material-matrix.general.description": "This matrix illustrates the dependencies between materials, shares, battery models, and battery packs.",
        "battery-material-matrix.tableheader.batterymodel":"Batterymodel",
        "battery-material-matrix.tableheader.manufacturer":"Manufacturer",
        "battery-material-matrix.tableheader.packs":"Packs",
       "battery-material-matrix-itemselector.batterymodel": "Battery Models",
       "battery-material-matrix-itemselector.manufacturer": "Manufacturer",
       "battery-material-matrix-itemselector.material": "Materials"
       
    },

    "de":{
        "battery-material-matrix.general.title":"Batterie-Material-Matrix",
        "battery-material-matrix.general.description": "Diese Matrix veranschaulicht die Abhängigkeiten zwischen Materialien, Anteilen, Batteriemodellen und Batteriepaketen.",
        "battery-material-matrix.tableheader.batterymodel":"Batteriemodell",
        "battery-material-matrix.tableheader.manufacturer":"Hersteller",
        "battery-material-matrix.tableheader.packs":"Batterien",
        "battery-material-matrix-itemselector.batterymodel": "Batterie Modelle",
       "battery-material-matrix-itemselector.manufacturer": "Hersteller",
       "battery-material-matrix-itemselector.material": "Materialien"
    }
}
</i18n>

