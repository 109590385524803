<template>
    <v-card :tile="tile" :flat="flat">
        <v-card-text class="error white--text text-h6">
            {{  $t('required-roles-card.component.title') }}
        </v-card-text>
        <v-card-text class="error white--text text-body-2">
            {{  $t('required-roles-card.component.description') }}
        </v-card-text>
        <v-divider />
        <v-subheader>{{  $t('required-roles-card.component.missingRoles') }}</v-subheader>
        <v-card-text>
            <v-chip v-for="(item, index) of items" :label="item" :key="`role-chip-${index}`" /> 
        </v-card-text>
    </v-card>
</template>

<script>
export default {

    name: 'required-roles-missing-card',

    props: {

        /**
         * Each item must be a string, representing a role
         */
        items: {
            type: Array,
            required: true,
            default: () => ([])
        },

        tile : {
            type: Boolean,
            required: true,
            default: false

        },

        flat : {
            type: Boolean,
            required: true,
            default: false

        },
    },

    
}
</script>

<i18n>
{
    "en" : {
        "required-roles-card.component.title" : "Required Roles are Missing",
        "required-roles-card.component.description" : "The requested information can't be displays, because your user account is missing required roles. Please request assignment of the appropriate roles from your system administrator.",
        "required-roles-card.component.missingRoles" : "Missing Roles"
    },

    "de" : {
        "required-roles-card.component.title" : "Erforderliche Rollen sind nicht zugewiesen",
        "required-roles-card.component.description" : "Die angeforderten Informationen können nicht angezeigt werden, da Ihrem Benutzerkonto die erforderlichen Rollen fehlen. Bitte beantragen Sie die Zuweisung der entsprechenden Rollen bei Ihrem Systemadministrator.",
        "required-roles-card.component.missingRoles" : "Fehlende Rollen"
    }
}    
</i18n>