import api from '../../../api/battery-data-hub.api';

export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(), 

        filter: getDefaultFilter()
    },

    mutations: {

        setResponse(state, response) {
            if (response) {

                let items = [];
                if(response.items){
                    state.response.items = response.items;
                }else{
                    for (let item of response.items) {
                        items.push(item.collectionSettings);
                    }

                    state.response.items = items;
                }

                if(response.tenantId){
                    state.response.tenantId = response.tenantId;
                }

                if(response.pagination){
                    state.response.pagination = response.pagination;
                }
                    
            }

        },

        setFilter(state, filter) {

            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter()
            }
        }
    },

    actions: {

        async loadResponse(context, {from, pageSize}) {

            try {
                let response = await api.masterdata.batteryCategories.findByFilter(context.state.filter, from, pageSize);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async deleteItem(context, {name}) {
            
            await api.masterdata.batteryCategories.deleteById(name);
        },

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        }
    },

    getters: {

        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        }
    }
}

function getDefaultResponse() {

    return {
        tenantId: "",
        items: [],
        pagination: {}
    };
}

function getDefaultFilter() {
    return '*';
}