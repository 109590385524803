<template>
  <div class="secondary darken-1" >

        <v-breadcrumbs>
            <v-breadcrumbs-item :disabled="true">{{$t('Katalog')}}</v-breadcrumbs-item>
            <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
            <v-breadcrumbs-item :to="{ name: 'schemaList' }" :disabled="false">{{$t('Schemas')}}</v-breadcrumbs-item>
            <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
            <v-breadcrumbs-item>{{schema.name}}</v-breadcrumbs-item>
        </v-breadcrumbs>
        

        <v-card tile>
            <c-sticky-content>
            
                <v-toolbar color="white" flat>
                    <v-toolbar-items><v-btn icon @click="cancel"><v-icon>arrow_back</v-icon></v-btn></v-toolbar-items>
                    <div class="text-h6 ">{{$t('catalog.common.schema.label')}}: {{schema | localized-name($i18n.locale)}}</div>
                    
                    <v-spacer />
                    <v-toolbar-items><v-btn text @click="save">{{$t('actions.save.label')}}</v-btn></v-toolbar-items>
                    <v-toolbar-items><v-btn text @click="cancel">{{$t('actions.cancel.label')}}</v-btn></v-toolbar-items>
                    
                </v-toolbar>
                <v-divider />
            </c-sticky-content>
            
            <v-card-text />
            <v-card-text class="text-h6">{{$t('catalog.common.technicalname.label')}}</v-card-text>
            <v-card-text class="body-2">
                {{$t('catalog.common.technicalname.schema.description')}}
            </v-card-text>
            <v-card-text class="body-2">
                <v-text-field :value="schema.name" :disabled="true" filled :label="$t('catalog.common.technicalname.label')" />
            </v-card-text>
            
            
            <v-divider />
            <v-card-text />
            <v-card-text class="text-h6">{{$t('catalog.common.translation.localization.label')}}</v-card-text>
            <v-card-text class="body-2">
                {{$t('catalog.common.translation.localization.schema.description')}}
            </v-card-text>
             
            <c-i18n-editor 
                :items="i18n"
                :disabled="!hasEditRole"
                header-style="lite" 
                flat 
                @item-removed="removeI18n" 
                @item-updated="addI18n"/>
            <v-divider />
            <v-card-text />
            <v-card-text class="text-h6">{{$t('schema-editor.view.sections.attributes.title')}}</v-card-text>
            <v-card-text class="body-2">
                {{$t('schema-editor.view.sections.attributes.subtitle')}}
            </v-card-text>

                <dxs-attribute-edit-list 
                    v-model="attributes" 
                    header-style="lite" 
                    flat
                    :disabled="!hasEditRole" 
                    @item-updated = checkComputedAttributeUpdate
                />
            <v-card-text />
        </v-card>

        <v-dialog v-model="displayConfirmationDialog" width="420" >
            <v-card tile>
                <v-card-title>
                    {{$t('schema-editor.view.confirmationDialog.title')}}
                </v-card-title>
                <v-spacer/>
                <v-card-subtitle>
                    {{$t('schema-editor.view.confirmationDialog.subtitle')}}
                </v-card-subtitle>
                <v-card-text>
                    <dxs-warning-message>
                        {{$t('schema-editor.view.confirmationDialog.disclaimer')}}
                    </dxs-warning-message>  
                </v-card-text>
                
                <v-card-text v-for="item of collectionSettingsToReingest" :key="item" class="pl-5">
                    {{item}}
                </v-card-text>
                <v-card-actions class="text-right pa-5">
                    <v-btn text class="accent" @click="hideConfirmationDialog">{{$t('schema-editor.view.confirmationDialog.actions.confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

  </div>
</template>

<script>
const MODULE_NAME = 'schemaEditor';

import WarningMessageComponent from '@/components/messages/warning-message.component';
import Roles from '@/roles';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import AttributeEditorListComponent from '@/core/components/catalog/schema/attribute-edit-list.component';
import { get } from 'lodash';

export default {

    name: 'schema-editor',
    
    props: {
        
        value: {
            type: Object,
            required: false,
        },

        options: {
            type: Object,
            required: false
        }
    },

    components: {
        'dxs-attribute-edit-list' : AttributeEditorListComponent,
        'dxs-warning-message' : WarningMessageComponent
    },

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    data: () =>({
        showCollectionSettingsToReingest: [],

        displayConfirmationDialog : false,
        
        changedComputed: false
     }),

    methods: {
        async refresh() {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse", { name: this.name })
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async save() {
            
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/update", { name: this.name })
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
                this.postSave({
                    changedComputed: this.changedComputed,
                    schema: this.schema});
            }
        },

        async postSave({changedComputed, schema}) {
            try{
                if(changedComputed){
                    let schemaName = schema.name;
                    await this.$store.dispatch(MODULE_NAME + "/searchCollectionSettings", { schemaName: schemaName })
                    this.showConfirmationDialog(this.collectionSettingsToReingest);
                    this.changedComputed = false;
                }
            } catch (error) {
                this.errorHandlerService.handleError(error);                
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push( {
                name: 'schemaList'
            });
        },

        checkChangedComputed(){
            // DXS-673 Check if an computed attribute was changed (created and/or updated) 
            // to flag the schema for reingestion.
            if(!this.changedComputed){
               let attributes = get(this.request, 'attributes', {});
                for (let key in this.oldAttributes) {
                    let oldScript = get(this.oldAttributes[key], 'computation.script', "") ? get(this.oldAttributes[key], 'computation.script', "") : "";
                    let newScript = get(attributes[key], 'computation.script', "") ? get(attributes[key], 'computation.script', "") : "";
                    if (oldScript !== newScript){
                        this.changedComputed = true;
                    }
                } 
            }
        },

        checkComputedAttributeUpdate(attribute){
            // DXS-673 Check if an computed attribute was changed (created and/or updated) 
            // to flag the schema for reingestion.
            let computed = get(attribute, 'item.computed', null);
            let attributeKey = get(attribute, 'item.name', "");
            if(computed && !(attributeKey in this.oldAttributes)){
                this.changedComputed = true;
            }
        },

        showConfirmationDialog(collectionSettingsToReingest) {

            this.showCollectionSettingsToReingest = collectionSettingsToReingest;
            this.displayConfirmationDialog = true;
        },

        hideConfirmationDialog() {

            this.collectionSettingsToReingest = [];
            this.displayConfirmationDialog = false;
        },

        addI18n(event) {

            let temp = cloneDeep(this.i18n);
            temp[event.item.languageTag] = event.item;
            this.i18n = temp;
        },

        removeI18n(event) {

            let temp = omit(this.i18n, event.item.languageTag);
            this.i18n = temp;
        },
    },

    computed: {

        name: {

            get() {
                return this.value && this.value.name ? this.value.name : null;
            } 

            // No setter here - the name must not be changed after initial setting.
        },

        request() {
            return this.$store.getters[MODULE_NAME + '/request'];
        },
        
        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        data() {
            return this.response.data ? this.response.data : {};  
        },

        schema() {
            return this.data.schema ? this.data.schema : {};
        },

        collectionSettingsToReingest(){
            return this.$store.getters[MODULE_NAME + '/collectionSettingsToReingest'];
        },

        attributes: {
            
            get() { 
                return this.schema.attributes ? this.schema.attributes : {} 
            },

            set(value) {
                 this.$store
                        .dispatch(MODULE_NAME + "/updateAttributes", { attributes: value })
                        .then( () => this.checkChangedComputed())
                        .catch(this.errorHandlerService.handleError);
            }
        },

        oldAttributes() {
            return this.$store.getters[MODULE_NAME + '/oldAttributes'];
        },

        i18n: {

            get() {
                return this.schema.i18n ? this.schema.i18n : {};
            },
            
            set(value) {

                this.$store
                    .dispatch(MODULE_NAME + "/updateI18N", { i18n: value })
                    .catch(this.errorHandlerService.handleError);
            }
        },

        hasEditRole() {
            return this.userContextService.hasAllRoles(Roles.SCHEMAS_EDIT);
        },
    },

    created() {
        this.refresh();
    }

}
</script>

<i18n>
{
    "en" : {

        "schema-editor.view.sections.attributes.title" : "Attributes",
        "schema-editor.view.sections.attributes.subtitle" : "This card contains information related to the schema's attributes.",
        "schema-editor.view.confirmationDialog.title" : "Reingest Collections",
        "schema-editor.view.confirmationDialog.subtitle" : "At least one computed attribute was created or changed. In order to evaluate the computed attribute, the following collections need to be reingested: ",
        "schema-editor.view.confirmationDialog.disclaimer" : "Please note: the reindexing action must be triggered manually for each collection. The system will not apply reindexing automatically.",
        "schema-editor.view.confirmationDialog.actions.confirm" : "Confirm"
     },

    "de" : {

        "schema-editor.view.sections.attributes.title" : "Attribute",
        "schema-editor.view.sections.attributes.subtitle" : "Diese Karte enthält die Informationen zu den Attributen des Schemas.",
        "schema-editor.view.confirmationDialog.title" : "Datenbereiche neu laden",
        "schema-editor.view.confirmationDialog.subtitle" : "Mindestens ein berechnetes Attribut wurde erstellt oder geändert. Um geänderte berechnete Attribute neu evaluieren zu können, müssen folgende Datenbereiche neu geladen werden: ",
        "schema-editor.view.confirmationDialog.disclaimer" : "Bitte beachten Sie: Die Neuindizierung muss für jeden Datenbereich manuell ausgelöst werden. Das System wendet die Neuindizierung nicht automatisch an.",
        "schema-editor.view.confirmationDialog.actions.confirm" : "Bestätigen"
    }
}
</i18n>