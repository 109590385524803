<template>
    <v-card tile flat>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="4">
                    <v-sheet class="grey lighten-3 pa-3 text-body-2">
                        <v-chip color="grey" label>{{ $t('shift-event-log-filter-summary.component.filter.timeRange') }}</v-chip>
                        <span class="ml-3 font-italic">{{ dateTimeFromFormatted }} - {{ dateTimeUntilFormatted }}</span>
                    </v-sheet> 
                </v-col>
                <v-col cols="12" md="4">
                    <v-sheet class="grey lighten-3 pa-3 text-body-2">
                        <v-chip color="grey" label>{{ $t('shift-event-log-filter-summary.component.filter.workplaces') }}</v-chip>
                        <span class="ml-3 font-italic">{{ workplaceCommaSeparatedList }}</span>
                    </v-sheet>
                    
                </v-col>
                <v-col cols="12" md="4">
                    <v-sheet class="grey lighten-3 pa-3 text-body-2">
                        <v-chip color="grey" label>{{ $t('shift-event-log-filter-summary.component.filter.shiftEventTypes') }}</v-chip>
                        <span class="ml-3 font-italic">{{ shiftEventTypesCommaSeparatedList }}</span>
                    </v-sheet>
                   
                </v-col>
            </v-row>
        </v-container>       
    </v-card>
</template>

<script>
import { get, isEmpty } from 'lodash';

export default {

    props: {

        value: {
            type: Object,
            required: true,
        }
    },

    computed: {

        filter() {
            return get(this.value, 'filter', {});
        },

        dateTimeFrom() {
            return get(this.filter, 'dateTimeFrom', null);
        },

        dateTimeFromFormatted() {
            return this.$dxs.formatting.formatDateTime(this.dateTimeFrom, this.$i18n.locale);
        },

        dateTimeUntil() {
            return get(this.filter, 'dateTimeUntil', null);
        },

        dateTimeUntilFormatted() {
            return this.$dxs.formatting.formatDateTime(this.dateTimeUntil, this.$i18n.locale);
        },

        workplaces() {
            return get(this.filter, 'workplaces', []);
        },

        workplaceMap(){
            return get(this.value, 'workplaces', {})
        },

        workplaceCommaSeparatedList() {
            if (isEmpty(this.workplaces)) {
                return '*';
            } else {
                return `${this.workplaces.map(v => this.$dxs.i18n.localizedName(this.workplaceMap[v], this.$i18n.locale)).join('; ')}`;
            }
        },

        shiftEventTypes() {
            return get(this.filter, 'shiftEventTypes', []);
        },

        shiftEventTypeMap() {
            return get(this.value, 'shiftEventTypes', {})
        },  

        shiftEventTypesCommaSeparatedList() {

            if (isEmpty(this.shiftEventTypes)) {
                return '*';
            } else {
                return `${this.shiftEventTypes.map(v => this.$dxs.i18n.localizedName(this.shiftEventTypeMap[v], this.$i18n.locale)).join('; ')}`;
            }

           
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "shift-event-log-filter-summary.component.filter.timeRange": "Time Range",
        "shift-event-log-filter-summary.component.filter.workplaces": "Workplaces",
        "shift-event-log-filter-summary.component.filter.shiftEventTypes": "Shift Event Types"
    },

    "de" : {
        "shift-event-log-filter-summary.component.filter.timeRange": "Zeitraum",
        "shift-event-log-filter-summary.component.filter.workplaces": "Arbeitsplätze",
        "shift-event-log-filter-summary.component.filter.shiftEventTypes": "Schichtmeldungsarten"
    }
}
</i18n>