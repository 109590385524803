<template>
    
  <div>
    <c-section
      :title="$t('sites-list.view.title')"
      :subtitle="$t('sites-list.view.subtitle')"
      class="secondary"
    >
      <v-card class="mt-5 mb-5" tile>
        <c-item-list
          :title="$t('sites-list.view.toolbar.title')"
          :items="items"
          :pagination="pagination"
          @refresh-list="refresh"
          @previous-page="onPreviousPage"
          @next-page="onNextPage"
          @item-added="onItemAdded"
          @item-edited="onItemEdited"
          @item-deleted="onItemDeleted"
          @item-list-filter-applied="onFilterChanged"
          :disable-add-item="!hasCreateRole"
          :disable-edit-item="!hasEditRole && !hasReadRole"
          :disable-delete-item="!hasDeleteRole"
          supports-delete-item
        >

          <template
            v-slot:delete-dialog="{ item, onDeleteConfirmed, onDeleteCanceled }"
          >
            <v-card color="error pa-5">
              <v-card-text class="text-h4 white--text">{{
                $t("sites-list.view.dialogs.delete.title")
              }}</v-card-text>
              <v-card-text class="white--text">{{
                $t("sites-list.view.dialogs.delete.description", {
                  name: $dxs.i18n.localizedName(item, $i18n.locale),
                })
              }}</v-card-text>
              <v-card-actions>
                <v-btn @click="onDeleteConfirmed">{{
                  $t("sites-list.view.dialogs.delete.actions.confirm")
                }}</v-btn>
                <v-btn @click="onDeleteCanceled">{{
                  $t("sites-list.view.dialogs.delete.actions.cancel")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </c-item-list>
        <v-card-text></v-card-text>
        <v-divider />
        <v-subheader class="grey lighten-5">{{
          $t("sites-list.view.help.title")
        }}</v-subheader>
        <v-card-text class="grey lighten-5">{{
          $t("sites-list.view.help.description")
        }}</v-card-text>
      </v-card>
    </c-section>
  </div>
</template>

<script>
import Roles from "@/roles";
import { get } from "lodash";

import SitesListModule from "../../../store/masterdata/sites/sites-list.module";

const MODULE_ID = "sitesList";

export default {
  name: "sites-list",

  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "userContextService",
  ],

  data: () => ({
    collectionSetting: {},

    displayConfirmationDialog: false,

    displayDeleteDialog: false,
  }),

  computed: {
    response() {
      return this.$store.getters[MODULE_ID + "/response"];
    },

    items() {
      return this.response.items;
    },

    pagination() {
      return get(this.response, "pagination", {});
    },

    hasReadRole() {
      return this.userContextService.hasAllRoles(Roles.SITES_READ);
    },

    hasEditRole() {
      return this.userContextService.hasAllRoles(Roles.SITES_EDIT);
    },

    hasCreateRole() {
      return this.userContextService.hasAllRoles(Roles.SITES_CREATE);
    },

    hasDeleteRole() {
      return this.userContextService.hasAllRoles(Roles.SITES_DELETE);
    },
  },

  methods: {
    async refresh() {
      this.loadPage(0, 50);
    },

    async onNextPage({ from, pageSize }) {
      this.loadPage(from, pageSize);
    },

    async onPreviousPage({ from, pageSize }) {
      this.loadPage(from, pageSize);
    },

    async loadPage(from, pageSize) {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_ID + "/loadResponse", {
          from,
          pageSize,
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    onItemEdited(event) {
      if (event) {
        this.$router.push({
          name: "SiteEditorView",
          params: {
            name: event.item.name,
          },
        });
      } else {
        this.$log.debug(
          "Event object is undefined. Can't navigate to prediction scenario."
        );
      }
    },

    onItemAdded() {
      this.$router.push({ name: "SitesWizardView" });
    },

    async onItemDeleted({ item }) {
      this.progressIndicatorService.show();
      try {
        // Delete the item, then reload the list in order to reflect change.
        await this.$store.dispatch(MODULE_ID + "/deleteItem", item);
        this.refresh();
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    async onFilterChanged(event) {
      this.progressIndicatorService.show();
      try {
        await this.$store.dispatch(MODULE_ID + "/updateFilter", event.filter);
        await this.refresh();
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    }
  },

  created() {
    // Dynamically register the vuex store module for managing the data.
    if (!this.$store.hasModule(MODULE_ID)) {
      this.$store.registerModule(MODULE_ID, SitesListModule);
    }

    this.refresh();
  },
};
</script>

<i18n>
{
    "en" : {
        "sites-list.view.title" : "Site Management",
        "sites-list.view.subtitle" : "You may use this dialog for creating, editing or deleting sites.",
        "sites-list.view.toolbar.title" : "Sites",

        "sites-list.view.help.title" : "What are Sites?",
        "sites-list.view.help.description" : "Sites are production locations. Workplaces are typically assigned to a site. These are, among other things, key elements of a Shift Event.",

        "sites-list.view.dialogs.delete.title" : "Do you really want to delete this site?",
        "sites-list.view.dialogs.delete.description" : "You are about to delete the site {name}. Before deleting a site, all workplaces must be unlinked from this site.",
        "sites-list.view.dialogs.delete.actions.confirm" : "Confirm",
        "sites-list.view.dialogs.delete.actions.cancel" : "Cancel"


    },

    "de" : {
        "sites-list.view.title" : "Verwaltung Produktionsstandorte",
        "sites-list.view.subtitle" : "Sie können neue Produktionsstandorte anlegen, einen Produktionsstandort editieren oder löschen.",
        "sites-list.view.toolbar.title" : "Produktionsstandorte",

        "sites-list.view.help.title" : "Was sind Produktionsstandorte?",
        "sites-list.view.help.description" : "Arbeitsplätze können Produktionsstandorten zugewiesen werden. Produktionsstandorte sind ein wesentliches Element einer Schichtmeldung.",
        
        "sites-list.view.dialogs.delete.title" : "Möchten Sie den Produktionsstandort wirklich löschen?",
        "sites-list.view.dialogs.delete.description" : "Sie möchten den Produktionsstandort {name} löschen. Bevor ein Produktionsstandort gelöscht werden kann, müssen zunächst alle Arbeitsplätze getrennt werden.",
        "sites-list.view.dialogs.delete.actions.confirm" : "Bestätigen",
        "sites-list.view.dialogs.delete.actions.cancel" : "Abbrechen"

    }
}
</i18n>