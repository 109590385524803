
<template>
    <v-sheet>
        <c-section :title="$t('shift-event-log-list.view.title')" :subtitle="$t('shift-event-log-list.view.subtitle')" class="secondary darken-1">
            <v-container fluid>
                <v-row class="justify-center">
                    <v-col cols="12" md="8">
                        <v-card tile>
                            <v-toolbar flat>
                                <v-toolbar-title>{{ $t('shift-event-log-list.view.list.title') }}</v-toolbar-title>
                                <v-spacer />
                                <v-toolbar-items>
                                    <v-btn icon @click="refresh"><v-icon>refresh</v-icon></v-btn>
                                </v-toolbar-items>
                            </v-toolbar>
                            <v-divider />
                            <v-list>
                                <v-list-item v-for="(item, index) of sites" :key="`list-item-shiftevent-${index}`">
                                    <v-list-item-action>
                                        <v-btn icon color="accent" @click="openShiftEventLogDashboard(item)"><v-icon>note_alt</v-icon></v-btn>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ $t('shift-event-log-list.view.list.item.title', {name: $dxs.i18n.localizedName(item, $i18n.name)})}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ $t('shift-event-log-list.view.list.item.subtitle', {name: $dxs.i18n.localizedName(item, $i18n.name)})}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="accent" @click="openShiftEventLogWizard(item)"><v-icon>add</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            
        </c-section>
    </v-sheet>
</template>

<script>
import { get } from 'lodash';
import ShiftEventLogListModule from '@/solutions/smart-factory-services/store/apps/shift-event-log/shift-event-log-list.module';

const MODULE_ID = "smart-factory-services:apps:shift-event-log:shift-event-log.list";

export default {
    
    name: 'shift-event-log-list',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],


    computed: {

        model() {
            return this.$store.getters[MODULE_ID + '/model'];
        },

        sites() {
            return get(this.model, 'sites', []);
        }
    },

    methods: {
        
        async refresh() {
            this.progressIndicatorService.show();
            
            try {
                this.$log.debug(`Loading sites for displaying shift event logs`);
                await this.$store.dispatch(`${MODULE_ID}/loadSites`)
            
            } catch (error) {
                this.errorHandlerService.handleError(error);
            
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        openShiftEventLogDashboard({name}) {
            this.$log.debug(`Opening shift event log dashboard for site ${name}.`);
            this.$router.push({
                name: "ShiftEventLogDashboardView",
                params: {
                    name: name,
                },
            });
        },

        openShiftEventLogWizard({name}) {
            this.$log.debug(`Opening shift event log wizard for site ${name}.`);
            this.$router.push({
                name: "ShiftEventLogWizardView",
                params: {
                    siteId: name,
                },
            });
        }
    },

    // ==================================================================
    // HOOKS
    // ==================================================================
    created() {
            
        // Dynamically register the vuex store module for managing the data.
        if (!this.$store.hasModule(MODULE_ID)){
            this.$store.registerModule(MODULE_ID, ShiftEventLogListModule);
        }

        // Load initial data
        this.refresh();
    }

}
</script>

<i18n>
{
    "en" : {
        "shift-event-log-list.view.title" : "Shift Event Log",
        "shift-event-log-list.view.subtitle" : "Please click on a site in order to open its shift event log.",
        "shift-event-log-list.view.actions.open" : "Open Shift Log",


        "shift-event-log-list.view.list.title" : "Shift Event Logs",
        "shift-event-log-list.view.list.item.title" : "Shift Event Log {name}",
        "shift-event-log-list.view.list.item.subtitle" : "Contains shift events for site {name}."
    },

    "de" : {
        "shift-event-log-list.view.title" : "Schichtbuch",
        "shift-event-log-list.view.subtitle" : "Klicken Sie auf einen Standort, um das zugehörige Schichtbuch zu öffnen.",
        "shift-event-log-list.view.actions.open" : "Schichtbuch öffnen",
        
        
        "shift-event-log-list.view.list.title" : "Schichtbücher",
        "shift-event-log-list.view.list.item.title" : "Schichtbuch {name}",
        "shift-event-log-list.view.list.item.subtitle" : "Enthält die gesammelten Schichtmeldungen für den Standort {name}."
    }
}
</i18n>