<template>
  <v-container fluid class="secondary pt-10 pb-10">
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <c-base-dialog
          :value="model"
          :title="$t('shift-event-log-wizard.view.title')"
          :subtitle="$t('shift-event-log-wizard.view.subtitle')"
          color="secondary"
          @dialog-saved="save"
          @dialog-canceled="cancel"
        >
          <template v-slot:default>
            <v-card flat>
                <v-card-text>
                    <v-text-field :disabled="true" :label="$t('shift-event-log-wizard.view.fields.site.label')" :value="$dxs.i18n.localizedName(site)" filled />
                    
                    <c-item-selector 
                        :label="$t('shift-event-log-wizard.view.fields.shiftEventType.label')" 
                        v-model="shiftEventType"
                        :items="shiftEventTypes"
                        :rules="[  v => v && !!v.trim()  || $t('shift-event-log-wizard.view.fields.shiftEventType.validation.notEmpty') ]"
                        filled 
                        />
                    <c-item-selector 
                        :label="$t('shift-event-log-wizard.view.fields.workplace.label')" 
                        v-model="workplace"
                        :items="workplaces" 
                        filled 
                        />
                    <c-date-time-selector 
                        :label="$t('shift-event-log-wizard.view.fields.dateTime.label')" 
                        v-model="dateTime"
                        :rules="[  v => v && !!v.trim()  || $t('shift-event-log-wizard.view.fields.dateTime.validation.notEmpty') ]"
                        filled />       
                </v-card-text>
                <v-card-text>
                    <v-textarea 
                        :label="$t('shift-event-log-wizard.view.fields.description.label')" 
                        v-model="description"
                        :rules="[  v => v && !!v.trim()  || $t('shift-event-log-wizard.view.fields.description.validation.notEmpty') ]"
                        filled />
                </v-card-text>
            </v-card>
          </template>
        </c-base-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get } from 'lodash';
//import { DateTime } from 'luxon';
import ShiftEventLogWizardModule from '@/solutions/smart-factory-services/store/apps/shift-event-log/shift-event-log-wizard.module';
const MODULE_NAME =  'smart-factory-services:apps:shift-event-log:shift-event-log-wizard';

export default {
    
    name: 'shift-event-log-wizard',


    inject: [
        "errorHandlerService",
        "progressIndicatorService",
        "envContextService",
    ],

    props: {
        
        value: {
            type: Object,
            required: true
        }
    },

    computed: {

        siteId() {
            return get(this.value, 'name', null);
        },

        model: {
            get() {
                return this.$store.getters[`${MODULE_NAME}/model`]; 
            }
        },

        site() {
            return get(this.model, 'site', {name: this.siteId});
        },

        shiftEventTypes() {
            return get(this.model, 'shiftEventTypes', [])
        },

        workplaces() {
            return get(this.model, 'workplaces', [])
        },

        // =============================================================
        // Binding to VUEX
        // =============================================================

        item: {
            get() {
                return this.$store.getters[`${MODULE_NAME}/item`];
            }
        },

        shiftEventType: {
            get() {
                return this.item.shiftEventTypeId;
            },

            set(shiftEventType) {
                this.$log.debug(`Updated shift event type to '${shiftEventType}'.'`);
                this.$store.dispatch(`${MODULE_NAME}/updateShiftEventTypeId`, shiftEventType);
            }
        },

        workplace: {
            get() {
                return this.item.workplaceId;
            },

            set(workplace) {
                this.$log.debug(`Updated workplace to '${workplace}'.'`);
                this.$store.dispatch(`${MODULE_NAME}/updateWorkplaceId`, workplace);
            }
        },

        dateTime: {
            
            get() {
                return this.item.dateTime;
            },

            set(dateTime) {
                this.$log.debug(`Updated shift event type to '${dateTime}'.`);
                this.$store.dispatch(`${MODULE_NAME}/updateDateTime`, dateTime);
            }
        },

        description: {
            
            get() {
                return this.item.description;
            },

            set(description) {
                this.$log.debug(`Updated shift event type to '${description}'.`);
                this.$store.dispatch(`${MODULE_NAME}/updateDescription`, description);
            }
        }

    },

    methods: {

        async loadData() {

            const siteId = get(this.value, 'name', null);
            if (siteId) {
                this.$log.debug(`Loading required data from the backend for creating a new shift event for site ${siteId}.`);

                try {
                    await this.$store.dispatch(MODULE_NAME + '/loadData', { siteId });

                } catch (error) {
                    this.errorHandlerService.handleError(error);
                } finally {
                    this.progressIndicatorService.hide();
                }
            } else {
                this.$log.warn(`No value found siteId: Not loading data from backend.`)
            }
            
        },

        async save() {
            
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/saveData');

                // After the call returned successfully, we need to reset our 
                // data model so old values will not linger in the forms on next call.
                this.$store.dispatch(MODULE_NAME + '/resetData');

                // Now redirect back to the shift event
                this.$router.push({
                    name: 'ShiftEventLogDashboardView',
                    params: { name: this.siteId },
                });

                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }

             
        },

        cancel() {
            
            this.$store.dispatch(MODULE_NAME + '/resetData');

            this.$router.go(-1);
        }
    },

    // ==================================================================
    // HOOKS
    // ==================================================================
    created() {
            
        // Dynamically register the vuex store module for managing the data.
        if (!this.$store.hasModule(MODULE_NAME)){
            this.$store.registerModule(MODULE_NAME, ShiftEventLogWizardModule);
        }

        // Load initial data
        this.loadData();
    }
}
</script>

<i18n>
{
    "en" : {
        "shift-event-log-wizard.view.title" : "Create New Shift Event",
        "shift-event-log-wizard.view.subtitle" : "This wizard will guide you through the required steps for creating a new shift event.",

        "shift-event-log-wizard.view.fields.site.label" : "Site",
        "shift-event-log-wizard.view.fields.dateTime.label" : "Date and Time",
        "shift-event-log-wizard.view.fields.dateTime.validation.notEmpty" : "Date and Time must not be empty.",
        "shift-event-log-wizard.view.fields.shiftEventType.label" : "Shift Event Type",
        "shift-event-log-wizard.view.fields.shiftEventType.validation.notEmpty" : "Shift Event Type must not be empty.",
        "shift-event-log-wizard.view.fields.workplace.label" : "Workplace (Optional)",
        "shift-event-log-wizard.view.fields.description.label" : "Description",
        "shift-event-log-wizard.view.fields.description.validation.notEmpty" : "Description must not be empty"
    },

    "de" : {
        "shift-event-log-wizard.view.title" : "Neue Schichtmeldung erfassen",
        "shift-event-log-wizard.view.subtitle" : "Dieser Assistent führt Sie durch die notwendigen Schritte, um den Schichtbuch eine neue Schichtmeldung hinzuzufügen.",
        "shift-event-log-wizard.view.fields.site.label" : "Produktionsstandort",
        "shift-event-log-wizard.view.fields.dateTime.label" : "Zeitpunkt",
        "shift-event-log-wizard.view.fields.dateTime.validation.notEmpty" : "Der Zeitpunkt darf nicht leer sein.",
        "shift-event-log-wizard.view.fields.shiftEventType.label" : "Schichtmeldungsart",
        "shift-event-log-wizard.view.fields.shiftEventType.validation.notEmpty" : "Die Schichtmeldungsart darf nicht leer sein.",
        "shift-event-log-wizard.view.fields.workplace.label" : "Arbeitsplatz (Optional)",
        "shift-event-log-wizard.view.fields.description.label" : "Beschreibung",
        "shift-event-log-wizard.view.fields.description.validation.notEmpty" : "Die Beschreibung darf nicht leer sein."  
    }
}
</i18n>