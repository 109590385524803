<template>
  <v-card flat tile>
      <v-toolbar flat>
          <v-toolbar-items>
              <v-btn icon @click="cancel"><v-icon>arrow_back</v-icon></v-btn>
          </v-toolbar-items>
          <v-toolbar-title>{{name}}: Open API</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
              <v-btn icon @click="copyToClipboard"><v-icon>content_copy</v-icon></v-btn>
          </v-toolbar-items>
      </v-toolbar>
      <v-divider />
      
      <v-card-text>
             <v-tabs  background-color="white" slider-color="grey" color="grey" v-model="selectedTab">
                    <v-tab :key="0">YAML</v-tab>
                    <v-tab :key="1">JSON</v-tab>
                </v-tabs>

                <v-tabs-items v-model="selectedTab">
                    <v-tab-item :key="0">
                        <v-sheet class="ma-5 grey lighten-3">
                            <dxs-code-highlight language="yaml">
                            <pre>{{openApiYaml}}</pre>
                            </dxs-code-highlight>
                        </v-sheet>
                    </v-tab-item>
                    <v-tab-item :key="1">
                        <v-sheet class="ma-5 grey lighten-3">
                            <dxs-code-highlight language="javascript">
                            <pre>{{openApiJson}}</pre>
                            </dxs-code-highlight>
                        </v-sheet>
                    </v-tab-item>
                </v-tabs-items>
            

      </v-card-text>
  </v-card>
</template>

<script>
const MODULE_NAME = 'collectionSettingsApi';

import { get } from 'lodash';
import yaml from 'js-yaml';
import { component as VueCodeHighlightComponent } from 'vue-code-highlight';

//import 'vue-code-highlight/themes/duotone-sea.css';
//import 'vue-code-highlight/themes/prism-solarizedlight.css';
import 'prism-es6/components/prism-markup-templating';
import 'prism-es6/components/prism-yaml';


export default {

    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data() {
        
        return {
            selectedTab: 0
        };
    },

    inject: ['errorHandlerService', 'progressIndicatorService', 'envContextService'],

    components: {
        'dxs-code-highlight' : VueCodeHighlightComponent
    },


    computed: {

        name: {
            get() {
                return this.value ? this.value.name : null
            }
        },

        tenantId: {
            get() {
                return this.envContextService.tenantId;
            }
        },

        apiGatewayHostPort: {
            get() {
                return this.envContextService.apiGatewayHostPort;
            }
        },

        queryItemsUrl() {
            return this.apiGatewayHostPort + '/v1/' + this.tenantId + '/collections/' + this.name;
        },

        response: {
            get() {
                return this.$store.getters[MODULE_NAME + "/response"];
            }
        },

        data: {
            get() {
               return this.response.data ? this.response.data : {}; 
            }
        },

        collectionSettings: {
            get() {
                return this.data.collectionSettings ? this.data.collectionSettings : {}
            }
        },

        schema: {
            get() {
                return this.data.schema ? this.data.schema : {};
            }
        },

        attributeList() {

            const attributeList = [];
            if (this.schema.attributes) {

                for (const name in this.schema.attributes) {
                    attributeList.push(this.schema.attributes[name]);
                }
            }
            return attributeList;
        },

        sortAttributeNames() {

            return this.attributeList.map(e => e.name);
        },

        rangeFilterAttributeNames() {
            return this.attributeList.filter(e => e.datatype !== 'STRING' && e.datatype !== 'BOOLEAN').map(e => e.name);
        },

        facetFilterAttributeNames() {
             return this.attributeList.filter(e => e.datatype === 'STRING' || e.datatype === 'BOOLEAN').map(e => e.name);
        },

        openApiJson() {

            const tenantId = this.tenantId;
            const collectionId = this.name;

            let attributes = {

                id: {
                    type: 'string',
                    description: 'The unique ID of the given document. The id is constructured according to the following pattern: ' + get(this.collectionSettings, 'idTemplate'),
                    nullable: false,
                    readOnly: true
                },

                partitionId: {
                    type: 'string',
                    description: 'The ID of the partition where the given document is stored. The partition id is constructured according to the following pattern: ' + get(this.collectionSettings, 'partitionIdTemplate')
                },

                i18n: {
                    type: 'object',
                    properties: {

                        en : {
                            type: 'object'
                        },

                        de : {
                            type: 'object'
                        }
                    }
                },

                creationDateTime: {
                    type: 'string',
                    description: 'The date and time when this document has been initially created.',
                    nullable: false,
                    readOnly: true
                },

                creationUserId : {
                    type: 'string',
                    description: 'he id of the user who has initially created this document.',
                    nullable: false,
                    readOnly: true
                },

                modificationDateTime: {
                    type: 'string',
                    description: 'The date and time when this document has been modified for the last time.',
                    nullable: false,
                    readOnly: true
                },

                modificationUserId: {
                    type: 'string',
                    description: 'The id of the user who has modified this document for the last time.',
                    nullable: false,
                    readOnly: true
                },

                data: {

                    type: 'object',
                    properties: {


                    }
                }
            };



            for (const attribute of this.attributeList) {

                let description = this.$dxs.i18n.localizedName(attribute, 'en') + ': ' +this.$dxs.i18n.localizedDescription(attribute, 'en');
                

                attributes.data.properties[attribute.name] = {
                    description: description,
                    type: this.getOpenApiDatatype(attribute),
                    nullable: attribute.optional,
                    example: this.getOpenApiExampleValue(attribute),
                    readOnly: attribute.computed

                };

            } 

            // PATHS ====================================== 
            const paths = {}

            paths[`/v1/${tenantId}/collections/${collectionId}/query`] = {

                post: {
                    description: 'Retrieves a list of documents in the collection. Filtering, sorting and pagination can be applied.',
                    requestBody: {
                        description: '',
                        required: true,
                        content: {
                            'application/json' :{
                                schema: {
                                    '$ref' : '#/components/schemas/DocumentListRequest'
                                }
                            }
                        }

                    },
                    responses: {
                        '200' : {
                            description: '',
                            content: {
                            'application/json' :{
                                schema: {
                                    '$ref' : '#/components/schemas/DocumentListResponse'
                                }
                            }
                        }
                            
                        },

                        '403' : {
                            description: 'Access Denied - This status code implies that the caller has no sufficient permission for reading the content of the collection'
                        },

                        '500' : {
                            description: 'Internal Server Error - This status code implies that the requested operation has failed due to an application error. In that case please ask the system administrator to check the logs for further details.'
                        }
                    }

                },


            };

            

            return {

                openapi: '3.0.2',
                
                info: {
                    title: 'amentis DXS API',
                    description: 'Public API for consuming contents from the collection',
                    version: '1.0.35'
                },
                
                servers: [ 
                    { 'url' : this.apiGatewayHostPort} 
                ],
                
                paths: paths,

                components: {

                    schemas: {

                        DocumentListRequest: {
                            
                            type: 'object',
                            properties: { 

                                filter: {
                                    '$ref' : '#/components/schemas/Filter',
                                    description: 'The filter settings to be applied when querying the document list. Applying filters will potentially restrict the result set.',
                                    nullable: false,
                                },

                                sort: {
                                    '$ref' : '#/components/schemas/Sort',
                                    description: 'The sort settings to be applied when sorting the documents in the result set.',
                                    nullable: false,
                                },

                                pagination: {
                                    '$ref' : '#/components/schemas/Pagination',
                                    description: 'The pagination settings to be applied when loading the documents in the result set: Only the given portion of documents will be transmitted to the client.',
                                    nullable: false,
                                },

                                queryTimeoutMiliseconds: {
                                    type: 'integer',
                                    description: 'Defines the query timeout in miliseconds.',
                                    example: 25000
                                }

                            }
                        },

                        DocumentListResponse: {

                            type: 'object',
                            properties: { 
                                tenantId: {
                                    type: 'string',
                                    description: 'The unique ID of the selected tenant.',
                                    example: `${this.tenantId}`
                                },

                                collectionId: {
                                    type: 'string',
                                    description: 'The unique ID of the selected collection.',
                                    example: `${this.collectionId}`
                                },

                                meta: {
                                    type: 'object',

                                },

                                items: {
                                    type: 'array',
                                    items:  { '$ref' : '#/components/schemas/Document' }
                                },

                                pagination: {

                                }
                            }
                        },

                        Document: {
                            type: 'object',
                            properties: attributes
                        },

                        Filter: {
                            type: 'object',
                            description: 'Defines filter criteria for documents. Only documents which satisfy the filter criteria will be contained in the resulting response.',
                            properties : {
                                
                                filterQuery: {
                                    type: 'string',
                                },

                                filterQueryLanguage: {
                                    type: 'string',
                                    enum: ['SIMPLE', 'LUCENE'],

                                },

                                rangeFilters: {
                                    type: 'array',
                                    items: {
                                        '$ref' : '#/components/schemas/RangeFilterCriterion'
                                    }
                                },

                                facetFilters: {
                                    type: 'array',
                                    items: {
                                        '$ref' : '#/components/schemas/FacetFilterCriterion'
                                    }
                                }
                            }

                        }, 

                        RangeFilterCriterion: {

                            type: 'object',
                            properties: {

                                name: {
                                    type: 'string',
                                    description: 'The name of the attribute to be sorted',
                                    nullable: false,
                                    enum: this.rangeFilterAttributeNames
                                },

                                type: {
                                    type: 'string',
                                    description: 'The type of attribute to apply this range filter',
                                    nullable: false,
                                    enum: ['NUMERIC', 'TEMPORAL'],
                                },

                                from: {
                                    oneOf: [ { type: 'number'}, {type: 'string'}],
                                    description: 'The from value of the range (included). In case of NUMERIC type  provide the value as a number, please. In case of TEMPORAL use ISO-formatted date strings instead.',
                                    nullable: false
                                },

                                until: {
                                    oneOf: [ { type: 'number'}, {type: 'string'}],
                                    description: 'The until value of the range (included). In case of NUMERIC type  provide the value as a number, please. In case of TEMPORAL use ISO-formatted date strings instead.',
                                    nullable: false
                                },

                                filterMode: {
                                    type: 'string',
                                    description: 'The filter mode: Can be either inclusive (CONTAINS) or exclusive (NOT_CONTAINS)',
                                    nullable: false,
                                    enum: ['CONTAINS', 'NOT_CONTAINS']
                                }

                               
                            }
                        },

                        FacetFilterCriterion: {

                            type: 'object',
                            properties: {

                                name: {
                                    type: 'string',
                                    description: 'The name of the attribute to be sorted',
                                    nullable: false,
                                    enum: this.facetFilterAttributeNames
                                },

                                filterMode: {
                                    type: 'string',
                                    description: 'The filter mode: Can be either inclusive (CONTAINS) or exclusive (NOT_CONTAINS)',
                                    nullable: false,
                                    enum: ['CONTAINS', 'NOT_CONTAINS']
                                },

                                values:  {
                                    type: 'array',
                                    description: 'A list of string values to be queried for. Values may include wildcards (*).',
                                    nullable: false,
                                    items: {
                                        type: 'string'
                                    }
 
                                },

                                type: {
                                    type: 'string',
                                    description: 'The type of attribute to apply this facet filter',
                                    nullable: false,
                                    enum: ['TEXT'],
                                },

                            }
                        },



                        Sort: {
                            type: 'object',
                            description: 'Defines sort criteria for documents. Documents in the resulting response will be ordered according to the given sorting criteria.',
                            properties: {

                                sortCriteria: {
                                    type: 'array',
                                    items: {
                                        '$ref' : '#/components/schemas/SortCriterion'
                                    }
                                }
                            }
                        },

                        SortCriterion: {

                            type: 'object',
                            properties: {

                                name: {
                                    type: 'string',
                                    description: 'The name of the attribute to be sorted',
                                    nullable: false,
                                    enum: this.sortAttributeNames
                                },

                                ordering: {
                                    type: 'string',
                                    description: 'The name of the attribute to be sorted',
                                    nullable: false,
                                    enum: ['ASC', 'DESC']
                                }
                            }
                        },

                        Pagination: {

                            type: 'object',
                            properties: {

                                size: {
                                    type: 'integer',
                                    description: 'The number of documents to load on a single request',
                                    nullable: false,
                                    example: 50
                                },

                                searchAfter: {
                                    type: 'array',
                                    items: {
                                        type: 'string'
                                    },
                                    description: 'An array of attribute keys which identfiy the last item in the set',
                                    nullable: false,
                                }
                            }
                        }
                    }

                }


                    
            }
        },

        openApiYaml() {

            return yaml.dump(this.openApiJson);
        },

    },

    methods: {

        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadResponse", { name: this.name })
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        cancel() {
            this.$router.push({ 
                name: 'collectionSettingsList',
            });
        },

        copyToClipboard() {

            if (navigator.clipboard) {
                navigator.clipboard.writeText(this.openApiYaml);
            } 
        },

        getOpenApiDatatype(attribute) {

            const type = get(attribute, 'datatype', 'undefined');

            if (type === 'STRING') return 'string';
            else if (type === 'INTEGER') return 'integer'; 
            else if (type === 'DECIMAL') return 'number'; 
            else if (type === 'DATE') return 'string'; 
            else if (type === 'DATETIME') return 'string'; 
            else if (type === 'BOOLEAN') return 'boolean'; 
            else throw new Error('Each property must have a datatype ' + JSON.stringify(attribute));
        },

        getOpenApiExampleValue(attribute) {

            const type = get(attribute, 'datatype', 'undefined');

            if (type === 'STRING') return 'HelloWorld123Id!';
            else if (type === 'INTEGER') return '45.0'; 
            else if (type === 'DECIMAL') return '45.873'; 
            else if (type === 'DATE') return '2022-06-27'; 
            else if (type === 'DATETIME') return '2022-06-27T16:04:32+02:00'; 
            else if (type === 'BOOLEAN') return 'true'; 
            else throw new Error('Each property must have a datatype ' + JSON.stringify(attribute));
        }
        
    },

    created() {
        this.refresh();
    }
}
</script>

<style>

</style>