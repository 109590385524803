<template>
    <div>
        <c-sticky-content>
                <v-toolbar color="secondary darken-1" flat >
                    <v-toolbar-items><v-btn icon @click="close"><v-icon>close</v-icon></v-btn></v-toolbar-items>
                    <v-breadcrumbs>
                        <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false">{{$t('collection-view.breadcrumbs.root')}}</v-breadcrumbs-item>
                        <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                        <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false" v-if="hasReadPermission">
                            {{collectionSettings | localizedName($i18n.locale)}}
                        </v-breadcrumbs-item>
                        <v-breadcrumbs-item :to="{ name: 'collectionList' }" v-else>
                            {{$t("document-view.collectionNotFound.breadcrumb")}}
                        </v-breadcrumbs-item>  
                        <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                        <v-breadcrumbs-item v-if="hasReadPermission && documentFound">
                            {{document | localizedName($i18n.locale)}}
                        </v-breadcrumbs-item>      
                        <v-breadcrumbs-item :to="{ name: 'collectionList' }" v-else>
                            {{$t("document-view.notFound.breadcrumb")}}
                        </v-breadcrumbs-item> 
                    </v-breadcrumbs>
                    <v-spacer />
                    <v-toolbar-items v-if="hasReadPermission && documentFound">
                        <v-btn icon><v-icon @click="refresh">refresh</v-icon></v-btn>
                        <v-btn :disabled="!hasDeletePermission" @click="onDeleteClicked" icon><v-icon >delete</v-icon></v-btn>
                        <v-btn icon><v-icon @click="openChangeLog">history</v-icon></v-btn>
                    </v-toolbar-items>
                </v-toolbar>
            </c-sticky-content>
        <dxs-document-view :value="response" v-if="hasReadPermission && documentFound" />

        <!-- ====================================================== -->
        <!-- DOCUMENT NOT FOUND -->
        <!-- ====================================================== -->
        <template v-else>
            <c-section class="secondary" >
                <v-container fluid>
                    <v-row justify="center">
                        <v-col cols="12" lg="9">
                            <v-card tile>
                                <v-card-text class="text-h6 text-center">
                                    {{$t('document.view.notFound.title')}}
                                </v-card-text>
                                <v-card-text class="text-center">
                                    <v-icon x-large>sentiment_very_dissatisfied</v-icon>
                                </v-card-text>
                                <v-card-text class="text-center">
                                    {{$t('document.view.notFound.description')}}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </c-section>
        </template>

        <!-- ====================================================== -->
        <!-- DELETE DIALOG -->
        <!-- ====================================================== -->
        <v-dialog v-model="showDocumentDeleteDialog" width="480">
            <v-card tile>
                <v-toolbar flat>
                    <v-toolbar-title>{{$t('document.view.dialogs.delete.title')}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-5">
                  {{$t('document.view.dialogs.delete.description')}}
                </v-card-text>
                <v-card-actions class="text-right">
                    <v-btn @click="onConfirmDeletion" text color="error">{{$t('document.view.dialogs.actions.confirm')}}</v-btn>
                    <v-btn @click="onCancelDeletion" text color="grey" >{{$t('document.view.dialogs.actions.cancel')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
const MODULE_NAME = 'documentDetail';

// import _isEmpty from 'lodash/isEmpty';
import DocumentViewComponent from '@/core/components/document/document-view.component';
import { get, isEmpty } from 'lodash';

export default {

    name: 'document-detail-page',
    
    components : {
        'dxs-document-view' : DocumentViewComponent
    },
    
    props: {
        
        value: {
            type: Object,
            required: false,
        },

        options: {
            type: Object,
            required: false
        }
    },

    data() {

        return {
            showDocumentDeleteDialog : false,
            selectedIdForDeletion : null
        };
    },

    inject: ['progressIndicatorService', 'envContextService', 'userContextService', 'collectionPermissionService'],

    computed: {

        attributeTableDisplayType() {
            return this.$vuetify.breakpoint.smAndDown ? 'list' : 'table';
        },

        response() {
            let response = this.$store.getters[MODULE_NAME + '/response'];
            return response;
        },   
        collectionSettings() {
            return this.response.collectionSettings ? this.response.collectionSettings : {};
        },

        document() {

            return this.response.document ? this.response.document : {};
        },

        collectionId() {
            return get(this.value, 'collectionId', null);
        },

        hasDeletePermission() {
            return this.collectionPermissionService.hasDeletePermission({collectionId: this.collectionId});
        },

        hasReadPermission() {

            // System collections are authorized by means of functional roles.
            return this.collectionSettings.systemCollection || this.collectionPermissionService.hasReadPermission({collectionId: this.collectionId});
        },

        documentFound() {
            return !isEmpty(this.document);
        }
             
    },
    methods: {
        async refresh() {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData', {
                   
                    collectionId : this.value.collectionId,
                    documentId: this.value.documentId,
                });
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
        close() {
             if (window) {
                 window.close();
             }
             
         },

        onDeleteClicked() {

            this.showDocumentDeleteDialog = true;
        },

        async onConfirmDeletion() {

            this.showDocumentDeleteDialog = false;
            await this.deleteDocument();

            this.$router.go(-1);
   
        },

        onCancelDeletion() {
            this.showDocumentDeleteDialog = false;
            
        },


        async deleteDocument() {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/deleteDocument', {
                   
                    collectionId : this.value.collectionId,
                    documentId: this.value.documentId,
                });
                
             
                this.openChangeLog()
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
         },

         openChangeLog() {
             this.$router.push({
                name: 'documentChangeLogView',
                params: {
                    value: {collectionId: this.value.collectionId, documentId: this.value.documentId}
                }
            });
         }
    },

    created() {
        
        // Refresh will trigger loading of the collection settings information.
        this.refresh();
    },

    
}
</script>

<i18n>
{   
    "en" : {

        "document.view.dialogs.delete.title" : "Confirm Deletion",
        "document.view.dialogs.actions.confirm" : "Confirm",
        "document.view.dialogs.actions.cancel" : "Cancel",
        "document.view.dialogs.delete.description" : "Please confirm this operation in order to delete the document from the system permanently. Please note that a deletion is final and cannot be undone.",
        
        "document.view.notFound.title" :  "Document Not Found. We are sorry...",
        "document.view.notFound.description" : "Sorry, but we cannot find the document you are looking for. Please contact your system support for further details and help.",
    
        "document-view.collectionNotFound.breadcrumb" : "Collection Not Found",
        "document-view.notFound.breadcrumb" : "Document Not Found"
    },

    "de" : {
        "document.view.dialogs.delete.title" : "Löschen bestätigen",
        "document.views.dialogs.actions.confirm" : "Bestätigen",
        "document.view.dialogs.actions.cancel" : "Abbrechen",
        "document.view.dialogs.delete.description" : "Bitte bestätigen Sie den Vorgang, um das Dokument dauerhaft aus dem  System zu löschen. Bitte beachten Sie, dass eine Löschung endgültig ist und nicht rückgängig gemacht werden kann.",

        "document.view.notFound.title" : "Das Dokument wurde leider nicht gefunden",
        "document.view.notFound.description" : "Es tut uns leid, aber wir können das von Ihnen gesuchte Dokument nicht finden. Bitte kontaktieren Sie Ihren Systembetreuer für weitergehende Unterstützung.",
    
        "document-view.collectionNotFound.breadcrumb" : "Datenbereich nicht gefunden",
        "document-view.notFound.breadcrumb" : "Dokument nicht gefunden"
    }
}
</i18n>

