<template>
    <v-sheet>
        <c-sticky-content>
            <!-- *********************************************************************** -->
            <!-- FILTER INPUT                                                            -->
            <!-- *********************************************************************** -->
            
            <!-- *********************************************************************** -->
            <!-- TOOLBAR                                                                 -->
            <!-- *********************************************************************** -->
            <v-container fluid class="grey lighten-4"> 
                
                <v-row justify="center" >
                    <v-col :cols="12">
                        <c-filter-query-input :value="{ query: filter.filterQuery }" :disabled="loading" :loading="loading" @filter-query-changed="onFilterQueryChange" />
                    </v-col>  
                </v-row>
            </v-container>

            <v-divider />

            <v-toolbar flat color="white">
                <v-toolbar-title>{{$t('collection-series.view.results', {totalItemCount: totalItemCountFormatted})}}</v-toolbar-title>
                <v-btn icon @click="toogleQuerySummary">
                    <v-icon v-if="!querySummary">expand_more</v-icon>
                    <v-icon v-else>expand_less</v-icon>
                </v-btn>
                <v-spacer />
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon @click="getSeriesData">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-btn icon @click="filterDialog = !filterDialog">
                        <v-icon>filter_list</v-icon>
                    </v-btn>

                    <v-menu top>
                        <template v-slot:activator="{ on }">
                            <v-btn :disabled="!hasDataSeries && !formSent" icon v-on="on">
                                <v-icon>print</v-icon>
                            </v-btn>
                        </template>

                        <v-list>                            
                            <v-list-item @click="goToPrintView('ALL')">
                                <v-list-item-icon><v-icon>print</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('collection-series.view.print.diagramTable')}}
                                    <v-list-item-subtitle v-if="series.length > 5" class="red--text">
                                        {{$t('collection-series-view.max-data-series.hint')}}
                                    </v-list-item-subtitle>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="goToPrintView('DIAGRAMM')">
                                <v-list-item-icon><v-icon>print</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('collection-series.view.print.diagram')}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu top>
                        <template v-slot:activator="{ on }">
                            <v-btn :disabled="!hasDataSeries && !formSent" icon v-on="on">
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="exportDocument('CSV')">
                                <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('collection-documents.view.actions.export.csv', $i18n.locale)}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportDocument('JSON')">
                                <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('collection-documents.view.actions.export.json', $i18n.locale)}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <!--
                    <v-menu top :close-on-click="closeOnClick">
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon v-if="isChartTypeColumn()">insert_chart</v-icon>
                                <v-icon v-if="isChartTypeArea()">insert_photo</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="setChartTypeColumn">
                                <v-list-item-icon><v-icon>insert_chart</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('collection-series.view.chartStyle.columnChart')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="setChartTypeArea">
                                <v-list-item-icon><v-icon>insert_photo</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('collection-series.view.chartStyle.areaChart')}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    -->

                </v-toolbar-items>

                
            </v-toolbar>
            
            <v-divider />
        </c-sticky-content>

        <template v-if="querySummary">
            <v-divider />
            <c-query-summary :value="{filter: filter, meta: schema}" />
        </template>

        
        <!--<v-subheader>Primäre Datenreihe</v-subheader>-->
        <v-container fluid>  
            <v-row justify="center">
                <v-col>
                    <c-measure-single-selector v-model="measure" :items="{...attributes}" :filled="true" />
                </v-col>
                <v-col>
                    <v-combobox filled :label="$t('collection-series.view.selectAggregation.label')" :items="aggregations" v-model="aggregation" :item-text="`i18n.${$i18n.locale}.name`">
                        <template v-slot:item="{ item }">
                            {{item  | localizedName($i18n.locale)}}
                        </template>
                        <template v-slot:selection="{ item }">
                            {{item  | localizedName($i18n.locale)}}
                        </template>
                    </v-combobox>
                </v-col>
                <v-col>
                    <c-color-selector v-model="color" />
                </v-col>               
            </v-row>
            <v-row justify="center" >  
                <v-col :cols="12" :md="4">
                    <c-grouping-attribute-selector filled :value="{meta: schema, items: groupingAttributes}" :label="$t('collection-series.view.selectors.columns')"  @selection-changed="onGroupingAttributeSelectionChanged"/>
                </v-col> 
                <v-col :cols="12" :md="2">
                    <v-select filled :label="$t('collection-series.view.max-num-cols.label')" :items="numberOfColumns" v-model="maxNumCols" />
                </v-col>
                <v-col :cols="12" :md="4">
                    <c-grouping-attribute-selector filled :value="{meta: schema}" :label="$t('collection-series.view.selectors.segments')" @selection-changed="onRowAttributeSelectionChanged"/>
                </v-col> 
                <v-col :cols="12" :md="2">
                    <v-select filled :label="$t('collection-series.view.max-num-rows.label')" :items="numberOfSegments" v-model="maxNumRows" />
                </v-col>       
            </v-row>
        </v-container>

        <!-- Filter Settings Sidebar -->
        <v-navigation-drawer 
            fixed
            right
            temporary
            v-model="filterDialog"
            width="500"
            >
                <c-filter-settings :value="filter" :schema="schema" @filter-changed="onFilterChanged" />
        </v-navigation-drawer>

        <!-- *********************************************************************** -->
        <!-- CONTENT                                                                 -->
        <!-- *********************************************************************** -->

        <v-card-actions>
            <v-btn :disabled="!formValid" block color="accent" v-on:click="getSeriesData"><v-icon class="mr-2">input</v-icon>
            {{$t('collection-time-series.view.showChart.title')}}
            </v-btn>
        </v-card-actions>

        <template v-if="(!hasDataSeries || !formValid) && !formSent">
            <v-card tile flat >
                <v-card-text>
                    <p />
                    <div class="text-center"><v-icon color="primary" large>info</v-icon></div>
                    <div class="title text-center">{{$t('collection-series.view.missingConfig.title')}}</div>
                    <div class="text-center">{{$t('collection-series.view.missingConfig.subtitle')}}</div>
                </v-card-text>
                <v-card-text>
                    <div class="text-center">
                        <v-chip v-for="formField in formFields" :outlined="!formField.isValid" :key="formField.name"
                        class="ma-2"
                        :input-value="formField.isValid"
                        filter>
                        {{formField.text}}
                        </v-chip>
                    </div>
                </v-card-text>
            </v-card>
        </template>

        <template v-else-if="!hasDataSeries && !loading && formSent">
            <c-empty-result-tile />
        </template>
        
        <template v-else>
            <v-card-text>
                <c-data-series-chart ref="dataSeriesChart" :value="response" />
                <v-divider />
                <c-data-series-table :value="response" ></c-data-series-table>
            </v-card-text>
        </template>

    </v-sheet>
</template>

<script>
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import aggregations from './mock/collection-time-series-agg.mock'
const MODULE_NAME = "collectionSeries";

export default {
    inject: ['shellState', 'errorHandlerService', 'messageService', 'progressIndicatorService'],

    props: {
        value: {
            type: Object,
            required: true,
        }
    },

    data: () => ({
        filterDialog: false,
        aggregationDialog: false,
        querySummary: false,
        aggregations: aggregations,

        numberOfColumns: [5, 10, 25, 50, 100, 200],
        numberOfSegments: [5, 10, 25],
    }),    
    
    methods: {

        async getSeriesData() {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData', {collectionId: this.collectionId});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterQueryChange(event) {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/updateFilterQuery', {collectionId: this.collectionId, filterQuery: { filterQuery: event.query, filterQueryLanguage: event.mode } });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        }, 

        async onFilterChanged(event) {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/updateFacetFilters', {collectionId: this.collectionId, facetFilters: event});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            } 
        },

        onGroupingAttributeSelectionChanged(event) {
            this.$store.commit(MODULE_NAME + '/setGroupingAttributes', event.selectedItems)
        },

        onRowAttributeSelectionChanged(event) {
            this.$store.commit(MODULE_NAME + '/setRowAttributes', event.selectedItems);
        },

        toogleQuerySummary() {
            this.querySummary = !this.querySummary;
        },

        addMeasure(event) {
            const measureId = event.item.measure.name + '_' + event.item.aggregation.name;
            const measures = this.measures;

            measures[event.index] = {...event.item, measureId};

            this.measures = clone(measures);
        },

        removeMeasure(event) {
            const measures = this.measures;
            measures.splice(event.index, 1);

            this.measures = measures;
        },

        goToPrintView(type) {
            this.progressIndicatorService.show();
            const chart = this.$refs.dataSeriesChart.$data.eChart;
            const chartImage = chart.getDataURL({
                // Exporting format, can be either png, or jpeg
                type: 'png',
                // Resolution ratio of exporting image, 1 by default.
                pixelRatio: 2,
                // Background color of exporting image, use backgroundColor in option by default.
                backgroundColor: '#fff',
                // Excluded components list. e.g. ['toolbox']
                excludeComponents: ['toolbox', 'tooltip', 'dataZoom']
            });
            this.progressIndicatorService.hide();

            this.$router.push({name: 'collectionSeriesPrint', params: {image: chartImage, type: type}})
        },

        async exportDocument(format) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + '/export', {collectionId: this.collectionId, format: format});

                this.messageService.show({
                    title: this.$t('collection-documents.view.messages.export.success.title', this.$i18n.locale),  
                    text: this.$t('collection-documents.view.messages.export.success.message', this.$i18n.locale)
                });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    computed: {
        loading() {
            return this.shellState.loading;
        },

        schema() {
            return this.$store.getters[MODULE_NAME + "/schema"]
        },

        attributes() {
            const attributes = get(this.schema, 'attributes', {});
            const attributeList = [];
            Object.keys(attributes).forEach(key => {
                attributeList.push(attributes[key]);
            });

            return attributeList;
        },

        formValid() {
            return this.$store.getters[MODULE_NAME + '/formValid'];
        },

        response() {
            return this.$store.getters[MODULE_NAME + '/response'];
        },

        series() {
            const seriesResponse = get(this.response, 'data.series', []);
            const series = [];
            for (let serie of seriesResponse) {
                if (serie.series && serie.series.length > 0) {
                for (let subSerie of serie.series) {
                    series.push(subSerie);
                }
                } else {
                    series.push(serie);
                }
            }

            return series;
        },

        filter() {
            return this.$store.getters[MODULE_NAME + '/filter'];
        },

        meta() {
            return this.response.meta;
        },

        collectionId() {
            return this.value.collectionId;
        },

        groupingAttributes() {
            return this.$store.getters[MODULE_NAME + '/groupingAttributes'];
        },

        totalItemCountFormatted() {
            const totalItemCount = get(this.response, 'pagination.totalItemCount', 0);
            return this.$dxs.formatting.formatInteger(totalItemCount, this.$i18n.locale);
        },

        measure: {
            get() {
                return this.$store.getters[MODULE_NAME + '/measure'];
            },

            set(value) {
                this.$store.commit(MODULE_NAME + '/setMeasure', value);
            }
        },

        aggregation: {
            get() {
                return this.$store.getters[MODULE_NAME + '/aggregation'];
            },

            set(value) {
                this.$store.commit(MODULE_NAME + '/setAggregation', value);
            }
        },

        color: {
            get() {
                return this.$store.getters[MODULE_NAME + '/color'];
            },

            set(value) {
                this.$store.commit(MODULE_NAME + '/setColor', value);
            }
        },

        maxNumCols : {
            get() {
                return this.$store.getters[MODULE_NAME + '/maxNumCols'];
            },

            set(value) {
                this.$store.commit(MODULE_NAME + '/setMaxNumCols', value);
            }
        },

        maxNumRows : {
            get() {
                return this.$store.getters[MODULE_NAME + '/maxNumRows'];
            },

            set(value) {
                this.$store.commit(MODULE_NAME + '/setMaxNumRows', value);
            }
        },

        formSent() {
            return this.$store.getters[MODULE_NAME + '/formSent'];
        },

        formFields() {
            return [
                {
                    name: 'groupingAttributes',
                    text: this.$t('collection-series.view.column-attributes.label'),
                    value: this.groupingAttributes,
                    isValid: !isEmpty(this.groupingAttributes)
                },
                {
                    name: 'measure',
                    text: this.$t('collection-series.view.measure.label'),
                    value: this.measures,
                    isValid: !isEmpty(this.measure)
                },
                {
                    name: 'aggregation',
                    text: this.$t('collection-series.view.aggregation.label'),
                    value: this.aggregation,
                    isValid: !isEmpty(this.aggregation)
                },
                {
                    name: 'color',
                    text: this.$t('collection-series.view.color.label'),
                    value: this.aggregation,
                    isValid: !isEmpty(this.color)
                }
            ]
        },

        hasDataSeries() {
            return get(this.response, 'data.labels', []).length > 0;
        }
        
    },

    created() {
        this.$store.dispatch(MODULE_NAME + '/loadCollectionSettings',  {collectionId: this.collectionId}).finally(() => this.progressIndicatorService.hide());
    },
}
/** due to better readabilies - source out the uncommented and unused sections */

/** METHODS **
setChartTypeColumn() {
    this.setChartType('COLUMN_CHART');
},
setChartTypeArea() {
    this.setChartType('AREA_CHART');
},
setChartType(chartType) {
    this.$store.dispatch(MODULE_NAME + '/updateChartType', chartType);
},
isChartTypeColumn() {
    return this.isChartType('COLUMN_CHART');
},
isChartTypeArea() {
    return this.isChartType('AREA_CHART');
},
isChartType(chartType) {
    return this.viewSettings.chartType === chartType;
}
** END METHODS*/

/** TEMPLATE **
<v-divider />
<v-subheader>Zusätzliche Datenreihen</v-subheader>
<v-card-text>
    Definieren Sie bis zu drei zusätzliche Datenreihen. Diese Datenreihen werden als Linien innerhalb des Diagramms angezeigt.
</v-card-text>
<v-container fluid>  
    <v-row justify="center" >  
        <v-col :cols="12" :sm="4">
            <c-aggregated-measure-selector :value="response" filled :label="$t('collection-series.view.selectors.measures')" />
        </v-col>  
        <v-col :cols="12" :sm="4">
            <c-aggregated-measure-selector :value="response" filled :label="$t('collection-series.view.selectors.measures')" />
        </v-col> 
        <v-col :cols="12" :sm="4">
            <c-aggregated-measure-selector :value="response" filled :label="$t('collection-series.view.selectors.measures')" />
        </v-col>  
    </v-row>
</v-container>
** END TEMPLATE */
</script>

<i18n>
{
    "en": {
        "collection-series.view.column-attributes.label": "Column attributes",
        "collection-series-view.max-data-series.hint": "For optimal printing of the table, it is recommended not to exceed the number of 5 data series."
    },

    "de": {
        "collection-series.view.column-attributes.label": "Spaltenattribute",
        "collection-series-view.max-data-series.hint": "Für einen optimalen Druck der Tabelle wird empfohlen, die Anzahl von 5 Datenreihen nicht zu überschreiten."
    }
}
</i18n>