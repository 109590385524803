<template>
  <div>
    <v-card tile flat>

      <v-container fluid grid-list-md class="grey lighten-4">
        <v-row justify="center">
          <v-col :cols="12" :sm="4">
            <c-measure-selector
              :value="response"
              solo
              :label="$t('data-correlation-cockpit.component.selectors.attributeX')"
              @selection-changed="onAttributeXSelectionChanged"
            />
          </v-col>
          <v-col :cols="12" :sm="4">
            <c-measure-selector
              solo
              :value="response"
              :label="$t('data-correlation-cockpit.component.selectors.attributeY')"
              @selection-changed="onAttributeYSelectionChanged"
            />
          </v-col>
          <v-col :cols="12" :sm="4">
            <c-filter-query-input
              :value="{ query: '*' }"
              :disabled="loading"
              :loading="loading"
              @filter-query-changed="onFilterQueryChange"
              :dark="false"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-divider />

      <template v-if="xAttributeEmpty || yAttributeEmpty">
        <v-card tile>
          <v-card-text>
            <p />
            <div class="text-center">
              <v-icon color="primary" large>info</v-icon>
            </div>
            <div
              class="title text-center"
            >{{$t('data-correlation-cockpit.component.missingConfig.title')}}</div>
            <div
              class="text-center"
            >{{$t('data-correlation-cockpit.component.missingConfig.subtitle')}}</div>
          </v-card-text>
          <v-card-text></v-card-text>
        </v-card>
      </template>

      <template v-else>
        <!-- *********************************************************************** -->
        <!-- TOOLBAR                                                                 -->
        <!-- *********************************************************************** -->

        <v-toolbar flat color="white">
          <v-toolbar-title>{{$t('data-correlation-cockpit.component.results', {totalItemCount: response.pagination.totalItemCount})}}</v-toolbar-title>
          <v-btn icon @click="toogleQuerySummary">
            <v-icon v-if="!querySummary">expand_more</v-icon>
            <v-icon v-else>expand_less</v-icon>
          </v-btn>
          <v-spacer />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon @click="refresh">
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-btn icon @click="filterDialog = !filterDialog">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <template v-if="querySummary">
          <v-divider />
          <c-query-summary :value="response" />
        </template>

        <v-divider />

        <!-- *********************************************************************** -->
        <!-- CONTENT                                                                 -->
        <!-- *********************************************************************** -->
        <v-card tile flat>
          <!-- Chart -->
          <template>
            Comming soon
            <!-- <c-data-correlation-chart :value="response" /> -->
          </template>

          <!-- Filter Settings Sidebar -->
          <v-navigation-drawer fixed right temporary v-model="filterDialog" width="500">
            <c-filter-settings :value="response" @filter-changed="onFilterChanged" />
          </v-navigation-drawer>
        </v-card>
      </template>
    </v-card>
    <!-- </v-col>
            </v-row>
        </v-container>
    -->
  </div>
</template>

<script>

//import api from "@/store/api";
import forOwn from "lodash/forOwn";
import get from "lodash/get";

//import DataCorrelationModuleFactory from "./data-correlation-module.factory";

//const DATA_SERVICE = api.collections.correlation;
const MODULE_NAME = "collectionCorrelation";

export default {
  name: "collection-correlation",

  inject: ['shellState', 'errorHandlerService', 'progressIndicatorService'],

  props: {
    value: {
      type: Object,
      required: false
    },
    options: {
      required: false,
      type: Object
    }
  },

  watch: {
    value: "onValueUpdate",
  },

  data: () => ({
    filterDialog: false,
    aggregationDialog: false,
    querySummary: false,
  }),

  computed: {
    loading() {
      return this.shellState.loading;
    },

    model() {
      return {
        collectionId: this.value.collectionId,
        filter: {}
      };
    },

    response() {
      let response = this.$store.getters[MODULE_NAME + "/response"];
      return response;
    },

    facets() {
      let facets = [];
      forOwn(this.response.facets, (value, key) => {
        let facet = {
          name: key,
          attribute: this.meta.attributes[key],
          values: value
        };

        facets.push(facet);
      });

      return { facets: facets };
    },

    meta() {
      return this.response.meta;
    },

    devMode() {
      return this.options && this.options.dev;
    },

    moduleName() {
      return this.model.name;
    },

    collectionId() {
      return this.model.collectionId;
    },

    xAttribute() {
      let x = this.$store.getters[MODULE_NAME + "/xAttribute"];
      return x;
    },

    xAttributeEmpty() {
      return this.xAttribute === '';
    },

    yAttribute() {
      let y = this.$store.getters[MODULE_NAME + "/yAttribute"];
      return y;
    },

    yAttributeEmpty() {
      return this.yAttribute === '';
    },

    data() {
      return this.value && this.value.data ? this.value.data : {};
    },

    labels() {
      return this.data.labels ? this.data.labels : [];
    },

    // ==================================================================
    // Options
    // ==================================================================
    showXAxisFilter() {
      return get(this.options, "showXAxisFilter", true);
    },

    chartHeight() {
      return get(this.options, "chartHeight", 600);
    },

    chartStyle() {
      return "width:100%;" + "height:" + this.chartHeight + "px;";
    }
  },

  methods: {
    onValueUpdate(value) {
      if (value && value.filter) {
        this.updateFilter(value.filter);
      } else {
        this.$log.warn(
          "The value property does not contain the expected filter object"
        );
      }

      // A change to the value indicates that a reload should be triggered.

      this.$log.info("Resetting view for new value " + JSON.stringify(value));
      this.reset();
    },

    onFilterQueryChange(event) {
      this.$store.dispatch(MODULE_NAME + "/updateFilterQuery", {
        collectionId: this.collectionId,
        filterQuery: event.query
      });
    },

    onFilterChanged(event) {
      this.$store.dispatch(MODULE_NAME + "/updateFacetFilters", {
        collectionId: this.collectionId,
        facetFilters: event
      });
    },

    onAttributeXSelectionChanged(event) {
      this.$store.dispatch(MODULE_NAME + "/updateXAttribute", {
        collectionId: this.collectionId,
        xAttribute: event.selectedItems[0].attribute
      });
    },

    onAttributeYSelectionChanged(event) {
      this.$store.dispatch(MODULE_NAME + "/updateYAttribute", {
        collectionId: this.collectionId,
        yAttribute: event.selectedItems[0].attribute
      });
    },

    toogleQuerySummary() {
      this.querySummary = !this.querySummary;
    },

    async updateFilter(filter) {
      this.progressIndicatorService.show();

      try {
        await this.$store.dispatch(MODULE_NAME + "/updateFilter", filter);
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    async refresh() {
      this.progressIndicatorService.show();

      try {
        await this.$store.dispatch(MODULE_NAME + "/loadData", {
          collectionId: this.collectionId
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    },

    async reset() {
      this.progressIndicatorService.show();

      // Resetting the dialog will trigger another refresh .
      try {
        await this.$store.dispatch(MODULE_NAME + "/resetData");
        await this.$store.dispatch(MODULE_NAME + "/loadData", {
          collectionId: this.collectionId
        });
      } catch (error) {
        this.errorHandlerService.handleError(error);
      } finally {
        this.progressIndicatorService.hide();
      }
    }
  },

  created() {

    this.updateFilter(this.model.filter);
  },


};
</script>

<style>

</style>