<template>
    <v-sheet>
        <c-sticky-content>
            <v-toolbar color="secondary darken-1" flat >
                <v-toolbar-items><v-btn icon @click="close"><v-icon>close</v-icon></v-btn></v-toolbar-items>
                <v-breadcrumbs>
                    <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false">{{$t('collection-view.breadcrumbs.root')}}</v-breadcrumbs-item>
                    <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                    <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false">{{collectionSettings | localizedName($i18n.locale)}}</v-breadcrumbs-item>
                    <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                    <v-breadcrumbs-item>{{document | localizedName($i18n.locale)}}</v-breadcrumbs-item>
                    <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                    <v-breadcrumbs-item>{{'Dashboard'}}</v-breadcrumbs-item>                
                </v-breadcrumbs>
                <v-spacer />
                <v-toolbar-items>
                    <v-btn icon><v-icon @click="refresh">refresh</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-divider />
        </c-sticky-content>
        <c-section title="Measures" class="secondary">
            <v-container fluid>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-toolbar flat>
                                <v-toolbar-title>Pressdruck</v-toolbar-title>
                            </v-toolbar>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <div class="text-left">Aktueller Wert</div>
                                        <div class="text-h3 text-left">456</div>
                                        
                                    </v-col>
                                    <v-divider vertical />
                                    <v-col cols="12" md="3">
                                        <div class="text-left">Maximaler Wert</div>
                                        <div class="text-h5 text-left">488</div>
                                        <p />
                                        <div class="text-left">Der Wert liegt {{488 -456}} unter dem größten Wert.</div>

                                    </v-col>
                                    <v-divider vertical />
                                    <v-col cols="12" md="3">
                                        <div class="text-left">Minimaler Wert</div>
                                        <div class="text-h5 text-left">350</div>
                                        <p />
                                        <div class="text-left">Der Wert liegt {{488 - 350}} Über dem kleinsten Wert.</div>

                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </c-section>
    </v-sheet>
</template>

<script>
export default {

}
</script>

<style>

</style>