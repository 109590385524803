import api from '@/store/api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state : {
        tenantListTemplate: {},

        schema: {},

        collectionSettings: {}
    },

    mutations: {

        setTenantListTemplate(state, tenantListTemplate) {
            set(state, 'tenantListTemplate', tenantListTemplate);
        },

        setSchema(state, schema) {
            set(state, 'schema', schema);
        },

        setCollectionSettings(state, collectionSettings) {
            set(state, 'sourceCollection', collectionSettings);
        },

        setBookmark(state, bookmark) {
            set(state, 'tenantListTemplate.bookmark', bookmark);
        }
    },

    actions: {
    
        async loadData(context, { name }) {
            
            try {
                let response = await api.catalog.tenantListTemplates.findByName(name);

                context.commit('setTenantListTemplate', get(response, 'data.template', {}));
                context.commit('setSchema', get(response, 'data.schema', {}));
                context.commit('setCollectionSettings', get(response, 'data.collection', {}));
            } catch (error) {
                context.commit('setTenantListTemplate', {});
                throw error;
            }

        },

        async saveData(context) {

            try {

                const payload = context.getters.payload;
                let response = await api.catalog.tenantListTemplates.update(payload.collectionId, payload.name, payload);
                
                context.commit('setTenantListTemplate', get(response, 'data.template', context.state.tenantListTemplate));

            } catch (error) {
                context.commit('setTenantListTemplate', context.state.tenantListTemplate);
                throw error;

            }
        },

        updateTenantListTemplate(context, template) {
            context.commit('setTenantListTemplate', template);
        },

        updateBookmark(context, bookmark) {
            context.commit('setBookmark', bookmark);
        }
        
    },

    getters: {
        model(state) {

            return {
                tenantListTemplate: state.tenantListTemplate,
                schema: state.schema,
                collectionSettings: state.collectionSettings
            };
        },

        payload(state) {
            let payload = get(state, 'tenantListTemplate', {});
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        bookmark(state) {
            return get(state, 'tenantListTemplate.bookmark', {});
        }
   
    }
}
