import api from '@/store/api';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export default {

    namespaced : true,

    state: {

        items: [],

        meta: {},

        collectionSettings: {},

        query: {

            filter: {

                filterQuery: '*',

                filterQueryLanguage: 'SIMPLE',

                facetFilters: [],

                rangeFilters: []
            },

            view: {
                columns: []
            },

            sort: {
                sortCriteria: []
            },

            pagination: {
                from: 0,
                size: 50,
                searchAfter: []
            },
        },

        viewSettings: {

            attributes: []
        },

        pagination: {
            from: 0,
            size: 50,
            searchAfter: []
        },

        facets: {},

        links: {},

        userListTemplates: [],

        listTemplate: {

            name: null,
            templateId: "",
            collectionId: "",
            userId: "",
            i18n: {},
            bookmark: false,
            filterSettings: {
                filterQuery: "*",
                filterQueryLanguage: "SIMPLE",
                facetFilters: [],
                rangeFilters: []
            },
            viewSettings: {
                columns: []
            },
            sortSettings: {
                sortCriteria: []
            },
            custData: {}
        }
    },

    mutations: {

        setCollectionSettings(state, collectionSettings) {
            state.collectionSettings = collectionSettings ? collectionSettings : {}
        },

        setSchema(state, schema) {
            state.meta = schema ? schema : { attributes: {}};

            // Compute the view settings
            state.viewSettings = {attributes: []};
            forOwn(state.meta.attributes, attribute => state.viewSettings.attributes.push({
                value: attribute,
                display: !!get(attribute, 'clientSettings.table.display', true)
            }));


            state.viewSettings.attributes.sort( (first, second) => first.value.ordering - second.value.ordering);
        },

        /**
         * @deprecated
         * @param {*} state 
         * @param {*} viewSettings 
         */
        setViewSettings(state, viewSettings) {

            if (viewSettings) {

                if (viewSettings.attributes) {
                    state.viewSettings.attributes = viewSettings.attributes;
                } else {
                    state.viewSettings.attributes = [];
                }
            }
        },

        setPagination(state, pagination) {
            state.pagination = pagination;
        },

        setResponse(state, response) {


            state.items = response.items ? response.items : [];
            state.meta  = response.meta ? response.meta : {};
            state.collectionSettings = response.collectionSettings ? response.collectionSettings : { name: null};

            state.facets     = response.facets ? response.facets : {};
            state.links      = response.links ? response.links : {};
            state.pagination = response.pagination ? response.pagination : {from: 0, size: 50, searchAfter: []};

            state.query.filter     = (response.query && response.query.filter) ? response.query.filter : { filterQuery: '*', filterQueryLanguage: 'SIMPLE', facetFilters: [], rangeFilters: [] };
            state.query.sort       = (response.query && response.query.sort) ? response.query.sort : { sortCriteria: [] },
            state.query.pagination = (response.query && response.query.pagination) ? response.query.pagination : { from: 0, size: 50, searchAfter: [] };

            // DXS-99: Reset the view settings if an empty response has been received.
            // It is necessary to do so in order to support the reusing of components by vue router.
            // Otherwise the view settings will not change when navigating.
            // DXS-327 always reset the viewsettings

            if (isEmpty(response)) { // Always remember: Empty objects are NOT falsy... thus use the lodash is empty method for a safe check.
                state.viewSettings.attributes = [];
            }

            // If no explicit view settings have been set,
            // compute them on the fly.
            if (isEmpty(state.viewSettings.attributes)) {
                forOwn(state.meta.attributes, attribute => state.viewSettings.attributes.push({
                    value: attribute,
                    display: !!get(attribute, 'clientSettings.table.display', true)
                }));


                state.viewSettings.attributes.sort( (first, second) => first.value.ordering - second.value.ordering);
            }

        },

        appendResponse(state, response) {

            // In order to play it safe, make sure there is an actual instance.
            if (!state.items) {
                state.items = [];
            }

            // Push additional items
            // Note: Due to reasons with vuex it is necessary to push each item separately inside of a loop
            // It is not sufficient to push the array as is.
            for (let item of response.items) {
                state.items.push(item);
            }


            // Of course we also need to update the pagination
            state.pagination = response.pagination;
        },

        setUserListTemplates(state, response){
            // response -> [{template, schema, collection}]
            state.userListTemplates = response.items;
        },

        setFilter(state, filter) {

            state.query.filter = filter;
        },

        setFilterQuery(state, { filterQuery, filterQueryLanguage}) {

            //console.log(">>>" + JSON.stringify(filterQuery) + " + " + JSON.stringify(filterQueryLanguage))
            state.query.filter.filterQuery = filterQuery ? filterQuery : '*';
            state.query.filter.filterQueryLanguage = filterQueryLanguage ? filterQueryLanguage  : 'SIMPLE';
        },

        setFacetFilters(state, filters) {

            state.query.filter.facetFilters = filters.facetFilters;
            state.query.filter.rangeFilters = filters.rangeFilters;
        },

        setView(state, view) {
            state.query.view = view;
        },

        setSort(state, sort) {

            state.query.sort = sort;
        },

        setListTemplateId(state, templateId){
            state.listTemplate.templateId = templateId;
        },

        setListTemplateUserId(state, userId){
            state.listTemplate.userId = userId;
        },

        setListTemplateCollectionId(state, collectionId){
            state.listTemplate.collectionId = collectionId;
        },

        setListTemplateSettings(state, templateSettings){
            //console.log(">> " + JSON.stringify(templateSettings));
            state.listTemplate.filterSettings = get(templateSettings, 'filterSettings', {});
            state.listTemplate.sortSettings = get(templateSettings, 'sortSettings', {});
            state.listTemplate.viewSettings = get(templateSettings, 'viewSettings', {});
        }
    },

    actions: {

        /**
         * Resets the filter and sort settings.
         * @param {*} context
         */
        async resetView(context) {

            // Adding an empty response will lead to resetting the dialog back to its original state,
            // esp. discarding all filters, etc.
            context.commit('setResponse', {});
            //context.dispatch('resetView', { attributes: [] });

        },

        async updateViewSettings(context, viewSettings) {

            context.commit('setViewSettings', viewSettings);
        },

        async initData(context, {collectionId}) {

            const response = await api.collections.findById(collectionId);
            context.commit('setCollectionSettings', response ? response.collectionSettings : {});
            context.commit('setSchema', response ? response.schema : {});
        },

        async applyListTemplate(context, {name, filterSettings, viewSettings, sortSettings}) {

            context.commit('setName', name);
            context.commit('setFilter', filterSettings);
            context.commit('setView', viewSettings);
            context.commit('setSort', sortSettings);
        },

        async saveTemplate(context, name){
            
            try {
                const request = context.getters.templateRequest;
                const response = await api.me.listTemplates.update(name, request);
                return response;
            } catch (error) {
                console.log("An error has occured" + error);
              }

        },

        async loadUserListTemplates(context, collectionId) {
            try {
                const response = await api.me.listTemplates.findByCollectionId(collectionId, '*');
                context.commit('setUserListTemplates', response);
            } catch (error) {

                console.log("An error has occured" + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('setUserListTemplates', []);
              } 
        },

        async loadData(context, {collectionId, listTemplate}) {

            //console.log(">>> " + collectionId + " " + JSON.stringify(listTemplate))
            // Reset the pagination... otherwise sooner or later we will run out of pages to display
            let pagination = {
                from: 0,
                searchAfter: [],
                size: 50
            };
            context.commit('setListTemplateSettings', listTemplate);
            context.commit('setPagination', pagination);
            const query = context.getters.query;


            return api.collections.documents.findByQuery(collectionId, query).then(response => context.commit('setResponse', response));
        },

        async exportDocument(context, {collectionId, format}) {

            const query = context.getters.query;
            const body = {
                filter: query.filter,
                sort: query.sort,
                format: format
            }

            return api.collections.documents.exportDocument(collectionId, body).then(() => {});
        },

        async deleteItemById(context, {collectionId, documentId}) {

            try {

                await api.collections.documents.deleteById(collectionId, documentId);

            } catch (error) {

                console.log("An error has occured" + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('setResponse', {collectionSettings: {}, schema: {}} );
              }
        },

        async loadMore(context, {collectionId, listTemplate}) {

            context.commit('setListTemplateSettings', listTemplate);

            // Please note that load more will add another page to the existing set of results.
            const query = context.getters.query;

            // We need to tweak the query in order to load the next page.
            query.pagination.searchAfter = context.state.pagination.searchAfter;
            query.pagination.size = context.state.pagination.size;

            // DXS-95: Add the from - parameter: This is not required on subsequent calls by elastic,
            // but causes trouble in the Backend if missing.
            if (!query.pagination.from) query.pagination.from = 0;

            //console.log(">>> LOAD MORE : " + JSON.stringify(query))

            return api.collections.documents.findByQuery(collectionId, query).then(response => context.commit('appendResponse', response));
        },

        async updateFilter(context, {collectionId, filter}) {

            context.commit('setFilter', filter);

            return context.dispatch('loadData', {collectionId});
        },

        async updateFacetFilters(context, {collectionId, facetFilters}) {
            context.commit('setFacetFilters', facetFilters);

            return context.dispatch('loadData', {collectionId});
        },

        async updateFilterQuery(context, {collectionId, filterQuery}) {

            //console.log("+++ " + JSON.stringify(filterQuery));
            context.commit('setFilterQuery', filterQuery);

            return context.dispatch('loadData', {collectionId});
        },



        async resetFilter(context) {

            const filter = {
                filterExpression: '*',
                facetFilters: [],
                rangeFilters: []
            };

            return context.dispatch('setFilter', filter);
        },

        async updateSort(context, {collectionId, sort}) {

            context.commit('setSort', sort);

            return context.dispatch('loadData', {collectionId});
        },

        async resetSort(context) {

            const sort = {
                sortCriteria: []
            };

            return context.dispatch('setSort', sort);
        },

        /**
         * Updates filter and sort with the given values without triggering a reload.
         * @param {*} context
         * @param {*} param1
         */
        updateFilterAndSort(context, {filter, sort}) {

            context.commit('setFilter', filter);
            context.commit('setSort', sort);
        },

        updateTemplateId(context, templateId){
            context.commit('setListTemplateId', templateId);
        },

        createRequest(context, {userId, collectionId, templateId, templateSettings}){
            context.commit('setListTemplateUserId', userId);
            context.commit('setListTemplateCollectionId', collectionId);
            context.commit('setListTemplateId', templateId);
            context.commit('setListTemplateSettings', templateSettings);
        }
    },

    getters: {

        templateRequest(state) {

            return {
                templateId: state.listTemplate.templateId,
                collectionId: state.listTemplate.collectionId,
                userId: state.listTemplate.userId,
                i18n: state.listTemplate.i18n,
                bookmark: state.listTemplate.bookmark,
                filterSettings: state.listTemplate.filterSettings,
                viewSettings: state.listTemplate.viewSettings,
                sortSettings: state.listTemplate.sortSettings,
                custData: state.listTemplate.custData,  
                creationDateTime: null,
                creationUser: null,
                modificationDateTime: null,
                modificationUser: null
            }
        },

        query(state) {

            return {
                filter: state.listTemplate.filterSettings,
                sort: state.listTemplate.sortSettings,
                pagination: state.pagination,
                queryTimeoutMiliseconds: 25 * 1000 // 25 seconds
            }
        },

        selectedListTemplate(state) {

            return {
                collectionId: state.collectionSettings.name,
                filterSettings: state.query.filter,
                viewSettings: state.query.view,
                sortSettings: state.query.sort,
            }
        },

        userListTemplates(state) {
            return state.userListTemplates;
        },

        templateId(state) {
            return state.listTemplate.templateId;
        },

        filter(state) {

            return state.query.filter;
        },

        response(state) {

            return {
                items              : state.items,
                meta               : state.meta,
                collectionSettings : state.collectionSettings,
                filter             : state.query.filter,
                viewSettings       : state.viewSettings,
                sort               : state.query.sort,
                pagination         : state.pagination,
                facets             : state.facets,
                links              : state.links
            }
        }

    }


}
