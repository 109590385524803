<template>
  <div class="secondary darken-1">

        <!-- ================================================================= -->
        <!-- Breadcrumbs                                                 -->
        <!-- ================================================================= -->  
        <div class="secondary darken-1">
            <v-breadcrumbs>
                <v-breadcrumbs-item>{{$t('Alle Vorhersage-Szenarien')}}</v-breadcrumbs-item>
                <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                <v-breadcrumbs-item>{{predictionId}}</v-breadcrumbs-item>
                <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                <v-breadcrumbs-item>{{$t('Modelle')}}</v-breadcrumbs-item>
                <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                <v-breadcrumbs-item>{{modelId}}</v-breadcrumbs-item>
            </v-breadcrumbs>
        </div>

        <template v-if="trainingInProgress">
            <c-section
                :title="'Modell ' + predictionId + ':' + modelId"
                :subtitle="$t('Dieses Modell wird aktuell noch trainiert.')"
                class="secondary">
                    <v-container fluid class="secondary">

                        <v-row justify="center">
                            <v-col cols="12" sm="6" md="4">
                                <v-card tile >
                                    <v-card-text />
                                    <v-card-text class="text-center text-h5">
                                        Model Training in Progress 
                                    </v-card-text>
                                    <v-container fluid>
                                        <v-row justify="center">
                                            <v-col cols="6">
                                                <v-progress-linear color="accent" indeterminate rounded  />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    
                                    <v-card-text class="text-center">
                                        Das Training eines Modells ist ein potentiell langlaufender Prozess. Bitte überprüfen Sie den Status des Trainings zu einem späteren Zeitpunkt erneut.
                                    </v-card-text>
                                    <v-card-text />
                                </v-card>
                            </v-col>
                        </v-row>

                    </v-container>
            </c-section>
        </template>

        <!-- ============================================================================== -->
        <!-- TRAINING FINISHED -->
        <!-- ============================================================================== -->
        <template v-else>

            <c-section
            :title="'Modell ' + modelId"
            :subtitle="$t('Dieses Modell wurde erfolgreich trainiert und steht nun für Vorhersagen zur Verfügung.')"
            class="secondary">
            </c-section>

            <c-section
            :title="$t('prediction-model-training-evaluation.view.sections.evaluation.title')"
            :subtitle="$t('prediction-model-training-evaluation.view.sections.evaluation.subtitle')"
            class="secondary darken-1">
                    <v-container fluid class="secondary darken-1">

                        <!-- This row is displayed after successfully finishing the training -->
                        <v-row justify="center" v-if="!trainingInProgress">
                            <v-col cols="12" sm="4" md="3">
                                <v-card tile>
                                    <v-card-text>
                                        <div class="title">RMSE</div>
                                        <div class="subtitle">Wurzel der mittleren Felderquadratsumme</div>
                                    </v-card-text>
                                    <v-card-text class="text-h3 text-end">0.01</v-card-text>
                                    <!--
                                    <v-divider />  
                                    <v-card-text>
                                        Der RMSE-Wert ist eine Maßzahl zur Beurteilung der Vorhersagequalität. 
                                        Er gibt an wie stark eine Prognose im Durchschnitt von den tatsächlichen Beobachtungswerten der Trainingsdaten abweicht.
                                        Je kleiner der RMSE umso präziser kann das Modell Werte vorhersagen.
                                    </v-card-text> -->
                                <v-divider />
                                    <v-subheader>Erläuterung</v-subheader>
                                    <v-card-text>
                                        Die Vorhersagen Ihres Modells weichen im Durchschnitt etwa 1% von den tatsächlichen Werten ab. 
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <v-card tile>
                                    <v-card-text>
                                        <div class="title">MAE</div>
                                        <div class="subtitle">Mittlerer absoluter Fehler</div>
                                    </v-card-text>
                                    <v-card-text class="text-h3 text-end">0.46</v-card-text>
                                    <v-divider />
                                <!--
                                    <v-card-text>
                                        Der MAE-Wert gibt an wie weit ein vorhergesagter Wert durchschnittlich von einem tatsächlichen Beobachtungswert in den Trainingsdaten abweicht.
                                        Diese Maßzahl muss immer im fachlichen Zusammenhang betrachtet werden und kann nicht allgemeingültig interpretiert werden.
                                    </v-card-text>

                                    <v-divider />
                                    -->
                                    <v-subheader>Erläuterung</v-subheader>
                                    <v-card-text>
                                        Im Durchschnitt weichen Vorhersagen dieses Modells um den Wert 0.46 nach oben oder unten ab.
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="4" md="3">
                                <v-card tile>
                                    <v-card-text>
                                        <div class="title">R^2</div>
                                        <div class="subtitle">Determinationskoeffizient</div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" sm="12" md="9">
                                <v-card tile>
                                    <v-card-text class="title">Vorhersagen</v-card-text>
                                    <v-card-text>
                                        Die nachfolgende Tabelle zeigt Ihnen einige Stichproben von Vorhersagen, die diese Modell-Konfiguration auf den Trainingsdaten durchgeführt hat.
                                        Bitte beachten Sie, dass diese Stichproben nicht repräsentativ sind und Ihnen nur zur groben Einschätzung dienen.
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </c-section>

            

            <c-section
                :title="$t('prediction-model-training-evaluation.view.sections.trainingParams.title')"
                :subtitle="$t('prediction-model-training-evaluation.view.sections.trainingParams.subtitle')"
                class="secondary darken-2">
                    <v-container fluid>
                        <v-row justify="center">
                            <v-col cols="12" sm="12" md="9">
                                <v-card tile>
                                    <v-card-text class="title">Parameter</v-card-text>
                                    <v-simple-table>
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Wert</th>
                                                <th>Beschreibung</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Ausreißer</td>
                                                <td>Eingeschaltet</td>
                                                <td>Das System ermittelt Zeilen mit Ausreißer-Werten und ignoriert diese.</td>
                                            </tr>
                                            <tr>
                                                <td>Test-Training-Split</td>
                                                <td>25%</td>
                                                <td>Das System reserviert im Training 25% der Datensätze zur Evaluierung von Vorhersagen.</td>
                                            </tr>
                                            <tr>
                                                <td>Kreuzvalidierung</td>
                                                <td>Eingeschaltet</td>
                                                <td>Das System führt iterativ mehrere Validierungen des Modells durch. Führt zu längeren Laufzeiten des Trainings.</td>
                                            </tr>
                                            
                                        </tbody>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="12" md="9">
                                <v-card tile>
                                    <v-card-text class="title">Attribute</v-card-text>
                                    <v-card-text>
                                        Für die Vorhersage wurden die nachfolgenden Attribute berücksichtigt.
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>

            </c-section>

            <c-section
                :title="$t('prediction-model-training-evaluation.view.sections.dataset.title')"
                :subtitle="$t('prediction-model-training-evaluation.view.sections.dataset.subtitle')"
                class="secondary lighten-1">

                
                    <v-tabs background-color="secondary lighten-1" color="secondary darken-1" centered>
                        <v-tab>Stichproben</v-tab>
                        <v-tab>Statistiken</v-tab>
                        <!-- 

                            -->
                    </v-tabs> 
            </c-section>
        </template>
  </div>
</template>

<script>
export default {
    
    props: {
        value: {
            type: Object,
            required: false,
        },

        options: {
            type: Object,
            required: false,
        },
    },

    data: () => ({ }),

    computed: {

        predictionId() {
             return this.value ? this.value.predictionId : ''
        },

        modelId() {
            return this.value ? this.value.modelId : ''
        },

        trainingInProgress() {
            return false;
        }
    }
}
</script>

<style>

</style>