<template>
    <v-card tile flat>
        <v-card-text>
            <div class="text-h5">{{$t('tag-settings-page.component.title')}}</div>
            <div>{{$t('tag-settings-page.component.subtitle')}}</div>
        </v-card-text>
        <v-divider />
        <v-card-text>
            <div class="text-subtitle-1 font-weight-medium pb-3">{{$t("tag-settings-page.component.sections.color.title")}}</div>
            <div>{{$t('tag-settings-page.component.sections.color.subtitle')}}</div>
            <c-color-single-selector :items="tagColors" v-model="selectedTagColor" flat color="white" />
        </v-card-text>
</v-card>
</template>

<script>
import { cloneDeep, get, set } from 'lodash';
import ColorItems from '@/core/constants/colors.constants';

export default {

    name: 'tag-settings-page',

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    data() {

        return {

            // Perform a deep clone in order to avoid unintentional changes due to call by reference.
            model: cloneDeep(this.value),
        }
    },

    watch: {

        /**
         * Occassionally the response from the backend will not arrive fast enough, thus resulting
         * the model to have only default values. By adding this watcher, we can react properly to 
         * updates properties.
         */
        value(newValue) {
            this.model = cloneDeep(newValue);
        }
    },

    methods: {

        fireInput() {
            console.log(">>> FIre INput " + JSON.stringify(this.model))
            this.$emit('input', cloneDeep(this.model));

        }
    },

    computed: {

        tagColors() {
            return ColorItems;
        },

        selectedTagColor: {
            
            get() {
                return get(this.model, 'tag.color', null);
            },

            set(value) {
                
                set(this.model, ['tag', 'color'], value);
                this.fireInput();
            }
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "tag-settings-page.component.title" : "Settings",
        "tag-settings-page.component.subtitle" : "This page contains settings for the selected tag.",

        "tag-settings-page.component.sections.color.title" : "Color",
        "tag-settings-page.component.sections.color.subtitle" : "Please assign the tag a color with which it can be displayed in the user interface."
    },

    "de" : {
        "tag-settings-page.component.title" : "Einstellungen",
        "tag-settings-page.component.subtitle" : "Diese Seite enthält Einstellungen zum ausgewählten Tag.",

        "tag-settings-page.component.sections.color.title" : "Farbe",
        "tag-settings-page.component.sections.color.subtitle" : "Bitte weisen Sie dem Tag eine Farbe zu, mit der es in der Benutzeroberfläche dargestellt werden kann."
    }
}
</i18n>