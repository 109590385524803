
export function newInstance() {

    return {
        
        computed: {

            devModeOn() {
                return this.value && this.value.dev;
            },

            devModeOff() {
                return !this.devModeOn;
            },
        }
    };
    
}