<template>
    <div>
    <v-toolbar color="secondary darken-1" flat >
        <v-breadcrumbs>
            <v-breadcrumbs-item :to="{ name: 'collectionList' }" :disabled="false" :link="true">{{$t('time-series-cockpit.view.breadcrumbs.root')}}</v-breadcrumbs-item>
            <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
            <v-breadcrumbs-item :to="{ name: 'collectionList' }" v-if="hasReadPermission">
                {{collectionSettings | localizedName($i18n.locale)}}
            </v-breadcrumbs-item>
            <v-breadcrumbs-item :to="{ name: 'collectionList' }" v-else>
                {{$t("collection.view.notFound.breadcrumb")}}
            </v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-spacer />
    </v-toolbar>
    <c-section :title="$dxs.i18n.localizedName(timeSeriesSettings, $i18n.locale)" :subtitle="$dxs.i18n.localizedName(collectionSettings, $i18n.locale)" class="secondary">
        <v-card flat class="grey lighten-3">
            <v-container fluid class="grey lighten-4">
                <v-row justify="center" align="center">
                    <v-col :cols="12" >
                        <c-time-range-filter-input :value="{ query: filterQuery }" :schema="schema" :disabled="disabled" />
                    </v-col>

                </v-row>
            </v-container>
            
            <v-divider />
            <c-section :title="$t('time-series-dashboard.view.sections.groups.title')"
                :subtitle="$t('time-series-dashboard.view.sections.groups.subtitle', {count: dimensionTotalItemCount})" 
                class="grey lighten-3">
                <v-container fluid>
                    <v-row>
                        <v-col cols="4" class="text-left" v-for="(group, index) of groups" :key="'tsgroup_' + index" >
                            <v-card tile>
                                <v-card-text>
                                    <div class="text-h5">{{getCardHeader(group)}}</div>
                                    <div class="text-h6">{{'250 erste Werte'}}</div>
                                </v-card-text>
                                
                                <c-metrics-time-series-chart :value="timeSeries" height="320px" fallbackChartType="line" legend="off"  zoom="off" />
                                <v-divider />
                                <c-metrics-time-series-chart :value="timeSeries" class="pb-5" height="200px" fallbackChartType="bar" />
                                <v-divider />
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th>Kennzahl</th>
                                            <th>Min</th>
                                            <th>Max</th>
                                            <th>Avg</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                                <v-card-actions>
                                    <v-btn @click="openDetailDialog(group)" block text>Details</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </c-section>
        </v-card>
    </c-section>
    <v-dialog v-model="displayDetailDialog" fullscreen persistent>
        <v-card flat>
            <v-toolbar flat color="grey lighten-3">
                <v-toolbar-items>
                    <v-btn @click="hideDetailDialog"><v-icon>close</v-icon></v-btn>
                </v-toolbar-items>
            </v-toolbar>

                        <v-card tile>
                            <v-card-text>
                                <div class="title">Kennzahl</div>
                                <div class="title">Gruppierung: A, B, C</div>
                            </v-card-text>
                            <v-divider />
                            <v-subheader>{{$t('time-series-cockpit.view.sections.timeseries.title')}}</v-subheader>
                            
                            <c-metrics-time-series-chart :value="timeSeries" class="pb-5" height="600px" fallbackChartType="line" legend="bottom"  zoom="top" />
                            <c-metrics-time-series-chart :value="timeSeries" class="pb-5" height="200px" fallbackChartType="bar" />
                            
                            <v-divider />
                            <v-subheader>{{$t('time-series-cockpit.view.sections.distribution.title')}}</v-subheader>
                            <c-metrics-numeric-histogram-chart :items="histogramData" height="350px" barColor="red" />
                            <v-divider />
                            <v-subheader>{{$t('time-series-cockpit.view.sections.table.title')}}</v-subheader>
                        </v-card>

        </v-card>
    </v-dialog>
    </div>
</template>

<script>
import chroma from "chroma-js";

import { DateTime } from 'luxon';

export default {

    props: {

    },


    data: () => {

        const now = DateTime.now();

        return {
            
            timeRange: {
                dateTimeFrom: now.startOf('day').toISO(),
                dateTimeUntil: now.toISO(),
            },

            displayDetailDialog: false,
            selectedGroup: null,

        };
    },

    methods: {

        /**
         * Opens the detail dialog for a single group in the time series.
         * Groups are defined by means of key value pairs, i.e. { [attributeName] = value }
         */
        openDetailDialog(group) {

            this.$log.debug(group);


        },

        getCardHeader(group) {

            const values = [];
            for (const key in group) {
                values.push(group[key]);
            }

            return values.join('; ');
        },

        showDetailDialog() {
            this.displayDetailDialog = true;
        },

        hideDetailDialog() {
            this.displayDetailDialog = false;
        },


    },

    computed: {

        colorScale() {
            return chroma.bezier(['#005155', '#A5D3E3',]).scale().correctLightness();
        },

        timeSeriesSettings() {
            return {
                i18n: {
                    en: {
                        name: 'Meine Zeitreihe'
                    }
                },
            }
        },

        groups() {
            return [
                { testTrackId: '010', testBenchId: 'T001', siteId: 'gemue', },
                { testTrackId: '011', testBenchId: 'T001', siteId: 'gemue', },
                { testTrackId: '012', testBenchId: 'T001', siteId: 'gemue', }
            ]
        },

        collectionSettings() {
            return {
        "creationDateTime": "2023-03-30T08:43:53.57079Z",
        "creationUser": "harry",
        "modificationDateTime": "2023-03-30T09:00:13.149816Z",
        "modificationUser": "harry",
        "clientSettings": {
            "detailDialogUrlTemplate": "/collections/${collectionId}/documents/${documentId}",
            "distribution": {
                "timeIntervals": []
            },
            "listDialogUrlTemplate": "/collections/${collectionId}/documents"
        },
        "collectionId": "EnduranceTestingHTSamples",
        "custData": {},
        "embeddedCollections": [],
        "exportSettings": {
            "maxNumberDocumentsPerFile": 10000
        },
        "i18n": {
            "de": {
                "description": "Langlaufende Testreihen mit hoher Temperatur",
                "languageTag": "de",
                "name": "Langzeittests (HT)",
                "shortName": "Langzeittests (HT)"
            },
            "en": {
                "description": "",
                "languageTag": "en",
                "name": "Long Term Endurance Tests (HT)",
                "shortName": "Long Term Endurance  Tests (HT)"
            }
        },
        "idTemplate": "UUID($data.siteId += '-' += $data.testBenchId += '-' += $data.testTrackId += '-' += $data.sampleDateTime)",
        "loggingSettings": {
            "documentChangeLogging": "TENANT",
            "queryLogging": "TENANT"
        },
        "name": "EnduranceTestingHTSamples",
        "partitionIdTemplate": "$data.siteId += '-' += $data.sampleDateTime_dayInYear",
        "schemaId": "EnduranceTestingHTSamples",
        "systemCollection": false
    };
        },
    
        schema() { return {
            "creationDateTime": "2023-03-30T10:27:27Z",
            "creationUser": "harry",
            "modificationDateTime": "2023-04-25T07:54:59.225699Z",
            "modificationUser": "harry",
            "attributes": {
                "temperatureBeforeTestObject": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Eingangstemperatur Prüfobjekt (Grad Celsius)",
                            "languageTag": "de",
                            "name": "Eingangstemperatur Prüfobjekt (Grad Celsius)",
                            "shortName": "Eingangstemperatur Prüfobjekt (Grad Celsius)"
                        },
                        "en": {
                            "description": "Inlet temperature (Grad Celsius)",
                            "languageTag": "en",
                            "name": "Inlet temperature (Grad Celsius)",
                            "shortName": "Inlet temperature (Grad Celsius)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "temperatureBeforeTestObject",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3290,
                    "path": "data_temperatureBeforeTestObject",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "valveId": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Ventil ID",
                            "languageTag": "de",
                            "name": "Ventil ID",
                            "shortName": "Ventil ID"
                        },
                        "en": {
                            "description": "Valve ID",
                            "languageTag": "en",
                            "name": "Valve ID",
                            "shortName": "Valve ID"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "valveId",
                    "numerical": false,
                    "optional": true,
                    "ordering": 1900,
                    "path": "data_valveId",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "pressureBeforeTestObject": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Eingangsdruck Prüfobjekt (Bar)",
                            "languageTag": "de",
                            "name": "Eingangsdruck Prüfobjekt (Bar)",
                            "shortName": "Eingangsdruck Prüfobjekt (Bar)"
                        },
                        "en": {
                            "description": "Inlet pressure (Bar)",
                            "languageTag": "en",
                            "name": "Inlet pressure (Bar)",
                            "shortName": "Inlet pressure (Bar)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "pressureBeforeTestObject",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3270,
                    "path": "data_pressureBeforeTestObject",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "medium": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "INTEGER",
                    "dimension": true,
                    "format": {
                        "en": {
                            "formatString": "0,0",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Lastmedium des Ventiles",
                            "languageTag": "de",
                            "name": "Last",
                            "shortName": "Last"
                        },
                        "en": {
                            "description": "Valve Load",
                            "languageTag": "en",
                            "name": "Load",
                            "shortName": "Load"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "medium",
                    "numerical": true,
                    "optional": true,
                    "ordering": 800,
                    "path": "data_medium",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "type": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Typenschlüssel",
                            "languageTag": "de",
                            "name": "Typenschlüssel",
                            "shortName": "Typ"
                        },
                        "en": {
                            "description": "Type",
                            "languageTag": "en",
                            "name": "Type",
                            "shortName": "Type"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "type",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2000,
                    "path": "data_type",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "batchId": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Charge",
                            "languageTag": "de",
                            "name": "Charge",
                            "shortName": "Charge"
                        },
                        "en": {
                            "description": "Batch",
                            "languageTag": "en",
                            "name": "Batch",
                            "shortName": "Batch"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "batchId",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2800,
                    "path": "data_batchId",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "sampleDateTime": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "DATETIME",
                    "dimension": false,
                    "format": {
                        "de": {
                            "formatString": "dd.MM.yyyy HH:mm:ss ",
                            "languageTag": "de"
                        },
                        "en": {
                            "formatString": "yyyy-MM-dd HH:mm:ss ",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Zeitstempel (Ortszeit) der Datenmessung.",
                            "languageTag": "de",
                            "name": "Zeitstempel Prüfung (Ortszeit)",
                            "shortName": "Zeitst. Prüf. (Ort)"
                        },
                        "en": {
                            "description": "Timestamp (Local) when the sample has been taken.",
                            "languageTag": "en",
                            "name": "Sample Timestamp Local",
                            "shortName": "Sample TS (Local)"
                        }
                    },
                    "key": true,
                    "measure": false,
                    "name": "sampleDateTime",
                    "numerical": false,
                    "optional": false,
                    "ordering": 400,
                    "path": "data_sampleDateTime",
                    "temporal": true,
                    "temporalDimension": true,
                    "valueMapping": {}
                },
                "cycle": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Zykluszeit des Ventiles",
                            "languageTag": "de",
                            "name": "Zykluszeit",
                            "shortName": "Zyklus"
                        },
                        "en": {
                            "description": "Cycle Time of the Valve",
                            "languageTag": "en",
                            "name": "Cycle Time",
                            "shortName": "Cycle"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "cycle",
                    "numerical": true,
                    "optional": true,
                    "ordering": 1100,
                    "path": "data_cycle",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "antriebsgroesse": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Antriebsgröße",
                            "languageTag": "de",
                            "name": "Antriebsgröße",
                            "shortName": "Antriebsgröße"
                        },
                        "en": {
                            "description": "Drive Size",
                            "languageTag": "en",
                            "name": "Drive Size",
                            "shortName": "Drive Size"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "antriebsgroesse",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2600,
                    "path": "data_antriebsgroesse",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "steamGeneratorPressureInLoop": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Versorgungsdruck Dampfringleitung (Grad Celsius)",
                            "languageTag": "de",
                            "name": "Versorgungsdruck Dampfringleitung (Grad Celsius)",
                            "shortName": "Versorgungsdruck Dampfringleitung (Grad Celsius)"
                        },
                        "en": {
                            "description": "Steamgenerator Inloop pressure (Grad Celsius)",
                            "languageTag": "en",
                            "name": "Steamgenerator Inloop pressure (Grad Celsius)",
                            "shortName": "Steamgenerator Inloop pressure (Grad Celsius)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "steamGeneratorPressureInLoop",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3220,
                    "path": "data_steamGeneratorPressureInLoop",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "sampleDateTime_dayInYear": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Tag des Jahres der Datenmessung (UTC).",
                            "languageTag": "de",
                            "name": "Zeitstempel Prüfung (UTC): Tag im Jahr",
                            "shortName": "PrüfZS (UTC): Tag/Jahr"
                        },
                        "en": {
                            "description": "Day of the year when the sample has been taken (UTC).",
                            "languageTag": "en",
                            "name": "Sample Timestamp (UTC): Day of Year",
                            "shortName": "SampTS (UTC): Day/Year"
                        }
                    },
                    "key": true,
                    "measure": false,
                    "name": "sampleDateTime_dayInYear",
                    "numerical": false,
                    "optional": false,
                    "ordering": 500,
                    "path": "data_sampleDateTime_dayInYear",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "steuerfunktion": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Steuerfunktion",
                            "languageTag": "de",
                            "name": "Steuerfunktion",
                            "shortName": "Steuerfunktion"
                        },
                        "en": {
                            "description": "Controlfunction",
                            "languageTag": "en",
                            "name": "Controlfunction",
                            "shortName": "Controlfunction"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "steuerfunktion",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2500,
                    "path": "data_steuerfunktion",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "coldWaterAggregateFlow": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Coldwater Flowrate (L/min)",
                            "languageTag": "de",
                            "name": "Coldwater Flowrate (L/min)",
                            "shortName": "Coldwater Flowrate (L/min)"
                        },
                        "en": {
                            "description": "Coldwater Flowrate (L/min)",
                            "languageTag": "en",
                            "name": "Coldwater Flowrate (L/min)",
                            "shortName": "Coldwater Flowrate (L/min)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "coldWaterAggregateFlow",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3170,
                    "path": "data_coldWaterAggregateFlow",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "vacuumPumpPressure": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Druck Vakuumpumpe (Bar)",
                            "languageTag": "de",
                            "name": "Druck Vakuumpumpe (Bar)",
                            "shortName": "Druck Vakuumpumpe (Bar)"
                        },
                        "en": {
                            "description": "Pressure Vaccum Pump (Bar)",
                            "languageTag": "en",
                            "name": "Pressure Vaccum Pump (Bar)",
                            "shortName": "Pressure Vaccum Pump (Bar)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "vacuumPumpPressure",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3250,
                    "path": "data_vacuumPumpPressure",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "Versuchstitel": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Versuchstitel",
                            "languageTag": "de",
                            "name": "Versuchstitel",
                            "shortName": "Versuchstitel"
                        },
                        "en": {
                            "description": "Test Titel",
                            "languageTag": "en",
                            "name": "Titel",
                            "shortName": "Titel"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "Versuchstitel",
                    "numerical": false,
                    "optional": true,
                    "ordering": 1700,
                    "path": "data_Versuchstitel",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "pressureBoosterPressure": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Druck Druckbooster (Bar)",
                            "languageTag": "de",
                            "name": "Druck Druckbooster (Bar)",
                            "shortName": "Druck Druckbooster (Bar)"
                        },
                        "en": {
                            "description": "Pressure Pressure booster (Bar)",
                            "languageTag": "en",
                            "name": "Pressure Pressure booster (Bar)",
                            "shortName": "Pressure Pressure booster (Bar)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "pressureBoosterPressure",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3200,
                    "path": "data_pressureBoosterPressure",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "temperatureAfterTestObject": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Ausgangstemperatur Prüfobjekt (Grad Celsius)",
                            "languageTag": "de",
                            "name": "Ausgangstemperatur Prüfobjekt (Grad Celsius)",
                            "shortName": "Ausgangstemperatur Prüfobjekt (Grad Celsius)"
                        },
                        "en": {
                            "description": "Outlet temperature (Grad Celsius)",
                            "languageTag": "en",
                            "name": "Outlet temperature (Grad Celsius)",
                            "shortName": "Outlet temperature (Grad Celsius)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "temperatureAfterTestObject",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3280,
                    "path": "data_temperatureAfterTestObject",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "pressureBoosterTemperature": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Temperatur Druckbooster (Grad Celsius)",
                            "languageTag": "de",
                            "name": "Temperatur Druckbooster (Grad Celsius)",
                            "shortName": "Temperatur Druckbooster (Grad Celsius)"
                        },
                        "en": {
                            "description": "Temperature Pressure booster (Grad Celsius)",
                            "languageTag": "en",
                            "name": "Temperature Pressure booster (Grad Celsius)",
                            "shortName": "Temperature Pressure booster (Grad Celsius)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "pressureBoosterTemperature",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3180,
                    "path": "data_pressureBoosterTemperature",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "driveActualValue": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Istwertsignal des Antriebs",
                            "languageTag": "de",
                            "name": "Antrieb Istwert",
                            "shortName": "Anrieb Ist."
                        },
                        "en": {
                            "description": "Acutal Drive Position",
                            "languageTag": "en",
                            "name": "Drive Read Value",
                            "shortName": "Drive Read"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "driveActualValue",
                    "numerical": true,
                    "optional": true,
                    "ordering": 1400,
                    "path": "data_driveActualValue",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "pressureBoosterWaterlevel": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Füllstand Druckbooster (Prozent)",
                            "languageTag": "de",
                            "name": "Füllstand Druckbooster (Prozent)",
                            "shortName": "Füllstand Druckbooster (Prozent)"
                        },
                        "en": {
                            "description": "Water level Pressure booster (Percentage)",
                            "languageTag": "en",
                            "name": "Water level Pressure booster (Percentage)",
                            "shortName": "Water level Pressure booster (Percentage)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "pressureBoosterWaterlevel",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3190,
                    "path": "data_pressureBoosterWaterlevel",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "coldWaterAggregatePressure": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Druck Kaltwasseraggregat (Bar)",
                            "languageTag": "de",
                            "name": "Druck Kaltwasseraggregat (Bar)",
                            "shortName": "Druck Kaltwasseraggregat (Bar)"
                        },
                        "en": {
                            "description": "Coldwater pressure (Bar)",
                            "languageTag": "en",
                            "name": "Coldwater pressure (Bar)",
                            "shortName": "Coldwater pressure (Bar)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "coldWaterAggregatePressure",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3160,
                    "path": "data_coldWaterAggregatePressure",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "sampleDateTime_month": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Monat der Datenmessung (UTC).",
                            "languageTag": "de",
                            "name": "Zeitst. Prüf. (UTC): Monat",
                            "shortName": "Zeitst. Prüf. (UTC): Monat"
                        },
                        "en": {
                            "description": "Month of year when the sample has been taken (UTC).",
                            "languageTag": "en",
                            "name": "Sample Timestamp (UTC): Month of Year",
                            "shortName": "SampTS (UTC): Month/Year"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "sampleDateTime_month",
                    "numerical": false,
                    "optional": false,
                    "ordering": 600,
                    "path": "data_sampleDateTime_month",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "pressureAfterTestObject": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Ausgangsdruck Prüfobjekt (Bar)",
                            "languageTag": "de",
                            "name": "Ausgangsdruck Prüfobjekt (Bar)",
                            "shortName": "Ausgangsdruck Prüfobjekt (Bar)"
                        },
                        "en": {
                            "description": "Outlet pressure (Bar)",
                            "languageTag": "en",
                            "name": "Outlet pressure (Bar)",
                            "shortName": "Outlet pressure (Bar)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "pressureAfterTestObject",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3260,
                    "path": "data_pressureAfterTestObject",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "projectNumber": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Projektnummer",
                            "languageTag": "de",
                            "name": "Projektnummer",
                            "shortName": "Projektnr."
                        },
                        "en": {
                            "description": "Project number",
                            "languageTag": "en",
                            "name": "Project number",
                            "shortName": "Project no."
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "projectNumber",
                    "numerical": false,
                    "optional": true,
                    "ordering": 1600,
                    "path": "data_projectNumber",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "drivePlanValue": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Sollwert Signal des Antriebs",
                            "languageTag": "de",
                            "name": "Antrieb Sollwert",
                            "shortName": "Antriebe Soll."
                        },
                        "en": {
                            "description": "Actual Drive Setpoint",
                            "languageTag": "en",
                            "name": "Drive Setpoint",
                            "shortName": "Drive Set."
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "drivePlanValue",
                    "numerical": true,
                    "optional": true,
                    "ordering": 1300,
                    "path": "data_drivePlanValue",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "currentSwitchCounter": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "INTEGER",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Der Taktkreis Zähler.",
                            "languageTag": "de",
                            "name": "Taktkreis Zähler",
                            "shortName": "Taktkr. Zähler."
                        },
                        "en": {
                            "description": "Switch Count Actual",
                            "languageTag": "en",
                            "name": "Switch Count (Actual)",
                            "shortName": "Switch (Actual)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "currentSwitchCounter",
                    "numerical": true,
                    "optional": true,
                    "ordering": 900,
                    "path": "data_currentSwitchCounter",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "nennweite": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Nennweite des Ventils",
                            "languageTag": "de",
                            "name": "Nennweite",
                            "shortName": "Nennweite"
                        },
                        "en": {
                            "description": "Nominal Size of the Valve",
                            "languageTag": "en",
                            "name": "Nominal Size",
                            "shortName": "Nominal Size"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "nennweite",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2100,
                    "path": "data_nennweite",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "cavity": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Kavität",
                            "languageTag": "de",
                            "name": "Kavität",
                            "shortName": "Kavität"
                        },
                        "en": {
                            "description": "Cavity",
                            "languageTag": "en",
                            "name": "Cavity",
                            "shortName": "Cavity"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "cavity",
                    "numerical": false,
                    "optional": true,
                    "ordering": 3100,
                    "path": "data_cavity",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "testBenchId": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Alphanumerischer Schlüssel des Prüfstands.",
                            "languageTag": "de",
                            "name": "Prüfstand-ID",
                            "shortName": "Prüfstd.-ID"
                        },
                        "en": {
                            "description": "Alphanumeric identifier for a test bench.",
                            "languageTag": "en",
                            "name": "Test Bench ID",
                            "shortName": "Test Bench ID"
                        }
                    },
                    "key": true,
                    "measure": false,
                    "name": "testBenchId",
                    "numerical": false,
                    "optional": false,
                    "ordering": 200,
                    "path": "data_testBenchId",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "controlMedium": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "INTEGER",
                    "dimension": true,
                    "format": {
                        "en": {
                            "formatString": "0,0",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Antriebsansteuerung des Ventiles",
                            "languageTag": "de",
                            "name": "Antrieb",
                            "shortName": "Antrieb"
                        },
                        "en": {
                            "description": "Drivecontroller Valve",
                            "languageTag": "en",
                            "name": "Drivecontroller",
                            "shortName": "Drivecontroller"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "controlMedium",
                    "numerical": true,
                    "optional": true,
                    "ordering": 1200,
                    "path": "data_controlMedium",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "testTrackId": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Alphanumerischer Schlüssel des Prüfstrecke.",
                            "languageTag": "de",
                            "name": "Prüfstrecken-ID",
                            "shortName": "Prüfstr.-ID"
                        },
                        "en": {
                            "description": "Alphanumeric identifier for a test track.",
                            "languageTag": "en",
                            "name": "Test Track ID",
                            "shortName": "Test Track ID"
                        }
                    },
                    "key": true,
                    "measure": false,
                    "name": "testTrackId",
                    "numerical": false,
                    "optional": false,
                    "ordering": 300,
                    "path": "data_testTrackId",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "projectState": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Projektstatus",
                            "languageTag": "de",
                            "name": "Projektstatus",
                            "shortName": "Projektstatus"
                        },
                        "en": {
                            "description": "Project State",
                            "languageTag": "en",
                            "name": "Project State",
                            "shortName": "Project State"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "projectState",
                    "numerical": false,
                    "optional": true,
                    "ordering": 1700,
                    "path": "data_projectState",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "dichtungskennziffer": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Dichtungskennziffer",
                            "languageTag": "de",
                            "name": "Dichtungskennziffer",
                            "shortName": "Dichtungskennziffer"
                        },
                        "en": {
                            "description": "Sealcode",
                            "languageTag": "en",
                            "name": "Sealcode",
                            "shortName": "Sealcode"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "dichtungskennziffer",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2400,
                    "path": "data_dichtungskennziffer",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "supplyAirPressure": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Druckluftversogrung (Bar)",
                            "languageTag": "de",
                            "name": "Druckluftversogrung (Bar)",
                            "shortName": "Druckluftversogrung (Bar)"
                        },
                        "en": {
                            "description": "Supply Air pressure (Bar)",
                            "languageTag": "en",
                            "name": "Supply Air pressure (Bar)",
                            "shortName": "Supply Air pressure (Bar)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "supplyAirPressure",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3240,
                    "path": "data_supplyAirPressure",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "koerperwerkstoff": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Körperwerkstoff",
                            "languageTag": "de",
                            "name": "Körperwerkstoff",
                            "shortName": "Körperwerkstoff"
                        },
                        "en": {
                            "description": "Bodymaterial",
                            "languageTag": "en",
                            "name": "Bodymaterial",
                            "shortName": "Bodymaterial"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "koerperwerkstoff",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2200,
                    "path": "data_koerperwerkstoff",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "steamGeneratorPressure": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Versorgungsdruck Dampfaggregat (Bar)",
                            "languageTag": "de",
                            "name": "Versorgungsdruck Dampfaggregat (Bar)",
                            "shortName": "Versorgungsdruck Dampfaggregat (Bar)"
                        },
                        "en": {
                            "description": "Steamgenerator Supplypressure (Bar)",
                            "languageTag": "en",
                            "name": "Steamgenerator Supplypressure (Bar)",
                            "shortName": "Steamgenerator Supplypressure (Bar)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "steamGeneratorPressure",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3210,
                    "path": "data_steamGeneratorPressure",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "coldWaterAggregateTemperature": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Temperatur Kaltwasseraggregat (Grad Celsius)",
                            "languageTag": "de",
                            "name": "Temperatur Kaltwasseraggregat (Grad Celsius)",
                            "shortName": "Temperatur Kaltwasseraggregat (Grad Celsius)"
                        },
                        "en": {
                            "description": "Coldwater Temperature (Grad Celsius)",
                            "languageTag": "en",
                            "name": "Coldwater Temperature (Grad Celsius)",
                            "shortName": "Coldwater Temperature (Grad Celsius)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "coldWaterAggregateTemperature",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3150,
                    "path": "data_coldWaterAggregateTemperature",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "steamGeneratorTemperatureInLoop": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {
                        "attributeType": "TestBench"
                    },
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Temperatur Dampfringleitung (Grad Celsius)",
                            "languageTag": "de",
                            "name": "Temperatur Dampfringleitung (Grad Celsius)",
                            "shortName": "Temperatur Dampfringleitung (Grad Celsius)"
                        },
                        "en": {
                            "description": "Steamgenerator Inloop temperature (Grad Celsius)",
                            "languageTag": "en",
                            "name": "Steamgenerator Inloop temperature (Grad Celsius)",
                            "shortName": "Steamgenerator Inloop temperature (Grad Celsius)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "steamGeneratorTemperatureInLoop",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3230,
                    "path": "data_steamGeneratorTemperatureInLoop",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "anschlusskennziffer": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Anschlusskennziffer",
                            "languageTag": "de",
                            "name": "Anschlusskennziffer",
                            "shortName": "Anschlusskennziffer"
                        },
                        "en": {
                            "description": "Connection Value",
                            "languageTag": "en",
                            "name": "Connection Value",
                            "shortName": "Connection Value"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "anschlusskennziffer",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2300,
                    "path": "data_anschlusskennziffer",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "membrangroesse": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Membrangröße",
                            "languageTag": "de",
                            "name": "Membrangröße",
                            "shortName": "Membrangröße"
                        },
                        "en": {
                            "description": "Membransize",
                            "languageTag": "en",
                            "name": "Membransize",
                            "shortName": "Membransize"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "membrangroesse",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2700,
                    "path": "data_membrangroesse",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "siteId": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Die eindeutige ID des Standorts.",
                            "languageTag": "de",
                            "name": "Standort-ID",
                            "shortName": "Standort-ID"
                        },
                        "en": {
                            "description": "The unique ID of the site.",
                            "languageTag": "en",
                            "name": "Site ID",
                            "shortName": "Site ID"
                        }
                    },
                    "key": true,
                    "measure": false,
                    "name": "siteId",
                    "numerical": false,
                    "optional": false,
                    "ordering": 100,
                    "path": "data_siteId",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "TVID": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "TVID",
                            "languageTag": "de",
                            "name": "TVID",
                            "shortName": "TVID"
                        },
                        "en": {
                            "description": "TVID",
                            "languageTag": "en",
                            "name": "TVID",
                            "shortName": "TVID"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "TVID",
                    "numerical": false,
                    "optional": true,
                    "ordering": 1500,
                    "path": "data_TVID",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "temperatureInTestObject": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "DECIMAL",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0.00",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Temperatur im Prüfobjekt (Grad Celsius)",
                            "languageTag": "de",
                            "name": "Temperatur im Prüfobjekt (Grad Celsius)",
                            "shortName": "Temperatur im Prüfobjekt (Grad Celsius)"
                        },
                        "en": {
                            "description": "Inner object temperature (Grad Celsius)",
                            "languageTag": "en",
                            "name": "Inner object temperature (Grad Celsius)",
                            "shortName": "Inner object temperature (Grad Celsius)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "temperatureInTestObject",
                    "numerical": true,
                    "optional": true,
                    "ordering": 3300,
                    "path": "data_temperatureInTestObject",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "plannedSwitchCounter": {
                    "attributeType": "MEASURE",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "INTEGER",
                    "dimension": false,
                    "format": {
                        "en": {
                            "formatString": "0,0",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Taktkreis Ziel.",
                            "languageTag": "de",
                            "name": "Taktkreis Ziel",
                            "shortName": "Taktkr. Ziel"
                        },
                        "en": {
                            "description": "Switch Count Planned",
                            "languageTag": "en",
                            "name": "Switch Count (Planned)",
                            "shortName": "Switch (Plan)"
                        }
                    },
                    "key": false,
                    "measure": true,
                    "name": "plannedSwitchCounter",
                    "numerical": true,
                    "optional": true,
                    "ordering": 1000,
                    "path": "data_plannedSwitchCounter",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "parameters": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Parametersatz",
                            "languageTag": "de",
                            "name": "Parametersatz",
                            "shortName": "Parametersatz"
                        },
                        "en": {
                            "description": "Parameters",
                            "languageTag": "en",
                            "name": "Parameters",
                            "shortName": "Parameters"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "parameters",
                    "numerical": false,
                    "optional": true,
                    "ordering": 3000,
                    "path": "data_parameters",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "softwareVersion": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "STRING",
                    "dimension": true,
                    "format": {},
                    "i18n": {
                        "de": {
                            "description": "Softwareversion",
                            "languageTag": "de",
                            "name": "Softwareversion",
                            "shortName": "Softwareversion"
                        },
                        "en": {
                            "description": "Softwareversion",
                            "languageTag": "en",
                            "name": "Softwareversion",
                            "shortName": "Softwareversion"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "softwareVersion",
                    "numerical": false,
                    "optional": true,
                    "ordering": 2900,
                    "path": "data_softwareVersion",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                },
                "status": {
                    "attributeType": "DIMENSION",
                    "clientSettings": {
                        "displayTable": true,
                        "displayTile": true,
                        "exportActive": true,
                        "facetFilterActive": false,
                        "filterActive": true,
                        "sortActive": true,
                        "tooltipTemplate": null
                    },
                    "computation": null,
                    "computed": false,
                    "custData": {},
                    "datatype": "INTEGER",
                    "dimension": true,
                    "format": {
                        "en": {
                            "formatString": "0,0",
                            "languageTag": "en"
                        }
                    },
                    "i18n": {
                        "de": {
                            "description": "Das Statuskennzeichen",
                            "languageTag": "de",
                            "name": "Statuskennzeichen",
                            "shortName": "Statuskz."
                        },
                        "en": {
                            "description": "The status flag.",
                            "languageTag": "en",
                            "name": "Status flag",
                            "shortName": "Status"
                        }
                    },
                    "key": false,
                    "measure": false,
                    "name": "status",
                    "numerical": true,
                    "optional": true,
                    "ordering": 700,
                    "path": "data_status",
                    "temporal": false,
                    "temporalDimension": false,
                    "valueMapping": {}
                }
            },
            "i18n": {
                "de": {
                    "description": "Enthält die Messwerte für Langzeittests, welche mit hohen Umgebungstemperaturen ausgeführt werden.",
                    "languageTag": "de",
                    "name": "Langzeittests (HT)",
                    "shortName": "Langzeittests (HT)"
                },
                "en": {
                    "description": "Contains samples of long term endurance test processes which are executed within setups of high environment temperatures (in particular no extreme temperature settings will apply for the given tests).",
                    "languageTag": "en",
                    "name": "Endurance Tests (HT)",
                    "shortName": "Endurance Tests (HT)"
                }
            },
            "name": "EnduranceTestingHTSamples"
            };
        },


        timeSeries() {

            const palette = this.colorScale.colors(3);
            return {
                items: this.timeSeriesData,
                series: {

                    actual : {
                        name: 'actual',

                        visualSettings: { line : { color: palette[0] } },

                        i18n: {
                            'en' : {
                                name: 'Actual'
                            },
                            'de' : {
                                name: 'IST-Wert'
                            },

                        }
                    },

                    forecast: {
                        name: 'forecast',
                        visualSettings: { line : { color: palette[1]} },

                        i18n: {
                            'en' : {
                                name: 'Forecast'
                            },
                            'de' : {
                                name: 'Prognose'
                            },

                        }
                    },

                    toleranceUpper: {
                        name: 'toleranceUpper',
                        visualSettings: { line : { color: palette[2]} },
                        i18n: {
                            'en' : {
                                name: 'Tolerance (Upper)'
                            },
                            'de' : {
                                name: 'Toleranz (oben)'
                            },

                        }
                    }
                }
            };
        },

        timeSeriesData() {

            
            
            const now = DateTime.now();
            const timeSeriesData =  [];
            for (let i = 0; i < 200; i++) {
                timeSeriesData.push({
                    dateTime: now.plus({minutes: i}).toISO(),
                    values: {
                        actual: Math.random() * 10,
                        forecast: Math.random() * 8,
                        toleranceUpper: 11
                    }
                })
            } 

            return  timeSeriesData;
        },

        histogramData() {
            return  [
                { range: {from: 0.0, to: 0.5},   count: 612 },
                { range: {from: 0.5, to: 35},   count: 312 },
                { range: {from: 35, to: 35.9},   count: 788 },
                { range: {from: 35, to: 36.8}, count: 400},
                { range: {from: 36.8, to: 277.0}, count: 930 },
                { range: {from: 36.8, to: 277.0}, count: 930 },
                { range: {from: 36.8, to: 277.0}, count: 850 },
                { range: {from: 36.8, to: 277.0}, count: 930 }, 
                { range: {from: 36.8, to: 277.0}, count: 930 }
            ];
        },


    }
}
</script>

<i18n>
{
    "en" : {

        "time-series-dashboard.view.sections.groups.title" : "Groups",
        "time-series-dashboard.view.sections.groups.subtitle" : "Displaying Time Series for {Metrics}. Please click the link on each card for a detailed view.",
        "time-series-cockpit.view.sections.timeseries.title" : "Time Series",
        "time-series-cockpit.view.sections.distribution.title" : "Histogram",
        "time-series-cockpit.view.sections.table.title" : "Table",

        "time-series-cockpit.view.breadcrumbs.root" : "Time Series"
    },

    "de" : {
        "time-series-cockpit.view.sections.timeseries.title" : "Zeitreihe",
        "time-series-cockpit.view.sections.distribution.title" : "Datenverteilung",
        "time-series-cockpit.view.sections.table.title" : "Detailwerte",

        "time-series-cockpit.view.breadcrumbs.root" : "Zeitreihen"
    }
}
</i18n>