<template>
    <c-item-editor-page :title="$t('conformity-editor-page.component.title')"
        :subtitle="$t('conformity-editor-page.component.subtitle')" class="mb-5" flat>
        <v-card-text>
            <v-text-field v-model="euDeclarationOfConformityId" color="accent" :disabled="disabled"
                :label="$t('conformity-editor-page.component.fields.euDeclarationOfConformityId.title')" persistent-hint
                :hint="$t('conformity-editor-page.component.fields.euDeclarationOfConformityId.hint')" filled />

            <v-text-field :disabled="disabled" v-model="euDeclarationOfConformityLink" color="accent"
                :label="$t('conformity-editor-page.component.fields.euDeclarationOfConformityLink.title')" persistent-hint
                :hint="$t('conformity-editor-page.component.fields.euDeclarationOfConformityLink.hint')" filled
                prepend-inner-icon="link" />
        </v-card-text>
    </c-item-editor-page>
</template>

<script>
import { cloneDeep, get, set } from 'lodash';

export default {

    name: 'conformity',

    props: {
        value: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            model: cloneDeep(this.value)
        };
    },

    computed: {

        euDeclarationOfConformityId: {
            get() {
                return get(this.model, 'euDeclarationOfConformityId');
            },

            set(value) {
                set(this.model, 'euDeclarationOfConformityId', value);
                this.fireInput();
            }
        },

        euDeclarationOfConformityLink: {
            get() {
                return get(this.model, 'euDeclarationOfConformityLink');
            },

            set(value) {
                set(this.model, 'euDeclarationOfConformityLink', value);
                this.fireInput();
            }
        },
    },

    methods: {

        fireInput() {

            this.$emit('input', cloneDeep(this.model));
        }
    },

}
</script>

<i18n>
{
    "en" : {
        "conformity-editor-page.component.title" : "Conformity",
        "conformity-editor-page.component.subtitle" : "This page contains information related to the conformity requirements.",

        "conformity-editor-page.component.fields.euDeclarationOfConformityId.title": "EU Declaration Of Conformity ID",
        "conformity-editor-page.component.fields.euDeclarationOfConformityId.hint": "The Battery Carbon Footprint Declaration shall refer to the identification number of the EU declaration of conformity (EU Battery Passport Attribute #11).",
        "conformity-editor-page.component.fields.euDeclarationOfConformityLink.title": "Link to EU Declaration Of Conformity Document",
        "conformity-editor-page.component.fields.euDeclarationOfConformityLink.hint": "The EU declaration of conformity shall state that the requirements of the Battery Regulation have been fulfilled (EU Battery Passport Attribute #10)." 

    },

    "de" : {
        "conformity-editor-page.component.title" : "Konformität",
        "conformity-editor-page.component.subtitle" : "Die Seite enthält Informationen hinsichtlich der Anforderungen an die Konformität.",
        "conformity-editor-page.component.fields.euDeclarationOfConformityId.title": "ID der EU-Konformitätserklärung",
        "conformity-editor-page.component.fields.euDeclarationOfConformityId.hint": "Die Erklärung zum CO2-Fußabdruck der Batterie muss sich auf die Identifikationsnummer der EU-Konformitätserklärung beziehen (EU-Batteriepass-Attribut Nr. 11).",
        "conformity-editor-page.component.fields.euDeclarationOfConformityLink.title": "Link zum Dokument der EU-Konformitätserklärung",
        "conformity-editor-page.component.fields.euDeclarationOfConformityLink.hint": "In der EU-Konformitätserklärung muss angegeben werden, dass die Anforderungen der Batterieverordnung erfüllt sind (EU-Batteriepass-Attribut Nr. 10)." 
    }
}
</i18n>