import api from '@/store/api';

export default {

    namespaced: true,

    state: {

        collectionSettings: [],
        request: {
            name: "",
            collectionSettingsId: "",
            targetAttribute : "",
            i18n: {},
            customData: {}
        }
    },

    mutations: {

        collectionSettings(state, collectionSettings) {

            if (collectionSettings) {
                state.collectionSettings = collectionSettings
            }
        },

        setRequest(state, request) {
            if (request) {
                state.request = request
            }
        },
    },

    actions: {

        async loadCollectionSettings(context) {

            try {

                let collectionSettings = await api.catalog.findAllCollectionSettings();
                context.commit('collectionSettings', collectionSettings);

            } catch (error) {

                console.log("An error has occured while fetching collection settings: " + error);

                // Pass an empty response so nothing will be added actually.
                context.commit('collectionSettings', []);
            }
        },

        async createPredictionScenario(context) {
            try {

                await api.prediction.createPredictionScenario(context.state.request.name, context.state.request);

            } catch (error) {

                console.log("An error has occured while creating prediction scenario" + error);
            }
        },
    },

    getters: {

        collectionSettings(state) {
            return state.collectionSettings.map((e) => e.collectionSettings);
        },

        schemas(state) {
            return state.collectionSettings.map((e) => e.schema);
        }
    }
}