import api from '@/store/api';
import { get, set } from 'lodash';

export default {

    namespaced: true,

    state : {
        userListTemplate: {},

        schema: {},

        collectionSettings: {}
    },

    mutations: {

        setUserListTemplate(state, userListTemplate) {
            set(state, 'userListTemplate', userListTemplate);
        },

        setSchema(state, schema) {
            set(state, 'schema', schema);
        },

        setCollectionSettings(state, collectionSettings) {
            set(state, 'sourceCollection', collectionSettings);
        },

        setBookmark(state, bookmark) {
            set(state, 'userListTemplate.bookmark', bookmark);
        }
    },

    actions: {
    
        async loadData(context, { name, verifyUser }) {
            
            try {
                let response = {};
                if(verifyUser){
                    response = await api.me.listTemplates.findByName(name);
                }else{
                    response = await api.catalog.userListTemplates.findByName(name);
                }
                context.commit('setUserListTemplate', get(response, 'data.template', {}));
                context.commit('setSchema', get(response, 'data.schema', {}));
                context.commit('setCollectionSettings', get(response, 'data.collection', {}));
            } catch (error) {
                context.commit('setUserListTemplate', {});
                throw error;
            }

        },

        async saveData(context, {name, verifyUser}) {

            try {

                const payload = context.getters.payload;
                let response = {};
                if(verifyUser){
                    response = await api.me.listTemplates.update(name, payload);
                }else{
                    response = await api.catalog.userListTemplates.update(payload.userId, payload.collectionId, payload.name, payload);
                }
                
                context.commit('setUserListTemplate', get(response, 'data.template', context.state.userListTemplate));

            } catch (error) {
                context.commit('setUserListTemplate', context.state.userListTemplate);
                throw error;

            }
        },

        updateUserListTemplate(context, template) {
            context.commit('setUserListTemplate', template);
        },

        updateBookmark(context, bookmark) {
            context.commit('setBookmark', bookmark);
        }
        
    },

    getters: {
        model(state) {

            return {
                userListTemplate: state.userListTemplate,
                schema: state.schema,
                collectionSettings: state.collectionSettings
            };
        },

        payload(state) {
            let payload = get(state, 'userListTemplate', {});
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        bookmark(state) {
            return get(state, 'userListTemplate.bookmark', {});
        }
   
    }
}
