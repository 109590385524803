
const MODULE_NAME = 'documentDetail';

import { get, isEmpty } from 'lodash';

export default {

    inject: ['progressIndicatorService', 'envContextService'],

    // ==================================================================
    // METHODS
    // ==================================================================
    props: {

        value: {
            type: Object,
            required: true,
        }
    },

    // ==================================================================
    // METHODS
    // ==================================================================
    methods: {
        
        async refresh() {

            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData', {
                   
                    collectionId : this.value.collectionId,
                    documentId: this.value.documentId,
                });
                
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        getSchemaForEmbeddedCollection(embeddedCollection) {
            return this.embeddedSchemas[embeddedCollection.name] 
                 ? this.embeddedSchemas[embeddedCollection.name]
                 : {};
        },

        getDocumentsForEmbeddedCollection(embeddedCollection) {

            return this.embeddedDocuments[embeddedCollection.name]
                ? this.embeddedDocuments[embeddedCollection.name]
                : [];
        },
    },

    
    // ==================================================================
    // COMPUTED
    // ==================================================================
    computed: {

        documentId() {
            return get(this.value, 'documentId');
        },

        response() {

            let response = this.$store.getters[MODULE_NAME + '/response'];
            return response;
        },
    
        document() {
    
            return this.response.document ? this.response.document : {};
        },

        data() {
            return get(this.document, 'data', {});
        },
    
        meta() {
    
            return this.response.meta ? this.response.meta : {};
        },
    
        collectionSettings() {
            return this.response.collectionSettings ? this.response.collectionSettings : {};
        },
    
        schema() {
            return this.response.schema ? this.response.schema : {};
        },
    
        measures() {
            return this.$dxs.attributes.getAttributeListFromSchema(this.schema, e => e.attributeType === 'MEASURE');
        },
    
        embeddedCollections() {
            return this.collectionSettings.embeddedCollections ? this.collectionSettings.embeddedCollections : {}; 
        },
    
        embeddedCollectionsEmpty() {
            return isEmpty(this.embeddedCollections);
        },
    
        embeddedSchemas() {
            return this.response.embeddedSchemas ? this.response.embeddedSchemas : {};
        },
    
        embeddedDocuments() {
            return this.document.embedded ? this.document.embedded : {};
        },
    
        embeddedDocumentsEmpty() {
            return isEmpty(this.embeddedDocuments);
        },
    
        indexName() {
            return this.envContextService.tenantId + '-' + this.collectionSettings.name.toLowerCase() + '-' + this.document.partitionId;
        }
    },

    // ==================================================================
    // LIFECYCLE
    // ==================================================================
    created() {
        
        // Refresh will trigger loading of the collection settings information.
        this.refresh();
    },
 
}