import api from '../../../api/battery-data-hub.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        batteryStatus: createEmptyBaterryStatus(),
        tenantId: null,
        statusColor: null
    },

    mutations: {

        setBatteryStatus(state, batteryStatus) {

            if (batteryStatus) {

                state.batteryStatus.tenantId = batteryStatus.tenantId ? batteryStatus.tenantId : null;
                state.batteryStatus.name = batteryStatus.name ? batteryStatus.name : null;
                state.batteryStatus.i18n = batteryStatus.i18n ? batteryStatus.i18n : {};
                state.batteryStatus.active = batteryStatus.active ? batteryStatus.active : true;
                state.batteryStatus.references = batteryStatus.references ? batteryStatus.references : {};
                state.batteryStatus.data.color = batteryStatus.data.color ? batteryStatus.data.color : null;

            } else {
                state.batteryStatus.tenantId = null;
                state.batteryStatus.name = null;
                state.batteryStatus.i18n = null;
                state.batteryStatus.data = null;
                state.batteryStatus.active = null;
                state.batteryStatus.references = null;
                state.batteryStatus.data.color = null;
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.batteryStatus.tenantId = tenantId;

            } else {
                state.batteryStatus.tenantId = null;
            }
        },

        setColor(state, statusColor) {
            if (statusColor) {
                state.batteryStatus.data.color = statusColor;
            }
        }


    },

    actions: {

        async createBatteryStatus(context) {

            try {

                let request = context.getters.request;
                await api.masterdata.batteryStatus.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetBatteryStatus(context) {
            context.commit('setBatteryStatus', createEmptyBaterryStatus());
        },

        async updateBatteryStatus(context, batteryStatus) {

            try {
                context.commit('setBatteryStatus', batteryStatus);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateColor(context, color) {
            try {
                context.commit('setColor', color);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.batteryStatus;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        batteryStatus(state) {
            return state.batteryStatus ? state.batteryStatus : createEmptyBaterryStatus();
        },

        statusColor(state) {
            return state.batteryStatus.data.color ? state.batteryStatus.data.color : null;
        }

    }
}

function createEmptyBaterryStatus() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": {
            "color": null
        },
        "active": true,
        "references": {}

    }
}