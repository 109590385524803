import api from '../../../api/battery-data-hub.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        hazardousSubstanceClassification: createEmptyHazardousSubstanceClassification(),
        tenantId: null
    },

    mutations: {

        setHazardousSubstanceClassification(state, hazardousSubstanceClassification) {

            if (hazardousSubstanceClassification) {

                state.hazardousSubstanceClassification.tenantId = hazardousSubstanceClassification.tenantId ? hazardousSubstanceClassification.tenantId : null;
                state.hazardousSubstanceClassification.name = hazardousSubstanceClassification.name ? hazardousSubstanceClassification.name : null;
                state.hazardousSubstanceClassification.i18n = hazardousSubstanceClassification.i18n ? hazardousSubstanceClassification.i18n : {};
                state.hazardousSubstanceClassification.data = hazardousSubstanceClassification.data ? hazardousSubstanceClassification.data : { custData: {} };
                state.hazardousSubstanceClassification.active = hazardousSubstanceClassification.active ? hazardousSubstanceClassification.active : true;
                state.hazardousSubstanceClassification.references = hazardousSubstanceClassification.references ? hazardousSubstanceClassification.references : {};

            } else {
                state.hazardousSubstanceClassification.tenantId = null;
                state.hazardousSubstanceClassification.name = null;
                state.hazardousSubstanceClassification.i18n = null;
                state.hazardousSubstanceClassification.data = null;
                state.hazardousSubstanceClassification.active = null;
                state.hazardousSubstanceClassification.references = null;
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        }


    },

    actions: {

        async createHazardousSubstanceClassification(context) {

            try {

                let request = context.getters.request;
                await api.masterdata.hazardousSubstanceClassifications.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetHazardousSubstanceClassification(context) {
            context.commit('setHazardousSubstanceClassification', createEmptyHazardousSubstanceClassification());
        },

        async updateHazardousSubstanceClassification(context, hazardousSubstanceClassification) {

            try {
                context.commit('setHazardousSubstanceClassification', hazardousSubstanceClassification);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.hazardousSubstanceClassification;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        hazardousSubstanceClassification(state) {
            return state.hazardousSubstanceClassification ? state.hazardousSubstanceClassification : createEmptyHazardousSubstanceClassification();
        }

}
}

function createEmptyHazardousSubstanceClassification() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { custData: {} },
        "active": true,
        "references": {}

    }
}