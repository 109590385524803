export default [
    {
        name: 'METRIC',
        i18n : {
            'en' : {
                name: 'Metric',
                shortName: 'Metric',
                description: 'Displays only the numeric value of the given metric.'
            },

            'de' : {
                name: 'Metrik',
                shortName: 'Metrik',
                description: 'Stellt nur den numerischen Wert der ausgewählten Metrik dar.'
            }
        }
    },

    {
        name: 'METRIC_TREND',
        i18n : {
            'en' : {
                name: 'Metric and Trend Line Chart',
                shortName: 'Metric and Trend',
                description: 'Displays the numeric value along with a line chart.'
            },

            'de' : {
                name: 'Metrik und Trend-Diagramm',
                shortName: 'Metrik und Trend',
                description: 'Stellt den numerischen Wert und zusätzlich ein Liniendiagramm dar.'
            }
        }
    },


]