<template>
    <v-overlay :absolute="false" :value="loading">
        <c-data-loading-progress
            :rotate="rotate"
            :size="size"
            :width="width">
            
            <template v-if="text">
                {{text}}
            </template>
        </c-data-loading-progress>
    </v-overlay>
</template>

<script>
import isNull from 'lodash/isNull';
import get from 'lodash/get';

export default {

    props: {

        loading: {
            type: Boolean,
            required: true
        },

        label: {
            type: [String, Object],
            required: false
        },

        options: {
            type: Object,
            required: false,
            default: () => ({
                rotate: 0,
                sizeProgress: 250,
                sizeDefault: 75,
                width: 3
            })
        }
    },

    computed: {
        rotate() {
            return this.options.rotate;
        },

        sizeProgress() {
            return this.options.sizeProgress;
        },

        sizeDefault() {
            return this.options.sizeDefault;
        },

        width() {
            return this.options.width;
        },

        size() {
            return this.label ? this.sizeProgress : this.sizeDefault;
        },

        text() {
            if (this.label instanceof Object) {
                const from = get(this.label, 'from', null);
                const to = get(this.label, 'to', null);

                if (!isNull(from) && !isNull(to)) {
                    return `${from} / ${to}`; 
                } else {
                    if (this.label['toString']) {
                        return this.label.toString();
                    } else {
                        return JSON.stringify(this.label);
                    }
                }
            } else {
                return this.label;
            }
        }
    }
}
</script>
