import api from '@/store/api';

import { get } from 'lodash';

export default {

    namespaced: true,

    state: {

        dateHistogramDistribution: {}
    },

    mutations: {
        
        setDateHistogramDistribution(state, dateHistogramDistribution) {
            state.dateHistogramDistribution = dateHistogramDistribution;
        },

        clear(state){
            state.dateHistogramDistribution = {}
        }
    },

    actions: {
        
        
        async loadCollectionDateHistogramDistribution(context, { collectionId, filter, histogramSize, dateTimeDimensionId, timeInterval}) {
            
            // First reset the previously loaded results.
            context.commit('setDateHistogramDistribution', []);

                try {
                    const response = await api.collections.distribution.getCollectionHistogramDistribution(collectionId, filter, histogramSize, dateTimeDimensionId, timeInterval);
                    context.commit('setDateHistogramDistribution', get(response, 'data.value', {}));
                } catch (error) {
                    console.log(error);
                }
        },

        async loadDateHistogramDistribution(context, { collectionId, filter, histogramSize, dateTimeDimensionId, timeInterval, dimensions}) {
            
            // First reset the previously loaded results.
            context.commit('setDateHistogramDistribution', []);

            try {
                const response = await api.collections.dimensions.getHistogramDistribution(collectionId, filter, histogramSize, dateTimeDimensionId, timeInterval, dimensions);
          
                context.commit('setDateHistogramDistribution', get(response, 'data.value', {}));
            } catch (error) {
                console.log(error);
            }
        },

        async clearHistogramData(context) {
            context.commit('clear');
        },

        
    },

    getters: {

        model(state) {
            return {
                dateHistogramDistribution : state.dateHistogramDistribution
            }
        },

        
    }

}
