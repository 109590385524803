<template>
  <v-sheet >
    <v-sheet color="error" class="text-center pa-5 white--text" v-if="unavailableServices.length > 0">
      <v-icon color="white">error</v-icon>
      {{$t('service-availability-dashboard.link.label')}}
      <v-btn depressed color="white" small :to="'/system/serviceAvailability/'">
        {{$t('service-availability-dashboard.view.title')}}
      </v-btn>
    </v-sheet>
    <slot></slot>
  </v-sheet>
</template>

// <script>
import { get } from "lodash";

const MODULE_NAME = "serviceAvailability";
export default {
  inject: [
    "errorHandlerService",
    "progressIndicatorService",
    "messageService",
    "envContextService"
  ],
  computed: {
    response() {
      return this.$store.getters[MODULE_NAME + "/response"];
    },
    items() {
      return get(this.response, "items", []);
    },
    unavailableServices() {
      let services = []
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].status == "DOWN") {
          services.push(this.items[i].name);
        }
      }
      return services;
    },
    interval(){
      return this.envContextService.serviceHealthCheckIntervalMS;
    }
  },
  methods: {
    async refresh() {
      try {
        await this.$store.dispatch(MODULE_NAME + "/loadResponse");
      } catch (error) {
        // DXS-543: Stop the error dialog from appearing when the background process for loading the data of the service dashboard fails
        //this.errorHandlerService.handleError(error);
        // Instead log the error.
        this.$log.error("Failed to load data for service dashboard.", error);
        
      }
    }
  },

created(){
  this.refresh();
  this.timer = setInterval(this.refresh, this.interval);
}

}
</script>

<i18n>
{
    "en":{
        "service-availability-dashboard.view.title" : "Further Info...",
        "service-availability-dashboard.link.label" : "The connection to the application is temporarily not available. Some information my not be available."
    },
    "de":{
        "service-availability-dashboard.view.title" : "Weitere Infos...",
        "service-availability-dashboard.link.label" : "Die Verbindung zur Anwendung ist vorübergehend gestört. Es stehen eventuell nicht alle Informationen zur Verfügung."
    }
}
</i18n>