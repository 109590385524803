<template>
    <v-card tile flat color="grey lighten-3">
            <v-card-text class="pa-5">
                <div class="text-center"><v-icon color="accent" x-large>arrow_circle_up</v-icon></div>
                <div class="text-h4 text-center grey--text">{{$t('send-query-hint-banner.component.noData.title')}}</div>
                <div class="text-center body-2 grey--text">{{$t('send-query-hint-banner.component.noData.subtitle')}}</div>
            </v-card-text>
            <v-container fluid>
            <v-row justify="center">
                <v-col cols="10" lg="6">
                    <v-card class="ma-5">
                        <v-card-text class="text-center">
                            <strong>{{$t('send-query-hint-banner.component.noData.hint.title')}}:</strong> {{$t('send-query-hint-banner.component.noData.hint.text')}}
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {

}
</script>

<i18n>
{
    "en" : {

        "send-query-hint-banner.component.noData.title" : "Where is my Data?",
        "send-query-hint-banner.component.noData.subtitle" : "Your data is only a few clicks away. Please enter a filter expression and then press Search in order to display available documents.",
        "send-query-hint-banner.component.noData.hint.title" : "Hint",
        "send-query-hint-banner.component.noData.hint.text" : "Use the expression * in order to search for all documents without applying filter conditions."
    },

    "de" : {

        "send-query-hint-banner.component.noData.title" : "Wo sind meine Daten?",
        "send-query-hint-banner.component.noData.subtitle" : "Ihre Dokumente sind nur einen Knopfdruck entfernt: Bitte geben Sie einen Suchausdruck ein und drücken Sie anschließend die Schaltfläche Suchen um die Dokumente anzuzeigen.",
        "send-query-hint-banner.component.noData.hint.title" : "Hinweise",
        "send-query-hint-banner.component.noData.hint.text" : "Verwenden Sie den Suchausdruck * um alle Dokumente ohne Einschränkung zu suchen."
    }
}
</i18n>