import api from '../../../api/smart-factory.api';
import { get } from 'lodash';

export default {
    namespaced: true,

    state: {

        workplaceType: createDefaultWorkplaceType(),
    },

    mutations: {

        setWorkplaceType(state, workplaceType) {

            if (workplaceType) {
                state.workplaceType.tenantId = workplaceType.tenantId;
                state.workplaceType.name = workplaceType.name;
                state.workplaceType.i18n = workplaceType.i18n;
                state.workplaceType.data = workplaceType.data;
                state.workplaceType.active = workplaceType.active;
                state.workplaceType.creationDateTime = workplaceType.creationDateTime;
                state.workplaceType.creationUser = workplaceType.creationUser;
                state.workplaceType.modificationDateTime = workplaceType.modificationDateTime;
                state.workplaceType.modificationUser = workplaceType.modificationUser;
            }

        }

    },

    actions: {


        async loadData(context, { name }) {
            try {
                let response = await api.masterdata.workplaceTypes.findById(name);

                context.commit('setWorkplaceType', get(response, 'item.workPlaceType', createDefaultWorkplaceType()));

            } catch (error) {
                context.commit('setWorkplaceType', createDefaultWorkplaceType());
                throw error;
            }
        },

        async saveData(context) {

            try {

                const payload = get(context.getters.model, 'workplaceType');
                console.log(">>> VUEX SAVE DATA ", JSON.stringify(payload));
                let response = await api.masterdata.workplaceTypes.update(payload);
                context.commit('setWorkplaceType', get(response, 'item.workPlaceType', createDefaultWorkplaceType()));

            } catch (error) {
                console.log(error);
                context.commit('setWorkplaceType', createDefaultWorkplaceType());
                throw error;

            }
        },


        updateModel(context, model) {
            console.log('+++ updateModel ', JSON.stringify(model, true, 2));
            context.commit('setWorkplaceType', get(model, 'workplaceType', createDefaultWorkplaceType()));
        },

        updateWorkplaceType(context, workplaceType) {
            console.log('+++ updateWorkplaceType ', JSON.stringify(workplaceType, true, 2));
            context.commit('setWorkplaceType', workplaceType);
        }
    },

    getters: {

        model(state) {
            return {
                workplaceType: state.workplaceType
            }
        },

    }
}

function createDefaultWorkplaceType() {
    return {
        tenantId: null,
        name: null,
        i18n: null,
        data: {},
        active: null,
        references: {},
        creationDateTime: null,
        creationUser: null,
        modificationDateTime: null,
        modificationUser: null,
    }
}