<template>
    <v-sheet class="grey lighten-4 px-0">
        <c-sticky-content>
            <v-sheet class="grey lighten-4 px-0">
            <v-container fluid>
                <c-filter-query-input
                        v-model="filterQuery" 
                        track-by="filterQuery" 
                        :value="{ query: filter.filterQuery }"
                        :disabled="loading"
                        :loading="loading"
                        @filter-query-changed="onFilterQueryChange"/>
            </v-container>

            <v-divider />                

            <v-toolbar flat color="white">
                <v-toolbar-title>{{$t('collection-series.view.results', {totalItemCount: totalItemCountFormatted})}}</v-toolbar-title>
                <v-btn icon @click="querySummary = !querySummary">
                    <v-icon v-if="!querySummary">expand_more</v-icon>
                    <v-icon v-else>expand_less</v-icon>
                </v-btn>
                
                <v-spacer />

                <v-toolbar-items>
                    <v-btn icon @click="loadData">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-btn icon @click="filterDialog = !filterDialog">
                        <v-icon>filter_list</v-icon>
                    </v-btn>

                    <v-menu top>
                        <template v-slot:activator="{ on }">
                            <v-btn :disabled="!response.data && !form.sent" icon v-on="on">
                                <v-icon>print</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="goToPrintView('ALL')">
                                <v-list-item-icon><v-icon>print</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('collection-series.view.print.diagramTable')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="goToPrintView('DIAGRAMM')">
                                <v-list-item-icon><v-icon>print</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('collection-series.view.print.diagram')}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu top>
                        <template v-slot:activator="{ on }">
                            <v-btn :disabled="!response.data && !form.sent" icon v-on="on">
                                <v-icon>cloud_download</v-icon>
                            </v-btn>
                        </template>

                        <v-list>
                            <v-list-item @click="exportDocument('CSV')">
                                <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                                <v-list-item-title>{{$t('collection-documents.view.actions.export.csv', $i18n.locale)}}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-toolbar-items>
            </v-toolbar>

            </v-sheet>
            <v-divider />
            <v-expand-transition>
            <v-card v-show="querySummary" tile flat>
                <c-query-summary :value="{filter: filter, meta: schema}" />
            </v-card>
            
        </v-expand-transition>
        
        </c-sticky-content>

        

        

        <v-card tile flat>
            <!-- Form -->
            <v-container fluid>
                <v-row>
                    <v-col><c-attribute-single-selector v-model="attribute" :items="{...attributes}" :filled="true" /></v-col>
                    <v-col><v-select filled :label="$t('collection-series.view.max-num-cols.label')" :items="intervals" v-model="interval" /></v-col>
                    <v-col><v-select filled :label="$t('collections.common.view.sort-by.title')" :items="sort" item-text="text" item-value="value" v-model="selectedSort" /></v-col>
                </v-row>

                <v-card-actions class="px-0">
                     <v-btn :disabled="!form.valid" block color="accent" v-on:click="loadData">
                         <v-icon class="mr-2">input</v-icon>
                        {{$t('collection-time-series.view.showChart.title')}}
                    </v-btn>
                </v-card-actions>
            </v-container>

            <!-- Form invalid / never submitted -->
            <v-container v-if="!response.data && !form.sent">
                <v-card-text>
                    <p />
                    <div class="text-center"><v-icon color="primary" large>info</v-icon></div>
                    <div class="title text-center">{{$t('collection-distribution.view.missingConfig.title')}}</div>
                    <div class="text-center">{{$t('collection-distribution.view.missingConfig.subtitle')}}</div>
                    <div class="text-center">
                        <v-chip v-for="formField in formFields" :outlined="!formField.isValid" :key="formField.name"
                        class="ma-2"
                        :input-value="formField.isValid"
                        filter
                        >{{formField.text}}</v-chip>
                    </div>
                </v-card-text>
                <v-card-text />
            </v-container>

            <!-- no data -->
            <template v-else-if="!response.data">
                <c-empty-result-tile />
            </template>

            <!-- show the results -->
            <template v-else>
                <v-card-text>
                    <template v-if="response.data.datatype === 'DATE' || response.data.datatype === 'DATETIME'">
                        <!-- temporial data -->
                        <c-date-histogram-chart ref="chart" :value="sortedBuckets"></c-date-histogram-chart>
                    </template>
                    <template v-else-if="response.data.datatype === 'DECIMAL' || response.data.datatype === 'INTEGER'">
                        <!-- numeric data -->
                        <c-numeric-histogram-chart ref="chart" :value="sortedBuckets"></c-numeric-histogram-chart>
                    </template>
                    <template v-else>
                        <!-- categorial data -->
                        <c-treemap-histogram-chart ref="chart" :value="sortedBuckets"></c-treemap-histogram-chart>
                    </template>
                </v-card-text>

                <!-- Table -->
                <v-card-text>
                    <v-divider />
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="text-left">{{$t('collections.common.view.value.title')}}</th>
                                <th class="text-right">{{$t('collections.common.view.frequency-absolute.title')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="bucket in sortedBuckets" :key="bucket.name">
                                <td class="text-left">{{bucket.name}}</td>
                                <td class="text-right">{{bucket.count | integer-format($i18n.locale)}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </template>
        </v-card>

        <!-- Filter Settings Sidebar -->
        <v-navigation-drawer
            fixed
            right
            temporary
            v-model="filterDialog"
            width="500"
            >
                <c-filter-settings :value="{meta: schema, filter: filter}" @filter-changed="onFilterChanged" />
        </v-navigation-drawer>
</v-sheet>
</template>

<script>
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const MODULE_NAME = 'collectionDistribution';

export default {

    name: 'collection-distribution',

    inject: ['shellState', 'progressIndicatorService', 'errorHandlerService', 'messageService'],


    props: {
        value: {
            type: Object,
            required: false,
        }        
    },

    data () {
        return {
            querySummary: false,
            filterDialog: false,
            filterQuery: {query: ''},
            intervals: [5, 10, 15, 20, 25, 30, 45, 50, 60, 70]            
        }
    },

    computed: {
        loading() {
            return this.shellState.loading;
        },

        sort() {
            return [
                {
                    value: 'name-asc',
                    property: 'name',
                    text: `${this.$t('collections.common.view.value.title')} ${this.$t('collections.common.view.sort.asc.title')}`,
                    sort: 'ASC'
                },
                {
                    value: 'name-desc',
                    property: 'name',
                    text: `${this.$t('collections.common.view.value.title')} ${this.$t('collections.common.view.sort.desc.title')}`,
                    sort: 'DESC'
                },
                {
                    value: 'count-asc',
                    property: 'count',
                    text: `${this.$t('collections.common.view.frequency-absolute.title')} ${this.$t('collections.common.view.sort.asc.title')}`,
                    sort: 'ASC'
                },
                {
                    value: 'count-desc',
                    property: 'count',
                    text: `${this.$t('collections.common.view.frequency-absolute.title')} ${this.$t('collections.common.view.sort.desc.title')}`,
                    sort: 'DESC'
                }
            ]
        },

        collectionId() {
            return this.value ? this.value.collectionId : null;
        },

        collectionSettings() {
            return this.$store.getters[MODULE_NAME + "/collectionSettings"]
        },

        schema() {
            return this.$store.getters[MODULE_NAME + "/schema"]
        },

        attributes() {
            const attributes = get(this.schema, 'attributes', {});
            const attributeList = [];
            Object.keys(attributes).forEach(key => {
                attributeList.push(attributes[key]);
            });

            return attributeList;
        },

        response() {
            return this.$store.getters[MODULE_NAME + '/response'] || {};
        },

        buckets() {
            return get(this.response, 'data.buckets', []);
        },

        sortedBuckets() {
            const buckets = this.buckets;
            return buckets.sort((a, b) => {
                const selectedProperty = this.selectedSort.property;
                const property = 
                    selectedProperty === 'name' && 
                    a.sortValue > -1 ? 'sortValue' : selectedProperty;

                const sortA = a[property];
                const sortB = b[property];

                if (this.selectedSort.sort === 'ASC') {
                    if (typeof sortA === 'number' && typeof sortB === 'number') return sortA - sortB;
                    return sortA.localeCompare(sortB);
                } else {
                    if (typeof sortA === 'number' && typeof sortB === 'number') return sortB - sortA;
                    return sortB.localeCompare(sortA);
                }
            });               
        },

        pagination() {
            return get(this.response, 'data.pagination', {});
        },

        totalItemCount() {
            return this.pagination.totalItemCount || 0;
        },

        
        totalItemCountFormatted() {
            return this.$dxs.formatting.formatInteger(this.totalItemCount, this.$i18n.locale);
        },

        filter() {
            return this.$store.getters[MODULE_NAME + "/filter"];
        },

        formFields() {
            return [
                {
                    name: 'attribute',
                    text: this.$t('collections.common.view.attribute.title'),
                    value: this.attribute,
                    isValid: !isEmpty(this.attribute)
                },
            ]
        },

        form() {
            return this.$store.getters[MODULE_NAME + "/form"];
        },

        attribute: {
            get() {
                return this.form.attribute;
            },

            set(value) {
                this.$store.commit(MODULE_NAME + '/setAttribute', value)
            }
        },

        interval: {
            get() {
                return this.form.interval;
            },

            set(value) {
                this.$store.commit(MODULE_NAME + '/setInterval', value)
            }
        },

        selectedSort: {
            get() {
                const selectedSort = this.$store.getters[MODULE_NAME + '/selectedSort'];
                return this.sort.find(a => a.value === selectedSort);
            },

            set(value) {
                this.$store.commit(MODULE_NAME + '/setSelectedSort', value);
            }
        }
    },

    methods: {
        async loadCollectionSettings() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + '/loadCollectionSettings', { collectionId : this.value.collectionId }); 
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async loadData() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + '/loadData', { collectionId : this.value.collectionId, statsAPI: this.statsAPI }); 
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterQueryChange(event) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + '/updateFilterQuery', { collectionId: this.collectionId, filterQuery: { filterQuery: event.query, filterQueryLanguage: event.mode } });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async onFilterChanged(event) {
            this.progressIndicatorService.show();

            try {
                await this.$store.dispatch(MODULE_NAME + '/updateFacetFilters', {collectionId: this.collectionId, facetFilters: event});
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        goToPrintView(type) {
            const chart = this.$refs.chart.$data.eChart;
            const chartImage = chart.getDataURL({
                // Exporting format, can be either png, or jpeg
                type: 'png',
                // Resolution ratio of exporting image, 1 by default.
                pixelRatio: 2,
                // Background color of exporting image, use backgroundColor in option by default.
                backgroundColor: '#fff',
                // Excluded components list. e.g. ['toolbox']
                excludeComponents: ['toolbox', 'tooltip', 'dataZoom']
            });

            this.$router.push({name: 'collectionDistributionPrint', params: {image: chartImage, type: type}})
        },

        async exportDocument(format) {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + '/export', {collectionId: this.collectionId, format: format});

                this.messageService.show({
                    title: this.$t('collection-documents.view.messages.export.success.title', this.$i18n.locale),  
                    text: this.$t('collection-documents.view.messages.export.success.message', this.$i18n.locale)
                });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },
    },

    created() {
        this.loadCollectionSettings();
        this.filterQuery.query = this.filter.filterQuery;
    }
}
</script>

<i18n>
{
    "en": {
        "collection-distribution.view.missingConfig.title" : "Define Attribute",
        "collection-distribution.view.missingConfig.subtitle" : "Please select an attribute to view a distribution analysis."
    },

    "de": {
        "collection-distribution.view.missingConfig.title" : "Attribut festlegen",
        "collection-distribution.view.missingConfig.subtitle" : "Bitte wählen Sie ein Attribut aus, um eine Verteilungsanalyse anzuzeigen."
    }
}
</i18n>