<template>
    <v-sheet>
        <c-section title="Log Item" class="secondary"/>
        <v-container fluid class="secondary">
            <v-row justify="center">
                <v-col cols="12" md="10">
                    <v-card>
                        <v-card-text>
                            <div class="text-h6">{{message | truncate(40)}}</div>
                            <div class="text-subtitle-1">{{timestamp | localized-date-time-format($i18n.locale)}}</div>
                            <div class="text-subtitle-1">{{serviceName}} | {{loggerName}}</div>
                        </v-card-text>
                        <v-divider />
                        <v-card-text class="grey lighten-3">
                            <v-subheader>Message</v-subheader>
                            <div class="ma-3">{{message}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
import DocumentDetailDataMixin from '@/core/mixins/document-detail-data.mixin';
import { get } from 'lodash';

export default {

    name: 'service-log-item-detail',

    mixins: [DocumentDetailDataMixin],

    computed: {

        message() {
            return get(this.data, 'message', '');
        },

        timestamp() {
            return get(this.data, 'timestamp', '');
        },

        serviceName() {
            return get(this.data, 'serviceName', '');
        },

        loggerName() {
            return get(this.data, 'loggerName', '');
        }
    }
}
</script>

<style>

</style>