import api from '@/store/api';

export default {

    namespaced: true,

    state : {

        response: getDefaultResponse(),

        filter: getDefaultFilter()
    },

    mutations: {

        setResponse(state, response) {

            if (response) {
                state.response = response;
            }

        },

        setFilter(state, filter) {

            if (filter) {
                state.filter = filter;
            } else {
                state.filter = getDefaultFilter();
            }
        }
    },

    actions: {

        async loadResponse(context) {

            try {
                let response = await api.catalog.collectionPermissions.findAll(context.state.filter);
                context.commit('setResponse', response);
            } catch (error) {
                context.commit('setResponse', getDefaultResponse());
                throw error;
            }

        },

        async updateFilter(context, filter) {

            context.commit('setFilter', filter);
        },

        async deleteItemByName(context, item) {

            if (item) {

                console.log(">>>> ", JSON.stringify(item));
                await api.catalog.collectionPermissions.deleteByName(item.name);
                context.dispatch('loadResponse');
            }
            
        }
    },

    getters: {

        response(state) {
            return state.response ? state.response : getDefaultResponse();
        },

        filter(state) {
            return state.filter ? state.filter : getDefaultFilter();
        }
    }
}

function getDefaultResponse() {

    return {
        items: []
    };
}

function getDefaultFilter() {
    return '*';
}