<template>
    <v-card tile :flat="flat">
        <v-card-text>
            <div class="text-h6 grey--text">{{title}}</div>
            <div class="grey--text">{{subtitle}}</div>
        </v-card-text>
        <c-metrics-distribution-chart :items="materialDistribution" type="cake" show-value-label />                          
    </v-card>
</template>

<script>
import { get } from 'lodash';

export default {

    props: {

        flat: {
            type: Boolean,
            required: false,
            default: false
        },

        value: {
            type: Object,
            required: true, 
        }

    },

    computed: {

        materialComposition() {
            return get(this.value, 'materialComposition', {});
        },  


        material() {
            return get(this.value, 'material', {});
        },

        title() {
            return get(this.material, 'name', null);
        },

        subtitle() {
            return get(this.material, 'data.casId', null);
        },

        color() {
            return get(this.material, 'data.color', 'grey');
        },

        share() {
            return get(this.materialComposition, 'share', 0.0);
        },

        materialDistribution() {
            return [
                {color: this.color, name: `${this.title}`, value: this.share}, 
                {color: 'lightgray', name: 'Other', value: (100 - this.share)}
            ];
        }
    }


}
</script>