<template>
    <v-sheet>

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form :title="$t('manufacturerplant-editor.view.title', { name: name })" :value="manufacturerplant"
            @update-confirmed="save" @update-canceled="cancel" :editable="hasEditRole">

            <template v-slot:default>
                <v-sheet color="grey lighten-3">

                    <v-tabs v-model="selectedTab" :vertical="$vuetify.breakpoint.mdAndUp" color="grey"
                        :background-color="$vuetify.breakpoint.mdAndUp ? 'white' : 'grey lighten-3'" slider-color="grey"
                        show-arrows :fixed-tabs="!$vuetify.breakpoint.mdAndUp">


                        <!-- Tabs -->
                        <v-tab :key="0">{{ $t('manufacturerplant-editor.view.tabs.general') }}</v-tab>
                        <v-tab :key="1">{{ $t('manufacturerplant-editor.view.tabs.settings') }}</v-tab>
                        <v-tab :key="2">{{ $t('manufacturerplant-editor.view.tabs.json') }}</v-tab>


                        <v-tabs-items v-model="selectedTab">

                            <!-- General -->
                            <v-tab-item :key="0">
                                <c-name-and-i18n-item-editor-page v-model="manufacturerplant" />
                            </v-tab-item>

                            <!-- manufacturer Settings (manufacturer, etc.) -->
                            <v-tab-item :key="1">
                                <v-card flat>
                                    <v-card-text>
                                        <div class="text-h5">{{ $t('manufacturerplant-editor.view.component.title') }}</div>
                                        <div>{{ $t('manufacturerplant-editor.view.component.subtitle') }}</div>
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" lg="6">
                                    
                                        <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("manufacturerplant-editor.view.sections.manufacturer.title") }}</div>
                                        <div>{{ $t('manufacturerplant-editor.view.sections.manufacturer.subtitle') }}</div>
                                        <c-item-selector :items="manufacturers.items" v-model="manufacturerplant.references.manufacturerid" :return-object="false" filled
                                          :chips="false"
                                          :label="$t('manufacturerplant-wizard.view.subheader.manufacturer')">Manufacturers</c-item-selector>
                                        </v-col>
                                        </v-row>
                                    <v-divider />
                                    
                                    <div class="text-subtitle-1 font-weight-medium pb-3">
                                            {{ $t("manufacturerplant-editor.view.sections.location.title") }}</div>
                                    <div>{{ $t('manufacturerplant-editor.view.sections.location.subtitle') }}</div>
                                
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="manufacturerplant.data.street" filled
                                    :label="streetLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>

                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="manufacturerplant.data.building" filled
                                    :label="buildingLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>

                                    <v-col cols="12" md="6">
                                    <v-text-field v-model="manufacturerplant.data.zipCode" filled
                                    :label="zipLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>
                              </v-row>
                              <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="manufacturerplant.data.country" filled
                                    :label="countryLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>

                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="manufacturerplant.data.province" filled
                                    :label="provinceLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>

                                    <v-col cols="12" md="6">
                                    <v-text-field v-model="manufacturerplant.data.city" filled
                                    :label="cityLabel"
                                    :rules="[ 
                                        v => v && !!v.trim()  || $t('name-input.component.validation.notEmpty')
                                    ]"/>
                                  </v-col>
                              </v-row>
                              
                            </v-card-text>
                            </v-card>
                            </v-tab-item>

                            <!-- JSON -->
                            <v-tab-item :key="2">
                                <c-json-item-editor-page v-model="model" />
                            </v-tab-item>

                        </v-tabs-items>

                    </v-tabs>
                </v-sheet>
            </template>
        </c-item-editor-form>
    </v-sheet>
</template>

<script>
import {cloneDeep, get } from 'lodash';

import ManufacturerplantEditorModule from "../../../store/masterdata/manufacturerplant/manufacturerplant-editor.module";

import Roles from '@/roles';

const MODULE_ID = 'ManufacturerplantEditor';

export default {

    name: 'manufacturerplant-editor',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],



    props: {

        value: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        selectedTab: 0
    }),

    methods: {
        async refresh() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/loadData", { name: this.name });
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },



        async save() {
            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_ID + "/saveData");
                await this.$store.dispatch(MODULE_ID + "/updateModel",this.$store.getters[MODULE_ID + '/manufacturerplant']);
                await this.refresh();
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

cancel() {
    this.$router.push({
        name: "ManufacturerplantListView",
    });
}
    },

    computed: {

        street:{
            get() {
                return this.$store.getters[MODULE_ID + '/manufacturerplant'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateManufacturerplant", value);
            }
        },

        name: {

            get() {
                return get(this.value, 'name');
            }
        },

        streetLabel(){
            return this.$t("name-input.component.streetLabel")
        },
        buildingLabel(){
            return this.$t("name-input.component.buildingLabel")
        },
        zipLabel(){
            return this.$t("name-input.component.zipLabel")
        },
        countryLabel(){
            return this.$t("name-input.component.countryLabel")
        },
        cityLabel(){
            return this.$t("name-input.component.cityLabel")
        },
        provinceLabel(){
            return this.$t("name-input.component.provinceLabel")
        },


        hasEditRole() {
            return this.userContextService.hasRole(Roles.MANUFACTURERPLANT_EDIT);
        },

        manufacturerplantid: {

            get() {
                return this.$store.getters[MODULE_ID + '/manufacturerplant'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateManufacturerplant", value);
            }
        },
        manufacturerplant: {

        get() {
            return this.$store.getters[MODULE_ID + '/manufacturerplant'];
        },

        set(value) {
            this.$store.dispatch(MODULE_ID + "/updateManufacturerplant", value);
        }
},

        manufacturer: {
            get() {
                return this.$store.getters[MODULE_ID + '/manufacturer'];
            },

            set(value) {
                this.$store.dispatch(MODULE_ID + "/updateManufacturer", value);
            }

        },
        

        
manufacturers() {
    return this.$store.getters[MODULE_ID + "/manufacturers"];
},
manufacturerId: {
    get() {
        return this.$store.getters[MODULE_ID + "/manufacturerId"];
    },
    

    set(item) {
        this.$store
            .dispatch(MODULE_ID + "/updateManufacturerId", item)
            .catch(this.errorHandlerService.handleError);
    },
},manufacturerid: {
    get() {
        return this.$store.getters[MODULE_ID + "/manufacturerId"];
    },
    

    set(item) {
        this.$store
            .dispatch(MODULE_ID + "/updateManufacturerId", item)
            .catch(this.errorHandlerService.handleError);
    },
},
        model() {
            return {manufacturerPlant: cloneDeep(this.manufacturerplant)};
        }

    },

    created() {

        // Register the module dynamically with the view.
        if (!this.$store.hasModule(MODULE_ID)) {
            this.$store.registerModule(MODULE_ID, ManufacturerplantEditorModule);
        }

        this.refresh();

    }
}
</script>

<i18n>
   
{
    "en" : {
        "manufacturerplant-editor.view.title" : "Manufacturer Plant {name}",

        "manufacturerplant-editor.view.tabs.general" : "General",
        "manufacturerplant-editor.view.tabs.settings" : "Settings",
        "manufacturerplant-editor.view.tabs.json" : "JSON",
        "manufacturerplant-editor.view.component.title":"Settings",
        "manufacturerplant-editor.view.component.subtitle": "This page contains settings for the selected Manufacturer Plant.",
        "manufacturerplant-editor.view.sections.manufacturer.title":"Manufacturers",
        "manufacturerplant-editor.view.sections.manufacturer.subtitle" : "Here you assign the Manufacturer to the Manufacturer Plant.",
        "manufacturerplant-editor.view.sections.location.title":"Location Data",
        "manufacturerplant-editor.view.sections.location.subtitle" : "Here you assign the location of the Manufacturer Plant.",
        "manufacturerplant-wizard.view.subheader.manufacturer" : "Manufacturer",
        "name-input.component.streetLabel" : "Street Name",
        "name-input.component.buildingLabel" : "Building Number",
        "name-input.component.zipLabel" : "Zip Code",
        "name-input.component.countryLabel" : "Country",
        "name-input.component.cityLabel" : "City",
        "name-input.component.provinceLabel" : "Province",
        "name-input.component.validation.notEmpty" : "This field must be filled",
        "name-input.component.validation.onlyValidCharacters" : "Please only use the characters from a-z and figures, please no special characters"
    },

    "de" : {
        "manufacturerplant-editor.view.title" : "Hersteller-Werk {name}",
        "manufacturerplant-editor.view.tabs.general" : "Allgemein",
        "manufacturerplant-editor.view.tabs.settings" : "Einstellungen",
        "manufacturerplant-editor.view.tabs.json" : "JSON",
        "manufacturerplant-editor.view.component.title":"Einstellungen",
        "manufacturerplant-editor.view.component.subtitle": "Diese Seite beinhaltet Einstellungen für das ausgewählte Hersteller-Werk.",
        "manufacturerplant-editor.view.sections.manufacturer.title":"Hersteller",
        "manufacturerplant-editor.view.sections.manufacturer.subtitle" : "Hier weisen Sie dem Hersteller-Werk einen Hersteller zu.",
        "manufacturerplant-editor.view.sections.location.title":"Standort-Daten",
        "manufacturerplant-editor.view.sections.location.subtitle" : "Hier weisen Sie dem Hersteller-Werk einen Standort zu.",
        "manufacturerplant-wizard.view.subheader.manufacturer" : "Hersteller",
        "name-input.component.streetLabel" : "Straßenname",
        "name-input.component.buildingLabel" : "Hausnummer",
        "name-input.component.zipLabel" : "Postleitzahl",
        "name-input.component.countryLabel" : "Land",
        "name-input.component.cityLabel" : "Stadt",
        "name-input.component.provinceLabel" : "Bundesland",
        "name-input.component.validation.notEmpty" : "Dieses Feld kann nicht leer sein.",
        "name-input.component.validation.onlyValidCharacters" : "Bitte nutze ausschließlich reguläre Buchstaben (a-z) und Zahlen; bitte nutze keine Sonderzeichen"
    }
}
</i18n>