import api from '@/store/api';

import { get } from 'lodash';

export default {

    namespaced: true,

    state: {

        quartileDistribution: [],
        percentileDistribution: []
    },

    mutations: {
        
        setQuartileDistribution(state, quartileDistribution) {
            state.quartileDistribution = quartileDistribution;
        },

        addQuartileDistribution(state, quartileDistribution) {

            if (quartileDistribution) {
                state.quartileDistribution.push(quartileDistribution) 
            }
        },

        setPercentileDistribution(state, percentileDistribution) {
            state.percentileDistribution = percentileDistribution;
        },

        addPercentileDistribution(state, percentileDistribution) {

            if (percentileDistribution) {
                state.percentileDistribution.push(percentileDistribution) 
            }
        }
    },

    actions: {
        
        async loadQuartileDistributions(context, { collectionId, measureIdList, filter}) {
            
            // First reset the previously loaded results.
            context.commit('setQuartileDistribution', []);
            
            for (const measureId of measureIdList) {
                try {
                    const response = await api.collections.measures.getQuartilesDistribution(collectionId, measureId, filter);
                    context.commit('addQuartileDistribution', get(response, 'data.quartiles', {}));
                   
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async loadPercentileDistributions(context, { collectionId, measureIdList, filter, percents}) {
            
            // First reset the previously loaded results.
            context.commit('setPercentileDistribution', []);

            for (const measureId of measureIdList) {
                try {
                    const response = await api.collections.measures.getPercentilesDistribution(collectionId, measureId, filter, percents);
   
                    context.commit('addPercentileDistribution', get(response, 'data.percentiles', {}));
                } catch (error) {
                    console.log(error);
                }
            }
        },
    },

    getters: {

        quartileModel(state) {
            return {
                quartileDistributionItems : state.quartileDistribution
            }
        },

        percentileModel(state) {
            return {
                percentileDistributionItems : state.percentileDistribution 
            }
        },

        
    }

}
