<template>
    <v-sheet>
    
    <dxs-category-section :title="$t('battery-compliance.page.sections.conformity.title')">
        <v-container fluid>
            <v-row class="justify-center">
                <v-col cols="12" sm="8" lg="6">
                    <c-link-card :value="euDeclarationOfConformanceLink" tile/>
                </v-col>
            </v-row>
        </v-container>
    </dxs-category-section>
    <v-divider />
    
    <dxs-category-section :title="$t('battery-compliance.page.sections.symbols.title')">
        <v-container fluid>
            <v-row class="justify-center">
                <v-col cols="12" sm="8" lg="6">
                    WIP
                </v-col>
            </v-row>
        </v-container>
    </dxs-category-section>
    </v-sheet>
</template>

<script>

import CategorySectionComponent from '@/solutions/battery-data-hub/components/common/category-section.component.vue';
import { get } from 'lodash';

export default {
    
    name: 'battery-compliance-chain-page',

    components: {
        'dxs-category-section' : CategorySectionComponent,
    },

    props: {

        value: {
            type: Object,
            required: false
        }
    },

    computed: {
        
        conformity() {
            return get(this.value, 'conformity', {});
        },
        
        euDeclarationOfConformanceLink() {
            return get(this.conformity, 'links.euDeclarationOfConformance', {})
        },    
    }
}
</script>

<i18n>
{
    "en" : {
        "battery-compliance.page.sections.conformity.title" : "Conformity",
        "battery-compliance.page.sections.symbols.title" : "Symbols"
    },

    "de" : {
        "battery-compliance.page.sections.conformity.title" : "Konformität",
        "battery-compliance.page.sections.symbols.title" : "Symbole"
    }
}
</i18n>