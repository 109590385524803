import api from '../../../api/battery-data-hub.api';
import { set } from 'lodash';

export default {

    namespaced: true,

    state: {
        manufacturers: createEmptyManufacturers(),
        tenantId: null
    },

    mutations: {

        setManufacturers(state, manufacturers) {

            if (manufacturers) {

                state.manufacturers.tenantId = manufacturers.tenantId ? manufacturers.tenantId : null;
                state.manufacturers.name = manufacturers.name ? manufacturers.name : null;
                state.manufacturers.i18n = manufacturers.i18n ? manufacturers.i18n : {};
                state.manufacturers.data = manufacturers.data ? manufacturers.data : { custData: {} };
                state.manufacturers.active = manufacturers.active ? manufacturers.active : true;
                state.manufacturers.references = manufacturers.references ? manufacturers.references : {};

            } else {
                state.manufacturers.tenantId = null;
                state.manufacturers.name = null;
                state.manufacturers.i18n = null;
                state.manufacturers.data = null;
                state.manufacturers.active = null;
                state.manufacturers.references = null;
            }

        },

        setTenantId(state, tenantId) {
            if (tenantId) {
                state.tenantId = tenantId;

            } else {
                state.tenantId = null;
            }
        }


    },

    actions: {

        async createManufacturers(context) {

            try {

                let request = context.getters.request;
                await api.masterdata.manufacturers.create(request);

            } catch (error) {

                console.log("An error has occured" + error);

                return Promise.reject(error);
            }
        },

        async resetManufacturers(context) {
            context.commit('setManufacturers', createEmptyManufacturers());
        },

        async updateManufacturers(context, manufacturers) {

            try {
                context.commit('setManufacturers', manufacturers);
            } catch (error) {
                return Promise.reject(error);
            }
        },

        async updateTenantId(context, tenantId) {
            try {
                context.commit('setTenantId', tenantId);
            } catch (error) {
                return Promise.reject(error);
            }
        }

    },



    getters: {

        request(state) {
            let payload = state.manufacturers;
            payload.creationDateTime ? set(payload, "creationDateTime", payload.creationDateTime) : set(payload, "creationDateTime", null);
            payload.creationUser ? set(payload, "creationUser", payload.creationUser) : set(payload, "creationUser", null);
            payload.modificationDateTime ? set(payload, "modificationDateTime", payload.modificationDateTime) : set(payload, "modificationDateTime", null);
            payload.modificationUser ? set(payload, "modificationUser", payload.modificationUser) : set(payload, "modificationUser", null);
            return payload;
        },

        manufacturers(state) {
            return state.manufacturers ? state.manufacturers : createEmptyManufacturers();
        }

}
}

function createEmptyManufacturers() {

    return {

        "tenantId": null,
        "name": null,
        "i18n": {},
        "data": { custData: {} },
        "active": true,
        "references": {}

    }
}