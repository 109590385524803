<template>
    <v-sheet>

        
        <v-toolbar color="secondary darken-1" flat >   
            <v-breadcrumbs>
                <v-breadcrumbs-item>System</v-breadcrumbs-item>
                <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
                <v-breadcrumbs-item disabled>Service Log</v-breadcrumbs-item>
            </v-breadcrumbs>
            
        </v-toolbar>
        
        
        <c-section :title="$t('service-log-item-list.view.title')" :subtitle="$t('service-log-item-list.view.subtitle')" class="secondary">
            <dxs-collection-documents :value="{collectionId: 'Log'}" hide-select-view-type>
                
                <template v-slot:content-items="{items, collectionSettings,}">
                    
                    <!-- *************************** -->
                    <!-- Data                        -->
                    <!-- *************************** -->
                    <template v-if="!itemsEmpty"> 
                        <v-container fluid class="grey lighten-5"> 
                            <v-row justify="center">
                                <v-col cols="12" md="10">
                                    <v-card tile>
                                        <v-list three-line>
                                            <v-list-item v-for="(item, index) of items" :key="'logItem_' + index">
                                                <v-list-item-icon>
                                                    <v-icon large v-if="isError(item)"  color="accent darken-1">error</v-icon>
                                                    <v-icon large v-else-if="isWarning(item)" color="accent lighten-1">warning</v-icon>
                                                    <v-icon large v-else color="grey">info</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-subtitle>{{item.data.timestamp | localized-date-time-format($i18n.locale)}} | {{item.data.severity}}</v-list-item-subtitle>
                                                    <v-list-item-title>{{item.data.message }}</v-list-item-title>
                                                    <v-list-item-subtitle> {{item.data.serviceName}} ({{item.data.serviceInstance | integer-format}}) / {{item.data.source}}.{{item.data.sourceMethod}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn icon @click="goToDetailsView({ collectionSettings, item })">
                                                        <v-icon>open_in_new</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>

                    <!-- *************************** -->
                    <!-- No Data                     -->
                    <!-- *************************** -->
                    <template v-else>
                        <c-empty-result-tile />
                    </template>
                
                </template>
            </dxs-collection-documents>
        </c-section>


    </v-sheet>
</template>

<script>
import CollectionDocumentsView from '@/core/views/collection/collection-documents.view';

import { get } from 'lodash';

export default {

    name: 'service-log-item-list',

    components : {
        'dxs-collection-documents' : CollectionDocumentsView
    },

    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    methods: {
        getSeverity(item) {
            return get(item, 'data.severity', 'unknown').toLowerCase(); 
        },

        isError(item) {
            return this.getSeverity(item) === 'severe';
        },

        isWarning(item) {
            return this.getSeverity(item) === 'warning';
        },

        isInfo(item) {
            return !this.isError(item) && this.isWarning(item);
        },

        goToDetailsView({collectionSettings, item}) {

            // This method relies on the client settings attached to the given collection.
            const pathDetailView = this.$dxs.collectionSettings.getDetailViewUrl(collectionSettings, item);
           
            // Unfortunatetly there is currently not better way to open new tabs in vue router.
            const route = this.$router.resolve(pathDetailView);
            window.open(route.href);
        },

        openLogItem(item) {
            this.goToDetailsView({
                collectionSettings: this.collectionSettings,
                item: item
            });
        }
    }

}
</script>

<i18n>
{
    "en" : {
        "service-log-item-list.view.title" : "Service Log",
        "service-log-item-list.view.subtitle" : "The service log contains all technical log messages from the application services. This log is meant to be used by system administrators.",
        "service-log-item-list.view.toolbar.sort.label" : "Sorting",
        "service-log-item-list.view.header.filterAndSort" : "Filter: All log items with {filter}. Sorted by {sort}",
        "service-log-item-list.view.header.applyFilterAndSort" : "Apply",

         "service-log-item-list.view.results" : "Log ({totalItemCount} Items)",

        "service-log-item-list.view.table.loadMore" : "Load More Log Items ..."



    },

    "de" : {
        "service-log-item-list.view.title" : "Service-Protokoll",
        "service-log-item-list.view.subtitle" : "Das Service-Protokoll enthält die technischen Meldungen der Anwendungsservices für System-Administratoren.",
        "service-log-item-list.view.toolbar.sort.label" : "Sortierung",
        "service-log-item-list.view.header.filterAndSort" : "Filterung: Alle Einträge zum Filterausdruck: {filter}. Sortierung: {sort}",
        "service-log-item-list.view.header.applyFilterAndSort" : "Ausführen",

        "service-log-item-list.view.results" : "Protokoll ({totalItemCount} Einträge)",

        "service-log-item-list.view.table.loadMore" : "Weitere Protokoll-Einträge laden ..."
    }
}
</i18n>