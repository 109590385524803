<template>
    <v-sheet>
        <!-- ****************************** -->
        <!-- BREADCRUMBS                    -->
        <!-- ****************************** -->
        <v-breadcrumbs class="secondary darken-1">
            <v-breadcrumbs-item :disabled="true">{{$t('webhook-subscriptions-detail.view.breadcrumb.root')}}</v-breadcrumbs-item>
            <v-breadcrumbs-divider><v-icon>chevron_right</v-icon></v-breadcrumbs-divider>
            <v-breadcrumbs-item>{{name}}</v-breadcrumbs-item>
        </v-breadcrumbs>
        <v-divider />

        <!-- ****************************** -->
        <!-- EDITOR                         -->
        <!-- ****************************** -->
        <c-item-editor-form 
            :title="$t('webhook-subscriptions-detail.view.title', {name: name})" 
            v-model="model"
            :editable="hasEditRole"
            @update-confirmed="save"
            @update-canceled="cancel"
            >
            
            <template v-slot:default="{ item }">
                <dxs-webhook-editor v-model="item.webhookSubscription" /> 
            </template>
   
        </c-item-editor-form>

        <v-divider />
    </v-sheet>
</template>

<script>
import { get } from 'lodash';

import Roles from '@/roles';
import webhookSubscriptionsEditorComponent from '@/core/components/webhooks/webhook-editor.component';

const MODULE_NAME = 'webhookSubscriptionsEditor';



export default {

    name: 'webhook-subscriptions-editor-view',

    inject: ['errorHandlerService', 'progressIndicatorService', 'userContextService'],

    components: {
        'dxs-webhook-editor' : webhookSubscriptionsEditorComponent
    },

    props: {
        value: {
            type: Object,
            required: true,
             validator: (value) => value.name
        }
    },

    methods: {
        
        async refresh() {

            this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/loadData", {name: this.name})
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            }
        },

        async save() {
           this.progressIndicatorService.show();
            try {
                await this.$store.dispatch(MODULE_NAME + "/saveData");
            } catch (error) {
                this.errorHandlerService.handleError(error);
            } finally {
                this.progressIndicatorService.hide();
            } 
        },

        async cancel() {
            this.$router.push({
                name: 'webhookSubscriptionsList'
            })
        }

    },

    computed: {

        name() {
            return get(this.value, 'name', null);
        },

        model: {

            get() {
                return this.$store.getters[MODULE_NAME + '/model'];
            }, 
            
            set(value) {
                this.$store.dispatch(MODULE_NAME + "/updateModel", value);
            }
        },

        hasEditRole() {
            return this.userContextService.hasRole(Roles.WEBHOOKS_SUBSCRIPTIONS_EDIT);
        },

       
    },

    created() {
        this.refresh();
    }
}
</script>

<i18n>
{
    "en" : {
        "webhook-subscriptions-detail.view.title" : "{name}",
        "webhook-subscriptions-detail.view.breadcrumb.root" : "Webhooks"
    }
}
</i18n>