<template>
  <c-editable-item-list
    :title="$t('detection-rules-list.component.title')" 
    :items="items"
    @item-updated="fireItemUpdated" 
    @item-removed="fireItemRemoved"
    :disabled="disabled"
    :new-item="emptyItem" 
    :readonly="readonly"
    headerStyle="lite"
    editDialogStyle="fullscreen"
    flat
    :check-duplicate="(e1, e2) => (e1.name === e2.name)"
  >
<!-- 
    <template v-slot:item-list-head>
        <template>
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Name</th>
                    <th>Active?</th>
                    <th>Severity</th>
                </tr>
            </thead>
        </template> 
    </template> -->

    <!-- =========================================================== -->
    <!-- LIST BODY                                                   -->
    <!-- =========================================================== -->
    <template v-slot:item-list-body-row="props"> 
          <!-- For regular devices use tabular layout-->
        <template>
            <td>{{props.item | localized-name($i18n.locale)}}</td>
            <td><v-icon small v-if="props.item.active">check_box</v-icon><v-icon small v-else>check_box_outline_blank</v-icon></td>
            <td>{{props.item.severity}}</td>
        </template> 
    </template>
    
    <!-- =========================================================== -->
    <!-- EDITOR                                                      -->
    <!-- =========================================================== -->
    <template v-slot:item-list-editor="props">
        <dxs-detection-rules-editor 
            v-model="props.item"
            :context="context"
            :isNewItem="props.isNewItem"
        />
    </template>
  </c-editable-item-list>
</template>

<script>
import DetectionRulesEditorComponent from '@/core/components/catalog/detection-job-templates/detection-rules-editor.component.vue';

export default {

    name: 'detection-rules-list',

    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        
        value: {
            type: Object,
            required: false,
        },

        context: {
            type: Object,
            required: false,
        },

        readonly : {
            type: Boolean,
            required: false,
            default: false 
        },

        disabled : {
            type: Boolean,
            required: false,
            default: false 
        },
    },

    components: {
        'dxs-detection-rules-editor' : DetectionRulesEditorComponent
    },

    methods: {
        fireItemUpdated(event) {
            console.log('+++' , JSON.stringify(event));
            this.$emit('item-updated', event);
        },

        fireItemRemoved(event) {
            console.log('+++' , JSON.stringify(event));
            this.$emit('item-removed', event);
        }
    },

    computed: {
        emptyItem() {
            return {
                name: "",
                i18n: {},
                severity: "LOW",
                expression: "",
                active: false
            }
        }
    }
}
</script>

<i18n>
{
    "en" : {
        "detection-rules-list.component.title" : "Detection Rules"
    },

    "de" : {
        "detection-rules-list.component.title" : "Detection Regeln"
    }
}
</i18n>