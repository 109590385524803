<template>
<v-text-field
    v-model="ruleExpression"
    :label="fieldLabel"
    :hint="hint"
    :persisten-hint="persistenHint"
    :filled="filled"
    color="accent"
    :rules="[ e => !!e || $t('mapping-rule-expression-input.component.validation.notEmpty')]"
    />
<!--
  <v-combobox
    v-model="selectedItem" 
    :label="fieldLabel"
    :hint="hint"
    :persisten-hint="persistenHint"
    :filled="filled"
    clearable
    :return-object="false"
    :items="items"
    :multiple="false"
    color="accent"
    :filter="filterItems"
    >

    <template v-slot:item="{ item, on, attrs }">
        <v-list-item two-line v-on="on" v-bind="attrs" color="white">
            <v-list-item-content>
                <v-list-item-title>
                        <code>{{item.value}}</code>
                </v-list-item-title>
                <v-list-item-subtitle>
                        <div class="body-2">{{item.text}}</div>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </template>
  </v-combobox>
  -->
</template>

<script>
import  { get } from 'lodash';

export default {

    name: 'mapping-rule-input',

    props: {
        value: {
            type: String,
            required: false,
            default: null
        },

        label: {
            type: String, 
            required: false,
        },

        hint : {
            type: String, 
            required: false,
        },

        persistenHint: {
            type: Boolean,
            required: false,
            default: false
        },

        filled: {
            type: Boolean,
            required: false,
            default: false
        },

        source: {
            type: Object,
            required: true,
        }
    },

    data() {

        return {
            internalRuleExpression: this.value
        };
    },

    methods: {

        isNumeric(attribute) {
            return (attribute.datatype === 'DECIMAL' || attribute.datatype === 'INTEGER');
        },

        fireInput() {
            this.$emit('input', this.internalRuleExpression);
        },

        filterItems(item, query) {

            let normalizedQuery = query.toLowerCase();

            return item.value.toLowerCase().indexOf(normalizedQuery) > -1 || item.text.toLowerCase().indexOf(normalizedQuery) > -1;
        }
    },

    

    computed: {

        ruleExpression: {

            get() {
                return this.internalRuleExpression;
            },

            set(value) {
                this.internalRuleExpression = value;
                this.fireInput();
            }
        },

        fieldLabel() {
            return this.label ? this.label : this.$t('mapping-rule-input.component.label')
        },

        sourceAttributes() {
            return get(this.source, 'schema.attributes', {});
        },

        items() {

            let items = [];
            for (let key in this.sourceAttributes) {
                let attribute = this.sourceAttributes[key]; 

                
                items.push({
                    value: '$data.' + attribute.name,
                    text: this.$t('mapping-rule-input.component.items.value' ,{ name: this.$dxs.filters.localizedName(attribute, this.$i18n.locale)}),
                });

                items.push({
                    value: '$source.stats.' + attribute.name + '.count',
                    text: this.$t('mapping-rule-input.component.items.stats.count' ,{ name: this.$dxs.filters.localizedName(attribute, this.$i18n.locale)}),
                });

                if (this.isNumeric(attribute)) {
                    
                    items.push({
                        value: '$source.stats.' + attribute.name + '.max',
                        text: this.$t('mapping-rule-input.component.items.stats.max' ,{ name: this.$dxs.filters.localizedName(attribute, this.$i18n.locale)})
                    });

                    items.push({
                        value: '$source.stats.' + attribute.name + '.min',
                        text: this.$t('mapping-rule-input.component.items.stats.min' ,{ name: this.$dxs.filters.localizedName(attribute, this.$i18n.locale)})
                    });

                    items.push({
                        value: '$source.stats.' + attribute.name + '.avg',
                        text: this.$t('mapping-rule-input.component.items.stats.avg' ,{ name: this.$dxs.filters.localizedName(attribute, this.$i18n.locale)}),
                    });

                    items.push({
                        value: '$source.stats.' + attribute.name + '.stddev',
                        text: this.$t('mapping-rule-input.component.items.stats.stddev' ,{ name: this.$dxs.filters.localizedName(attribute, this.$i18n.locale)}),
                    });
                }
                
            }
            return items;
        }
    }

}
</script>

<i18n>
{
    "en" : {
        "mapping-rule-input.component.label" : "Mapping Rule Expression",
        
        "mapping-rule-input.component.items.stats.count" : "Count of non-null items for field {name}.",
        "mapping-rule-input.component.items.value" : "Value of field {name}.",
        "mapping-rule-input.component.items.stats.min" : "Minimum value of field {name}.",
        "mapping-rule-input.component.items.stats.max" : "Maximum value of field {name}.",
        "mapping-rule-input.component.items.stats.avg" : "Average value of field {name}.",
        "mapping-rule-input.component.items.stats.stddev" : "Standard deviation value of field {name}.",
        "mapping-rule-expression-input.component.validation.notEmpty" : "The mapping rule expression must not be empty. Please enter a valid expression."
    },

    "de" : {
        "mapping-rule-input.component.label" : "Mapping-Ausdruck",
        "mapping-rule-input.component.items.value" : "Wert im Feld {name}.",
        "mapping-rule-input.component.items.stats.count" : "Gesamtanzahl der nicht-leeren Werte im Feld {name}.",
        "mapping-rule-input.component.items.stats.min" : "Kleinster Wert im Feld {name}.",
        "mapping-rule-input.component.items.stats.max" : "Größter Wert im Feld {name}.",
        "mapping-rule-input.component.items.stats.avg" : "Durchschnittlicher Wert im Feld {name}.",
        "mapping-rule-input.component.items.stats.stddev" : "Standardabweichung im Feld {name}.",
        "mapping-rule-expression-input.component.validation.notEmpty" : "Der Mapping-Ausdruck darf nicht leer sein. Bitte geben Sie einen gültigen Ausdruck ein."
    }
}
</i18n>